import * as types from "./ActionTypes";

export const loadUblDataInProgress = () => {
  return {
    type: types.LOAD_UBL_DATA_IN_PROGRESS,
  };
};

export const loadUblDataSuccess = (ubl) => {
  return {
    type: types.LOAD_UBL_DATA_SUCCESS,
    ubl,
  };
};

export const loadUblDataFailure = (error) => {
  return {
    type: types.LOAD_UBL_DATA_FAILURE,
    error,
  };
};

export const addUblInPrgress = () => {
  return {
    type: types.ADD_UBL_DATA_IN_PROGRESS,
  };
};

export const addUblSuccess = (addUblMessage) => {
  return {
    type: types.ADD_UBL_DATA_SUCCESS,
    addUblMessage,
  };
};

export const addUblFailure = () => {
  return {
    type: types.ADD_UBL_DATA_FAILURE,
  };
};

export const viewUblSummaryInPrgress = () => {
  return {
    type: types.VIEW_UBL_SUMMARY_IN_PROGRESS,
  };
};

export const viewUblSummarySuccess = (viewUblSummary) => {
  return {
    type: types.VIEW_UBL_SUMMARY_SUCCESS,
    viewUblSummary,
  };
};

export const viewUblSummaryFailure = () => {
  return {
    type: types.VIEW_UBL_SUMMARY_SUCCESS,
  };
};
export const loadCblMaptoUblDataInProgress = () => {
  return {
    type: types.LOAD_CBL_MAPTOUBL_DATA_IN_PROGRESS,
  };
};

export const loadCblMaptoUblDataSuccess = (cublMapToUbl) => {
  return {
    type: types.LOAD_CBL_MAPTOUBL_DATA_SUCCESS,
    cublMapToUbl,
  };
};

export const loadCblMaptoUblDataFailure = () => {
  return {
    type: types.LOAD_CBL_MAPTOUBL_DATA_FAILURE,
  };
};

export const viewModifyCblDataInPrgress = () => {
  return {
    type: types.VIEW_MODIFY_CBL_DATA_IN_PROGRESS,
  };
};

export const viewModifyCblDataSuccess = (modifyCbl) => {
  return {
    type: types.VIEW_MODIFY_CBL_DATA_SUCCESS,
    modifyCbl,
  };
};

export const viewModifyCblDataFailure = () => {
  return {
    type: types.VIEW_MODIFY_CBL_DATA_FAILURE,
  };
};
