import React from "react";

/**
 * AWS Cognito constants
 *
 * Must be moved to Server side in order to keep these data secure
 * Probably an addition of lambda API layer would do
 */

export const CLIENT_ID = "7er5cuskn46jpgke4t7d3kmoug";
export const CLIENT_SECRET = "1liup6jn7k07rlrcrbf72a4lkjgph5b9vnjesj3o7hto28p7mogo";
export const GRANT_TYPE = "authorization_code";

/**
 * REDUX actions
 */

export const SAVE_TOKENS = "SAVE_TOKENS";

/**
 * Auth Context
 */

export const AuthContext = React.createContext();
