import Header from "components/common/Header";
import LoadingIndicator from "components/common/LoadingIndicator";
import Sidebar from "components/common/Sidebar";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import Toast from "react-bootstrap/Toast";
import { useIdleTimer } from "react-idle-timer";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  addCubl,
  cblMapToUbl,
  deleteCubl,
  getCubl,
  loadCblData,
  loadCblDestination,
  viewUblSummary,
} from "redux/thunks";
import CblService from "services/CblService";
import UblService from "services/UblService";
import { callLogoutURL } from "../../Authentication/utilities";
import Destination from "./Destination";
import NewCblPage from "./NewCblPage";
import ViewSummary from "./ViewSummary";

const ConsolidateDispatch = ({
  loadCblData,
  loadCblDestination,
  viewUblSummary,
  getCubl,
  deleteCubl,
  cblMapToUbl,
  ubl,
  ublData,
  cbl,
  viewUbl,
  message,
  message2,
  ...props
}) => {
  const user = JSON.parse(localStorage.getItem("User"));
  const { pending, error } = ublData;
  const [crumbs, setCrumbs] = useState(["Home", "Create Dispatch In Store"]);
  const [edit, setEdit] = useState(false);
  const [cart, setCart] = useState([]);
  const [current, setCurrent] = useState(0);
  const [index, setIndex] = useState(0);
  const [pageno, setpageno] = useState(1);
  const [ublIds, setUblIds] = useState([]);
  const [select, setSelect] = useState(false);
  const [barcodeValid, setBarcodeValidation] = useState(true);
  const [loader, setLoader] = useState(false);
  const [cblInputText, setCblInputText] = useState("");
  const [cblNewInputText, setCblNewInputText] = useState("");
  const [ublInputText, setUblInputText] = useState("");
  const [errorMsgPopup, setErrorMsgPopup] = useState("");
  const [stepperLabel, setStepperLable] = useState("");
  const [valid, setvalid] = useState(false);
  const [msg, setmsg] = useState("");
  const [timeoutDuration] = useState(
    localStorage.getItem("timeout") ? localStorage.getItem("timeout") : 1800000
  );
  const [itemTaggingToast, setItemTaggingToast] = useState(false);
  const [toastmsg, settoastmsg] = useState("UBL Headers created Successfully");
  const [dcno, setdcno] = useState("");
  const [dcname, setdcname] = useState("");
  const [address, setAddress] = useState({});
  console.log("UBLLLLL", pending, error);
  const [state, setState] = useState({
    errorMsgPopup: "",
    search: false,
    details: [],
    showLoader: false,
  });

  if (typeof window != "undefined") {
    console.log("listener", localStorage.getItem("access_token"));
    window.addEventListener("storage", function (e) {
      if (localStorage.getItem("access_token") == "null") {
        callLogoutURL();
        return;
      }
    });
  }

  useEffect(async () => {
    setLoader(true);
    await loadCblData(user.storeNo, user.token);
    await CblService.cblDestination(user.storeNo, user.token)
      .then((request) => {
        localStorage.setItem(
          "DCNO",
          request.DestinationConfigDetails.details[0].DC + ""
        );
        localStorage.setItem(
          "DCNAME",
          request.DestinationConfigDetails.details[0].DC_NAME
        );
        setAddress(request.DestinationConfigDetails.details[0]);
        setdcno(request.DestinationConfigDetails.details[0].DC + "");
        setdcname(request.DestinationConfigDetails.details[0].DC_NAME);
      })
      .catch((err) => console.log(err));
    setLoader(false);
  }, []);
  const shouldComponentRender = () => {
    const { pending } = ublData;

    if (pending === false) return false;
    // more tests
    return true;
  };
  console.log("STEPPERLABLE", stepperLabel);

  const setCurrentStep = () => {};
  const handleRemoveItem = async (storeNo, cublId, ublId, token) => {
    setLoader(true);
    await CblService.removeCublFromUbl(storeNo, cublId, ublId, token)
      .then((response) => {
        setLoader(false);
        if (
          response.updateRemoveCublFromUbl.message ==
          "CUBL Details removed from UBL"
        ) {
          settoastmsg("UBL removed from this CBL", setItemTaggingToast(true));
        } else {
          setmsg("Invalid UBL ID");
          $("#errorModalCenter12").modal();
        }
      })
      .catch((err) => {
        console.log(err);
      });

    var temp_cart = cart.filter((detail) => detail.UBL_ID != ublId);
    setCart(temp_cart);
    setLoader(false);
  };
  const handleViewUblSummary = async (index) => {
    setLoader(true);
    setIndex(index);
    await viewUblSummary(user.storeNo, index, user.token);
    setLoader(false);
    $("#myModal2").modal();
  };

  const handleMapCblToUbl = (e) => {
    e.preventDefault();
    cblMapToUbl(user.storeNo, cblInputText, [ublInputText], user.token);
    handleGoNextScreen();
  };
  const handleOnUblTextChange = (e) => {
    setUblInputText(e.target.value.replace(/[^0-9.]/g, ""));
    console.log(e.target.value);
    localStorage.setItem("UBLID", ublInputText);
  };
  const handleOnChange = (ubl_list, con) => {
    // Handle function for select all checkboxes
    let temp = ublIds;
    console.log(temp);
    console.log(ubl_list);
    let det_lis = [];
    let temp_ublids = [];

    if (con == "add") {
      setUblIds([...new Set(temp.concat(ubl_list))]);
      temp_ublids = [...new Set(temp.concat(ubl_list))];
    } else {
      if (temp.filter((a) => !ubl_list.includes(a)).length > 0) {
        setUblIds(temp.filter((a) => !ubl_list.includes(a)));
        temp_ublids = temp.filter((a) => !ubl_list.includes(a));
      } else setUblIds([]);
      console.log(temp.filter((a) => !ubl_list.includes(a)));
      temp_ublids = [];
    }

    setCart(ubl.filter((a) => temp_ublids.includes(a.UBL_ID)));
  };

  const handleChange = (e) => {
    let newArray = [...cart, e];
    const arrayWithFilterObjects = cart.filter((c) => c.UBL_ID === e.UBL_ID);

    if (arrayWithFilterObjects.length === 1) {
      newArray = newArray.filter((item) => item.UBL_ID !== e.UBL_ID);
    }

    setUblIds(newArray.map((ubl, index) => ubl.UBL_ID));
    setCart(newArray);
    setSelect(false);
  };

  const handleAddUbl = async (e) => {
    e.preventDefault();
    $("#add_option").removeClass("show");
    setLoader(true);
    for (let i = 0; i < cart.length; i++) {
      if (cart[i].UBL_ID == ublInputText) {
        setLoader(false);
        setmsg("Already in Use!");
        $("#errorModalCenter12").modal();
        setUblInputText("");
        return;
      }
    }
    UblService.addCublToUbl(
      user.storeNo,
      cblInputText,
      ublInputText,
      user.token
    )
      .then((response) => {
        if (
          response.addCublUbl.message ==
          "UBL Headers updated with CUBL and Ship details"
        )
          CblService.viewUBLSummary(user.storeNo, ublInputText, user.token)
            .then((response1) => {
              var temp_cart = cart;
              var temp_details = response1.UblSummaryDetails.details[0];
              temp_cart.push({
                ITEM_ID: temp_details.itemId,
                UBL_ID: temp_details.ublId,
                ITEM_DESCR: temp_details.itemDescription,
              });
              setCart(temp_cart);
              setUblInputText("");
              setLoader(false);
            })
            .catch((err) => {
              console.log(err);
              setLoader(false);
            });
        else {
          setUblInputText("");
          if (response.addCublUbl.message == "Detagged UBL")
            setmsg("UBL in detagged status ");
          else if (response.addCublUbl.message == "CUBL alreay mapped to UBL")
            setmsg("UBL already tagged to other CBL");
          else if (
            response.addCublUbl.message == "UBL already present in Shipment"
          )
            setmsg("UBL already tagged to shipment");
          else if (response.addCublUbl.message == "UBL already Dispatched")
            setmsg("UBL already Dispatched");
          else setmsg("UBL details not found");

          $("#errorModalCenter12").modal();
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };
  const handleAddCubl = async (e) => {
    e.preventDefault();
    setLoader(true);
    cblInputText
      ? await CblService.getCublHeader(user.storeNo, cblInputText, user.token)
          .then((response) => {
            if (
              response.CublDetails.message ==
              "CUBL Details not found for the storeId"
            ) {
              setCblNewInputText(cblInputText);
              setvalid(true);
              settoastmsg("Valid CBL ID ", setItemTaggingToast(true));
            } else {
              setvalid(false);
              setmsg("Already in Use!");
              $("#errorModalCenter12").modal();
            }
          })
          .catch((err) => console.log(err))
      : setBarcodeValidation(false);
    //setEdit(false);
    setLoader(false);
  };
  const handleCancel = () => {
    setSelect(false);
    setUblIds([]);
    setCart([]);
  };
  const handleCreateCubl = async (e) => {
    e.preventDefault();
    if (valid) {
      setLoader(true);

      CblService.addCublHeader(
        user.storeNo,
        cblInputText,
        dcno,
        dcname,
        cart.length,
        user.token,
        user.displayName
      )
        .then((res) => {
          console.log(res);
          console.log(ublIds);
          if (res.cublHeader.message == "CUBL Headers created Successfully") {
            UblService.mapCublToUbl(
              user.storeNo,
              cblInputText,
              ublIds,
              user.token
            )
              .then((res) => {
                if (
                  res.updateUblHeader.message ==
                  "UBL Headers updated with CUBL and Ship details"
                ) {
                  handleGoNextScreen();
                }
                setLoader(false);
              })
              .catch((err) => console.log(err));
          } else {
            setLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  };
  const handleChangeText = (e) => {
    setCblInputText(e.target.value);
    setUblIds([]);
    setCart([]);
    setSelect(false);
    var inputBarcode = e.target.value.toString().trim();
    setCblInputText(inputBarcode.replace(/[^0-9.]/g, ""));
    setvalid(false);
    if (
      inputBarcode.length === 10 &&
      !isNaN(inputBarcode) &&
      inputBarcode.substr(0, 3) === ("00" + user.storeNo).slice(-3) &&
      inputBarcode.substr(3, 7) > 0
    ) {
      console.log("valid");
      setBarcodeValidation(true);
    } else if (inputBarcode.length == 0) {
      setBarcodeValidation(true);
    } else {
      setBarcodeValidation(false);
    }
    localStorage.setItem("CUBLID", cblInputText);
  };
  const handleGoNextScreen = () => {
    setUblInputText("");
    if (pageno == 3) return;
    setpageno((pageno) => pageno + 1);
    setCurrent((current) => current + 1);
  };
  const handleGoHomeScreen = async () => {
    setCurrent((current) => current - 2);
    setpageno(1);
    await loadCblData(user.storeNo, user.token);
  };
  const handleGoPreviousScreen = () => {
    if (pageno == 1) return;
    setpageno((pageno) => pageno - 1);
    setCurrent((current) => current - 1);
  };
  const selected = (crumb) => {
    console.log(crumb);
  };

  const onClearOrderId = () => {
    setState({ orderId: "" });
  };

  const handleChangeCarrierRef = (event) => {
    setState({ CarrierRef: event.target.value });
  };

  console.log("CONNNN", state.details);
  const renderData = (ubl) => {
    return <div>Length:{ubl.length}</div>;
  };
  const cancel = async (showtoast) => {
    setLoader(true);
    message = {};
    await loadCblData(user.storeNo, user.token);
    if (showtoast) {
      settoastmsg("CBL closed Successfully", setItemTaggingToast(true));
    }
    setCblInputText("");
    setUblInputText("");
    setBarcodeValidation(true);
    setpageno(1);
    handleCancel();
    setvalid(false);
    setLoader(false);
  };
  const clearcbl = () => {
    setCblInputText("");
    setUblInputText("");
    setBarcodeValidation(true);
    setpageno(1);
    handleCancel();
    setvalid(false);
    setLoader(false);
    localStorage.setItem("CUBLID", cblInputText);
  };

  if (typeof window != "undefined") {
    console.log("listener", localStorage.getItem("access_token"));
    window.addEventListener("storage", function (e) {
      if (localStorage.getItem("access_token") == "null") {
        callLogoutURL();
        return;
      }
    });
  }

  useEffect(() => {
    if (localStorage.getItem("access_token") == "null") {
      callLogoutURL();
      return;
    }
    window.addEventListener("scroll", handleOnActive);
    return () => window.removeEventListener("scroll", handleOnAction);
  }, []);

  const handleOnIdle = (event) => {
    console.log("user is idle", event);
    console.log("last active", getLastActiveTime());
    callLogoutURL();
  };

  const handleOnActive = (event) => {
    console.log("user is active", event);
    console.log("time remaining", getRemainingTime());
    handleReset();
    if (localStorage.getItem("access_token") == "null") {
      callLogoutURL();
      return;
    }
  };

  const handleOnAction = (event) => {
    console.log("user did something", event);
    if (localStorage.getItem("access_token") == "null") {
      callLogoutURL();
      return;
    }
  };
  const { getRemainingTime, getLastActiveTime, reset } = useIdleTimer({
    timeout: timeoutDuration,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });
  const handleReset = () => reset();

  return (
    <div className="OrderLookup bg-grey" id="shipment_component">
      <Header />

      <div className="container-fluid">
        <div className="row">
          <Sidebar active="CreateCbl" state={user} />
          <main
            role="main"
            className="col-md-9 ml-sm-auto col-lg-10 rtn-main-wrapper"
          >
            {/* page title */}
            <section className="rtn-page-title mb-2">
              <div className="d-flex row bd-highlight ">
                <div className="bd-highlight  col-xs-12 col-md-6 modal-dialog-centered">
                  <h4>New CBL</h4>
                </div>

                <div className="col-xs-12 col-md-6 bd-highlight home-bc-padding">
                  {/* {<Breadcrumb crumbs={crumbs} selected={selected} />} */}
                  <nav
                    aria-label="breadcrumb"
                    style={{
                      marginRight: "15px",
                    }}
                  >
                    <ol className="breadcrumb">
                      <Link
                        to={{ pathname: "/LandingPage", state: user }}
                        className="breadcrumb-item breadcrumb-color pr-10"
                      >
                        Home
                      </Link>

                      <span className="pr-10"> {" >"}</span>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Create CBL
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </section>
            <section className="container-fluid section-three">
              <div className="row mrt14">
                <div class="col-md-2" id="sideprogress">
                  <div class="card1">
                    <ul id="progressbar" class="text-center ">
                      <li class="active step0 step1"></li>
                      <li
                        class={
                          pageno > 1 ? "step0 step2 active" : "step0 step2"
                        }
                      ></li>
                      <li
                        class={
                          pageno > 2 ? "step0 step3 active" : "step0 step3"
                        }
                      ></li>
                    </ul>
                    <div class="mb21">
                      <div class="row">
                        <div class="col-md-1 progressIcon">
                          <img
                            src="./images/create-icon.svg"
                            style={{ height: "65%", marginTop: "-2px" }}
                            alt=""
                          />
                        </div>
                        <div class="col icon_div">
                          <h6 class="fs12 zero">New CBL</h6>
                          <h6 class="fs10 gray">Enter/Scan CBL</h6>
                        </div>
                      </div>
                    </div>
                    <div class="mb21">
                      <div class="row">
                        <div class="col-md-1 progressIcon">
                          <img
                            src="./images/location-icon.svg"
                            style={{ height: "65%", marginTop: "-2px" }}
                            alt=""
                          />
                        </div>
                        <div class="col icon_div">
                          <h6 class="fs12 zero">Destination </h6>
                          <h6 class="fs10 gray">Select Destination</h6>
                        </div>
                      </div>
                    </div>
                    <div class="mb21">
                      <div class="row">
                        <div class="col-md-1 progressIcon">
                          <img
                            src="./images/view-summary-icon.svg"
                            style={{ height: "65%", marginTop: "-2px" }}
                            alt=""
                          />
                        </div>
                        <div class="col icon_div">
                          <h6 class="fs12 zero">View Summary </h6>
                          <h6 class="fs10 gray">Item Details</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {pageno === 1 ? (
                  <>
                    <NewCblPage
                      handleAddCubl={handleAddCubl}
                      handleCancel={handleCancel}
                      handleCreateCubl={handleCreateCubl}
                      cbl={message}
                      cblInputText={cblInputText}
                      cblNewInputText={cblNewInputText}
                      Address={address}
                      barcodeValid={barcodeValid}
                      ubl={message2}
                      details={ubl}
                      cart={cart}
                      pending={pending}
                      ublIds={ublIds}
                      error={error}
                      viewUbl={viewUbl}
                      handleGoNextScreen={handleGoNextScreen}
                      handleChange={handleChange}
                      handleOnChange={handleOnChange}
                      handleViewUblSummary={handleViewUblSummary}
                      select={select}
                      handleChangeText={handleChangeText}
                      cblInputText={cblInputText}
                      cancel={() => {
                        setCblInputText("");
                        setUblInputText("");
                        setBarcodeValidation(true);
                        setpageno(1);
                        handleCancel();
                        setvalid(false);
                      }}
                      valid={valid}
                      clearcbl={clearcbl}
                    />
                  </>
                ) : pageno === 2 ? (
                  <>
                    <Destination
                      dcname={dcname}
                      handleGoNextScreen={handleGoNextScreen}
                      handleRemoveItem={handleRemoveItem}
                      handleOnUblTextChange={handleOnUblTextChange}
                      handleAddUbl={handleAddUbl}
                      handleOnUblTextChange={handleOnUblTextChange}
                      handleGoPreviousScreen={handleGoPreviousScreen}
                      handleViewUblSummary={handleViewUblSummary}
                      cblInputText={cblInputText}
                      ublInputText={ublInputText}
                      Address={address}
                      viewUbl={viewUbl}
                      props={user}
                      cart={cart}
                      cbl={cbl}
                      message={message}
                      message2={message2}
                      cancel={cancel}
                    />
                  </>
                ) : (
                  <>
                    <ViewSummary
                      cart={cart}
                      cbl={cbl}
                      message={message}
                      handleRemoveItem={handleRemoveItem}
                      handleOnUblTextChange={handleOnUblTextChange}
                      handleViewUblSummary={handleViewUblSummary}
                      handleGoHomeScreen={handleGoHomeScreen}
                      handleGoPreviousScreen={handleGoPreviousScreen}
                      handleAddUbl={handleAddUbl}
                      cblInputText={cblInputText}
                      ublInputText={ublInputText}
                      viewUbl={viewUbl}
                      message={message}
                      message2={message2}
                      cancel={cancel}
                      address={address}
                    />
                  </>
                )}
              </div>
              <div className="topCenter" hidden={!itemTaggingToast}>
                <Toast
                  id="toast"
                  show={itemTaggingToast}
                  onClose={() => setItemTaggingToast(false)}
                  delay={5000}
                  autohide
                >
                  <div className="toast__content">
                    <div>
                      <span class="vertical-toast-border"></span>
                      <div className="tickmark" style={{ height: "100%" }}>
                        <img
                          className="tickmark"
                          src="./images/tick-circle-icon.svg"
                          alt=""
                        />
                      </div>
                      <div className="pt-10">
                        <p className="toast__type">Success</p>
                        <p className="toast__message">{toastmsg}</p>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="close toast-close"
                          data-dismiss="toast"
                          aria-label="Close"
                          onClick={() => setItemTaggingToast(false)}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Toast>
              </div>
              {loader ? <LoadingIndicator /> : null}
            </section>
          </main>
        </div>
      </div>
      <div
        className="modal fade loginModal"
        id="errorModalCenter12"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-z"
          role="document"
        >
          <div className="modal-content modal-radius">
            <div className="modal-header error-popup-header-bg">
              <h5
                className="modal-title confirmation-style"
                id="errorModalCenterTitle"
              >
                Error
              </h5>
            </div>
            <div className="modal-body text-align-centre">
              <div>{msg} </div>
            </div>
            <div className="modal-footer bo-modalbtnftr bt-none">
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-yes"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*<div
        className="modal fade"
        id="errorModalCenter12"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header bg-light-black whitetext">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Alert
              </h5>
            </div>
            <div className="modal-body">
              <p className="text-center"> { msg} </p>
            </div>
            <div className="modal-footer border-none">
              <button
                type="button"
                className="btn bgred whitetext ctbtn "
                data-dismiss="modal"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>*/}
    </div>
  );
};
const mapStateToProps = (state, ownProps) => {
  return {
    ubl: state.ubl.ubl,
    ublData: state.ubl,
    cbl: state.cbl.cbl,
    viewUbl: state.ubl.viewUblSummary,
    message: state.cbl,
    message2: state.ubl,
  };
};
const mapDispatchToProps = {
  loadCblData,
  loadCblDestination,
  addCubl,
  getCubl,
  cblMapToUbl,
  deleteCubl,
  viewUblSummary,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsolidateDispatch);
