import Header from "components/common/Header";
import React, { useEffect, useState,useRef } from "react";
import $ from "jquery";
import Toast from "react-bootstrap/Toast";
import LoadingIndicator from "components/common/LoadingIndicator";
import * as constants from "../../components/constants";
import { Link } from "react-router-dom";
import Sidebar from "components/common/Sidebar";
import { callLogoutURL } from "components/Authentication/utilities";
import { useIdleTimer } from "react-idle-timer";
import moment from "moment";
import DcLocationService from "services/DcLocationService";
import DcreceptingService from "services/DcreceptingService";

const DCReceiptingUbl = () => {
  const user = JSON.parse(localStorage.getItem("User"));
  const [total, settotal] = useState("0");
  const [received, setreceived] = useState("0");
  const [pending, setpending] = useState("0");
  const [loader, setLoader] = useState(false);
  const [ublDetails, setUblDetails] = useState([]);
  const [toggleNoItemsFound, setToggleNoItemsFound] = useState(false);
  const [toggleUblResults, setToggleUblResults] = useState(false);
  const [toggleButtons, setToggleButtons] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  const [invalidUblNum, setInvalidUblNum] = useState(false);
  const [location, setlocation] = useState("");
  const [locationdisable, setlocationdisable] = useState(false);
  const [togglescreen, settogglescreen] = useState(true);
  const [shipid_list,setShipId_list]=useState([]);
  const [cubl_list,setcubl_list]=useState([]);
  const [DCrec,setDCrec]=useState("");
  const [shipmentmissed,setshipmentmissed]=useState("");
  const [disableublcbl,setdisableublcbl]=useState(false);
  const [viewubl,setviewubl]=useState({});
  const ubldetails_ref=useRef([]);
  const focus_filed_ref=useRef(false);
  const [validate_loc,setValidate_loc]=useState(true); 
  const [Valoc_text,setValoc_text]=useState("");
  const inputFocus2=useRef(); 
  const  inputFocus=useRef();
  const DCrect=useRef();
  useEffect(()=>{
    inputFocus.current.focus()
  },[]);


  const [input, setInput] = useState({
    ublId: "",
  });
  useEffect(()=>{
    if(ublDetails.length>0)
    setdisableublcbl(true);
    else
    setdisableublcbl(false);

  },[ublDetails]);
  const [timeoutDuration] = useState(
    localStorage.getItem("timeout") ? localStorage.getItem("timeout") : 1800000
  );
  const [errorMessageAlert, setErrorMessageAlert] = useState("");
  useEffect(() => {
    setLoader(true);
    DcreceptingService.searchorder(parseInt(user.storeNo), "U", user.token)
      .then((res) => {
        setLoader(false);
        if (
          res.searchOrder.message != "Couldn't find dispatch quantity details"
        ) {
          if (res.searchOrder.TOTAL_UBL_DISPATCH_QTY != "")
            setpending(res.searchOrder.TOTAL_UBL_DISPATCH_QTY);
          else setpending("0");

          setreceived(
            res.searchOrder.TOTAL_UBL_REC_QTY
              ? res.searchOrder.TOTAL_UBL_REC_QTY
              : 0
          );

          if (
            res.searchOrder.TOTAL_UBL_DISPATCH_QTY != "" &&
            res.searchOrder.TOTAL_UBL_REC_QTY != ""
          )
            settotal(
              res.searchOrder.TOTAL_UBL_REC_QTY
                ? (
                    parseInt(res.searchOrder.TOTAL_UBL_DISPATCH_QTY) +
                    parseInt(res.searchOrder.TOTAL_UBL_REC_QTY)
                  ).toString()
                : res.searchOrder.TOTAL_UBL_DISPATCH_QTY
            );
          else if (res.searchOrder.TOTAL_UBL_REC_QTY != "") {
            settotal(res.searchOrder.TOTAL_UBL_REC_QTY);
          } else if (res.searchOrder.TOTAL_UBL_DISPATCH_QTY != "")
            settotal(res.searchOrder.TOTAL_UBL_DISPATCH_QTY);
          else settotal("0");
        }
      })
      .catch((err) => {
        console.log(err);
       

        setLoader(false);
      });
  }, [togglescreen]);

  const handleOnchange = (e) => {
    const inputBarcode = e.target.value.replace(/[^0-9\+]/g, "");
    setInput({
      ...input,
      [e.target.name]: inputBarcode,
    });
    if (
      inputBarcode.length === 7 &&
      !isNaN(inputBarcode) &&

      inputBarcode> 0
    ) {
      setInvalidUblNum(false);
    } else {
      setInvalidUblNum(true);
    }
  };

  const errorModal = () => {
    setLoader(false);
    setToggleUblResults(false);
    setToggleNoItemsFound(false);
    setToggleButtons(false);
    $("#errorModal").modal();
  };

  const getUblSummary = async (isScanned, scannedData) => {
    try
    {
    if(!focus_filed_ref.current)
    {
      console.log("!focused");
      setInput({
        ...input,
        ublId: "",
      });
     
      return;
      
    }
 
  
    let inputBarcode = isScanned ? scannedData : input.ublId;
    if (
      inputBarcode.length === 7 &&
      !isNaN(inputBarcode) &&

      inputBarcode> 0
    ) {
      setInvalidUblNum(false);
      
    } else {
      setInvalidUblNum(true);
      return;
    }

  


    setLoader(true);
    const res = await fetch(
      process.env.REACT_APP_API_BASE_ENDPOINT +
        process.env.REACT_APP_STORE +
        parseInt(user.storeNo) +
        "/viewUblSummaryDetails?ublId=" +
        inputBarcode,
      {
        method: "GET",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          Authorization: user.token,
        },
      }
    );
    const data = await res.json();
    console.log(data);
    if (data.errorMessage === "Bad request") {
      setErrorMessageAlert("Invalid UBL");
      $("#errorModal").modal();
      setLoader(false);
      return;
    }
    if (data.UblSummaryDetails.message === "Ubl Summary Details fetched successfully") {
      console.log("GET SUCCESS");
      // var temp = ublDetails;
      // temp.push(data.UblSummaryDetails.details[0]);
      // setUblDetails(temp);
      // setLoader(false);
      // setToggleUblResults(true);
      // setToggleNoItemsFound(true);
      // setToggleButtons(true);

      const receiveOrderBody = data.UblSummaryDetails.details[0];
     

      if (
        receiveOrderBody.ublStatus === "D" ||
        
        receiveOrderBody.ublStatus === "M" 
      ) {
        const response = await fetch(
          process.env.REACT_APP_API_BASE_ENDPOINT +
            process.env.REACT_APP_STORE +
            parseInt(user.storeNo) +
            "/receiveUbl",
          {
            method: "PUT",
            headers: {
              "x-api-key": process.env.REACT_APP_API_KEY,
              Authorization: user.token,
            },
            body: JSON.stringify({
              ublId: [receiveOrderBody.ublId],
            cublId:receiveOrderBody.cublId?[receiveOrderBody.cublId]:[],
              employeeId: user.displayName,
              shipId: [receiveOrderBody.shipId],
              ublStatus: "R",
              recLoc:DCrect.current
            }),
          }
        );
        const receiveUblData = await response.json();
        console.log(receiveUblData);


        if (
          receiveUblData.errorMessage ===
            "The required input fields [ublStatus] is/are invalid" ||
          receiveUblData.errorMessage === "Bad request"
        ) {
          setInput({
            ...input,
            ublId: "",
          });
          setErrorMessageAlert("Invalid UBL");
          $("#errorModal").modal();
          setLoader(false);
          return;
        }
        if (
          receiveUblData.updateRecieveUbl.message ===
            "Receive UBL Order Details Could not be Updated" ||
          receiveUblData.updateRecieveUbl.message === "Ubl Id not found" ||
          receiveUblData.updateRecieveUbl.message ===
            "Invalid Shipment for receiving/shipment already received" ||
          receiveUblData.updateRecieveUbl.message === "Ship Details are invalid"
        ) {
          setInput({
            ...input,
            ublId: "",
          });
          setErrorMessageAlert("UBL order details could not be updated");
          $("#errorModal").modal();
          setLoader(false);
          return;
        } else {
          setInput({
            ...input,
            ublId: "",
          });
          $(".locField").addClass("disabletextbg");
          $(".inputLoc").attr("disabled", true);
          setSuccessToast(true);
          let temp=ubldetails_ref.current;
          temp=[data.UblSummaryDetails.details[0]].concat(temp);
          ubldetails_ref.current=temp;
          setUblDetails(temp);
          let shipd_temp=data.UblSummaryDetails.details[0].shipId;
          let cblid_temp=data.UblSummaryDetails.details[0].cublId;
         let temp_ship_list=shipid_list;
         let temp_cubl_list=cubl_list;
         temp_ship_list.push(shipd_temp);
         setShipId_list([...new Set(temp_ship_list)]);
         if(data.UblSummaryDetails.details[0].cublId && data.UblSummaryDetails.details[0].cublId!=" ")
         {
           temp_cubl_list.push(cblid_temp);
           setcubl_list([...new Set(temp_cubl_list)]);
         }



          setLoader(false);
          setToggleUblResults(true);
          setToggleNoItemsFound(true);
          setToggleButtons(true);
        }
      } else if (receiveOrderBody.ublStatus === "R" || receiveOrderBody.ublStatus === "RD" || receiveOrderBody.ublStatus === "SD" || receiveOrderBody.ublStatus === "DS") {
        console.log("ITEM RECEIVED");

        setErrorMessageAlert("UBL already received");
        $("#errorModal").modal();
        setLoader(false);
      } else if (receiveOrderBody.ublStatus === "X") {
        console.log("ITEM RECEIVED");

        setErrorMessageAlert("UBL in detagged Status");
        $("#errorModal").modal();
        setLoader(false);
      } else {
        console.log("ITEM NOT DISPATCHED");

        setErrorMessageAlert("UBL not yet dispatched");
        $("#errorModal").modal();
        setLoader(false);
        // setLoader(false);
        // setToggleUblResults(true);
        // setToggleNoItemsFound(true);
        // setToggleButtons(true);
      }
    } else if (
      data.UblSummaryDetails.message ===
      "Ubl Summary Details not found for the storeId"
    ) {
      setInput({
        ...input,
        ublId: "",
      });
      setErrorMessageAlert("UBL not found");
      $("#errorModal").modal();
      setLoader(false);
    }
  }
  catch(e)
  {
    setErrorMessageAlert("Internal Error");
    $("#errorModal").modal();
    setLoader(false);
    console.log(e);
  }
  };
  const viewsummary=(barcode_view)=>{
    setLoader(true);
    
    DcLocationService.getUblSummary(user.storeNo, barcode_view, user.token).then((response)=>{
      setLoader(false);
      setviewubl(response.UblSummaryDetails.details[0]);
      $("#collapseExample").modal();

    }).catch(err=>{console.log(err);setLoader(false);})
    
  }

  const getReceiveUblSuccess = async () => {
      setUblDetails([]);
      ubldetails_ref.current=[];
      setInput({
        ...input,
        ublId: "",
      });
      setLoader(false);
      setToggleUblResults(false);
      setToggleNoItemsFound(false);
      setToggleButtons(false);
      setlocation("");
      setlocationdisable(false);
      $(".locField").removeClass("disabletextbg");
      $(".inputLoc").attr("disabled", false);
      settogglescreen((prev) => !prev);
   
  };

  useEffect(() => {
    console.log("useeffect in ubl");
    if (input.ublId === "") setInvalidUblNum(true);
    window.addEventListener("getScannedDataInDevice", getScannedDataInDevice);
    return () => {
      window.removeEventListener(
        "getScannedDataInDevice",
        getScannedDataInDevice
      );
    };
  }, []);

  // const enableListener = () =>{
  //   window.addEventListener("getScannedDataInDevice", getScannedDataInDevice);
  // }
  // const disableListener = () =>{
  //   window.removeEventListener("getScannedDataInDevice", getScannedDataInDevice);
  // }

  const getScannedDataInDevice = (data) => {
    let inputBarcode = data.detail.toString().trim();
    console.log("useeffect in ubl", inputBarcode);
    if(focus_filed_ref.current)
    {

    console.log("useeffect in ubl", input.ublId);
    if (
      inputBarcode.length === 7 &&
      !isNaN(inputBarcode) &&
      inputBarcode>0
    ) {
      setInvalidUblNum(false);
      getUblSummary(true, inputBarcode);
    } else {
      setInvalidUblNum(true);
    }
  }
  };

  React.useEffect(() => {
    $(document).click(function (e) {
      if (!$(e.target).is(".panel-body")) {
        $(".collapse").collapse("hide");
      }
    });
  });
  const getsupplierdetails = async () =>{
    if(location)
  {
    setDCrec(location);
    DCrect.current=location;
    setLoader(true);
    const res = await fetch(
      process.env.REACT_APP_API_BASE_ENDPOINT +
        process.env.REACT_APP_DC +
        location +
        "/dcLocValid",
      {
        method: "GET",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          Authorization: user.token,
        },
      }
    );
    const data = await res.json();
    const temp =
      data.dc_loc_valid && data.dc_loc_valid.supplierDesc
        ? data.dc_loc_valid.supplierDesc.toString().trim()
        : "";
       
    setlocation(location + (temp && temp != "" ? "-" + temp : ""));
    
    setLoader(false);
    setlocationdisable(true);
    inputFocus2.current.focus()
  }
};
  const handleOnIdle = (event) => {
    console.log("user is idle", event);
    console.log("last active", getLastActiveTime());
    callLogoutURL();
  };
  const handleOnActive = (event) => {
    console.log("user is active", event);
    console.log("time remaining", getRemainingTime());
    handleReset();
    if(localStorage.getItem('access_token') == "null"){
      callLogoutURL()
      return
    }
  };

  const handleOnAction = (event) => {
    // console.log("user did something", event);
    if(localStorage.getItem('access_token') == "null"){
      callLogoutURL()
      return
    }
  };
  const { getRemainingTime, getLastActiveTime, reset } = useIdleTimer({
    timeout: timeoutDuration,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });
  const handleReset = () => reset();

  return (
    <div id="DCReceipting">
      <Header />
      <div className="container-fluid">
        <div className="row">
          <Sidebar active="DCReceipting" state={user} />
          <main
            role="main"
            className="col-md-9 ml-sm-auto col-lg-10 rtn-main-wrapper"
            style={{ padding: "0px" }}
          >
            <section className="dcreceipting-header">
              <p>DC RECEIPTING</p>
              <div 
               class="d-none d-lg-block d-md-block"
              style={{
                float:"right",
                marginTop:"-40px"
              }}>
                  {/* {<Breadcrumb crumbs={crumbs} selected={selected} />} */}
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb" style={{fontSize:"12px"}}>
                    <Link
                        to={{ pathname: "/LandingPage", state: user }}
                        className="breadcrumb-item breadcrumb-color pr-10"
                      >
                        Home
                      </Link>
                      <span className="pr-10"> {" >"}</span>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                       DC Receipting
                      </li>
                    </ol>
                  </nav>
                </div>
            </section>

            {/* UBL selected by default */}
            <div className="row">
              <section className="dcr-fields col-md-8 col-10">
                <div className="dcr-input-group">
                  <form
                    // className={
                    //   locationdisable
                    //     ? "dcr-input-icon-container disabletextbg"
                    //     : "dcr-input-icon-container"
                    // }
                    className="dcr-input-icon-container locField"
                    onSubmit={(e) => {
                      e.preventDefault();
                      var letterNumber = /^[0-9a-zA-Z]*$/;
                     if(letterNumber.test(location))
                   if(location.length<=7)
                      getsupplierdetails();
                     

                    }}
                  >
                    <i className="fas fa-map-marker-alt ml-10"></i>
                    <input
                      className="inputLoc"
                      type="text"
                      value={location}
                      //disabled={locationdisable}
                      autocomplete="off"
                      placeholder="Scan or Enter Location"
                      name="location"
                     
                      ref={inputFocus}
                      onChange={(e) => {setlocation(e.target.value);  
                      setlocationdisable(false);
                      var letterNumber = /^[0-9a-zA-Z]*$/;
                     if(letterNumber.test(e.target.value))
                   { if(e.target.value.length>7)
                    {
                      setValidate_loc(false);
                      setValoc_text("Receive location entered exceed maximum length(7)");
                    }
                    else
                    setValidate_loc(true);
                   }
                   else
                   {
                    setValoc_text("Invalid Location!");
                    setValidate_loc(false);

                   }
                    }}
                    />
                    <img
                      class="dcr-dispatch-scanner-img"
                      src="./images/barcodescanner.png"
                      alt=""
                    />
                  </form>
                  <div className="invalid-ubl-num" 
                  style={{fontSize:"11px"}}
                  hidden={validate_loc}
                 >
                        <img src="./images/error-icon.svg" alt="" />
                       {Valoc_text}
                      </div>
                </div>
               
                  
                <div className="dcr-options">
                  <ul>
                    <li className="active">UBL</li>
                    <li>
                      <Link
                        style={{ textDecoration: "none", color: "black" }}
                        class={toggleUblResults?"pointernon":""}
                        to="/DCReceiptingCbl"
                      >
                        CBL
                      </Link>
                    </li>
                    <li>
                      <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to="/DCReceiptingManifest"
                       class={toggleUblResults?"pointernon":""}
                      >
                        Manifest
                      </Link>
                    </li>
                  </ul>
                </div>
                <div>
                  <p>
                    <strong>Scan/Enter UBL</strong>
                  </p>
                  <div className="dcr-input-group">
                    <form
                      className={
                        !locationdisable
                          ? "dcr-input-icon-container disabletextbg"
                          : "dcr-input-icon-container"
                      }
                      onSubmit={(e) =>{e.preventDefault();getUblSummary(false, "")}}
                    >
                    
                      <input
                        type="text"
                        placeholder="Scan/Enter UBL"
                        autocomplete="off"
                        name="ublId"
                        disabled={!locationdisable}
                        value={input.ublId}
                        onChange={(e)=>{
                          const inputBarcode = e.target.value.replace(/[^0-9\+]/g, "");
                          setInput({
                            ...input,
                            [e.target.name]: inputBarcode,
                          });
                          setInvalidUblNum(false);
                        }}
                       
                        onFocus={(e)=>
                          {
                            setInvalidUblNum(false);
                            focus_filed_ref.current=true;

                          }
                        }
                        onBlur={(e)=>{
                          focus_filed_ref.current=false;
                          handleOnchange(e);
                        }}
                        ref={inputFocus2}
                      />
                      <img
                        class="dcr-dispatch-scanner-img"
                        src="./images/barcodescanner.png"
                        alt=""
                      />
                      
                      
                    </form>
                    {invalidUblNum && input.ublId !== "" ? (
                      <div className="invalid-ubl-num">
                        <img src="./images/error-icon.svg" alt="" />
                        Invalid UBL!
                      </div>
                    ) : null}
                  </div>
                </div>
              </section>
            </div>

            {/* Scanned results for UBL */}
            {toggleUblResults ? (
              <section className="scanned-ubl-results">
                <div className="sur-header">
                  <p>Scanned results for UBL</p>
                  {/* <div>
              <input
                type="checkbox"
                onClick={onCheckboxClick}
                checked={checked}
              />
              <label>Select All</label>
            </div> */}
                </div>
                {ublDetails.map((detail, detailIdx) => (
                  <div className="sur-list" key={detailIdx}>
                    <div>
                      <span>Item #: </span>
                      <span>{detail.itemId}</span>
                      <span>
                        {/*<input
                    type="checkbox"
                    onClick={onCheckboxClick}
                    checked={checked}
                  />*/}
                      </span>

                      <p>{detail.itemDescription}</p>
                      <p>{detail.supplierNo} | {detail.supplierName}</p>
                    </div>
                    <div className="sur-bc-view">
                      <p className="barcode-font" style={{ fontSize: "27px" }}>
                        {detail.ublId}
                      </p>
                      <a
                        data-toggle="collapse"
                        href={"#collapseExample" + detail.ublId}
                        role="button"
                        aria-expanded="false"
                        data-placement="right"
                        aria-controls="collapseExample"
                        onClick={() => {
                          viewsummary(detail.ublId)
                        }}
                      >
                        <i class="fas fa-eye"></i>View
                      </a>
                    </div>
                  </div>
                ))}
              </section>
            ) : null}

            {/* No items found message */}
            {!toggleNoItemsFound ? (
              <div className="dcru-no-items-found pl-0 pr-0 pt-2 bg-white">
                <section
                  className="trp-container manifest_check"
                  style={{ fontSize: "15px" }}
                >
                  <div className="count_container">
                    <p className="count_container_val">Total</p>
                    <p className="p10 text-dark">{total}</p>
                  </div>
                  <div className="count_container">
                    <p className="count_container_val">Received</p>
                    <p className="p10 text-dark">{received}</p>
                  </div>
                  <div className="count_container">
                    <p className="count_container_val">Pending</p>
                    <p className="p10 text-dark">{pending}</p>
                  </div>
                </section>
                <img
                  src="./images/Item-not-found.svg"
                  className="pt-3"
                  alt=""
                />
                <p>No items found</p>
              </div>
            ) : null}

            {/* Back and Complete Receiving buttons */}
            {toggleButtons ? (
              <section className="dcru-buttons-ubl">
                {/* <button type="button" data-toggle="modal" data-target="#backModal">
            Back
          </button> */}
                {/* <button
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModal1"
                  className="back_button"
                >
                  Back
                </button> */}

                <button
                  type="button"
                  onClick={getReceiveUblSuccess}
                  className="receive-ubl"
                >
                 Exit
                </button>
              </section>
            ) : null}

            {/* View Details */}
            {toggleUblResults ? (
              <>
                
                  <div
                    class="modal summary-modal right fade"
                    id="collapseExample"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                  >
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h4 class="modal-title" id="myModalLabel">
                            View Summary
                          </h4>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>

                        <div class="modal-body">
                          <div class="d-flex bd-highlight">
                            <div class="bd-highlight item-num">
                              Item #: <span>{viewubl.itemId}</span>
                            </div>

                            <div class="ml-auto view-det-link bd-highlight">
                              <div class="barcode-font">{viewubl.ublId}</div>
                            </div>
                          </div>

                          <div class=" item-name mb-3">
                            {" "}
                            {viewubl.itemDescription}{" "}
                          </div>

                          <div class="card">
                            <div class="card-header">
                              <div className="row">
                                <div className="col-md-6 col-xs-12">
                                  <h6>UBL:{viewubl.ublId}</h6>
                                </div>
                                <div className="col-md-6 col-xs-12">
                                  <h6></h6>
                                </div>
                              </div>
                            </div>
                            <div class="card-body">
                              <div className="row">
                              <div className="col-md-6 col-xs-12 mb-3">
                                  <h6>Customer Order#</h6>
                                  <span>{viewubl.saleOrderNo}</span>
                                </div>
                                <div className="col-md-6 col-xs-12 mb-3">
                                  <h6> Quantity</h6>
                                  <span>{viewubl.qty}</span>
                                </div>
                                <div className="col-md-6 col-xs-12 mb-3">
                                  <h6>Supplier PO#</h6>
                                  <span>{viewubl.supplierPo}</span>
                                </div>
                                <div className="col-md-6 col-xs-12 mb-3">
                                  <h6>Return Location</h6>
                                  <span>{('000'+viewubl.storeId).substr(-3)}-{viewubl.returnLocation}</span>
                                </div>
                                <div className="col-md-6 col-xs-12 mb-3">
                                  <h6>Supplier </h6>
                                  <span>{viewubl.supplierNo} | {viewubl.supplierName}</span><br/>
                                  {viewubl.addLine1?<span
                                  style={{
                                    fontSize:"11px",
                                    display:"inherit",
                                    marginTop:"5px"
                                  }}> <img
                          src="./images/location-icon.svg"
                          alt=""    
                          style={{height:"15px",
                          marginRight:"4px",
                          float:"left",}}
                        />
                        <p style={{
                          
                          display:"inline"

                        }}>{viewubl.addLine1+","+(viewubl.addLine2?(viewubl.addLine2+","):"")+(viewubl.addLine3?(viewubl.addLine3+","):"")+viewubl.city+","+viewubl.state+"-"+viewubl.postCode}</p>
                        </span>:null}
                                </div>
                                <div className="col-md-6 col-xs-12 mb-3">
                                  <h6>Return Reason</h6>
                                  <span>{viewubl.returnReason}</span>
                                </div>
                                <div className="col-md-6 col-xs-12 mb-3">
                                  <h6>Brand</h6>
                                  <span>{viewubl.brand}</span>
                                </div>
                               <div className="col-md-6 col-xs-12 mb-3">
                                  <h6>Return Order#</h6>
                                  <span>{viewubl.returnOrderNo}</span>
                                </div>
                                <div className="col-md-6 col-xs-12 ">
                                  <h6>Status</h6>
                                  <span>{"Received at DC"}|{user.storeName} ,{ viewubl.updatedTimestamp?
                                        (moment.utc(viewubl.updatedTimestamp).format("DD-MM-YYYY | hh:mm A")):null}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
               
              </>
            ) : null}

            {/* Success Modal */}
            <div className="topCenter">
              <Toast
                id="toast"
                show={successToast}
                onClose={() => setSuccessToast(false)}
                delay={3000}
                autohide
              >
                <div className="toast__content pt-12">
                  <div className="tickmark h-100">
                    <img
                      className="tickmark mt-5"
                      src="./images/tick-circle-icon.svg"
                      alt=""
                    />
                  </div>
                  <div>
                    <p className="toast__type">Success</p>
                    <p className="toast__message">
                      UBL Received successfully
                    </p>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="close toast-close"
                      data-dismiss="toast"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
              </Toast>
            </div>

            {/* Error Modal */}


            {/* Confirmation UBL Modal */}
            <div
              className="modal fade"
              id="errorModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content dcr-modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Alert
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body dcr-modal-alert">
                    <i className="fas fa-exclamation-triangle"></i>
                    {errorMessageAlert}
                  </div>
                  <div className="dcr-modal-alert-button">
                    <button
                      type="button"
                      className="btn btn-secondary text-decoration-none"
                      data-dismiss="modal"
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
        className="modal fade loginModal"
        id="errorModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-z"
          role="document"
        >
          <div className="modal-content modal-radius">
            <div className="modal-header error-popup-header-bg">
              <h5
                className="modal-title confirmation-style"
                id="errorModalCenterTitle"
              >
                Error
              </h5>
            </div>
            <div className="modal-body text-align-centre">
            <i className="fas fa-exclamation-triangle"></i>
                    {errorMessageAlert}
            </div>
            <div className="modal-footer bo-modalbtnftr bt-none justify-content-center">
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-yes"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
            <div
              className="modal fade loginModal"
              id="exampleModal1"
              tabindex="-1"
              role="dialog"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-z"
                role="document"
              >
                <div className="modal-content modal-radius">
                  <div className="modal-header confirmation-header">
                    <h5 className="modal-title confirmation-style">
                      Confirmation
                    </h5>
                  </div>
                  <div className="modal-body text-align-centre font-weight-bold">
                    Receive UBL(s) not completed! <br /> Are you sure you want
                    to click back?
                  </div>
                  <div className="modal-footer bo-modalbtnftr bt-none justify-content-center justify-content-md-end">
                    <button
                      type="button"
                      className="text-dark text-decoration-none 
                     btn bo-btnprimary 
                     confirm-no
                     border border-secondary"
                      data-dismiss="modal"
                      style={{ width: "45%" }}
                    >
                      No
                    </button>

                    <button
                      data-dismiss="modal"
                      type="button"
                      className=" btn bo-btnprimary confirm-yes"
                      onClick={() => {
                        setUblDetails([]);
                        ubldetails_ref.current=[];
                        
                        setInput({
                          ...input,
                          ublId: "",
                        });
                        setLoader(false);
                        setToggleUblResults(false);
                        setToggleNoItemsFound(false);
                        setToggleButtons(false);
                        setlocation("");
                        setlocationdisable(false);
                        $(".locField").removeClass("disabletextbg");
      $(".inputLoc").attr("disabled", false);
                        settogglescreen((prev) => !prev);
                      }}
                      style={{ width: "45%" }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade loginModal"
              id="CompleteModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="errorModalCenterTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-z"
                role="document"
              >
                <div className="modal-content modal-radius">
                  <div className="modal-header confirmation-header">
                    <h5
                      className="modal-title confirmation-style"
                      id="errorModalCenterTitle"
                    >
                      Confirmation
                    </h5>
                  </div>
                  <div className="modal-body text-align-centre font-weight-bold">
                    <div>
                      {" "}
                      Receive UBL(s) not completed for shipment# {
                        shipmentmissed
                      } <br />
                      Do you want to continue to receive UBL?
                    </div>
                  </div>
                  <div className="modal-footer bo-modalbtnftr bt-none justify-content-center justify-content-md-end">
                    <button
                      style={{ width: "45%" }}
                      className="text-dark text-decoration-none 
                       btn bo-btnprimary 
                       confirm-no
                       border border-secondary"
                      data-dismiss="modal"
                      onClick={() => {
                        setUblDetails([]);
                        ubldetails_ref.current=[];
                        setInput({
                          ...input,
                          ublId: "",
                        });
                        setLoader(false);
                        setToggleUblResults(false);
                        setToggleNoItemsFound(false);
                        setToggleButtons(false);
                        setlocation("");
                        setlocationdisable(false);
                        $(".locField").removeClass("disabletextbg");
      $(".inputLoc").attr("disabled", false);
                        settogglescreen((prev) => !prev);
                      }}
                    >
                      No
                    </button>
                    <button
                      style={{ width: "45%" }}
                      data-dismiss="modal"
                      type="button"
                      className=" btn bo-btnprimary confirm-yes"
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {loader ? <LoadingIndicator /> : null}
          </main>
        </div>
      </div>
    </div>
  );
};

export default DCReceiptingUbl;
