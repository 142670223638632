import Checkbox from "components/common/Checkbox";
import Header from "components/common/Header";
import LoadingIndicator from "components/common/LoadingIndicator";
import Sidebar from "components/common/Sidebar";
import { addDays, subMonths } from "date-fns";
import dateFormat from "dateformat";
import $ from "jquery";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Toast } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useIdleTimer } from "react-idle-timer";
import { MultiSelect } from "react-multi-select-component";
import { Link } from "react-router-dom";
import SearchService from "services/SearchService";
import UblService from "services/UblService";
import { callLogoutURL } from "../Authentication/utilities";
import Search from "./Search";

const OrderLookup = (props) => {
  // console.log("STORE NO", props.location.state.storeNo);
  const [filteredObject, setFilteredObject] = useState({});
  const [showerrmanifest, setshowerrmanifest] = useState(false);
  const [showerrDCmanifest, setshowerrDCmanifest] = useState(false);
  const [showerrcbl, setshowerrcbl] = useState(false);
  const [showerrubl, setshowerrubl] = useState(false);
  const user = JSON.parse(localStorage.getItem("User"));
  const [statusselectedvalues, setstatusselectedvalues] = useState([]);
  const searchservice = new SearchService();
  const [loader, setLoader] = useState(false);
  const [Invalidreturn, setInvalidreturn] = useState(false);
  const [crumbs, setCrumbs] = useState(["Home", "Return Order Lookup"]);
  const [isAdvanceSearchDisabled, setIsAdvanceSearchDisabled] = useState(false);
  const numberOnlyRegex = /^[0-9\b]+$/;
  const alphaNumericRegex = /[^0-9a-zA-Z]/;
  const dateRegex = /^\d{2}([/])\d{2}\1\d{4}$/;
  const [toastmsg, settoastmsg] = useState("UBL Headers created Successfully");
  const [itemTaggingToast, setItemTaggingToast] = useState(false);
  const [dateFromValidation, setDateFromValidation] = useState({
    isDateFromValid: true,
    errorMessage: "",
  });
  const [returnDateFromValidation, setReturnDateFromValidation] = useState({
    isReturnDateFromValid: true,
    errorMessage: "",
  });
  const [defaultreturnlocation_1, setdefaultreturnlocation_1] = useState([]);
  const [itemSupplierValidation, setItemSupplierValidation] = useState({
    isSupplierPOValid: true,
    isItemIDValid: true,
  });
  const periodlabel = useRef();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 600;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  const [selectsupplier, setselectsupplier] = useState([]);
  const [myerMarketId, setmyerMarketId] = useState([]);
  const [defaultmsg, setdefaultmsg] = useState("All items are selected.");

  const [trackingValidation, setTrackingValidations] = useState({
    isUBLValid: true,
    isCBLValid: true,
    isCarrierRefValid: true,
    isStoreManifestValid: true,
    isDCManifestValid: true,
    isDCPutawayLocationValid: true,
  });
  useEffect(() => {
    if (localStorage.getItem("access_token") == "null") {
      callLogoutURL();
      return;
    }
    document.getElementById("date").setAttribute("autocomplete", "off");
  }, []);

  const [isLocationSearchLoading, setIsLocationSearchLoading] = useState(false);

  const [returnChannelselected, setreturnChannelselected] = useState([]);

  const [returnLocationSelectedValues, setReturnLocationSelectedValues] =
    useState([]);
  const [returnreasonselected, setreturnreasonselected] = useState([]);
  const [detagged, setdetagged] = useState([]);

  const [supplier, setSupplier] = useState([]);

  const [filterFields, setFilterFields] = useState([]);
  const [isSelectListEmpty, setIsSelectListEmpty] = useState(true);
  const [supplierSelectedValues, setSupplierSelectedValues] = useState([]);
  const [classgrpselectedvalue, setclassgrpselectedvalue] = useState([]);
  const [isROValid, setIsROValid] = useState(true);
  const [customerValidation, setCustomerValidation] = useState({
    isPhoneValid: true,
    isSalesOrderNumValid: true,
  });
  const [state1, setState1] = useState({
    token: user.token,
    userName: user.userName,
    storeNo: user.storeNo,
    role: user.role,
    storeName: user.storeName,
    displayName: user.displayName,
    menuAllowed: user.menuAllowed,
    tempSearchMenu: user.tempSearchMenu,
    tempCreateDispatchInStoreMenu: user.tempCreateDispatchInStoreMenu,
    tempCreateConsolidatedDispatchMenu: user.tempCreateConsolidatedDispatchMenu,
    tempCreateShipment: user.tempCreateShipment,
    loggedInTime: user.loggedInTime,
  });
  //const [startDate, setStartDate] = useState('')
  //const [endDate, setEndDate] = useState(new Date())
  const [timeoutDuration] = useState(
    localStorage.getItem("timeout") ? localStorage.getItem("timeout") : 1800000
  );
  const [expandAllSelect, setExpandAllSelect] = useState(false);

  const [searchAttributes, setSearchAttributes] = useState({
    errorMsgPopup: "",
    carrierRef: "",
    orderId: "",
    flaglist: false,
    salesOrderNo: "",
    returnOrderNumber: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    supplierPo: "",
    itemId: "",
    dateFrom: new Date().setDate(new Date().getDate() - 29),
    dateTo: new Date(),
    brand: "",
    supplier: "",
    isValid: true,
    sort: false,
    CreatedBy: "",
    search: false,
    AdvanceSearch: false,
    details: [],
    SearchReturnDetails: [],
    AdvanceDetails: [],
    showLoader: false,
    ubl: "",
    classGroup: [],
    status: [],
    myerMarketId: [],
    supplierNames: [],
    cbl: "",
    storeManifest: "",
    dcManifest: "",
    dcPutawayLocation: "",
    returnLocation: [
      JSON.parse(localStorage.getItem("User")).storeNo +
        "=" +
        JSON.parse(localStorage.getItem("User")).storeName,
    ],
    returnCreatedBy: "",
    returnReason: "",
    returnChannel: "",
    detagReason: "",
  });
  if (typeof window != "undefined") {
    console.log("listener", localStorage.getItem("access_token"));
    window.addEventListener("storage", function (e) {
      if (localStorage.getItem("access_token") == "null") {
        callLogoutURL();
        return;
      }
    });
  }
  console.log("ORDERLOOKUP", state1);
  const navigateToLandingPage = () => {
    props.history.push("/LandingPage");
  };
  const inputFocus = useRef();
  useEffect(() => {
    inputFocus.current.focus();
  }, []);

  const selected = (crumb) => {
    console.log(crumb);
    navigateToLandingPage();
  };
  const logout = () => {
    callLogoutURL();
  };
  const onClearOrderId = () => {
    setInvalidreturn(false);
    setSearchAttributes({ ...searchAttributes, orderId: "" });
    console.log("eeeeeeeeeeeeeeeeeee", searchAttributes.details.length);
  };

  const formatRetailPrice = (price, decPlaces, thouSeparator, decSeparator) => {
    var n = price,
      decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces,
      decSeparator = decSeparator == undefined ? "." : decSeparator,
      thouSeparator = thouSeparator == undefined ? "," : thouSeparator,
      sign = n < 0 ? "-" : "",
      i = parseInt((n = Math.abs(+n || 0).toFixed(decPlaces))) + "",
      j = (j = i.length) > 3 ? j % 3 : 0;
    return (
      sign +
      (j ? i.substr(0, j) + thouSeparator : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thouSeparator) +
      (decPlaces
        ? decSeparator +
          Math.abs(n - i)
            .toFixed(decPlaces)
            .slice(2)
        : "")
    );
  };

  // if(statusselectedvalues.length >1){
  //   var temp = statusselectedvalues.slice(1)
  //   setstatusselectedvalues(temp)
  // }

  const onSearch = (event) => {
    console.log(
      "onsearch",
      state1.token,
      searchAttributes.orderId,
      state1.storeNo,
      state1.displayName
    );

    event.preventDefault();
    setSearchAttributes({ ...searchAttributes, search: false, details: [] });
    setLoader(true);
    if (searchAttributes.orderId.trim().length == 0) {
      setSearchAttributes({
        ...searchAttributes,
        orderId: "",
        showLoader: false,
      });
      setLoader(false);
      //setInvalidreturn(true);
      return;
    }
    /* searchservice
       .orderDetails(
         state1.storeNo,
         searchAttributes.orderId,
         state1.token,
         null,
         null,
         null,
         null,
         null
       )
       .then((response) => {
         if (response.hasOwnProperty("message")) {
           console.log(response.message);
           setSearchAttributes({
             ...searchAttributes,
             search: false,
             details: [],
             AdvanceDetails: [],
             showLoader: false,
             AdvanceSearch: true,
           });
           setLoader(false);
           setInvalidreturn(true);
           return;
         }
         var tempdetails = response.OrderDetails.details;
         if (tempdetails.length > 0) {
           for (let i = 0; i < tempdetails.length; i++)
             tempdetails[i].retailPrice = (
               "$" + formatRetailPrice(tempdetails[i].retailPrice, 2, ",", ".")
             ).toString();
           // tempdetails[i].retailPrice = (parseInt(tempdetails[i].retailPrice)).toLocaleString('en-US', {
           //   style: 'currency',
           //   currency: 'USD',
           // });
           setSearchAttributes({
             ...searchAttributes,
             search: true,
             details: sortAscending(tempdetails),
             AdvanceSearch: false,
             showLoader: false,
           });
           setLoader(false);
         } else {
           setSearchAttributes({
             ...searchAttributes,
             search: false,
             details: [],
             showLoader: false,
           });
           setLoader(false);
           setInvalidreturn(true);
         }
       })
       .catch((err) => {
         console.log(err);
         setLoader(false);
       });*/
    UblService.searchorder(
      state1.storeNo,
      state1.token,
      searchAttributes.orderId,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
     !user.tempDC
    )
      .then((res) => {
        var tempdetails = res.searchReturnOrder.details;
        if (tempdetails.length > 0) {
          for (let i = 0; i < tempdetails.length; i++)
            tempdetails[i].retailPrice = (
              "$" + formatRetailPrice(tempdetails[i].retailPrice, 2, ",", ".")
            ).toString();
          setSearchAttributes({
            ...searchAttributes,
            orderId: "",
            details: tempdetails.sort((b, a) => {
              return (
                b.supplier.trim().localeCompare(a.supplier.trim()) ||
                b.itemClsGrp - a.itemClsGrp ||
                b.brand.trim().localeCompare(a.brand.trim()) ||
                b.item - a.item
              );
            }),
            AdvanceSearch: false,
            search: true,
            showLoader: false,
          });
          setLoader(false);
        } else {
          setInvalidreturn(true);
          setSearchAttributes({
            ...searchAttributes,

            details: [],
            AdvanceSearch: false,
            search: false,
            showLoader: false,
          });
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setSearchAttributes({
          ...searchAttributes,
          orderId: "",
          details: [],
          AdvanceSearch: false,
          search: false,
          showLoader: false,
        });
        setLoader(false);
      });
  };
  useEffect(() => {}, [loader]);

  const removeEmpty = (obj) => {
    console.log(supplierSelectedValues);
    console.log("REMOVE", obj);
    return Object.keys(obj)
      .filter(function (k) {
        return (
          obj[k] !== "" &&
          obj[k] !== false &&
          obj[k] !== true &&
          obj[k].length !== 0 &&
          !"returnLocation".includes(k) &&
          !"details".includes(k)
        );
      })
      .reduce(function (acc, k) {
        acc[k] = obj[k];
        return acc;
      }, {});
  };

  const setupFilteredFields = (searchAttributes)=>{
    if(startDateReturnOrder && endDateReturnOrder){
      searchAttributes.startDate = dateFormat(startDateReturnOrder, "dd-mm-yyyy")
      console.log(endDateReturnOrder,startDateReturnOrder);
      searchAttributes.endDate =  dateFormat(endDateReturnOrder, "dd-mm-yyyy")
    } else {
      searchAttributes.startDate = ""
      searchAttributes.endDate =  ""
    }
   
    let tempFilter =removeEmpty(searchAttributes)
    if(tempFilter.dateFrom){
      try{
        tempFilter.dateFrom = dateFormat(startDate, "dd-mm-yyyy")
      }
      catch(e){
        console.log(e)
      }
    }

    if(tempFilter.dateTo){
      try{
        tempFilter.dateTo =dateFormat(endDate, "dd-mm-yyyy")
      }
      catch(e){
        console.log(e)
      }
    }
    if(tempFilter.supplierNames){     
      tempFilter.supplierNames = getMultiselectedValues(supplierSelectedValues)
    }
    if(tempFilter.status){
      tempFilter.status = getMultiselectedValues(statusselectedvalues)
    }
    if(tempFilter.myerMarketId){
      tempFilter.myerMarketId = getMultiselectedValues(myerMarketId)
    }
    if(returnLocationSelectedValues && returnLocationSelectedValues.length > 0){
      console.log("supplierAppend",returnLocationSelectedValues)
      tempFilter.returnLocation = getMultiselectedValues(returnLocationSelectedValues)
    }
    if(tempFilter.returnReason){
      tempFilter.returnReason = getMultiselectedValues(returnreasonselected)
    }
    if(tempFilter.returnChannel){
      tempFilter.returnChannel = getMultiselectedValues(returnChannelselected)
    }
    if(tempFilter.detagReason){
      tempFilter.detagReason = getMultiselectedValues(detagged)
    }
    
    setFilteredObject(tempFilter)
    console.log("supplierAppend",tempFilter)
  }

  const getMultiselectedValues = (selectedValues) =>{
    let selectedValuesAppend;
      for(let i = 0 ; i < selectedValues.length ; i++){
        if(i==1){
          selectedValuesAppend = selectedValuesAppend + ","
        }
        selectedValuesAppend = selectedValuesAppend && selectedValuesAppend.length > 0 ? (selectedValuesAppend + "," + selectedValues[i].label) : selectedValues[i].label
       
      }
      console.log("supplierAppend",selectedValuesAppend)
      return selectedValuesAppend
  }
  const onAdvanceSearch = () => {
    console.log("ADVANCE", searchAttributes);
    //setFilteredObject(removeEmpty(searchAttributes));
    setupFilteredFields(searchAttributes)
    console.log("filtered",removeEmpty(searchAttributes))
    setInvalidreturn(false);
    $("#dropdownOrderLookUp").removeClass("show");
    setLoader(true);
    console.log();
    if (
      searchAttributes.ubl != "" ||
      searchAttributes.cbl != "" ||
      searchAttributes.salesOrderNo != "" ||
      searchAttributes.returnOrderNumber != "" ||
      searchAttributes.supplierPo != "" ||
      searchAttributes.dcManifest != "" ||
      searchAttributes.storeManifest != "" ||
      searchAttributes.dcPutawayLocation != "" ||
      searchAttributes.carrierRef != "" ||
      startDateReturnOrder
    ) {
      UblService.searchorder(
        state1.storeNo,
        state1.token,
        searchAttributes.returnOrderNumber
          ? searchAttributes.returnOrderNumber
          : null,
        returnLocationSelectedValues.length > 0 && returnLocationSelectedValues
          ? returnLocationSelectedValues.map((a, index) => a.value)
          : null,
        searchAttributes.returnCreatedBy
          ? searchAttributes.returnCreatedBy
          : null,
        returnreasonselected.length > 0 && returnreasonselected
          ? returnreasonselected.map((a, index) => a.value)
          : null,
        returnChannelselected.length == 1
          ? returnChannelselected[0].value
          : null,
        detagged.length > 0 && detagged
          ? detagged.map((a, index) => a.value)
          : null,
        startDateReturnOrder
          ? dateFormat(startDateReturnOrder, "yyyy-mm-dd")
          : null,
        endDateReturnOrder
          ? dateFormat(addDays(endDateReturnOrder, 1), "yyyy-mm-dd")
          : null,
        searchAttributes.firstName ? searchAttributes.firstName : null,
        searchAttributes.lastName ? searchAttributes.lastName : null,
        searchAttributes.phoneNumber ? searchAttributes.phoneNumber : null,
        searchAttributes.salesOrderNo ? searchAttributes.salesOrderNo : null,
        searchAttributes.ubl ? searchAttributes.ubl : null,
        searchAttributes.cbl ? searchAttributes.cbl : null,
        searchAttributes.storeManifest ? searchAttributes.storeManifest : null,
        null,
        searchAttributes.supplierPo ? searchAttributes.supplierPo : null,
        myerMarketId.length > 0
          ? myerMarketId.map((a) => a.value)
          : supplierSelectedValues.length > 0
          ? supplierSelectedValues.map((a, index) => a.value)
          : null,
        searchAttributes.itemId ? searchAttributes.itemId : null,
        searchAttributes.brand ? searchAttributes.brand : null,
        classgrpselectedvalue.length > 0
          ? [classgrpselectedvalue[0].id.toString()]
          : null,
        searchAttributes.dcManifest ? searchAttributes.dcManifest : null,

        statusselectedvalues.length > 0
          ? statusselectedvalues.map((a, index) => a.value)
          : null,
        searchAttributes.carrierRef ? searchAttributes.carrierRef : null,
        null,
        null,
        searchAttributes.dcPutawayLocation
          ? searchAttributes.dcPutawayLocation
          : null,
       !user.tempDC
      )
        .then((res) => {
        setLoader(false);
          var tempdetails = res.searchReturnOrder.details;

          if (tempdetails.length > 0) {
            console.log("TEMP", tempdetails);
            for (let i = 0; i < tempdetails.length; i++) {
              tempdetails[i].retailPrice = (
                "$" + formatRetailPrice(tempdetails[i].retailPrice, 2, ",", ".")
              ).toString();
            }

            console.log("ONSEARCH", searchAttributes);
            setSearchAttributes({
              ...searchAttributes,
              orderId: "",
              details: tempdetails.sort((b, a) => {
                return (
                  b.supplier.trim().localeCompare(a.supplier.trim()) ||
                  b.itemClsGrp - a.itemClsGrp ||
                  b.brand.trim().localeCompare(a.brand.trim()) ||
                  b.item - a.item
                );
              }),
              AdvanceSearch: true,
              search: true,
              showLoader: false,
            });
            setLoader(false);
          } else {
            setSearchAttributes({
              ...searchAttributes,
              orderId: "",
              details: [],
              AdvanceSearch: false,
              search: false,
              showLoader: false,
            });
            setLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setSearchAttributes({
            ...searchAttributes,
            orderId: "",
            details: [],
            AdvanceSearch: false,
            search: false,
            showLoader: false,
          });
          setLoader(false);
        });
    } else {
      UblService.searchorder(
        state1.storeNo,
        state1.token,
        searchAttributes.returnOrderNumber
          ? searchAttributes.returnOrderNumber
          : null,
        returnLocationSelectedValues.length > 0 && returnLocationSelectedValues
          ? returnLocationSelectedValues.map((a, index) => a.value)
          : null,
        searchAttributes.returnCreatedBy
          ? searchAttributes.returnCreatedBy
          : null,
        returnreasonselected.length > 0 && returnreasonselected
          ? returnreasonselected.map((a, index) => a.value)
          : null,
        returnChannelselected.length == 1
          ? returnChannelselected[0].value
          : null,
        detagged.length > 0 && detagged
          ? detagged.map((a, index) => a.value)
          : null,
        startDateReturnOrder
          ? dateFormat(startDateReturnOrder, "yyyy-mm-dd")
          : null,
        endDateReturnOrder
          ? dateFormat(addDays(endDateReturnOrder, 1), "yyyy-mm-dd")
          : null,
        searchAttributes.firstName ? searchAttributes.firstName : null,
        searchAttributes.lastName ? searchAttributes.lastName : null,
        searchAttributes.phoneNumber ? searchAttributes.phoneNumber : null,
        searchAttributes.salesOrderNo ? searchAttributes.salesOrderNo : null,
        searchAttributes.ubl ? searchAttributes.ubl : null,
        searchAttributes.cbl ? searchAttributes.cbl : null,
        searchAttributes.storeManifest ? searchAttributes.storeManifest : null,
        null,
        searchAttributes.supplierPo ? searchAttributes.supplierPo : null,
        myerMarketId.length > 0
          ? myerMarketId.map((a) => a.value)
          : supplierSelectedValues.length > 0
          ? supplierSelectedValues.map((a, index) => a.value)
          : null,
        searchAttributes.itemId ? searchAttributes.itemId : null,
        searchAttributes.brand ? searchAttributes.brand : null,
        classgrpselectedvalue.length > 0
          ? [classgrpselectedvalue[0].id.toString()]
          : null,
        searchAttributes.dcManifest ? searchAttributes.dcManifest : null,

        statusselectedvalues.length > 0
          ? statusselectedvalues.map((a, index) => a.value)
          : null,
        searchAttributes.carrierRef ? searchAttributes.carrierRef : null,
        startDate ? dateFormat(startDate, "yyyy-mm-dd") : null,
        endDate ? dateFormat(addDays(endDate, 1), "yyyy-mm-dd") : null,

        searchAttributes.dcPutawayLocation
          ? searchAttributes.dcPutawayLocation
          : null,
        !user.tempDC
      )
        .then((res) => {
          setLoader(false);
          var tempdetails = res.searchReturnOrder.details;
          if (tempdetails.length > 0) {
            for (let i = 0; i < tempdetails.length; i++)
              tempdetails[i].retailPrice = (
                "$" + formatRetailPrice(tempdetails[i].retailPrice, 2, ",", ".")
              ).toString();
            //console.log(searchAttributes.details);
            setSearchAttributes({
              ...searchAttributes,
              orderId: "",
              details: tempdetails.sort((b, a) => {
                return (
                  b.supplier.trim().localeCompare(a.supplier.trim()) ||
                  b.itemClsGrp - a.itemClsGrp ||
                  b.brand.trim().localeCompare(a.brand.trim()) ||
                  b.item - a.item
                );
              }),
              AdvanceSearch: true,
              search: true,
              showLoader: false,
            });
            setLoader(false);
          } else {
            setSearchAttributes({
              ...searchAttributes,
              orderId: "",
              details: [],
              AdvanceSearch: false,
              search: false,
              showLoader: false,
            });
            setLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setSearchAttributes({
            ...searchAttributes,
            orderId: "",
            details: [],
            AdvanceSearch: false,
            search: false,
            showLoader: false,
          });
          setLoader(false);
        });
    }
  };
  console.log("ONSEARCH", filteredObject);
  const onClear = () => {
    setSearchAttributes({
      ...searchAttributes,
      firstName: "",
      lastName: "",
      phoneNumber: "",
      supplierPo: "",
      supplier: "",
      brand: "",
      salesOrderNo: "",
      itemId: "",
      dateFrom: "",
      dateTo: "",
      carrierRef: "",
      ubl: "",
      returnOrderNumber: "",
      classGroup: "",
      status: "",
      myerMarketId: "",
      cbl: "",
      storeManifest: "",
      dcManifest: "",
      dcPutawayLocation: "",
      returnCreatedBy: "",
      returnReason: "",
      returnChannel: "",
      detagReason: "",
      dateFrom: null,
      dateTo: null,
    });
    setInvalidreturn(false);
    setDateFromValidation({ isDateFromValid: true, errorMessage: "" });
    setstatusselectedvalues([]);
    setReturnDateFromValidation({
      isReturnDateFromValid: true,
      errorMessage: "",
    });
    setReturnLocationSelectedValues([]);
    setSupplierSelectedValues([]);
    setclassgrpselectedvalue([]);
    setreturnreasonselected([]);
    setreturnChannelselected([]);
    setdetagged([]);
    setStartDate(null);
    setEndDate(null);
    setReturnOrderStartDate();
    setReturnOrderEndDate();
    setmyerMarketId([]);
    setInvalidreturn(false);
    setIsROValid(true);
    setCustomerValidation({
      isPhoneValid: true,
      isSalesOrderNumValid: true,
    });
    setTrackingValidations({
      isUBLValid: true,
      isCBLValid: true,
      isCarrierRefValid: true,
      isStoreManifestValid: true,
      isDCManifestValid: true,
      isDCPutawayLocationValid: true,
    });
    setItemSupplierValidation({ isSupplierPOValid: true, isItemIDValid: true });
  };

  const handleOrderId = (event) => {
    setInvalidreturn(false);
    setSearchAttributes({
      ...searchAttributes,
      orderId: event.target.value.replace(/[^\d]/g, ""),
    });
  };

  const handleChangeFirstname = (event) => {
    setInvalidreturn(false);
    const re = /^([a-zA-Z ]+\s)*[a-zA-Z ]+$/;
    var input = event.target.value;
    console.log("firstname", input);

    if (re.test(input)) {
      setSearchAttributes({ ...searchAttributes, firstName: input });
    } else {
      if (input.length == 0) {
        setSearchAttributes({ ...searchAttributes, firstName: "" });
      }
    }
  };
  const handleChangeLastName = (event) => {
    setInvalidreturn(false);
    const re = /^([a-zA-Z ]+\s)*[a-zA-Z ]+$/;
    var input = event.target.value;
    console.log("lastname", input);

    if (re.test(input)) {
      setSearchAttributes({ ...searchAttributes, lastName: input });
    } else {
      if (input.length == 0) {
        setSearchAttributes({ ...searchAttributes, lastName: "" });
      }
    }
  };
  const handleChangePhoneNumber = (event) => {
    setInvalidreturn(false);
    var input = event.target.value.toString().trim();
    setSearchAttributes({
      ...searchAttributes,
      phoneNumber: input.replace(/\s/g, ""),
    });
    if (input === "" || numberOnlyRegex.test(input)) {
      setCustomerValidation({ ...customerValidation, isPhoneValid: true });
      setIsAdvanceSearchDisabled(false);
    } else {
      setCustomerValidation({ ...customerValidation, isPhoneValid: false });
      setIsAdvanceSearchDisabled(true);
    }
  };
  const handleChangeSupplierPo = (event) => {
    setInvalidreturn(false);
    var input = event.target.value
      .replace(/[^0-9a-zA-Z!@#$%^&*()_+={}[\]\\|:;",.<>?/' .]/g, "")
      .toString()
      .trim();
    setSearchAttributes({
      ...searchAttributes,
      supplierPo: input.replace(/\s/g, ""),
    });
    if (input === "" || numberOnlyRegex.test(input)) {
      setItemSupplierValidation({
        ...itemSupplierValidation,
        isSupplierPOValid: true,
      });
      setIsAdvanceSearchDisabled(false);
    } else {
      setItemSupplierValidation({
        ...itemSupplierValidation,
        isSupplierPOValid: false,
      });
      setIsAdvanceSearchDisabled(true);
    }
  };

  const handleChangeBrand = (event) => {
    setInvalidreturn(false);
    setSearchAttributes({
      ...searchAttributes,
      brand: event.target.value.replace(
        /[^0-9a-zA-Z!@#$%^&*()_+={}[\]\\|:;",.<>?/' .]/g,
        ""
      ),
    });
  };
  const handleChangeSalesOrderNo = (event) => {
    setInvalidreturn(false);
    var input = event.target.value
      .replace(/[^0-9]/g, "")
      .toString()
      .trim();
    setSearchAttributes({
      ...searchAttributes,
      salesOrderNo: input.replace(/\s/g, ""),
    });
    if (input === "" || numberOnlyRegex.test(input)) {
      setCustomerValidation({
        ...customerValidation,
        isSalesOrderNumValid: true,
      });
      setIsAdvanceSearchDisabled(false);
    } else {
      setCustomerValidation({
        ...customerValidation,
        isSalesOrderNumValid: false,
      });
      setIsAdvanceSearchDisabled(true);
    }
  };
  console.log("FILTER", filterFields);
  const handleChangeItemId = (event) => {
    setInvalidreturn(false);
    var input = event.target.value
      .replace(/[^0-9a-zA-Z!@#$%^&*()_+={}[\]\\|:;",.<>?/' .]/g, "")
      .toString()
      .trim();
    setSearchAttributes({
      ...searchAttributes,
      itemId: input.replace(/\s/g, ""),
    });
    if (input === "" || numberOnlyRegex.test(input)) {
      setItemSupplierValidation({
        ...itemSupplierValidation,
        isItemIDValid: true,
      });
      setIsAdvanceSearchDisabled(false);
    } else {
      setItemSupplierValidation({
        ...itemSupplierValidation,
        isItemIDValid: false,
      });
      setIsAdvanceSearchDisabled(true);
    }
  };
  const [classgrp, setclassgrp] = useState([]);

  const [startDate, setStartDate] = useState(
    new Date().setDate(new Date().getDate() - 29)
  );
  const [endDate, setEndDate] = useState(new Date());
  const handleChangePeriod = (event) => {
    const [start, end] = event;
    setSearchAttributes({
      ...searchAttributes,
      dateFrom: moment(start, "DD-MM-YYYY").format("DD-MM-YYYY"),
      dateTo: moment(end, "DD-MM-YYYY").format("DD-MM-YYYY"),
    });
    if (start)
      if (start.getTime() < subMonths(new Date(), 15).getTime()) {
        setDateFromValidation({
          isDateFromValid: false,
          errorMessage: "Date Should not be less than 15 Month",
        });
      } else if (!end) {
        setDateFromValidation({
          isDateFromValid: false,
          errorMessage: "Please enter end date",
        });
        setStartDate(start);
        setEndDate(end);
      } else if (getDifferenceInDays(start, end) > 29) {
        setDateFromValidation({
          isDateFromValid: false,
          errorMessage: "Range should be less than 30 days",
        });
      } else {
        setDateFromValidation({ isDateFromValid: true, errorMessage: "" });
        setStartDate(start);
        setEndDate(end);
        setTimeout(() => {
          periodlabel.current.click();
        }, 2000);
      }
    else {
      setDateFromValidation({ isDateFromValid: true, errorMessage: "" });
      setStartDate(start);
      setEndDate(end);
    }
  };

  const getDifferenceInDays = (date1, date2) => {
    var admission = moment(date1, "DD/MM/YYYY");
    var discharge = moment(date2, "DD/MM/YYYY");
    return discharge.diff(admission, "days");
  };

  const handleChangeReturnLocation = (event) => {
    setInvalidreturn(false);
    setSearchAttributes({
      ...searchAttributes,
      returnLocation: event.target.value.replace(
        /[^0-9a-zA-Z!@#$%^&*()_+={}[\]\\|:;",.<>?/' .]/g,
        ""
      ),
    });
  };
  const handleChangeReturnCreatedBy = (event) => {
    setInvalidreturn(false);
    setSearchAttributes({
      ...searchAttributes,
      returnCreatedBy: event.target.value.replace(
        /[^0-9a-zA-Z!@#$%^&*()_+={}[\]\\|:;",.<>?/' .]/g,
        ""
      ),
    });
  };
  const handleChangeReturnOrderNumber = (event) => {
    setInvalidreturn(false);
    var input = event.target.value
      .replace(/[^0-9]/g, "")
      .toString()
      .trim();
    setSearchAttributes({
      ...searchAttributes,
      returnOrderNumber: input.replace(/\s/g, ""),
    });
    if (input === "" || numberOnlyRegex.test(input)) {
      setIsROValid(true);
      setIsAdvanceSearchDisabled(false);
    } else {
      setIsROValid(false);
      setIsAdvanceSearchDisabled(true);
    }
  };

  const handleChangeReturnReason = (event) => {
    setInvalidreturn(false);
    setSearchAttributes({
      ...searchAttributes,
      returnReason: event.target.value.replace(
        /[^0-9a-zA-Z!@#$%^&*()_+={}[\]\\|:;",.<>?/' .]/g,
        ""
      ),
    });
  };

  const handleChangeReturnChannel = (event) => {
    setInvalidreturn(false);
    setSearchAttributes({
      ...searchAttributes,
      returnChannel: event.target.value.replace(
        /[^0-9a-zA-Z!@#$%^&*()_+={}[\]\\|:;",.<>?/' .]/g,
        ""
      ),
    });
  };

  const handleChangeDetagReason = (event) => {
    setInvalidreturn(false);
    console.log("detag", event.target.value);
    setSearchAttributes({
      ...searchAttributes,
      detagReason: event.target.value.replace(
        /[^0-9a-zA-Z!@#$%^&*()_+={}[\]\\|:;",.<>?/' .]/g,
        ""
      ),
    });
  };

  const handleDateInputChangeRaw = (event) => {
    console.log("moment1", event);
    event.preventDefault();
  };

  const handleBackSpaceInDatePicker = (e, field) => {
    //in case we need to clear date range we can configure here
    // if (e.keyCode === 8) {
    //   switch (field) {
    //     case 'dateFrom': setSearchAttributes({ ...searchAttributes, dateFrom: "" });
    //       return
    //     case 'dateTo': setSearchAttributes({ ...searchAttributes, dateTo: "" });
    //       return
    //   }
    // }
  };

  const [startDateReturnOrder, setReturnOrderStartDate] = useState();
  const [endDateReturnOrder, setReturnOrderEndDate] = useState();

  const handleChangeReturnReceivedPeriod = (event) => {
    console.log("handleChangeReturnReceivedPeriod",event)
    setInvalidreturn(false);
    const [start, end] = event;
    if (start)
      if (start.getTime() < subMonths(new Date(), 15).getTime()) {
        setReturnDateFromValidation({
          isReturnDateFromValid: false,
          errorMessage: "Date Should not be less than 15 Month",
        });
      } else if (!end) {
        setReturnDateFromValidation({
          isReturnDateFromValid: false,
          errorMessage: "Please enter end date",
        });
        setReturnOrderStartDate(start);
        setReturnOrderEndDate(end);
      } else if (getDifferenceInDays(start, end) > 29) {
        setReturnDateFromValidation({
          isReturnDateFromValid: false,
          errorMessage: "Range should be less than 30 days",
        });
      } else {
        setReturnDateFromValidation({
          isReturnDateFromValid: true,
          errorMessage: "",
        });
        setReturnOrderStartDate(start);
        setReturnOrderEndDate(end);
      }
    else {
      setReturnDateFromValidation({
        isReturnDateFromValid: true,
        errorMessage: "",
      });
      console.log("handleChangeReturnReceivedPeriod",start+end)
      setReturnOrderStartDate(start);
      setReturnOrderEndDate(end);
    }
  };
  const handleChangeCarrierRef = (event) => {
    setInvalidreturn(false);
    var input = event.target.value
      .replace(/[^0-9a-zA-Z!@#$%^&*()_+={}[\]\\|:;",.<>?/' .]/g, "")
      .toString()
      .trim();
    console.log(searchAttributes.carrierRef.length);
    setSearchAttributes({
      ...searchAttributes,
      carrierRef: input.replace(/\s/g, ""),
    });
    if (
      searchAttributes.carrierRef.length === 0 ||
      !alphaNumericRegex.test(input.replace(/\s/g, ""))
    ) {
      setTrackingValidations({
        ...trackingValidation,
        isCarrierRefValid: true,
      });
      setIsAdvanceSearchDisabled(false);
    } else {
      setTrackingValidations({
        ...trackingValidation,
        isCarrierRefValid: false,
      });
      setIsAdvanceSearchDisabled(true);
    }
  };

  const handleChangeClassGroup = (event) => {
    setInvalidreturn(false);
    setSearchAttributes({
      ...searchAttributes,
      classGroup: event.target.value.replace(
        /[^0-9a-zA-Z!@#$%^&*()_+={}[\]\\|:;",.<>?/' .]/g,
        ""
      ),
    });
  };

  const handleChangeStatus = (event) => {
    setInvalidreturn(false);
    setSearchAttributes({
      ...searchAttributes,
      status: event.target.value.replace(
        /[^0-9a-zA-Z!@#$%^&*()_+={}[\]\\|:;",.<>?/' .]/g,
        ""
      ),
    });
  };

  const handleChangeUBL = (event) => {
    setInvalidreturn(false);
    var inputBarcode = event.target.value
      .replace(/[^0-9a-zA-Z!@#$%^&*()_+={}[\]\\|:;",.<>?/' .]/g, "")
      .toString()
      .trim()
      .replace(/[^\d.-]/g, "");
    setSearchAttributes({
      ...searchAttributes,
      ubl: inputBarcode.replace(/\s/g, ""),
    });
    if (isValidUBL(inputBarcode)) {
      setTrackingValidations({ ...trackingValidation, isUBLValid: true });
      setIsAdvanceSearchDisabled(false);
    } else {
      setTrackingValidations({ ...trackingValidation, isUBLValid: false });
      setIsAdvanceSearchDisabled(true);
    }
  };

  const isValidUBL = (data) => {
    if (data.length === 7 && data > 0) {
      return true;
    } else if (data.length == 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleChangeMyerMarketId = (event) => {
    setInvalidreturn(false);
    setSearchAttributes({
      ...searchAttributes,
      myerMarketId: event.target.value.replace(
        /[^0-9a-zA-Z!@#$%^&*()_+={}[\]\\|:;",.<>?/' .]/g,
        ""
      ),
    });
  };

  const handleChangeCBL = (event) => {
    setInvalidreturn(false);
    var inputBarcode = event.target.value
      .replace(/[^0-9a-zA-Z!@#$%^&*()_+={}[\]\\|:;",.<>?/' .]/g, "")
      .toString()
      .trim()
      .replace(/[^\d.-]/g, "");
    setSearchAttributes({
      ...searchAttributes,
      cbl: inputBarcode.replace(/\s/g, ""),
    });
    if (isValidCBL(inputBarcode)) {
      setTrackingValidations({ ...trackingValidation, isCBLValid: true });
      setIsAdvanceSearchDisabled(false);
    } else {
      setTrackingValidations({ ...trackingValidation, isCBLValid: false });
      setIsAdvanceSearchDisabled(true);
    }
  };

  const isValidCBL = (data) => {
    if (!user.tempDC) {
      if (data.length === 10 && data.substr(3, 7) > 0) {
        return true;
      } else if (data.length == 0) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        data.length === 10 &&
        !isNaN(data) &&
        data.substr(0, 3) === ("00" + user.storeNo).slice(-3) &&
        data.substr(3, 7) > 0
      ) {
        return true;
      } else if (data.length == 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleChangeStoreManifest = (event) => {
    setInvalidreturn(false);
    var input = event.target.value
      .replace(/[^0-9a-zA-Z!@#$%^&*()_+={}[\]\\|:;",.<>?/' .]/g, "")
      .toString()
      .trim();
    console.log("hello", searchAttributes.carrierRef.length);
    setSearchAttributes({
      ...searchAttributes,
      storeManifest: input.replace(/\s/g, ""),
    });
    if (!user.tempDC) {
      if (
        (input.replace(/\s/g, "").length == 0 ||
          numberOnlyRegex.test(input.replace(/\s/g, ""))) &&
        (input.replace(/\s/g, "").length == 0 ||
          (input.replace(/\s/g, "").length == 9 &&
            input.substr(0, 3) != "000".slice(-3)))
      ) {
        setTrackingValidations({
          ...trackingValidation,
          isStoreManifestValid: true,
        });
        setIsAdvanceSearchDisabled(false);
      } else {
        setTrackingValidations({
          ...trackingValidation,
          isStoreManifestValid: false,
        });
        setIsAdvanceSearchDisabled(true);
      }
    } else {
      if (
        (input.replace(/\s/g, "").length == 0 ||
          numberOnlyRegex.test(input.replace(/\s/g, ""))) &&
        (input.replace(/\s/g, "").length == 0 ||
          (input.replace(/\s/g, "").length == 9 &&
            input.substr(0, 3) == ("00" + user.storeNo).slice(-3)))
      ) {
        setTrackingValidations({
          ...trackingValidation,
          isStoreManifestValid: true,
        });
        setIsAdvanceSearchDisabled(false);
      } else {
        setTrackingValidations({
          ...trackingValidation,
          isStoreManifestValid: false,
        });
        setIsAdvanceSearchDisabled(true);
      }
    }
  };

  const handleChangeDCPutawayLocation = (event) => {
    setInvalidreturn(false);
    console.log("putaway",event.target.value)
    var input = event.target.value
      //.replace(/[^0-9a-zA-Z!@#$%^&*()_+={}[\]\\|:;",.<>?/' .]/g, "")
      .toString()
      //.trim();
    console.log("putaway length",searchAttributes.dcPutawayLocation.length);
    setSearchAttributes({
      ...searchAttributes,
      //dcPutawayLocation: input.replace(/\s/g, ""),
      dcPutawayLocation: input,
    });
    // if (
    //   searchAttributes.dcPutawayLocation.length === 0 || !alphaNumericRegex.test(input.replace(/\s/g, ""))
    // ) {
    //   setTrackingValidations({
    //     ...trackingValidation,
    //     isDCPutawayLocationValid: true,
    //   });
    //   setIsAdvanceSearchDisabled(false);
    // } else {
    //   setTrackingValidations({
    //     ...trackingValidation,
    //     isDCPutawayLocationValid: false,
    //   });
    //   setIsAdvanceSearchDisabled(true);
    // }

    if(input.trim().length >= 0 ){
      setTrackingValidations({
             ...trackingValidation,
             isDCPutawayLocationValid: true,
           });
           setIsAdvanceSearchDisabled(false);
    } else {
      setTrackingValidations({
             ...trackingValidation,
             isDCPutawayLocationValid: false,
           });
           setIsAdvanceSearchDisabled(true);
    }
  };

  const handleChangeDCManifest = (event) => {
    setInvalidreturn(false);
    var input = event.target.value
      .replace(/[^0-9a-zA-Z!@#$%^&*()_+={}[\]\\|:;",.<>?/' .]/g, "")
      .toString()
      .trim();
    console.log("hello", searchAttributes.dcManifest.length);
    setSearchAttributes({
      ...searchAttributes,
      dcManifest: input.replace(/\s/g, ""),
    });
    console.log(input.length);
    if (
      input.replace(/\s/g, "").length == 0 ||
      (numberOnlyRegex.test(input.replace(/\s/g, "")) &&
        (input.length == 0 || input.length == 7))
    ) {
      setTrackingValidations({
        ...trackingValidation,
        isDCManifestValid: true,
      });
      setIsAdvanceSearchDisabled(false);
    } else {
      setTrackingValidations({
        ...trackingValidation,
        isDCManifestValid: false,
      });
      setIsAdvanceSearchDisabled(true);
    }
  };

  const handleExpandAllSelect = (event) => {
    setExpandAllSelect(!expandAllSelect);
    console.log("expandAllSelect", expandAllSelect);
    //$('#itemAndSupplier').attr("aria-expanded","false");
    if (expandAllSelect) {
      $(".collapse1").collapse("hide");
    } else {
      $(".collapse1").collapse("show");
    }
  };

  const handleOnIdle = (event) => {
    console.log("user is idle", event);
    console.log("last active", getLastActiveTime());
    callLogoutURL();
  };

  const handleOnActive = (event) => {
    console.log("user is active", event);
    console.log("time remaining", getRemainingTime());
    if (localStorage.getItem("access_token") == "null") {
      callLogoutURL();
      return;
    }
    handleReset();
  };

  const showItemTaggingMessage = (message, status) => {
    if (status === "success") settoastmsg(message, setItemTaggingToast(true));
    else {
      setSearchAttributes({ ...searchAttributes, errorMsgPopup: message });
      $("#errorModalCenter1").modal();
    }
  };

  const handleOnAction = (event) => {
    console.log("user did something", event);
    console.log(timeoutDuration);
    if (localStorage.getItem("access_token") == "null") {
      callLogoutURL();
      return;
    }
  };

  const { getRemainingTime, getLastActiveTime, reset } = useIdleTimer({
    timeout: timeoutDuration,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });

  const handleReset = () => reset();

  useEffect(async () => {
    console.log("callLocationService");
 
     callLocationService();
      intialAPIcall();

   
  }, []);

  const intialAPIcall=async ()=>{
    let flag=0;
    setLoader(true);
    
    UblService.supplierdet(user.storeNo, user.token)
    .then((res) => {
      let temp = [];
      let temp_sup = res.ret_ord_supp_no.supplierDetails;
     


      if (
        res.ret_ord_supp_no.message !=
        "Supplier Details not found for the Store Id"
      )
        for (let i = 0; i < temp_sup.length; i++)
          if (temp_sup[i].SUPPLIER_NO != "9000397")
            temp.push({
              label:
                temp_sup[i].SUPPLIER_NO + " " + temp_sup[i].SUPPLIER_NAME,
              value: temp_sup[i].SUPPLIER_NO,
            });
      setSupplier(temp);
      flag=flag+1;
      //console.log(flag);
      if(flag==2)
      setLoader(false);
    })
    .catch((err) => {console.log(err)
      flag=flag+1;
      if(flag==2)
      setLoader(false);
    });
    UblService.classgrp(user.storeNo, user.token)
    .then((res) => {
      let temp = [];
      let temp_sup = res.ret_classgrp_det.classGrpDetails;
      for (let i = 0; i < temp_sup.length; i++)
        temp.push({
          name: temp_sup[i].ITEM_CLASS_GRP_DESCR,
          id: temp_sup[i].ITEM_CLASS_GROUP,
        });
      setclassgrp(temp);
      flag=flag+1;
      //console.log(flag);
      if(flag==2)
     { setLoader(false);
    
    }
    })
    .catch((err) => {console.log(err);
      flag=flag+1;
      if(flag==2)
      setLoader(false);
    });
    
  }

  const callLocationService = async () => {
    if (parseInt(user.storeNo) == 29) {
      setSearchAttributes({
        ...searchAttributes,
        returnLocation: [
          { label: "029 Altona", value: "029" },
          { label: "001 Melbourne", value: "001" },
          { label: "004 Chadstone", value: "004" },
          { label: "005 Northland", value: "005" },
          { label: "006 Eastland", value: "006" },
          { label: "007 Southland", value: "007" },
          { label: "008 Doncaster", value: "008" },
          { label: "009 Highpoint", value: "009" },
          { label: "012 Frankston", value: "012" },
          { label: "014 Geelong", value: "014" },
          { label: "015 Ballarat", value: "015" },
          { label: "016 Bendigo", value: "016" },
          { label: "021 Hobart", value: "021" },
          { label: "022 Launceston", value: "022" },
          { label: "023 Fountain Gate", value: "023" },
          { label: "082 Adelaide", value: "082" },
          { label: "083 Marion", value: "083" },
          { label: "084 Tea Tree Plaza", value: "084" },
          { label: "295 Werribee", value: "295" },
        ],
      });
       setReturnLocationSelectedValues([{ label: "029 Altona", value: "029" }]);
    } else if (parseInt(user.storeNo) == 267) {
      setSearchAttributes({
        ...searchAttributes,
        returnLocation: [
          { label: "267 Eastern Creek", value: "267" },
          { label: "017 Albury", value: "017" },
          { label: "202 Paramatta", value: "202" },
          { label: "203 Bondi", value: "203" },
          { label: "204 Chatswood", value: "204" },
          { label: "207 Warringah", value: "207" },
          { label: "208 Shellharbour", value: "208" },
          { label: "209 Roselands", value: "209" },
          { label: "211 Miranda", value: "211" },
          { label: "212 Liverpool", value: "212" },
          { label: "215 Macquarie", value: "215" },
          { label: "219 Sydney", value: "219" },
          { label: "220 Blacktown", value: "220" },
          { label: "222 Penrith", value: "222" },
          { label: "231 Belconnen", value: "231" },
          { label: "234 Dubbo", value: "234" },
          { label: "236 Erina", value: "236" },
          { label: "238 Charlestown", value: "238" },
          { label: "246 Canberra", value: "246" },
          { label: "259 Wagga Wagga", value: "259" },
          { label: "271 Eastgardens", value: "271" },
          { label: "278 Bankstown", value: "278" },
          { label: "384 Castle Hill", value: "384" },
        ],
      });
      setReturnLocationSelectedValues([
        { label: "267 Eastern Creek", value: "267" },
      ]);
    } else if (parseInt(user.storeNo) == 97) {
      setSearchAttributes({
        ...searchAttributes,
        returnLocation: [
          { label: "097 Kewdale", value: "097" },
          { label: "041 Perth", value: "041" },
          { label: "042 Carousel", value: "042" },
          { label: "043 Morley", value: "043" },
          { label: "047 Garden City", value: "047" },
          { label: "090 Joondalup", value: "090" },
          { label: "093 Karrinyup", value: "093" },
        ],
      });
      setReturnLocationSelectedValues([{ label: "097 Kewdale", value: "097" }]);
    } else if (parseInt(user.storeNo) == 60) {
      setSearchAttributes({
        ...searchAttributes,
        returnLocation: [
          { label: "060 Richalnds", value: "060" },

          { label: "051 Mt Gravatt", value: "051" },
          { label: "052 Mackay", value: "052" },
          { label: "053 Robina", value: "053" },
          { label: "055 Logan", value: "055" },
          { label: "056 North Lakes", value: "056" },
          { label: "057 Maroochydore", value: "057" },
          { label: "059 Brisbane", value: "059" },

          { label: "062 Cairns", value: "062" },
          { label: "063 Chermside", value: "063" },
          { label: "065 Toowoomba", value: "065" },
          { label: "066 Indooroopilly", value: "066" },
          { label: "068 Carindale", value: "068" },
          { label: "070 Pacific Fair", value: "070" },
          { label: "071 Townsville", value: "071" },
        ],
      });
      setReturnLocationSelectedValues([
        { label: "060 Richalnds", value: "060" },
      ]);
    } else {
      setSearchAttributes({
        ...searchAttributes,
        returnLocation: [
          { label: user.storeNo + " " + user.storeName, value: user.storeNo },
        ],
      });
      setReturnLocationSelectedValues([
        { label: user.storeNo + " " + user.storeName, value: user.storeNo },
      ]);
      setdefaultmsg(user.storeNo + " " + user.storeName);
    }
  };

  useEffect(() => {
    $(document).click(function (e) {
      console.log("inside e.target", isSelectListEmpty);
      if (
        !e.target.closest("#dropdownOrderLookUp") &&
        !e.target.closest("#date") &&
        !e.target.closest("#multiselectContainerReact")
      ) {
        $(".collapseMain").collapse("hide");
      }
    });
  });

  useEffect(() => {
    $(document).on("click", "#dropdownOrderLookUp", function (e) {
      console.log("click in dropdown");
      e.stopPropagation();
    });
    $(document).on("click", ".react-datepicker__close-icon", function (e) {
      console.log("click in dropdown");
      e.stopPropagation();
    });

    $(document).on("click", ".chip", function (e) {
      console.log("click in chip");
      e.stopPropagation();
    });

    $(document).on(
      "click",
      ".react-datepicker__navigation--next",
      function (e) {
        console.log("click in chip");
        e.stopPropagation();
      }
    );

    //document.getElementById("date").setAttribute("disabled", true);
    //document.getElementById("date1").setAttribute("disabled", true);

    $(document).on("focus", ".optionListContainer", function (e) {
      console.log("click in chip");
      e.stopPropagation();
    });

    //   $('#collapseOne').on('shown.bs.collapse', function () {
    //     console.log("Opened")
    //  });

    $("#collapseOne").on("hidden.bs.collapse", function () {
      setExpandAllSelect(false);
      console.log("Closed");
    });
    $("#collapseTwo").on("hidden.bs.collapse", function () {
      setExpandAllSelect(false);
      console.log("Closed");
    });
    $("#collapseThree").on("hidden.bs.collapse", function () {
      setExpandAllSelect(false);
      console.log("Closed");
    });
    $("#collapseFour").on("hidden.bs.collapse", function () {
      setExpandAllSelect(false);
      console.log("Closed");
    });
    window.addEventListener("scroll", handleOnActive);
    return () => window.removeEventListener("scroll", handleOnAction);
  }, []);

  const handleMyerMarketId = (selectedList) => {
    setmyerMarketId(selectedList);
    setSearchAttributes({
      ...searchAttributes,
      myerMarketId: selectedList,
    });
  };
  const handleReturnReason = (selectedList) => {
    setSearchAttributes({
      ...searchAttributes,
      returnReason: selectedList,
    });
    setreturnreasonselected(selectedList);
  };
  const handleDetagReason = (selectedList) => {
    setSearchAttributes({
      ...searchAttributes,
      detagReason: selectedList,
    });
    setdetagged(selectedList);
  };
  const handleReturnChannel = (selectedList) => {
    setSearchAttributes({
      ...searchAttributes,
      returnChannel: selectedList,
    });
    setreturnChannelselected(selectedList);
  };

  const onSelectSupplierMultiselect = (selectedList, selectedItem) => {
    console.log("MULTISELECT", selectedList);
    setSupplierSelectedValues(selectedList);
    setSearchAttributes({
      ...searchAttributes,
      supplierNames: selectedList,
    });
    console.log(
      "SELECTED",
      supplierSelectedValues,
      selectedList,
      selectedItem,
      searchAttributes
    );
    //console.log(selectsupplier);
    // let temp = selectsupplier;
    // temp.push(selectedItem.id);
    // setselectsupplier(temp);
    //todo update the supplierList
  };

  const onRemoveSupplierMultiselect = (selectedList, removedItem) => {
    //todo update the supplierList
    setSupplierSelectedValues(selectedList);
    console.log(supplierSelectedValues, selectedList, removedItem);
    console.log(selectsupplier);
    let temp = selectsupplier;
    for (let i = 0; i < selectsupplier.length; i++)
      if (selectsupplier[i] == removedItem.id) {
        temp.splice(i, 1);
        break;
      }
    setselectsupplier(temp);
  };

  return (
    <div className="OrderLookup bg-grey" id="OrderLookUp">
      <Header />

      <div className="container-fluid" id="search_screen">
        <div className="row">
          <Sidebar active="OrderLookup" state={user} />

          <main
            role="main"
            className="col-md-9 ml-sm-auto col-lg-10 rtn-main-wrapper"
          >
            {/* page title */}
            <section className="rtn-page-title mb-2">
              <div className="d-flex bd-highlight ">
                <div className="bd-highlight modal-dialog-centered">
                  <h4>Return Order Lookup</h4>
                </div>

                <div className="ml-auto  bd-highlight">
                  {/* {<Breadcrumb crumbs={crumbs} selected={selected} />} */}
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <Link
                        to={{ pathname: "/LandingPage", state: user }}
                        className="breadcrumb-item breadcrumb-color pr-10"
                      >
                        Home
                      </Link>
                      <span className="pr-10"> {" >"}</span>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Return Order Lookup
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </section>

            {/* Search panel with advanced filter */}

            <section className="search-panel-ro mb-2">
              <form
                className="form-inline"
                style={{ alignItems: "flex-start" }}
                onSubmit={onSearch}
              >
                <label
                  className="my-1 mr-3 mt-2"
                  for="inlineFormCustomSelectPref"
                >
                  Search
                </label>
                <div>
                  <div
                    className={
                      Invalidreturn
                        ? "input-group advanced-search err"
                        : "input-group advanced-search"
                    }
                  >
                    <input
                      name="field"
                      autocomplete="off"
                      type="text"
                      className={
                        Invalidreturn
                          ? "form-control input-search errtext"
                          : "form-control input-search"
                      }
                      id="icon"
                      placeholder="Return Order"
                      aria-label="Text input with dropdown button"
                      value={searchAttributes.orderId}
                      onChange={handleOrderId}
                      ref={inputFocus}
                    />

                    <div className="input-group-append rtn-clear-input">
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        id="Idclear"
                        onClick={onClearOrderId}
                      >
                        <img
                          className=""
                          src="./images/clear-icon.svg"
                          alt=""
                        />
                      </button>
                    </div>
                    <div className="input-group-append">
                      <button
                        className="btn btn-outline-secondary btn-filter-border dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                        id="AdvanceSearch"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          className=""
                          src="./images/filter-icon.svg"
                          alt=""
                        />
                      </button>

                      <div
                        className="dropdown-menu rtn-search-attr collapseMain"
                        id="dropdownOrderLookUp"
                      >
                        <form>
                          <div className="container ">
                            <div>
                              <div>
                                <ul className="list-unstyled row">
                                  <li className="col-md-3 col-lg-3 col-sm-3 pt-10">
                                    <Checkbox
                                      key="expandAll"
                                      id="expandAll"
                                      name="expandAll"
                                      select={expandAllSelect}
                                      handleChange={handleExpandAllSelect}
                                      type="checkbox"
                                    />{" "}
                                    <span className="expand-All">
                                      Expand All
                                    </span>
                                    {isMobile ? (
                                      <button
                                        type="button"
                                        className="btn rtn-link p-0 float-right"
                                        style={{
                                          marginTop: "-4px",
                                          marginLeft: "10px",
                                        }}
                                        id="clearSearch"
                                        onClick={onClear}
                                      >
                                        Clear
                                      </button>
                                    ) : null}
                                  </li>
                                  <li className="col-md-5 col-lg-5 col-sm-5 d-flex-align">
                                    <div className="d-inline form-group mt-0 w-100">
                                      <div
                                        className="d-flex  row"
                                        id="datePeriod"
                                      >
                                        <label
                                          for="exampleFormControlSelect1"
                                          className="pr-3 date-device col-md-3 text-nowrap"
                                          ref={periodlabel}
                                          onClick={() => console.log("pclick")}
                                        >
                                          Return Created
                                        </label>
                                        <div className="datePicker-heading col-md-9">
                                          <DatePicker
                                            id="date"
                                            // showPopperArrow={false}
                                            onChangeRaw={
                                              handleDateInputChangeRaw
                                            }
                                            className="form-control reportdate datepicker"
                                            wrapperClassName="form-control mt-0"
                                            shouldCloseOnSelect={false}
                                            placeholderText={
                                              "From Date - To Date"
                                            }
                                            dateFormat={"dd/MM/yyyy"}
                                            selected={startDate}
                                            showPopperArrow={false}
                                            maxDate={
                                              endDate
                                                ? new Date()
                                                : startDate
                                                ? addDays(
                                                    startDate,
                                                    29
                                                  ).getTime() >
                                                  new Date().getTime()
                                                  ? new Date()
                                                  : addDays(startDate, 29)
                                                : new Date()
                                            }
                                            isClearable
                                            startDate={startDate}
                                            //disabledKeyboardNavigation={true}
                                            autoFocus={false}
                                            onMonthChange={() => {
                                              console.log(122334566);
                                            }}
                                            includeDateIntervals={
                                              endDate
                                                ? null
                                                : startDate
                                                ? addDays(
                                                    startDate,
                                                    29
                                                  ).getTime() >
                                                  new Date().getTime()
                                                  ? [
                                                      {
                                                        start: startDate,
                                                        end: new Date(),
                                                      },
                                                    ]
                                                  : [
                                                      {
                                                        start: startDate,
                                                        end: addDays(
                                                          startDate,
                                                          29
                                                        ),
                                                      },
                                                    ]
                                                : null
                                            }
                                            //startDate={startDate}
                                            //disabledKeyboardNavigation={true}
                                           
                                            endDate={endDate}
                                            selectsRange
                                            onChange={(date) =>
                                              handleChangePeriod(date)
                                            }
                                            popperPlacement="bottom"
                                            popperModifiers={{
                                              flip: {
                                                behavior: ["bottom"], // don't allow it to flip to be above
                                              },
                                              preventOverflow: {
                                                enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                              },
                                              hide: {
                                                enabled: false, // turn off since needs preventOverflow to be enabled
                                              },
                                            }}
                                          />
                                          <div className="d-flex pt-1">
                                            <img
                                              src="./images/error-icon.svg"
                                              alt=""
                                              hidden={
                                                dateFromValidation.isDateFromValid
                                              }
                                            />
                                            <div
                                              className="advance-search-error"
                                              hidden={
                                                dateFromValidation.isDateFromValid
                                              }
                                            >
                                              {dateFromValidation.errorMessage}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>

                                  <li className="col-md-4 col-lg-4 col-sm-4 d-flex-align">
                                    <div className=" d-inline form-group mt-0 w-100">
                                      <div
                                        className="d-flex row"
                                        id="status_field"
                                      >
                                        <label
                                          for="exampleFormControlInput1"
                                          className="pr-0 d-mt-10 col-md-3"
                                        >
                                          Status
                                        </label>

                                        <MultiSelect
                                          options={[
                                            {
                                              label:
                                                "New Return(Single Select)",
                                              value: "NR",
                                            },
                                            { label: "UBL Tagged", value: "C" },
                                            {
                                              label:
                                                "CBL Tagged(Single Select)",
                                              value: "CL",
                                            },
                                            {
                                              label: "Item Detagged",
                                              value: "X",
                                            },
                                            {
                                              label:
                                                "Ready Despatch to DC(Single Select)",
                                              value: "S",
                                            },
                                            {
                                              label: "Despatched to DC",
                                              value: "D",
                                            },
                                            {
                                              label: "Received in DC",
                                              value: "R",
                                            },
                                            {
                                              label: "DC-Missing item",
                                              value: "M",
                                            },
                                            {
                                              label:
                                                "Putaway in DC(Single Select)",
                                              value: "P",
                                            },
                                            {
                                              label:
                                                "Ready Despatch to Supplier",
                                              value: "RD",
                                            },
                                            {
                                              label: "Despatched to Supplier",
                                              value: "DS",
                                            },
                                            {
                                              label: "In transit to Supplier",
                                              value: "F",
                                            },
                                            {
                                              label: "Delivered to Supplier",
                                              value: "G",
                                            },
                                          ]}
                                          className="col-md-9"
                                          //disableSearch={true}
                                          hasSelectAll={false}
                                          onChange={(item) => {
                                            console.log(item);
                                            setSearchAttributes({
                                              ...searchAttributes,
                                              status: item,
                                            });
                                            let temp_status = item.map(
                                              (a, index) => a.value
                                            );
                                            let temp_list_arr = item;
                                            if (
                                              temp_status.length > 0 &&
                                              (temp_status[
                                                temp_status.length - 1
                                              ] == "NR" ||
                                                temp_status[
                                                  temp_status.length - 1
                                                ] == "CL" ||
                                                temp_status[
                                                  temp_status.length - 1
                                                ] == "P" ||
                                                temp_status[
                                                  temp_status.length - 1
                                                ] == "S")
                                            )
                                              setstatusselectedvalues([
                                                item[item.length - 1],
                                              ]);
                                            else if (
                                              temp_status.includes("NR") &&
                                              temp_status.length > 1
                                            ) {
                                              temp_list_arr.splice(
                                                temp_status.indexOf("NR"),
                                                1
                                              );

                                              setstatusselectedvalues(
                                                temp_list_arr
                                              );
                                            } else if (
                                              temp_status.includes("CL") &&
                                              temp_status.length > 1
                                            ) {
                                              temp_list_arr.splice(
                                                temp_status.indexOf("CL"),
                                                1
                                              );
                                              setstatusselectedvalues(
                                                temp_list_arr
                                              );
                                            } else if (
                                              temp_status.includes("S") &&
                                              temp_status.length > 1
                                            ) {
                                              temp_list_arr.splice(
                                                temp_status.indexOf("S"),
                                                1
                                              );
                                              setstatusselectedvalues(
                                                temp_list_arr
                                              );
                                            } else if (
                                              temp_status.includes("P") &&
                                              temp_status.length > 1
                                            ) {
                                              temp_list_arr.splice(
                                                temp_status.indexOf("P"),
                                                1
                                              );
                                              setstatusselectedvalues(
                                                temp_list_arr
                                              );
                                            } else
                                              setstatusselectedvalues(item);
                                          }}
                                          value={statusselectedvalues}
                                          // disableSearch={true}

                                          overrideStrings={{
                                            allItemsAreSelected:
                                              "All items are selected.",
                                            clearSearch: "Clear Search",
                                            clearSelected: "Clear Selected",
                                            noOptions: "No options",
                                            search: "Search",
                                            selectAll: "Select All",
                                            selectAllFiltered:
                                              "Select All (Filtered)",
                                            selectSomeItems: "Select",
                                            create: "Create",
                                          }}
                                          filterOptions={(options, filter) => {
                                            if (!filter) {
                                              return options;
                                            }

                                            return options.filter(({ label }) =>
                                              label
                                                .toLocaleLowerCase()
                                                .includes(
                                                  filter.toLocaleLowerCase()
                                                )
                                            );
                                          }}
                                        />

                                        {/* <Multiselect


                                            options={[{ name: "New Return" ,id:"NR"},{name:"UBL Tagged",id:"C"},{name:"CBL Tagged",id:"CL"},
                                            {name:"Ready Despatch to DC",id:"S"},{name:"Despatched to DC",id:"D"},{name:"Received in DC",id:"R"},
                                            {name:"DC-Missing item",id:"M"},{name:"Putaway in DC",id:"R"},{name:"Ready Despatch to Supplier",id:"R"},
                                            {name:"Despatched to Supplier",id:"R"},{name:"In transit to Supplier",id:"R"},{name:"Delivered to Supplier",id:"R"},
                                           ]}
                                          
                                           keepSearchTerm="false"
                                           onBlur="true"
                                           
                                            selectedValues={statusselectedvalues}// Options to display in the dropdown
                                            onSelect={(item, selected) => {
                                              let temp_status=item.map((a,index)=>a.id);
                                              let temp_list_arr=item;
                                              if(temp_status.includes("NR") && temp_status.length>1)
                                              {
                                                temp_list_arr.splice(temp_status.indexOf("NR"), 1);
                                                
                                                setstatusselectedvalues(temp_list_arr);



                                              }
                                              else if(temp_status.includes("CL") && temp_status.length>1)
                                              {
                                                temp_list_arr.splice(temp_status.indexOf("CL"), 1);
                                                setstatusselectedvalues(temp_list_arr);

                                              }
                                              else if(temp_status.includes("S") && temp_status.length>1)
                                              {
                                                temp_list_arr.splice(temp_status.indexOf("S"), 1);
                                                setstatusselectedvalues(temp_list_arr);

                                              }
                                              else
                                              setstatusselectedvalues(item) }} // Function will trigger on select event
                                            onRemove={(item, selected) => { 
                                              
                                              
                                              
                                              setstatusselectedvalues(item) }}// Function will trigger on remove event

                                            showCheckbox={true}
                                            displayValue="name" // Property name to display in the dropdown options
                                            />*/}
                                      </div>
                                      <div className="date-error">
                                        <img
                                          src="./images/error-icon.svg"
                                          alt=""
                                          hidden={true}
                                        />
                                        <div
                                          className="advance-search-error"
                                          hidden={true}
                                        >
                                          {dateFromValidation.errorMessage}
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="accordion" id="accordionExample">
                              <div className="mt-10">
                                <div
                                  class="advance-search-header"
                                  id="headingOne"
                                >
                                  <h5
                                    id="itemAndSupplier"
                                    class="mb-0"
                                    data-toggle="collapse"
                                    data-target="#collapseOne"
                                    aria-expanded="true"
                                    aria-controls="collapseOne"
                                  >
                                    <button
                                      class="btn collapse-group-btn"
                                      type="button"
                                    >
                                      Item and Supplier
                                    </button>
                                  </h5>
                                </div>

                                <div
                                  id="collapseOne"
                                  class={
                                    isMobile
                                      ? "collapse1 collapse "
                                      : "collapse1 collapse show"
                                  }
                                  aria-labelledby="headingOne"
                                >
                                  <div class="card-body">
                                    <ul className="list-unstyled row">
                                      <li className="col-md-3 col-lg-3 col-sm-3">
                                        <div
                                          className="form-group"
                                          id="multiSelect"
                                        >
                                          <label for="exampleFormControlInput1">
                                            Supplier
                                          </label>

                                          {/* <Multiselect
                                           
                                           showArrow
                                            options={supplier} // Options to display in the dropdown
                                            selectedValues={supplierSelectedValues} // Preselected value to persist in dropdown
                                            onSelect={onSelectSupplierMultiselect} // Function will trigger on select event
                                            onRemove={onRemoveSupplierMultiselect} // Function will trigger on remove event
                                            showCheckbox={true}
                                            customCloseIcon={"X"}
                                            displayValue="name" // Property name to display in the dropdown options
                                          />*/}

                                          <MultiSelect
                                            options={supplier}
                                            value={supplierSelectedValues}
                                            onChange={
                                              onSelectSupplierMultiselect
                                            }
                                            // disableSearch={true}
                                            hasSelectAll={false}
                                            overrideStrings={{
                                              allItemsAreSelected:
                                                "All items are selected.",
                                              clearSearch: "Clear Search",
                                              clearSelected: "Clear Selected",
                                              noOptions: "No options",
                                              search: "Search",
                                              selectAll: "Select All",
                                              selectAllFiltered:
                                                "Select All (Filtered)",
                                              selectSomeItems: "Select",
                                              create: "Create",
                                            }}
                                            filterOptions={(
                                              options,
                                              filter
                                            ) => {
                                              if (!filter) {
                                                return options;
                                              }

                                              return options.filter(
                                                ({ label }) =>
                                                  label
                                                    .toLocaleLowerCase()
                                                    .includes(
                                                      filter.toLocaleLowerCase()
                                                    )
                                              );
                                            }}
                                          />
                                        </div>
                                      </li>
                                      <li className="col-md-3 col-lg-3 col-sm-3 ">
                                        <div className="form-group">
                                          <label for="exampleFormControlInput1">
                                            Supplier PO #
                                          </label>
                                          <input
                                            autocomplete="off"
                                            type="text"
                                            className="form-control"
                                            id="exampleFormControlInput1"
                                            placeholder=""
                                            maxLength={24}
                                            value={searchAttributes.supplierPo}
                                            onChange={handleChangeSupplierPo}
                                          />
                                          <div className="d-flex pt-1">
                                            <img
                                              src="./images/error-icon.svg"
                                              alt=""
                                              hidden={
                                                itemSupplierValidation.isSupplierPOValid
                                              }
                                            />
                                            <div
                                              className="advance-search-error"
                                              hidden={
                                                itemSupplierValidation.isSupplierPOValid
                                              }
                                            >
                                              {"Enter Numbers Only"}
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li className="col-md-3 col-lg-3 col-sm-3 ">
                                        <div className="form-group">
                                          <label for="exampleFormControlSelect1">
                                            Myer Market ID
                                          </label>

                                          <MultiSelect
                                            options={[
                                              {
                                                label: "9000397-Myer Market Id",
                                                value: "9000397",
                                              },
                                            ]}
                                            value={myerMarketId}
                                            onChange={handleMyerMarketId}
                                            // disableSearch={true}
                                            hasSelectAll={false}
                                            overrideStrings={{
                                              allItemsAreSelected:
                                                "9000397-Myer Market Id",
                                              clearSearch: "Clear Search",
                                              clearSelected: "Clear Selected",
                                              noOptions: "No options",
                                              search: "Search",
                                              selectAll: "Select All",
                                              selectAllFiltered:
                                                "Select All (Filtered)",
                                              selectSomeItems: "Select",
                                              create: "Create",
                                            }}
                                            filterOptions={(
                                              options,
                                              filter
                                            ) => {
                                              if (!filter) {
                                                return options;
                                              }

                                              return options.filter(
                                                ({ label }) =>
                                                  label
                                                    .toLocaleLowerCase()
                                                    .includes(
                                                      filter.toLocaleLowerCase()
                                                    )
                                              );
                                            }}
                                          />
                                          {/*  <Multiselect
                                        

                                        options={[{name:"9000397-Myer Market Id",id:"9000397"}]} // Options to display in the dropdown
                                              selectedValues={myerMarketId}
                                              onSelect={(list,selected)=>{setmyerMarketId(list);}} // Function will trigger on select event
                                               onRemove={(list,remove)=>{setmyerMarketId(list);}} // Function will trigger on remove event
                                              showCheckbox={true}
                                              displayValue="name" // Property name to display in the dropdown options
                                         /> */}
                                        </div>
                                      </li>

                                      <li className="col-md-3 col-lg-3 col-sm-3 ">
                                        <div className="form-group">
                                          <label for="exampleFormControlInput1">
                                            Item #
                                          </label>
                                          <input
                                            autocomplete="off"
                                            className="form-control"
                                            id="exampleFormControlInput1"
                                            placeholder=""
                                            maxLength={24}
                                            value={searchAttributes.itemId}
                                            onChange={handleChangeItemId}
                                          />
                                          <div className="d-flex pt-1">
                                            <img
                                              src="./images/error-icon.svg"
                                              alt=""
                                              hidden={
                                                itemSupplierValidation.isItemIDValid
                                              }
                                            />
                                            <div
                                              className="advance-search-error"
                                              hidden={
                                                itemSupplierValidation.isItemIDValid
                                              }
                                            >
                                              {"Enter Numbers Only"}
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                    <ul className="list-unstyled row">
                                      <li className="col-md-3 col-lg-3 col-sm-3 ">
                                        <div className="form-group">
                                          <label for="exampleFormControlInput1">
                                            Brand
                                          </label>
                                          <input
                                            autocomplete="off"
                                            type="email"
                                            className="form-control"
                                            id="exampleFormControlInput1"
                                            placeholder=""
                                            maxLength={60}
                                            value={searchAttributes.brand}
                                            onChange={handleChangeBrand}
                                          />
                                        </div>
                                      </li>
                                      <li className="col-md-3 col-lg-3 col-sm-3 ">
                                        <div
                                          className="form-group"
                                          id="class_grp"
                                        >
                                          <label for="exampleFormControlSelect1">
                                            Class Group
                                          </label>

                                          <MultiSelect
                                            options={classgrp.map(
                                              (item, index) => {
                                                return {
                                                  label:
                                                    item.id + " " + item.name,
                                                  value:
                                                    item.id + " " + item.name,
                                                  id: item.id,
                                                };
                                              }
                                            )} // Options to display in the dropdown
                                            value={classgrpselectedvalue}
                                            onChange={(item) => {
                                              if (item.length > 0) {
                                                setclassgrpselectedvalue([
                                                  item[item.length - 1],
                                                ]);
                                                setSearchAttributes({
                                                  ...searchAttributes,
                                                  classGroup: item,
                                                });
                                              } else
                                                setclassgrpselectedvalue([]);
                                            }}
                                            // disableSearch={true}
                                            hasSelectAll={false}
                                            overrideStrings={{
                                              allItemsAreSelected:
                                                "9000397-Myer Market Id",
                                              clearSearch: "Clear Search",
                                              clearSelected: "Clear Selected",
                                              noOptions: "No options",
                                              search: "Search",
                                              selectAll: "Select All",
                                              selectAllFiltered:
                                                "Select All (Filtered)",
                                              selectSomeItems: "Select",
                                              create: "Create",
                                            }}
                                            filterOptions={(
                                              options,
                                              filter
                                            ) => {
                                              if (!filter) {
                                                return options;
                                              }

                                              return options.filter(
                                                ({ label }) =>
                                                  label
                                                    .toLocaleLowerCase()
                                                    .includes(
                                                      filter.toLocaleLowerCase()
                                                    )
                                              );
                                            }}
                                          />
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-10">
                                <div
                                  class="advance-search-header"
                                  id="headingTwo"
                                >
                                  <h5
                                    class="mb-0"
                                    data-toggle="collapse"
                                    data-target="#collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="collapseTwo"
                                  >
                                    <button
                                      class="btn collapse-group-btn collapsed"
                                      type="button"
                                    >
                                      Tracking
                                    </button>
                                  </h5>
                                </div>
                                <div
                                  id="collapseTwo"
                                  class="collapse1 collapse show"
                                  aria-labelledby="headingTwo"
                                >
                                  <div class="card-body">
                                    <ul className="list-unstyled row">
                                      <li className="col-md-3 col-lg-3 col-sm-3">
                                        <div className="form-group">
                                          <label for="exampleFormControlInput1">
                                            UBL
                                          </label>
                                          <input
                                            autocomplete="off"
                                            className="form-control barcode-image"
                                            id="exampleFormControlInput1"
                                            placeholder=""
                                            value={searchAttributes.ubl}
                                            onChange={handleChangeUBL}
                                            onFocus={() => setshowerrubl(true)}
                                            onBlur={() => setshowerrubl(false)}
                                          />
                                          <div className="d-flex pt-1">
                                            <img
                                              src="./images/error-icon.svg"
                                              alt=""
                                              hidden={
                                                trackingValidation.isUBLValid ||
                                                showerrubl
                                              }
                                            />
                                            <div
                                              className="advance-search-error"
                                              hidden={
                                                trackingValidation.isUBLValid ||
                                                showerrubl
                                              }
                                            >
                                              {"Invalid Barcode!"}
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li className="col-md-3 col-lg-3 col-sm-3 ">
                                        <div className="form-group">
                                          <label for="exampleFormControlInput1">
                                            CBL
                                          </label>
                                          <input
                                            autocomplete="off"
                                            className="form-control barcode-image"
                                            id="exampleFormControlInput1"
                                            placeholder=""
                                            value={searchAttributes.cbl}
                                            onChange={handleChangeCBL}
                                            onBlur={() => setshowerrcbl(false)}
                                            onFocus={() => setshowerrcbl(true)}
                                          />
                                          <div className="d-flex pt-1">
                                            <img
                                              src="./images/error-icon.svg"
                                              alt=""
                                              hidden={
                                                trackingValidation.isCBLValid ||
                                                showerrcbl
                                              }
                                            />
                                            <div
                                              className="advance-search-error"
                                              hidden={
                                                trackingValidation.isCBLValid ||
                                                showerrcbl
                                              }
                                            >
                                              {"Invalid Barcode!"}
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li className="col-md-3 col-lg-3 col-sm-3 ">
                                        <div className="form-group">
                                          <label for="exampleFormControlInput1">
                                            Carrier Ref #
                                          </label>
                                          <input
                                            autocomplete="off"
                                            className="form-control barcode-image"
                                            id="exampleFormControlInput1"
                                            placeholder=""
                                            maxLength={30}
                                            value={searchAttributes.carrierRef}
                                            onChange={handleChangeCarrierRef}
                                          />
                                          <div className="d-flex pt-1">
                                            <img
                                              src="./images/error-icon.svg"
                                              alt=""
                                              hidden={
                                                trackingValidation.isCarrierRefValid
                                              }
                                            />
                                            <div
                                              className="advance-search-error"
                                              hidden={
                                                trackingValidation.isCarrierRefValid
                                              }
                                            >
                                              {"Invalid Carrier Ref!"}
                                            </div>
                                          </div>
                                        </div>
                                      </li>

                                      <li className="col-md-3 col-lg-3 col-sm-3 ">
                                        <div className="form-group">
                                          <label for="exampleFormControlInput1">
                                            Store Manifest #
                                          </label>
                                          <input
                                            autocomplete="off"
                                            className="form-control barcode-image"
                                            id="exampleFormControlInput1"
                                            placeholder=""
                                            value={
                                              searchAttributes.storeManifest
                                            }
                                            onChange={handleChangeStoreManifest}
                                            onFocus={() =>
                                              setshowerrmanifest(true)
                                            }
                                            onBlur={() =>
                                              setshowerrmanifest(false)
                                            }
                                          />
                                          <div className="d-flex pt-1">
                                            <img
                                              src="./images/error-icon.svg"
                                              alt=""
                                              hidden={
                                                trackingValidation.isStoreManifestValid ||
                                                showerrmanifest
                                              }
                                            />
                                            <div
                                              className="advance-search-error"
                                              hidden={
                                                trackingValidation.isStoreManifestValid ||
                                                showerrmanifest
                                              }
                                            >
                                              {"Invalid Manifest!"}
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                    <ul className="list-unstyled row">
                                      <li className="col-md-3 col-lg-3 col-sm-3 ">
                                        <div className="form-group">
                                          <label for="exampleFormControlInput1">
                                            DC Manifest #
                                          </label>
                                          <input
                                            autocomplete="off"
                                            type="text"
                                            className="form-control barcode-image"
                                            id="exampleFormControlInput1"
                                            placeholder=""
                                            value={searchAttributes.dcManifest}
                                            onChange={handleChangeDCManifest}
                                            onFocus={() => {
                                              setshowerrDCmanifest(true);
                                            }}
                                            onBlur={() => {
                                              setshowerrDCmanifest(false);
                                            }}
                                          />
                                          <div className="d-flex pt-1">
                                            <img
                                              src="./images/error-icon.svg"
                                              alt=""
                                              hidden={
                                                trackingValidation.isDCManifestValid ||
                                                showerrDCmanifest
                                              }
                                            />
                                            <div
                                              className="advance-search-error"
                                              hidden={
                                                trackingValidation.isDCManifestValid ||
                                                showerrDCmanifest
                                              }
                                            >
                                              {"Invalid Manifest!"}
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li className="col-md-3 col-lg-3 col-sm-3 ">
                                        <div className="form-group">
                                          <label for="exampleFormControlInput1">
                                            DC Putaway Location
                                          </label>
                                          <input
                                            autocomplete="off"
                                            type="text"
                                            className="form-control barcode-image"
                                            id="exampleFormControlInput1"
                                            placeholder=""
                                            maxLength={20}
                                            value={
                                              searchAttributes.dcPutawayLocation
                                            }
                                            onChange={
                                              handleChangeDCPutawayLocation
                                            }
                                          />
                                          <div className="d-flex pt-1">
                                            <img
                                              src="./images/error-icon.svg"
                                              alt=""
                                              hidden={
                                                trackingValidation.isDCPutawayLocationValid
                                              }
                                            />
                                            <div
                                              className="advance-search-error"
                                              hidden={
                                                trackingValidation.isDCPutawayLocationValid
                                              }
                                            >
                                              {"Invalid DC Putaway Location"}
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-10">
                                <div
                                  class="advance-search-header"
                                  id="headingThree"
                                >
                                  <h5
                                    class="mb-0"
                                    data-toggle="collapse"
                                    data-target="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree"
                                  >
                                    <button
                                      class="btn collapse-group-btn collapsed"
                                      type="button"
                                    >
                                      Returns
                                    </button>
                                  </h5>
                                </div>
                                <div
                                  id="collapseThree"
                                  class={
                                    isMobile
                                      ? "collapse1 collapse "
                                      : "collapse1 collapse "
                                  }
                                  aria-labelledby="headingThree"
                                >
                                  <div class="card-body">
                                    <ul className="list-unstyled row">
                                      <li className="col-md-3 col-lg-3 col-sm-3">
                                        <div className="form-group">
                                          <label for="exampleFormControlInput1">
                                            Return Order #:
                                          </label>
                                          <input
                                            autocomplete="off"
                                            type="text"
                                            className="form-control"
                                            id="exampleFormControlInput1"
                                            placeholder=""
                                            maxLength={24}
                                            value={
                                              searchAttributes.returnOrderNumber
                                            }
                                            onChange={
                                              handleChangeReturnOrderNumber
                                            }
                                          />
                                          <div className="d-flex pt-1">
                                            <img
                                              src="./images/error-icon.svg"
                                              alt=""
                                              hidden={isROValid}
                                            />
                                            <div
                                              className="advance-search-error"
                                              hidden={isROValid}
                                            >
                                              {"Enter Numbers Only"}
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li className="col-md-3 col-lg-3 col-sm-3 ">
                                        <div className="form-group">
                                          <label for="exampleFormControlInput1">
                                            Return Location
                                          </label>

                                          {/*<Multiselect


                                            options={searchAttributes.returnLocation} // Options to display in the dropdown
                                            selectedValues={returnLocationSelectedValues}
                                            onSelect={(item, selected) => { console.log(returnLocationSelectedValues); setReturnLocationSelectedValues(item) }} // Function will trigger on select event
                                            onRemove={(item, selected) => { console.log(returnLocationSelectedValues); setReturnLocationSelectedValues(item) }}// Function will trigger on remove event

                                            showCheckbox={true}
                                            displayValue="name" // Property name to display in the dropdown options
                                          />*/}
                                          <MultiSelect
                                            options={
                                              searchAttributes.returnLocation
                                            }
                                            value={returnLocationSelectedValues}
                                            onChange={
                                              setReturnLocationSelectedValues
                                            }
                                            //  disableSearch={true}
                                            hasSelectAll={false}
                                            overrideStrings={{
                                              allItemsAreSelected: defaultmsg,
                                              clearSearch: "Clear Search",
                                              clearSelected: "Clear Selected",
                                              noOptions: "No options",
                                              search: "Search",
                                              selectAll: "Select All",
                                              selectAllFiltered:
                                                "Select All (Filtered)",
                                              selectSomeItems: "Select",
                                              create: "Create",
                                            }}
                                            filterOptions={(
                                              options,
                                              filter
                                            ) => {
                                              if (!filter) {
                                                return options;
                                              }

                                              return options.filter(
                                                ({ label }) =>
                                                  label
                                                    .toLocaleLowerCase()
                                                    .includes(
                                                      filter.toLocaleLowerCase()
                                                    )
                                              );
                                            }}
                                          />

                                          {/*<select
                                            className="form-control"
                                            id="exampleFormControlSelect1"
                                            value={returnLocationSelectedValues}
                                            onChange={(e)=>setReturnLocationSelectedValues(e.target.value)}
                                          >
                                            <option value={null}>Select</option>
                                            {
                                              searchAttributes.returnLocation.map((a,index)=>{
                                                return(<option value={a}>{a}</option>)
                                              })

                                            }
                                          </select>*/}
                                        </div>
                                      </li>
                                      <li className="col-md-3 col-lg-3 col-sm-3 ">
                                        <div className="form-group">
                                          <label for="exampleFormControlSelect1">
                                            Return Created By
                                          </label>
                                          <input
                                            autocomplete="off"
                                            type="email"
                                            className="form-control"
                                            id="exampleFormControlInput1"
                                            placeholder=""
                                            value={
                                              searchAttributes.returnCreatedBy
                                            }
                                            onChange={
                                              handleChangeReturnCreatedBy
                                            }
                                          />
                                        </div>
                                      </li>

                                      <li className="col-md-3 col-lg-3 col-sm-3 ">
                                        <div className="form-group">
                                          <label for="exampleFormControlInput1">
                                            Return Reason
                                          </label>
                                          {/*<select
                                            className="form-control"
                                            id="exampleFormControlSelect1"
                                            value={searchAttributes.returnReason}
                                            onChange={handleChangeReturnReason}
                                          >
                                            <option>Select</option>
                                            <option>1</option>
                                            
                                          </select>*/}
                                          <MultiSelect
                                            options={[
                                              {
                                                label: "Soiled And Damaged",
                                                value: "Soiled And Damaged",
                                              },
                                              {
                                                label:
                                                  "Wrong Size and/or Colour",
                                                value:
                                                  "Wrong Size and/or Colour",
                                              },
                                              {
                                                label: "Gift registrant return",
                                                value: "Gift registrant return",
                                              },
                                              {
                                                label:
                                                  "Cancelled before fulfil",
                                                value:
                                                  "Cancelled before fulfil",
                                              },
                                              {
                                                label: "Unsuitable gift",
                                                value: "Unsuitable gift",
                                              },
                                              {
                                                label: "Unsuitable",
                                                value: "Unsuitable",
                                              },
                                              {
                                                label: "Security tag",
                                                value: "Security tag",
                                              },
                                              {
                                                label: "Lost in transit",
                                                value: "Lost in transit",
                                              },
                                              {
                                                label: "Competitive Pricing",
                                                value: "Competitive Pricing",
                                              },
                                              {
                                                label: "Change of mind",
                                                value: "Change of mind",
                                              },
                                              {
                                                label: "Incorrect Item Shipped",
                                                value: "Incorrect Item Shipped",
                                              },
                                              {
                                                label: "Faulty Item",
                                                value: "Faulty Item",
                                              },
                                              {
                                                label: "Delivered Damaged",
                                                value: "Delivered Damaged",
                                              },
                                            ]} // Options to display in the dropdown
                                            value={returnreasonselected}
                                            onChange={handleReturnReason}
                                            //  disableSearch={true}
                                            hasSelectAll={false}
                                            overrideStrings={{
                                              allItemsAreSelected:
                                                "All items are selected.",
                                              clearSearch: "Clear Search",
                                              clearSelected: "Clear Selected",
                                              noOptions: "No options",
                                              search: "Search",
                                              selectAll: "Select All",
                                              selectAllFiltered:
                                                "Select All (Filtered)",
                                              selectSomeItems: "Select",
                                              create: "Create",
                                            }}
                                            filterOptions={(
                                              options,
                                              filter
                                            ) => {
                                              if (!filter) {
                                                return options;
                                              }

                                              return options.filter(
                                                ({ label }) =>
                                                  label
                                                    .toLocaleLowerCase()
                                                    .includes(
                                                      filter.toLocaleLowerCase()
                                                    )
                                              );
                                            }}
                                          />

                                          {/*<Multiselect

                                           selectedValues={returnreasonselected}
                                            options={[{name:"Soiled And Damaged"},{name:"Wrong Size and/or Colour"},{name:"Gift registrant return"},{name:"Cancelled before fulfil"},{name:"Unsuitable gift"},{name:"Unsuitable"},{name:"Security tag"},
                                            {name:"Lost in transit"},{name:"Competitive Pricing"},{name:"Change of mind"},{name:"Incorrect Item Shipped"},{name:"Faulty Item"},{name:"Delivered Damaged"}]} // Options to display in the dropdown
                                            onSelect={(item,selected)=>{setreturnreasonselected(item)}} // Function will trigger on select event
                                            onRemove={(item,selected)=>{setreturnreasonselected(item)}}// Function will trigger on remove event
                                            
                                            showCheckbox={true}
                                            displayValue="name" // Property name to display in the dropdown options
                                          />*/}
                                        </div>
                                      </li>
                                    </ul>
                                    <ul className="list-unstyled row">
                                      <li className="col-md-3 col-lg-3 col-sm-3 ">
                                        <div className="form-group">
                                          <label for="exampleFormControlInput1">
                                            Return Channel
                                          </label>
                                          {/*<select
                                            className="form-control"
                                            id="exampleFormControlSelect1"
                                            value={searchAttributes.returnChannel}
                                            onChange={handleChangeReturnChannel}
                                          >
                                            <option value={null}>Select</option>
                                            <option value="Store">MCO</option>
                                            <option value="Call Center">Webcom</option>
                                            
                                          </select>
                                          <Multiselect
  
                                               selectedValues={returnChannelselected}
                                               options={[{name:"MCO",id:"Store"},{name:"Webcom",id:"Call Center"}]} // Options to display in the dropdown
                                               onSelect={(item,selected)=>{setreturnChannelselected(item)}} // Function will trigger on select event
                                               onRemove={(item,selected)=>{setreturnChannelselected(item)}}// Function will trigger on remove event

                                              showCheckbox={true}
                                              displayValue="name" // Property name to display in the dropdown options
                                          />*/}
                                          <MultiSelect
                                            options={[
                                              { label: "MCO", value: "Store" },
                                              {
                                                label: "Webcom",
                                                value: "Call Center",
                                              },
                                            ]} // Options to display in the dropdown
                                            value={returnChannelselected}
                                            onChange={handleReturnChannel}
                                            //   disableSearch={true}
                                            hasSelectAll={false}
                                            overrideStrings={{
                                              allItemsAreSelected:
                                                "All items are selected.",
                                              clearSearch: "Clear Search",
                                              clearSelected: "Clear Selected",
                                              noOptions: "No options",
                                              search: "Search",
                                              selectAll: "Select All",
                                              selectAllFiltered:
                                                "Select All (Filtered)",
                                              selectSomeItems: "Select",
                                              create: "Create",
                                            }}
                                            filterOptions={(
                                              options,
                                              filter
                                            ) => {
                                              if (!filter) {
                                                return options;
                                              }
                                              return options.filter(
                                                ({ label }) =>
                                                  label
                                                    .toLocaleLowerCase()
                                                    .includes(
                                                      filter.toLocaleLowerCase()
                                                    )
                                              );
                                            }}
                                          />
                                        </div>
                                      </li>
                                      <li className="col-md-3 col-lg-3 col-sm-3 ">
                                        <div className="form-group">
                                          <label for="exampleFormControlSelect1">
                                            Detag Reason
                                          </label>

                                          {/*<select
                                            className="form-control"
                                            id="exampleFormControlSelect1"
                                            value={searchAttributes.detagReason}
                                            onChange={handleChangeDetagReason}
                                          >
                                            <option value={null}>Select</option>
                                            <option>Remaining in Store - brand Partner request</option>
                                            <option>Broken item - Disposal</option>
                                           
                                          </select>*/}
                                          {/*<Multiselect


                                            options={[{ name: "Remaining in Store - brand Partner request" },
                                            { name: "Broken item - Disposal" }]}
                                            selectedValues={detagged}// Options to display in the dropdown
                                            onSelect={(item, selected) => { setdetagged(item) }} // Function will trigger on select event
                                            onRemove={(item, selected) => { setdetagged(item) }}// Function will trigger on remove event

                                            showCheckbox={true}
                                            displayValue="name" // Property name to display in the dropdown options
                                          />*/}

                                          <MultiSelect
                                            options={[
                                              {
                                                label:
                                                  "Remaining in Store - brand Partner request",
                                                value:
                                                  "Remaining in Store - brand Partner request",
                                              },
                                              {
                                                label: "Broken item - Disposal",
                                                value: "Broken item - Disposal",
                                              },
                                            ]} // Options to display in the dropdown
                                            value={detagged}
                                            onChange={handleDetagReason}
                                            //  disableSearch={true}
                                            hasSelectAll={false}
                                            overrideStrings={{
                                              allItemsAreSelected:
                                                "All items are selected.",
                                              clearSearch: "Clear Search",
                                              clearSelected: "Clear Selected",
                                              noOptions: "No options",
                                              search: "Search",
                                              selectAll: "Select All",
                                              selectAllFiltered:
                                                "Select All (Filtered)",
                                              selectSomeItems: "Select",
                                              create: "Create",
                                            }}
                                            filterOptions={(
                                              options,
                                              filter
                                            ) => {
                                              if (!filter) {
                                                return options;
                                              }

                                              return options.filter(
                                                ({ label }) =>
                                                  label
                                                    .toLocaleLowerCase()
                                                    .includes(
                                                      filter.toLocaleLowerCase()
                                                    )
                                              );
                                            }}
                                          />
                                        </div>
                                      </li>
                                      <li className="col-md-6 col-lg-6 col-sm-6 ">
                                        <div
                                          className="form-group d-m-0"
                                          id="datePeriodReturn"
                                        >
                                          <label for="exampleFormControlSelect1">
                                            Return Received Period
                                          </label>
                                          <DatePicker
                                            id="date1"
                                            isClearable
                                            showPopperArrow={false}
                                            className="form-control reportdate datepicker"
                                            wrapperClassName="form-control datepicker"
                                            onChangeRaw={
                                              handleDateInputChangeRaw
                                            }
                                            placeholderText={
                                              "From Date - To Date"
                                            }
                                            dateFormat={"dd/MM/yyyy"}
                                            shouldCloseOnSelect={false}
                                            selected={startDateReturnOrder}
                                            autoComplete="off"
                                            //onChange={onChange}

                                            maxDate={
                                              endDateReturnOrder
                                                ? new Date()
                                                : startDateReturnOrder
                                                ? addDays(
                                                    startDateReturnOrder,
                                                    29
                                                  ).getTime() >
                                                  new Date().getTime()
                                                  ? new Date()
                                                  : addDays(
                                                      startDateReturnOrder,
                                                      29
                                                    )
                                                : new Date()
                                            }
                                            includeDateIntervals={
                                              endDateReturnOrder
                                                ? null
                                                : startDateReturnOrder
                                                ? addDays(
                                                    startDateReturnOrder,
                                                    29
                                                  ).getTime() >
                                                  new Date().getTime()
                                                  ? [
                                                      {
                                                        start:
                                                          startDateReturnOrder,
                                                        end: new Date(),
                                                      },
                                                    ]
                                                  : [
                                                      {
                                                        start:
                                                          startDateReturnOrder,
                                                        end: addDays(
                                                          startDateReturnOrder,
                                                          29
                                                        ),
                                                      },
                                                    ]
                                                : null
                                            }
                                            startDate={startDateReturnOrder}
                                            endDate={endDateReturnOrder}
                                            selectsRange
                                            onChange={(date) =>
                                              handleChangeReturnReceivedPeriod(
                                                date
                                              )
                                            }
                                            popperPlacement="bottom"
                                            popperModifiers={{
                                              flip: {
                                                behavior: ["bottom"], // don't allow it to flip to be above
                                              },
                                              preventOverflow: {
                                                enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                              },
                                              hide: {
                                                enabled: false, // turn off since needs preventOverflow to be enabled
                                              },
                                            }}
                                          />
                                        </div>
                                        <div className="d-flex">
                                          <img
                                            src="./images/error-icon.svg"
                                            alt=""
                                            hidden={
                                              returnDateFromValidation.isReturnDateFromValid
                                            }
                                          />
                                          <div
                                            className="advance-search-error"
                                            hidden={
                                              returnDateFromValidation.isReturnDateFromValid
                                            }
                                          >
                                            {
                                              returnDateFromValidation.errorMessage
                                            }
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-10">
                                <div
                                  class="advance-search-header"
                                  id="headingFour"
                                >
                                  <h5
                                    class="mb-0"
                                    data-toggle="collapse"
                                    data-target="#collapseFour"
                                    aria-expanded="false"
                                    aria-controls="collapseFour"
                                  >
                                    <button
                                      class="btn collapse-group-btn collapsed"
                                      type="button"
                                    >
                                      Customer
                                    </button>
                                  </h5>
                                </div>
                                <div
                                  id="collapseFour"
                                  class={
                                    isMobile
                                      ? "collapse1 collapse "
                                      : "collapse1 collapse "
                                  }
                                  aria-labelledby="headingFour"
                                >
                                  <div class="card-body">
                                    <ul className="list-unstyled row">
                                      <li className="col-md-3 col-lg-3 col-sm-3 ">
                                        <div className="form-group">
                                          <label for="exampleFormControlInput1">
                                            Customer Order #
                                          </label>
                                          <input
                                            autocomplete="off"
                                            type="text"
                                            className="form-control"
                                            id="exampleFormControlInput1"
                                            placeholder=""
                                            maxLength={24}
                                            name="custOrderNo"
                                            value={
                                              searchAttributes.salesOrderNo
                                            }
                                            onChange={handleChangeSalesOrderNo}
                                          />
                                          <div className="d-flex pt-1">
                                            <img
                                              src="./images/error-icon.svg"
                                              alt=""
                                              hidden={
                                                customerValidation.isSalesOrderNumValid
                                              }
                                            />
                                            <div
                                              className="advance-search-error"
                                              hidden={
                                                customerValidation.isSalesOrderNumValid
                                              }
                                            >
                                              {"Enter Numbers Only"}
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="button-group text-right">
                              <button
                                type="button"
                                className="btn rtn-link"
                                id="clearSearch"
                                onClick={onClear}
                              >
                                Clear
                              </button>
                              <button
                                type="button"
                                className="btn rtn-search"
                                id="AdvanceSearch-Click"
                                onClick={onAdvanceSearch}
                                disabled={
                                  isAdvanceSearchDisabled ||
                                  !(
                                    searchAttributes.returnOrderNumber ||
                                    returnLocationSelectedValues.length > 0 ||
                                    searchAttributes.returnCreatedBy ||
                                    returnreasonselected.length > 0 ||
                                    returnChannelselected.length > 0 ||
                                    detagged.length > 0 ||
                                    startDateReturnOrder ||
                                    endDateReturnOrder ||
                                    searchAttributes.firstName ||
                                    searchAttributes.lastName ||
                                    searchAttributes.phoneNumber ||
                                    searchAttributes.salesOrderNo ||
                                    searchAttributes.ubl ||
                                    searchAttributes.cbl ||
                                    searchAttributes.storeManifest ||
                                    searchAttributes.supplierPo ||
                                    myerMarketId.length > 0 ||
                                    searchAttributes.itemId ||
                                    searchAttributes.brand ||
                                    classgrpselectedvalue.length > 0 ||
                                    searchAttributes.dcManifest ||
                                    statusselectedvalues.length > 0 ||
                                    searchAttributes.carrierRef ||
                                    startDate ||
                                    endDate ||
                                    searchAttributes.dcPutawayLocation
                                  ) ||
                                  !(
                                    searchAttributes.ubl != "" ||
                                    searchAttributes.cbl != "" ||
                                    searchAttributes.salesOrderNo != "" ||
                                    searchAttributes.returnOrderNumber != "" ||
                                    searchAttributes.supplierPo != "" ||
                                    searchAttributes.dcManifest != "" ||
                                    searchAttributes.storeManifest != "" ||
                                    searchAttributes.dcPutawayLocation != "" ||
                                    searchAttributes.carrierRef != "" ||
                                    startDate ||
                                    endDate
                                  ) ||
                                  !(
                                    dateFromValidation.isDateFromValid &&
                                    itemSupplierValidation.isSupplierPOValid &&
                                    itemSupplierValidation.isItemIDValid &&
                                    trackingValidation.isUBLValid &&
                                    trackingValidation.isCBLValid &&
                                    trackingValidation.isCarrierRefValid &&
                                    trackingValidation.isStoreManifestValid &&
                                    trackingValidation.isDCManifestValid &&
                                    trackingValidation.isDCPutawayLocationValid &&
                                    isROValid &&
                                    returnDateFromValidation.isReturnDateFromValid &&
                                    customerValidation.isPhoneValid &&
                                    customerValidation.isSalesOrderNumValid
                                  )
                                }
                              >
                                Search
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  {Invalidreturn ? (
                    <div class="search-error">
                      <img class="pb-2" src="./images/error-icon.svg" alt="" />
                      <span>Data doesn't match logged in Location</span>
                    </div>
                  ) : null}
                </div>
                <div class="searchorderSearchdiv">
                  <button
                    type="submit"
                    class="searchorderSearch"
                    disabled={searchAttributes.orderId.length == 0}
                  >
                    Search
                  </button>
                </div>
              </form>
            </section>

            {/* Search Results page */}

            <section className="rtn-search-results ">
              {searchAttributes.search === true && !loader ? (
                <Search
                  details={searchAttributes.details}
                  AdvanceSearch={searchAttributes.AdvanceSearch}
                  displayName={state1.displayName}
                  storeNo={state1.storeNo}
                  filteredObject={filteredObject}
                  token={state1.token}
                  showItemTaggingMessage={showItemTaggingMessage}
                />
              ) : !loader ? (
                <div className="img-with-text">
                  <img src="./images/Item-not-found.svg" alt="" />
                  <p className="no-item-found">No items found</p>
                </div>
              ) : null}
            </section>
          </main>
        </div>
      </div>
      {loader ? <LoadingIndicator /> : null}
      <div className="topCenter" hidden={!itemTaggingToast}>
        <Toast
          id="toast"
          show={itemTaggingToast}
          onClose={() => setItemTaggingToast(false)}
          delay={5000}
          autohide
        >
          <div className="toast__content">
            <div>
              <span class="vertical-toast-border"></span>
              <div className="tickmark" style={{ height: "100%" }}>
                <img
                  className="tickmark"
                  src="./images/tick-circle-icon.svg"
                  alt=""
                />
              </div>
              <div className="pt-10">
                <p className="toast__type">Success</p>
                <p className="toast__message">{toastmsg}</p>
              </div>
              <div>
                <button
                  type="button"
                  className="close toast-close"
                  data-dismiss="toast"
                  aria-label="Close"
                  onClick={() => setItemTaggingToast(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>
        </Toast>
      </div>
      <div
        className="modal fade loginModal"
        id="errorModalCenter1"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content modal-radius">
            <div className="modal-header error-popup-header-bg">
              <h5
                className="modal-title confirmation-style"
                id="errorModalCenterTitle"
              >
                Error
              </h5>
            </div>
            <div className="modal-body text-align-centre">
              <div>{searchAttributes.errorMsgPopup}</div>
            </div>
            <div className="modal-footer bo-modalbtnftr bt-none">
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-yes"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderLookup;
