import React, { useState,useEffect} from "react";
import $ from "jquery";
import Toast from "react-bootstrap/Toast";
import LoadingIndicator from "components/common/LoadingIndicator";
import DcreceptingService from "services/DcreceptingService";
import moment from "moment";
const Viewcbldetails=(props)=>{
    console.log(props.cbldetails);
const [loader,setLoader]=useState(false); 
const [ublids,setublids]=useState([]);   
const [cbldetails,setcbldetails]=useState(props.cbldetails);
const [checkboxlist,setcheckboxlist]=useState(new Array(props.cbldetails.length).fill(false));
const user = JSON.parse(localStorage.getItem("User"));
const [toggle,settoggle]=useState(true);
const [detail_1,setdetail_1]=useState({});
useEffect(()=>{},toggle);
const checked=(index,ublid)=>{
    settoggle((prev)=>!prev);
    console.log(checkboxlist);
 let temp=checkboxlist;
 temp[index]=!temp[index];
 setcheckboxlist(temp);   
 if(ublids.includes(ublid))
 {
     let temp_index=-1;
     let arr=ublids;
     for(let i=0;i<ublids.length;i++)
     if(ublids[i]==ublid)
     temp_index=i;
    arr.splice(temp_index, 1);
    setublids(arr);
 }
 else
 {

    let arr=ublids;
    arr.push(ublid);
    setublids(arr);

 }
}



const renderData=()=>{
    console.log(cbldetails);
    return (<>
    {cbldetails.map((item,index)=>{
        console.log(item.enable);
     return(
        <div className="sur-list">
        <div>
          <span>Item #: </span>
          <span>{item.itemId}</span>
          <span>
              { (user.tempDCTM || props.inpg)?null:
            <input
              type="checkbox"
              disabled={!(item.ublStatus=="D") }
              checked={checkboxlist[index] && (item.ublStatus=="D")}
              onClick={()=>{checked(index,item.ublId)}}
              value={item.ublId}
              
            />}
          </span>
          <p>{item.itemDescr}</p>
          <p>{item.supplierNo} | {item.supplierName}</p>
        </div>
        <div className="sur-bc-view">
          <p className="barcode-font"
         
          >{item.ublId}</p>
          <a
             data-toggle="collapse"
             href={"#collapseExample"}
             role="button"
             aria-expanded="false"
             data-placement="right"
             aria-controls="collapseExample"
             onClick={()=>{setdetail_1(item); $("#myModal2").modal();}}           
          >
            <i class="fas fa-eye"></i>View
          </a>
        </div>
      </div>
     )}
    )}
    </>)

}
const receiveUBL=()=>{
    setLoader(true);
    DcreceptingService.receiveUBL(ublids,user.displayName,[props.manifestno],user.storeNo,user.token,props.DCrec,props.cbl.length==7?null:[props.cbl])
    .then((response)=>{
        console.log(response);
     if(response.updateRecieveUbl.message=="Receive UBL Order Details Updated")
     {
         let manifestdetail=props.manifestdetail;
         for(let i=0;i<manifestdetail.length;i++)
         if(ublids.includes(manifestdetail[i].ublId))
         manifestdetail[i].ublStatus="M";
         let temp_cbldetails=cbldetails;
         for(let i=0;i<temp_cbldetails.length;i++)
         { console.log(ublids[ublids.length-1]==temp_cbldetails[i].ublId,temp_cbldetails[i].ublId,ublids[ublids.length-1]);
             if(ublids.includes(temp_cbldetails[i].ublId))
            temp_cbldetails[i].ublStatus="M";
        }
        if(manifestdetail.filter((a)=>a.ublStatus=="M").length==manifestdetail.length)
        props.setinpg();
         console.log(temp_cbldetails);
         setcbldetails(temp_cbldetails);
         props.setmanifestdetail(manifestdetail);
         setublids([]);
         setcheckboxlist(new Array(props.cbldetails.length).fill(false));
         settoggle((prev)=>!prev);
         setublids([]);
         setcheckboxlist(new Array(props.cbldetails.length).fill(false));
             }
     setLoader(false);
    }).catch(err=>{console.log(err);setLoader(false);})

}
return(
    <>
      <section className="dcreceipting-header cbl_header" >
      <i class="fa fa-arrow-left blackcolor back_arrow " aria-hidden="true" 
      
      onClick={props.back}></i>
        <p className="cblName">
        {props.cbl.length==7?'UBL':'CBL'}#:{props.cbl}</p>
        <p className="total-text">Total:
        {props.cbldetails.length}</p>
      </section>
      <section className="dcreceipting-header alert-msg" >
        Select Checkbox for missing items, If any.
      </section>
      <section className="scanned-ubl-results min-height-cbl" >
      {renderData()}
      </section>
      <section className="dcru-buttons">
            <button
                  type="button"    
                  className="recieve_manifest"
                  disabled={ user.tempDCTM || props.inpg || checkboxlist.filter((a)=>a==true).length==0}
                  onClick={()=>{ $("#errorModalCenter51").modal();}}
            >
             Flag Missing Items
            </button>
        </section>
        <div
                className="modal fade loginModal"
                id="errorModalCenter51"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="errorModalCenterTitle"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-z" role="document">
                    <div className="modal-content modal-radius">
                        <div className="modal-header confirmation-header">
                            <h5 className="modal-title confirmation-style" id="errorModalCenterTitle">
                                Confirmation
                            </h5>

                        </div>
                        <div className="modal-body text-align-centre" >
                            
                            <div className="pt10">
                            Would you like to declare selected Item(s) as missing?
                                </div>
                        </div>
                        <div className="modal-footer bo-modalbtnftr bt-none justify-content-center justify-content-md-end">
                 
                        <button type="button" 
                    className="text-dark text-decoration-none 
                     btn bo-btnprimary
                     border border-secondary
                     confirm-no"
                     data-dismiss="modal"
                     style={{width:"45%"}}>
                                No
                            </button>
                            <button
                                data-dismiss="modal"
                                type="button"
                                className=" btn bo-btnprimary confirm-yes"
                                onClick={receiveUBL}
                                style={{width:"45%"}}>
                                 Yes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="modal summary-modal right fade"
             id="myModal2"
             tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
            <div class="modal-dialog" role="document">
                <div class="modal-content">

                    <div class="modal-header">
                        
                        <h4 class="modal-title" id="myModalLabel">View Summary</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                          </button>
                    </div>
                    <div class="modal-body">
                    <div class="d-flex bd-highlight">
                        <div class="bd-highlight item-num">
                            Item #: <span>{detail_1.itemId}</span>
                           
                        </div>
                       
                        <div class="ml-auto view-det-link bd-highlight">
                            <div class="barcode-font">
                              {detail_1.ublId}
                            </div>
                        </div>
                    </div>

                    <div class=" item-name mb-3"> {detail_1.itemDescr} </div>

                    <div class="card">
                        <div class="card-header">
                            <div className="row">
                               
                                <div className="col-md-6 col-xs-12">
                                     <h6>UBL:{detail_1.ublId}</h6>
                                </div>
                            </div>
                            
                        </div>
                        <div class="card-body">
                            <div className="row">
                            <div className="col-md-6 col-xs-12 mb-3">
                                     <h6>Customer Order#</h6>
                                     <span>{detail_1.saleOrderNum}</span>
                                </div>
                                <div className="col-md-6 col-xs-12 mb-3">
                                  <h6> Quantity</h6>
                                  <span>{1}</span>
                                </div>
                                <div className="col-md-6 col-xs-12 mb-3">
                                     <h6>Supplier PO#</h6>
                                     <span>{detail_1.supplierPo}</span>
                                </div>
                                <div className="col-md-6 col-xs-12 mb-3">
                                     <h6>Return Location</h6>
                                     <span>{detail_1.returnLocation}</span>
                                </div>
                                <div className="col-md-6 col-xs-12 mb-3">
                                     <h6>Supplier </h6>
                                     <span>{detail_1.supplierNo} | {detail_1.supplierName}</span><br/>
                                     
                                     {detail_1.addLine1?<span
                                  style={{
                                    fontSize:"11px",
                                    display:"inherit",
                                    marginTop:"5px"
                                  }}> <img
                          src="./images/location-icon.svg"
                          alt=""    
                          style={{height:"15px",
                          marginRight:"4px",
                          float:"left"}}
                        />
                        <p style={{                          
                          display:"inline"
                        }}>{detail_1.addLine1+","+(detail_1.addLine2?(detail_1.addLine2+","):"")+(detail_1.addLine3?(detail_1.addLine3+","):"")+detail_1.city+","+detail_1.state+"-"+detail_1.postCode}</p>
                        </span>:null}
                                </div>
                                <div className="col-md-6 col-xs-12 mb-3">
                                     <h6>Return Reason</h6>
                                     <span>{detail_1.returnReason}</span>
                                </div>
                                <div className="col-md-6 col-xs-12 mb-3">
                                     <h6>Brand</h6>
                                     <span>{detail_1.brand}</span>
                                </div>



 

                                


                                
                                <div className="col-md-6 col-xs-12 mb-3">
                                     <h6>Return Order#</h6>
                                     <span>{detail_1.returnOrderNum}</span>
                                </div>
                                <div className="col-md-6 col-xs-12 ">
          <h6>Status</h6>
          <span>{detail_1.ublStatus=="R"?"Received at DC":
          detail_1.ublStatus=="M"?"Item Missing":
          detail_1.ublStatus=="RD"?"Despatched to Supplier":
          detail_1.ublStatus=="SD"?"Ready Despatch to Supplier":
          detail_1.ublStatus=="D"?"Despatched to DC"
          :""}|{user.storeName} ,{ detail_1.updatedTimestamp?
            (moment.utc(detail_1.updatedTimestamp).format("DD-MM-YYYY | hh:mm A")):null}
          
          </span>
    </div>
                            </div>
                        </div>
                    </div>

                    </div>

                </div>
            </div>
        </div>
        {loader ? <LoadingIndicator /> : null}
    </>
)


}
export default Viewcbldetails;