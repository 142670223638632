export const fieldMapper = {
  searchFilterHeader: {
    id: "Sl No",
    brand: "Brand",
    classGroup: "Class Group",
    dateFrom: "Date From",
    dateTo: "Date To",
    supplierNames: "Suppliers",
    carrierRef: "Carrier Ref",
    cbl: "CBL",
    dcManifest: "Dc Manifest",
    dcPutawayLocation: "Dc Putaway Location",
    detagReason: "Detag Reason",
    itemId: "Item Id",
    myerMarketId: "Myer Market Id",
    orderId: "Order Id",
    returnChannel: "Return Channel",
    returnCreatedBy: "",
    returnLocation: "Return Location",
    returnOrderNumber: "Return Order Number",
    returnReason: "Return Reason",
    salesOrderNo: "Sales Order Number",
    status: "Status",
    supplierPo: "Supplier PO",
    ubl: "UBL",
    All: "All",
    ItemId: "Item ID",
    CustomerOrder: "Customer Order #",
    ReturnOrder: "Return Order #",
    startDate: "Return Receive from",
    endDate: "Return Receive to",
  },
  returnOrderIdReport: {
    slNo: "Sl No",
    item: "Item Number",
    itemDesc: "Item Desc",
    // qty: "Qty",
    reasonCode: "Return Reason",
    //reasonNotes: "Reason Notes",
    returnReason: "Return Reason",
    // returnNotes:"Return Notes",
    retailPrice: "Retail Price",
    supplier: "Supplier",
    //supplierInvoice: "Supplier Invoice",
    supplierPo: "Supplier PO",
    // itemClsGrp: "Item Class Group",
    // itemTPC: "Item TPC",
    brand: "Brand",
    //returnOrderNum: "Return Order Num",
    returnOrderNumber: "Return Order No",
    //returnReceivedFrom: "Retrun Received Date",
    returnCreatedOn: "Return Received Date",
    // returnCreatedBy: "Return Created By",
    //returnLocation: "Return Location",
    //itemDestination: "Item Destination",
    salesOrder: "Sales Order No",
  },
  CUBLreport: {
    id: "Sl No",
    //storeId: "Store ID",
    returnOrderNo: "Return Order No",
    saleOrderNo: "Sales Order No",
    itemNum: "Item Id",
    itemDesc: "Item Description",
    // qty: "Qty",
    brand: "Brand",
    supplierName: "Supplier Name",
    cublId: "CUBL ID",
    ublId: "UBL ID",
    supplierPo: "Supplier PO",
    returnLocation: "Return Location",
  },
  //storeId: "Store ID",
  // id: "#Index",
  slNo: "Sl No",
  returnOrderNum: "Return Order No",
  salesOrderNumber: "Sales Order No",
  itemNum: "Item Id",
  date: "Return Received Date",
  itemDesc: "Item Description",
  // qty: "Qty",
  brand: "Brand",
  supplier: "Supplier",
  //cublId: "CUBL ID",
  ublId: "UBL ID",
  supplierPo: "Supplier PO",
  //returnLocation: "Return Location",
  reasonNotes: "Return reason",
  //returnNotes: "Return Notes",
  //itemNum: "Item Num",
  // itemDesc: "Item Desc",
  returnLocation: "Return Location",
  reasonCode: "Return Reason",
  //reasonNotes: "Reason Notes",

  //retailPrice: "Retail Price",
  supplier: "Supplier",
  //supplierInvoice: "Supplier Invoice",
  supplierPO: "Supplier PO",
  //itemClsGrp: "Item Class Group",
  //itemTPC: "Item TPC",

  //returnOrderNum: "Return Order Num",
  //returnCreatedBy: "Return Created By",

  //itemDestination: "Item Destination",
  //salesOrderNum: "Sales Order Num",
};

export const columnOrder = [
  "item",
  "itemDesc",
  "returnReason",
  "reasonCode",
  "reasonNotes",
  "retailPrice",
  "supplier",
  "supplierInvoice",
  "supplierPo",
  "supplierPO",
  "itemClsGrp",
  "itemTPC",
  "returnOrderNum",
  "returnCreatedBy",
  "itemDestination",
  "salesOrderNum",
  "storeId",
  "returnOrderNo",
  "saleOrderNo",
  "itemId",
  "itemDescription",
  "qty",
  "brand",
  "returnOrderNumber",
  "supplierName",
  "cublId",
  "ublId",
  "supplierPo",
  "returnLocation",
  "salesOrder",
];
