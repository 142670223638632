import React, { useEffect, useState } from "react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { ExportXLSX } from "components/common/xlsReports/ExportXLSX";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import { MDBDataTableV5 } from "mdbreact";
import data from "./Datatable";
import CreateShipment from "../../../services/CreateShipmentService";
import LoadingIndicator from "components/common/LoadingIndicator";
import Pagination from "components/common/Pagination";
const ManifestReport = (props) => {
  const createShipment = new CreateShipment();
  const [showloader, setshowloader] = useState(false);
  const [SearchTerm, setSearchTerm] = useState("");
  const [row_1, setrow] = useState(props.manifestdetails);
  const [sortToggle, setSortToggle] = useState(true);
  const [msg, setmsg] = useState("");
  const [msgheader, setmsgheader] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  useEffect(() => {}, [sortToggle, SearchTerm, currentPage]);
  let xlxData = [];
  let renderXLXData = <div></div>;
  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 700;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);
  if (props.manifestdetails) {
    // consider variable data if we want to export excel with the details that is present only in the
    renderXLXData = props.manifestdetails.map((item, index) => {
      xlxData.push({
        "Return Location": `${item.returnLocation ? item.returnLocation : "-"}`,
        "RO#": `${item.returnOrderNum ? item.returnOrderNum : "-"}`,
        "CO#": `${item.saleOrderNum ? item.saleOrderNum : "-"}`,
        "Item#": `${item.itemId ? item.itemId : "-"}`,
        Qty: `${item.qty ? item.qty : 1}`,
        Brand: `${item.brand ? item.brand : "-"}`,
        Supplier: `${item.supplierName ? item.supplierName : "-"}`,
        "CBL#": `${item.cublId ? item.cublId : "-"}`,
        "UBL#": `${item.ublId ? item.ublId : "-"}`,
        "Supplier PO#": `${item.supplierPo ? item.supplierPo : "-"}`,
        "Carrier Ref#": `${item.carrierRef ? item.carrierRef : "-"}`,
      });
      var price = 0;
      return (
        <tr>
          <td>{item.returnLocation ? item.returnLocation : "-"}</td>
          <td>{item.returnOrderNum ? item.returnOrderNum : "-"}</td>
          <td>{item.saleOrderNum ? item.saleOrderNum : "-"}</td>
          <td>{item.itemId ? item.itemId : "-"}</td>
          <td>{item.qty ? item.qty : 1}</td>
          <td>{item.brand ? item.brand : "-"}</td>
          <td>{item.supplierName ? item.supplierName : "-"}</td>
          <td>{item.cublId ? item.cublId : "-"}</td>
          <td>{item.ublId ? item.ublId : "-"}</td>
          <td>{item.supplierPo ? item.supplierPo : "-"}</td>
          <td>{item.carrierRef ? item.carrierRef : "-"}</td>
        </tr>
      );
    });
    if (xlxData.length === 0) {
      xlxData.push({
        "Return Location": ``,
        "RO#": ``,
        "CO#": ``,
        "Item#": ``,
        Qty: ``,
        Brand: ``,
        Supplier: ``,
        "CBL#": ``,
        "UBL#": ``,
        "Supplier PO#": ``,
        "Carrier Ref#": ``,
      });
    }
  }

  const blobToSaveAs = (fileName, blob) => {
    try {
      const arr = new Uint8Array(blob);
      const url = window.URL.createObjectURL(
        new Blob([arr], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (e) {
      console.error("BlobToSaveAs error", e);
    }
  };
  const download = () => {
    setshowloader(true);
    createShipment
      .ViewManifest(
        props.storeNo,
        props.manifestno,
        props.displayName,
        props.token,
        "Y",
        "N"
      )
      .then((response) => {
        blobToSaveAs(
          "ManifestReport",
          response.printManifest.binaryReportData.data
        );
        setshowloader(false);
      })
      .catch((err) => {
        console.log(err);
        setshowloader(false);
      });
  };
  const onSort = () => {
    let sortData = row_1;
    console.log("handleSort2", sortToggle);
    if (!sortToggle) {
      sortData.sort((a, b) => {
        return b.itemId - a.itemId;
      });
    } else {
      sortData.sort((a, b) => {
        return a.itemId - b.itemId;
      });
    }
    console.log(sortData);
    setrow(sortData);
    setSortToggle(!sortToggle);
  };

  const handlebutton = () => {
    setshowloader(true);
    createShipment
      .ViewManifest(
        props.storeNo,
        props.manifestno,
        props.displayName,
        props.token,
        "N",
        "Y"
      )
      .then((response) => {
        setshowloader(false);
        if(response && response.printManifest && response.printManifest.message=="Success! Manifest data sent for printing")
        $("#exampleModalCenter2").modal();
       else
        $("#exampleModalCenter23").modal();

      })
      .catch((err) => console.log(err));
  };
  console.log(props);
  const value = data;
  const handleOnChange = (e) => {
    setSearchTerm(e.target.value);
    let value = e.target.value.toLowerCase();
    if (value.length > 2) {
      let searchrow = props.manifestdetails.filter((d) => {
        let tempdata = JSON.stringify(d);
        return tempdata.toLowerCase().includes(value);
      });
      setrow(searchrow);
      if (searchrow.length > 0) setCurrentPage(1);
    } else {
      setrow(props.manifestdetails);
      setCurrentPage(1);
    }
  };
  const renderdata = (rows_temp) => {
    return (
      <>
        {rows_temp
          .map((item, index) => {
            return (
              <tr>
                <td class="align-middle" >
                  {item.returnLocation? item.returnLocation:null}
                </td>
                <td class="align-middle" >
                  {item.returnOrderNum ? item.returnOrderNum : "-"}
                </td>
                <td class="align-middle" >
                  {item.saleOrderNum ? item.saleOrderNum : "-"}
                </td>
                <td class="align-middle" >
                  {item.itemId ? item.itemId : "-"}
                </td>
                <td class="align-middle" >
                  {item.qty ? item.qty : 1}
                </td>
                <td class="align-middle" >
                  {item.brand ? item.brand : "-"}
                </td>
                <td class="align-middle" >
                  {item.supplierName ? item.supplierName : "-"}
                </td>
                <td class="align-middle" >
                  {item.cublId ? item.cublId : "-"}
                </td>
                <td class="align-middle" >
                  {item.ublId ? item.ublId : "-"}
                </td>
                <td class="align-middle" >
                  {item.supplierPo ? item.supplierPo : "-"}
                </td>
                <td class="align-middle" >
                  {item.carrierRef ? item.carrierRef : "-"}
                </td>
              </tr>
            );
          })
          .slice(indexOfFirstItem, indexOfLastItem)}
      </>
    );
  };

  const handleClick = (event) => {
    setCurrentPage(Number(event.target.id));
  };

  const handlePrevBtn = () => {
    setCurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };
  const handleNextBtn = () => {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlefourthpage = () => {
    setshowloader(true);
    createShipment
      .DespatchShipment(props.manifestno, props.storeNo, props.token)
      .then((response) => {
        if (
          response.despatch_shipment.message ==
          "Shipment Despatched Successfully"
        )
          props.NavigatetoLanding();
        else {
          setmsgheader("Error");
          setmsg(response.despatch_shipment.message);
          $("#exampleModalCenter1").modal();
        }
        setshowloader(false);
      })
      .catch((err) => {
        console.log(err);
        setshowloader(false);
      });
  };
  const handleback_report = () => {
    props.handleback_report();
  };

  return (
    <>
      <div class="col-md-10">
        <div class="card2  fourthScreen show" id="manifestReport">
          <div class="card-section border_radius">
            <div class="manifest-header">
              <img className="logo-1" src="./images/Logo/logo-blk.png" alt="" />
              <h6 class="manifest_header d-none d-sm-block ">Manifest</h6>
              <div class="manifest_label ">Manifest# :</div>
              <div class="manifest_barcode">
                <p class="manifest_no_display">{props.manifestno}</p>
                <img src="./images/barcode.png" className="barcode_img" />
              </div>
            </div>
            <div class="manifest-container">
              <div class="row">
                <div class="col-md-5">
                  <div class="row">
                    <div class={isMobile ? "col" : "col-md-4"}>
                      <h6 class="fs12 ">To Location:</h6>
                    </div>
                    <div class={isMobile ? "col" : "col-md-6"}>
                      <span>{props.Address.DC_NAME}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="row pr-2">
                    <div class={isMobile ? "col" : "col-md-9 "}>
                      <h6
                        class={
                          isMobile
                            ? "fs12 "
                            : "flr fs12 "
                        }
                      >
                        Date and Time:
                      </h6>
                    </div>
                    <div
                      class={isMobile ? "col" : "col-md-3 "}
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      <span class="fs12">{props.shipementtime}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <div class="row">
                    <div class={isMobile ? "col" : "col-md-4"}>
                      <h6 class="fs12">Carrier:</h6>
                    </div>
                    <div class={isMobile ? "col" : "col-md-6"}>
                      <span>
                        {props.manifestdetails.length > 0
                          ? props.manifestdetails[0].carrierName
                          : null}
                      </span>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
          <br />
          <div
            className="rtn-card-hdr"
            
          >
            <div className="container">
              <div className="row">
                <div className="col-md-6 card-padding">
                  <form
                    className="rtn-table-search"
                    onSubmit={(e) => e.preventDefault}
                  >
                    <input
                      name="field"
                      autocomplete="off"
                      className="form-control input-search col-md-6"
                      type="text"
                      placeholder="Search"
                      id="SearchTerm"
                      value={SearchTerm}
                      onChange={handleOnChange}
                    />
                  </form>
                </div>
                <div className="col-md-6 card-padding">
                  <ul className="rtn-card-icons d-md-block d-none  d-lg-block">
                    <li>
                      <ExportXLSX
                        csvData={xlxData}
                        fileName={"Manifest_Details"}
                      />
                    </li>
                    <li id="Sort">
                      <i className="table-icons" onClick={onSort}>
                        {" "}
                        <img
                          className=""
                          src="./images/table-icons/sorting-icon.svg"
                          alt=""
                        />{" "}
                      </i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="mdb-datatable dt-bootstrap4">
            <div class="row">
              <div
                class="col-sm-12"
                style={{
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              >
                <div class="dataTables_scroll" style={{ textAlign: "left" }}>
                  <div
                    class="mdb-dataTable_scrollBody"
                    style={{ overflow: "auto" }}
                  >
                    <div class="table-responsive">
                      <table
                        class="table table-bordered table-hover table-sm mdb-dataTable text-nowrap fs12"
                        style={{ textAlign: "left" }}
                      >
                       
                        <thead
                          class="mdb-dataTable-head"
                         
                        >
                          <tr>
                            <th
                              class="align-middle"
                             
                            >
                              Return Location
                            </th>
                            <th
                              class="align-middle"
                             
                            >
                              RO#
                            </th>
                            <th
                              class="align-middle"
                            
                            >
                              CO#
                            </th>
                            <th
                              class="align-middle"
                          
                            >
                              Item#
                            </th>
                            <th
                              class="align-middle"
                            
                            >
                              Qty
                            </th>
                            <th
                              class="align-middle"
                            
                            >
                              Brand
                            </th>
                            <th
                              class="align-middle"
                              
                            >
                              Supplier
                            </th>
                            <th
                              class="align-middle"
                            
                            >
                              CBL#
                            </th>
                            <th
                              class="align-middle"
                             
                            >
                              UBL#
                            </th>
                            <th
                              class="align-middle"
                            
                            >
                              Supplier PO#
                            </th>
                            <th
                              class="align-middle"
                             
                            >
                              Carrier Ref#
                            </th>
                          </tr>
                        </thead>
                        <tbody data-test="table-body">
                          {row_1.length > 0
                            ? renderdata(row_1)
                            : "No item found"}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom_count" style={{ marginTop: "20px" }}>
            Total Manifest Qty : {row_1.length}
          </div>

          <section className="paginat pag_ship">
            <Pagination
              className="pagination-bar"
              data={row_1}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              pageNumberLimit={pageNumberLimit}
              maxPageNumberLimit={maxPageNumberLimit}
              minPageNumberLimit={minPageNumberLimit}
              handleClick={handleClick}
              handlePrevBtn={handlePrevBtn}
              handleNextBtn={handleNextBtn}
            />
          </section>
        </div>
      </div>
      <div class="container-fluid section-four">
        <div class="float-right w100">
          <button class="exit-manifest-btn" onClick={()=>{$("#exampleModalCenter323").modal();}}>
            Exit
          </button>
          {isMobile ? null : (
            <button class="view-manifest-btn" onClick={download}>
              <img
                className="pr-10"
                src="./images/table-icons/download-icon.svg"
                alt=""
              />

              {"Download Manifest"}
            </button>
          )}
          <button class="print-manifest-btn" onClick={handlebutton}>
            <img
              className=""
              src="./images/table-icons/printing-icon.svg"
              alt=""
            />{" "}
            {"Print Manifest"}
          </button>
          <input
            type="button"
            value="Despatch Shipment"
            data-toggle="modal"
            data-target="#exampleModalCenter123"
            class="complete-shipment-btn next-button"
            id="closeShipment"
          />
        </div>
      </div>

      <div
        className="modal fade loginModal"
        id="exampleModalCenter1"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-z"
          role="document"
        >
          <div className="modal-content modal-radius">
          <div className={msgheader!="Error"?"modal-header confirmation-header"
            :"modal-header error-popup-header-bg"}>
              <h5
                className="modal-title confirmation-style"
                id="errorModalCenterTitle"
              >
                {msgheader}
              </h5>
            </div>
            <div className="modal-body text-align-centre">
              <div>{msg} </div>
            </div>
            <div className="modal-footer bo-modalbtnftr bt-none">
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-yes"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade loginModal"
        id="exampleModalCenter2"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-z"
          role="document"
        >
          <div className="modal-content modal-radius">
            <div className="modal-header confirmation-header">
              <h5
                className="modal-title confirmation-style"
                id="errorModalCenterTitle"
              >
                Success
              </h5>
            </div>
            <div className="modal-body text-align-centre">
              <div>Data sent to printer successfully </div>
            </div>
            <div className="modal-footer bo-modalbtnftr bt-none">
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-yes"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade loginModal"
        id="exampleModalCenter23"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-z"
          role="document"
        >
          <div className="modal-content modal-radius">
            <div className="modal-header error-popup-header-bg">
              <h5
                className="modal-title confirmation-style"
                id="errorModalCenterTitle"
              >
                Error
              </h5>
            </div>
            <div className="modal-body text-align-centre">
              <div>Failed to print!</div>
            </div>
            <div className="modal-footer bo-modalbtnftr bt-none">
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-yes"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>


      <div
        className="modal fade loginModal"
        id="exampleModalCenter123"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-z"
          role="document"
        >
          <div className="modal-content modal-radius">
            <div className="modal-header confirmation-header">
              <h5
                className="modal-title confirmation-style"
                id="errorModalCenterTitle"
              >
                Confirmation
              </h5>
            </div>
            <div className="modal-body text-align-centre">
              <div>Would you like to Despatch this Shipment?</div>
            </div>
            <div className="modal-footer bo-modalbtnftr bt-none">
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-no"
              >
                No
              </button>
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-yes"
                onClick={handlefourthpage}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade loginModal"
        id="exampleModalCenter323"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-z"
          role="document"
        >
          <div className="modal-content modal-radius">
            <div className="modal-header confirmation-header">
              <h5
                className="modal-title confirmation-style"
                id="errorModalCenterTitle"
              >
                Confirmation
              </h5>
            </div>
            <div className="modal-body text-align-centre">
              <div>Are you sure you want to exit without despatching shipment?</div>
            </div>
            <div className="modal-footer bo-modalbtnftr bt-none">
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-no"
              >
                No
              </button>
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-yes"
                onClick={handleback_report}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      {showloader ? <LoadingIndicator /> : null}
    </>
  );
};
export default ManifestReport;
