import * as types from "redux/actions/ActionTypes";
import initialState from "redux/reducers/initialState";

export default function DcLocationsReducer(
  state = initialState.dcItemPutAway,
  action
) {
  switch (action.type) {
    case types.GET_DC_LOCATION_IN_PROGRESS:
      return {
        ...state,
        pending: true,
      };
    case types.GET_DC_LOCATION_SUCCESS:
      // return {
      //   ...state,
      //   pending: false,
      //   dc_loc_valid: action.dc_loc_valid,
      // };
      return {
        ...state,
        dc_loc_valid: { ...state.dc_loc_valid, ...action.dc_loc_valid },
      };
    case types.GET_DC_LOCATION_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error,
      };

    default:
      return state;
  }
}
