import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  createFilterTable,
  createHeader,
  createSubHeaders,
  createTable,
  processReportBody,
  processReportBodyFilter,
  processReportTableFilterHeader,
  processReportTableHeader,
  reorderReportEntries,
} from "./pdfUtils/utilMethods";

export const generatePdf = (
  reportEntries = [],
  filteredObject,
  header = "Report Header",
  reportType
) => {
  const orderedReportEntries = reorderReportEntries(reportEntries);
  console.log(orderedReportEntries);
  console.log("REPORT ENTITIES", reportEntries);
  console.log("PDF", filteredObject);
  const pdfDocument = new jsPDF("landscape", "mm", "a4");
  var totalPagesExp = "{total_pages_count_string}";

  // First, we are creating the header data

  /**
   * @method processReportTableHeader
   * @description Used for adding report header to the pdf document
   * @param orderedReportEntries - report data in array of objects data type
   * @param reportType - type of report - identifier
   */
  const headerRow = processReportTableHeader(reportEntries, reportType);
  const headerFilterRow = processReportTableFilterHeader(filteredObject);
  // Secondly, like the header, we form the table body data as well

  /**
   * @method processReportBody
   * @description Used for adding report body to the pdf document
   * @param orderedReportEntries - report data in array of objects data type
   * @param reportType - type of report - identifier
   */

  const bodyData = processReportBody(reportEntries, reportType);
  const bodyDataFilter = processReportBodyFilter(filteredObject);

  /**
   * @method createHeader
   * @description Used for adding header to the pdf document
   * @param pdfDocument - document created so far
   * @param header - header coming from the UI invokation of the generatePDF method
   */
  createHeader(pdfDocument, header);

  /**
   * @method createSubHeaders
   * @description Used for adding sub headers to the pdf document
   * @param pdfDocument - document created so far
   */

  createSubHeaders(pdfDocument, filteredObject);

  /**
   * @method createTable
   * @description Used for adding the report table to the pdf document
   * @param pdfDocument - document created so far
   * @param headerRow - header data to be printed on the table's header
   * @param bodyData - body data to be printed on the table's body
   * @param totalPagesExp - Expression for total number of pages in the generated pdf
   */

  createFilterTable(pdfDocument, headerFilterRow, bodyDataFilter);
  createTable(pdfDocument, headerRow, bodyData, totalPagesExp);

  if (typeof pdfDocument.putTotalPages === "function") {
    pdfDocument.putTotalPages(totalPagesExp);
  }

  pdfDocument.save(`${header}.pdf`);
};
