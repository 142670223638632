const UblService = {
  addUblHeader(StoreNo, ublId, dcId, dcName, token, userId) {
    const user = JSON.parse(localStorage.getItem("USER"));
    return fetch(
      process.env.REACT_APP_API_BASE_ENDPOINT +
        process.env.REACT_APP_STORE +
        StoreNo +
        "/ublHead",
      {
        method: "POST",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          Authorization: token,
        },
        body: JSON.stringify({
          ublId: ublId,
          ublOriginType: "1",
          ublOriginId: StoreNo,
          despatchType: "1",
          ublStatus: "C",
          destType: "1",
          destId: dcId,
          destName: dcName,
          employeeId: userId,
          raNumber: null,
          reasonCode: "1",
          comments: null,
          claimNumber: null,
          itemId: "3464374564",
          salesOrderNumber: "7676656",
          supplierOrderNo: "87867767",
          supplierInvoicePo: "67656546",
          returnOrderNum: "8786756543",
          itemDescr: "Julius Marlow Kick Boot",
          itemClassgroup: "1432",
          retailPriceInclAmt: "61",
          itemClassgroupDescr: "Furniture",
        }),
      }
    )
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        } else {
          response = {};
          response.message = "Return order details not found.";
        }

        return response;
      })
      .catch((err) => {
        return err;
      });
  },

  mapCublToUbl(StoreNo, cublId, ublId, token,ShipId=null) {
    const user = JSON.parse(localStorage.getItem("USER"));
    //ublIds should be an array on selected items
    console.log("NETWORK API", cublId, ublId,ShipId);
    return fetch(
      process.env.REACT_APP_API_BASE_ENDPOINT +
        process.env.REACT_APP_STORE +
        StoreNo +
        "/ublHead",
      {
        method: "PUT",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          Authorization: token,
        },
        body: JSON.stringify({
          ublId: ublId,
          ublOriginType: "1",
          ublOriginId: StoreNo,
          cublId: cublId,
          cublOriginType: "1",
          cublOriginId: StoreNo,
          shipId:ShipId?ShipId:"null",

        }),
      }
    )
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        } else {
          response = {};
          response.message = "Return order details not found.";
        }

        return response;
      })
      .catch((err) => {
        return err;
      });
  },
  addCublToUbl(StoreNo, cublId, ublId, token,ShipId=null) {
    const user = JSON.parse(localStorage.getItem("USER"));
    //ublIds should be an array on selected items
    console.log("NETWORK API", cublId, ublId);
    return fetch(
      process.env.REACT_APP_API_BASE_ENDPOINT +
        process.env.REACT_APP_STORE +
        StoreNo +
        "/addCublUbl",
      {
        method: "PUT",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          Authorization: token,
        },
        body: JSON.stringify({
          ublId: ublId,
          ublOriginType: "1",
          ublOriginId: StoreNo,
          cublId: cublId,
          cublOriginType: "1",
          cublOriginId: StoreNo,
          shipId:ShipId?ShipId:"null",
        }),
      }
    )
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        } else {
          response = {};
          response.message = "Return order details not found.";
        }

        return response;
      })
      .catch((err) => {
        return err;
      });
  },

  getUblSummary(StoreNo, ublId, token) {
    return fetch(
      process.env.REACT_APP_API_BASE_ENDPOINT +
        process.env.REACT_APP_STORE +
        StoreNo +
        "/viewUblSummary?ublId=" +
        ublId,
      {
        method: "GET",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          Authorization: token,
        },
      }
    )
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        } else {
          response = {};
          response.message = "Return order details not found.";
        }

        return response;
      })
      .catch((err) => {
        return err;
      });
  },
  supplierdet(storeNo,token)
  {
    return fetch(
      process.env.REACT_APP_API_BASE_ENDPOINT +
      process.env.REACT_APP_STORE +
      parseInt(storeNo)+
      "/returnSupplierDet",
    {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        Authorization: token,
      },
    }

    ).then((response) => {
      if (response.status == 200) {
        return response.json();
      } 
      else {
        response = {};
        response.message = "Return order details not found.";
      }

      return response;
    }).catch(err=>console.log(err))
  },
  classgrp(storeNo,token)
  {
    return fetch(
      process.env.REACT_APP_API_BASE_ENDPOINT +
      process.env.REACT_APP_STORE +
      parseInt(storeNo) +
      "/returnClassGrpDet",
    {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        Authorization: token,
      },
    }

    ).then((response) => {
      if (response.status == 200) {
        return response.json();
      } 
      else {
        response = {};
        response.message = "Return order details not found.";
      }

      return response;
    }).catch(err=>console.log(err))
  },

  searchorder(storeNo,
    token,
    ro_number,
    r_location,
    r_createby,
    r_reason,
    r_channel,
    detag,
    date_from,
    date_to,
    f_name,
    l_name,
    p_number,
    s_number,
    ublid,
    cublid,
    manifestNo,
    supplier,
    SupplierPo,
    myer_Market_id,
    item_id,
    Brand,
    clsgrp,
    dc_manifestNo,
    ubl_status,
    carrier_Ref,
    date_from_range,
    date_to_range,
    putaway_location,
    isDC
    ) 
{
  let path;
  if(isDC)
  {
    path=process.env.REACT_APP_API_BASE_ENDPOINT+process.env.REACT_APP_DCID+storeNo+"/searchReturnOrderDC";
  }
  else
  {
    path=process.env.REACT_APP_API_BASE_ENDPOINT+ process.env.REACT_APP_STORE+storeNo+"/searchReturnOrder";
  }
    return fetch(path,
        {
            method: 'POST',
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY,
                'Authorization': token
            },
            body: JSON.stringify({
                returnOrderNum:ro_number,
                returnLocation:r_location,
                returnCreatedBy:r_createby,         
                returnReason:r_reason,           
                returnChannel:r_channel,
                detagReason:detag,
                returnReceivedFrom:date_from,      
                returnReceivedTo:date_to,
               // customerFirstName:f_name,
               // customerLastName:l_name,
               // phoneNumber:p_number,      
                salesOrder:s_number,          
                ublId:ublid,            
                cublId:cublid,           
                storeManifestNumber:manifestNo,           
               // supplier:supplier,           
                supplierPo:SupplierPo,           
                     
                item:item_id,
                brand:Brand,           
                classGroup:clsgrp,            
                dcManifestNumber:dc_manifestNo,    
                ublStatus:ubl_status,
                carrierRef:carrier_Ref,
                supplierNo:myer_Market_id,
                dateFrom:date_from_range,
                dateTo:date_to_range,
                dcPutawayLocation:putaway_location,
                myerMarketId:myer_Market_id?myer_Market_id[0]==="9000397"?"Y":"N":"N"

            })                    
        }).then(response => {

            if (response.status == 200) {

                return response.json();
            }
            else if (response.status == 400) {
                response = {}
                response.message = "Invalid Search";
            }
            else if(response.status == 502){
                response = {}
                response.message = "Internal Server Error";
            }
            else {
                response = {}
                response.message = "Internal Server Error";
            }

            return response;

        }).catch(err => console.log(err) );

},
};
export default UblService;
