import React ,{useState,useEffect} from "react";
import LoadingIndicator from "../../common/LoadingIndicator";
import * as constants from "../../constants";
import scanner from '../../../assets/images/barcodescanner.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEye, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import CreateShipment  from "../../../services/CreateShipmentService";
import $ from "jquery";
import Pagination from "components/common/Pagination";

const Summary=(props)=>{
    let createShipment=new CreateShipment();
    
    
    console.log(props);
    const [barcode,setbarcode]=useState("");
    const [validbarcode, setvalidatebarcode]=useState(true);
    const [items,setItem]=useState(props.item);
    const temp_item_detail=[{cublId:""}];
    const [itemdetail,setitemdetail]=useState(temp_item_detail);
    const [deleteindex,setDeleteindex]=useState("");
    const [dele,setdelete]=useState(true);
    const [msg,setmsg]=useState("");
    const [msgheader,setmsgheader]=useState("");
    const [showloader,setshowLoader]=useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(4);
    const [input_alert,setinputalert]=useState("");
    const [itemtype,setitemtype]=useState(true);
    const [ubldetails, setubldetails] = useState(props.ubldetails);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 700);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const [flag,setflag]=useState(true);
    useEffect(()=>{},[dele,validbarcode]);
    useEffect(() => {
        window.addEventListener("resize", () => {
            const ismobile = window.innerWidth < 700;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false);
    }, [isMobile]);
    const handle=()=>{
        
    }
    const deleteCUBL=()=>{
        var ind=parseInt(deleteindex);
        console.log(ind);
        var del=items;
      
        del.splice(ind, 1);
      
        setItem(del);
       
        setdelete(!dele);
        
        setCurrentPage(1);
        console.log(items);

    }
    const handleback2=()=>{
        props.handleback2(items,ubldetails);
    }
    
    const handlethirdpage=()=>{
        if(items.length==0)
        {
            setmsgheader("Alert");
            setmsg("No CBL/UBL Entered");
            $("#exampleModalCenter1").modal();
            return ;


        }
        
        setshowLoader(true);
      
        createShipment.CreateShipment(props.manifestno,props.storeNo,props.storeName,props.Address.DC,
            props.Address.DC_NAME,props.carrierId,props.carrierName,props.carrierRefno,props.displayName,props.token,props.Address)
        .then((response)=>{
            console.log(response);

           if(response.hasOwnProperty('message') && response.message=="Invalid Search")
           {
               console.log(1);
            setmsgheader("Error");
            setmsg("Invalid Input");
            $("#exampleModalCenter1").modal();
            setshowLoader(false);
            return ;

           }
            console.log(response.shipment.message=="Shipment created Successfully"|| response.shipment.message=="Shipment already present");
           if(response.shipment.message=="Shipment created Successfully"|| response.shipment.message=="Shipment already present")
           {
              
               console.log("success");
            createShipment.addCUBL(props.manifestno,items,props.storeNo,props.displayName,props.token,props.carrierId,props.carrierName,props.carrierRefno)
           .then((response1)=>{
               console.log(response);
               if(response1.shipment.message=="UBL Headers updated" || response1.shipment.message=="CUBL UBL Headers updated")
               {
                   console.log("Success");
                   props.handlethirdpage(items,itemdetail);
                   setshowLoader(false);
                   
                }
               else
              {
                setshowLoader(false);
                setmsgheader("Error");
                setmsg(response1.shipment.message);
                $("#exampleModalCenter1").modal();
              }

           })
           .catch(err=>{
               console.log(err);

           })}
        setshowLoader(false);
        })
        .catch(err=>{console.log(err);setshowLoader(false);});
       
    }
  
const onView=(barcode_1)=>{
    setshowLoader(true); 
    setitemdetail(temp_item_detail);
    createShipment.ViewUBLSummary(props.storeNo,barcode_1,props.token).then((response)=>
    {  
        if(response.UblSummaryDetails.details)
        {
            setitemdetail(response.UblSummaryDetails.details);    
            setshowLoader(false);
            $("#myModal2").modal();                    
        }
        else
        {
            setmsgheader("Error");
            setmsg(response.UblSummaryDetails.message);
            $("#exampleModalCenter1").modal();
            setshowLoader(false);


        }
       

    }
    ).catch(err=>{console.log(err);setshowLoader(false);})
      
  }
const onclearbar=()=>{
   
    setbarcode("");
    setflag(!flag);
}

const renderData = (data) => {
    return (
      <>
        {data
          .map((inputBarcode, index) => {
            if (
              inputBarcode.length === 10 &&
              !isNaN(inputBarcode) &&
              inputBarcode.substr(0,3) ===
                  ("00" + props.storeNo).slice(-3) &&
              inputBarcode.substr(3, 7) > 0
            )
              return (
                <div
                  class="card bg-white min"
                  style={{ minWidth: "200px" }}
                  index={index}
                >
                  <div class="card-header bgselago">
                    <p class="barcode-font">{inputBarcode}</p>
                    <button
                      class="delete-icon"
                      id="removeBtn"
                      data-toggle="modal"
                      value={index}
                      onClick={() => {
                        setitemtype(
                          inputBarcode.length==7
                        );

                        setDeleteindex(index);
                      }}
                      data-target="#exampleModalCenter"
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </div>
                  <div class="card-body  p10">
                    <h6 class="card-title fs12 fw6">{props.Address.DC_NAME}</h6>
                    <p class="card-text fs12">
                      {props.Address.DC}-{props.Address.ADDRESS_LN1},
                      {props.Address.ADDRESS_LN2}
                      {props.Address.ADDRESS_LN3 &&
                      props.Address.ADDRESS_LN3 != "NULL"
                        ? "," + props.Address.ADDRESS_LN3
                        : null}{" "}
                      {props.Address.POST_CD}
                    </p>
                  </div>
                  <div class="card-footer text-center bg-white bluec">
                    <button
                      class="border-none bg-none bluec viewbtn view"
                      onClick={() => {
                        onView(inputBarcode);
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon
                        icon={faEye}
                        className="view"
                      /> View{" "}
                    </button>
                  </div>
                </div>
              );
            else if(inputBarcode.length==7) {
              var ind;
              for (let i = 0; i < props.ubldetails.length; i++)
                if (props.ubldetails[i].UBL_ID == inputBarcode) ind = i;
              return (
                <div
                  class="card bg-white min"
                  index={index}
                  style={{ Width: "200px" }}
                >
                  <div
                    class="card-body"
                    style={{
                      padding: "10px 0px 10px 5px",
                      borderRadius: "5px",
                    }}
                  >
                    <button
                      class="delete-icon"
                      id="removeBtn"
                      data-toggle="modal"
                      value={index}
                      onClick={() => {
                        setitemtype(
                          inputBarcode.length==7
                        );

                        setDeleteindex(index);
                      }}
                      data-target="#exampleModalCenter"
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                    <h6
                      class="card-title"
                      style={{
                        fontSize: "13px",
                        fontWeight: 400,
                      }}
                    >
                      Item #:{props.ubldetails[ind].ITEM_ID}
                    </h6>
                    <p
                      class="card-text"
                      style={{
                        fontWeight: 600,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {props.ubldetails[ind].ITEM_DESCR}
                    </p>
                  </div>
                  <div
                    class="card-footer"
                    style={{
                      margin: "0px",
                      padding: "15px 0px 5px",
                      backgroundColor: "#f9f9fe",
                    }}
                  >
                    <p
                      class="barcode-font"
                      style={{
                        fontSize: "20px",
                        paddingLeft: "5px",
                      }}
                    >
                      {inputBarcode}
                    </p>

                    <button
                      class="border-none bg-none bluec viewbtn view"
                      style={{
                        float: "right",
                        marginTop: "-35px",
                      }}
                      onClick={() => {
                        onView(inputBarcode);
                      }}
                    >
                      <FontAwesomeIcon icon={faEye} className="view" /> View{" "}
                    </button>
                  </div>
                </div>
              );
            }
          })
          .slice(indexOfFirstItem, indexOfLastItem)}
      </>
    );
  };
const handleClick = (event) => {
    setCurrentPage(Number(event.target.id));
  };

  const handlePrevBtn = () => {
    setCurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };
  const handleNextBtn = () => {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

    return(

        <>
        <div class="col-md-10">
         {props.manifestno? <div class="bggray card-sect2_cr manifest-header-carrier">Manifest No: <p class="manifest-number-header">{props.manifestno}</p> </div> :null}

        <div class="card2 thirdScreen show" id="shipment_card">
        <h6 class="cbl-text f14 fw5">{items.length > 0 ?<>View Details</>:null}</h6>
            <div class="cbl-container">
            <a class="blue1 text-decoration-none" onClick={handleback2}><FontAwesomeIcon icon={faArrowLeft} />  
                    <span class="fw5 pl10"> Back</span></a>
                  
                    <div class={isMobile?"card-columns row":"card-columns"} >
                    {items.length > 0 ?renderData(items):(<div className="no-items-found-shipment"
                            style={{top:"40%",left:"40%"}}
                            >
                <img src="./images/Item-not-found.svg" alt="" />
                <p className="no-item-found">No Items found</p>
              </div>)}
            
                </div>
                {items.length > 0 ? (
          <section className="paginat">
          <Pagination
            className="pagination-bar"
            data={items}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            pageNumberLimit={pageNumberLimit}
            maxPageNumberLimit={maxPageNumberLimit}
            minPageNumberLimit={minPageNumberLimit}
            handleClick={handleClick}
            handlePrevBtn={handlePrevBtn}
            handleNextBtn={handleNextBtn}
          />
        </section>
        ) : null}
                
            </div>
                
        </div>
        </div>
        <div class="container-fluid section-four">
            <div class="float-right">
                                <input type="button" value="Create Shipment" 
                                disabled={items.length==0}
                                class="complete-shipment-btn next-button" onClick={handlethirdpage}/>
            </div>
        </div>
       
        <div
                className="modal fade loginModal"
                id="exampleModalCenter"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="errorModalCenterTitle"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-z" role="document">
                    <div className="modal-content modal-radius">
                        <div className="modal-header confirmation-header">
                            <h5 className="modal-title confirmation-style" id="errorModalCenterTitle">
                            Confirmation
                            </h5>

                        </div>
                        <div className="modal-body text-align-centre" >
                            <div>Would you like to remove this <b>{
                                  
                                  itemtype?"UBL":"CBL"
                                }# {items[parseInt(deleteindex)]}</b> ?</div>
                        </div>
                        <div className="modal-footer bo-modalbtnftr bt-none">

                            <button
                                data-dismiss="modal"
                                type="button"
                                className=" btn bo-btnprimary confirm-no"
                            >
                                No
                            </button>
                            <button
                                data-dismiss="modal"
                                type="button"
                                className=" btn bo-btnprimary confirm-yes"
                                onClick={deleteCUBL}
                            >
                                Yes
                            </button>
                        </div>
                    </div>
                </div>
            </div>


        
        <div
                className="modal fade loginModal"
                id="exampleModalCenter1"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="errorModalCenterTitle"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-z" role="document">
                    <div className="modal-content modal-radius">
                    <div className={msgheader!="Error"?"modal-header confirmation-header"
            :"modal-header error-popup-header-bg"}>
                            <h5 className="modal-title confirmation-style" id="errorModalCenterTitle">
                            {msgheader}
                            </h5>

                        </div>
                        <div className="modal-body text-align-centre" >
                            <div>{ msg} </div>
                        </div>
                        <div className="modal-footer bo-modalbtnftr bt-none">

                            <button
                                data-dismiss="modal"
                                type="button"
                                className=" btn bo-btnprimary confirm-yes"
                               
                            >
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal summary-modal right fade" id="myModal2"
                tabindex="-1" role="dialog"
                aria-labelledby="myModalLabel2"

            >

                <div class="modal-dialog" role="document">
                    <div class="modal-content">

                        <div class="modal-header">
                            <h4 class="modal-title" id="myModalLabel2">View Summary</h4>
                            <button type="button" class="close"
                                data-dismiss="modal" aria-label="Close"
                            >
                                <span aria-hidden="true" class="whitetext" onClick={() => { $("#myModal2").modal(); }}>&times;</span>
                            </button>
                        </div>


                            <div class="modal-body">
                            <p class="barcode-font"
                            style={{fontSize:"30px"}}
                            >{(itemdetail[0].cublId != "NULL" && itemdetail[0].cublId) ? itemdetail[0].cublId : itemdetail[0].ublId}</p>
                            <h6 class="card-title fs12"
                            style={{fontWeight:"bolder"}}
                            >{props.Address.DC_NAME}  </h6>
                            <p class=" fs12  pb-1 mb-1" >{props.Address.DC}-{props.Address.ADDRESS_LN1},</p>
                            <p class=" fs12  pb-1 mb-1">  {props.Address.ADDRESS_LN2}{((props.Address.ADDRESS_LN3) && (props.Address.ADDRESS_LN3!= "NULL")) ? ("," + props.Address.ADDRESS_LN3) : null}
                                {props.Address.POST_CD} </p>
                                <table class="table cttable"
                                style={{
                                    textAlign:"left"
                                }}
                                >
                                    <thead class="bg-grey"
                                     style={{
                                        whiteSpace:"nowrap"
                                    }}
                                    >
                                        <th class="fw5 fs12">Supplier</th>
                                        <th class="fw5 fs12">Item#</th>
                                        <th class="fw5 fs12">Customer Order#</th>
                                        <th class="fw5 fs12">Return Order#</th>
                                    </thead>
                                    <tbody>
                                    {itemdetail.map((item,index)=>{
                                        
                                        return (
                                        
                                        <tr>
                                            <td>{item.supplierNo?(item.supplierNo):null} {item.supplierName}</td>
                                            <td>{item.itemId}</td>
                                            <td>{item.saleOrderNo}</td>
                                            <td>{item.returnOrderNo}</td>
                                        </tr>)})}
                                    </tbody>
                                </table>
                                
                            </div>

                        </div>
                    </div>
                </div>
        {showloader?<LoadingIndicator/>:null}      
    </>
    );
}
export default Summary;