import Authentication from "components/Authentication";
import AuthProvider from "components/Authentication/authenticationProvider";
import ConsolidateDispatchModify from "components/ConsolidateDispatch/ModifyCbl/ConsolidateDispatchModify";
import ConsolidateDispatch from "components/ConsolidateDispatch/NewCbl/ConsolidateDispatch";
import CreateDespatchModify from "components/CreateDespatch/CreateDespatchModify/CreateDespatchModify";
import CreateDespatchNew from "components/CreateDespatch/CreateDespatchNew/CreateDespatchNew";
import CreateDcDespatch from "components/DcDespatch/CreateDcDespatch";
import ModifyDcDespatch from "components/DcDespatch/ModifyDcDespatch";
import DcItemPutAway from "components/DcItemPutAway/DcItemPutAway";
import DCItemPutawayModify from "components/DcItemPutAway/DCItemPutawayModify";
import LandingPage from "components/LandingPage/LandingPage";
import OrderLookup from "components/OrderLookup/OrderLookup";
import ModifyShipment from "components/Shipment/ModifyShipment/ModifyShipment";
import Newshipment from "components/Shipment/newShipment/newshipment";
import DCReceiptingUbl from "components/DCReceipting/Ubl.js";
import DCReceiptingMissing from "components/DCReceipting/AddMissing.js";
import DCReceiptingCbl from "components/DCReceipting/Cbl.js";
import DCReceiptingManfiest from "components/DCReceipting/Manifest";
import DCItemTaggingNew from "components/DCItemTagging/DCCreateDespatchNew/DCCreateDespatchNew";
import DCItemTaggingModify from "components/DCItemTagging/DCCreateDespatchModify/DCCreateDespatchModify";
import DCviewManifest from "components/DcDespatch/DCviewManifest";
import { createBrowserHistory } from "history";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./assets/sass/styles.scss";

export const history = createBrowserHistory();
function App() {
  return (
    <div className="App">
      <Router history={history}>
        <AuthProvider>
          <Switch>
            <Route path="/LandingPage" component={LandingPage} />
            <Route path="/OrderLookup" component={OrderLookup} />
            {/* <Route path="/CreateDispatch" component={CreateDispatch} /> */}
            {/* <Route path="/OrderLookupNew" component={OrderLookupNew} /> */}

            <Route path="/CreateShipment" component={Newshipment} />
            <Route path="/ModifyShipment" component={ModifyShipment} />
            <Route path="/DcItemPutAway" component={DcItemPutAway} />
            <Route
              path="/DCItemPutawayModify"
              component={DCItemPutawayModify}
            />
            {/* <Route path="/CreateDispatch" component={CreateDispatch} /> */}
            <Route path="/CreateDespatchNew" component={CreateDespatchNew} />
            <Route
              path="/CreateDespatchModify"
              component={CreateDespatchModify}
            />
            <Route path="/DCCreateDespatchNew" component={DCItemTaggingNew} />
            <Route
              path="/DCCreateDespatchModify"
              component={DCItemTaggingModify}
            />
            <Route path="/CreateCbl" component={ConsolidateDispatch} />
            <Route path="/ModifyCbl" component={ConsolidateDispatchModify} />
            <Route path="/DCReceiptingUbl" component={DCReceiptingUbl} />
            <Route path="/DCReceiptingCbl" component={DCReceiptingCbl} />
            <Route
              path="/DCReceiptingManifest"
              component={DCReceiptingManfiest}
            />
            <Route
              path="/DCReceiptingMissing"
              component={DCReceiptingMissing}
            />
            <Route path="/CreateDcDespatch" component={CreateDcDespatch} />
            <Route path="/ModifyDcDespatch" component={ModifyDcDespatch} />
            <Route
              path="/ViewDcDespatchManifest"
              component={DCviewManifest}/>
            <Route
              history={history}
              exact
              path="/"
              component={Authentication}
            ></Route>
          </Switch>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
