import Header from "components/common/Header";
import React, { useEffect, useState,useRef,useCallback} from "react";
import $ from "jquery";
import Sidebar from "components/common/Sidebar";
import { Link } from "react-router-dom";
import LoadingIndicator from "components/common/LoadingIndicator";
import Toast from "react-bootstrap/Toast";
import * as constants from "../../components/constants";
import DcreceptingService from "services/DcreceptingService";
import DcLocationService from "services/DcLocationService";
import { callLogoutURL } from "components/Authentication/utilities";
import { useIdleTimer } from "react-idle-timer";
import moment from "moment";

const DCReceiptingCbl = () => {
  const user = JSON.parse(localStorage.getItem("User"));
  const [total, settotal] = useState("0");
  const [received, setreceived] = useState("0");
  const [pending, setpending] = useState("0");
  const [loader, setLoader] = useState(false);
  const [ublDetails, setUblDetails] = useState([]);
  const [successToast, setSuccessToast] = useState(false);
  const [toggleNoItemsFound, setToggleNoItemsFound] = useState(false);
  const [toggleUblResults, setToggleUblResults] = useState(false);
  const [toggleButtons, setToggleButtons] = useState(false);
  const [receiveButton, setReceiveButton] = useState(true);
  const [toggleMissingMenu, setToggleMissingMenu] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(true);
  const [errorMessageAlert, setErrorMessageAlert] = useState("");
  const [missingItemsData, setMissingItemsData] = useState([]);
  const [toggleMissingItem, setToggleMissingItem] = useState(false);
  const [invalidUblNum, setInvalidUblNum] = useState(true);
  const [toggleMissingButton, setToggleMissingButton] = useState(false);
  const [missingUbl, setMissingUbl] = useState([]);
  const [addMissingBtn, setAddMissingBtn] = useState(true);
  const [shipId, setShipId] = useState("");
  const [ublStatus, setUblStatus] = useState("");
  const [missingItemModal, setMissingItemModal] = useState(false);
  const [itemId, setItemId] = useState("");
  const [location, setlocation] = useState("");
  const [locationdisable, setlocationdisable] = useState(false);
  const [description, setDescription] = useState("");
  const [cblSpanList, setCblSpanList] = useState([]);
  const [togglescreen, settogglescreen] = useState(true);
  const [DCrec,setDCrec]=useState("");
  const [inprg,setinprg]=useState([]);
  const ubldetails_ref=useRef([]);
  const [viewubl,setviewubl]=useState({});
  const [validate_loc,setValidate_loc]=useState(true); 
  const [Valoc_text,setValoc_text]=useState("");
  const [input, setInput] = useState({
    cblId: "",
  });
  const inputFocus2=useRef(); 
  const  inputFocus=useRef();
  useEffect(()=>{
    inputFocus.current.focus()
  },[]);
  const [timeoutDuration] = useState(
    localStorage.getItem("timeout") ? localStorage.getItem("timeout") : 1800000
  );
  const focus_filed_ref=useRef(false);
 
  const[validchange,setvalidchange]=useState(true);
  const [clickedCbl, setClickedCbl] = useState("");
  useEffect(() => {
    setLoader(true);
    DcreceptingService.searchorder(parseInt(user.storeNo), "C", user.token)
      .then((res) => {
        setLoader(false);
        if (
          res.searchOrder.message != "Couldn't find dispatch quantity details"
        ) {
          if (res.searchOrder.TOTAL_CUBL_DISPATCH_QTY != "")
            setpending(res.searchOrder.TOTAL_CUBL_DISPATCH_QTY);
          else setpending("0");

          setreceived(
            res.searchOrder.TOTAL_CUBL_REC_QTY
              ? res.searchOrder.TOTAL_CUBL_REC_QTY
              : 0
          );

          if (
            res.searchOrder.TOTAL_CUBL_DISPATCH_QTY != "" &&
            res.searchOrder.TOTAL_CUBL_REC_QTY != ""
          )
            settotal(
              res.searchOrder.TOTAL_CUBL_REC_QTY
                ? (
                    parseInt(res.searchOrder.TOTAL_CUBL_DISPATCH_QTY) +
                    parseInt(res.searchOrder.TOTAL_CUBL_REC_QTY)
                  ).toString()
                : res.searchOrder.TOTAL_CUBL_DISPATCH_QTY
            );
          else if (res.searchOrder.TOTAL_CUBL_REC_QTY != "") {
            settotal(res.searchOrder.TOTAL_CUBL_REC_QTY);
          } else if (res.searchOrder.TOTAL_CUBL_DISPATCH_QTY != "")
            settotal(res.searchOrder.TOTAL_CUBL_DISPATCH_QTY);
          else settotal("0");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  }, [togglescreen]);

  const handleOnchange = (e) => {
    const inputBarcode = e.target.value.replace(/[^0-9\+]/g, "");
    setInput({
      ...input,
      [e.target.name]: inputBarcode,
    });
    if (
      (inputBarcode.length === 10 &&
      !isNaN(inputBarcode) &&
          inputBarcode.substr(0, 3)!="000" ) &&
          inputBarcode.substr(3,7)>0 )  
           {
      setInvalidUblNum(false);
    } else {
      setInvalidUblNum(true);
    }
  };

  const errorModal = () => {
  
    //setToggleUblResults(false);
    //setToggleNoItemsFound(false);
    //setToggleButtons(false);
    setLoader(false);
    $("#errorModal").modal();
  };

  if(typeof window!='undefined'){
    console.log("listener",localStorage.getItem("access_token"));
    window.addEventListener("storage",function(e){
       if(localStorage.getItem('access_token') == "null"){
        callLogoutURL()
        return
      }
    })
  }

  useEffect(() => {
    console.log("useeffect in ubl");
    if (input.cblId === "") setInvalidUblNum(true);
    if(localStorage.getItem('access_token') == "null"){
      callLogoutURL()
      return
    }
    window.addEventListener("getScannedDataInDevice", getScannedDataInDevice);
    return () => {
      window.removeEventListener(
        "getScannedDataInDevice",
        getScannedDataInDevice
      );
    };
  }, []);
  // const enableListener = () =>{
  //   window.addEventListener("getScannedDataInDevice", getScannedDataInDevice);
  // }
  // const disableListener = () =>{
  //   window.removeEventListener("getScannedDataInDevice", getScannedDataInDevice);
  // }


  const getScannedDataInDevice = (data) => {
    let inputBarcode = data.detail.toString().trim();
    console.log("useeffect in ubl", inputBarcode);
    if(focus_filed_ref.current)
    {
  
    if (inputBarcode.length === 10 &&
      !isNaN(inputBarcode) &&
          inputBarcode.substr(0, 3)!="000" &&
          inputBarcode.substr(3,7)>0)    {
      setInvalidUblNum(false);
      getUblSummary(true, inputBarcode);
    } else {
      setInvalidUblNum(true);
    }}
  };

  const getUblSummary =async (isScanned, scannedData) => {
  
  
    if(!focus_filed_ref.current)
    {
      console.log("!focused");
      setInput({
        ...input,
        cblId: "",
      });
     
      return;
      
    }
    else{

    let inputBarcode = isScanned ? scannedData : input.cblId;
    if (
      (inputBarcode.length === 10 &&
      !isNaN(inputBarcode) &&
          inputBarcode.substr(0, 3)!="000") &&
          inputBarcode.substr(3,7)>0 )  
           {
      setInvalidUblNum(false);
    } else {
      setInvalidUblNum(true);
    }
    
    if (
      !(inputBarcode.length === 10 &&
      !isNaN(inputBarcode) &&
          inputBarcode.substr(0, 3)!="000" &&
          inputBarcode.substr(3,7)>0
          
          )    
          
    ) {
      
      return ;

    }
   

    setLoader(true);
    inputFocus2.current.blur();

    if (ubldetails_ref.current.length > 0) {
      console.log("Inside function with focus");
      for (let i = 0; i <  ubldetails_ref.current.length; i++) {
        if (ubldetails_ref.current[i][0].cublId == inputBarcode) {
       setErrorMessageAlert("CBL already added", $("#errorModal").modal());
          setLoader(false);
          setInput({
            ...input,
            cblId: "",
          });
         
        
          return;
        }
      }
    }
  
    const res = await fetch(
      process.env.REACT_APP_API_BASE_ENDPOINT +
        process.env.REACT_APP_STORE +
        parseInt(user.storeNo) +
        "/viewUblSummaryDetails?ublId=" +
        inputBarcode,
      {
        method: "GET",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          Authorization: user.token,
        },
      }
    );
    setInput({
      ...input,
      cblId: "",
    });
    const data = await res.json();
    console.log(data);
    if (data.errorMessage === "Bad request") {
    setErrorMessageAlert("Invalid CBL",$("#errorModal").modal());
      setLoader(false);

      
      return;
    }
    if (data.UblSummaryDetails.message === "Ubl Summary Details fetched successfully") {
      if (data.UblSummaryDetails.details[0].ublStatus == "C") {
      setErrorMessageAlert("CBL not yet depatched",$("#errorModal").modal());
        setLoader(false);
       
        return;
      }

      if (!user.tempDCTC &&
        data.UblSummaryDetails.details.length==
        data.UblSummaryDetails.details.filter((a) => (a.ublStatus == "R" || a.ublStatus == "RD" || a.ublStatus == "SD" || a.ublStatus == "DS")).length
      ) {
       setErrorMessageAlert("CBL Already Received",$("#errorModal").modal());
        setLoader(false);
      
        return;
       
      }
      if(user.tempDCTC && ( 
        data.UblSummaryDetails.details.filter((a) => (a.ublStatus == "D")).length ==  data.UblSummaryDetails.details.length
    
        ))
        {
          setErrorMessageAlert("CBL not yet Received ",$("#errorModal").modal());
        setLoader(false);
      
        return;


        }
      if ( 
        data.UblSummaryDetails.details.filter((a) => (a.ublStatus == "R" || a.ublStatus=="M" || a.ublStatus=="RD" || a.ublStatus=="SD" || a.ublStatus=="DS")).length>0)

      {
        let temp_arr_inprg=inprg;
        temp_arr_inprg.push(data.UblSummaryDetails.details[0].cublId);
        setinprg(temp_arr_inprg);
        


      }
      console.log("GET SUCCESS");
      setLoader(false);
      setToggleUblResults(true);
      setToggleNoItemsFound(true);
      setToggleButtons(true);
      console.log("ubldetails length",ublDetails.length);
        console.log(ublDetails);
      let  temp=ubldetails_ref.current;
        temp=[data.UblSummaryDetails.details].concat(temp);
        ubldetails_ref.current=temp;
        inputFocus2.current.focus();
    



        console.log(ubldetails_ref.current.length,"ref_length");
          setUblDetails(temp);
          settogglescreen((prev) => !prev);
          console.log("ubldetails length after ",ublDetails.length);
        console.log(ublDetails);
        $('input[name="cbl"]').each(function ()  {
          this.checked = false;
        });
        if(cblSpanList.length>0)
        for(let i=0;i<cblSpanList.length;i++)
        $('input[value='+cblSpanList[i]+']').each(function ()  {
          this.checked = true;
        });
     

      //setInput({ cblId: "" });
    } else  {
      setErrorMessageAlert("CBL not found", $("#errorModal").modal());
      setLoader(false);
     
  
    }
  }
}

  $(".toggle-all").click(function () {
    $('input[type="checkbox"]').prop("checked", this.checked);
  });

  const handleCheckbox = (e) => {
    const checked = e.target.checked;


    const sid = ublDetails[0][0].shipId;
    setShipId(sid);

    const texts = [];
    $('input[name="cbl"]:checked').each(function () {
      texts.push(this.value);
    });
    setCblSpanList(texts);
    console.log(texts);
  };

  const receiveCBLUBL = async () => {
    setLoader(true);
    let  cubl_recieved=[];
    let cubl_missing=[];
    let ship_list=[];
    for(let i=0;i<ublDetails.length;i++)
    {
      let temp=ublDetails[i];
      console.log(temp);
    
      if(temp.length==temp.filter((a,index)=>a.ublStatus=="R" || a.ublStatus=="D" || a.ublStatus=="RD" || a.ublStatus=="SD" || a.ublStatus == "DS").length)
      cubl_recieved.push(temp[0].cublId);
      else
      cubl_missing.push(temp[0].cublId);

      if(cblSpanList.includes(temp[0].cublId))
    ship_list.push(temp[0].shipId)

    }
    try{

    const res = await fetch(
      process.env.REACT_APP_API_BASE_ENDPOINT +
        process.env.REACT_APP_STORE +
        parseInt(user.storeNo) +
        "/receive",
      {
        method: "PUT",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          Authorization: user.token,
        },
        body: JSON.stringify({
          cublId_R: cubl_recieved.filter((a,index)=>cblSpanList.includes(a)),
          cublId_I: cubl_missing.filter((a,index)=>cblSpanList.includes(a)),
          employeeId: user.displayName,
          shipId: [...new Set(ship_list)],
          recLoc:DCrec
        }),
      }
    );
    const data = await res.json();
    console.log(data);
 
    if (
      data.errorMessage
    ) {
    setErrorMessageAlert("Invalid CBL", $("#errorModal").modal());
      setLoader(false);
     
      setLoader(false);
      return;
    }

    if (data.updateRecieveCublUbl.message === "Receive CUBL Details Could not be Updated") {
      setErrorMessageAlert("Invalid ship ID",$("#errorModal").modal());
      setLoader(false);
     
 
    } else {
      setSuccessToast(true);
      setUblDetails([]);
      setCblSpanList([]);
      ubldetails_ref.current=[];
      setInput({
        ...input,
        cblId: "",
      });
      setLoader(false);
      setToggleUblResults(false);
      setToggleNoItemsFound(false);
      setToggleButtons(false);
      setlocation("");
      setlocationdisable(false);
      setinprg([]);
      $(".locField").removeClass("disabletextbg");
      $(".inputLoc").attr("disabled", false);
      settogglescreen((prev) => !prev);
    }
  }
  catch(e)
  {
    console.log(e);
    setLoader(false);
  }
  };

  const missingItemsMenu = async (e) => {
    setLoader(true);
    setToggleMissingMenu(true);
    setToggleUblResults(false);
    setToggleMenu(false);
    setToggleMissingButton(true);
    setToggleButtons(false);
    const uid = e.target.attributes.getNamedItem("data-value").value;
    console.log(uid);
    let current = document.querySelector(".cblClicked");
    let prevSiblings =
      current.parentElement.previousSibling.previousSibling.lastChild
        .textContent;
    //console.log(prevSiblings);
    setClickedCbl(uid);
    console.log(ublDetails);
    console.log(ublDetails.filter((a)=>a[0].cublId==uid)[0]);
    setMissingItemsData(ublDetails.filter((a)=>a[0].cublId==uid)[0]);
    setToggleMissingItem(true);
    setLoader(false);
  };

  const backToMenu = () => {
    setToggleMissingMenu(false);
    setToggleUblResults(true);
    setToggleMenu(true);
    setToggleMissingButton(false);
    setToggleButtons(true);
    setCblSpanList([]);
  };

  const addMissingItemButton = () => {
    setMissingItemModal(true);
    $("#confirmModal").modal();
  };
  const viewsummary=(barcode_view)=>{
    setLoader(true);
    
    DcLocationService.getUblSummary(user.storeNo, barcode_view, user.token).then((response)=>{
      setLoader(false);
      setviewubl(response.UblSummaryDetails.details[0]);
      $("#collapseExample").modal();

    }).catch(err=>{console.log(err);setLoader(false);})
    
  }


  const addMissingItem = async () => {
    setLoader(true);
    const response = await fetch(
      process.env.REACT_APP_API_BASE_ENDPOINT +
        process.env.REACT_APP_STORE +
        parseInt(user.storeNo)+
        "/receiveUbl",
      {
        method: "PUT",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          Authorization: user.token,
        },
        body: JSON.stringify({
          ublId: missingUbl,
          cublId:[clickedCbl],
          employeeId: user.displayName,
          shipId: [shipId],
          ublStatus: "M",
          recLoc:DCrec
        }),
      }
    );

    const data = await response.json();
    console.log(data.updateRecieveUbl.message);
    if (data.updateRecieveUbl.message !== "Receive UBL Order Details Updated") {
    setErrorMessageAlert("Invalid ship details",$("#errorModal").modal());
      setLoader(false);
     
      
    } else {
      let temp = missingItemsData;
      let temp_cubl=ublDetails;
      let cubl_index;
      for(let i=0;i<ublDetails.length;i++)
      if(temp_cubl[i][0].cublId==temp[0].cublId)
       cubl_index=i;

      for (let i = 0; i < temp.length; i++)
        if  (missingUbl.includes(temp[i].ublId)) 
        {
          temp[i].ublStatus = "M";
          temp_cubl[cubl_index][i].ublStatus="M";
      }
      if(temp.filter((a)=>a.ublStatus=="M").length==temp.length)
      {
        let temp_inprg=inprg;
        temp_inprg.push(temp[0].cublId);
        setinprg(temp_inprg);
      }
      setMissingItemsData(temp);
      setUblDetails(temp_cubl);
      setLoader(false);
      setAddMissingBtn(true);
      $('input[name="UBL_check"]').each(function ()  {
        this.checked = false;
      });
    }
    settogglescreen((prev)=>!prev);

    //console.log(ublDetails);
  };

  const missingItemCheckbox = (e) => {
    let texts=[];
    setAddMissingBtn(true);
    $('input[name="UBL_check"]:checked').each(function ()  {
      texts.push(this.value);
      setAddMissingBtn(false)
     
    });
    const ch = e.target.checked;
    const val = e.currentTarget.value;
    const sid = e.target.attributes.getNamedItem("data-value").value;
    const desc = e.target.attributes.getNamedItem("data-value1").value;
    const iid = e.target.attributes.getNamedItem("data-value2").value;
    const ustatus = e.target.attributes.getNamedItem("data-value3").value;
    console.log(sid, desc, iid, ustatus);
    setShipId(sid);
    setUblStatus(ustatus);
    setMissingUbl(texts);
    
  };

  React.useEffect(() => {
    $(document).click(function (e) {
      if (!$(e.target).is(".panel-body")) {
        $(".collapse").collapse("hide");
      }
    });
  });
  const getsupplierdetails = async (e) => {
    if(location){
    setLoader(true);
    setDCrec(location);
    setvalidchange(false);   
    const res = await fetch(
      process.env.REACT_APP_API_BASE_ENDPOINT +
        process.env.REACT_APP_DC +
        location +
        "/dcLocValid",
      {
        method: "GET",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          Authorization: user.token,
        },
      }
    );
    const data = await res.json();
    const temp =
      data.dc_loc_valid && data.dc_loc_valid.supplierDesc
        ? data.dc_loc_valid.supplierDesc.toString().trim()
        : "";
    setlocation(location + (temp && temp != "" ? "-" + temp : ""));
    setLoader(false);
    const locInput = location.valueOf();
    console.log(locInput);
    setlocationdisable(true);
    inputFocus2.current.focus()
  
  }
};
  const handleOnIdle = (event) => {
    console.log("user is idle", event);
    console.log("last active", getLastActiveTime());
    callLogoutURL();
  };

  const handleOnActive = (event) => {
    console.log("user is active", event);
    console.log("time remaining", getRemainingTime());
    handleReset();
    if(localStorage.getItem('access_token') == "null"){
      callLogoutURL()
      return
    }
  };

  const handleOnAction = (event) => {
    console.log("user did something", event);
    if(localStorage.getItem('access_token') == "null"){
      callLogoutURL()
      return
    }
  };
  const { getRemainingTime, getLastActiveTime, reset } = useIdleTimer({
    timeout: timeoutDuration,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });
  const handleReset = () => reset();

  return (
    <div id="DCReceipting">
      <Header />
      <div className="container-fluid">
        <div className="row">
          <Sidebar active="DCReceipting" state={user} />
          <main
            role="main"
            className="col-md-9 ml-sm-auto col-lg-10 rtn-main-wrapper"
            style={{ padding: "0px" }}
          >
            <section className="dcreceipting-header" hidden={!toggleMenu}>
              <p>DC RECEIPTING</p>
              <div 
               class="d-none d-lg-block d-md-block"
               style={{
                float:"right",
                marginTop:"-40px"
              }}>
                  {/* {<Breadcrumb crumbs={crumbs} selected={selected} />} */}
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb" style={{fontSize:"12px"}}>
                    <Link
                        to={{ pathname: "/LandingPage", state: user }}
                        className="breadcrumb-item breadcrumb-color pr-10"
                      >
                        Home
                      </Link>
                      <span className="pr-10"> {" >"}</span>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                       DC Receipting
                      </li>
                    </ol>
                  </nav>
                </div>
            </section>

            {/* CBL Active */}
            <div className="row">
              {toggleMenu ? (
                <section className="dcr-fields col-md-8 col-10">
                  <div className="dcr-input-group">
                    <form
                      // className={
                      //   locationdisable
                      //     ? "dcr-input-icon-container disabletextbg"
                      //     : "dcr-input-icon-container"
                      // }
                      className="dcr-input-icon-container locField"
                      onSubmit={(e) => {
                        e.preventDefault();
                        var letterNumber = /^[0-9a-zA-Z]*$/;
                        if(letterNumber.test(location))
                        if(location.length<=7)
                        getsupplierdetails();
                      }}
                    >
                      <i className="fas fa-map-marker-alt ml-10"></i>
                      <input
                        className="inputLoc"
                        type="text"
                        autocomplete="off"
                        value={location}
                        //disabled={locationdisable}
                        placeholder="Scan or Enter Location"
                        name="location"
                        ref={inputFocus}
                       
                        onChange={(e) => {setlocation(e.target.value);   setvalidchange(true);
                          var letterNumber = /^[0-9a-zA-Z]*$/;
                          if(letterNumber.test(e.target.value))
                   { if(e.target.value.length>7)
                    {
                      setValidate_loc(false);
                      setValoc_text("Receive location entered exceed maximum length(7)");
                    }
                    else
                    setValidate_loc(true);
                   }
                   else
                   {
                    setValoc_text("Invalid Location!");
                    setValidate_loc(false);

                   }
                        }}
                      />
                      <img
                        class="dcr-dispatch-scanner-img"
                        src="./images/barcodescanner.png"
                        alt=""
                      />
                    </form>
                    <div className="invalid-ubl-num" 
                  style={{fontSize:"11px"}}
                  hidden={validate_loc}
                 >
                        <img src="./images/error-icon.svg" alt="" />
                       {Valoc_text}
                      </div>
                  </div>
                  <div className="dcr-options">
                    <ul>
                      <li>
                        <Link
                          style={{ textDecoration: "none", color: "black" }}
                          to="/DCReceiptingUbl"
                          class={toggleUblResults?"pointernon":""}
                        >
                          UBL
                        </Link>
                      </li>
                      <li className="active">CBL</li>
                      <li>
                        <Link
                          style={{ textDecoration: "none", color: "black" }}
                          to="/DCReceiptingManifest"
                          class={toggleUblResults?"pointernon":""}
                        >
                          Manifest
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p>
                      <strong>Scan/Enter CBL</strong>
                    </p>
                    <div className="dcr-input-group">
                      <form
                        className={
                          !locationdisable
                            ? "dcr-input-icon-container disabletextbg"
                            : "dcr-input-icon-container"
                        }
                        onSubmit={(e) =>{ getUblSummary(false, "");e.preventDefault();}}
                      >
                        <input
                          type="text"
                          placeholder="Scan/Enter CBL"
                          autocomplete="off"
                          name="cblId"
                          disabled={!locationdisable}
                          value={input.cblId}
                          ref={inputFocus2}
                          onChange={(e) => {
                            const inputBarcode = e.target.value.replace(/[^0-9\+]/g, "");
                            setInput({
                              ...input,
                              [e.target.name]: inputBarcode,
                            });
                            setInvalidUblNum(false);
                          }
                          }
                          
                          onFocus={()=>{focus_filed_ref.current=true;
                            setInvalidUblNum(false);}}
                          onBlur={(e)=>{focus_filed_ref.current=false;
                            handleOnchange(e);
                          }}

                        />
                        <img
                          class="dcr-dispatch-scanner-img"
                          src="./images/barcodescanner.png"
                          alt=""
                        />
                        
                      </form>
                      {invalidUblNum && input.cblId !== "" ? (
                        <div className="invalid-ubl-num">
                          <img src="./images/error-icon.svg" alt="" />
                          Invalid CBL!
                        </div>
                      ) : null}
                    </div>
                  </div>
                </section>
              ) : null}
            </div>

            {/* Scanned results for CBL */}
            {toggleUblResults ? (
              <section
                className="scanned-ubl-results bg-white"
                style={{ minHeight: "65%" }}
              >
                <div className="sur-header">
                  <span>
                    <strong>Scanned results for CBL</strong>
                  </span>
                  <span className="pull-right">
                    <input
                      type="checkbox"
                      className="mr-10 toggle-all"
                      onClick={handleCheckbox}
                      checked={ublDetails.length == cblSpanList.length}
                    disabled={user.tempDCTC || inprg.length>0}
                    />
                    <label>Select All</label>
                  </span>
                </div>
                <div>
                  <div className="cbl-header-results">
                    <span>
                      <strong>CBL</strong>
                    </span>
                    <span>
                      <strong>UBL</strong>
                    </span>
                    <span>&nbsp;</span>
                  </div>
                  {ublDetails.map((detail, detailIdx) => (
                    <div className="cbl-results" key={detailIdx}>
                      <ul>
                        <li>
                          <input
                            type="checkbox"
                            name="cbl"
                           disabled={user.tempDCTC || inprg.includes(detail[0].cublId)}
                            onClick={handleCheckbox}
                            value={detail[0].cublId}
                            
                           
                            // onChange={handleSingleCheck}
                            // name={detail[0].cublId}
                            // checked={isChecked[detail[0].cublId]}
                          />
                          <span className="ublIdSpan">{detail[0].cublId}</span>
                        </li>
                        <li>{detail.length}</li>
                        <li>
                          <i
                            class="fas fa-chevron-right cblClicked"
                            data-value={detail[0].cublId}
                            onClick={missingItemsMenu}
                          ></i>
                        </li>
                      </ul>
                    </div>
                  ))}
                </div>
              </section>
            ) : null}

            {/* Checkbox for missing items */}
            {toggleMissingMenu ? (
              <section className="cbx-missing-items">
                <div className="pt-2 pb-3" style={{background:"#f8f8f8"}}>
                  <i
                    class="fas fa-arrow-left black"
                    onClick={backToMenu}
                    style={{ cursor: "pointer" }}
                  ></i>
                  <span>
                    <strong>CBL#:{clickedCbl}</strong>
                  </span>
                  <span className="total-cbl">
                    <strong>Total {missingItemsData.length}</strong>
                  </span>
                </div>
                <div
                  className="cbx-message mb-3"
                  style={{
                    backgroundColor: "antiquewhite",
                  }}
                >
                  <p>Select Checkbox for missing items, if any.</p>
                </div>
                {missingItemsData.map((detail, detailIdx) => (
                  <div
                    className="scanned-ubl-results border-0"
                    key={detailIdx}
                    style={{ paddingTop: "0px", paddingBottom: "0px" }}
                  >
                    <div className="sur-list sur-list pl-0 pr-0 pb-0">
                      <div>
                        <span
                          style={{
                            padding: "5px 0px",
                          }}
                        >
                          Item #:{" "}
                        </span>
                        <span>{detail.itemId}</span>
                        <span className="chkbxMissing">
                          <input
                            type="checkbox"
                            name="UBL_check"
                            hidden={user.tempDCTC || inprg.includes(detail.cublId)}
                            onClick={missingItemCheckbox}
                            disabled={detail.ublStatus=="M" }
                            value={detail.ublId}
                            data-value={detail.shipId}
                            data-value1={detail.itemDescription}
                            data-value2={detail.itemId}
                            data-value3={detail.ublStatus}
                            // checked={check}
                          />
                        </span>
                        <p>{detail.itemDescription}</p>
                        <p>{detail.supplierNo} | {detail.supplierName}</p>
                      </div>
                      <div className="sur-bc-view">
                        <p className="barcode-font m-0 p-0">{detail.ublId}</p>
                        <a
                          data-toggle="collapse"
                          href={"#collapseExample" + detail.ublId}
                          role="button"
                          aria-expanded="false"
                          data-placement="right"
                          aria-controls="collapseExample"
                          onClick={() => {
                            viewsummary(detail.ublId)
                          }}
                          style={{
                            marginTop: "-30px",
                          }}
                        >
                          <i class="fas fa-eye mr-0 pr-1 pt-1 mt-1"></i>View
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </section>
            ) : null}

            {/* View Details for CBL */}
            {toggleMissingItem ? (
                           <>
                
                           <div
                             class="modal summary-modal right fade"
                             id="collapseExample"
                             tabindex="-1"
                             role="dialog"
                             aria-labelledby="myModalLabel"
                           >
                             <div class="modal-dialog" role="document">
                               <div class="modal-content">
                                 <div class="modal-header">
                                   <h4 class="modal-title" id="myModalLabel">
                                     View Summary
                                   </h4>
                                   <button
                                     type="button"
                                     class="close"
                                     data-dismiss="modal"
                                     aria-label="Close"
                                   >
                                     <span aria-hidden="true">&times;</span>
                                   </button>
                                 </div>
         
                                 <div class="modal-body">
                                   <div class="d-flex bd-highlight">
                                     <div class="bd-highlight item-num">
                                       Item #: <span>{viewubl.itemId}</span>
                                     </div>
         
                                     <div class="ml-auto view-det-link bd-highlight">
                                       <div class="barcode-font">{viewubl.ublId}</div>
                                     </div>
                                   </div>
         
                                   <div class=" item-name mb-3">
                                     {" "}
                                     {viewubl.itemDescription}{" "}
                                   </div>
         
                                   <div class="card">
                                     <div class="card-header">
                                       <div className="row">
                                         <div className="col-md-6 col-xs-12">
                                           <h6>UBL:{viewubl.ublId}</h6>
                                         </div>
                                         <div className="col-md-6 col-xs-12">
                                           <h6></h6>
                                         </div>
                                       </div>
                                     </div>
                                     <div class="card-body">
                                       <div className="row">
                                       <div className="col-md-6 col-xs-12 mb-3">
                                           <h6>Customer Order#</h6>
                                           <span>{viewubl.saleOrderNo}</span>
                                         </div>
                                         <div className="col-md-6 col-xs-12 mb-3">
                                           <h6> Quantity</h6>
                                           <span>{viewubl.qty}</span>
                                         </div>
                                         <div className="col-md-6 col-xs-12 mb-3">
                                           <h6>Supplier PO#</h6>
                                           <span>{viewubl.supplierPo}</span>
                                         </div>
                                         <div className="col-md-6 col-xs-12 mb-3">
                                           <h6>Return Location</h6>
                                           <span>{('000'+viewubl.storeId).substr(-3)}-{viewubl.returnLocation}</span>
                     
                                         </div>
                                         <div className="col-md-6 col-xs-12 mb-3">
                                           <h6>Supplier </h6>
                                           <span>{viewubl.supplierNo} | {viewubl.supplierName}</span><br/>
                                           {viewubl.addLine1?<span
                                           style={{
                                             fontSize:"11px",
                                             display:"inherit",
                                             marginTop:"5px"
                                           }}> <img
                                   src="./images/location-icon.svg"
                                   alt=""    
                                   style={{height:"15px",
                                   marginRight:"4px",
                                   float:"left",}}
                                 />
                                 <p style={{
                                   
                                   display:"inline"
         
                                 }}>{viewubl.addLine1+","+(viewubl.addLine2?(viewubl.addLine2+","):"")+(viewubl.addLine3?(viewubl.addLine3+","):"")+viewubl.city+","+viewubl.state+"-"+viewubl.postCode}</p>
                                 </span>:null}
                                         </div>
                                         <div className="col-md-6 col-xs-12 mb-3">
                                           <h6>Return Reason</h6>
                                           <span>{viewubl.returnReason}</span>
                                         </div>
                                         <div className="col-md-6 col-xs-12 mb-3">
                                           <h6>Brand</h6>
                                           <span>{viewubl.brand}</span>
                                         </div>
                                        <div className="col-md-6 col-xs-12 mb-3">
                                           <h6>Return Order#</h6>
                                           <span>{viewubl.returnOrderNo}</span>
                                         </div>
                                         <div className="col-md-6 col-xs-12 ">
                                           <h6>Status</h6>
                                           <span>
                                           {viewubl.ublStatus=="R"?"Received at DC":
          viewubl.ublStatus=="M"?"Item Missing":
          viewubl.ublStatus=="D"?"Despatched to DC":
          viewubl.ublStatus=="RD"?"Despatched to Supplier":
          viewubl.ublStatus=="SD"?"Ready Despatch to Supplier":
          viewubl.ublStatus=="DS"?"Despatched to Supplier"
          :""}
                                           
                                           |{user.storeName} ,{ viewubl.updatedTimestamp?
                                                (moment.utc(viewubl.updatedTimestamp).format("DD-MM-YYYY | hh:mm A")):null}
                                           </span>
                                         </div>
                                       </div>
                                     </div>
                                   </div>
                                 </div>
                               </div>
                             </div>
                           </div>
                        
                       </>            ) : null}

            {/* No items found message */}
            {!toggleNoItemsFound ? (
              <div className="dcru-no-items-found pl-0 pr-0 pt-2 bg-white">
                <section
                  className="trp-container manifest_check"
                  style={{ fontSize: "15px" }}
                >
                  <div className="count_container">
                    <p className="count_container_val">Total</p>
                    <p className="p10 text-dark">{total}</p>
                  </div>
                  <div className="count_container">
                    <p className="count_container_val">Received</p>
                    <p className="p10 text-dark">{received}</p>
                  </div>
                  <div className="count_container">
                    <p className="count_container_val">Pending</p>
                    <p className="p10 text-dark">{pending}</p>
                  </div>
                </section>
                <img
                  src="./images/Item-not-found.svg"
                  className="pt-3"
                  alt=""
                />
                <p>No items found</p>
              </div>
            ) : null}

            {/* Confirmation modal */}

            <div
              className="modal fade loginModal"
              id="confirmModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="errorModalCenterTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-z"
                role="document"
              >
                <div className="modal-content modal-radius">
                  <div className="modal-header confirmation-header">
                    <h5
                      className="modal-title confirmation-style"
                      id="errorModalCenterTitle"
                    >
                      Confirmation
                    </h5>
                  </div>
                  <div className="modal-body text-align-centre">
                    
                    <div className="pt10">
                    Would you like to declare selected Item(s) as missing?
                    </div>
                  </div>
                  <div className="modal-footer bo-modalbtnftr bt-none justify-content-center justify-content-md-end">
                    <button
                      type="button"
                      className="text-dark text-decoration-none 
                     btn bo-btnprimary
                     border border-secondary
                     confirm-no"
                      data-dismiss="modal"
                      style={{ width: "45%" }}
                    >
                      No
                    </button>
                    <button
                      data-dismiss="modal"
                      type="button"
                      className=" btn bo-btnprimary confirm-yes"
                      onClick={addMissingItem}
                      style={{ width: "45%" }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
 
            </div>

            {/* Error Modal */}
            <div
              className="modal fade"
              id="errorModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content dcr-modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Alert
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body dcr-modal-alert">
                    <i className="fas fa-exclamation-triangle"></i>
                    {errorMessageAlert}
                  </div>
                  <div className="dcr-modal-alert-button">
                    <button
                      type="button"
                      className="btn btn-secondary text-decoration-none"
                      data-dismiss="modal"
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Back and Complete Receiving buttons */}
            {toggleButtons ? (
              <section className="dcru-buttons pt-3 pb-3">
                {/* <button type="button" data-toggle="modal" data-target="#backModal">
            Back
          </button> */}
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModal1"
                  className="back_button"
                >
                  Back
                </button>
                <button
                  type="button"
                  disabled={cblSpanList.length==0 ||  user.tempDCTC || validchange}
                  className="ifdisable"
                  onClick={receiveCBLUBL}
                >
                  Receive CBL(s)
                </button>
              </section>
            ) : null}

            {/* Add Missing Items Button */}
            {toggleMissingButton ? (
              <section className="dcru-buttons pt-3 pb-3">
                <button
                  disabled={addMissingBtn ||  user.tempDCTC}
                  type="button"
                  className="addMissingButton ifdisable"
                  onClick={addMissingItemButton}
                >
                  Flag Missing Items
                </button>
              </section>
            ) : null}

            {/* Success Modal */}
            <div className="topCenter">
              <Toast
                id="toast"
                show={successToast}
                onClose={() => setSuccessToast(false)}
                delay={5000}
                autohide
              >
                <div className="toast__content pt-12">
                  <div className="tickmark" style={{ height: "100%" }}>
                    <img
                      className="tickmark mt-5"
                      src="./images/tick-circle-icon.svg"
                      alt=""
                    />
                  </div>
                  <div>
                    <p className="toast__type">Success</p>
                    <p className="toast__message">
                      CBL(s) Received successfully
                    </p>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="close toast-close"
                      data-dismiss="toast"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
              </Toast>
            </div>

            {loader ? <LoadingIndicator /> : null}
          </main>
        </div>
      </div>
      <div
        className="modal fade loginModal"
        id="exampleModal1"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-z"
          role="document"
        >
          <div className="modal-content modal-radius">
            <div className="modal-header confirmation-header">
              <h5 className="modal-title confirmation-style">Confirmation</h5>
            </div>
            <div className="modal-body text-align-centre font-weight-bold">
              Receive CBL(s) not completed! <br /> Are you sure you want to
             Go back?
            </div>
            <div className="modal-footer bo-modalbtnftr bt-none justify-content-center justify-content-md-end">
              <button
                type="button"
                className="text-dark text-decoration-none 
                     btn bo-btnprimary
                     border border-secondary
                     confirm-no"
                data-dismiss="modal"
                style={{ width: "45%" }}
              >
                No
              </button>

              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-yes"
                onClick={() => {
                  setUblDetails([]);
                  ubldetails_ref.current=[];
                  setInput({
                    ...input,
                    cblId: "",
                  });
                  setLoader(false);
                  setToggleUblResults(false);
                  setToggleNoItemsFound(false);
                  setToggleButtons(false);
                  setlocation("");
                  setinprg([]);
                  setCblSpanList([]);
                  setlocationdisable(false);
                  $(".locField").removeClass("disabletextbg");
      $(".inputLoc").attr("disabled", false);
                  settogglescreen((prev) => !prev);
                }}
                style={{ width: "45%" }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DCReceiptingCbl;
