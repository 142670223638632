const CblService = {
  untaggedUblDetails(StoreNo, token) {
    return fetch(
      process.env.REACT_APP_API_BASE_ENDPOINT +
        process.env.REACT_APP_STORE +
        StoreNo +
        "/ublHead",
      {
        method: "GET",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          Authorization: token,
        },
      }
    )
      .then((response) => {
        if (response.status == 200) {
         return response.json();
        } else if (response.status == 400) {
          response = {};
          response.message = "Invalid Data";
        } else if (response.status == 502) {
          response = {};
          response.message = "Internal Server Error";
        } else {
          response = {};
          response.message = "Internal Server Error";
        }

        return response;
      })
      .catch((err) => {
        return err;
      });
  },

  cblDestination(StoreNo, token) {
    return fetch(
      process.env.REACT_APP_API_BASE_ENDPOINT +
        process.env.REACT_APP_STORE +
        StoreNo +
        "/destinationConfig",
      {
        method: "GET",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          Authorization: token,
        },
      }
    )
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        } else {
          response = {};
          response.message = "Return order details not found.";
        }

        return response;
      })
      .catch((err) => {
        return err;
      });
  },
  addCublHeader(
    StoreNo,
    cublId,
    dcId,
    dcName,
    selectedItemsCount,
    token,
    userId
  ) {
    console.log(
      "ADD CUBL SERVICE CALLED",
      StoreNo,
      cublId,
      dcId,
      dcName,
      selectedItemsCount,
      token
    );

    return fetch(
      process.env.REACT_APP_API_BASE_ENDPOINT +
        process.env.REACT_APP_STORE +
        StoreNo +
        "/cublHead",
      {
        method: "POST",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          Authorization: token,
        },
        body: JSON.stringify({
          cublId: cublId,
          cublOriginType: "1",
          cublOriginId: StoreNo,
          despatchType: "1",
          destId: dcId,
          destName: dcName,
          cublStatus: "C",
          trackingStatus: "C",
          comments: null,
          employeeId: userId,
          ublCount: selectedItemsCount,
        }),
      }
    )
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        } else {
          response = {};
          response.message = "Return order details not found.";
        }

        return response;
      })
      .catch((err) => {
        return err;
      });
  },

  getCublHeader(StoreNo, cublId, token) {
    return fetch(
      process.env.REACT_APP_API_BASE_ENDPOINT +
        process.env.REACT_APP_STORE +
        StoreNo +
        "/cublHead?cublId=" +
        cublId,
      {
        method: "GET",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          Authorization: token,
        },
      }
    )
      .then((response) => {
        if (response.status == 200) {
          return response.json();
          
        } else {
          response = {};
          response.message = "Return order details not found.";
        }

        return response;
      })
      .catch((err) => {
        return err;
      });
  },
  removeCublFromUbl(StoreNo, cublId, ublId, token) {
    return fetch(
      process.env.REACT_APP_API_BASE_ENDPOINT +
        process.env.REACT_APP_STORE +
        StoreNo +
        "/removeCubl",
      {
        method: "PUT",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          Authorization: token,
        },
        body: JSON.stringify({
          cublId: cublId,
          cublOriginType: "1",
          cublOriginId: StoreNo,
          ublId: ublId,
        }),
      }
    )
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        } else {
          response = {};
          response.message = "Return order details not found.";
        }

        return response;
      })
      .catch((err) => {
        return err;
      });
  },
  validateCublHeader(StoreNo, cublId, token) {
    return fetch(
      process.env.REACT_APP_API_BASE_ENDPOINT +
        process.env.REACT_APP_STORE +
        StoreNo +
        "/validateCblUbl?cublublId=" +
        cublId +
        "&despatchType=1",
      {
        method: "GET",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          Authorization: token,
        },
      }
    )
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        } else {
          response = {};
          response.message = "Return order details not found.";
        }

        return response;
      })
      .catch((err) => {
        return err;
      });
  },
  viewUBLSummary(StoreNo, barcode, token) {
    return fetch(
      process.env.REACT_APP_API_BASE_ENDPOINT +
        process.env.REACT_APP_STORE +
        StoreNo +
        "/viewUblSummary?ublId=" +
        barcode,
      {
        method: "GET",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          Authorization: token,
        },
      }
    )
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        } else if (response.status == 400) {
          let res = {};
          response.message = "Invalid Search";
        } else {
          let res = {};
          response.message = "Internal Server Error";
        }

        return response;
      })
      .catch((err) => {
        return err;
      });
  },
};
export default CblService;
