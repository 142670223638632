export default class CreateShipment
{
    validateCUBL(StoreNo,barcode,token)
    {
        return fetch(process.env.REACT_APP_API_BASE_ENDPOINT + process.env.REACT_APP_STORE + StoreNo +'/validateCblUbl?cublublId='+barcode+"&despatchType=1",
        {
            method: 'GET',
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY,
                'Authorization': token
            }
               
        }
        
        ).then(response => {
            if (response.status == 200) {

                return response.json();
            }
            else if(response.status == 400){
                let response = {message: "Invalid Search"};
                
                return response;
            }
            else
            {
                let response = {message :"Internal Server Error"};
                return response;
            }

            return response;

        }
        ).catch(err => {
            return err;

        });
    }
    getCUBLUBL(StoreNo,token)
    {
        return fetch(process.env.REACT_APP_API_BASE_ENDPOINT + process.env.REACT_APP_STORE + StoreNo +'/cublUblforShipment',
        {
            method: 'GET',
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY,
                'Authorization': token
            }
               
        }
        
        ).then(response => {
            if (response.status == 200) {

                return response.json();
            }
            else if(response.status == 400){
                let response = {message: "Invalid Search"};
                
                return response;
            }
            else
            {
                let response = {message :"Internal Server Error"};
                return response;
            }

            return response;

        }
        ).catch(err => {
            return err;

        });
    }
    ViewManifest(StoreNo,manifestno,displayName,token,download="N",print="N")
    {
        return fetch(process.env.REACT_APP_API_BASE_ENDPOINT + process.env.REACT_APP_STORE + StoreNo +'/printManifest?shipId='
        +manifestno+"&userId="+displayName+"&download="+download+"&print="+print+"&pageNumber=1&pageSize=100",
        {
            method: 'GET',
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY,
                'Authorization': token
            }
               
        }
        
        ).then(response => {
            if (response.status == 200) {

                return response.json();
            }
            else if(response.status == 400){
                let res = {}
                response.message = "Invalid Search";
            }
            else
            {
                let res = {}
                response.message = "Internal Server Error";
            }

            return response;

        }
        ).catch(err => {
            return err;

        });
        
    }
    

    ViewUBLSummary(StoreNo,barcode,token)
    {
        return fetch(process.env.REACT_APP_API_BASE_ENDPOINT + process.env.REACT_APP_STORE + StoreNo +'/viewUblSummary?ublId='+barcode,
        {
            method: 'GET',
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY,
                'Authorization': token
            }
               
        }
        
        ).then(response => {
            if (response.status == 200) {

                return response.json();
            }
            else if(response.status == 400){
                let res = {}
                response.message = "Invalid Search";
            }
            else
            {
                let res = {}
                response.message = "Internal Server Error";
            }

            return response;

        }
        ).catch(err => {
            return err;

        });

    }
    CreateShipment(manifestno,StoreNo,storename,dc,dcname,carrierId,carriername,carrierref,displayname,token,address)
    {        
        return fetch(process.env.REACT_APP_API_BASE_ENDPOINT + process.env.REACT_APP_STORE + StoreNo +'/shipment',
        {
            method: 'POST',
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY,
                'Authorization': token
            },
            body: JSON.stringify({
                  shipId:manifestno,
                  shipOriginType:"1",
                  shipOriginId:('000' +StoreNo).substr(-3),
                  shipOriginName:storename,
                  despatchType:"1",                  
                  destType:"1",
                  destId:dc+"",
                  destName:dcname,
                  shipStatus:'C',
                  trackingStatus:'S',
                  carrierId:carrierId+"",
                  carrierName:carriername,
                  carrierConsFlg:null,
                  carrierRef:carrierref==""?null:carrierref,
                  comment:null,
                  raNumber:null,
                  employeeId:displayname,
                  destAddr1:address.ADDRESS_LN1,
                  destAddr2:address.ADDRESS_LN2,
                  destPostCode:address.POST_CD
                //  terminalId:"1"              
            })     
        }
        
        ).then(response => {
            if (response.status == 200) {

                return response.json();
            }
            else if(response.status == 400){
                let res = {}
                response.message = "Invalid Search";
            }
            else
            {
                let res = {}
                response.message = "Internal Server Error";
            }

            return response;

        }
        ).catch(err => {
            return err;

        });
    }
    UpdateCarrier(manifestno,StoreNo,carrierName,CarrierId,carrierref,token)
    {
        return fetch(process.env.REACT_APP_API_BASE_ENDPOINT + process.env.REACT_APP_STORE + StoreNo +'/updateCarrier',
        {
            method: 'PUT',
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY,
                'Authorization': token
            },
            body: JSON.stringify({
                
                            shipId:manifestno,
                            carrierId:CarrierId+"",
                            carrierName:carrierName,
                            carrierRef:carrierName=="Linfox"?null:carrierref                  
                           
            })     
        }
        
        ).then(response => {
            if (response.status == 200) {

                return response.json();
            }
            else if(response.status == 400){
                let res = {}
                response.message = "Invalid Search";
            }
            else
            {
                let res = {}
                response.message = "Internal Server Error";
            }

            return response;

        }
        ).catch(err => {
            return err;

        });
        
    }
    DespatchShipment(manifestno,StoreNo,token)
    {        
        return fetch(process.env.REACT_APP_API_BASE_ENDPOINT + process.env.REACT_APP_STORE + StoreNo +'/despatchShipment',
        {
            method: 'PUT',
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY,
                'Authorization': token
            },
            body: JSON.stringify({
                
                            shipId:manifestno,
                            shipOriginType:'1',
                            shipOriginId:('000' +StoreNo).substr(-3),
                            despatchType: '1'
                    
                           
            })     
        }
        
        ).then(response => {
            if (response.status == 200) {

                return response.json();
            }
            else if(response.status == 400){
                let res = {}
                response.message = "Invalid Search";
            }
            else
            {
                let res = {}
                response.message = "Internal Server Error";
            }

            return response;

        }
        ).catch(err => {
            return err;

        });
    }
    addCUBL(manifest,cubl,StoreNo,displayname,token,carrierid,carriername,carrierref)
    {
        return fetch(process.env.REACT_APP_API_BASE_ENDPOINT + process.env.REACT_APP_STORE + StoreNo +'/shipment',
        {
            method: 'PUT',
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY,
                'Authorization': token
            },
            body: JSON.stringify({
                shipId:manifest,
                shipOriginType:"1",
                shipOriginId:('000' +StoreNo).substr(-3),
                despatchType:"1",
                cublUblId:cubl,
                comments:'C',
                employeeId:displayname,
                carrierId:carrierid+"",
                carrierName:carriername,        
                carrierRef:carrierref==""?null:carrierref,
                terminalId:null                   
            })     
        }
        
        ).then(response => {
            if (response.status == 200) {

                return response.json();
            }
            else if(response.status == 400){
                let res = {}
                response.message = "Invalid Search";
            }
            else
            {
                let res = {}
                response.message = "Internal Server Error";
            }

            return response;

        }
        ).catch(err => {
            return err;

        });
    }
    delCUBL(manifestno,StoreNo,barcode,token){
        return fetch(process.env.REACT_APP_API_BASE_ENDPOINT + process.env.REACT_APP_STORE + StoreNo +'/cublHead',
        {
            method: 'PUT',
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY,
                'Authorization': token
            },
            body: JSON.stringify({
                 shipId:manifestno,
                 shipOriginType:1,
                 shipOriginId:('000' +StoreNo).substr(-3),
                 despatchType:1,
                 cublUblId:barcode
                  
            })     
        }
        
        ).then(response => {
            if (response.status == 200) {

                return response.json();
            }
            else if(response.status == 400){
                let res = {}
                response.message = "Invalid Search";
            }
            else
            {
                let res = {}
                response.message = "Internal Server Error";
            }

            return response;

        }
        ).catch(err => {
            return err;

        });

    }
    manifestno(StoreNo,token)
    { 
        return fetch(process.env.REACT_APP_API_BASE_ENDPOINT + process.env.REACT_APP_STORE + StoreNo + '/fetchManifest?despatchType=1',
        {
            method: 'GET',
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY,
                'Authorization': token
            }

        }).then(response => {
            if (response.status == 200) {

                return response.json();
            }
            else if(response.status == 400){
                let res = {}
                response.message = "Invalid Search";
            }
            else
            {
                let res = {}
                response.message = "Internal Server Error";
            }

            return response;

        }
        ).catch(err => {
            return err;

        });
}
manifestlist(StoreNo,token)
    { 
        return fetch(process.env.REACT_APP_API_BASE_ENDPOINT + process.env.REACT_APP_STORE + StoreNo + '/shipment?despatchType=1',
        {
            method: 'GET',
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY,
                'Authorization': token
            }

        }).then(response => {
            if (response.status == 200) {

                return response.json();
            }
            else if(response.status == 400){
                let res = {}
                response.message = "Invalid Search";
            }
            else
            {
                let res = {}
                response.message = "Internal Server Error";
            }

            return response;

        }
        ).catch(err => {
            return err;

        });
}
    destination(storeNo,token)
    {
        return fetch(process.env.REACT_APP_API_BASE_ENDPOINT + process.env.REACT_APP_STORE + storeNo+"/destinationConfig",
        {
            method: 'GET',
            headers: {
                       'x-api-key': process.env.REACT_APP_API_KEY,
                       'Authorization': token
                     }
        }).then(response => {
            if (response.status == 200) {

                return response.json();
            }
            else if(response.status == 400){
                let res = {}
                response.message = "Invalid Search";
            }
            else
            {
                let res = {}
                response.message = "Internal Server Error";
            }

            return response;

        }
        ).catch(err => {
            return err;

        });
    }

    CarrierDetails(token)
    {
       
       return  fetch(process.env.REACT_APP_API_BASE_ENDPOINT+"/carrier?carrierId=all",
        {
            method: 'GET',
            headers: {
                       'x-api-key': process.env.REACT_APP_API_KEY,
                       'Authorization': token
                     }
        }).then(response => {
            if (response.status == 200) {

                return response.json();
            }
            else if(response.status == 400){
                let res = {}
                response.message = "Invalid Search";
            }
            else
            {
                let res = {}
                response.message = "Internal Server Error";
            }

            return response;

        }
        ).catch(err => {
            return err;

        });
    }




}