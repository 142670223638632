const DcreceptingService = {
    ViewManifest(StoreNo,manifestno,displayName,token,download="N",print="N")
    {
        return fetch(process.env.REACT_APP_API_BASE_ENDPOINT + process.env.REACT_APP_STORE + StoreNo +'/printManifestDc?shipId='
        +manifestno+"&userId="+displayName+"&download="+download+"&print="+print+"&pageNumber=1&pageSize=100",
        {
            method: 'GET',
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY,
                'Authorization': token
            }
               
        }
        
        ).then(response => {
            if (response.status == 200) {

                return response.json();
            }
            else if(response.status == 400){
                let res = {}
                response.message = "Invalid Search";
            }
            else
            {
                let res = {}
                response.message = "Internal Server Error";
            }

            return response;

        }
        ).catch(err => {
            return err;

        });
        
    },
    getUblSummary(StoreNo, ublId, token) {
      return fetch(
        process.env.REACT_APP_API_BASE_ENDPOINT +
          process.env.REACT_APP_STORE +
          parseInt(StoreNo) +
          "/viewUblSummaryDC?ublId=" +
          ublId,
        {
          method: "GET",
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: token,
          },
        }
      )
      .then(async(response) => {
        if (response.status == 200) {
          var temp=await response.json();
          if(temp&&temp.UblSummaryDetails&&temp.UblSummaryDetails.details.length>0&&
            parseInt(temp.UblSummaryDetails.details[0].destId)== parseInt(StoreNo))
          {
            return temp;
 
          }
          else
          {
            return  {"UblSummaryDetails":{"message":"Ubl Summary Details not found for the storeId","details":""}};
          }
         } else {
          response = {};
          response.message = "Return order details not found.";
        }

        return response;
      })
      .catch((err) => {
        return err;
      });
  },

    receiveUBL(ublid,emplyeeid,manifestno,StoreNo,token,DCrec,cbl){
        return fetch(
            process.env.REACT_APP_API_BASE_ENDPOINT +
              process.env.REACT_APP_STORE +
              parseInt(StoreNo) +
              "/receiveUbl",
            {
              method: "PUT",
              headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                Authorization: token,
              },
              body: JSON.stringify({
                ublId: ublid,
                cublId:cbl.length>0?cbl:[],
                employeeId:emplyeeid,
                shipId:manifestno,
                ublStatus:"M",
                recLoc:DCrec
              }),
            }
          )
            .then((response) => {
              if (response.status == 200) {
                return response.json();
              } else {
                response = {};
                response.message = "details not found.";
              }
      
              return response;
            })
            .catch((err) => {
              return err;
            });

    },
    receiveManifest(emplyeeid,manifestno,StoreNo,token,DCrec){
        return fetch(
            process.env.REACT_APP_API_BASE_ENDPOINT +
              process.env.REACT_APP_STORE +
              StoreNo +
              "/receiveManifest",
            {
              method: "PUT",
              headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                Authorization: token,
              },
              body: JSON.stringify({
               
                employeeId:emplyeeid,
                shipId:manifestno,
                recLoc:DCrec
               
              }),
            }
          )
            .then((response) => {
              if (response.status == 200) {
                return response.json();
              } else {
                response = {};
                response.message = "details not found.";
              }
      
              return response;
            })
            .catch((err) => {
              return err;
            });

    },
    searchorder(storeNo,cublubl,token)
    {
      return fetch(
        process.env.REACT_APP_API_BASE_ENDPOINT +
          process.env.REACT_APP_STORE +
          storeNo +
          "/searchOrder?checkFlag="+cublubl,
        {
          method: "GET",
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: token,
          }, 
        }
      )
        .then((response) => {
          if (response.status == 200) {
            return response.json();
          } else {
            response = {};
            response.message = "details not found.";
          }
  
          return response;
        })
        .catch((err) => {
          return err;
        });

    },
    

}
export default DcreceptingService;