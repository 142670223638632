import Checkbox from "components/common/Checkbox";
import LoadingIndicator from "components/common/LoadingIndicator";
import Pagination from "components/common/Pagination";
import React, { useEffect, useState,useRef} from "react";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CblService from "services/CblService";
import $ from "jquery";
import { borderRadius } from "@mui/system";
const ModifyCblPage = ({
  handleAddCubl,
  handleProceed,
  handleCreateCubl,
  handleChangeText,
  handleChange,
  handleOnChange,
  handleGoNextScreen,
  handleViewUblSummary,
  viewUbl,
  select,
  ...props
}) => {
  console.log(viewUbl);
  const [checkbox,setcheckbox]=useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);
  useEffect(() => {
      window.addEventListener("resize", () => {
          const ismobile = window.innerWidth < 700;
          if (ismobile !== isMobile) setIsMobile(ismobile);
      }, false);
  }, [isMobile]);
  
  
  const [cart, setCart] = useState(props.details);
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(()=>{setCart(props.details);
    setcheckstatus(new Array(props.details.length).fill(false))},[props.details]);
  const [detail_1,setdetail_1]=useState([{}]);
  const user = JSON.parse(localStorage.getItem("User"));
  const [loader, setLoader] = useState(false);
  const [checked,setchecked]=useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [checkstatus,setcheckstatus]=useState(new Array(props.details.length).fill(false));
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [ublIds,setUblIds]=useState([]);
  const [show,setshow]=useState(true);
  const  inputFocus=useRef();
  useEffect(()=>{
    inputFocus.current.focus()
  },[]);
  

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  useEffect(() => {}, [currentPage]);
  const check=(e) => { 
    if(e.target.checked==false)
    {
      console.log(checkbox);
      setcheckbox(false); 
      setchecked((prev)=>(prev-1));
    }
    else
    {
      var temp=checked+1;
      setchecked((prev)=>(prev+1));
      if(temp==props.details.length)
      setcheckbox(true); 

    }
    

    }

  console.log("PENDING", props.pending);
  const viewdetail=(ublId)=>{
    setLoader(true);
    CblService.viewUBLSummary(user.storeNo,ublId,user.token)
        .then((response1)=>{
          setdetail_1(response1.UblSummaryDetails.details,console.log(detail_1));
          $("#myModal2").modal();
          setLoader(false);
        }).catch(err=>{console.log(err);setLoader(false);})

  }

  const renderData = (data) => {
    return (
      <>
        {data
          .map((detail_1, index) => {
            return (
              <div className="col-md-4 col-lg-4 col-sm-4 card-padding">
                <div className="card-content-ubl p-2">
                  {/* item id */}
                  <div className="d-flex mb-1 bd-highlight">
                    <div className="bd-highlight item-id">
                      Item #: <span>{detail_1.ITEM_ID}</span>
                    </div>
                    <div className="ml-auto check-link bd-highlight">
                      <input
                        key={detail_1.UBL_ID}
                        id={detail_1.UBL_ID}
                        type="checkbox"
                        disabled={props.valid || !props.barcodeValid}
                       
                        checked={checkstatus[
                          props.details
                          .findIndex((element)=>element.UBL_ID==detail_1.UBL_ID)]}
                        name={detail_1.UBL_ID}
                        onClick={(e)=>{
                          var temp=checkstatus;
                          temp[
                            props.details
                            .findIndex((element)=>element.UBL_ID==detail_1.UBL_ID)]=!temp[
                              props.details
                              .findIndex((element)=>element.UBL_ID==detail_1.UBL_ID)]
                           setcheckstatus(temp);   
                
                          check(e);
                          handleChange(detail_1);
                         
                        }}
                      />
                    </div>
                  </div>

                  {/* Item name */}
                  <div className="d-flex bd-highlight mb-1"
                  style={{
                    height:"30px",
                    overflow:"hidden"
                  }}>
                    <div className="bd-highlight item-ublname">
                      {detail_1.ITEM_DESCR}
                      </div>
                </div>

                <hr />

                {/* UBL ID */}
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div style={{}}>
                    <div> <p class="barcode-font" 
                        style={{fontSize:28,
                        marginTop:15,
                        marginLeft:5}}>{detail_1.UBL_ID}</p></div>
                    </div>
                  </div>

                  <div
                    className="ml-auto view-det-link bd-highlight"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                      <button
                      class="border-none bg-none bluec viewbtn view"
                      onClick={(e) => viewdetail(detail_1.UBL_ID)}
                    >
                      <FontAwesomeIcon icon={faEye} className="view" />
                      View
                    </button>
                  </div>
                </div>
              </div>
            </div>
            );
          })
          .slice(indexOfFirstItem, indexOfLastItem)}
      </>
    );
  };
  const handleClick = (event) => {
    setCurrentPage(Number(event.target.id));
  };

  const handlePrevBtn = () => {
    setCurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };
  const handleNextBtn = () => {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };
  useEffect(()=>{},[checkbox]);
  const handleOnChangesearch = (e) => {
    setSearchTerm(e.target.value);
    let value = e.target.value.toLowerCase();
    if (value.length > 2) {
      let result = props.details.filter((d) => {
        let tempdata=(JSON.stringify(d));
        return (tempdata).toLowerCase().includes(value);
      });
      
      setCart(result);
      
      if(result.length>0){
        setCurrentPage(1)
      }
     
    } else {
      setCart(props.details);
    }
    console.log(ublIds);
    for(let i=0;i<ublIds.length;i++)
    console.log($("#"+ublIds[i]).prop('checked'),123);
  };
  useEffect(()=>{
    setcheckbox(false);
    setcheckstatus(new Array(props.details.length).fill(false));
  },[props.valid]);
  

  return (
    <>
      <div className="col-md-10">
        <div className="bggray card-sect2_cr">
          <div className="row">
            <div className="col-md-7 row flex" style={{
               paddingLeft:30
            }}>
              <p className=" pt10 fw6  label col-md-3"style={{
               width:"25%"
              }}>Modify CBL</p>
              <div className="form-group col-md-9" style={{paddingLeft:"0px"}}>
                <div className="input-group">
                <input
                      type="text"
                      id="cblInput"
                      placeholder="Enter/Scan to create CBL"
                      className="form-control cblInput"
                      autocomplete="off"
                      name="cblInput"
                      maxLength={isMobile?"":10}
                     
                      ref={inputFocus}
                      value={props.cblInputText}
                    
                      onFocus={()=>setshow(true)}
                      onBlur={()=>setshow(false)}
                      onKeyPress={(e)=>{if(e.key === 'Enter') {
                        setshow(false);
                      }}}
                      onChange={(e)=>{
                        setshow(true)
                        handleChangeText(e);}}
                      style={{ borderRadius: "10px !important" ,margin:"0px"}}
                      autofocus
                    />
                  <div class="input-group-append rtn-clear-input">
                      <button id="clrbutton" class="btn  btn-outline-secondary clr" onClick={props.clearcbl}></button>
                      <button class="btn  btn-outline-secondary scan_button cubl_scan_btn"></button>
                  </div>
                </div>
                <img
                    src="./images/error-icon.svg"
                    alt=""
                    style={{marginLeft:"10px"}}
                    hidden={(props.barcodeValid || show)&&props.cblInputText.length<=10}
                  />
                  <span
                    className="barcode-validation-failed"
                    hidden={(props.barcodeValid || show)&&props.cblInputText.length<=10}
                  >
                    Invalid Barcode !
                  </span>
              </div>
            </div>

            <div className="col-md-4 add">
              <input
                type="submit"
                id="Add-button"
                value="Validate"
                className="addbtnc"
                disabled={!props.barcodeValid | !props.cblInputText}
                onClick={handleAddCubl}
              />
            </div>
          
          </div>

        </div>
        <div className="card2 firstScreen show">
          <h6 className="cbl-text f14 fw5">UBL available for Tagging</h6>
          <div className="cbl-container" style={{ height: "auto",minHeight:300 }}>
          {props.details.length!=0 ?  (<>
            <div className="rtn-card-hdr">
          <div className="container">
            <div className="row">
              <div className="col-md-6 card-padding">
                <form className="rtn-table-search" onSubmit={(e) => e.preventDefault()}>
                  <input
                    name="field"
                    autocomplete="off"
                    className="form-control input-search col-md-6"
                    type="text"
                    placeholder="Search"
                    id="SearchTerm"
                    value={searchTerm}
                    onChange={handleOnChangesearch}
                  />
                </form>
              </div>
              <div className="col-md-6 card-padding">
              {cart.length>0? <div style={{float:"right"}}>
              <input
                type="checkbox"
                id={1}
                name="selectAll"
                checked={cart.filter((a)=>props.ublIds.includes(a.UBL_ID)).length==cart.length}
                              select={select}
                              disabled={props.valid || !props.barcodeValid}
                              onClick={(e) => {
                               
                                if (e.target.checked == true) {

                                  var temp = checkstatus;
                                  for(let i=0;i<cart.length;i++)
                                  temp[
                                    props.details.findIndex(
                                      (element) => element.UBL_ID == cart[i].UBL_ID
                                    )
                                  ]=true;
                                  
                                  setcheckstatus(temp);
                                  console.log(props.ublIds)
                                  console.log(cart.map((ubl, index) => ubl.UBL_ID),"add");
                                  handleOnChange(cart.map((ubl, index) => ubl.UBL_ID),"add");
                                 
                                  
                                } else {
                                  var temp = checkstatus;
                                  for(let i=0;i<cart.length;i++)
                                  temp[
                                    props.details.findIndex(
                                      (element) => element.UBL_ID == cart[i].UBL_ID
                                    )
                                  ]=false;
                                
                                  console.log(temp);
                                  setcheckstatus(temp);
                                  console.log(props.ublIds)
                                  console.log(cart.map((ubl, index) => ubl.UBL_ID),"remove");
                                  handleOnChange(cart.map((ubl, index) => ubl.UBL_ID),"remove");
                                 
                                 
                                }
                                 setchecked(props.ublIds.length);
                               
                              }}
              />
              <label
                for="exampleFormControlInput1"
                style={{ marginLeft: "10px" }}
              >
                Select All
              </label>
                
              </div>:null}
            </div>
          </div>
        </div>


              
            </div>
           {cart.length>0? (<>
            <div className="rtn-card-tiles">
              <div className="container">
                <div className="row">
               
                {cart.length>0?renderData(cart):"No result found"}
                 
                </div>
              </div>
            </div>

            <section className="paginat">
              <Pagination
                className="pagination-bar"
                data={cart}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                pageNumberLimit={pageNumberLimit}
                maxPageNumberLimit={maxPageNumberLimit}
                minPageNumberLimit={minPageNumberLimit}
                handleClick={handleClick}
                handlePrevBtn={handlePrevBtn}
                handleNextBtn={handleNextBtn}
              />
            </section>
           </>):(<div className="img-with-text"
                            style={{paddingTop:"20px",margin:"0px"}}
                            >
                <img src="./images/Item-not-found.svg" alt="" />
                <p className="no-item-found">No Item found</p>
              </div>)}
            
            
            
            
            </>
            
            ):(<div className="img-with-text"
                            style={{paddingTop:"20px",margin:"0px"}}
                            >
                <img src="./images/Item-not-found.svg" alt="" />
                <p className="no-item-found">No Tagged UBL found</p>
              </div>)}
          </div>
        </div>
      </div>
      <div className="container-fluid section-four" style={{marginTop:30}}>
        <div className="float-right w100">
          <input type="button" value="Cancel" className="cancel-btn min fs12" onClick={
            ()=>{
              setcheckbox(false);
              setcheckstatus(new Array(props.details.length).fill(false));
            props.onCancel();
            }}/>
          <input
            type="submit"
            value="Tag"
            style={{marginRight:15}}
            onClick={handleCreateCubl}
            disabled={props.valid || checkstatus.filter((a,index)=>a==true).length==0}
            className="add-carrier-btn min next-button fs12"
          />
           <input
            type="button"
            value="Proceed"
            onClick={handleProceed}
            disabled={props.valid}
            className="add-carrier-btn min next-button fs12"
          />
        </div>

</div>
{/*<div
        className="modal right fade"
        id="myModal2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel2"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header bgred">
              <h4
                className="modal-title fw5 f18 whitetext p10"
                id="myModalLabel2"
              >
                View Summary
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" className="whitetext">
                  &times;
                </span>
              </button>
            </div>

            <div className="modal-body table-responsive">
              <div class="container ">
              <div class="row pb-3 pt-2 mb-3">
              <div class="col-md-6">
                <div class="container">
                  <div class="row mb-2">
                  Item#:{detail_1[0].itemId}</div>
                  <div class="row" style={{fontWeight:600,overflow:"hidden",height:"30px"}}>
                {detail_1[0].itemDescription}</div>
              </div>
             </div>
             <div class="col-md-6 barcode-font pt-2 float-right"
             style={{
               textAlign:"right"
             }}>
             {detail_1[0].ublId}
             </div>
              </div>
              </div>



              <div class="container shadow  bg-white" style={{
                 borderRadius: "5px"
              }}>
              <div class="row pb-3 pt-3 mb-3" style={{
                backgroundColor:"#cdeff9",
                borderRadius:"5px 5px 0px 0px "
               
                }}>
              <div class="col-md-6 " style={{fontWeight:600}}>
              Item Summary Detials
             </div>
             <div class="col-md-6 " style={{fontWeight:600}}>
              
             </div>
              </div>
                
                <div>
                <div class="row mb-4">
              <div class="col-md-6 ">
              <div class="container">
              <div class="row mb-2" style={{fontWeight:600}}>
               Customer Sales Order Number
                </div>
                <div class="row">
               {detail_1[0].saleOrderNo}
                </div>

                </div>
             </div>
             <div class="col-md-6">
              <div class="container">
              <div class="row mb-2" style={{fontWeight:600}}>
               Quantity
                </div>
                <div class="row">
                {detail_1[0].qty}
                </div>

                </div>
             </div>
              </div>
              <div class="row mb-4">
              <div class="col-md-6">
              <div class="container">
              <div class="row mb-2" style={{fontWeight:600}}>
                Supplier PO Number
                </div>
                <div class="row">
                {detail_1[0].supplierPo}
                </div>

                </div>
             </div>
             <div class="col-md-6">
              <div class="container">
              <div class="row mb-2" style={{fontWeight:600}}>
               Return Store
                </div>
                <div class="row">
                {detail_1[0].returnOrderNo}
                </div>

                </div>
             </div>
              </div>
              <div class="row mb-4">
              <div class="col-md-6">
              <div class="container">
              <div class="row mb-2" style={{fontWeight:600}}>
                Supplier
                </div>
                <div class="row">
                {detail_1[0].supplierName}
                </div>

                </div>
             </div>
             <div class="col-md-6">
              <div class="container">
              <div class="row mb-2" style={{fontWeight:600}}>
                Return Reason
                </div>
                <div class="row">
                {detail_1[0].returnReason}
                </div>

                </div>
             </div>
              </div> <div class="row mb-4">
              <div class="col-md-6">
              <div class="container">
              <div class="row mb-2"  style={{fontWeight:600}}>
                Brand
                </div>
                <div class="row mb-3">
                {detail_1[0].brand}
                </div>

                </div>
             </div>

             <div class="col-md-6">
              <div class="container">
              <div class="row mb-2"  style={{fontWeight:600}}>
               RO Number
                </div>
                <div class="row mb-3">
               {detail_1[0].returnOrderNo}
                </div>

                </div>
             </div>
              </div>

              </div>
              </div>
            </div>
          </div>
        </div>
              </div>*/}


         <div class="modal summary-modal right fade"
             id="myModal2"
             tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
            <div class="modal-dialog" role="document">
                <div class="modal-content">

                    <div class="modal-header">
                        
                        <h4 class="modal-title" id="myModalLabel">View Summary</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                          </button>
                    </div>
                    <div class="modal-body">
                    {/* <div class="d-flex bd-highlight mb-3">
                        <div class="bd-highlight item-num">
                            Item #: <span>{detail_1[0].itemId}</span>
                            <div class=" item-name" style={{
                              width:"250px",
                              whiteSpace:"normal"
                            }}>  {detail_1[0].itemDescription} </div>
                        </div>
                       
                        <div class="ml-auto view-det-link bd-highlight">
                            <div class="barcode-font">
                            {detail_1[0].ublId}
                            </div>
                        </div>
                    </div> */}
                     <div class="d-flex bd-highlight">
                        <div class="bd-highlight item-num">
                            Item #: <span>{detail_1[0].itemId}</span>
                           
                        </div>
                       
                        <div class="ml-auto view-det-link bd-highlight">
                            <div class="barcode-font">
                              {detail_1[0].ublId}
                            </div>
                        </div>
                    </div>

                    <div class=" item-name mb-3"> {detail_1[0].itemDescription} </div>


                    <div class="card">
                        <div class="card-header">
                            <div className="row">
                                <div className="col-md-6 col-xs-12">
                                     <h6>Item Summary Details</h6>
                                </div>
                                <div className="col-md-6 col-xs-12">
                                     <h6></h6>
                                </div>
                            </div>
                            
                        </div>
                        <div class="card-body">
                            <div className="row">
                                <div className="col-md-6 col-xs-12 mb-3">
                                <h6>Customer Order #</h6>
                                     <span>{detail_1[0].saleOrderNo}</span>
                                </div>
                                <div className="col-md-6 col-xs-12 mb-3">
                                     <h6>Quantity</h6>
                                     <span>{detail_1[0].qty}</span>
                                </div>

                                <div className="col-md-6 col-xs-12 mb-3">
                                     <h6>Supplier PO #</h6>
                                     <span>{detail_1[0].supplierPo}</span>
                                </div>
                                
                                <div className="col-md-6 col-xs-12 mb-3">
                                     <h6>Return Location</h6>
                                     <span> {user.storeNo} - {user.storeName}</span> 
                                </div>

                                <div className="col-md-6 col-xs-12 mb-3">
                                     <h6>Supplier </h6>
                                     <span>{detail_1[0].supplierNo?(detail_1[0].supplierNo):""} {detail_1[0].supplierName}</span>
                                   
                                </div>
                                
                                <div className="col-md-6 col-xs-12 mb-3">
                                     <h6>Return Reason</h6>
                                     <span>{detail_1[0].returnReason}</span>
                                </div>

                                <div className="col-md-6 col-xs-12 mb-3">
                                     <h6>Brand</h6>
                                     <span>{detail_1[0].brand}</span>
                                </div>
                                <div className="col-md-6 col-xs-12 ">
                                <h6>Return Order #</h6>
                                     <span>{detail_1[0].returnOrderNo}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    </div>

                </div>
            </div>
        </div>
      
      
      {loader ? <LoadingIndicator /> : null}
      
    </>
  );
};

export default ModifyCblPage;
