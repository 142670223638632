import {
  addCblFailure,
  addCblInPrgress,
  addCblSuccess,
  deleteCublFailure,
  deleteCublInPrgress,
  deleteCublSuccess,
  getCublFailure,
  getCublInPrgress,
  getCublSuccess,
  loadCblDestinationFailure,
  loadCblDestinationInPrgress,
  loadCblDestinationSuccess,
} from "redux/actions/CblActions";
import {
  getDcLocationFailure,
  getDcLocationInPrgress,
  getDcLocationSuccess,
  getUblDetailsFailure,
  getUblDetailsInPrgress,
  getUblDetailsSuccess,
  putUblToStoreLocationFailure,
  putUblToStoreLocationInPrgress,
  putUblToStoreLocationSuccess,
} from "redux/actions/DcLocationActions";
import {
  addUblFailure,
  addUblInPrgress,
  addUblSuccess,
  loadCblMaptoUblDataFailure,
  loadCblMaptoUblDataInProgress,
  loadCblMaptoUblDataSuccess,
  loadUblDataFailure,
  loadUblDataInProgress,
  loadUblDataSuccess,
  viewModifyCblDataFailure,
  viewModifyCblDataInPrgress,
  viewModifyCblDataSuccess,
  viewUblSummaryFailure,
  viewUblSummaryInPrgress,
  viewUblSummarySuccess,
} from "redux/actions/UblActions";
import cblService from "services/CblService";
import dcLocationSevice from "services/DcLocationService";
import ublService from "services/UblService";

export const loadCblData = (storeNo, token) => async (dispatch, getState) => {
  try {
    const user = JSON.parse(localStorage.getItem("User"));
   
    dispatch(loadUblDataInProgress);
    await cblService.untaggedUblDetails(storeNo, token).then((response) => {
      if(response.return_ubl_response.details != " ")
      response.return_ubl_response.details.map((a,index)=>{a.returnstore=(user.storeNo+"-"+user.storeName);return a})
      dispatch(
        loadUblDataSuccess(
          response.return_ubl_response.details === " "
            ? []
            : response.return_ubl_response.details.filter(
                (index) => index.UBL_STATUS != "X"
              )
        )
      );
    });
  } catch (error) {
   // alert(error);
    dispatch(loadUblDataFailure(error));
  }
};

export const loadCblDestination =
  (storeNo, token) => async (dispatch, getState) => {
    try {
      dispatch(loadCblDestinationInPrgress);
      const cbl = await cblService
        .cblDestination(storeNo, token)
        .then((response) => {
          var tempdetails = response.DestinationConfigDetails.details;
          if (tempdetails.length > 0) {
            return tempdetails;
          } else {
            return null;
          }
        });
      dispatch(loadCblDestinationSuccess(cbl));
    } catch (e) {
      dispatch(loadCblDestinationFailure());
    }
  };

export const addCubl =
  (storeNo, cublId, dcId, dcName, selectedItemsCount, token, userId) =>
  async (dispatch, getState) => {
    try {
      dispatch(addCblInPrgress);
      const message = await cblService
        .addCublHeader(
          storeNo,
          cublId,
          dcId,
          dcName,
          selectedItemsCount,
          token,
          userId
        )
        .then((response) => {
          var tempdetails = response.cublHeader.message;
          return tempdetails;
        });
      dispatch(addCblSuccess(message));
    } catch (e) {
      dispatch(addCblFailure());
    }
  };

export const addUbl = (storeNo, ublId, token) => async (dispatch, getState) => {
  try {
    dispatch(addUblInPrgress);
    const message = await ublService
      .addUblHeader(storeNo, ublId, token)
      .then((response) => {
        var tempdetails = response.ublHeader.message;
        return tempdetails;
      });
    dispatch(addUblSuccess(message));
  } catch (e) {
    dispatch(addUblFailure());
  }
};
export const getCubl =
  (storeNo, cublId, token) => async (dispatch, getState) => {
    try {
      dispatch(getCublInPrgress);
      const message = await cblService
        .getCublHeader(storeNo, cublId, token)
        .then((response) => {
          var tempdetails = response.CublDetails.message;
          if (tempdetails === "CUBL Details not found for the storeId") {
            return "Barcode validated successfully";
          } else {
            return "Barcode validation failed";
          }
        });

      dispatch(getCublSuccess(message));
    } catch (e) {
      dispatch(getCublFailure());
    }
  };

export const deleteCubl =
  (storeNo, cublId, ublId, token) => async (dispatch, getState) => {
    try {
      dispatch(deleteCublInPrgress);
      const message = await cblService
        .removeCublFromUbl(storeNo, cublId, ublId, token)
        .then((response) => {
          var tempdetails = response.updateRemoveCublFromUbl.message;
          return tempdetails;
        });
      dispatch(deleteCublSuccess(message));
    } catch (e) {
      dispatch(deleteCublFailure());
    }
  };

export const cblMapToUbl =
  (storeNo, cublId, ublId, token,shipment=null) => async (dispatch, getState) => {
    try {
      dispatch(loadCblMaptoUblDataInProgress);
      const message = await ublService
        .mapCublToUbl(storeNo, cublId, ublId, token,shipment)
        .then((response) => {
          var tempdetails = response.updateUblHeader.message;
          return tempdetails;
        });
      dispatch(loadCblMaptoUblDataSuccess(message));
    } catch (e) {
      dispatch(loadCblMaptoUblDataFailure());
    }
  };

export const viewUblSummary =
  (storeNo, ublId, inputLocation, Barcode, token) =>
  async (dispatch, getState) => {
    try {
      dispatch(viewUblSummaryInPrgress);
      const ubl = await ublService
        .getUblSummary(storeNo, ublId, token)
        .then((response) => {
          var tempdetails = response.UblSummaryDetails.details;
          if (tempdetails.length > 0) {
            return response.UblSummaryDetails;
          } else {
            return response.UblSummaryDetails;
          }
        });
      dispatch(viewUblSummarySuccess(ubl));

    } catch (e) {
      dispatch(viewUblSummaryFailure());
    }
  };

export const viewModifyCblData =
  (storeNo, ublId, token) => async (dispatch, getState) => {
    try {
      dispatch(viewModifyCblDataInPrgress);
      await ublService.getUblSummary(storeNo, ublId, token).then((response) => {
        dispatch(
          viewModifyCblDataSuccess(
            response.UblSummaryDetails.details === ""
              ? []
              : response.UblSummaryDetails.details
          )
        );
      });
    } catch (e) {
      dispatch(viewModifyCblDataFailure());
    }
  };

/* THIS IS THE API ENDPOINT FOR DC ITEM PUTAWAY SHELF LOCATION WITH LOC_ID WHICH WILL
     RETURN SUPPLIER DETAILS
  */
export const getDcLocations = (locId, token) => async (dispatch, getState) => {
  try {
    dispatch(getDcLocationInPrgress);
    const message = await dcLocationSevice
      .getDcLocations(locId, token)
      .then((response) => {
        return response.dc_loc_valid;
      });
    dispatch(getDcLocationSuccess(message));
  } catch (e) {
    dispatch(getDcLocationFailure());
  }
};

/* THIS IS THE API ENDPOINT FOR DC ITEM PUTAWAY SHELF LOCATION WITH LOC_ID WHICH WILL
     RETURN SUPPLIER DETAILS AND ON SELECTING SUPPLIER AND WHEN USER INPUTS UBL ID API WILL RETURN ITEMS..
  */
export const getUblDetails =
  (storeNo, ublId, inputLocation, token) => async (dispatch, getState) => {
    const user = JSON.parse(localStorage.getItem("User"));
    try {
      dispatch(getUblDetailsInPrgress);

      const message = await dcLocationSevice
        .getUblDetails(storeNo, ublId, token)
        .then((response) => {
          var tempdetails = response.LocUblIdValidationDetails.message;
          return tempdetails;
        });
      dispatch(getUblDetailsSuccess(message));
      await dispatch(viewUblSummary(user.storeNo, ublId, inputLocation, token));
    } catch (e) {
      dispatch(getUblDetailsFailure());
    }
  };

/* THIS IS THE API ENDPOINT FOR DC ITEM PUTAWAY SHELF LOCATION WITH LOC_ID WHICH WILL
     RETURN SUPPLIER DETAILS AND ON SELECTING SUPPLIER AND WHEN USER INPUTS UBL ID API WILL RETURN ITEMS..
  */
export const putUblDetailsToDcLocation =
  (storeNo, locId, itemId, itemQty, itemDesc, Barcode, token) =>
  async (dispatch, getState) => {
    try {
      dispatch(putUblToStoreLocationInPrgress);
      const message = await dcLocationSevice
        .putUblDetailsToLocation(
          storeNo,
          locId,
          itemId,
          itemQty,
          itemDesc,
          Barcode,
          token
        )
        .then((response) => {
          var tempdetails = response.dcPutaway.message;
          return tempdetails;
        });
      dispatch(putUblToStoreLocationSuccess(message));
    } catch (e) {
      dispatch(putUblToStoreLocationFailure());
    }
  };
