import * as types from "./ActionTypes";

export const getDcLocationInPrgress = () => {
  return {
    type: types.GET_DC_LOCATION_IN_PROGRESS,
  };
};

export const getDcLocationSuccess = (dc_loc_valid) => {
  return {
    type: types.GET_DC_LOCATION_SUCCESS,
    dc_loc_valid,
  };
};

export const getDcLocationFailure = (error) => {
  return {
    type: types.GET_DC_LOCATION_FAILURE,
    error,
  };
};

export const getUblDetailsInPrgress = () => {
  return {
    type: types.GET_UBL_DETAILS_IN_PROGRESS,
  };
};

export const getUblDetailsSuccess = (LocUblIdValidationDetails) => {
  return {
    type: types.GET_UBL_DETAILS_SUCCESS,
    LocUblIdValidationDetails,
  };
};

export const getUblDetailsFailure = (error) => {
  return {
    type: types.GET_UBL_DETAILS_FAILURE,
    error,
  };
};

export const putUblToStoreLocationInPrgress = () => {
  return {
    type: types.PUT_UBL_DETAILS_TO_DC_LOCATION_IN_PROGRESS,
  };
};

export const putUblToStoreLocationSuccess = (putMessage) => {
  return {
    type: types.PUT_UBL_DETAILS_TO_DC_LOCATION_SUCCESS,
    putMessage,
  };
};

export const putUblToStoreLocationFailure = (error) => {
  return {
    type: types.PUT_UBL_DETAILS_TO_DC_LOCATION_FAILURE,
    error,
  };
};
