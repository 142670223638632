import React, { createContext, Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {
  codeGrantFlow,
  codeAndTokenExhangeForAuthentication,
} from "./utilities";
import * as actions from "./actions";
import ReportsService from "../../services/ReportsService";
import ConfigService from "../../services/ConfigService";

class Authentiction extends Component {
  componentDidMount = async () => {
    const { saveTokens } = this.props;
    const code =
      (window.location.search &&
        /code=([^&]+)/.test(window.location) &&
        window.location.search.split("=")[1]) ||
      null;

    /**
     * If there is no code on the URL of the app,
     * go for authorization to cognito login endpoint.
     * Post authentication, "code" param will be a part of the url
     * Use this code to fetch tokens from Cognito oauth endpoint.
     * On successfully fetching it, navigating the user to Homepage/can be anywhere
     */

    if (!code) {
      codeGrantFlow();
    } else {
      try {
        const tokenResponse = await codeAndTokenExhangeForAuthentication(code.split("&")[0]);
        const {
          data: { access_token, refresh_token, id_token },
        } = tokenResponse;
        if (access_token && refresh_token && id_token) {
          // Decode JWT id_token
          var base64Url = id_token.split('.')[1];
          var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          }).join(''));

          console.log("json payload log: ",JSON.parse(jsonPayload), "jsonPayload");
          var role = JSON.parse(jsonPayload)["custom:role"];
          var storeNo;
        
          storeNo = JSON.parse(jsonPayload)["custom:storeNo"].toString().padStart(3,"0");
          // if(JSON.parse(jsonPayload)["custom:storeNo"].toString()=="6000")
          // storeNo="060";
          // if(JSON.parse(jsonPayload)["custom:storeNo"].toString()=="2900")
          // storeNo="029";
          // if(JSON.parse(jsonPayload)["custom:storeNo"].toString()=="9700")
          // storeNo="097";
          // if(JSON.parse(jsonPayload)["custom:storeNo"].toString()=="26700")
          // storeNo="267";

          
          var userId = JSON.parse(jsonPayload).identities[0].userId;
          var displayName = JSON.parse(jsonPayload)["custom:displayName"];
         
          var givenName = JSON.parse(jsonPayload)["custom:givenName"];
          var storeName;
       
          storeName = JSON.parse(jsonPayload)["custom:storeName"];
          var isSuperUser = false;
          localStorage.setItem('exp', JSON.parse(jsonPayload)["exp"])
          localStorage.setItem('refresh_token', refresh_token)
          localStorage.setItem('id_token', id_token)
          localStorage.setItem('access_token', access_token)
          console.log("groupname :",JSON.parse(jsonPayload)["custom:group"])
          var group = JSON.parse(jsonPayload)["custom:group"];

          saveTokens({
            accessToken: access_token,
            refreshToken: refresh_token,
            id_token,
          });
          var tempSearchMenu = true;
          var tempCreateDispatchInStoreMenu = true;
          var tempCreateConsolidatedDispatchMenu = true;
          var tempCreateShipment = true;
          var tempCreateShipmentToggle = true;
          var tempDC=true;
          var tempDCTM=true;
          var tempDCTC=true;
          var tempChangeLocation=true;
          let dateObj = new Date();
          const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
          var hours = dateObj.getHours();
          var minutes = dateObj.getMinutes();
          var ampm = hours >= 12 ? 'PM' : 'AM';
          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          minutes = minutes < 10 ? '0' + minutes : minutes;
          var strTime = hours + ':' + minutes + ' ' + ampm;
          var loggedInTime = monthNames[dateObj.getMonth()] + ' ' + (dateObj.getDate()) + ',' + ' ' + dateObj.getFullYear() +':' + ' ' + strTime;

          
          const refresh=async(token)=>{
            console.log("refresh called")
            const details = {
              grant_type: "refresh_token",
              client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
              redirect_uri: process.env.REACT_APP_COGNITO_REDIRECT_URI, // Update redirect URI here
              code,
              client_secret: process.env.REACT_APP_COGNITO_CLIENT_SECRET,
              refresh_token: token
            };
          
            const headers = {
              "Content-Type": "application/x-www-form-urlencoded",
            };
          
            const formBody = Object.keys(details)
              .map(
                (key) => encodeURIComponent(key) + "=" + encodeURIComponent(details[key])
              )
              .join("&");
          
            return await axios.post(
              process.env.REACT_APP_COGNITO_BASE_ENDPOINT + "/oauth2/token",
              formBody,
              headers
            );
          }
          const tokenResponse = await refresh(refresh_token);
          console.log("refresh",tokenResponse)

          new ReportsService().menuAccess(role, this.token)
            .then(async(response) => {
              if (response && response.menuDetails && response.menuDetails.menuAllowed === 'Y') {
                
                var menuAccess = response.menuDetails.details;
                for (var i = 0; menuAccess && i <= menuAccess.length - 1; i++) {
                  var href = menuAccess[i].MENU_CODE;
                  if (href === 'ROPS') {
                    tempSearchMenu = false
                  }
                  if (href === 'ROPSIT') {
                    tempCreateDispatchInStoreMenu = false
                  }
                  if (href === 'ROPSIC') {
                    tempCreateConsolidatedDispatchMenu = false
                  }
                  if (href === 'ROPSD') {
                    tempCreateShipment = false
                  }
                  if(href==="ROPDC")
                  {
                    tempDC=false
                  }
                  if(href==="ROPTDCM")
                  {
                    tempDCTM=false;
                  }
                  if(href==="ROPTDCC")
                  {
                    tempDCTC=false;
                  }
                  if(href ==='ROPSDT')
                  {
                    tempCreateShipmentToggle=false;
                  }
                  if(href==="ROPCL") 
                  {
                    tempChangeLocation=false;
                  }
                  if(href=="ADMIN"){
                    if(group){
                      var groupArray = JSON.parse(jsonPayload)["custom:group"].replace("[","").replace("]","").split(',')
                      if(groupArray.length > 0 && groupArray.toString().includes(menuAccess[i].MENU_DESC)){
                          isSuperUser = true;
                      } else {
                        isSuperUser = false
                      }
                    } else {
                      isSuperUser = false
                    }
                  }
                }
                if(!tempChangeLocation)
                {

                  console.log("change Location",tempDC);
                await  new ConfigService().Location(this.token, isSuperUser ? "S": tempDC?"S":"D").then((Response)=>{
                    let locations=
                    isSuperUser ?  Response.destinationConfigDetails.details.map((a,index)=>{return{STORE_ID:a.STORE_ID,STORE_NAME:a.STORE_NAME, IS_STORE:a.STORE_ID!= a.DC }})
                    :
                    tempDC?
                    Response.destinationConfigDetails.details.filter((a)=>a.STORE_ID!=a.DC):
                      Response.destinationConfigDetails.details.map((a,index)=>{return{STORE_ID:a.DC,STORE_NAME:a.DC_NAME}});
                    
                    isSuperUser ? localStorage.setItem("Return_location_list",
                    JSON.stringify(locations.map((a,index)=>{return {storeNo:a.STORE_ID,storeName:a.STORE_NAME, isStore:a.IS_STORE}})))
                    :
                    localStorage.setItem("Return_location_list",
                    JSON.stringify(locations.map((a,index)=>{return {storeNo:a.STORE_ID,storeName:a.STORE_NAME}})));
          
                  }).catch((err)=>{console.log(err); localStorage.setItem("Return_location_list",[]);});

                }

                if(!tempDC)
                {
                  var temp_storeNo=parseInt(storeNo);
                  if(!(temp_storeNo==29 || temp_storeNo==60 || temp_storeNo==97 || temp_storeNo==267))
                  {
                    await  new ConfigService().getDCID(storeNo,this.token).then((response)=>{
                      if(response.mappedDcIdDet.message=="Dc Id fetched successfully")
                    storeNo=response.mappedDcIdDet.details[0].MAPPED_DC_ID.toString().padStart(3,"0");
                    }).catch((e)=>console.log(e))

                  }
                }









                localStorage.setItem(
                  "User",
                  JSON.stringify({
                    token: access_token, storeNo: storeNo, userID: userId, userName: givenName, role: role, storeName: storeName, displayName: displayName, menuAllowed: "Y",
                    tempSearchMenu: tempSearchMenu, tempCreateDispatchInStoreMenu: tempCreateDispatchInStoreMenu,tempCreateShipmentToggle: tempCreateShipmentToggle, tempCreateConsolidatedDispatchMenu: tempCreateConsolidatedDispatchMenu, tempCreateShipment: tempCreateShipment, 
                    tempDC:tempDC,tempDCTM:tempDCTM,tempDCTC:tempDCTC,loggedInTime: loggedInTime,tempChangeLocation:tempChangeLocation, isSuperUser: isSuperUser
                  }
                ));
                this.props.history.push('/LandingPage')
              }
              else {
                localStorage.setItem(
                  "User",
                  JSON.stringify({
                    token: access_token, storeNo: storeNo, userID: userId, userName: givenName, role: role, storeName: storeName, displayName: displayName, menuAllowed: "N",
                    tempSearchMenu: tempSearchMenu, tempCreateDispatchInStoreMenu: tempCreateDispatchInStoreMenu,tempCreateShipmentToggle: tempCreateShipmentToggle, tempCreateConsolidatedDispatchMenu: tempCreateConsolidatedDispatchMenu, tempCreateShipment: tempCreateShipment, 
                    tempDC:tempDC,loggedInTime: loggedInTime,tempDCTM:tempDCTM,tempDCTC:tempDCTC,tempChangeLocation:tempChangeLocation, isSuperUser: isSuperUser
                  }
                ));
                this.props.history.push('/LandingPage') }
            })
            .catch(error => {
              localStorage.setItem(
                "User",
                JSON.stringify({
                  token: access_token, storeNo: storeNo, userID: userId, userName: givenName, role: role, storeName: storeName, displayName: displayName, menuAllowed: "N",
                  tempSearchMenu: tempSearchMenu, tempCreateDispatchInStoreMenu: tempCreateDispatchInStoreMenu, tempCreateConsolidatedDispatchMenu: tempCreateConsolidatedDispatchMenu, tempCreateShipment: tempCreateShipment, 
                  tempDC:tempDC,loggedInTime: loggedInTime,tempDCTM:tempDCTM,tempDCTC:tempDCTC,tempCreateShipmentToggle:tempCreateShipmentToggle, isSuperUser: isSuperUser
                  ,tempChangeLocation:tempChangeLocation
                }
              ));
              this.props.history.push('/LandingPage')})
          // this.storeName = this.props.location.state.storName;

        }
      } catch (err) {
        err && this.props.history.push("/");
      }
    }
  };



  render() {
    return (<div className="Authentication layout-desktop position-loader">
      <div className="loader" ></div></div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  saveTokens: (tokens) => dispatch(actions.saveTokens(tokens)),
});

export default connect(null, mapDispatchToProps)(Authentiction);