export const LOAD_UBL_DATA_IN_PROGRESS = "LOAD_UBL_DATA_IN_PROGRESS";
export const LOAD_UBL_DATA_SUCCESS = "LOAD_UBL_DATA_SUCCESS";
export const LOAD_UBL_DATA_FAILURE = "LOAD_UBL_DATA_FAILURE";

export const ADD_UBL_DATA_IN_PROGRESS = "ADD_UBL_DATA_IN_PROGRESS";
export const ADD_UBL_DATA_SUCCESS = "ADD_UBL_DATA_SUCCESS";
export const ADD_UBL_DATA_FAILURE = "ADD_UBL_DATA_FAILURE";

export const GET_DC_LOCATION_IN_PROGRESS = "GET_DC_LOCATION_IN_PROGRESS";
export const GET_DC_LOCATION_SUCCESS = "GET_DC_LOCATION_SUCCESS";
export const GET_DC_LOCATION_FAILURE = "GET_DC_LOCATION_FAILURE";

export const GET_UBL_DETAILS_IN_PROGRESS = "GET_UBL_DETAILS_IN_PROGRESS";
export const GET_UBL_DETAILS_SUCCESS = "GET_UBL_DETAILS_SUCCESS";
export const GET_UBL_DETAILS_FAILURE = "GET_UBL_DETAILS_FAILURE";

export const PUT_UBL_DETAILS_TO_DC_LOCATION_IN_PROGRESS =
  "PUT_UBL_DETAILS_TO_DC_LOCATION_IN_PROGRESS";
export const PUT_UBL_DETAILS_TO_DC_LOCATION_SUCCESS =
  "PUT_UBL_DETAILS_TO_DC_LOCATION_SUCCESS";
export const PUT_UBL_DETAILS_TO_DC_LOCATION_FAILURE =
  "PUT_UBL_DETAILS_TO_DC_LOCATION_FAILURE";

export const LOAD_CBL_MAPTOUBL_DATA_IN_PROGRESS =
  "LOAD_CBL_MAPTOUBL_DATA_IN_PROGRESS";
export const LOAD_CBL_MAPTOUBL_DATA_SUCCESS = "LOAD_CBL_MAPTOUBL_DATA_SUCCESS";
export const LOAD_CBL_MAPTOUBL_DATA_FAILURE = "LOAD_CBL_MAPTOUBL_DATA_FAILURE";

export const LOAD_CBL_DESTINATION_DATA_IN_PROGRESS =
  "LOAD_CBL_DESTINATION_DATA_IN_PROGRESS";
export const LOAD_CBL_DESTINATION_DATA_SUCCESS =
  "LOAD_CBL_DESTINATION_DATA_SUCCESS";
export const LOAD_CBL_DESTINATION_DATA_FAILURE =
  "LOAD_CBL_DESTINATION_DATA_FAILURE";

export const ADD_CBL_IN_PROGRESS = "ADD_CBL_IN_PROGRESS";
export const ADD_CBL_SUCCESS = "ADD_CBL_SUCCESS";
export const ADD_CBL_FAILURE = "ADD_CBL_FAILURE";

export const VIEW_MODIFY_CBL_DATA_IN_PROGRESS =
  "VIEW_MODIFY_CBL_DATA_IN_PROGRESS";
export const VIEW_MODIFY_CBL_DATA_SUCCESS = "VIEW_MODIFY_CBL_DATA_SUCCESS";
export const VIEW_MODIFY_CBL_DATA_FAILURE = "VIEW_MODIFY_CBL_DATA_FAILURE";

export const VIEW_UBL_SUMMARY_IN_PROGRESS = "VIEW_UBL_SUMMARY_IN_PROGRESS";
export const VIEW_UBL_SUMMARY_SUCCESS = "VIEW_UBL_SUMMARY_SUCCESS";
export const VIEW_UBL_SUMMARY_FAILURE = "VIEW_UBL_SUMMARY_FAILURE";

export const GET_CUBL_IN_PROGRESS = "GET_CUBL_IN_PROGRESS";
export const GET_CUBL_SUCCESS = "GET_CUBL_SUCCESS";
export const GET_CUBL_FAILURE = "GET_CUBL_FAILURE";

export const DELETE_CUBL_IN_PROGRESS = "DELETE_CUBL_IN_PROGRESS";
export const DELETE_CUBL_SUCCESS = "DELETE_CUBL_SUCCESS";
export const DELETE_CUBL_FAILURE = "DELETE_CUBL_FAILURE";
