import Header from "components/common/Header";
import React, { useEffect, useState,useRef } from "react";
import $ from "jquery";
import { Toast } from "react-bootstrap";
import LoadingIndicator from "components/common/LoadingIndicator";
import DcreceptingService from "services/DcreceptingService";
import Viewcbldetails from "./viewcbldetails";
import { Link } from "react-router-dom";
import Sidebar from "components/common/Sidebar";
import { callLogoutURL } from "components/Authentication/utilities";
import { useIdleTimer } from 'react-idle-timer';

const DCReceiptingManifest = () => {
  const user = JSON.parse(localStorage.getItem("User"));
  const [loader, setLoader] = useState(false);
  const [manifestext, setmanifesttext] = useState("");
  const [successToast, setSuccessToast] = useState(false);
  const [manifest, setmanifest] = useState("");
  const [validate_loc,setValidate_loc]=useState(true); 
  const [manifestdetails, setmanifestdetails] = useState([]);
  const [itemIds, setitemids] = useState([]);
  const [pageno, setpageno] = useState(0);
  const [cblid, setcblid] = useState("");
  const [location,setlocation]=useState("");
  const [locationdisable,setlocationdisable]=useState(false);
  const [itemTaggingToast, setItemTaggingToast] = useState(false);
  const [errmsg,seterrmsg]=useState("");
  const [toastmsg, settoastmsg] = useState("UBL Headers created Successfully");
  const [invalidUblNum,setinvalidUblNum]=useState(false);
  const [DCrec,setDCrec]=useState("");
  const [inpg,setinpg]=useState(true);
  const [    validchange,setvalidchange]=useState(true);
  const [Valoc_text,setValoc_text]=useState("");
  const [timeoutDuration] = useState(
    localStorage.getItem("timeout") ? localStorage.getItem("timeout") : 1800000
  );
  const focus_filed_ref=useRef(false);
  console.log(user);
  const inputFocus2=useRef(); 
  const  inputFocus=useRef();
  useEffect(()=>{
    inputFocus.current.focus()
  },[]);

  const renderdata = (data) => {
    return (
      <>
        {data.map((item, index) => {
          return (
            <tr
              className="cbl_list_table cursor-pointer"
             
              onClick={() => {
                setcblid(item);
                setpageno(2);
              }}
            >
              <td className="w-70">{item}</td>
              {item.length==7 ? (
                <td>1</td>
              ) : (
                <td>
                  {
                    manifestdetails.filter(
                      (item_details) => item_details.cublId == item
                    ).length
                  }
                </td>
              )}
              <td
              className="text-right color-blue"
              >
                 <i class="fas fa-chevron-right"></i>
              </td>
            </tr>
          );
        })}
      </>
    );
  };

  if (typeof window != 'undefined') {
    console.log("listener", localStorage.getItem("access_token"));
    window.addEventListener("storage", function (e) {
      if (localStorage.getItem('access_token') == "null") {
        callLogoutURL()
        return
      }
    })
  }

  useEffect(()=>{
    console.log('useeffect in manifest')
    window.addEventListener('getScannedDataInDevice', getScannedDataInDevice);
   if (localStorage.getItem('access_token') == "null") {
    callLogoutURL()
    return
  }
    return() =>{
      window.removeEventListener('getScannedDataInDevice', getScannedDataInDevice);
    } 
  },[])

  const getScannedDataInDevice = (data) =>{
    let inputBarcode=data.detail.toString().trim();
    if(focus_filed_ref.current)
    {
    setmanifest(inputBarcode);
  
    getManifest(true,inputBarcode);
    }
  }
  const handleOnchangeManifest = (e) => {
    setmanifesttext(e.target.value.replace(/[^0-9\+]/g, ""));
    let barcode=e.target.value.replace(/[^0-9\+]/g, "") 
    if(barcode.length==9 &&
       barcode.substr(0,3)!="000"&&  barcode.charAt(3)=="1" &&
    barcode.substr(4,5)>0)
    setinvalidUblNum(false);
    else if(barcode=="")
    setinvalidUblNum(false);
    else
    setinvalidUblNum(true);

  };
  const getManifest = (isScanned, scannedData) => {
    
    let inputBarcode = isScanned ? scannedData :  manifestext;
    let barcode=inputBarcode;
    if(barcode.length==9 &&
       barcode.substr(0,3)!="000"&&  barcode.charAt(3)=="1" &&
    barcode.substr(4,5)>0)
    setinvalidUblNum(false);
    else if(barcode=="")
    setinvalidUblNum(false);
    else
    setinvalidUblNum(true);

    if(!(inputBarcode.length==9 &&
      inputBarcode.substr(0,3)!="000"&&  inputBarcode.charAt(3)=="1" &&
      inputBarcode.substr(4,5)>0))
      return;

    setLoader(true);
    setmanifest(inputBarcode);
    setmanifesttext(inputBarcode);
    setpageno(0);
    DcreceptingService.ViewManifest(
      parseInt(user.storeNo),
      inputBarcode,
      user.displayName,
      user.token
    )
      .then((response) => {
        setmanifesttext("");
        setLoader(false);
        if (response.printManifest.results && response.printManifest.results.length>0) {

         if(response.printManifest.results
          .filter(a=>((a.ublStatus=="R"||a.ublStatus=="RD"||a.ublStatus=="SD" ||a.ublStatus=="DS"))).length==response.printManifest.results.length)
          response.printManifest.results[0].shipStatus="R";
        else
        if(response.printManifest.results
          .filter(a=>((a.ublStatus=="R"||a.ublStatus=="RD"||a.ublStatus=="SD" || a.ublStatus=="DS"))).length>0)
          response.printManifest.results[0].shipStatus="I";
          
          if(!user.tempDCTM &&
            
           ( response.printManifest.results[0].shipStatus=="R" )
         )
          {
            seterrmsg("Manifest already Received!");
            $("#errorModal").modal();
            
            return;
          }

          if(user.tempDCTM && (response.printManifest.results[0].shipStatus!="R"&&
          response.printManifest.results[0].shipStatus!="I"))
          {
            seterrmsg("Manifest not yet Received!");
            $("#errorModal").modal();
            return;

          }
          if(response.printManifest.results[0].shipStatus=="I" 
          )
          setinpg(true);
          else
          setinpg(false);
          if(response.printManifest.results[0].shipStatus=="C")
          {
            seterrmsg("Manifest not yet despatch");
            $("#errorModal").modal();
            return;
          }
          setpageno(1);
          var item_id = response.printManifest.results.map((item, index) => {
            
            return item.cublId && item.cublId != "NULL"
              ? item.cublId
              : item.ublId;
          });
          setmanifestdetails(response.printManifest.results);
          console.log(response.printManifest.results);
          item_id=[...new Set(item_id)];
          var cbl=item_id.filter((a)=>a.length==10).sort((a, b)=>a-b);
          var ubl=item_id.filter((a)=>a.length==7).sort((a, b)=>a-b);

          setitemids(cbl.concat(ubl));
        }
        else
        {
          
        
          seterrmsg("Manifest not found ");
          $("#errorModal").modal();
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };
  const receiveManifest = () => {
    setLoader(true);
    DcreceptingService.receiveManifest(
      user.displayName,
      manifest,
      parseInt(user.storeNo),
      user.token,
      DCrec
    )
      .then((response) => {
        setLoader(false);
        if (
          response.updateRecieveManifest.message ==
          "Receive Manifest Details Updated for CUBL,UBL,SHIPMENT" ||
          response.updateRecieveManifest.message ==
          "Receive Manifest Details Partially Updated for CUBL,UBL,SHIPMENT"
        ) {
         
          settoastmsg(
            "Shipment received successfully",
            setItemTaggingToast(true)
          );
          setpageno(0);
          setmanifesttext("");
          setlocation("");
          setlocationdisable(false);
          $(".locField").removeClass("disabletextbg");
          $(".inputLoc").attr("disabled", false);

        } 
        else {
          seterrmsg(response.updateRecieveManifest.message);
          $("#errorModal").modal();
        }
      })
      .catch((err) => {console.log(err);setLoader(false)});
  };
  const getsupplierdetails=async ()=>{
    if(location){
    setvalidchange(false);
    setDCrec(location);
    setLoader(true);
    const res = await fetch(
      process.env.REACT_APP_API_BASE_ENDPOINT +
      process.env.REACT_APP_DC +
        location +
        "/dcLocValid",
      {
        method: "GET",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          Authorization: user.token,
        },
        
      }
    );
    setlocationdisable(true);
    inputFocus2.current.focus();
    const data = await res.json();
    const temp=(data.dc_loc_valid && data.dc_loc_valid.supplierDesc)?
    data.dc_loc_valid.supplierDesc.toString().trim():"";
    setlocation(
      location+((temp&&temp!="")?("-"+temp):"")
    )
    setLoader(false);

  }
}
  const retunrecievedcbl=()=>
  {
    let ans=0;
    for( let i=0;i<itemIds.length;i++)
    if(itemIds[i].length==10)
    if(manifestdetails.filter(a=>(a.cublId==itemIds[i])).length
    ==manifestdetails.filter(a=>(a.cublId==itemIds[i] && (a.ublStatus=="R"||a.ublStatus=="RD"||a.ublStatus=="SD" || a.ublStatus=="DS"))).length)
    ans=ans+1;
    return ans;

  }

  const handleOnIdle = (event) => {
    console.log("user is idle", event);
    console.log("last active", getLastActiveTime());
    callLogoutURL();
  };

  const handleOnActive = (event) => {
    console.log("user is active", event);
    console.log("time remaining", getRemainingTime());
    handleReset();
    if(localStorage.getItem('access_token') == "null"){
      callLogoutURL()
      return
    }
  };

  const handleOnAction = (event) => {
    console.log("user did something", event);
    if(localStorage.getItem('access_token') == "null"){
      callLogoutURL()
      return
    }
  };
  const { getRemainingTime, getLastActiveTime, reset } = useIdleTimer({
    timeout: timeoutDuration,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });
  const handleReset = () => reset();


  return (<div id="DCReceipting">
      <Header />
      <div className="container-fluid">
        <div className="row">
          <Sidebar active="DCReceipting" state={user} />
          <main
            role="main"
            className="col-md-9 ml-sm-auto col-lg-10 rtn-main-wrapper p-0"
          
          >
            {pageno < 2 ? (
              <>
                <section className="dcreceipting-header">
                  <p>DC RECEIPTING</p>
                  <div
                   class="d-none d-lg-block d-md-block"
                  style={{
                float:"right",
                marginTop:"-40px"
              }}>
                  {/* {<Breadcrumb crumbs={crumbs} selected={selected} />} */}
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb" style={{fontSize:"12px"}}>
                    <Link
                        to={{ pathname: "/LandingPage", state: user }}
                        className="breadcrumb-item breadcrumb-color pr-10"
                      >
                        Home
                      </Link>
                      <span className="pr-10"> {" >"}</span>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                       DC Receipting
                      </li>
                    </ol>
                  </nav>
                </div>
                </section>
                <div className="row">
                <section className="dcr-fields col-md-8 col-10">
                  <div className="dcr-input-group">
                  <form
                  className={"dcr-input-icon-container locField"}
                 
                  onSubmit={(e)=>{e.preventDefault();
                    if(location.length<=7)
                    getsupplierdetails();}}
                >
                  <i className="fas fa-map-marker-alt ml-10"></i>
                  <input
                        className="inputLoc"
                        type="text"
                        autocomplete="off"
                        value={location}
                        //disabled={locationdisable}
                        placeholder="Scan or Enter Location"
                        name="location"
                       
                        ref={inputFocus}
                           onChange={(e) => {setlocation(e.target.value);setvalidchange(true);
                           var letterNumber = /^[0-9a-zA-Z]*$/;
                           if(letterNumber.test(e.target.value))
                    { if(e.target.value.length>7)
                     {
                       setValidate_loc(false);
                       setValoc_text("Receive location entered exceed maximum length(7)");
                     }
                     else
                     setValidate_loc(true);
                    }
                    else
                    {
                     setValoc_text("Invalid Location!");
                     setValidate_loc(false);
 
                    }
                    }}
                      />
                  <img
                    class="dcr-dispatch-scanner-img"
                    src="./images/barcodescanner.png"
                    alt=""
                  />
                </form>
                <div className="invalid-ubl-num" 
                  style={{fontSize:"11px"}}
                  hidden={validate_loc}
                 >
                        <img src="./images/error-icon.svg" alt="" />
                      {Valoc_text}
                      </div>
                  </div>
                  <div className="dcr-options">
                    <ul>
                      <li>
                        <Link
                      
                          to="/DCReceiptingUbl"
                          class={pageno==1?" LinkUBLCBL pointernon":"LinkUBLCBL"}
                        >
                          UBL
                        </Link>
                      </li>
                      <li>
                        <Link
                          style={{ textDecoration: "none", color: "black" }}
                          to="/DCReceiptingCbl"
                          class={pageno==1?"pointernon":""}
                        >
                          CBL
                        </Link>
                      </li>
                      <li className="active">Manifest</li>
                    </ul>
                  </div>
                  <div>
                    <p>
                      <strong>Scan/Enter Manifest</strong>
                    </p>
                    <div className="dcr-input-group">
                    <form
                    onSubmit={(e)=>{getManifest(false,"");e.preventDefault()}}
                    className={(!locationdisable)?"dcr-input-icon-container disabletextbg"
                  :"dcr-input-icon-container"}>
                        <input
                          type="text"
                          placeholder="Scan/Enter Manifest"
                          name="ublId"
                          disabled={!locationdisable}
                          value={manifestext}
                          ref={inputFocus2}
                          onChange={(e)=>{setmanifesttext(e.target.value.replace(/[^0-9\+]/g, ""));
                          setinvalidUblNum(false);
                        }}
                          onFocus={(e)=>{setinvalidUblNum(false);
                            focus_filed_ref.current=true;
                          }}
                          onBlur={(e)=>{handleOnchangeManifest(e);
                            focus_filed_ref.current=false;
                          }}
                        
                          autocomplete="off"
                        />
                        <img
                          class="dcr-dispatch-scanner-img"
                          src="./images/barcodescanner.png"
                          alt=""
                        />
                       
                      </form>
                      {invalidUblNum? (
                      <div className="invalid-ubl-num">
                        <img src="./images/error-icon.svg" alt="" />
                        Invalid Manifest!
                      </div>
                    ) : null}

                    </div>
                  </div>
                </section>
                </div>

                {pageno == 1 ? (
                  <>
                    <section
                      className="scanned-ubl-results"
                    >
                     <div className="sur-header ml-1 mb-3">
                        <p>
                          Scanned results for Manifest
                        </p>
                      </div>
                      <section
                        className="trp-container manifest_check"
                        style={{fontSize:"12px"}}
                       
                      >
                        <div className="count_container">
                          <p  className="count_container_val">Manifest</p>
                          <p className="p10">#{manifest}</p>
                        </div>
                        <div className="count_container">
                          <p  className="count_container_val">CBL Received</p>
                          <p className="p10" >
                            {
                              retunrecievedcbl()
                            }/{
                              itemIds.filter(
                                (item_details) =>
                                  item_details.length==10
                              ).length
                            }
                          </p>
                        </div>
                        <div className="count_container">
                          <p className="count_container_val">UBL Received</p>
                          <p className="p10">
                            {manifestdetails.filter(a=>((a.ublStatus=="R"||a.ublStatus=="RD"||a.ublStatus=="SD" || a.ublStatus=="DS"))).length}
                            /{manifestdetails.length}</p>
                        </div>
                      </section>

                      <div
                        class="table-responsive manifest_table"
                        
                      >
                        <table
                          class="table p-0 m-0 "
                          
                        >
                          <thead className="bg-blue">
                            <th scope="col cbl_list_table" colspan="3">
                              CBL/UBL
                            </th>
                          </thead>
                          <tbody>{renderdata(itemIds)}</tbody>
                        </table>
                      </div>
                    </section>
                    <section className="dcru-buttons">
                      <button
                        type="button"
                        data-toggle="modal"
                        data-target="#exampleModal1"
                        className="back_button"
                      >
                        Back
                      </button>
                      <button
                        type="button"
                        disabled={ user.tempDCTM || inpg ||  validchange}
                        onClick={receiveManifest}
                        className="recieve_manifest"
                        
                      >
                        Receive Manifest
                      </button>
                    </section>
                  </>
                ) : (
                  <div className="dcru-no-items-found">
                    <img src="./images/Item-not-found.svg" alt="" />
                    <p>No items found</p>
                  </div>
                )}
              </>
            ) : (
              <Viewcbldetails
                cbl={cblid}
                manifestno={manifest}
                inpg={inpg}
                manifestdetail={manifestdetails}
                cbldetails={
                  cblid.length==7
                    ? manifestdetails.filter(
                        (item_details) => item_details.ublId == cblid
                      )
                    : manifestdetails.filter(
                        (item_details) => item_details.cublId == cblid
                      )
                }
                back={() => {
                  setpageno(1);
                }}
                setmanifestdetail={(details) => setmanifestdetails(details)}
                DCrec={DCrec}
                setinpg={()=>setinpg(true)}
              />
            )}

            <div className="topCenter success-toast">
              <Toast
                id="toast"
                show={successToast}
                onClose={() => setSuccessToast(false)}
                delay={5000}
                autohide
              >
                <div className="toast__content pt-12">
                  <div className="tickmark" style={{ height: "100%" }}>
                    <img
                      className="tickmark mt-5"
                      src="./images/tick-circle-icon.svg"
                      alt=""
                    />
                  </div>
                  <div>
                    <p className="toast__type">Success</p>
                    <p className="toast__message">
                      UBL(s) Received successfully
                    </p>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="close toast-close"
                      data-dismiss="toast"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
              </Toast>
            </div>

            {/* Invalid UBL Alert Modal */}
            <div
              className="modal fade"
              id="invalidUbl"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content dcr-modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Alert
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body dcr-modal-alert">
                    <i className="fas fa-exclamation-triangle"></i>
                    Invalid UBL
                  </div>
                  <div className="dcr-modal-alert-button">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="topCenter" hidden={!itemTaggingToast}>
              <Toast
                id="toast"
                show={itemTaggingToast}
                onClose={() => setItemTaggingToast(false)}
                delay={5000}
                autohide
              >
                <div className="toast__content">
                  <div>
                    <span class="vertical-toast-border"></span>
                    <div className="tickmark" >
                      <img
                        className="tickmark"
                        src="./images/tick-circle-icon.svg"
                        alt=""
                      />
                    </div>
                    <div className="pt-10">
                      <p className="toast__type">Manifest#:{manifest}</p>
                      <p className="toast__message"style={{marginLeft:"50px"}}>{toastmsg}</p>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="close toast-close"
                        data-dismiss="toast"
                        aria-label="Close"
                        onClick={() => setItemTaggingToast(false)}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                </div>
              </Toast>
            </div>
            <div
              className="modal fade loginModal"
              id="exampleModal1"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="errorModalCenterTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-z"
                role="document"
              >
                <div className="modal-content modal-radius">
                  <div className="modal-header confirmation-header">
                    <h5
                      className="modal-title confirmation-style"
                      id="errorModalCenterTitle"
                    >
                      Confirmation
                    </h5>
                  </div>
                  <div className="modal-body text-align-centre font-weight-bold">
                    <div>
                      Receive Manifest not completed! <br /> Are you sure you
                      want to click back?
                    </div>
                  </div>
                  <div className="modal-footer bo-modalbtnftr bt-none justify-content-center justify-content-md-end">
                  
                    <button
                      
                      type="button"
                      className="text-dark text-decoration-none 
                      btn bo-btnprimary 
                      confirm-no
                      border border-secondary"
                      data-dismiss="modal"
                      style={{width:"45%"}}
                    >
                      No
                    </button>
                    <button
                      data-dismiss="modal"
                      type="button"
                      className=" btn bo-btnprimary confirm-yes"
                      onClick={() => {
                        setpageno(0);
                        setmanifesttext("");
                        setlocation("");
                        setlocationdisable(false);
                        $(".locField").removeClass("disabletextbg");
                        $(".inputLoc").attr("disabled", false);
                      }}
                      style={{width:"45%"}}>
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
       

      <div
              className="modal fade"
              id="errorModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content dcr-modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Alert
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body dcr-modal-alert">
                    <i className="fas fa-exclamation-triangle"></i>
                    {errmsg}
                  </div>
                  <div className="dcr-modal-alert-button">
                    <button
                      type="button"
                      className="btn btn-secondary text-decoration-none"
                      data-dismiss="modal"
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
                  

            {loader ? <LoadingIndicator /> : null}
          </main>
        </div>
      </div>
    </div>
  );
};

export default DCReceiptingManifest;
