import React, { useState, useEffect,useRef } from "react";
import * as constants from "../../constants";
import LoadingIndicator from "../../common/LoadingIndicator";
import scanner from "../../../assets/images/barcode-thin.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import CreateShipment from "../../../services/CreateShipmentService";
import $ from "jquery";
import Pagination from "components/common/Pagination";

const CreateManifest = (props) => {
  console.log(props);

  const [barcode, setbarcode] = useState("");
  const [barcodeValid, setvalidatebarcode] = useState(true);
  const [items, setItem] = useState(props.item);
  const [deleteindex, setDeleteindex] = useState(1);
  const [dele, setdelete] = useState(true);
  const [flag, setflag] = useState(true);
  const [msg, setmsg] = useState("");
  const [msgheader, setmsgheader] = useState("");
  const [loader, setloader] = useState(false);
  const createShipment = new CreateShipment();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [input_alert, setinputalert] = useState("");
  const temp_item_detail = [{ cublId: "" }];
  const [itemdetail, setitemdetail] = useState(temp_item_detail);
  const indexOfLastItem = currentPage * itemsPerPage;
  const [itemtype, setitemtype] = useState(true);
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);
  const [ubldetails, setubldetails] = useState(props.ubldetails);
  const [checked,setchecked]=useState(new Array(props.cublubllist.length).fill(false));
  const [selectall,setselectall]=useState(false);
  const [toggle,settoggle]=useState(true);
  const [itemchange,setitemchange]=useState(true);
  const cubllist=useRef(props.cublubllist);
  const item_ref=useRef(props.item);
  const [focused,setfocused]=useState(false);
  const  inputFocus=useRef();
  useEffect(()=>{
    inputFocus.current.focus()
  },[]);
  const user = JSON.parse(localStorage.getItem("User"));


 useEffect(()=>{cubllist.current.value=props.cublubllist},[props.cublubllist])
useEffect(()=>{},[toggle,props.item]);
useEffect(()=>{
  
  console.log(cubllist.current.value);
  for(let i=0;i<cubllist.current.value.length;i++)
  { let val =cubllist.current.value[i].CUBL_ID?cubllist.current.value[i].CUBL_ID:cubllist.current.value[i].UBL_ID;
   if(items.includes(val))
    {
      let temp_c=checked;
      temp_c[i]=true;
      setchecked(temp_c);
    }
    if(items.length==cubllist.current.value.length)
  setselectall(true);
 
  }
  settoggle((prev)=>!prev);
  
},[props.pageno,itemchange]);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 700;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);
   useEffect(()=>{item_ref.current.value=items;},[items,props.item]);
  useEffect(() => {}, [dele, flag,props.item]);
  useEffect(()=>{

    $('#CBLdropdown').click(()=>{
    let temp=[];
    for(let i=0;i<cubllist.current.value.length;i++)
    {
       let val =cubllist.current.value[i].CUBL_ID?cubllist.current.value[i].CUBL_ID:cubllist.current.value[i].UBL_ID;
     if(item_ref.current.value.includes(val))
      {
        temp.push(true);
     
      }
      else
      {
        temp.push(false);

      }

      if(item_ref.current.value.length==cubllist.current.value.length)
    setselectall(true);
    else
    setselectall(false);

   
    }
    setchecked(temp);
    settoggle((prev)=>!prev);
  });

},[])

  const deleteCUBL = () => {
    var ind = parseInt(deleteindex);
    console.log(ind);
    var del = items;
    del.splice(ind, 1);

    for(let i=0;i<props.cublubllist.length;i++)
    { let val =props.cublubllist[i].CUBL_ID?props.cublubllist[i].CUBL_ID:props.cublubllist[i].UBL_ID;
     if(del.includes(val))
      {
        let temp_c=checked;
        temp_c[i]=true;
        setchecked(temp_c);
      }
      else
      {let temp_c=checked;
        temp_c[i]=false;
        setchecked(temp_c);

      }
    }
    setItem(del);
    setdelete(!dele);
    setCurrentPage(1);
    setselectall(false);
   
    console.log(items);
  };
  const validatebarcode = (event) => {
    setfocused(true);
    setbarcode(event.target.value);
    setinputalert("Invalid Barcode !");

    var inputBarcode = event.target.value.toString().trim();
    console.log(inputBarcode);

    setbarcode(inputBarcode.replace(/[^0-9.]/g, ""));
    if (
      (inputBarcode.length === 10 &&
        !isNaN(inputBarcode) &&
        inputBarcode.substr(0, 3) ===
  
            ("00" + props.storeNo).slice(-3) &&
        inputBarcode.substr(3, 7) > 0) ||
      ((inputBarcode.length === 7 &&
        !isNaN(inputBarcode) &&
       
        inputBarcode > 0) && !user.tempCreateShipmentToggle)
    ) {
      setvalidatebarcode(true);
    } else if (inputBarcode.length == 0) {
      setvalidatebarcode(true);
    } else {
      setvalidatebarcode(false);
    }
  };
  const onclearbar = () => {
    setbarcode("");
    setvalidatebarcode(true);
    setflag(!flag);
  };
  const isCheckDigitValid = (barcode) => {
    var oddSum = 0;
    var evenSum = 0;
    for (var i = 0; i < barcode.length; i++) {
      if (i % 2 == 1) {
        oddSum = parseInt(barcode.charAt(i)) + oddSum;
      } else {
        evenSum = parseInt(barcode.charAt(i)) + evenSum;
      }
    }
    var result = (oddSum + evenSum) % 10;
    console.log(oddSum, evenSum, result);
    if (10 - result == barcode.charAt(19)) {
      return true;
    } else {
      return false;
    }
  };
  const handleaddcarrier = () => {
    console.log(itemdetail);
    if (items.length == 0) {
      setmsgheader("Alert");
      setmsg(" No CBL/UBL Entered");
      $("#exampleModalCenter1").modal();
      return;
    }
    props.handlefirstpage(items, itemdetail, ubldetails);
  };
  const onCancel = () => {
    setchecked(new Array(props.cublubllist.length).fill(false));
    setselectall(false);
    setitemdetail(temp_item_detail);
    setItem([]);
  };
  const onView = (barcode_1) => {
    setloader(true);
    setitemdetail(temp_item_detail);
    createShipment
      .ViewUBLSummary(props.storeNo, barcode_1, props.token)
      .then((response) => {
        if (response.UblSummaryDetails.details) {
          setitemdetail(response.UblSummaryDetails.details);
          setloader(false);
          $("#myModal2").modal();
        } else {
          setmsgheader("Error");
          setmsg(response.UblSummaryDetails.message);
          $("#exampleModalCenter1").modal();
          setloader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setloader(false);
      });
  };

  const addCUBL = () => {
    if (barcode == "") {
      setmsgheader("Alert");
      setmsg("CBL/UBL Id must not be Empty");
      $("#exampleModalCenter1").modal();
      return;
    }


    if (barcodeValid)
      if (items.indexOf(barcode) === -1) {
        setloader(true);

        createShipment
          .validateCUBL(props.storeNo, barcode, props.token)
          .then((response1) => {
            if (
              (response1.validate_cublubl.message == "Valid Ubl Id" &&
                (!response1.validate_cublubl.details[0].CUBL_ID ||
                  response1.validate_cublubl.details[0].CUBL_ID == "null") &&
                (!response1.validate_cublubl.details[0].SHIP_ID ||
                  response1.validate_cublubl.details[0].SHIP_ID == "null") &&
                response1.validate_cublubl.details[0].UBL_STATUS == "C") ||
              (response1.validate_cublubl.message == "Valid Cubl Id" &&
                (!response1.validate_cublubl.details[0].SHIP_ID ||
                  response1.validate_cublubl.details[0].SHIP_ID == "null") &&
                response1.validate_cublubl.details[0].UBL_STATUS == "C")
            ) {
              var item = items;
              if (item.length == 0) {
                item = [];
              }
                item.push(barcode);
                setItem(item, setloader(false));
                for(let i=0;i<props.cublubllist.length;i++)
                { let val =props.cublubllist[i].CUBL_ID?props.cublubllist[i].CUBL_ID:props.cublubllist[i].UBL_ID;
                 if(item.includes(val))
                  {
                    let temp_c=checked;
                    temp_c[i]=true;
                    setchecked(temp_c);
                  }
                  if(item.length==props.cublubllist.length)
                 setselectall(true);
               
                }
                setbarcode("");
                setCurrentPage(1);
                setflag(!flag);
              
            } else {
              var inputBarcode = barcode;
              if (
                inputBarcode.length === 7
              ) {
                if (
                  response1.validate_cublubl.message ==
                  "Cubl/Ubl details not found"
                )
                  setinputalert("UBL details not found");
                else if (
                  response1.validate_cublubl.details[0].UBL_STATUS == "X"
                )
                  setinputalert("Detagged UBL");
                else if (
                  response1.validate_cublubl.details[0].UBL_STATUS == "D"
                )
                  setinputalert("UBL already Despatched");
                else if (response1.validate_cublubl.details[0].SHIP_ID)
                  setinputalert("UBL already tagged to another Shipment");
                else
                  setinputalert(
                    "UBL is Tagged to the CBL, Hence please scan the CBL"
                  );
              } else {
                if (
                  response1.validate_cublubl.message ==
                  "Cubl/Ubl details not found"
                )
                  setinputalert("CBL details not found");
                else if (
                  response1.validate_cublubl.details[0].UBL_STATUS == "D"
                )
                  setinputalert("CBL already Despatched");
                else setinputalert("CBL already tagged to another Shipment");
              }
              setvalidatebarcode(false);
              setloader(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setloader(false);
          });
      } else {
        setmsgheader("Error");
        setmsg(!user.tempCreateShipmentToggle?"CBL/UBL Already Exists in this Shipment":"CBL Already Exists in this Shipment");
        $("#exampleModalCenter1").modal();
      }
  };

  const renderData = (data) => {
    return (
      <>
        {data
          .map((inputBarcode, index) => {
            if (
              inputBarcode.length === 10 &&
              !isNaN(inputBarcode) &&
              inputBarcode.substr(0, 3) ===
                  ("00" + props.storeNo).slice(-3) &&
              inputBarcode.substr(3, 7) > 0
            )
              return (
                <div
                  class="card bg-white min"
                  style={{ minWidth: "200px" }}
                  index={index}
                >
                  <div class="card-header bgselago">
                    <p class="barcode-font">{inputBarcode}</p>
                    <button
                      class="delete-icon"
                      id="removeBtn"
                      data-toggle="modal"
                      value={index}
                      onClick={() => {
                        setitemtype(
                          inputBarcode.length==7
                        );

                        setDeleteindex(index);
                      }}
                      data-target="#exampleModalCenter"
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </div>
                  <div class="card-body  p10">
                    <h6 class="card-title fs12 fw6">{props.Address.DC_NAME}</h6>
                    <p class="card-text fs12">
                      {props.Address.DC}-{props.Address.ADDRESS_LN1},
                      {props.Address.ADDRESS_LN2}
                      {props.Address.ADDRESS_LN3 &&
                      props.Address.ADDRESS_LN3 != "NULL"
                        ? "," + props.Address.ADDRESS_LN3
                        : null}{" "}
                      {props.Address.POST_CD}
                    </p>
                  </div>
                  <div class="card-footer text-center bg-white bluec">
                    <button
                      class="border-none bg-none bluec viewbtn view"
                      onClick={() => {
                        onView(inputBarcode);
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon
                        icon={faEye}
                        className="view"
                      /> View{" "}
                    </button>
                  </div>
                </div>
              );
            else if(inputBarcode.length===7) 
            {
              var ind;
              for (let i = 0; i < props.ubldetails.length; i++)
                if (props.ubldetails[i].UBL_ID == inputBarcode) ind = i;
              return (
                <div
                  class="card bg-white min"
                  index={index}
                  style={{ Width: "200px" }}
                >
                  <div
                    class="card-body"
                    style={{
                      padding: "10px 0px 10px 5px",
                      borderRadius: "5px",
                    }}
                  >
                    <button
                      class="delete-icon"
                      id="removeBtn"
                      data-toggle="modal"
                      value={index}
                      onClick={() => {
                        setitemtype(
                          inputBarcode.length==7
                        );

                        setDeleteindex(index);
                      }}
                      data-target="#exampleModalCenter"
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                    <h6
                      class="card-title"
                      style={{
                        fontSize: "13px",
                        fontWeight: 400,
                      }}
                    >
                      Item #:{props.ubldetails[ind].ITEM_ID}
                    </h6>
                    <p
                      class="card-text"
                      style={{
                        fontWeight: 600,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {props.ubldetails[ind].ITEM_DESCR}
                    </p>
                  </div>
                  <div
                    class="card-footer"
                    style={{
                      margin: "0px",
                      padding: "15px 0px 5px",
                      backgroundColor: "#f9f9fe",
                    }}
                  >
                    <p
                      class="barcode-font"
                      style={{
                        fontSize: "20px",
                        paddingLeft: "5px",
                      }}
                    >
                      {inputBarcode}
                    </p>

                    <button
                      class="border-none bg-none bluec viewbtn view"
                      style={{
                        float: "right",
                        marginTop: "-35px",
                      }}
                      onClick={() => {
                        onView(inputBarcode);
                      }}
                    >
                      <FontAwesomeIcon icon={faEye} className="view" /> View{" "}
                    </button>
                  </div>
                </div>
              );
            }
          })
          .slice(indexOfFirstItem, indexOfLastItem)}
      </>
    );
  };
  const handleClick = (event) => {
    setCurrentPage(Number(event.target.id));
  };

  const handlePrevBtn = () => {
    setCurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };
  const handleNextBtn = () => {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };
  const Addcublubllist=()=>{
    let lis=[];
    for(let i=0;i<checked.length;i++)
    if(checked[i])
    lis.push(props.cublubllist[i].CUBL_ID?props.cublubllist[i].CUBL_ID:props.cublubllist[i].UBL_ID);
    setItem(lis);
    setCurrentPage(1);
  }

  return (
    <>
      <div class="col-md-10">
        <div class="bggray card-sect2_cr">
          <div class="row">
            <div class={isMobile ? "col-md-8" : "col-md-8 flex"}>
              <p class="pl24 pt10 fw6 fs12 label" style={{ width: "25%" }}>
                {!user.tempCreateShipmentToggle?"Scan or Enter CBL/UBL:":"Scan or Enter CBL:"}
              </p>
              <div class="form-group">
                <div class="input-group">
              
                  <input
                    type="text"
                    id="search"
                  
                    class="form-control ctfinput"
                    autocomplete="off"
                    name="search"
                    value={barcode}
                    maxLength={isMobile?"":10}
                    onChange={validatebarcode}
                    onFocus={(e)=>setfocused(true)}
                    onBlur={(e)=>setfocused(false)}
                    onKeyPress={(e)=>{if(e.key === 'Enter') {
                      setfocused(false);
                    }}}
                    
                    ref={inputFocus}
                
                  />
              
                  <div class="input-group-append rtn-clear-input">
                    <button
                      type="submit"
                      id="clrbutton"
                      class="empt_button"
                      onClick={onclearbar}
                    >
                      <img class="" src="./images/clear-icon.svg" alt="" />
                    </button>
                    {/* <button  class="btn  btn-outline-secondary empt_button"></button> */}
                    <button class="scan_button"></button>
                    <button class="search_button-1 fs12"  id="CBLdropdown" 
                     data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Select CBL</button>
                     <div class="dropdown-menu dropdown-menu-right w-100 pt-3 pl-0 pr-0 " id="CBLdropdown_menu"
                     style={{borderRadius:"10px",zIndex:"1"}} onClick={(e)=> e.stopPropagation()}>
                       
                       <div className="cblubllist w-100 p-2 overflow-auto">
                       {props.cublubllist.filter((a,index)=>!items.includes(a.CUBL_ID?a.CUBL_ID:a.UBL_ID)).length>0?
                       <div className="p-2 pl-3">
                         <input type="checkbox"
                         className="mr-3"
                         checked={selectall}
                         onClick={(e)=>{
                           if(selectall)
                           {
                             setselectall(false);
                             let temp_arr=[]
                            for(let i=0;i<props.cublubllist.length;i++)
                            {
                              if(items.includes(props.cublubllist[i].CUBL_ID?
                                props.cublubllist[i].CUBL_ID:props.cublubllist[i].UBL_ID))
                                temp_arr.push(true);
                                else
                                temp_arr.push(false);
                            }
                            console.log(temp_arr);
                            setchecked(temp_arr);                            
                           }
                           else
                           {
                             setselectall(true);
                             setchecked(new Array(props.cublubllist.length).fill(true)) 

                           }
                         }}
                         
                         />Select All
                       </div>: <div>
                  <div className="text-center"><img src="./images/Item-not-found.svg" alt="" /></div>
                  <p className="no-item-found">No Items found</p>
                </div>}
                       {props.cublubllist.map((a,index)=>{
                         return( <div className="p-2 pl-3" 
                         hidden={items.includes(a.CUBL_ID?a.CUBL_ID:a.UBL_ID)}>
                         <input type="checkbox"className="mr-3"
                         checked={checked[index]}
                         onClick={()=>{
                           var temp=checked;
                           temp[index]=!temp[index];
                           console.log(temp);
                           if(temp[index]==false)
                           setselectall(false);
                           else
                           if(temp.length==temp.filter(a=>a==true).length)
                           setselectall(true);
                           setchecked(temp);
                           settoggle(toggle=>!toggle);
                         }}
 

                         />{a.CUBL_ID?a.CUBL_ID:a.UBL_ID}
                       </div>)
                       })}
                       </div>
                      <div className="add-buttons w-100 mt-1 mb-3 p-3">
                        <div className="float-right pb-1">
                        <button className="cancel-btn fs12"
                        onClick={()=>{
                        
                        if(props.cublubllist.filter((a,index)=>!items.includes(a.CUBL_ID?a.CUBL_ID:a.UBL_ID)).length>0)
                        {setselectall(false);
                          let temp_arr=[]
                         for(let i=0;i<props.cublubllist.length;i++)
                         {
                           if(items.includes(props.cublubllist[i].CUBL_ID?
                             props.cublubllist[i].CUBL_ID:props.cublubllist[i].UBL_ID))
                             temp_arr.push(true);
                             else
                             temp_arr.push(false);
                         }
                         setchecked(temp_arr);  
                        }
                        else
                       { $("#CBLdropdown_menu").removeClass("show");
                       document.getElementById("CBLdropdown").setAttribute("aria-expanded",false);
                      }
                            
                          
                        }}
                        >Cancel</button>
                      <button className="add-carrier-btn fs12" onClick={()=>{Addcublubllist();
                      $("#CBLdropdown_menu").removeClass("show");
                      document.getElementById("CBLdropdown").setAttribute("aria-expanded",false);
                    }}>Add</button>
                      </div>
                      </div>
                     

                     </div>

                  </div>
                </div>
                <div
                  className="barcode-validation-failed  cre_val"
                  hidden={(barcodeValid || focused) && barcode.length<=10}
                >
                  <img src="./images/error-icon.svg" alt="" />
                  {input_alert}
                </div>
              </div>
            </div>

            <div class="col-md-4 add mt-0">
              <input
                type="button"
                id="Add-button"
                value="Add"
                class="addbtnc"
                disabled={!barcodeValid || barcode.length == 0}
                onClick={addCUBL}
              />
              {props.manifestno ? (
                <span class="mftText">
                  Manifest No:{" "}
                  <span class="fw6 fs12 manifest">{props.manifestno}</span>
                </span>
              ) : null}
            </div>
          </div>
        </div>
        <div class="card2 firstScreen show " id="shipment_card">
          <h6 class="cbl-text f14 fw5">
            {items.length > 0 ? <>
            {!user.tempCreateShipmentToggle?"Selected CBL/UBL":"Selected CBL"}
            </> : null}
          </h6>
          <div class="cbl-container">
            <div class={isMobile ? "card-columns row" : "card-columns"}>
              {items.length > 0 ? (
                renderData(items)
              ) : (
                <div
                  className="no-items-found-shipment"
                  style={{ top: "40%", left: "40%" }}
                >
                  <img src="./images/Item-not-found.svg" alt="" />
                  <p className="no-item-found">No Items found</p>
                </div>
              )}
            </div>
            {items.length > 0 ? (
              <section className="paginat">
                <Pagination
                  className="pagination-bar"
                  data={items}
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  pageNumberLimit={pageNumberLimit}
                  maxPageNumberLimit={maxPageNumberLimit}
                  minPageNumberLimit={minPageNumberLimit}
                  handleClick={handleClick}
                  handlePrevBtn={handlePrevBtn}
                  handleNextBtn={handleNextBtn}
                />
              </section>
            ) : null}
          </div>
        </div>
      </div>
      <div class="container-fluid section-four">
        <div class="float-right">
          <input
            type="button"
            value="Cancel"
            class="cancel-btn fs12"
            onClick={onCancel}
          />
          <input
            type="button"
            value="Add Carrier"
            class="add-carrier-btn next-button fs12"
            disabled={!items.length}
            onClick={handleaddcarrier}
          />
        </div>
      </div>
      <div
        className="modal fade loginModal"
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-z"
          role="document"
        >
          <div className="modal-content modal-radius">
            <div className="modal-header confirmation-header">
              <h5
                className="modal-title confirmation-style"
                id="errorModalCenterTitle"
              >
                Confirmation
              </h5>
            </div>
            <div className="modal-body text-align-centre">
              <div>
                Would you like to remove this{" "}
                <b>
                  {itemtype ? "UBL" : "CBL"}# {items[parseInt(deleteindex)]}
                </b>{" "}
                ?
              </div>
            </div>
            <div className="modal-footer bo-modalbtnftr bt-none">
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-no"
              >
                No
              </button>
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-yes"
                onClick={deleteCUBL}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade loginModal"
        id="exampleModalCenter1"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-z"
          role="document"
        >
          <div className="modal-content modal-radius">
          <div className={msgheader!="Error"?"modal-header confirmation-header"
            :"modal-header error-popup-header-bg"}>
              <h5
                className="modal-title confirmation-style"
                id="errorModalCenterTitle"
              >
                {msgheader}
              </h5>
            </div>
            <div className="modal-body text-align-centre">
              <div>{msg} </div>
            </div>
            <div className="modal-footer bo-modalbtnftr bt-none">
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-yes"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal summary-modal right fade"
        id="myModal2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel2"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="myModalLabel2">
                View Summary
              </h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span
                  aria-hidden="true"
                  class="whitetext"
                  onClick={() => {
                    $("#myModal2").modal();
                  }}
                >
                  &times;
                </span>
              </button>
            </div>

            <div class="modal-body">
              <p class="barcode-font" style={{ fontSize: "30px" }}>
                {itemdetail[0].cublId != "NULL" && itemdetail[0].cublId
                  ? itemdetail[0].cublId
                  : itemdetail[0].ublId}
              </p>
              <h6 class="card-title fs12" style={{ fontWeight: "bolder" }}>
                {props.Address.DC_NAME}{" "}
              </h6>
              <p class=" fs12  pb-1 mb-1">
                {props.Address.DC}-{props.Address.ADDRESS_LN1},
              </p>
              <p class=" fs12  pb-1 mb-1">
                {" "}
                {props.Address.ADDRESS_LN2}
                {props.Address.ADDRESS_LN3 &&
                props.Address.ADDRESS_LN3 != "NULL"
                  ? "," + props.Address.ADDRESS_LN3
                  : null}
                {props.Address.POST_CD}{" "}
              </p>
              <table
                class="table cttable"
                style={{
                  textAlign: "left",
                }}
              >
                <thead
                  class="bg-grey"
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  <th class="fw5 fs12">Supplier</th>
                  <th class="fw5 fs12">Item#</th>
                  <th class="fw5 fs12">Customer Order#</th>
                  <th class="fw5 fs12">Return Order#</th>
                </thead>
                <tbody>
                  {itemdetail.map((item, index) => {
                    console.log(item);
                    return (
                      <tr>
                        <td>{item.supplierNo?(item.supplierNo):null} {item.supplierName}</td>
                        <td>{item.itemId}</td>
                        <td>{item.saleOrderNo}</td>
                        <td>{item.returnOrderNo}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {loader ? <LoadingIndicator /> : null}
    </>
  );
};
export default CreateManifest;
