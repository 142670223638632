import React,{useState,useEffect} from "react";
import SearchManifest from './SearchManifest';
import scanner from '../../../assets/images/barcodescanner.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { callLogoutURL } from "../../Authentication/utilities";
import Sidebar from "components/common/Sidebar";
import ManifestReport from "../newShipment/ManifestReport";
import { faArrowLeft, faEdit, faSearch, faPrint, faEye, faTrashAlt, faWindowClose, faSync, faPencilAlt,faDownload } from '@fortawesome/free-solid-svg-icons';
import CreateShipment from "services/CreateShipmentService";
import LoadingIndicator from "components/common/LoadingIndicator";
import { useIdleTimer } from 'react-idle-timer'
import Header from "components/common/Header";
import { Toast } from "react-bootstrap";
import { Link } from "react-router-dom";


const ModifyShipment=(props)=>{
  
  const user = JSON.parse(localStorage.getItem("User"));
    const createShipment=new CreateShipment();
    const[props1,setprops1]=useState(user);
    const [pageno,setpageno]=useState(1);
    const [carrierName,setcarrierName]=useState("");
    const [manifestno,setmanifestno]=useState("");
    const [shipmenttime,setshipmenttime]=useState("");
    const [Address,setAddress]=useState({});
    const [manifestdetails,setmanifestdetails]=useState([]);
    const [loader,setloader]=useState(false);
    const [itemTaggingToast, setItemTaggingToast] = useState(false);
    const [toastmsg, settoastmsg] = useState(" ");
    const [timeoutDuration] = useState(localStorage.getItem('timeout') ? localStorage.getItem('timeout') : 1800000);
    const [state1, setState1] = useState({
      token: user.token,
      userName: user.userName,
      storeNo: user.storeNo,
      role: user.role,
      storeName: user.storeName,
      displayName: user.displayName,
      menuAllowed: user.menuAllowed,
      tempSearchMenu: user.tempSearchMenu,
      tempCreateDispatchInStoreMenu:
        user.tempCreateDispatchInStoreMenu,
      tempCreateConsolidatedDispatchMenu:
        user.tempCreateConsolidatedDispatchMenu,
      tempCreateShipment: user.tempCreateShipment,
      loggedInTime: user.loggedInTime,
    });
    let dateObj = new Date();
    var hours = dateObj.getHours();
    var minutes = dateObj.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    var stime =  (dateObj.getDate()) + '/'+ [dateObj.getMonth()+1]+"/"+ dateObj.getFullYear() + ' | ' + strTime;
    
    const handleback_report=()=>{
      setpageno(1);
      setcarrierName("");
    }
    const setSecondPage=()=>{
      setpageno(2);
    }
    const handlefirstPage=(manifestno,carriername,address)=>{
      setloader(true);
      createShipment.ViewManifest(props1.storeNo,manifestno,props1.displayName,props1.token).then(
        response=>{
          
          if(response.printManifest.results)
          {
            setmanifestdetails(response.printManifest.results);
            console.log(response.printManifest.results);
            setmanifestno(manifestno);
            setcarrierName(carriername);
            setAddress(address);
            setloader(false);
            setpageno(3);
        }
       

        }
      ).catch(err=>{
        console.log(err);
        setloader(false);
      
      });


    }
    const logout=()=>{
      callLogoutURL();
    }
    const navigateToLandingPage=()=> {
     props.history.push("/LandingPage");
     
    }
    const handleOnIdle = event => {
      console.log('user is idle', event)
      console.log('last active', getLastActiveTime())
      callLogoutURL()
    }
  
    const handleOnActive = event => {
      console.log('user is active', event)
      console.log('time remaining', getRemainingTime())
      handleReset()
      if(localStorage.getItem('access_token') == "null"){
        callLogoutURL()
        return
      }
    }
  
    const handleOnAction = event => {
      console.log('user did something', event)
      if(localStorage.getItem('access_token') == "null"){
        callLogoutURL()
        return
      }
    }
    const { getRemainingTime, getLastActiveTime, reset } = useIdleTimer({
      timeout: timeoutDuration,
      onIdle: handleOnIdle,
      onActive: handleOnActive,
      onAction: handleOnAction,
      debounce: 500
    })
    const handleReset = () => reset()

    if (typeof window != 'undefined') {
      console.log("listener", localStorage.getItem("access_token"));
      window.addEventListener("storage", function (e) {
        if (localStorage.getItem('access_token') == "null") {
          callLogoutURL()
          return
        }
      })
    }
  
    useEffect(() => {
      if (localStorage.getItem('access_token') == "null") {
        callLogoutURL()
        return
      }
      window.addEventListener("scroll", handleOnActive);
      return () => window.removeEventListener("scroll", handleOnAction);
    }, []);

    return (
        <div className="OrderLookup bg-grey" id="shipment_component">         
         <Header/>
          <div className="container-fluid">
                <div className="row">
                  <Sidebar active="ModifyShipment" state={user} />
                  <main
                    role="main"
                    className="col-md-9 ml-sm-auto col-lg-10 rtn-main-wrapper"
                  >
                    {/* page title */}
                    <section className="rtn-page-title mb-2">
              <div className="d-flex row bd-highlight ">
                <div className="bd-highlight col-xs-12 col-md-6 modal-dialog-centered">
                          <h4>Modify Shipment</h4>
                        </div>
        
                <div className=" col-xs-12 col-md-6 bd-highlight home-bc-padding">
                          {/* {<Breadcrumb crumbs={crumbs} selected={selected} />} */}
                          <nav aria-label="breadcrumb" style={{
                            marginRight:"15px"
                          }}>
                            <ol className="breadcrumb">
                            <Link
                        to={{ pathname: "/LandingPage", state: user }}
                        className="breadcrumb-item breadcrumb-color pr-10"
                      >
                        Home
                      </Link>
                              <span className="pr-10"> {" >"}</span>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                               Shipment
                              </li>
                            </ol>
                          </nav>
                        </div>
                      </div>
                    </section>

                    <div class="container-fluid section-three" >
                         <div class="row mrt14">
                         <div class="col-md-2">
                                        <div class="card1" id="sideprogress">
                                            <ul id="progressbar" class="text-center">
                                                <li class="active step0 step1"></li>
                                                <li class={pageno>1?"step0 step2 active":"step0 step2"}></li>
                                                <li class={pageno>2?"step0 step3 active":"step0 step3"}></li>
                                            </ul>
                                            <div class="mb21">
                                                <div class="row">
                                                    <div class="col-md-1  progressIcon">
                                                     <FontAwesomeIcon icon={faSearch} />
                                                    </div>
                                                    <div class="col icon_div">
                                                        <h6 class="fs12 zero"> Search </h6>
                                                        <h6 class="fs10 gray">Scan/Enter CBL/UBL</h6>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mb21">
                                                <div class="row">
                                                    <div class="col-md-1  progressIcon">
                                                    <img
                                                      src="./images/create-icon.svg"
                                                      style={{height:"65%",marginTop:"-2px"}}
                                                      alt=""   
                                                    />
                         
                                                    </div>
                                                    <div class="col icon_div">
                                                        <h6 class="fs12 zero">Modify </h6>
                                                        <h6 class="fs10 gray">Select Destination</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mb21">
                                                <div class="row">
                                                    <div class="col-md-1  progressIcon">
                                                    <img
                                                      src="./images/view-summary-icon.svg"
                                                      style={{height:"65%",marginTop:"-2px"}}
                                                      alt=""   
                                                    />
                         
                                                    </div>
                                                    <div class="col icon_div">
                                                        <h6 class="fs12 zero">Print Manifest </h6>
                                                        <h6 class="fs10 gray">Item Details</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        {
                          pageno<3?<SearchManifest storeNo={props1.storeNo} displayName={props1.displayName}
                          token={props1.token} setSecondPage={setSecondPage} handlepage={handlefirstPage}  
                          NavigatetoLanding={navigateToLandingPage}
                          toast={()=>{
                            settoastmsg("Modified successfully", setItemTaggingToast(true));
                          }}
                          stepage1={
                            ()=>setpageno(1)
                          }/>:
                          <ManifestReport carrier={carrierName} manifestno={manifestno} Address={Address} 
                          manifestdetails={manifestdetails}  shipementtime={shipmenttime} storeNo={props1.storeNo}
                          token={props1.token} displayName={props1.displayName} shipementtime={stime}
                          handleback_report={handleback_report} handlefourthpage={handleback_report}
                          NavigatetoLanding={navigateToLandingPage}
                          />
                        
                        }
                      </div>
                    </div>

                    </main>
                </div>
             </div>
            
             <div className="topCenter" hidden={!itemTaggingToast}>
        <Toast  id="toast" show={itemTaggingToast} onClose={() => setItemTaggingToast(false)} delay={5000} autohide>
          <div className="toast__content">
            <div>
              <span class="vertical-toast-border"></span>
              <div className="tickmark" style={{ height: "100%" }}>
                <img className="tickmark" src="./images/tick-circle-icon.svg" alt="" />
              </div>
              <div className="pt-10" >
                <p className="toast__type">Success</p>
                <p className="toast__message">{toastmsg}</p>
              </div>
              <div>
                <button
                  type="button"
                  className="close toast-close"
                  data-dismiss="toast"
                  aria-label="Close"
                  onClick={() => setItemTaggingToast(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>
        </Toast>
      </div>
      {loader?<LoadingIndicator/>:null}
        </div>);

}

export default ModifyShipment;