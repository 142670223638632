
import React ,{useState,useEffect,useRef} from "react";
import Sidebar from "../../components/common/Sidebar";
import LoadingIndicator from "../../components/common/LoadingIndicator";
import { useIdleTimer } from 'react-idle-timer'
import Header from "components/common/Header";
import { Link } from "react-router-dom";
import { ExportXLSX } from "components/common/xlsReports/ExportXLSX";
import Pagination from "components/common/Pagination";
import { callLogoutURL } from "../Authentication/utilities";
import scanner from "../../assets/images/barcode-thin.svg";
import {MultiSelect} from "react-multi-select-component";
import ViewManifestDC from "services/viewManifestDC";
import Toast from 'react-bootstrap/Toast';
import $ from "jquery";
const DCviewManifest = (props) => {
    const [manifestselectedvalue, setmanifestselectedvalue] = useState([]);
    const [dropdownselected,setdropdownselected]=useState("Open Manifest");
    const focusref=useRef();

    
  const user = JSON.parse(localStorage.getItem("User"));
  const [manifestlist, setmanifestlist] = useState([]);
  const [showloader, setshowloader] = useState(false);
  const [timeoutDuration] = useState(localStorage.getItem('timeout') ? localStorage.getItem('timeout') : 1800000);
  const [SearchTerm, setSearchTerm] = useState("");
  const [row_1, setrow] = useState([]);

  const [datas,setdatas]=useState([]);
  const [sortToggle, setSortToggle] = useState(true);
  const [msg, setmsg] = useState("");
  const [msgheader, setmsgheader] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [carrierRef,setcarrierRef]=useState("");
  const [togglescreen,settogglescreen]=useState(false);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  let xlxData = [];
  let renderXLXData = <div></div>;

  const [itemTaggingToast, setItemTaggingToast] = useState(false);
  const [toastmsg, settoastmsg] = useState("UBL Headers created Successfully");
 
  useEffect(()=>{

    let temp=localStorage.getItem("DespatchId-viewManifestscreen");
    if(temp!=null)
    {
      getManifestDetails(temp.substring(0,temp.length-2));
      localStorage.removeItem("DespatchId-viewManifestscreen");
    }
    else
    handleDropDownSetting("Open Manifest")

  },[]);

  const reset_1=()=>{
  

    setdropdownselected("Open Manifest");
    setmanifestselectedvalue([]);
    setmanifestlist([])
    setSearchTerm("")
    handleDropDownSetting("Open Manifest")
    setrow([])
    setdatas([])
  setcarrierRef("")
  setCurrentPage(1);
  settogglescreen(false)
  }
  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 700;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  const handleDropDownSetting=(select)=>{
  

    setdropdownselected(select);
    setmanifestselectedvalue([]);
    setshowloader(true);
    ViewManifestDC.getManifestlist(user.storeNo,select=="Open Manifest"?'O':select=="Closed Manifest"?'C':'all',user.token).then((Response)=>{
      setshowloader(false);
     var list= Response.ManifestDetails.details;
     var arr=[];
     for(let i=0;i<list.length;i++) 
     {
       arr.push({label:list[i].manifestId+" - "+list[i].supplierNo+" "+list[i].supplierName,value:list[i].manifestId,status:list[i].manifestStatus,supplierNo:list[i].supplierNo});
     }

     const set = new Set(arr.map(item => JSON.stringify(item)));
     const dedup = [...set].map(item => JSON.parse(item));
     setmanifestlist(dedup);
     //$(".dropdown-heading").click();

    }).catch((err)=>console.log(err));
  
  }
 const getManifestDetails=(man)=>{
  setshowloader(true);
  ViewManifestDC.getManifestDetails(user.storeNo,man,carrierRef?carrierRef:null,user.token).then((Response)=>{
    setshowloader(false);
    if(Response.viewManifest&&Response.viewManifest.results&&Response.viewManifest.results.length>0)
    {
    setrow(Response.viewManifest.results);
    setdatas(Response.viewManifest.results);

    settogglescreen(true);
    }
    else
    {
      setmsgheader("Error");
      setmsg("Manifest Details Not Found");
      $("#exampleModalCenter1").modal();
    }

  }).catch((err)=>console.log(err));
 }

 const closeManifest=()=>{
   setshowloader(true);
   ViewManifestDC.updateManifest(user.storeNo,datas.filter((a)=>a.manifestId!=null)[0].manifestId,user.token,datas.filter((a)=>a.manifestId!=null)[0].toLocation.split(",")[0]).then((Response)=>{
    setshowloader(false);
     if(Response.despatchSupplier.message=="Supplier Details Updated Successfully")
     {
      props.history.push("/LandingPage");

     }
     else if(Response.despatchSupplier.message=="Supplier No exist with Ship Status as OPEN")
     {
      $("#exampleModalCenter_cantclose").modal();

     }





   }).catch(err=>{console.log(err);
  setshowloader(false) 
  })
   
 }



  if (typeof window != 'undefined') {
    console.log("listener", localStorage.getItem("access_token"));
    window.addEventListener("storage", function (e) {
      if (localStorage.getItem('access_token') == "null") {
        callLogoutURL()
        return
      }
    })
  }

  if (datas) {
    // consider variable data if we want to export excel with the details that is present only in the
    renderXLXData = datas.map((item, index) => {
      xlxData.push({
        "Return Location": `${item.returnLocation ? item.returnLocation : "-"}`,
        "RO#": `${item.returnOrderNo ? item.returnOrderNo : "-"}`,
        "CO#": `${item.salesOrderNo ? item.salesOrderNo : "-"}`,
        "Item#": `${item.itemId ? item.itemId : "-"}`,
        Qty: `${item.qty ? item.qty : 1}`,
        Brand: `${item.brand ? item.brand : "-"}`,
        "Con#": `${item.conNo ? item.conNo: "-"}`,
        "Carrier Ref#": `${item.carrierRef ? item.carrierRef : "-"}`,
        "Status": `${item.ublStatus=="RD"?"Ready to Despatch":item.ublStatus=="SD"?"Not Despatch":"Despatch"}`,
        "Supplier PO#": `${item.supplierPo ? item.supplierPo : "-"}`,
        "Manifest#":`${datas.length>0&& datas.filter((a)=>a.manifestId!=null).length>0?(datas.filter((a)=>a.manifestId!=null)[0].manifestId):
          "-"}`
      });
      var price = 0;
      return (
        <tr>
          <td>{item.returnLocation ? item.returnLocation : "-"}</td>
          <td>{item.returnOrderNo ? item.returnOrderNo : "-"}</td>
          <td>{item.salesOrderNo ? item.salesOrderNo : "-"}</td>
          <td>{item.itemId ? item.itemId : "-"}</td>
          <td>{item.qty ? item.qty : 1}</td>
          <td>{item.brand ? item.brand : "-"}</td>
          <td>{item.conNo ? item.conNo : "-"}</td>
          <td>{item.carrierRef ? item.carrierRef : "-"}</td>
          <td>{item.ublStatus=="RD"?"Ready to Despatch":item.ublStatus=="SD"?"Not Despatch":"Despatch"}</td>
          <td>{item.supplierPo ? item.supplierPo : "-"}</td>
          <td>{datas.length>0&& datas.filter((a)=>a.manifestId!=null).length>0?(datas.filter((a)=>a.manifestId!=null)[0].manifestId):
          "-"}</td>
    
        </tr>
      );
    });
    if (xlxData.length === 0) {
      xlxData.push({
        "Return Location": ``,
        "RO#": ``,
        "CO#": ``,
        "Item#": ``,
        Qty: ``,
        Brand: ``,
        "Con#": ``,
        "Carrier Ref#": ``,
        "Status": ``,
        "Supplier PO#": ``,
        "Manifest#":``
      });
    }
  }



  const onSort = () => {
    let sortData = row_1;
    console.log("handleSort2", sortToggle);
    if (!sortToggle) {
      sortData.sort((a, b) => {
        return b.supplierPo - a.supplierPo;
      });
    } else {
      sortData.sort((a, b) => {
        return a.supplierPo - b.supplierPo;
      });
    }
    console.log(sortData);
    setrow(sortData);
    setSortToggle(!sortToggle);
  };

  const handleClick = (event) => {
    setCurrentPage(Number(event.target.id));
  };

  const handlePrevBtn = () => {
    setCurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };
  const handleNextBtn = () => {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const renderdata = (rows_temp) => {
    return (
      <>
        {rows_temp
          .map((item, index) => {
            return (
              <tr>
                <td class="align-middle" >
                  {item.returnLocation? item.returnLocation:null}
                </td>
                <td class="align-middle" >
                  {item.returnOrderNo ? item.returnOrderNo : "-"}
                </td>
                <td class="align-middle" >
                  {item.salesOrderNo ? item.salesOrderNo : "-"}
                </td>
                <td class="align-middle" >
                  {item.itemId ? item.itemId : "-"}
                </td>
                <td class="align-middle" >
                  {item.qty ? item.qty : 1}
                </td>
                <td class="align-middle" >
                  {item.brand ? item.brand : "-"}
                </td>
                <td class="align-middle" >
                  {item.conNo ? item.conNo : "-"}
                </td>
                <td class="align-middle" >
                  {item.carrierRef ? item.carrierRef : "-"}
                </td>
                <td class="align-middle" >
                  {item.ublStatus=="RD"?"Ready to Despatch":item.ublStatus=="SD"?"Not Despatch":"Despatch"}
                </td>
                <td class="align-middle" >
                  {item.supplierPo ? item.supplierPo : "-"}
                </td>
              </tr>
            );
          })
          .slice(indexOfFirstItem, indexOfLastItem)}
      </>
    );
  };
  const convertedBlobData = (base64) => {
    if (
      typeof window.AndroidInterface !== "undefined" &&
      window.AndroidInterface !== null
    ) {
      window.AndroidInterface.convertedBlobData(base64);
    }
  };
  const handleOnChange = (e) => {
    setSearchTerm(e.target.value);
    let value = e.target.value.toLowerCase();
    if (value.length > 2) {
      let searchrow = datas.filter((d) => {
        let tempdata = JSON.stringify(d);
        return tempdata.toLowerCase().includes(value);
      });
      setrow(searchrow);
      if (searchrow.length > 0) setCurrentPage(1);
    } else {
      setrow(datas);
      setCurrentPage(1);
    }
  };
  const handleprint = () => {
    setshowloader(true);
    ViewManifestDC.getManifestDetails(
      user.storeNo,
      datas.filter((a)=>a.manifestId!=null).length?datas.filter((a)=>a.manifestId!=null)[0].manifestId:null,
      carrierRef?carrierRef:null,
      user.token,
        "Y",
        "N"
      )
      .then((response) => {
        setshowloader(false);
        if(response && response.viewManifest && response.viewManifest.message=="Success! Manifest data sent for printing")
        $("#exampleModalCenter2").modal();
       else
        $("#exampleModalCenter23").modal();

      })
      .catch((err) => console.log(err));
  };
  const blobToSaveAs = (fileName, blob) => {




    try {
      const arr = new Uint8Array(blob);
      var reader = new FileReader();
      reader.readAsDataURL(new Blob([arr])); 
      reader.onloadend = function() {
          var base64data = reader.result; 
          convertedBlobData(base64data.toString());               
          console.log(base64data);
          return;
      }


      const url = window.URL.createObjectURL(
        new Blob([arr], { type: "application/pdf" })
      );
      const link = document.createElement("a");

    


      if (link.download !== undefined) {
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (e) {
      console.error("BlobToSaveAs error", e);
    }
  };

  const download=()=>{
    setshowloader(true);
    ViewManifestDC.getManifestDetails(
      user.storeNo,
      datas.filter((a)=>a.manifestId!=null).length?datas.filter((a)=>a.manifestId!=null)[0].manifestId:null,
      carrierRef?carrierRef:null,
      user.token,
        "N",
        "Y"
      )
      .then((response) => {
        setshowloader(false);
        blobToSaveAs(
          "ManifestReport",
          response.viewManifest.binaryReportData.data
        );
        
      })
      .catch((err) => {
        console.log(err);
        setshowloader(false);
      });

  }


  useEffect(() => {
    if (localStorage.getItem('access_token') == "null") {
      callLogoutURL()
      return
    }
    window.addEventListener("scroll", handleOnActive);
    return () => window.removeEventListener("scroll", handleOnAction);
  }, []);


 
  const handleOnIdle = event => {
    console.log('user is idle', event)
    console.log('last active', getLastActiveTime())
    callLogoutURL()
  }

  const handleOnActive = event => {
    console.log('user is active', event)
    console.log('time remaining', getRemainingTime())
    handleReset()
    if(localStorage.getItem('access_token') == "null"){
      callLogoutURL()
      return
    }
  }

  const handleOnAction = event => {
    console.log('user did something', event)
    if(localStorage.getItem('access_token') == "null"){
      callLogoutURL()
      return
    }
  }
  const { getRemainingTime, getLastActiveTime, reset } = useIdleTimer({
    timeout: timeoutDuration,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
  })
  const handleReset = () => reset()

  return (
    <div className="OrderLookup bg-grey" id="shipment_component">
      <Header />
      <div className="container-fluid" id="Despatch_Component">
        <div className="row">
          <Sidebar active="ViewDcDespatchManifest" state={user} />
          <main
            role="main"
            className={isMobile?"col-md-9 ml-sm-auto col-lg-10 rtn-main-wrapper p-0 bg-white"
            :"col-md-9 ml-sm-auto col-lg-10 rtn-main-wrapper"}
          >
            {/* page title */}
            {isMobile?
                        <section className="dcdespatch-header">
                        <p>DC Despatch - View Manifest</p>
                      </section>
            :<section className="rtn-page-title mb-2">
              <div className="d-flex row bd-highlight ">
                <div className="bd-highlight col-xs-12 col-md-6 modal-dialog-centered">
                  <h4>DC Despatch</h4>
                </div>

                <div className=" col-xs-12 col-md-6 bd-highlight home-bc-padding">
                  {/* {<Breadcrumb crumbs={crumbs} selected={selected} />} */}
                  <nav aria-label="breadcrumb"style={{
                    marginRight:"15px"
                  }}>
                    <ol className="breadcrumb">
                    <Link
                        to={{ pathname: "/LandingPage", state: user }}
                        className="breadcrumb-item breadcrumb-color pr-10"
                      >
                        Home
                      </Link>
                              <span className="pr-10"> {" >"}</span>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                               DC Despatch
                              </li>
                            </ol>
                  </nav>
                </div>
              </div>
            </section>}



            <div class="container-fluid section-three">
              <div class="row mrt14">
                <div class="col-md-2" id="sideprogress">
                  <div class="card1">
                    <ul id="progressbar" class="text-center ">
                      <li class="active step0 step1"></li>
                      <li class={togglescreen? "step0 step3 active removeafter" : "step0 step3 removeafter"}></li>
                    </ul>
                    <div class="mb21">
                      <div class="row">
                        <div class="col-md-1 progressIcon">
                        <img
                           src="./images/create-icon.svg"
                            style={{height:"65%",marginTop:"-2px"}}
                            alt=""   
                        />
                         
                        </div>
                        <div class="col icon_div" > 
                          <h6 class="fs12 zero"> Search Manifest </h6>
                          <h6 class="fs10 gray">Scan/Enter Manifest</h6>

                        </div>
                      </div>
                    </div>
                   
                    <div class="mb21">
                      <div class="row">
                        <div class="col-md-1 progressIcon">
                        <img
                           src="./images/view-summary-icon.svg"
                          style={{height:"65%",marginTop:"-2px"}}
                           alt=""   
                        />
                         
                        </div>
                        <div class="col icon_div">
                          <h6 class="fs12 zero">Print Manifest </h6>
                          <h6 class="fs10 gray">Item Details</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {togglescreen?
                <>
                <div class="col-md-10">
                <section className="manifest-number">
                <p>Manifest</p>
                <p>{datas.length>0?
                ( (datas.filter((a)=>a.manifestId!=null).length>0?(datas.filter((a)=>a.manifestId!=null)[0].manifestId+" - "):"")+
                datas[0].toLocation.split(",")[0]+" "+datas[0].toLocation.split(",")[1])
                :null}</p>
              </section>
        <div class="card2  fourthScreen show" id="manifestReport">
          <div class="card-section border_radius">
            <div class="manifest-header">
              <img className="logo-1" src="./images/Logo/logo-blk.png" alt="" />
              <h6 class="manifest_header d-none d-sm-block ">Manifest</h6>
              {datas.length>0 && datas.filter((a)=>a.manifestId!=null).length>0?
              <>
              <div class="manifest_label ">Manifest# :</div>
              <div class="manifest_barcode">
                <p class="manifest_no_display">{datas.length>0?(datas.filter((a)=>a.manifestId!=null)[0].manifestId):null}</p>
                <img src="./images/barcode.png" className="barcode_img" style={{marginRight:"1px"}} />
              </div>
              </>
              :null}
            </div>
            <div class="manifest-container">
              <div class="row">
                <div class="col-md-5">
                  <div class="row">
                    <div class={isMobile ? "col-5" : "col-md-4"}>
                      <h6 class="fs12 ">DC Location:</h6>
                    </div>
                    <div class={isMobile ? "col-7 mb-2" : "col-md-6 "}>
                      <span>{row_1.length?row_1[0].dcLocation:null}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="row pr-2">
                    <div class={isMobile ? "col-5" : "col-md-3 "}>
                      <h6
                        class={
                          isMobile
                            ? "fs12 "
                            : "flr fs12 "
                        }
                      >
                        To Location:
                      </h6>
                    </div>
                    <div
                      class={isMobile ? "col-7 mb-2" : "col-md-9 pr-0"}

                    >
                      {row_1.length>0?
                      <span class="fs12">{
                     
                      row_1.length>0&&row_1[0].toLocation?
                      (row_1[0].toLocation.split(",")[0]+" "+row_1[0].toLocation.split(",")[1]):null}
                       <br/>
                      {row_1.length>0&&row_1[0].toLocation?
                      (row_1[0].toLocation.split(",")[2]&&
                      row_1[0].toLocation.split(",")[2]!="null"?(row_1[0].toLocation.split(",")[2]):""):null}
                     
                      {row_1.length>0&&row_1[0].toLocation?
                      (row_1[0].toLocation.split(",")[3]&&
                      row_1[0].toLocation.split(",")[3]!="null"?(","+row_1[0].toLocation.split(",")[3]):""):null}
                      {row_1.length>0&&row_1[0].toLocation?
                      (row_1[0].toLocation.split(",")[4]&&
                      row_1[0].toLocation.split(",")[4]!="null"?(","+row_1[0].toLocation.split(",")[4]):"-"):null}
                      {row_1.length>0&&row_1[0].toLocation?
                      (row_1[0].toLocation.split(",")[5]&&
                      row_1[0].toLocation.split(",")[5]!="null"?("-"+row_1[0].toLocation.split(",")[5]):""):null
                     

                      }</span>:
                      <span class="fs12">
                      -  
                      </span>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <div class="row">
                    <div class={isMobile ? "col-5" : "col-md-4"}>
                      <h6 class="fs12">Carrier:</h6>
                    </div>
                    <div class={isMobile ? "col-7" : "col-md-6"}>
                      <span>
                      {row_1.length && 
                      row_1.filter((a)=>a.carrier!=null).length>0?row_1.filter((a)=>a.carrier!=null)[0].carrier:null}
                      </span>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
          <br />
          <div
            className="rtn-card-hdr"
            
          >
            <div className="container">
              <div className="row">
                <div className="col-md-6 card-padding">
                  <form
                    className="rtn-table-search d-md-block d-none  d-lg-block"
                    onSubmit={(e) => e.preventDefault}
                  >
                    <input
                      name="field"
                      autocomplete="off"
                      className="form-control input-search col-md-6"
                      type="text"
                      placeholder="Search"
                      id="SearchTerm"
                      value={SearchTerm}
                      onChange={handleOnChange}
                    />
                  </form>
                </div>
                <div className="col-md-6 card-padding">
                  {!isMobile?<ul className="rtn-card-icons ">
                    <li>
                      <ExportXLSX
                        csvData={xlxData}
                        fileName={"Manifest_Details"}
                      />
                    </li>
                    <li id="Sort">
                      <i className="table-icons" onClick={onSort}>
                        {" "}
                        <img
                          className=""
                          src="./images/table-icons/sorting-icon.svg"
                          alt=""
                        />{" "}
                      </i>
                    </li>
                  </ul>:
                  <ul className="rtn-card-icons ">
                  
                    <li  onClick={handleprint}>
                    {" "}
                        <img
                          className=""
                          src="./images/table-icons/printing-icon.svg"
                          alt=""
                        />{" "}
                    </li>
                   
                  </ul>}
                </div>
              </div>
            </div>
          </div>
          <div className="mdb-datatable dt-bootstrap4">
            <div class="row">
              <div
                class="col-sm-12"
                style={{
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              >
                <div class="dataTables_scroll" style={{ textAlign: "left" }}>
                  <div
                    class="mdb-dataTable_scrollBody"
                    style={{ overflow: "auto" }}
                  >
                    <div class="table-responsive">
                      <table
                        class="table table-bordered table-hover table-sm mdb-dataTable text-nowrap fs12"
                        style={{ textAlign: "left" }}
                      >
                       
                        <thead
                          class="mdb-dataTable-head"
                         
                        >
                          <tr>
                            <th
                              class="align-middle"
                             
                            >
                              Return Location
                            </th>
                            <th
                              class="align-middle"
                             
                            >
                              RO#
                            </th>
                            <th
                              class="align-middle"
                            
                            >
                              CO#
                            </th>
                            <th
                              class="align-middle"
                          
                            >
                              Item#
                            </th>
                            <th
                              class="align-middle"
                            
                            >
                              Qty
                            </th>
                            <th
                              class="align-middle"
                            
                            >
                              Brand
                            </th>
                            <th
                              class="align-middle"
                              
                            >
                             Con#
                            </th>
                            <th
                              class="align-middle"
                            
                            >
                          Carrier Ref#
                            </th>
                            <th
                              class="align-middle"
                             
                            >
                            Status
                            </th>
                            <th
                              class="align-middle"
                            
                            >
                              Supplier PO#
                            </th>
                          </tr>
                        </thead>
                        <tbody data-test="table-body">
                          {row_1.length > 0
                            ? renderdata(row_1)
                            : "No item found"}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom_count" style={{ marginTop: "20px" }}>
            Total Manifest Qty : {row_1.length}
          </div>

          <section className="paginat pag_ship">
            <Pagination
              className="pagination-bar"
              data={row_1}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              pageNumberLimit={pageNumberLimit}
              maxPageNumberLimit={maxPageNumberLimit}
              minPageNumberLimit={minPageNumberLimit}
              handleClick={handleClick}
              handlePrevBtn={handlePrevBtn}
              handleNextBtn={handleNextBtn}
            />
          </section>
        </div>
      </div>
      {!isMobile?<div class="container-fluid section-four">
        <div class="float-right w100">
          <button class="exit-manifest-btn"
         onClick={reset_1}
           >
            Exit
          </button>
          {isMobile ? null : (
            <button class="view-manifest-btn"
            onClick={download}
             >
              <img
                className="pr-10"
                src="./images/table-icons/download-icon.svg"
                alt=""
              />

              {"Download Manifest"}
            </button>
          )}
          <button class="print-manifest-btn"
           onClick={handleprint}
           >
            <img
              className=""
              src="./images/table-icons/printing-icon.svg"
              alt=""
            />{" "}
            {"Print Manifest"}
          </button>
          <input
            type="button"
            value="Close Manifest"
            disabled={datas.length>0?((datas[0].ublStatus!="RD" && datas[0].ublStatus!="SD") || row_1.filter((a)=>a.manifestId!=null).length==0):false}
            data-toggle="modal"
            data-target="#exampleModalCenter123"
            class="complete-shipment-btnDC next-button"
            id="closeShipment"
          />
        </div>
      </div>:
      <div className="button-container  w-100 fs12">
              <button type="button" className="exit-manifest-btn"
              onClick={reset_1}
              >
                Exit
              </button>
              <button
                type="button"
                className="close-manifest-btn"
                onClick={()=>$("#exampleModalCenter123").modal()}
                disabled={datas.length>0?((datas[0].ublStatus!="RD" && datas[0].ublStatus!="SD") || row_1.filter((a)=>a.manifestId!=null).length==0):false}
              >
               
                Close Manifest
              </button>
            </div>
      
          }
      </>:
         <div class="col-md-10" style={{minHeight:"30pc"}}>
      <div className="InputContainer row pl-2 pt-3 pb-3 m-1" id="dcviewmanifest">
          <div className="row col-md-10">
              <div className="col-md-5 mb-4">
              <h6 className="DCinputlabel">Manifest</h6>
              <span  id="ManifestList">
              <section style={{
                      width:"40%",
                      float:"left"
                  }}>
                  <div >
                    <div class="manifest-dropdown">
                      <button
                        className="btn btn-secondary dropdown-search_dc width100"
                        type="button"
                        id="manifestOptions"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{
                          overflow:"hidden",
                          whiteSpace:"nowrap",
                          textOverflow:"ellipsis"
                        }}
                      
                      >
                      {dropdownselected}
                      </button>
                      <div
                        className="dropdown-menu despatch-search-dropdown-align p-0"
                        aria-labelledby="manifestOptions"
                        style={{ marginTop: "-5px", zIndex: "11" }}
                      >
                        <a
                          className="dropdown-item p10 fs12 fw6 cd"
                          onClick={() => handleDropDownSetting("All")}
                        >
                          All
                        </a>
                        <a
                          className="dropdown-item p10 fs12 fw6 cd"
                          onClick={() => handleDropDownSetting("Open Manifest")}
                        >
                          Open Manifest
                        </a>
                        <a
                          className="dropdown-item p10 fs12 fw6 cd"
                          onClick={() =>
                            handleDropDownSetting("Closed Manifest")
                        }
                        >
                          Closed Manifest
                        </a>
                      </div>
                    </div>
                    <div class="manifest-dropdown">

                    </div>
                  </div>
                </section>
                <MultiSelect
                                         options={manifestlist}
                                        
                                         
                                            
                                         // Options to display in the dropdown
                                          
                                         value={manifestselectedvalue}
                                       onChange=
                                       {(item)=>{if(item.length>0)
                                        {
                                          setmanifestselectedvalue([item[item.length-1]])
                                          focusref.current.focus();
                  
                                        }

                                        else
                                        setmanifestselectedvalue([])}}
                                      // disableSearch={true}
                                       hasSelectAll={false}
                                      
                                       overrideStrings={{
                                        "allItemsAreSelected": manifestselectedvalue.length>0?manifestselectedvalue[0].label:"",
                                        "clearSearch": "Clear Search",
                                        "clearSelected": "Clear Selected",
                                        "noOptions": "No options",
                                        "search": "Search",
                                        "selectAll": "Select All",
                                        "selectAllFiltered": "Select All (Filtered)",
                                        "selectSomeItems": "Select",
                                        "create": "Create",
                                       }}   
                                       filterOptions={(options, filter) => {
                                        if (!filter) {
                                          return options;
                                        }
                                                                             
                                        return options.filter(({ label }) => label.toLocaleLowerCase().includes(filter.toLocaleLowerCase()));
                                      }}                                    
                                        /> 

              
                 
                



              </span>
              
              </div>
              <div className="col-md-5 ">
              <h6 className="DCinputlabel">Search by Con# or Carrier Reference#</h6>
              <span>
              <div className="input-group  dcdespatch-search">
                      <input
                        name="field"
                        autocomplete="off"
                        type="text"
                        className="form-control input-search inputDCCOnf"
                        placeholder="Search"
                        value={carrierRef}
                        ref={focusref}
                        onChange={(e)=>{setcarrierRef(e.target.value)}}
                      />
                      <div className="input-group-append DCscanner bg-white">
                        <span className="btn btn-outline-secondary bg-white" 
                        style={{
                            border:"none",
                        

                        }}
                        >
                          <img src={scanner} class="scannerimgdc" alt="" />
                        </span>
                      </div>
                    </div>
    


              </span>
              
              </div>
              <div className="col-md-2 text-center">
              <h6 className="DCinputlabel"></h6>
              <span>
<button className="dcsearch" onClick={()=>getManifestDetails(manifestselectedvalue.length>0?manifestselectedvalue[0].value:null)} disabled={manifestselectedvalue.length==0 && carrierRef.length==0}>search</button>

              </span>
              </div>



          </div>

</div>

     </div>
      
      }

      <div
        className="modal fade loginModal"
        id="exampleModalCenter1"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-z"
          role="document"
        >
          <div className="modal-content modal-radius">
          <div className={msgheader!="Error"?"modal-header confirmation-header"
            :"modal-header error-popup-header-bg"}>
              <h5
                className="modal-title confirmation-style"
                id="errorModalCenterTitle"
              >
                {msgheader}
              </h5>
            </div>
            <div className="modal-body text-align-centre">
              <div>{msg} </div>
            </div>
            <div className="modal-footer bo-modalbtnftr bt-none">
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-yes"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade loginModal"
        id="exampleModalCenter2"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-z"
          role="document"
        >
          <div className="modal-content modal-radius">
            <div className="modal-header confirmation-header">
              <h5
                className="modal-title confirmation-style"
                id="errorModalCenterTitle"
              >
                Success
              </h5>
            </div>
            <div className="modal-body text-align-centre">
              <div>Data sent to printer successfully </div>
            </div>
            <div className="modal-footer bo-modalbtnftr bt-none">
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-yes"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade loginModal"
        id="exampleModalCenter23"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-z"
          role="document"
        >
          <div className="modal-content modal-radius">
            <div className="modal-header error-popup-header-bg">
              <h5
                className="modal-title confirmation-style"
                id="errorModalCenterTitle"
              >
                Error
              </h5>
            </div>
            <div className="modal-body text-align-centre">
              <div>Failed to print!</div>
            </div>
            <div className="modal-footer bo-modalbtnftr bt-none">
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-yes"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade loginModal"
        id="exampleModalCenter_cantclose"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-z"
          role="document"
        >
          <div className="modal-content modal-radius">
            <div className="modal-header error-popup-header-bg">
              <h5
                className="modal-title confirmation-style"
                id="errorModalCenterTitle"
              >
                Error
              </h5>
            </div>
            <div className="modal-body text-align-centre">
              <div>{datas.length>0 && datas.filter((a)=>a.manifestId!=null).length>0?(datas.filter((a)=>a.manifestId!=null)[0].toLocation.split(",")[0]+" "+datas.filter((a)=>a.manifestId!=null)[0].toLocation.split(",")[1]):null}
                <br/>
                Can’t close the manifest.The supplier has an Open Despatch
              </div>
            </div>
            <div className="modal-footer bo-modalbtnftr bt-none">
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-yes"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade loginModal"
        id="exampleModalCenter123"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-z"
          role="document"
        >
          <div className="modal-content modal-radius">
            <div className="modal-header confirmation-header">
              <h5
                className="modal-title confirmation-style"
                id="errorModalCenterTitle"
              >
                Confirmation
              </h5>
            </div>
            <div className="modal-body text-align-centre">
              <div>Would you like to Close this Manifest?</div>
            </div>
            <div className="modal-footer bo-modalbtnftr bt-none">
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-no"
              >
                No
              </button>
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-yes"
               onClick={closeManifest}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

                        {showloader?<LoadingIndicator/>:null}

                        <div className="topCenter" hidden={!itemTaggingToast}>
        <Toast id="toast" show={itemTaggingToast} onClose={() => setItemTaggingToast(false)} delay={5000} autohide>
          <div className="toast__content">
            <div>
              <span class="vertical-toast-border"></span>
              <div className="tickmark" style={{ height: "100%" }}>
                <img className="tickmark" src="./images/tick-circle-icon.svg" alt="" />
              </div>
              <div className="pt-10" >
                <p className="toast__type">Success</p>
                <p className="toast__message">{toastmsg}</p>
              </div>
              <div>
                <button
                  type="button"
                  className="close toast-close"
                  data-dismiss="toast"
                  aria-label="Close"
                  onClick={() => setItemTaggingToast(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>
        </Toast>
      </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>);


}

export default DCviewManifest;
