import React from "react";
import Toast from "react-bootstrap/Toast";
const ToastNotification = ({
  itemTaggingToast,
  setItemTaggingToast,
  putMessage,
  toastmsg,
  ...props
}) => {
  return (
    <div className="topCenter" hidden={!itemTaggingToast}>
      <Toast
        id="toast"
        show={itemTaggingToast}
        onClose={() => setItemTaggingToast(false)}
        delay={5000}
        autohide
      >
        <div className="toast__content">
          <div>
            <div className="tickmark h-100" >
              <img
                className="tickmark"
                src="./images/tick-circle-icon.svg"
                alt=""
              />
            </div>
            <div className="pt-10">
              <p className="toast__type">Success</p>
              <p className="toast__message">{toastmsg}</p>
            </div>
            <div>
              <button
                type="button"
                className="close toast-close"
                data-dismiss="toast"
                aria-label="Close"
                onClick={() => setItemTaggingToast(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>
      </Toast>
    </div>
  );
};

export default ToastNotification;
