import Header from "components/common/Header";
import React, { useEffect, useState,useRef } from "react";
import $ from "jquery";
import Toast from "react-bootstrap/Toast";
import LoadingIndicator from "components/common/LoadingIndicator";
import * as constants from "../../components/constants";
import { Link } from "react-router-dom";
import Sidebar from "components/common/Sidebar";
import { callLogoutURL } from "components/Authentication/utilities";
import { useIdleTimer } from "react-idle-timer";
import dateFormat from 'dateformat';
import moment from "moment";

import DcreceptingService from "services/DcreceptingService";
const DCReceiptingMissing = () => {
  const user = JSON.parse(localStorage.getItem("User"));

  const [loader, setLoader] = useState(false);
  const [ublDetails, setUblDetails] = useState([]);
  const [toggleNoItemsFound, setToggleNoItemsFound] = useState(false);
  const [toggleUblResults, setToggleUblResults] = useState(false);
  const [toggleButtons, setToggleButtons] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  const [invalidUblNum, setInvalidUblNum] = useState(false);

  const [togglescreen, settogglescreen] = useState(true);
  const [checked,setchecked]=useState([]);

  const [input, setInput] = useState("");
  const [timeoutDuration] = useState(
    localStorage.getItem("timeout") ? localStorage.getItem("timeout") : 1800000
  );
  useEffect(()=>{},togglescreen);
  const  inputFocus=useRef();

  if(typeof window!='undefined'){
    console.log("listener",localStorage.getItem("access_token"));
    window.addEventListener("storage",function(e){
       if(localStorage.getItem('access_token') == "null"){
        callLogoutURL()
        return
      }
    })
  }
  
  useEffect(()=>{
    if(localStorage.getItem('access_token') == "null"){
      callLogoutURL()
      return
    }
    inputFocus.current.focus()
  },[]);
  const [errorMessageAlert, setErrorMessageAlert] = useState("");
  const inputBarcode =input;
  const ViewSummary=()=>{
    if (
      inputBarcode.length === 7 &&
     
      inputBarcode > 0
    ) {
      setInvalidUblNum(false);
    }
    else if(inputBarcode.length === 0)
    {
        setInvalidUblNum(false);
        return;
    }
    else {
      setInvalidUblNum(true);
      return;
    }
    
      
   let temp_input=input;
   setInput("");
   if (
    inputBarcode.length === 7 &&
   
    inputBarcode > 0
  )    
    if(ublDetails.filter((detail)=>detail.ublId==temp_input).length==0)  
    {
      setLoader(true);
    DcreceptingService.getUblSummary(user.storeNo,temp_input,user.token).then((response)=>{
        setLoader(false);
        let tempdetails=response.UblSummaryDetails.details;
        if(tempdetails.length>0)
        {
            if(tempdetails[0].locationId)
            {
                setErrorMessageAlert("Item tagged at Putaway");
                $("#errorModal").modal();
    

            }
            else if(tempdetails[0].ublStatus=="M")
            {
                setErrorMessageAlert("Item Already tagged as Missing");
                $("#errorModal").modal();


            }
            else if(tempdetails[0].ublStatus=="X")
            {
                setErrorMessageAlert("Item in Detagged Status");
                $("#errorModal").modal();


            }
            else if(tempdetails[0].ublStatus=="C")
            {
                setErrorMessageAlert("Item not yet Despatch");
                $("#errorModal").modal();

            }
            else if(tempdetails[0].ublStatus=="D"||tempdetails[0].ublStatus=="R")
            {
              
                let temp=ublDetails;
                temp=tempdetails.concat(temp);
             
                setUblDetails(temp);
                let temp_checked=checked;
                temp_checked=[false].concat(temp_checked);
                setchecked(temp_checked);
                setToggleNoItemsFound(true);
                setToggleUblResults(true);
                setToggleButtons(true);
                settogglescreen((prev)=>!prev);
            }

        }
        else
        {
            setErrorMessageAlert("Invalid UBL");
            $("#errorModal").modal();

        }


    }).catch(err=>{console.log(err);setLoader(false)})
    }
    else
    {
      setErrorMessageAlert("UBL Already Added");
      $("#errorModal").modal();
      
    }

  }
  const handleOnchange = (e) => {
    const inputBarcode = e.target.value.replace(/[^\w\s]/gi, "");
    setInput(inputBarcode);
    if (
      inputBarcode.length === 7 &&
     
      inputBarcode > 0
    ) {
      setInvalidUblNum(false);
    }
    else if(inputBarcode.length === 0)
    {
        setInvalidUblNum(false);
    }
    else {
      setInvalidUblNum(true);
    }
  };

  const addMissingUBL=()=>{
      let ublid=[];
      let manifestid=[];
      let cblid=[];
      for(let i=0;i<checked.length;i++)
      {
          if(checked[i])
          {
              
              ublid.push(ublDetails[i].ublId);
              manifestid.push(ublDetails[i].shipId);
              if(ublDetails[i].cublId)
              cblid.push(ublDetails[i].cublId);
          }
      }
      setLoader(true);
      DcreceptingService.receiveUBL(ublid,user.displayName,manifestid,user.storeNo,user.token,null,cblid).then((response)=>{
        setLoader(false);
             if(response.updateRecieveUbl.message=="Receive UBL Order Details Updated")
               {
                setSuccessToast(true);
                setUblDetails([]); 
                setchecked([]);
                setToggleNoItemsFound(false);
                setToggleUblResults(false);
                setToggleButtons(false);
                settogglescreen((prev)=>!prev);
              }
        
      }).catch(err=>{console.log(err); setLoader(true);})
  }


  const handleOnIdle = (event) => {
    console.log("user is idle", event);
    console.log("last active", getLastActiveTime());
    callLogoutURL();
  };

  const handleOnActive = (event) => {
    console.log("user is active", event);
    console.log("time remaining", getRemainingTime());
    handleReset();
    if(localStorage.getItem('access_token') == "null"){
      callLogoutURL()
      return
    }
  };

  const handleOnAction = (event) => {
    // console.log("user did something", event);
    if(localStorage.getItem('access_token') == "null"){
      callLogoutURL()
      return
    }
  };
  const { getRemainingTime, getLastActiveTime, reset } = useIdleTimer({
    timeout: timeoutDuration,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });
  const handleReset = () => reset();




  return (
    <div id="DCReceipting">
      <Header />
      <div className="container-fluid">
        <div className="row">
          <Sidebar active="DCReceiptingMissing" state={user} />
          <main
            role="main"
            className="col-md-9 ml-sm-auto col-lg-10 rtn-main-wrapper"
            style={{ padding: "0px" }}
          >
            <section className="dcreceipting-header">
              <p class="opensansemi">DC RECEIPTING - Flag Missing UBLs</p>
              <div 
               class="d-none d-lg-block d-md-block"
               style={{
                float:"right",
                marginTop:"-40px"
              }}>
                  {/* {<Breadcrumb crumbs={crumbs} selected={selected} />} */}
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb " style={{fontSize:"12px"}}>
                    <Link
                        to={{ pathname: "/LandingPage", state: user }}
                        className="breadcrumb-item breadcrumb-color pr-10"
                      >
                        Home
                      </Link>
                      <span className="pr-10"> {" >"}</span>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                       DC Receipting
                      </li>
                    </ol>
                  </nav>
                </div>
            </section>

            {/* UBL selected by default */}
            <div className="row">
              <section className="dcr-fields col-md-8 col-10">
               
                <div>
                  <p class="opensansemi m-1 mb-2">
                   Search UBL
                  </p>
                  <div className="dcr-input-group">
                    <div
                      className={
                         "dcr-input-icon-container"
                      }
                    ><form class="p-0 m-0" onSubmit={(e)=>{
                        e.preventDefault();
                        ViewSummary();
                    }}>
                      <input
                        type="text"
                        placeholder="Search"
                        autocomplete="off"
                        name="ublId"
                        value={input}
                       
                        ref={inputFocus}
                        onChange={(e)=>{
                          const inputBarcode = e.target.value.replace(/[^0-9\+]/g, "");
                          setInput(inputBarcode);                         
                        }}
                        onFocus={(e)=>
                          {
                            setInvalidUblNum(false);

                          }
                        }
                        onBlur={handleOnchange}
                       
                      /></form>
                    
                    </div>
                    {invalidUblNum && input.ublId !== "" ? (
                      <div className="invalid-ubl-num">
                        <img src="./images/error-icon.svg" alt="" />
                        Invalid UBL!
                      </div>
                    ) : null}
                  </div>
                </div>
              </section>
            </div>

            {/* Scanned results for UBL */}
            {toggleUblResults ? (
              <section className="scanned-ubl-results">
                <div className="sur-header pb-3 pt-1">
                  <p className="float-left">Missing UBLs</p>
                   <div>
              <input
                type="checkbox"
                checked={checked.filter((a)=>a==false)==0}
                onClick={(e)=>{
                    if(!e.target.checked)
                    setchecked(new Array(checked.length).fill(false));
                    else
                    setchecked(new Array(checked.length).fill(true));
                    settogglescreen((prev)=>!prev);
                }
                }
               
              />
              <label>Select All</label>
            </div> 
                </div>
                {ublDetails.map((detail, detailIdx) => (
                  <div className="sur-list" key={detailIdx}>
                    <div>
                      <span>Item #: </span>
                      <span>{detail.itemId}</span>
                      <span>
                        {<input
                    type="checkbox"
                    checked={checked[detailIdx]}
                    onClick={()=>{
                        let temp_checked=checked;
                        console.log(checked);
                        temp_checked[detailIdx]=!checked[detailIdx];
                        setchecked(temp_checked);
                        settogglescreen((prev)=>!prev);

                    }}
                    
                  />}
                      </span>

                      <p>{detail.itemDescription}</p>
                      <p>{detail.supplierNo} | {detail.supplierName}</p>
                    </div>
                    <div className="sur-bc-view">
                      <p className="barcode-font" style={{ fontSize: "27px" }}>
                        {detail.ublId}
                      </p>
                      <a
                        data-toggle="collapse"
                        href={"#collapseExample" + detail.ublId}
                        role="button"
                        aria-expanded="false"
                        data-placement="right"
                        aria-controls="collapseExample"
                        onClick={() => {
                          $("#collapseExample" + detail.ublId).modal();
                        }}
                      >
                        <i class="fas fa-eye"></i>View
                      </a>
                    </div>
                  </div>
                ))}
              </section>
            ) : null}

            {/* No items found message */}
            {!toggleNoItemsFound ? (
              <div className="dcru-no-items-found pl-0 pr-0 pt-2 bg-white">

                <img
                  src="./images/Item-not-found.svg"
                  className="pt-3"
                  alt=""
                />
                <p>No items found</p>
              </div>
            ) : null}

            {/* Back and Complete Receiving buttons */}
            {toggleButtons ? (
              <section className="dcru-buttons-ubl">
                {/* <button type="button" data-toggle="modal" data-target="#backModal">
            Back
          </button> */}
                 <button
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModal1"
                  className="flagMissingExit"
                  onClick={()=>{               
                    setUblDetails([]); 
                    setchecked([]);
                    setToggleNoItemsFound(false);
                    setToggleUblResults(false);
                    setToggleButtons(false);
                    settogglescreen((prev)=>!prev);}}
                >
                 Exit
                </button> 

                <button
                  type="button"
                  onClick={()=>{$("#errorModalCenter51").modal()}}
                 disabled={checked.filter(a=>a==true)==0}
                  className="FlagMissing ifdisable"
                >
                Flag Missing UBL(s)
                </button>
              </section>
            ) : null}

            {/* View Details */}
            {toggleUblResults ? (
              <>
                {ublDetails.map((viewubl, detailIdx) => (
                  <div
                    class="modal summary-modal right fade"
                    id={"collapseExample" + viewubl.ublId}
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                  >
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h4 class="modal-title" id="myModalLabel">
                            View Summary
                          </h4>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>

                        <div class="modal-body">
                          <div class="d-flex bd-highlight">
                            <div class="bd-highlight item-num">
                              Item #: <span>{viewubl.itemId}</span>
                            </div>

                            <div class="ml-auto view-det-link bd-highlight">
                              <div class="barcode-font">{viewubl.ublId}</div>
                            </div>
                          </div>

                          <div class=" item-name mb-3">
                            {" "}
                            {viewubl.itemDescription}{" "}
                          </div>

                          <div class="card">
                            <div class="card-header">
                              <div className="row">
                                <div className="col-md-6 col-xs-12">
                                  <h6>UBL:{viewubl.ublId}</h6>
                                </div>
                                <div className="col-md-6 col-xs-12">
                                  <h6></h6>
                                </div>
                              </div>
                            </div>
                            <div class="card-body">
                              <div className="row">
                                <div className="col-md-6 col-xs-12 mb-3">
                                  <h6>Supplier </h6>
                                  <span>{viewubl.supplierNo} | {viewubl.supplierName}</span><br/>
                                  {viewubl.addLine1?<span
                                  style={{
                                    fontSize:"11px",
                                    display:"inherit",
                                    marginTop:"5px"
                                  }}> <img
                          src="./images/location-icon.svg"
                          alt=""    
                          style={{height:"15px",
                          marginRight:"4px",
                          float:"left",}}
                        />
                        <p style={{
                          
                          display:"inline"

                        }}>{viewubl.addLine1+","+(viewubl.addLine2?(viewubl.addLine2+","):"")+(viewubl.addLine3?(viewubl.addLine3+","):"")+viewubl.city+","+viewubl.state+"-"+viewubl.postCode}</p>
                        </span>:null}
                                </div>
                                <div className="col-md-6 col-xs-12 mb-3">
                                  <h6>Supplier PO#</h6>
                                  <span>{viewubl.supplierPo}</span>
                                </div>
                                <div className="col-md-6 col-xs-12 mb-3">
                                  <h6> Customer Order#</h6>
                                  <span>{viewubl.saleOrderNo}</span>
                                </div>
                                <div className="col-md-6 col-xs-12 mb-3">
                                  <h6> Quantity</h6>
                                  <span>{1}</span>
                                </div>

                                <div className="col-md-6 col-xs-12 mb-3">
                                <h6>Return Location</h6>
                                <span>{('000'+viewubl.storeId).substr(-3)}-{viewubl.returnLocation}</span>
                     
                                </div>
                                <div className="col-md-6 col-xs-12 mb-3">
                                  <h6>Return Reason</h6>
                                  <span>{viewubl.returnReason}</span>
                                </div>

                                <div className="col-md-6 col-xs-12 mb-3">
                                  <h6>Return Order#</h6>
                                  <span>{viewubl.returnOrderNo}</span>
                                </div>
                                <div className="col-md-6 col-xs-12 ">
                                  <h6>Status</h6>
                                  <span>                                           {viewubl.ublStatus=="R"?"Received at DC":
          viewubl.ublStatus=="M"?"Item Missing":
          viewubl.ublStatus=="D"?"Despatched to DC":
          viewubl.ublStatus=="RD"?"Despatched to Supplier":
          viewubl.ublStatus=="SD"?"Ready Despatch to Supplier"
          :""}|{user.storeName} ,{ viewubl.updatedTimestamp?
            (moment.utc(viewubl.updatedTimestamp).format("DD-MM-YYYY | hh:mm A")):null}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : null}

            {/* Success Modal */}
            <div className="topCenter">
              <Toast
                id="toast"
                show={successToast}
                onClose={() => setSuccessToast(false)}
                delay={5000}
                autohide
              >
                <div className="toast__content pt-12">
                  <div className="tickmark h-100">
                    <img
                      className="tickmark mt-5"
                      src="./images/tick-circle-icon.svg"
                      alt=""
                    />
                  </div>
                  <div>
                    <p className="toast__type">Success</p>
                    <p className="toast__message">
                    Flagged as Missing UBL(s)
                    </p>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="close toast-close"
                      data-dismiss="toast"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
              </Toast>
            </div>

            {/* Error Modal */}
            <div
              className="modal fade"
              id="errorModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content dcr-modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Alert
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body dcr-modal-alert">
                    <i className="fas fa-exclamation-triangle"></i>
                    {errorMessageAlert}
                  </div>
                  <div className="dcr-modal-alert-button">
                    <button
                      type="button"
                      className="btn btn-secondary text-decoration-none"
                      data-dismiss="modal"
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Confirmation UBL Modal */}
            <div
                className="modal fade loginModal"
                id="errorModalCenter51"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="errorModalCenterTitle"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-z" role="document">
                    <div className="modal-content modal-radius">
                        <div className="modal-header confirmation-header">
                            <h5 className="modal-title confirmation-style" id="errorModalCenterTitle">
                                Confirmation
                            </h5>

                        </div>
                        <div className="modal-body text-align-centre" >
                            
                            <div className="pt10">
                            Would you like to declare selected Item(s) as missing?
                                </div>
                        </div>
                        <div className="modal-footer bo-modalbtnftr bt-none justify-content-center justify-content-md-end">
                 
                        <button type="button" 
                    className="text-dark text-decoration-none 
                     btn bo-btnprimary
                     border border-secondary
                     confirm-no"
                     data-dismiss="modal"
                     style={{width:"45%"}}>
                                No
                            </button>
                            <button
                                data-dismiss="modal"
                                type="button"
                                className=" btn bo-btnprimary confirm-yes"
                                onClick={addMissingUBL}
                                style={{width:"45%"}}>
                                 Yes
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {loader ? <LoadingIndicator /> : null}
          </main>
        </div>
      </div>
    </div>
  );
};

export default DCReceiptingMissing;
