
import React ,{useState,useEffect} from "react";
import CarrierDetailInput from "./CarrierDetailInput";
import CreateManifest from "./CreateManifest";
import Summary from "./Summary";
import Sidebar from "../../../components/common/Sidebar";
import { callLogoutURL } from "../../Authentication/utilities";
import CreateShipment  from "../../../services/CreateShipmentService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faMapMarkerAlt, faPrint } from '@fortawesome/free-solid-svg-icons';
import LoadingIndicator from "../../../components/common/LoadingIndicator";
import { useIdleTimer } from 'react-idle-timer'
import ManifestReport from "./ManifestReport";
import Header from "components/common/Header";
import { Link } from "react-router-dom";
import { textAlign } from "@mui/system";
const Newshipment = (props) => {
  const user = JSON.parse(localStorage.getItem("User"));
  const [carrierlist, setcarrierlist] = useState([]);
  const [manifestno, setmanifestno] = useState("");
  const [Address, setAddress] = useState("");
  const [shipmenttime, setshipementtime] = useState("");
  const createShipment = new CreateShipment();
  const [props1, setprops1] = useState(user);
  const [pageno, setpageno] = useState(1);
  const [items, setitems] = useState([]);
  const [carrierName, setcarrierName] = useState("");
  const [carrierRefno, setcarrierRefno] = useState("");
  const [carrierId, setcarrierId] = useState("");
  const [itemdetail, setitemdetail] = useState([]);
  const [manifestdetails, setmanifestdetails] = useState([]);
  const [fresh, setfresh] = useState(true);
  const [shipmentCreated,setshipmentCreated]=useState(false);
  const [showloader, setshowloader] = useState(false);
  const [ubldetails,setubldetails]=useState([]);
  const [cublubllist,setcublubllist]=useState([]);
  const [timeoutDuration] = useState(localStorage.getItem('timeout') ? localStorage.getItem('timeout') : 1800000);
  const [state1, setState1] = useState({
    token: user.token,
    userName: user.userName,
    storeNo: user.storeNo,
    role: user.role,
    storeName: user.storeName,
    displayName: user.displayName,
    menuAllowed: user.menuAllowed,
    tempSearchMenu: user.tempSearchMenu,
    tempCreateDispatchInStoreMenu:
      user.tempCreateDispatchInStoreMenu,
    tempCreateConsolidatedDispatchMenu:
      user.tempCreateConsolidatedDispatchMenu,
    tempCreateShipment: user.tempCreateShipment,
    loggedInTime: user.loggedInTime,
  });

  if (typeof window != 'undefined') {
    console.log("listener", localStorage.getItem("access_token"));
    window.addEventListener("storage", function (e) {
      if (localStorage.getItem('access_token') == "null") {
        callLogoutURL()
        return
      }
    })
  }

  useEffect(() => {
    if (localStorage.getItem('access_token') == "null") {
      callLogoutURL()
      return
    }
    window.addEventListener("scroll", handleOnActive);
    return () => window.removeEventListener("scroll", handleOnAction);
  }, []);

  useEffect(async() => {
    let flag=0;
    console.log(props);
    setshowloader(true);
  createShipment.manifestno(props1.storeNo, props1.token).then((response) => {
      console.log(response);
      setmanifestno(response.fetch_manifest.ship_Id);
      flag=flag+1;
      if(flag==4)
      {
        setshowloader(false);
      }
     
      
    }).catch(err => { console.log(err);
      flag=flag+1;
      if(flag==4)
      {
        setshowloader(false);
      }
    });
  createShipment.getCUBLUBL(props1.storeNo,props1.token)
    .then((res)=>{
      let temp=[];
      if(res.cubl_ubl_shipment.cublId && res.cubl_ubl_shipment.cublId!=" ")
      { console.log(res.cubl_ubl_shipment.cublId);
        temp=res.cubl_ubl_shipment.cublId;}
      
      if(res.cubl_ubl_shipment.ubldetails && res.cubl_ubl_shipment.ubldetails!=" " && !user.tempCreateShipmentToggle)
     {
      console.log(res.cubl_ubl_shipment.ubldetails);
       temp.push(...res.cubl_ubl_shipment.ubldetails);
    }
     if(res.cubl_ubl_shipment.ubldetails && res.cubl_ubl_shipment.ubldetails!=" ")
     setubldetails(res.cubl_ubl_shipment.ubldetails);
     else
     setubldetails([]);

     console.log(res.cubl_ubl_shipment.ubldetails);

     setcublubllist(temp);
     console.log(temp);

     flag=flag+1;
      if(flag==4)
      {
        setshowloader(false);
      }

    })
    .catch(err=>{console.log(err);
      flag=flag+1;
      if(flag==4)
      {
        setshowloader(false);
      }
    
    })

   createShipment.CarrierDetails(props1.token).then((response) => {
      let res = response.return_carrier_response.details;
      let carriername = [];
      setcarrierName(res[0].CARRIER_NAME);
      for (let i = 0; i < res.length; i++)
        carriername.push(res[i]);

      setcarrierlist(carriername);

      flag=flag+1;
      if(flag==4)
      {
        setshowloader(false);
      }
     
    }).catch(err => {console.log(err);
    
      flag=flag+1;
      if(flag==4)
      {
        setshowloader(false);
      }
    });
 
  createShipment.destination(props1.storeNo, props1.token).then((response) => {
      console.log(response);
      setAddress(response.DestinationConfigDetails.details[0]);
      flag=flag+1;
      if(flag==4)
      {
        setshowloader(false);
      }
      
    }).catch(err => {
      console.log(err);
    
      flag=flag+1;
      if(flag==4)
      {
        setshowloader(false);
      }
    });
   

    let dateObj = new Date();
    var hours = dateObj.getHours();
    var minutes = dateObj.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    var stime =  (dateObj.getDate()) + '/'+ [dateObj.getMonth()+1]+"/"+ dateObj.getFullYear() + ' | ' + strTime;
    setshipementtime(stime);

  }, [fresh]);
  const handleback_report = () => {

    setitems([]);
    setitemdetail([]);
    setcarrierId("");
    setcarrierRefno("");
    setpageno(1);
    setfresh(!fresh);
  }
  const oncancel = () => {
    setitems([]);
    setitemdetail([]);
    setcarrierId("");
    setcarrierRefno("");
    setpageno(1);

  }
  const logout = () => {
    callLogoutURL();
  }
  const navigateToLandingPage = () => {
    props.history.push("/LandingPage");

  }
  const handlefirstpage = (item, itemdetail,ubldetail_1) => {
    setitems(item);
    setitemdetail(itemdetail);
   
    console.log(carrierlist);
    setpageno(2);

  }
  const handleback = (carriername, carrierref, carrierId) => {
    setpageno(1);
    setcarrierName(carriername);
    setcarrierRefno(carrierref);
    setcarrierId(carrierId);
  }
  const handleback2 = (item,ubldetail_1) => {
    setpageno(2);
    setitems(item);
  
  }
  const handlethirdpage = (item, details) => {
    setitems(item);
    setitemdetail(details);
    
    setshowloader(true);
    createShipment.ViewManifest(props1.storeNo, manifestno, props1.displayName, props1.token).then((response) => {
      if (response.printManifest.results)
        setmanifestdetails(response.printManifest.results);
      console.log(response);
      setpageno(4);
      setshowloader(false)

    }).catch(err => { console.log(err); setshowloader(false) })
  }
  const handlesecondpage = (carriername, carrierref, carrierId) => {
    setpageno(3);
    setcarrierName(carriername);
    setcarrierRefno(carrierref);
    setcarrierId(carrierId);
  }

  const handleOnIdle = event => {
    console.log('user is idle', event)
    console.log('last active', getLastActiveTime())
    callLogoutURL()
  }

  const handleOnActive = event => {
    console.log('user is active', event)
    console.log('time remaining', getRemainingTime())
    handleReset()
    if(localStorage.getItem('access_token') == "null"){
      callLogoutURL()
      return
    }
  }

  const handleOnAction = event => {
    console.log('user did something', event)
    if(localStorage.getItem('access_token') == "null"){
      callLogoutURL()
      return
    }
  }
  const { getRemainingTime, getLastActiveTime, reset } = useIdleTimer({
    timeout: timeoutDuration,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
  })
  const handleReset = () => reset()

  return (
    <div className="OrderLookup bg-grey" id="shipment_component">
      <Header />
      <div className="container-fluid">
        <div className="row">
          <Sidebar active="CreateShipment" state={user} />
          <main
            role="main"
            className="col-md-9 ml-sm-auto col-lg-10 rtn-main-wrapper"
          >
            {/* page title */}
            <section className="rtn-page-title mb-2">
              <div className="d-flex row bd-highlight ">
                <div className="bd-highlight col-xs-12 col-md-6 modal-dialog-centered">
                  <h4>New Shipment</h4>
                </div>

                <div className=" col-xs-12 col-md-6 bd-highlight home-bc-padding">
                  {/* {<Breadcrumb crumbs={crumbs} selected={selected} />} */}
                  <nav aria-label="breadcrumb"style={{
                    marginRight:"15px"
                  }}>
                    <ol className="breadcrumb">
                    <Link
                        to={{ pathname: "/LandingPage", state: user }}
                        className="breadcrumb-item breadcrumb-color pr-10"
                      >
                        Home
                      </Link>
                              <span className="pr-10"> {" >"}</span>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                               Shipment
                              </li>
                            </ol>
                  </nav>
                </div>
              </div>
            </section>
            <div class="container-fluid section-three">
              <div class="row mrt14">
                <div class="col-md-2" id="sideprogress">
                  <div class="card1">
                    <ul id="progressbar" class="text-center ">
                      <li class="active step0 step1"></li>
                      <li class={pageno > 1 ? "step0 step2 active" : "step0 step2"}></li>
                      <li class={pageno > 3 ? "step0 step3 active" : "step0 step3"}></li>
                    </ul>
                    <div class="mb21">
                      <div class="row">
                        <div class="col-md-1 progressIcon">
                        <img
                           src="./images/create-icon.svg"
                            style={{height:"65%",marginTop:"-2px"}}
                            alt=""   
                        />
                         
                        </div>
                        <div class="col icon_div" > 
                          <h6 class="fs12 zero"> New Shipment </h6>
                          <h6 class="fs10 gray">{!user.tempCreateShipmentToggle?"Scan/Enter CBL/UBL":"Scan/Enter CBL"}</h6>

                        </div>
                      </div>
                    </div>
                    <div class="mb21">
                      <div class="row">
                        <div class="col-md-1 progressIcon">
                        <img
                          src="./images/location-icon.svg"
                          style={{height:"65%",marginTop:"-2px"}}
                          alt=""   
                        />
                         
                        </div>
                        <div class="col icon_div">
                          <h6 class="fs12 zero">Add Carrier </h6>
                          <h6 class="fs10 gray">Select Destination</h6>
                        </div>
                      </div>
                    </div>
                    <div class="mb21">
                      <div class="row">
                        <div class="col-md-1 progressIcon">
                        <img
                           src="./images/view-summary-icon.svg"
                          style={{height:"65%",marginTop:"-2px"}}
                           alt=""   
                        />
                         
                        </div>
                        <div class="col icon_div">
                          <h6 class="fs12 zero">Print Manifest </h6>
                          <h6 class="fs10 gray">Item Details</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {
                        pageno==1?<CreateManifest ubldetails={ubldetails}  cublubllist={cublubllist} pageno={pageno}
                        item={items} Address={Address} manifestno={manifestno} storeNo={props1.storeNo} itemdetail={itemdetail} token={props1.token}  handlefirstpage={handlefirstpage}/>:
                        pageno==2?<CarrierDetailInput oncancel={oncancel} carrierName={carrierName} carrierRefno={carrierRefno} carrierlist={carrierlist} manifestno={manifestno} handlesecondpage={handlesecondpage} token={props1.token} handleback={handleback}/>:
                        pageno==3?<Summary item={items} Address={Address} ubldetails={ubldetails} carrierName={carrierName} 
                           carrierRefno={carrierRefno} manifestno={manifestno} storeNo={props1.storeNo} storeName={props1.storeName}
                           token={props1.token} carrierId={carrierId} displayName={props1.displayName} itemdetail={itemdetail}
                           handleback2={handleback2}  handlethirdpage={handlethirdpage}/>:
                        <ManifestReport carrier={carrierName} manifestno={manifestno} Address={Address}
                         manifestdetails={manifestdetails} storeNo={props1.storeNo} token={props1.token} displayName={props1.displayName}
                          shipementtime={shipmenttime} handleback_report={handleback_report} handlefourthpage={handleback_report}
                          NavigatetoLanding={navigateToLandingPage}/>
                        
                        }
                        {showloader?<LoadingIndicator/>:null}
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>);


}

export default Newshipment;
