import React from "react";
import { connect } from "react-redux";
import { authenticationReducer } from "./reducer";
import { AuthContext } from "./constants";

class AuthProvider extends React.Component {
  render() {
    const { isAuthenticated, accessToken, refreshToken, children } = this.props;
    const AuthDetails = {
      isAuthenticated,
      accessToken,
      refreshToken,
    };
    return (
      <AuthContext.Provider value={AuthDetails}>
        {children}
      </AuthContext.Provider>
    );
  }
}

const mapStateToProps = (state) => {
  const { authenticated, accessToken, refreshToken } = state.authentication;
  return {
    isAuthenticated: authenticated,
    accessToken,
    refreshToken,
  };
};

export default connect(mapStateToProps, null)(AuthProvider);
