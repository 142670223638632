import React, { useEffect, useState } from "react";
import {
    faArrowDown,
    faArrowUp,
    faBox,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as constants from "../constants";
import { addUbl } from "redux/thunks";
import LoadingIndicator from "components/common/LoadingIndicator";
import $ from "jquery";


const EditCreateDespatch = ({ itemDetails, index, showloader,  ...props }) => {
    const [toggleArrows, setToggleArrows] = useState(true);
    const [invalidUblNum, setInvalidUblNum] = useState(false);
    const [ublItemId, setUblItemId] = useState({
        ublItemId: "",
    });
    const [despatchNumber, setDefaultFields] = useState(itemDetails[0].despatchNumber)
    
    const handleOnchangeUblItemId = (e) => {
        const inputBarcode = e.target.value.replace(/[^\w\s]/gi, "");
        setUblItemId({
            ...ublItemId,
            [e.target.name]: inputBarcode,
        });
        if (
            inputBarcode.length === 7 &&
            !isNaN(inputBarcode) &&
            inputBarcode > 0
        ) {
            setInvalidUblNum(false);
        } else {
            setInvalidUblNum(true);
        }
    };

    useEffect(()=>{
        console.log("resetInput called")
        setUblItemId({...ublItemId,
        ublItemId:""
        });
        setInvalidUblNum(false);
    },[props.resetInput, props.resetInputRef])

    useEffect(()=>{
            console.log("useeffect in edit called")
            setUblItemId({...ublItemId,
                ublItemId:""
                });
                setInvalidUblNum(false);
    },[itemDetails])

    const addUblToCarton = () => {
        let inputBarcode = ublItemId.ublItemId;
        if (inputBarcode.length === 7 && !isNaN(inputBarcode) && inputBarcode > 0) {
            setInvalidUblNum(false);
        }
        else {
            setInvalidUblNum(true);
            return;
        }

        props.addUblToCarton(ublItemId.ublItemId, despatchNumber, index)

    }

    const removeUblFromShipmentDC = (ublID, itemIndex) => {
        props.removeUblFromShipmentDC(ublID, itemIndex)

    }
    return (
        <>
            <div
                class="modal summary-modal right fade"
                id={"EditCreateDespatch"}
                tabindex="-1"
                role="dialog"
                aria-labelledby="myModalLabel"
            >
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title" id="myModalLabel">
                                Edit Summary
                            </h4>
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div class="modal-body">
                            <div class="d-flex bd-highlight">
                                <div class="bd-highlight item-num">
                                    DC Despatch Number #: <span>{itemDetails[0].despatchNumber}</span>
                                </div>
                            </div>
                            <hr />
                            <section className="search-panel mb-2 putaway-input-section">
                                <label className="my-1 mr-3" for="ublItemId">
                                    <p>
                                        <strong>Add UBL</strong>
                                    </p>
                                </label>
                                <form
                                    className="dcr-input-icon-container"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        addUblToCarton()
                                    }}
                                >
                                    <input
                                        id="ublItemId"
                                        autocomplete="off"
                                        type="text"
                                        required
                                        name="ublItemId"
                                        placeholder="Scan or Enter Items"
                                        aria-label="Text input with dropdown button"
                                        value={ublItemId.ublItemId}
                                        // onChange={handleOnchangeUblItemId}
                                        onChange={(e) => {
                                            const inputBarcode = e.target.value.replace(/[^\w\s]/gi, "");
                                            setUblItemId({
                                                ...ublItemId,
                                                [e.target.name]: inputBarcode,
                                            });
                                            setInvalidUblNum(false);
                                        }}
                                        onBlur={handleOnchangeUblItemId}
                                        maxLength={20}
                                    />
                                    <img
                                        class="dcr-dispatch-scanner-img"
                                        src="./images/barcodescanner.png"
                                        alt=""
                                    />
                                </form>
                                {invalidUblNum && ublItemId.ublItemId !== "" ? (
                                    <div className="invalid-ubl-num">
                                        <img src="./images/error-icon.svg" alt="" />
                                        Invalid Barcode!
                                    </div>
                                ) : null}
                            </section>
                            <section
                                className="d-flex justify-content-between align-items-center edit-summary-details"
                                onClick={() => setToggleArrows(!toggleArrows)}
                            >
                                {/* UBL ITEM 1  NEED TP MAP OVER THE ITEMS*/}
                                <span className="bd-highlight">
                                    View Added UBL ({itemDetails.length})
                                </span>
                                {toggleArrows ? (
                                    <a
                                        href="#"
                                        onClick={() => setToggleArrows(!toggleArrows)}
                                    >
                                        <img
                                            src="./images/down-arrow-icon-black.svg"
                                            alt="downArrow"
                                            className="m-2"
                                        />
                                    </a>
                                ) : (
                                    <a
                                        href="#"
                                        onClick={() => setToggleArrows(!toggleArrows)}
                                    >
                                        <img
                                            src="./images/up-arrow-icon.svg"
                                            alt="upArrow"
                                            className="m-2"
                                        />
                                    </a>
                                )}
                            </section>
                            {toggleArrows && (
                                <section>
                                    {itemDetails.map((detail_1, index) => {
                                        return (
                                            <div className="sur-list" key={index}>
                                                <div>
                                                    <span className="tile-item">Item #: </span>
                                                    <span>{detail_1.itemId}</span>
                                                    <span>
                                                        <a
                                                            data-toggle="collapse"
                                                            role="button"
                                                            aria-expanded="false"
                                                            data-placement="right"
                                                            aria-controls="EditCreateDespatch"
                                                            onClick={() => {
                                                                // $("#3").modal();
                                                            }}
                                                        >
                                                            <img
                                                                src="./images/delete-icon.svg"
                                                                alt="delete"
                                                                onClick={() => removeUblFromShipmentDC(detail_1.ublId, index)}
                                                            />
                                                        </a>
                                                    </span>

                                                    <p className="bd-highlight item-name mb-0">
                                                        {detail_1.itemDescription}
                                                    </p>
                                                    <p>
                                                        {detail_1.supplierNo} |
                                                        {detail_1.supplierName}
                                                    </p>
                                                </div>
                                                <div className="container">
                                                    <div className="row sur-bc-view d-flex bd-highlight mt-3 p-0 m-0">
                                                        <div className="p-2 col-4 bd-highlight">
                                                            <p className="barcode-font barcode-font-size">
                                                                {detail_1.ublId}
                                                            </p>
                                                        </div>
                                                        <div class="p-2 col-8 bd-highlight mt-0">
                                                            <label className="bd-highlight brand mb-0">
                                                                {/* {detail_1.BRAND} */}
                                                                BRAND
                                                            </label>
                                                            <p className="brand-desc">
                                                                {detail_1.brand}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </section>
                            )}
                        </div>
                    </div>
                    
                </div>
                {showloader ? <LoadingIndicator /> : null}
                
            </div>
        </>

    );
}

export default EditCreateDespatch;