export default class SearchService {

    orderDetails(StoreNo, OrderId, token, saleOrderId, cublId, ublId, itemId, searchId) {
        return fetch(process.env.REACT_APP_API_BASE_ENDPOINT + process.env.REACT_APP_STORE + StoreNo + '/returnOrderDetails?returnOrderId=' + OrderId + '&saleOrderId=' + saleOrderId + 
        '&cublId=' + cublId + '&ublId=' + ublId + '&itemId=' + itemId + '&searchId=' + searchId,
            {
                method: 'GET',
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY,
                    'Authorization': token
                }

            }).then(response => {
                if (response.status == 200) {
                    console.log('response.status',response.status)
                    return response.json();
                }
                else if (response.status == 400) {
                    let res = {}
                    response.message = "Invalid Search";
                }
                else if(response.status == 502){
                    let res = {}
                    response.message = "Internal Server Error";
                }
                else {
                    let res = {}
                    response.message = "Internal Server Error";
                }

                return response;

            }
            ).catch(err => {
                return err;

            });
    }
    AdvanceOrderSearch(storeNo, SalesOrderNo, returnOrderNo, firstName, lastName, phoneNumber, customerId, returnStatus, location, supplierInvoice, supplierPo, itemId, dateFrom, dateTo, reasonCode, brand, supplierName, token) {
        return fetch(process.env.REACT_APP_API_BASE_ENDPOINT + process.env.REACT_APP_STORE + storeNo + "/returnOrder?salesOrderNum=" + SalesOrderNo + "&returnOrderNum=" + returnOrderNo + "&firstName=" + firstName + "&lastName=" + lastName + "&phoneNumber=" + phoneNumber + "&customerId=" + customerId + "&returnStatus=" + returnStatus + "&location=" + location + "&supplierInvoice=" + supplierInvoice + "&supplierPo=" + supplierPo + "&itemId=" + itemId + "&dateFrom=" + dateFrom + "&dateTo=" + dateTo + "&reasonCode=" + reasonCode + "&brand=" + brand + "&supplierName=" + supplierName,
            {
                method: 'GET',
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY,
                    'Authorization': token
                }

            }).then(response => {

                if (response.status == 200) {

                    return response.json();
                }
                else if (response.status == 400) {
                    response = {}
                    response.message = "Invalid Search";
                }
                else if(response.status == 502){
                    response = {}
                    response.message = "Internal Server Error";
                }
                else {
                    response = {}
                    response.message = "Internal Server Error";
                }

                return response;

            }).catch(err => { return err });


    }
    ViewUblDetails(storeNo, orderNo, token) {
        let orderNo_array = '[';
        for (let i = 0; i < orderNo.length; i++) {
            if (i == (orderNo.length - 1))
                orderNo_array = orderNo_array + "\"" + orderNo[i] + "\"]";
            else
                orderNo_array = orderNo_array + "\"" + orderNo[i] + "\",";
        }

        return fetch(process.env.REACT_APP_API_BASE_ENDPOINT + process.env.REACT_APP_STORE + storeNo + "/viewUbl?returnOrderNo=" + orderNo_array,
            {
                method: 'GET',
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY,
                    'Authorization': token
                }

            }).then(response => {

                if (response.status == 200) {

                    return response.json();
                }
                else if (response.status == 400) {
                    response = {}
                    response.message = "Invalid Search";
                }
                else if(response.status == 502){
                    response = {}
                    response.message = "Internal Server Error";
                }
                else {
                    response = {}
                    response.message = "Internal Server Error";
                }

                return response;

            }).catch(err => { return err });

    }

    CreateUblHeader(storeNo, itemDetail, barcode, token, displayName) {
        let itemDetailRequest= itemDetail
        if(!itemDetailRequest.itemClsGrpDescription){
            itemDetailRequest.itemClsGrpDescription = 'null'
        }

        return fetch(process.env.REACT_APP_API_BASE_ENDPOINT + process.env.REACT_APP_STORE + storeNo + "/ublHead",
            {
                method: 'POST',
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY,
                    'Authorization': token
                },
                body: JSON.stringify({
                    ublId: barcode,
                    ublOriginType: "1",
                    ublOriginId: storeNo,
                    despatchType: "1",
                    ublStatus: "C",
                    destType: "1",
            
                    employeeId: displayName,
                   
                    reasonCode: itemDetail.reasonCode,
                    comments: "null",
                   
                    itemId: itemDetail.itemNum.toString().replace(/\s/g, ''),
                    salesOrderNumber: itemDetail.salesOrderNumber.toString().replace(/\s/g, ''),
                    supplierOrderNo: itemDetail.supplierPO?itemDetail.supplierPO.toString().replace(/\s/g, ''):itemDetail.supplierPo.toString().replace(/\s/g, ''),
                    supplierName: itemDetail.supplier,
                    brand:itemDetail.brand,
                    returnOrderNum: itemDetail.returnOrderNum.toString().replace(/\s/g, ''),
                    itemDescr: itemDetail.itemDesc,
                    itemClassgroup: itemDetail.itemClsGrp.toString(),
                    retailPriceInclAmt: itemDetail.retailPrice.toString().replace(/[^0-9.-]+/g,""),
                    itemClassgroupDescr: itemDetailRequest.itemClsGrpDescription,
                    qtyShip: "1",
                    seqNo:itemDetail.id.toString()
                })

            }).then(response => {

                if (response.status == 200) {

                    return response.json();
                }
                else if (response.status == 400) {
                    response = {}
                    response.message = "Invalid input fields in request";
                    console.log('api error' + response)
                    console.log('api-error' +response.errorMessage)
                }
                else if(response.status == 502){
                    response = {}
                    response.message = "Internal Server Error";
                }
                else {
                    response = {}
                    response.message = "Internal Server Error";
                }

                return response;

            }).catch(err => { return err });

    }

    DetagItem(storeNo, UBLId, reason,detagcomments, token ){
        
        return fetch(process.env.REACT_APP_API_BASE_ENDPOINT + process.env.REACT_APP_STORE + storeNo + "/ublHead",
            {
                method: 'DELETE',
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY,
                    'Authorization': token
                },
                body: JSON.stringify({
                    ublId:UBLId,
                    reasonCode:reason,
                    comments:detagcomments==""?null:detagcomments                  
                })     

            }).then(response => {
                if (response.status == 200) {
                    return response.json();
                }
                else if (response.status == 400) {
                    response = {}
                    response.message = "Bad request";
                }
                else if(response.status == 502){
                    response = {}
                    response.message = "Internal Server Error";
                }
                else {
                    response = {}
                    response.message = "Internal Server Error";
                }
                return response;

            }).catch(err => { return err });
    }

    EditUblHeader(storeNo, old_UBLid, new_UBLid, token)
    {
        return fetch(process.env.REACT_APP_API_BASE_ENDPOINT + process.env.REACT_APP_STORE + storeNo + "/editUbl",
            {
                method: 'PUT',
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY,
                    'Authorization': token
                },
                body: JSON.stringify({
                    oldUblId:old_UBLid,
                    newUblId:new_UBLid,
                    ublOriginType:'1',
                    ublOriginId:storeNo                     
                })     

            }).then(response => {
                if (response.status == 200) {
                    return response.json();
                }
                else if (response.status == 400) {
                    response = {}
                    response.message = "Invalid input";
                }
                else if(response.status == 502){
                    response = {}
                    response.message = "Internal Server Error";
                }
                else {
                    response = {}
                    response.message = "Internal Server Error";
                }
                return response;

            }).catch(err => { return err });
    }
    ItemValidation(StoreNo,ProductNo,token)
    {
        return fetch(process.env.REACT_APP_ITEM_VALIDATION_API_BASE_ENDPOINT + process.env.REACT_APP_STORE + StoreNo + '/itemValidate?productId=' + ProductNo,
        {
            method: 'GET',
            headers: {
                'x-api-key': process.env.REACT_APP_ITEM_VALIDATION_API_KEY,
                'Authorization': token
            }

        }).then(response => {
            if (response.status == 200) {

                return response.json();
            }
            else if (response.status == 400) {
                response = {}
                response.message = "Invalid Search";
            }
            else if(response.status == 502){
                response = {}
                response.message = "Internal Server Error";
            }
            else {
                response = {}
                response.message = "Internal Server Error";
            }

            return response;

        }
        ).catch(err => {
            return err;

        });
    }

    getLocations() {
        return fetch(process.env.REACT_APP_LOCATION_SERVICE_API_ENDPOINT,
            {
                method: 'GET',
                headers: {
                    'x-api-key': process.env.REACT_APP_LOCATION_SERVICE_API_KEY,
                    //'Authorization': token
                }

            }).then(response => {

                if (response.status == 200) {

                    return response.json();
                }
                else if (response.status == 400) {
                    response = {}
                    response.message = "Invalid Search";
                }
                else if(response.status == 502){
                    response = {}
                    response.message = "Internal Server Error";
                }
                else {
                    response = {}
                    response.message = "Internal Server Error";
                }

                return response;

            }).catch(err => { return err });

    }
   

    
}
