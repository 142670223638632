import Header from "components/common/Header";
import LoadingIndicator from "components/common/LoadingIndicator";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import ConfigService from "../../services/ConfigService";
import { callLogoutURL } from "../Authentication/utilities";
import { Chart } from "react-google-charts";

const LandingPage = (props) => {
  console.log("LANDINGPAGE PROPS", props);
  var user =JSON.parse(localStorage.getItem("User"));
  const [state, setState] = useState({
    errorMsgPopup: "",
    menuToggle: true,
    showLandingMenu: false,
    searchMenu: true,
    dispatchStoreMenu: true,
    consolidatedDispatchMenu: true,
    createShipmentMenu: true,
    loggedInTime: user.loggedInTime,
  });

  let token = "";
  let userID = "";
  const configService = new ConfigService();
  // const user = localStorage.setItem(
  //   "User",
  //   JSON.stringify(props.location.state)
  // );

  const userName = user.userName;
  const storeNo = user.storeNo;
  const role = user.role;
  const storeName = user.storeName;
  const displayName = user.displayName;
  const menuAllowed = user.menuAllowed;
  const tempSearchMenu = user.tempSearchMenu;
  const tempCreateDispatchInStoreMenu =
    user.tempCreateDispatchInStoreMenu;
  const tempCreateConsolidatedDispatchMenu =
    user.tempCreateConsolidatedDispatchMenu;
  const tempCreateShipment = user.tempCreateShipment;
  const tempDC=user.tempDC;
  const tempDCT=user.tempDCT;
  const tempCreateItemPutAway = user.tempCreateItemPutAway;
  const loggedInTime = user.loggedInTime;

  const [timeoutDuration, setTimeoutDuration] = useState(1800000); //Default timeout to 30 minutes
  const [loader, setLoader] = useState(false);
  if(typeof window!='undefined'){
    console.log("listener",localStorage.getItem("access_token"));
    window.addEventListener("storage",function(e){
       if(localStorage.getItem('access_token') == "null"){
        callLogoutURL()
        return
      }
    })
  }
  useEffect(() => {
    if(localStorage.getItem('access_token') == "null"){
      callLogoutURL()
      return
    }
    console.log("useEffect");
    window.addEventListener("simulateMessage", textFromInterface);
    initialiseTriggerToSendMessage();

    if (menuAllowed === "Y") {
      console.log(
        "inside useEffect menu allowed",
        tempSearchMenu,
        tempCreateConsolidatedDispatchMenu
      );
      setState({
        showLandingMenu: true,
        searchMenu: tempSearchMenu,
        dispatchStoreMenu: tempCreateDispatchInStoreMenu,
        consolidatedDispatchMenu: tempCreateConsolidatedDispatchMenu,
        createShipmentMenu: tempCreateShipment,
        DC:tempDC,
        DCT:tempDCT
      });
      console.log(
        "inside useEffect menu allowed",
        state.searchMenu,
        state.consolidatedDispatchMenu
      );
    } else {
      console.log("inside useEffect menu not allowed", state.searchMenu);
      setState({
        showLandingMenu: false,
        errorMsgPopup:
          "The functionality is not available for the selected User role. Please contact a Store Leader.",
      });
      setLoader(false);
      $("#errorModalCenter1").modal();
    }
    callConfigService();
  }, []);
  const callConfigService = async() => {
    setLoader(true);
   await configService
      .config(storeNo, token)
      .then((response) => {
        if (
          response.ConfigDetails.message ===
          "Config details fetched successfully"
        ) {
          var configDetails = response.ConfigDetails.details;
          for (var i = 0; configDetails && i <= configDetails.length - 1; i++) {
            var href = configDetails[i].KEY_GROUP;
            if (href === "TIME_OUT") {

              setTimeoutDuration(1000 * parseInt(configDetails[i].VALUE));
              localStorage.setItem(
                "timeout",
                1000 * parseInt(configDetails[i].VALUE)
              );

            }
          }
        }
        
      })
      .catch((error) => {
       
        console.log(error);
      });
      await configService.piechartcount(storeNo,token,  !user.tempDC ? "D": "S").then((response)=>
      {
        let consen=response.notificationDetails.concessionDetails[0].concessionQty;
        let market=response.notificationDetails.mpDetails[0].mpQty;
        let consenpers=Math.round(((consen/(consen+market))*100));
        let marketpers=Math.round(((market/(consen+market))*100));

        if((consen+market)==0)
        {
          setOptions({...options,slices: [
            {
              color: "#eea668"
            },
            {
              color: "#8a7cd0"
            },
            {
              color: "transparent"
            }
          ]});
          setData([
            ["label", "Percentage"],
            ["0", 50],
            ["0", 50],
            ["",100],

          ]);


        }
        else{
          setOptions({...options,  slices: [
            {
              color: "#e68a38"
            },
            {
              color: "#433490"
            },
            {
              color: "transparent"
            }
          ]
        });


        
        setData([
          ["label", "Percentage"],
          [consenpers+"%  ("+consen+")", consen],
          [marketpers+"%  ("+market+")", market],
          ["",consen+market],
        ]);
      }
        

      }).catch((e)=>{console.log(e);
       

        setOptions({...options,slices: [
          {
            color: "#eea668"
          },
          {
            color: "#8a7cd0"
          },
          {
            color: "transparent"
          }
        ]});
        setData([
          ["label", "Percentage"],
          ["0", 50],
          ["0", 50],
          ["",100],

        ]);
      
      })
      setLoader(false);

  };

  const initialiseTriggerToSendMessage = () => {
    if (
      typeof window.AndroidInterface !== "undefined" &&
      window.AndroidInterface !== null
    ) {
      window.AndroidInterface.initialiseTriggerToSendMessage();
    }
  };

  const handleOnIdle = (event) => {
    console.log("user is idle", event);
    console.log("last active", getLastActiveTime());
    callLogoutURL();
  };

  const handleOnActive = (event) => {
    console.log("user is active", event);
    console.log("time remaining", getRemainingTime());
    handleReset();
    if(localStorage.getItem('access_token') == "null"){
      callLogoutURL()
      return
    }

  };

  const handleOnAction = (event) => {
    console.log("user did something", event);
    if(localStorage.getItem('access_token') == "null"){
      callLogoutURL()
      return
    }
  };

  const { getRemainingTime, getLastActiveTime, reset } = useIdleTimer({
    timeout: timeoutDuration,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    events:['mousemove','keydown','wheel','DOMMouseScroll','mousewheel','mousedown','touchstart','touchmove','MSPointerDown','MSPointerMove'],
    debounce: 500,
  });

  const handleReset = () => reset();

  useEffect(() => {
    window.addEventListener("scroll", handleOnActive);
    return () => window.removeEventListener("scroll", handleOnAction);
  }, []);

  const textFromInterface = (e) => {
    //userName = e.detail;
    //forceUpdate();
  };

  const hideStatus = () => {
    $("#multiCollapseExample3").collapse("toggle");
  };

  const logout = () => {
    callLogoutURL();
  };
  const navigateToOrderLookup = () => {
    console.log("navigateToOrderLookup is called");
    props.history.push("/OrderLookup");
  };
  const navigateToDCDespatch = () => {
    console.log("navigateToOrderLookup is called");
    props.history.push("/CreateDcDespatch");
  };
  const navigateToDispatch = () => {
    props.history.push("/CreateDespatchNew");
    console.log("navigateToDispatch is called");
  };
  const [data,setData] = useState([
    ["label", "Percentage"],
    ["", 25],
    ["", 25],
    ["", 50],
  ]);

  const navigateToDCItemTagging = () => {
    props.history.push("/DCCreateDespatchNew", {
      token: token,
      storeNo: storeNo,
      userID: userName,
      userName: userName,
      role: role,
      storeName: storeName,
      displayName: displayName,
      menuAllowed: "Y",
      tempSearchMenu: tempSearchMenu,
      tempDC: tempDC,
      tempDCT:tempDCT,
      tempCreateDispatchInStoreMenu: tempCreateDispatchInStoreMenu,
      tempCreateConsolidatedDispatchMenu: tempCreateConsolidatedDispatchMenu,
      tempCreateShipment: tempCreateShipment,
      loggedInTime: loggedInTime,
      timeoutDuration: timeoutDuration,
    });
    console.log("navigateToDispatch is called");
  };


  const navigateToShipment = () => {
    console.log("navigateToShipment is called");
    props.history.push("/CreateShipment", {
      token: token,
      storeNo: storeNo,
      userID: userName,
      userName: userName,
      role: role,
      storeName: storeName,
      displayName: displayName,
      menuAllowed: "Y",
      tempSearchMenu: tempSearchMenu,
      tempCreateDispatchInStoreMenu: tempCreateDispatchInStoreMenu,
      tempCreateConsolidatedDispatchMenu: tempCreateConsolidatedDispatchMenu,
      tempCreateShipment: tempCreateShipment,
      tempDC: tempDC,
      tempDCT:tempDCT,
      loggedInTime: loggedInTime,
      timeoutDuration: timeoutDuration,
    });
  };
  //props.history.push("/ConsolidateDispatch", props.location.state);

  const navigateToConsolidateDispatch = () => {
    props.history.push("/CreateCbl");
  };

  const navigateToDCReceipting = () => {
    props.history.push("/DCReceiptingUbl");
  };
  const navigateToDcputaway=()=>{
    props.history.push("/DcItemPutAway");
  }

  const [options,setOptions]= useState({
    title: "",
  
    slices: [
      {
        color: "#e68a38"
      },
      {
        color: "#433490"
      },
      {
        color: "transparent"
      }
    ],
    legend: "none",
    pieSliceText: "label",
    tooltip: {
      trigger: "none" ,
    
    },
    chartArea: {
      left: 0,
      top: 10,
      bottom:20,
      width: "100%",
      height: "100%"
    },
    backgroundColor:"transparent",
    pieStartAngle: -90
  });



const refresh=async(Newstore)=>{
  setLoader(true);
  user =JSON.parse(localStorage.getItem("User"));
  await configService
  .config(Newstore, token)
  .then((response) => {
    if (
      response.ConfigDetails.message ===
      "Config details fetched successfully"
    ) {
      var configDetails = response.ConfigDetails.details;
      for (var i = 0; configDetails && i <= configDetails.length - 1; i++) {
        var href = configDetails[i].KEY_GROUP;
        if (href === "TIME_OUT") {

          setTimeoutDuration(1000 * parseInt(configDetails[i].VALUE));
          localStorage.setItem(
            "timeout",
            1000 * parseInt(configDetails[i].VALUE)
          );

        }
      }
    }
  
  })
  .catch((error) => {
    
    console.log(error);
  });
  await configService.piechartcount(Newstore,token,  !user.tempDC ? "D": "S").then((response)=>
  {
    let consen=response.notificationDetails.concessionDetails[0].concessionQty;
    let market=response.notificationDetails.mpDetails[0].mpQty;
    let consenpers=Math.round(((consen/(consen+market))*100));
    let marketpers=Math.round(((market/(consen+market))*100));

    if((consen+market)==0)
    {
      setOptions({...options,slices: [
        {
          color: "#eea668"
        },
        {
          color: "#8a7cd0"
        },
        {
          color: "transparent"
        }
      ]});
      setData([
        ["label", "Percentage"],
        ["0", 50],
        ["0", 50],
        ["",100],

      ]);


    }
    else{
      setOptions({...options,  slices: [
        {
          color: "#e68a38"
        },
        {
          color: "#433490"
        },
        {
          color: "transparent"
        }
      ]
    });

    
    setData([
      ["label", "Percentage"],
      [consenpers+"%  ("+consen+")", consen],
      [marketpers+"%  ("+market+")", market],
      ["",consen+market],
    ]);
  }
    

  }).catch((e)=>{console.log(e);
       

    setOptions({...options,slices: [
      {
        color: "#eea668"
      },
      {
        color: "#8a7cd0"
      },
      {
        color: "transparent"
      }
    ]});
    setData([
      ["label", "Percentage"],
      ["0", 50],
      ["0", 50],
      ["",100],

    ]);
  
  })

  if(user.isSuperUser){
    setState({
      showLandingMenu: true,
      searchMenu: tempSearchMenu,
      dispatchStoreMenu: user.tempCreateDispatchInStoreMenu,
      consolidatedDispatchMenu: user.tempCreateConsolidatedDispatchMenu,
      createShipmentMenu: user.tempCreateShipment,
      DC: user.tempDC,
      DCT: tempDCT
    });
  }

  setLoader(false);
}


  return (
    <div id="landingPage" className="LandingPage layout-desktop">
      {/*main content*/}
      <div className="DashboardPage" id="dashboard-menu">
        <Header isLandingPage={true} refresh={refresh}/>       
        <main className="dashboard-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-sm-8 col-xs-8">
                <section className="dashboard-banner margin-dashboard-banner">
                  <div class="d-flex bd-highlight mb-3">
                    <div class="p-2 bd-highlight">
                      <section class="greetings-msg">
                        <h3>Hello, {userName}! </h3>
                        <h5> Welcome back to Returns Portal</h5>
                      </section>
                    </div>

                    <div class="ml-auto p-2 bd-highlight">
                      {" "}
                      <img
                        className="logo d-none d-sm-block"
                        src="./images/banner-img.png"
                        alt=""
                      />
                    </div>
                  </div>
                </section>
                {state.showLandingMenu == true ? (
                  <section className="dashboard-menu">
                    <div className="container">
                      <ul className="list-unstyled row">
                        <li
                          className="col-md-6 col-lg-6 "
                          hidden={state.searchMenu}
                          onClick={navigateToOrderLookup}
                        >
                          <section className="dashboard-menu-tile modal-dialog-centered">
                            <div
                              className="menu-tile"
                              id="SearchReturnOrder"
                             
                            >
                              <i className="main-menus">
                                <img
                                  className="icon img-fluid"
                                  src="./images/search-order.png"
                                  alt=""
                                />
                              </i>
                              Search Return Order
                            </div>
                          </section>
                        </li>
                        <li
                          className="col-md-6 col-lg-6 "
                          hidden={state.dispatchStoreMenu}
                          onClick={navigateToDispatch}
                        >
                          <section className="dashboard-menu-tile modal-dialog-centered">
                            <div
                              className="menu-tile"
                              id="Dispatch"
                           
                            >
                              <i className="main-menus">
                                <img
                                  className="icon"
                                  src="./images/create-dispatch.png"
                                  alt=""
                                />
                              </i>
                              Tag UBL
                            </div>
                          </section>
                        </li>
                        <li
                          className="col-md-6 col-lg-6 "
                          hidden={state.consolidatedDispatchMenu}
                          onClick={navigateToConsolidateDispatch}
                        >
                          <section className="dashboard-menu-tile modal-dialog-centered">
                            <div
                              className="menu-tile"
                              id="ConsolidateDispatch"
                             
                            >
                              <i className="main-menus">
                                <img
                                  className="icon"
                                  src="./images/create-consolidated.png"
                                  alt=""
                                />
                              </i>
                               Create CBL
                            </div>
                          </section>
                        </li>
                        <li
                          className="col-md-6 col-lg-6 "
                          hidden={state.createShipmentMenu}
                          onClick={navigateToShipment}
                        >
                          <section className="dashboard-menu-tile modal-dialog-centered">
                            <div
                              className="menu-tile"
                              id="Shipment"
                            
                            >
                              <i className="main-menus">
                                <img
                                  className="icon"
                                  src="./images/create-shipment.png"
                                  alt=""
                                />
                              </i>
                              Shipment
                            </div>
                          </section>
                        </li>
                        <li
                          className="col-md-6 col-lg-6 "
                          hidden={state.DC}
                          onClick={navigateToDCReceipting}
                        >
                          <section className="dashboard-menu-tile modal-dialog-centered">
                            <div
                              className="menu-tile"
                              id="Dispatch"
                            
                            >
                              <i className="main-menus">
                                <img
                                  className="icon"
                                  src="./images/create-dispatch.png"
                                  alt=""
                                />
                              </i>
                              DC Receipting
                            </div>
                          </section>
                        </li>
                        <li
                          className="col-md-6 col-lg-6 "
                          hidden={state.DC}
                          onClick={navigateToDcputaway}
                        >
                          <section className="dashboard-menu-tile modal-dialog-centered">
                            <div
                              className="menu-tile"
                              id="Dispatch"
                        
                            >
                              <i className="main-menus">
                                <img
                                  className="icon"
                                  src="./images/create-dispatch.png"
                                  alt=""
                                />
                              </i>
                              DC Item Putaway
                            </div>
                          </section>
                        </li>
                       
                        <li
                          className="col-md-6 col-lg-6 "
                          hidden={state.DC}
                          onClick={navigateToDCItemTagging}
                        >
                          <section className="dashboard-menu-tile modal-dialog-centered">
                            <div
                              className="menu-tile"
                              id="Dispatch"
                          
                            >
                              <i className="main-menus">
                                <img
                                  className="icon"
                                  src="./images/create-dispatch.png"
                                  alt=""
                                />
                              </i>
                              Tag UBL
                            </div>
                          </section>
                        </li>
                        <li
                          className="col-md-6 col-lg-6 "
                          hidden={state.DC}
                          onClick={navigateToDCDespatch}
                        >
                          <section className="dashboard-menu-tile modal-dialog-centered">
                            <div
                              className="menu-tile"
                              id="Dispatch"
                          
                            >
                              <i className="main-menus">
                                <img
                                  className="icon"
                                  src="./images/create-dispatch.png"
                                  alt=""
                                />
                              </i>
                              DC Despatch
                            </div>
                          </section>
                        </li>
                      </ul>
                    </div>
                  </section>
                ) : null}
              </div>
              <div className="col-md-4 col-sm-4 col-xs-4">
                <section className="dashboard-right text-center mb-3">
                  <img
                    style={{width:"100%"}}
                    className="logo img-fluid d-none d-sm-block"
                    src="./images/db-banner-right.png"
                    alt=""
                  />
                </section>

                <section className="notification-wrap d-none d-sm-block" style={{width:"100%"}}>

                  <div className="pie-cart-container ">
                    
                    <div className="pie-cart-header">
                    <h6 style={{fontFamily:'open_sanssemibold',fontSize:"16px"}}>Returns Summary</h6>
                    <span style={{fontSize:"13px"}}>Location: {user.storeNo+" "+user.storeName}</span>
                    </div>
                    <div
                    style={{
                      margin:"15px",
                      paddingLeft:"20px",
                      borderLeft:"15px solid #e68a38",
                      fontSize:"13px"
                    }}
                    >
                    Concession
                    </div>
                    <div
                    style={{
                      margin:"15px",
                      paddingLeft:"20px",
                      borderLeft:"15px solid #433490",
                      fontSize:"13px"

                    }}
                    >
                    Market Place
                    </div>
                  
                  <div style={{maxHeight:"410px",overflow:"hidden",paddingLeft:"10%",paddingRight:"10%",marginTop:"-200px",pointerEvents:"none"}}>
                <Chart
      chartType="PieChart"
      data={data}
      options={options}
      width={"100%"}
      height={"800px"}
    />
    </div>


    </div>
                </section>
                <div style={{height:"20px"}}>
                  </div>
              </div>
            </div>
          </div>
        </main>

        <footer className="footer mt-auto py-3">
          <div className="container">
            <span>© Copyright 1999-2021 Myer Pty Ltd. </span>
          </div>
        </footer>
      </div>

      {loader ? <LoadingIndicator /> : null}
      <div
        className="modal fade loginModal"
        id="errorModalCenter1"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="errorModalCenterTitle">
                Error
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>{state.errorMsgPopup}</div>
            </div>
            <div className="modal-footer bo-modalbtnftr">
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
