import { callLogoutURL } from "../components/Authentication/utilities";

export default class ReportsService {
  menuAccess(role, token) {
    return fetch(
      process.env.REACT_APP_API_BASE_ENDPOINT +
        process.env.REACT_APP_STORE +
        "return-menu?userRole=" +
        role +
        "&userFunction=" +
        process.env.REACT_APP_RETURNS_USER_FUNCTION,
      {
        method: "GET",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "x-token": token,
        },
      }
    )
      .then((response) => {
        // response only can be ok in range of 2XX
        if (response.ok) {
          // you can call response.json() here too if you want to return json
          return response.json();
        } else {
          //handle errors in the way you want to
          switch (response.status) {
            case 401:
              callLogoutURL();
            case 400:
            case 403:
              response = {};
              response.statusErrorMsg =
                "The functionality is not available for the selected User role. Please contact a Store Leader";
              return response;
            case 404:
              console.log("Object not found");
              response = {};
              response.statusErrorMsg =
                "The functionality is not available for the selected User role. Please contact a Store Leader";
              return response;
            case 500:
            case 502:
            case 503:
            case 504:
            case 505:
              console.log("Internal server error");
              response = {};
              response.statusErrorMsg =
                "The functionality is not available for the selected User role. Please contact a Store Leader";
              return response;
            default:
              console.log("Some error occured");
              response = {};
              response.statusErrorMsg =
                "The functionality is not available for the selected User role. Please contact a Store Leader";
              return response;
          }
        }
      })

      .catch(function (error) {
        return error;
      });
  }
}
