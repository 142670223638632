import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export const ExportXLSX = ({csvData, fileName, csvDataUntagged}) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToXLSX = (csvData, fileName, csvDataUntagged) => {
        //var excelBuffer;
        if(fileName === "New UBL"){
            const csvTagged = XLSX.utils.json_to_sheet(csvData);
            const csvUntagged = XLSX.utils.json_to_sheet(csvDataUntagged);
            const wb = { Sheets: { 'Items Not Tagged': csvUntagged, 'Items Tagged': csvTagged }, SheetNames: ['Items Not Tagged', 'Items Tagged'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});
            FileSaver.saveAs(data, fileName + fileExtension);
        } else {
            const ws = XLSX.utils.json_to_sheet(csvData);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});
            FileSaver.saveAs(data, fileName + fileExtension);
        }
        
        
    }

    return (
       
            <i onClick={(e) => exportToXLSX(csvData,fileName,csvDataUntagged)} >
                <img className="xlsImage" src="./images/table-icons/excel-icon.svg" alt="logo" />
            </i>
    
    )
}