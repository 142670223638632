import * as types from "./ActionTypes";

export const loadCblDestinationInPrgress = () => {
  return {
    type: types.LOAD_CBL_DESTINATION_DATA_IN_PROGRESS,
  };
};

export const loadCblDestinationSuccess = (cbl) => {
  return {
    type: types.LOAD_CBL_DESTINATION_DATA_SUCCESS,
    cbl,
  };
};

export const loadCblDestinationFailure = (error) => {
  return {
    type: types.LOAD_CBL_DESTINATION_DATA_FAILURE,
    error,
  };
};

export const addCblInPrgress = () => {
  return {
    type: types.ADD_CBL_IN_PROGRESS,
  };
};

export const addCblSuccess = (addCblMessage) => {
  return {
    type: types.ADD_CBL_SUCCESS,
    addCblMessage,
  };
};

export const addCblFailure = () => {
  return {
    type: types.ADD_CBL_FAILURE,
  };
};

export const getCublInPrgress = () => {
  return {
    type: types.GET_CUBL_IN_PROGRESS,
  };
};

export const getCublSuccess = (cblGetMessge) => {
  return {
    type: types.GET_CUBL_SUCCESS,
    cblGetMessge,
  };
};

export const getCublFailure = () => {
  return {
    type: types.GET_CUBL_FAILURE,
  };
};
export const deleteCublInPrgress = () => {
  return {
    type: types.DELETE_CUBL_IN_PROGRESS,
  };
};

export const deleteCublSuccess = (deleteCblMessage) => {
  return {
    type: types.DELETE_CUBL_SUCCESS,
    deleteCblMessage,
  };
};

export const deleteCublFailure = () => {
  return {
    type: types.DELETE_CUBL_FAILURE,
  };
};
