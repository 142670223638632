export default {
  ublData: {
    ubl: [],
    pending: false,
    error: null,
    viewUblSummary: null,
    modifyCbl: [],
    cublMapToUbl: "",
    editUblMessage: "",
    addUblMessage: "",
    deleteUblMessage: "",
  },
  cblData: {
    cbl: [],
    pending: false,
    error: null,
    editCblMessage: "",
    cblGetMessge: "",
    addCblMessage: "",
    deleteCblMessage: "",
  },
  dcItemPutAway: {
    dc_loc_valid: null,
    pending: false,
    error: null,
  },
  ublDetails: {
    LocUblIdValidationDetails: "",
    pending: false,
    error: null,
    putMessage: "",
  },
};
