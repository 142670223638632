import React, { useRef, useState } from "react";
import { callLogoutURL } from "../Authentication/utilities";
import $ from "jquery";
import { Toast } from "react-bootstrap";

const Header = (props) => {
  const user = JSON.parse(localStorage.getItem("User"));
  console.log(user);
  const [selectloc,setselectloc]=useState([]);
  const [updatescreen,setupdatscreen]=useState(false);
  const [dropdowntext,setdropdowntext]=useState("Select");
  const [toastmsg, settoastmsg] = useState("");
  const [itemTaggingToast, setItemTaggingToast] = useState(false);
  const focusref=useRef();
  const logout = () => {
    callLogoutURL();
  };

  const ChangeLocation=async()=>{
    try{
    if(parseInt(user.storeNo)==selectloc[0].value)
    {
      setdropdowntext("select");
      setselectloc([]);
      return;
    }
  }
  catch(e){
    console.log(e)
  }
  

    user.storeNo=selectloc[0].value.toString().padStart(3,"0");
    user.storeName=selectloc[0].storeName;
    if(user.isSuperUser){
      if(selectloc[0].isStore){
        user.tempCreateDispatchInStoreMenu = false;
        user.tempCreateConsolidatedDispatchMenu = false;
        user.tempCreateShipment = false;
        user.tempDC = true;
      } else {
        user.tempCreateDispatchInStoreMenu = true;
        user.tempCreateConsolidatedDispatchMenu = true;
        user.tempCreateShipment = true;
        user.tempDC = false;
      }
    }
    localStorage.setItem(
      "User",
      JSON.stringify(user));
      setupdatscreen(!updatescreen);
     await props.refresh(selectloc[0].value);
      settoastmsg("Location changed Successfully", setItemTaggingToast(true));
      setdropdowntext("select");
      setselectloc([]);





  }
  return (
    <>
    <header className="header-sticky">
      <div className="d-flex bd-highlight">
        {props && props.isLandingPage && props.isLandingPage === true ? (
          null
        ) : (<div className="bd-highlight">
          <button
            class="navbar-toggler d-md-none collapsed mt-9"
            type="button"
            id="dropdown-menu-call"
            data-toggle="collapse"
            data-target="#sidebarMenu"
            aria-controls="sidebarMenu"
            aria-expanded="false"
      
          >
           
  
            <span class="navbar-toggler-icon"></span>
          </button>{" "}
        </div>)}
        <div className="mr-auto p-2 bd-highlight">
          <img className={props && props.isLandingPage && props.isLandingPage === true ?
            "img-fluid" : "img-fluid d-none d-sm-block"} src="./images/Logo/logo-blk.png" alt="" />
        </div>

        <div className="bd-highlight ">
          <i className="main-menu-icon">
            <img className="icon" src="./images/bell-icon.svg" alt="" />
          </i>
        </div>

        <div className="bd-highlight" id ="dropdownProfile" >
          <div class="dropdown">
            <a
              className="btn  background-transparent text-decoration-none dropdown-profile user-profile-dropdown"
              href="#"
              role="button"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-expanded="false"
             
            >
              <div class="media text-left" >
                <img
                  className="user-icon"
                  src="./images/user-icon.png"
                  alt=""
                />
                <div class="media-body d-none d-sm-block ">
                  <h5 class="user-display-name">{user.userName}</h5>
                  <span class="user-role-span">{user.role}</span>
                </div>
              </div>
            </a>

            <div
              class="dropdown-menu user-profile-align"
              aria-labelledby="dropdownMenuLink"
              id="profileId"
            >
              <section className="user-profile p-3">
                <div className="mb-3 username-role">
                  <h5>{user.displayName} </h5>
                  <h6>{user.role}</h6>
                </div>

                <div class="d-flex bd-highlight mb-3 user-profile-store text-nowrap">
                  <div class="bd-highlight mr-1">
                    {!user.tempDC ? "DC:": "Store:"}
                    {" "}
                    <span>
                      {user.storeNo} - {user.storeName}
                    </span>
                  </div>
                  {props && props.isLandingPage && props.isLandingPage === true && !user.tempChangeLocation? ( <div class="ml-auto bd-highlight">
                  <a
                   style={{color:"#007bff",fontSize:"12px"}}
                 
                   role="button"
                  onClick={()=>$("#errorModalCenter_loc_change").modal()}
                  >Change Location</a>
                  </div>):null}
                </div>
                <div className="d-flex bd-highlight mb-3 user-profile-store" >
                  <span>
                    Version: <span>1.0.0.7</span>
                  </span>
                  <div class="ml-auto bd-highlight">
                  <span class="desktop-text">Desktop</span>
                    <span class="mobile-text">Mobile</span>
                </div>
                </div>

                <div className="mb-3 logged-time">
                  <h6>
                    Logged in <span>{user.loggedInTime}</span>
                  </h6>
                </div>

                
                <button
                  type="button"
                  class="btn btn-block btn-logout btn-sm "
                  onClick={logout}
                >
                  {" "}
                  <img
                    className="icon-logout"
                    src="./images/logout-line.svg"
                    alt=""
                  />
                  Logout
                </button>
              </section>
            </div>
          </div>
        </div>
      </div>
    </header>

    <div
        className="modal fade loginModal"
        id="errorModalCenter_loc_change"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content modal-radius">
            <div className="modal-header confirmation-header">
              <h5
                className="modal-title confirmation-style"
                id="errorModalCenterTitle"
              >
               Confirmation
              </h5>
              <button
                      type="button"
                      className="close text-white"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
            </div>
            <div className="modal-body text-align-centre font-weight-bold">
              <div>
                      

              <div class="row mb-3" id="changeLoc" style={{justifyContent:"center"}} >
                   <span class="p-2 mt-1 mr-2"   ref={focusref}>Location No  :</span>
                   <div class="manifest-dropdown">
                      <button
                        className="btn btn-secondary dropdown-search_loc "
                        type="button"
                        id="manifestOptions"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{
                          overflow:"hidden",
                          whiteSpace:"nowrap",
                          textOverflow:"ellipsis"
                        }}
                      
                      >
                        {dropdowntext}
                   
                      </button>
                      <div
                        className="dropdown-menu despatch-search-dropdown-align p-0"
                        aria-labelledby="manifestOptions"
                        style={{ marginTop: "-5px", zIndex: "11",maxHeight:"250px",width:"200px",overflowY:"auto" }}
                      >
                         {!user.tempChangeLocation?
                         
                         JSON.parse(localStorage.getItem("Return_location_list")).map((item,index)=>{
                           return  (<a
                          className="dropdown-item p10 fs12 fw6 cd"
                          onClick={() => 
                           { user.isSuperUser? setselectloc([{label: item.storeNo+"-"+item.storeName,value:item.storeNo,storeName:item.storeName,isStore:item.isStore}]) :
                            setselectloc([{label: item.storeNo+"-"+item.storeName,value:item.storeNo,storeName:item.storeName}])
                          setdropdowntext(item.storeNo+"-"+item.storeName);
                          }
                          
                          }
                        >
                        { item.storeNo+"-"+item.storeName}
                        </a>)
                        }
                        ):null} 
                        
                      </div>
                    </div>
                   {/* <MultiSelect
                      options=
                      {JSON.parse(localStorage.getItem("Return_location_list")).map((a,item)=>{return {label:a.storeNo+"-"+a.storeName,value:a.storeNo,storeName:a.storeName}})} // Options to display in the dropdown                 
                      value={selectloc}
                      onChange=
                      {(item)=>{
                        if(item.length>0)setselectloc([item[item.length-1]])
                    
                        
                                  
                        }}
                      disableSearch={true}
                      hasSelectAll={false}
                         
                    /> */}
                                       
                </div>    
                Would you like to Change to Selected Location?                
                     



              </div>
            </div>
            <div className="modal-footer bo-modalbtnftr bt-none">
            <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-no"
              >
               No
              </button>
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-yes"
                onClick={ChangeLocation}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>


      <div className="topCenter" hidden={!itemTaggingToast}>
        <Toast id="toast" show={itemTaggingToast} onClose={() => setItemTaggingToast(false)} delay={5000} autohide>
          <div className="toast__content">
            <div>
              <span class="vertical-toast-border"></span>
              <div className="tickmark" style={{ height: "100%" }}>
                <img className="tickmark" src="./images/tick-circle-icon.svg" alt="" />
              </div>
              <div className="pt-10" >
                <p className="toast__type">Success</p>
                <p className="toast__message">{toastmsg}</p>
              </div>
              <div>
                <button
                  type="button"
                  className="close toast-close"
                  data-dismiss="toast"
                  aria-label="Close"
                  onClick={() => setItemTaggingToast(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>
        </Toast>
      </div>


    </>
  );
};

export default Header;
