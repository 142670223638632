import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from "react-tooltip";
import * as constants from "../../constants";
import $ from "jquery";
import { Details, Opacity } from "@mui/icons-material";


const DCRetagDetag = ({ itemDetails, index, ...props }) => {

    const [barcodeValid, setBarcodeValidation] = useState(true);
    const [Barcode, setBarCode] = useState(itemDetails.ublId);
    const [edit, setEdit] = useState(true);
    const [reasonForDetag, setReasonForDetag] = useState('Remaining in Store - brand Partner request');
    const [retagActive, setRetagActive] = useState(true)
    const [barcodeInFocus,setBarcodeInFocus] = useState(false)
    const [detagcomments,setDetagcomments]=useState("")
    const onBarcodeChange = (event) => {
        setBarCode(event.target.value);
        var inputBarcode = event.target.value.toString().trim()
        setBarCode(inputBarcode.replace(/[^0-9\+]/g, ""))
        if (inputBarcode.length === 7 
            && !isNaN(inputBarcode) && inputBarcode > 0) {
            console.log("valid")
            setBarcodeValidation(true)
        } else if (inputBarcode.length == 0) {
            setBarcodeValidation(true)
        }
        else {
            setBarcodeValidation(false)
        }
    };

    const detagPopUp = (event) => {
        event.preventDefault();
        console.log('reasonForDetag', reasonForDetag)
        var modelname = "#myModal2" + index.toString()
        $(modelname).modal('hide')
        localStorage.setItem('detag_ubl', itemDetails.ublId)
        localStorage.setItem('detag_ubl_reason', reasonForDetag);
        localStorage.setItem('detag_ubl_index', index);
        localStorage.setItem('detag_comments',detagcomments);
        $(("#confirmDetagModel"+itemDetails.ublId)).modal();
    }

    const detagItemConfirmation = (event) => {

        $(("#confirmDetagModel"+itemDetails.ublId)).modal('hide')
        var modelname = "#myModal2" + index.toString()
        $(modelname).modal('hide')
        event.preventDefault();
        props.detagItem()
    }

    const onRetag = (event) => {
        var inputBarcode = Barcode;
       
        if (inputBarcode.length === 7 && inputBarcode > 0) {

            var modelname = "#myModal2" + index.toString()
            $(modelname).modal('hide')
            event.preventDefault();
            //$("#myModal2").modal('hide')
            window.removeEventListener('simulateScan', barcodeScanResult);
            props.onRetag(itemDetails.itemNum, index, Barcode, props.tagged)
            //setEdit(false);
        } else {
            event.preventDefault();
            setBarcodeValidation(false)
        }

    };

    const enableListener = (e) =>{
        console.log('listener' +index)
        //e.preventDefault()
        localStorage.setItem('infocusIndex',index)
        setBarcodeInFocus(true)
        initialiseTriggerToSetFocus(true)
        window.addEventListener('simulateScan', barcodeScanResult);  
        
      }
    
      const disableListener =(e) => {
        console.log('listenerremove' +index)
        //e.preventDefault()
        localStorage.setItem('infocusIndex',"null")
        setBarcodeInFocus(false)
        initialiseTriggerToSetFocus(false)
        window.removeEventListener('simulateScan', barcodeScanResult);     
      }

      const initialiseTriggerToSetFocus = (state) => {
        if (
          typeof window.AndroidInterface !== "undefined" &&
          window.AndroidInterface !== null
        ) {
          console.log("barcodescan initialiseTriggerToSetFocus web")
          window.AndroidInterface.initialiseTriggerToSetFocus(state);
        }
      };

      const barcodeScanResult=(e) =>{
        console.log("barcodescan in called", barcodeInFocus)
        console.log("barcodescan in called index", index + "   " + localStorage.getItem("infocusIndex"))
        if(localStorage.getItem("infocusIndex") == index) {
          setBarCode(e.detail.toString().trim())
            console.log("barcodeScanResult called" + e.detail)
            
            var inputBarcode = e.detail.toString().trim()
            if (inputBarcode.length === 7 && inputBarcode > 0) {
              console.log("barcodeScanResult called valid")
              setBarcodeValidation(true)
              window.removeEventListener('simulateScan', barcodeScanResult);
              var modelname = "#myModal2" + index.toString()
              $(modelname).modal('hide')
              props.onRetag(itemDetails.itemNum, index, inputBarcode, props.tagged)
            } else if (inputBarcode.length == 0) {
              console.log("barcodeScanResult called valid length zero")
              setBarcodeValidation(true)
            }
            else {
              console.log("barcodeScanResult called else")
              setBarcodeValidation(false)
            }
        } else {
          console.log('barcodescan in called index else'+props.index +"   " +localStorage.getItem("infocusIndex"))
          window.removeEventListener('simulateScan', barcodeScanResult);
        }
    
      }
    

    useEffect(() => {
        var modelname = "#myModal2" + index.toString()
        if ($(modelname).hasClass('show')) {
            console.log('visible')
        } else {
            console.log('invisible')
        }
    }, [Barcode])

    useEffect(() => {
        setDetagcomments("")
        console.log('useeffect retag', itemDetails.ublId)
        setBarCode(itemDetails.ublId);
        setEdit(false);
        setBarcodeValidation(true)
        setReasonForDetag('Remaining in Store - brand Partner request')
    
    }, [itemDetails, props.reset, props.flag]);


    return (
        <div>
            <div class="modal summary-modal right fade" id={"myModal2" + index} tabindex="-1" role="dialog" aria-labelledby="myModalLabel2">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="col-12 modal-header-despatch bgred d-flex">
                            <h4 class="modal-title-despatch fw5 f18 whitetext p10" id="myModalLabel2">Modify UBL</h4>
                            <button type="button" class="close modify-close-align" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" class="whitetext">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <ul class="nav nav-tabs modify-tabs modify-tabs-bg">
                                <li class="nav-item">
                                    <a class="nav-link modify-dispatch-tab active " data-toggle="tab" href="#retag" onClick={() => setRetagActive(true)}>Retag UBL</a>
                                </li>
                                <li class="nav-item ml-20">
                                    <a class="nav-link modify-dispatch-tab" data-toggle="tab" href="#detag" onClick={() => setRetagActive(false)}>Detag UBL</a>
                                </li>
                            </ul>
                            <div class="tab-content mrt14 cbl-container">
                                {retagActive === true ? (
                                    <div class="tab-pane container black-text active" id="retag">
                                        <div className=" card-padding ">
                                            <section className="card-parent-group position-relative">
                                                <div className="item-retag-card-content p-2">
                                                    {/* item id */}
                                                    <div className="d-flex mb-1 bd-highlight pb-item-num">
                                                        <div className="bd-highlight item-num">
                                                            Item #: <span>{itemDetails.itemNum}</span>
                                                        </div>
                                                    </div>



                                                    {/* Item name */}
                                                    <div className="d-flex bd-highlight mb-1" >
                                                        <div className="bd-highlight item-name" data-tip={itemDetails.itemDesc} data-for="registerTip_1">
                                                            {" "}
                                                            {itemDetails.itemDesc}
                                                           
                                                        </div>
                                                     
                                                    </div>


                                                    {/* Reason and qty*/}
                                                    <div className="d-flex bd-highlight mb-1" >
                                                        <div className="bd-highlight reason-code" >
                                                            Reason: <span className="pl-2" data-tip={itemDetails.reasonCode} data-for="reason">{itemDetails.reasonCode}
                                                            </span>
                                                  
                                                        </div>
                                                        <div className="ml-auto item-qty bd-highlight">
                                                            Qty to return: <span>{itemDetails.qty}</span>
                                                        </div>
                                                    </div>


                                                    {/* Item name */}
                                                    <div className="d-flex bd-highlight mb-1">
                                                        <div className="bd-highlight item-price">
                                                            {" "}
                                                            {itemDetails.retailPrice}{" "}
                                                        </div>
                                                    </div>

                                                    <hr />

                                                    {/* Item name */}

                                                    <form className="item-level-tag">
                                                        <div>
                                                            <div className="pb-15">
                                                                <input
                                                                    type="text"
                                                                    className="form-control lighten"
                                                                    placeholder="Enter Barcode"
                                                                    value={Barcode}
                                                                    onChange={e=>setBarCode(e.target.value.replace(/[^0-9\+]/g, ""))}
                                                                    onFocus={(e) => {setBarcodeValidation(true);enableListener(e)}}
                                                                    onBlur={(e) => {onBarcodeChange(e);disableListener(e)}}
                                                                 

                                                                />
                                                                <div className="d-flex position-absolute">
                                                                    <img src="./images/error-icon.svg" alt="" hidden={barcodeValid} />
                                                                    <div className="barcode-validation-failed-despatch" hidden={barcodeValid}>Invalid Barcode !</div>
                                                                </div>

                                                            </div>
                                                            <div className="text-align-centre">
                                                                <button
                                                                    id="itemtag"
                                                                    type="submit"
                                                                    className={"btn-item-tagged-edit w-3"}
                                                                    onClick={onRetag}
                                                                //disabled={!edit}
                                                                >
                                                                    Item Tag
                                                                </button>
                                                            </div>

                                                        </div>

                                                    </form>
                                                </div>
                                            </section>
                                        </div>

                                    </div>
                                ) : (
                                    <div class="tab-pane container black-text active" id="detag">
                                        <div className="card-padding ">
                                            <section className="card-parent-group position-relative">
                                                <div className="item-detag-card-content p-2">
                                                    {/* item id */}
                                                    <div className="d-flex mb-1 bd-highlight pb-item-num">
                                                        <div className="bd-highlight item-num">
                                                            Item #: <span>{itemDetails.itemNum}</span>
                                                        </div>
                                                    </div>



                                                    {/* Item name */}
                                                    <div className="d-flex bd-highlight mb-1" >
                                                        <div className="bd-highlight item-name" data-tip={itemDetails.itemDesc} data-for="registerTip_12" >
                                                            {" "}
                                                            {itemDetails.itemDesc}
                                                           
                                                        </div>
                                                       
                                                    </div>


                                                    {/* Reason and qty*/}
                                                    <div className="d-flex bd-highlight mb-1" >
                                                        <div className="bd-highlight reason-code" >
                                                            Reason: <span className="pl-2" data-tip={itemDetails.reasonCode} data-for="reason_12">{itemDetails.reasonCode}
                                                            </span>
                                                        </div>
                                                        <div className="ml-auto item-qty bd-highlight">
                                                            Qty to return: <span>{itemDetails.qty}</span>
                                                        </div>
                                                    </div>


                                                    {/* Item name */}
                                                    <div className="d-flex bd-highlight mb-1">
                                                        <div className="bd-highlight item-price">
                                                            {" "}
                                                            {itemDetails.retailPrice}{" "}
                                                        </div>
                                                    </div>

                                                    <hr />

                                                    {/* Item name */}

                                                    <form className="item-level-tag">
                                                        <div className="item-level-tag">
                                                            <div className="">
                                                                <input
                                                                    type="text"
                                                                    className="form-control lighten "
                                                                    placeholder="Enter Barcode"
                                                                    value={itemDetails.ublId}
                                                                    onChange={onBarcodeChange}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                            <div className="form-row mt-10  justify-content-center">
                                                                <p class="pt10 fw6 f14 pt-15" style={{width:"20%"}}>Reason</p>
                                                                <div class="dropdown ml-15" style={{width:"74%"}}>
                                                                    <button class="btn btn-secondary detag-dropdown w-100" style={{overflow:"hidden",
                                                                    textOverflow:"ellipsis",whiteSpace:"nowrap"}} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        {reasonForDetag}
                                                                    </button>
                                                                    <div class="dropdown-menu detag-dropdown-align mw-12 w-100" aria-labelledby="dropdownMenuButton">
                                                                        <a class="dropdown-item p10 fs12 fw6 cd" style={{whiteSpace:"initial"}}onClick={() => setReasonForDetag("Remaining in Store - brand Partner request")}>Remaining in Store - brand Partner request</a>
                                                                        <a class="dropdown-item p10 fs12 fw6 cd" style={{whiteSpace:"initial"}} onClick={() => setReasonForDetag("Broken item - Disposal")}>Broken item - Disposal</a>
                                                                    </div>
                                                                </div>

                                                                <p 
                                                                class="pt10 fw6 f14 pt-15  pt-3"
                                                                style={{width:"21%"}}
                                                                hidden={reasonForDetag!="Remaining in Store - brand Partner request"}
                                                                >Comments
                                                                </p>

                                                                <input 
                                                                class="ml-15 mt-2 form-control lighten"
                                                                hidden={reasonForDetag!="Remaining in Store - brand Partner request"}
                                                                style={{width:"73%",paddingLeft:"10px",background:"none",border:"1px solid rgba(0,0,0,.2)"}}
                                                                type="text" 
                                                                placeholder="Add Comments"
                                                                value={detagcomments} 
                                                                maxLength="50"
                                                                onChange={(e)=>setDetagcomments(e.target.value)}                                                               
                                                                />

                                                                <button
                                                                    className="item-detag-button "
                                                                    onClick={detagPopUp}
                                                                    disabled={
                                                                    reasonForDetag=="Remaining in Store - brand Partner request"?
                                                                    detagcomments==""?true:false
                                                                    :false}
                                       
                                                                >
                                                                    Item Detag
                                                                </button>




                                                            </div>
                                                        </div>

                                                    </form>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                )}</div>

                        </div>
                    </div>
                </div>
            </div>

            <div
                className="modal fade loginModal"
                id={"confirmDetagModel"+itemDetails.ublId}
                tabIndex="-1"
                role="dialog"
                aria-labelledby="errorModalCenterTitle"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-z" role="document">
                    <div className="modal-content modal-radius">
                        <div className={itemDetails.dcPutawayLocation ?" modal-header error-popup-header-bg":"modal-header confirmation-header"}>
                            <h5 className= "modal-title confirmation-style" id="errorModalCenterTitle">
                                {itemDetails.dcPutawayLocation ? "Error" : "Confirmation"}
                            </h5>

                        </div>
                        <div className="modal-body text-align-centre" >
                            <div>{(itemDetails.dcPutawayLocation ?"UBL added to putaway":
                             "Would you like to detag this item?")}</div>
                        </div>
                        <div className="modal-footer bo-modalbtnftr bt-none">

                            <button
                                data-dismiss="modal"
                                type="button"
                                className=" btn bo-btnprimary confirm-no"
                                hidden={itemDetails.dcPutawayLocation}
                            >
                                No
                            </button>
                            <button
                                data-dismiss="modal"
                                type="button"
                                className=" btn bo-btnprimary confirm-yes"
                                onClick={!(itemDetails.dcPutawayLocation) ? detagItemConfirmation : null}
                            >
                                {(itemDetails.dcPutawayLocation) ? "Ok" : "Yes"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DCRetagDetag;