import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingIndicator from "components/common/LoadingIndicator";
import React from "react";
function ViewCart({
  cart,
  handleChange,
  handleViewUblSummary,
  viewUblSummary,
  ...props
}) {
  return (
    <>
   
       {viewUblSummary.map((detail, detailIdx) => (
                  <div className="sur-list" key={detailIdx}>
                    <div>
                      <span>Item #: </span>
                      <span>{detail.itemId}</span>
                       <span>
                  {/*<input
                    type="checkbox"
                    onClick={onCheckboxClick}
                    checked={checked}
                  />*/}
                </span> 
                
                      <p>{detail.itemDescription}</p>
                      <p>{detail.supplierNo} | {detail.supplierName}</p>
                    </div>
                    <div className="sur-bc-view">
                      <p className="barcode-font"
                         style={{fontSize:"27px"}}
                       >{detail.ublId}</p>
                      <a
                        data-toggle="collapse"
                        href={"#collapseExample" + detail.ublId}
                        role="button"
                        aria-expanded="false"
                        data-placement="right"
                        aria-controls="collapseExample"
                        onClick={()=>{
                          props.setdetails([detail]);
                        }}
                      >
                        <i class="fas fa-eye"></i>View
                      </a>
                    </div>
                  </div>
                ))}
    </>
  );
}

export default ViewCart;
