import classnames from "classnames";
import React from "react";

const Pagination = ({ ...props }) => {
  const {
    className,
    data,
    currentPage,
    itemsPerPage,
    pageNumberLimit,
    minPageNumberLimit,
    maxPageNumberLimit,
    handleClick,
    handleNextBtn,
    handlePrevBtn,
  } = props;

  let pages = [];
  for (let i = 1; i <= Math.ceil(data.length / itemsPerPage); i++) {
    pages.push(i);
  }
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          className={classnames("pagination-item", {
            selected: Number(currentPage) == number,
          })}
          key={number}
          id={number}
          onClick={handleClick}
        >
          {number}
        </li>
      );
    }
  });

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextBtn}>&hellip;</li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevBtn}>&hellip;</li>;
  }
  return (
    <ul
      className={classnames("pagination-container", { [className]: className })}
    >
      {/* Left navigation arrow */}
      <li
        className={classnames("pagination-item", {
          disabled: Number(currentPage) === pages[0],
        })}
        onClick={handlePrevBtn}
      >
        <div className="arrow left" />
      </li>
      {pageDecrementBtn}
      {renderPageNumbers}
      {pageIncrementBtn}
      {/*  Right Navigation arrow */}
      <li
        className={classnames("pagination-item", {
          disabled: Number(currentPage) === pages[pages.length - 1],
        })}
        onClick={handleNextBtn}
      >
        <div className="arrow right" />
      </li>
    </ul>
  );
};

export default Pagination;
