import { callLogoutURL } from "../components/Authentication/utilities";

export default class ConfigService {
 

 Location(token,flag) {
  return fetch(
    process.env.REACT_APP_API_BASE_ENDPOINT +
      process.env.REACT_APP_STORE +
      "getDestinationConfigDet?storeDcId="+flag
     ,
    {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "x-token": token,
      },
    }
  )
    .then((response) => {
      // response only can be ok in range of 2XX
      if (response.ok) {
        // you can call response.json() here too if you want to return json
        return response.json();
      } else {
        //handle errors in the way you want to
        switch (response.status) {
          case 401:
            callLogoutURL();
          case 400:
          case 403:
            response = {};
            response.statusErrorMsg = "Config service not available";
            return response;
          case 404:
            console.log("Object not found");
            response = {};
            response.statusErrorMsg = "Config service not available";
            return response;
          case 500:
          case 502:
          case 503:
          case 504:
          case 505:
            console.log("Internal server error");
            response = {};
            response.statusErrorMsg = "Config service not available";
            return response;
          default:
            console.log("Some error occured");
            response = {};
            response.statusErrorMsg = "Config service not available";
            return response;
        }
      }
    })

    .catch(function (error) {
      return error;
    });
}
  config(storeNo, token) {
    return fetch(
      process.env.REACT_APP_API_BASE_ENDPOINT +
        process.env.REACT_APP_STORE +
        storeNo +
        process.env.REACT_APP_CONFIG,
      {
        method: "GET",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "x-token": token,
        },
      }
    )
      .then((response) => {
        // response only can be ok in range of 2XX
        if (response.ok) {
          // you can call response.json() here too if you want to return json
          return response.json();
        } else {
          //handle errors in the way you want to
          switch (response.status) {
            case 401:
              callLogoutURL();
            case 400:
            case 403:
              response = {};
              response.statusErrorMsg = "Config service not available";
              return response;
            case 404:
              console.log("Object not found");
              response = {};
              response.statusErrorMsg = "Config service not available";
              return response;
            case 500:
            case 502:
            case 503:
            case 504:
            case 505:
              console.log("Internal server error");
              response = {};
              response.statusErrorMsg = "Config service not available";
              return response;
            default:
              console.log("Some error occured");
              response = {};
              response.statusErrorMsg = "Config service not available";
              return response;
          }
        }
      })

      .catch(function (error) {
        return error;
      });
  }
  getDCID(DC_ID,token)
  {
    return fetch(
      process.env.REACT_APP_API_BASE_ENDPOINT +
        process.env.REACT_APP_DCID +
        DC_ID+"/mappedDcId",
      {
        method: "GET",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "x-token": token,
        },
      }
    )
      .then((response) => {
        // response only can be ok in range of 2XX
        if (response.ok) {
          // you can call response.json() here too if you want to return json
          return response.json();
        } else {
          //handle errors in the way you want to
          switch (response.status) {
            case 401:
              callLogoutURL();
            case 400:
            case 403:
              response = {};
              response.statusErrorMsg = "Config service not available";
              return response;
            case 404:
              console.log("Object not found");
              response = {};
              response.statusErrorMsg = "Config service not available";
              return response;
            case 500:
            case 502:
            case 503:
            case 504:
            case 505:
              console.log("Internal server error");
              response = {};
              response.statusErrorMsg = "Config service not available";
              return response;
            default:
              console.log("Some error occured");
              response = {};
              response.statusErrorMsg = "Config service not available";
              return response;
          }
        }
      })

      .catch(function (error) {
        return error;
      });

  }

  piechartcount(storeNo, token,notification) {
    return fetch(
      process.env.REACT_APP_API_BASE_ENDPOINT +
        process.env.REACT_APP_STORE +
        storeNo +"/notificationDetails?notificationType="+notification
       ,
      {
        method: "GET",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "x-token": token,
        },
      }
    )
      .then((response) => {
        // response only can be ok in range of 2XX
        if (response.ok) {
          // you can call response.json() here too if you want to return json
          return response.json();
        } else {
          //handle errors in the way you want to
          switch (response.status) {
            case 401:
              callLogoutURL();
            case 400:
            case 403:
              response = {};
              response.statusErrorMsg = "Config service not available";
              return response;
            case 404:
              console.log("Object not found");
              response = {};
              response.statusErrorMsg = "Config service not available";
              return response;
            case 500:
            case 502:
            case 503:
            case 504:
            case 505:
              console.log("Internal server error");
              response = {};
              response.statusErrorMsg = "Config service not available";
              return response;
            default:
              console.log("Some error occured");
              response = {};
              response.statusErrorMsg = "Config service not available";
              return response;
          }
        }
      })

      .catch(function (error) {
        return error;
      });
  }



}
