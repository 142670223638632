import Header from "components/common/Header";
import Sidebar from "components/common/Sidebar";
import $ from "jquery";
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import * as constants from "../constants";
import data from "./data";
import LoadingIndicator from "components/common/LoadingIndicator";
import EditCreateDespatch from "./EditCreateDespatch";
import CreateShipment from "services/CreateShipmentService";
import DcLocationService from "services/DcLocationService";
import { useIdleTimer } from "react-idle-timer";
import { callLogoutURL } from "../Authentication/utilities";
import CreateDespatchCartModify from "components/CreateDespatch/CreateDespatchModify/CreateDespatchCartModify";
import { getUblDetails } from "redux/thunks";
const CreateDcDespatch = ({ ...props }) => {
  const user = JSON.parse(localStorage.getItem("User"));
  const [invalidUblNum, setInvalidUblNum] = useState(false);
  const [displayItems, setDisplayItems] = useState(false);
  const [carrierForm, showCarrierForm] = useState(false);
  const [input, setInput] = useState({
    ublId: "",
  });
  const [ublItemId, setUblItemId] = useState({
    ublItemId: "",
  });
  const [carrierAPIFailed, setCarrierApiFailed]= useState(false)
  const [ublToDelete, setUblToDelete] = useState("")
  const [ubltoDeleteIndex, setUblToDeleteIndex] = useState("")
  const scannedUBLRef=useRef([]);
  // const [toggleNoItemsFound, setToggleNoItemsFound] = useState(false);
  const [carrierlist, setcarrierlist] = useState([]);
  const [carrierId, setCarrierId] = useState("")
  const [scannedUBL, setScannedUBL] = useState([]);
  const [resetInput, setResetInput] = useState(true);
  const resetInputRef = useRef(false)
  const ScannedInputRef =useRef();
  const [despatchNumber, setDespatchNumber] = useState("")
  const despatchNumberRef = useRef()
  const [carrierRefNo, setCarrierRefNo] = useState("")
  const [showloader, setshowloader] = useState(false);
  const [carrierName, setcarrierName] = useState("");
  const createShipment = new CreateShipment();
  const [fresh, setfresh] = useState(true);
  const [currentSupplierName, setCurrentSupplierName] = useState("")
  const [currentSupplierNo, setCurrentSupplierNo] = useState("")
  const currentSupplierNoRef = useRef()
  const [cartonBox, setCartonBox] = useState([])
  const [errorMessage, setErrorMessage] = useState()
  const [errorMessageViewManifest, setMessageViewManifest] = useState("")
  const [viewManifestDespatchID,setviewManifestDespatchID] = useState("");
  const [search, setSearch] = useState(false);
  const [apConNo, setAPConNo] = useState("")
  const [weight, setWeight] = useState("")
  const [timeoutDuration] = useState(localStorage.getItem('timeout') ? localStorage.getItem('timeout') : 1800000);
  const getsupplierShelfLocation = async () => {
    /* API CALL TO GET SUPPLIER SHELF LOCATION */
  };
  const getScannedItemsByNumber = async () => {
    /* API CALL TO GET SCANNED ITEMS BY NUMBER */
  };

  const hanldeAddToDespatch = async () => {
    /* API CALL TO ADD DESPATCH ITEMS TO SHELF LOCATION */
  };

  // useEffect(()=>{

  // },[cartonBox])

  if (typeof window != 'undefined') {
    console.log("listener", localStorage.getItem("access_token"));
    window.addEventListener("storage", function (e) {
      if (localStorage.getItem('access_token') == "null") {
        callLogoutURL()
        return
      }
    })
  }

  useEffect(async () => {
    let flag = 0;
    console.log(props);
    setshowloader(true);

    await createShipment.CarrierDetails(user.token).then((response) => {
      if(response && response.message == "Internal Server Error"){
        setErrorMessage("Server error while fetching carrier details")
        setCarrierApiFailed(true)
        $("#errorModalApiError").modal();
        return
      }
      let res = response.return_carrier_response.details;
      let carriername = [];
      setcarrierName(res[1].CARRIER_NAME);
      setCarrierId(res[1].CARRIER_ID)
      for (let i = 0; i < res.length; i++)
        carriername.push(res[i]);

      setcarrierlist(carriername);
      console.log("carrier name ")
      setCarrierApiFailed(false)
    }).catch(err => console.log(err));
    setshowloader(false);

  }, [fresh]);

  const getUBLDetails = (scannedBarcode) => {
    console.log("getUBLDetails",input.ublId)
    console.log("getUBLDetails",scannedBarcode);
    setshowloader(true);
    try{
      ScannedInputRef.current.blur();
    } catch(e){
      console.log("onblur error",e)
    }
    
    DcLocationService.getUblSummary(user.storeNo, scannedBarcode ? scannedBarcode : input.ublId, user.token)
      .then((response) => {
  
        console.log("getUBLDetails",response)
        if (response.UblSummaryDetails.details) {
          if (response.UblSummaryDetails.details[0].ublStatus == "RD" || response.UblSummaryDetails.details[0].ublStatus == "SD") {
            if (response.UblSummaryDetails.details[0].dcShipId != null && response.UblSummaryDetails.details[0].dcShipId == despatchNumber) {
              setErrorMessage("Scanned UBL -" + input.ublId + " is already part of current Despatch")
            } else {
              setErrorMessage("Scanned UBL -" + input.ublId + " already part of another DC despatch (" + response.UblSummaryDetails.details[0].dcShipId + ")")
            }
            setshowloader(false);
            $("#errorModalApiError").modal();
          }
          else if(response.UblSummaryDetails.details[0].dcShipId != null)
        {
          setErrorMessage("UBL already Despatched")
          setshowloader(false);
          $("#errorModalApiError").modal();

        }
          else if (response.UblSummaryDetails.details[0].ublStatus == "M") {

            setErrorMessage("Sorry,UBL Flagged as Missing")
            setshowloader(false);
            $("#errorModalApiError").modal();

          }
          else if (response.UblSummaryDetails.details[0].ublStatus == "X") {
            setErrorMessage("Sorry,UBL is in Detagged status")
            setshowloader(false);
            $("#errorModalApiError").modal();

          }
          // else if (response.UblSummaryDetails.details[0].ublStatus == "C" || response.UblSummaryDetails.details[0].ublStatus == "R") {   
          else if (response.UblSummaryDetails.details[0].locationId != null) {
            //  if(response.UblSummaryDetails.details[0].ublStatus == "C" &&  parseInt(response.UblSummaryDetails.details[0].storeId) != parseInt(user.storeNo)){
            //     setErrorMessage("UBL Id Not Found")
            //     $("#errorModalApiError").modal();
            //     setshowloader(false);
            //     return
            //   }

            let tempItemDetail = response.UblSummaryDetails.details[0]
            tempItemDetail.despatchNumber = despatchNumber
            if (currentSupplierNo || currentSupplierNoRef.current) {
              if (currentSupplierNo === tempItemDetail.supplierNo || currentSupplierNoRef.current === tempItemDetail.supplierNo) {
                addUBLShipmentDC(tempItemDetail)
              } else {
                $("#errorModal1").modal();
              }
            } else {
              getDespatchNumber(tempItemDetail, "Y")

              //addUBLShipmentDC(tempItemDetail)
            }
            console.log("scannedUBL", scannedUBL[0].itemDescription)
          } else {
            //setshowloader(false);
            setErrorMessage("UBL status doesn't allow despatch")
            $("#errorModalApiError").modal();
          }

        }
        else {
          //setshowloader(false);
          setErrorMessage("UBL Summary details not found")
          $("#errorModalApiError").modal();
        }

        setshowloader(false)
      }
      ).catch(err => {
        console.log(err);
        console.log("loader1")
      })


  }

  const getDespatchNumber = (itemDetails, despatchRequestType) => {
    setshowloader(true)
    DcLocationService.despatchSupplier(user.storeNo, itemDetails, user.token, despatchRequestType, despatchNumber ? [despatchNumber] : [], user.displayName, user.storeName)
      .then((response) => {
        if (response && response.despatchSupplier.details.length > 0) {
          if (despatchRequestType == "N") {
            setshowloader(false)
            setviewManifestDespatchID(despatchNumber);
            setMessageViewManifest("Despatched Successfully (" + despatchNumber + ")")
            navigateToMainPage()
            $("#ViewManifestCreateNewModal").modal()
            return
          }
          setDespatchNumber(response.despatchSupplier.details.toString());
          despatchNumberRef.current = response.despatchSupplier.details.toString()
          itemDetails.despatchNumber = response.despatchSupplier.details.toString()
          // dcShipmentHeader(itemDetails)
          addUBLShipmentDC(itemDetails)

        } else if (response && response.dcShipment.message == "Shipment Details not found in config table") {
          setshowloader(false);
          setErrorMessage("Shipment Details not found in config table")
          $("#errorModalApiError").modal();
        } else if (response && response.message == "Invalid input fields in request") {
          setshowloader(false);
          setErrorMessage("Invalid input fields in request")
          $("#errorModalApiError").modal();
          console.log("called2")
          // addUBLShipmentDC(itemDetails)

        }
      }).catch(err => {
        console.log(err);
        // addUBLShipmentDC(itemDetails)
        setshowloader(false)
      })
  }

  const dcShipmentHeader = (itemDetails) => {
    console.log("loader2")
    setshowloader(true)
    DcLocationService.dcShipmentHeader(user.storeNo, itemDetails, user.token, user.displayName)
      .then((response) => {
        if (response && response.dcShipment.message == "DC Shipment Header created Successfully") {
          addUBLShipmentDC(itemDetails)
        } else if (response && response.dcShipment.message == "Shipment Details not found in config table") {
          setshowloader(false);
          setErrorMessage("Shipment Details not found in config table")
          $("#errorModalApiError").modal();
        } else if (response && response.message == "Invalid input fields in request") {
          setshowloader(false);
          setErrorMessage("Invalid input fields in request")
          $("#errorModalApiError").modal();
          console.log("called2")
          // addUBLShipmentDC(itemDetails)

        }
      }).catch(err => {
        console.log(err);
        // addUBLShipmentDC(itemDetails)
        showloader(false)
      })
  }

  const createDespatch = () => {
    console.log("createDespatch called to get despatch number")
    getDespatchNumber(scannedUBL[0], "N")
  }

  const addUBLShipmentDC = (itemDetails) => {
    setshowloader(true)
    DcLocationService.addUBLShipmentDC(user.storeNo, itemDetails.despatchNumber, itemDetails.ublId, user.token, user.displayName)
      .then((response) => {
        try{
          ScannedInputRef.current.focus()
        } catch(e){
          console.log("focus error", e)
        }
       
        if (response && response.shipment.message == "UBL Headers updated for DC") {
          //let arr = [...scannedUBL]
          let temp = scannedUBLRef.current
          temp =  [itemDetails].concat(temp)
          //const newArr = [itemDetails].concat(scannedUBL);
         // arr.push(itemDetails)
          scannedUBLRef.current = temp
          setScannedUBL(temp)
          setCurrentSupplierName(itemDetails.supplierName)
          setCurrentSupplierNo(itemDetails.supplierNo)
          currentSupplierNoRef.current = itemDetails.supplierNo
          setSearch(true);
          //setToggleNoItemsFound(true);
          setshowloader(false);
          setResetInput(!resetInput)
          let reset = resetInputRef.current
          resetInputRef.current = !reset
          console.log("resetInput",resetInputRef.current)
          setInput({...input,ublId:""})
          //setUblItemId({...ublItemId, ublItemId : ""})
        } else if (response && response.shipment.message == "UBL Id Not Found") {
          setshowloader(false);
          setErrorMessage("UBL Id Not Found")
          $("#errorModalApiError").modal();
        } else if (response && response.shipment.message == "Scanned UBL does not belong to this supplier") {
          setshowloader(false);
          $("#errorModal1").modal();
        }

      }).catch(err => { console.log(err); setshowloader(false); })

  }

  const setupCartonBoxDetails = () => {
    setInput({ ublId: "" })
    console.log("carton", cartonBox.length)
  }

  const confirmDelete = (barcode, itemIndex) => {
    setUblToDelete(barcode)
    setUblToDeleteIndex(itemIndex)
    $("#exampleModalCenter").modal()
  }

  const removeUblFromShipmentDC = () => {
    setshowloader(true);
    DcLocationService.removeUblFromShipmentDC(user.storeNo, ublToDelete, user.token,despatchNumber,
      scannedUBL.length == 1?"Y":"N",scannedUBL[ubltoDeleteIndex].storeId==parseInt(user.storeNo)?"C":"R")
      .then((response) => {
        console.log("response", response)
        if (response && response.updateRemoveUblFromShipmentDC.message == "UBL Details removed from Shipment") {
          // cartonBox[cartonIndex].splice(itemIndex, 1)
          // setCartonBox(cartonBox)
          let arr = [...scannedUBL]

          if (scannedUBL.length == 1) {
            console.log("arr length equal one")
            $("#EditCreateDespatch").modal('hide');
            arr.splice(ubltoDeleteIndex, 1)
            console.log(arr.length)
          } else {
            arr.splice(ubltoDeleteIndex, 1)
          }
          if (arr.length > 0) {
            console.log("arr length greater zero")
            
            setScannedUBL(arr)
            scannedUBLRef.current = arr
          } else {
            console.log("arr length lesser zero")
            setCartonBox([])
            setScannedUBL([])
            scannedUBLRef.current = []
            setDisplayItems(false)
            setDespatchNumber("")
            despatchNumberRef.current = ""
            setCurrentSupplierNo("")
            currentSupplierNoRef.current = ""
            setCurrentSupplierName("")
            setSearch(false)
          }
          setshowloader(false);
          setErrorMessage("UBL -" + ublToDelete + " deleted successfully")
          $("#errorModalApiError").modal();
        } else if (response && response.updateRemoveUblFromShipmentDC.message == "UBL details not found") {
          setshowloader(false);
          setErrorMessage("UBL details not found")
          $("#errorModalApiError").modal();

        } else if (response && response.updateRemoveUblFromShipmentDC.message == "No UBL Id exist") {
          setshowloader(false);
          setErrorMessage("No UBL Id exist")
          $("#errorModalApiError").modal();
        }
      }).catch(err => { console.log(err); setshowloader(false); })
    //setshowloader(false);
  }

  const addUblToCarton = (barcode, despatchNumber, index) => {
   console.log("addUblToCarton", barcode, despatchNumber, index)
    setshowloader(true);
    try{
      ScannedInputRef.current.blur();
    } catch(e){
      console.log("onblur error",e)
    }
    DcLocationService.getUblSummary(user.storeNo, barcode, user.token).then((response) => {
      if (response.UblSummaryDetails.details) {
        console.log("hello inside case")
        if (response.UblSummaryDetails.details[0].ublStatus == "RD" || response.UblSummaryDetails.details[0].ublStatus == "SD") {
          if (response.UblSummaryDetails.details[0].dcShipId != null && response.UblSummaryDetails.details[0].dcShipId == despatchNumber) {
            setErrorMessage("Scanned UBL - " + barcode + " is already part of current Despatch")
          } else {
            setErrorMessage("Scanned UBL - " + barcode + " already part of another DC despatch (" + response.UblSummaryDetails.details[0].dcShipId + ")")
          }
          setshowloader(false);
          $("#errorModalApiError").modal();
        }
        else if(response.UblSummaryDetails.details[0].dcShipId != null)
        {
          setErrorMessage("UBL already Despatched")
          setshowloader(false);
          $("#errorModalApiError").modal();

        }

        else if (response.UblSummaryDetails.details[0].ublStatus == "M") {

          setErrorMessage("Sorry,UBL Flagged as Missing")
          setshowloader(false);
          $("#errorModalApiError").modal();

        }
        else if (response.UblSummaryDetails.details[0].ublStatus == "X") {
          setErrorMessage("Sorry,UBL is in Detagged status")
          setshowloader(false);
          $("#errorModalApiError").modal();

        }
        //else if (response.UblSummaryDetails.details[0].ublStatus == "C" || response.UblSummaryDetails.details[0].ublStatus == "R") {
        else if (response.UblSummaryDetails.details[0].locationId != null) {
          // if(response.UblSummaryDetails.details[0].ublStatus == "C" &&  response.UblSummaryDetails.details[0].storeId != user.storeNo){
          //   console.log("call2")
          //   setErrorMessage("UBL Id Not Found")
          //   $("#errorModalApiError").modal();
          //   setshowloader(false);
          //   return
          // }
          let tempItemDetail = response.UblSummaryDetails.details[0]
          tempItemDetail.despatchNumber = despatchNumber
          if (currentSupplierNo === tempItemDetail.supplierNo || currentSupplierNoRef.current === tempItemDetail.supplierNo) {
            DcLocationService.addUBLShipmentDC(user.storeNo, despatchNumber, tempItemDetail.ublId, user.token, user.displayName)
              .then((response) => {
                try{
                  ScannedInputRef.current.focus()
                } catch(e){
                  console.log("focus error", e)
                }
                if (response && response.shipment.message == "UBL Headers updated for DC") {
                  console.log("added if")
                  // const newArr = [...scannedUBL]
                  // newArr.push(tempItemDetail)
                  let temp = scannedUBLRef.current
                 // const newArr = [tempItemDetail].concat(scannedUBL);
                  temp = [tempItemDetail].concat(temp)
                  //console.log("newArr", newArr)
                  setScannedUBL(temp)
                  scannedUBLRef.current = temp
                  setshowloader(false);
                  setResetInput(!resetInput)
                  let reset = resetInputRef.current
                  resetInputRef.current = !reset
                  console.log("resetInput",resetInputRef.current)
                  setInput({...input,ublId:""})
                } else if (response && response.shipment.message == "UBL Id Not Found") {
                  setErrorMessage("UBL Id Not Found")
                  $("#errorModalApiError").modal();
                  setshowloader(false);
                } else if (response && response.shipment.message == "Scanned UBL does not belong to this supplier") {
                  setshowloader(false);
                  $("#errorModal1").modal();
                } else {
                  setshowloader(false);
                }
              }).catch(err => { console.log(err); setshowloader(false); console.log("catch add")})
          } else {
            setshowloader(false);
            setInput({ ublId: barcode })
            $("#errorModal1").modal();
          }
        } else {
          setshowloader(false);
          setErrorMessage("UBL status doesn't allow despatch")
          $("#errorModalApiError").modal();
        }
      }
      else {
        console.log("hello else case")
        setshowloader(false);
      }
    }
    ).catch(err => { console.log(err); setshowloader(false); console.log("added catch ubl") })
  }

  const validateCarrierRef = (event) => {
    console.log(isCarrierFormInComplete())
    var regex = /^[A-Za-z0-9]*$/;
    if (regex.test(event.target.value)) {
      setCarrierRefNo(event.target.value)
      if (event.target.value.length >= 10) {
        setAPConNo(event.target.value.toString().slice(0, 10))
      } else {
        setAPConNo("")
      }
    }

  }

  const validateAPConNo = (event) => {
    var regex = /^[A-Za-z0-9]*$/;
    if (regex.test(event.target.value))
      setAPConNo(event.target.value)
  }

  const validateWeight = (event) => {
    console.log("validateWeight", event.target.value)
    console.log("")
    var t = event.target.value;
    var input = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
    if (!isNaN(input)) {
      console.log("validateWeight if", input)
      setWeight(input)
    } else {
      //setWeight("")
      console.log("validateWeight", input)
    }

    console.log("isCarrierFormInComplete", isCarrierFormInComplete)

  }

  const navigateToViewManifest = () => {
    console.log(despatchNumber);
    localStorage.setItem("DespatchId-viewManifestscreen",viewManifestDespatchID);
    props.history.push("/ViewDcDespatchManifest");
  }

  const handleOnchange = (e) => {
    const inputBarcode = e.target.value.replace(/[^\w\s]/gi, "");
    setInput({
      ...input,
      [e.target.name]: inputBarcode,
    });
    if (
      inputBarcode.length === 7 &&
      !isNaN(inputBarcode) &&
      inputBarcode > 0
    ) {
      setInvalidUblNum(false);
    } else {
      setInvalidUblNum(true);
    }
  };

  const updateCarrierDetails = (isDraftClicked) => {
    setshowloader(true)
  
    DcLocationService.updateCarrierDetails(user.storeNo, user.token, despatchNumber, carrierRefNo.length >= 10?carrierRefNo:null, carrierId, carrierName, 
      (weight.toString().length > 0 && parseFloat(weight) > 0)?weight:null, apConNo.length==10?apConNo:null)
      .then((response) => {
        if (response && response.updateCarrierDetailsDC && response.updateCarrierDetailsDC.message == "Carrier Details Updated Successfully for the ShipId Found") {
          if (isDraftClicked) {
            console.log("isdraftclicked")
            setshowloader(false)
            setviewManifestDespatchID(despatchNumber);
            setMessageViewManifest("Draft Saved Successfully (" + despatchNumber + ")")
            navigateToMainPage()
            $("#ViewManifestCreateNewModal").modal()
          } else {
            console.log("isdraftNotclicked")
            createDespatch()
          }
        } else if (response && response.updateCarrierDetailsDC  && response.updateCarrierDetailsDC.ship_notFound && response.updateCarrierDetailsDC.ship_notFound.length > 0){
          if(response.updateCarrierDetailsDC.ship_notFound.length < 4){
            let temptext;
            response.updateCarrierDetailsDC.ship_notFound.forEach(element => {
              temptext = temptext ? temptext + "," + element  : element
            });
            setErrorMessage("Carrier Name mismatch found for "+ temptext)
          } else {
            setErrorMessage("Carrier Name mismatch for multiple despatches")
          }       
          $("#errorModalApiError").modal();
           setshowloader(false)
          return
        } else if (response && response.updateCarrierDetailsDC && response.updateCarrierDetailsDC.message == "Details Not Found") {
          setshowloader(false)
          setErrorMessage("Details Not Found")
          $("#errorModalApiError").modal();
        } else if (response && response.message == "Invalid input fields in request") {
          setshowloader(false)
          setErrorMessage("Invalid input fields in request")
          $("#errorModalApiError").modal();
        } else if (response && response.message == "Internal Server Error") {
          setshowloader(false)
          setErrorMessage("Internal Server Error")
          $("#errorModalApiError").modal();
        } else {
          setshowloader(false)
        }
      }).catch((e) => {
        console.log(e);
        setshowloader(false)
      })

  }

  const navigateToMainPage = () => {
    setDisplayItems(false)
    setSearch(false)
    setCartonBox([])
    setScannedUBL([])
    scannedUBLRef.current = []
    showCarrierForm(false)
    setDespatchNumber("")
    despatchNumberRef.current = ""
    setCurrentSupplierNo("")
    currentSupplierNoRef.current = ""
    setCurrentSupplierName("")
    setWeight("")
    setAPConNo("")
    setCarrierRefNo("")
    //setfresh((prev) => !prev)
  }

  const onDraftClicked = () => {
    console.log("onDraftClicked", carrierRefNo)
    console.log("onDraftClicked", weight)
    console.log("onDraftClicked", apConNo)
    // (carrierRefNo.length < 10 || (weight.toString().length == 0 || parseFloat(weight) <= 0) || apConNo.length < 10)
    if (carrierRefNo.length>=10 || (weight.toString().length > 0 && parseFloat(weight) > 0)) {
      console.log("inside if")
      updateCarrierDetails(true)
    } else {
      setviewManifestDespatchID(despatchNumber);
      setMessageViewManifest("Draft Saved Successfully (" + despatchNumber + ")")
      navigateToMainPage()
      //setMessageViewManifest("Draft Saved Successfully")
      $("#ViewManifestCreateNewModal").modal()
    }
  }

  const onCreateDespatchClicked = () => {
    updateCarrierDetails(false)
  }

  const isCarrierFormInComplete = () => {
    console.log("carrierRefNo", carrierRefNo)
    console.log("carrierRefNo", weight)
    console.log("carrierRefNo", apConNo)
    return (carrierRefNo.length < 10 || (weight.toString().length == 0 || parseFloat(weight) <= 0) || apConNo.length < 10)
  }


  const handleOnIdle = (event) => {
    console.log("user is idle", event);
    console.log("last active", getLastActiveTime());
    callLogoutURL();
  };

  const handleOnActive = (event) => {
    console.log("user is active", event);
    console.log("time remaining", getRemainingTime());
    handleReset();
    if (localStorage.getItem('access_token') == "null") {
      callLogoutURL()
      return
    }

  };

  const handleOnAction = (event) => {
    console.log("user did something", event);
    if (localStorage.getItem('access_token') == "null") {
      callLogoutURL()
      return
    }
  };

  const { getRemainingTime, getLastActiveTime, reset } = useIdleTimer({
    timeout: timeoutDuration,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });

  const handleReset = () => reset();

  useEffect(() => {
    console.log("exampleModalCenter",$('#exampleModalCenter').hasClass('show'))
    if (localStorage.getItem('access_token') == "null") {
      callLogoutURL()
      return
    }
    window.addEventListener("scroll", handleOnActive);
    return () => window.removeEventListener("scroll", handleOnAction);
  }, []);


  useEffect(() => {
    console.log("getScannedDataInDevice added");
    if (input.ublId === "") setInvalidUblNum(true);
    window.addEventListener("getScannedDataInDevice", getScannedDataInDevice);
    return () => {
      console.log("getScannedDataInDevice removed");
      window.removeEventListener(
        "getScannedDataInDevice",
        getScannedDataInDevice
      );
    };
  }, []);

  const getScannedDataInDevice = (data) => {
    if (!carrierForm) {
      let inputBarcode = data.detail.toString().trim();
      console.log("getScannedDataInDevice called and inputbarcode", inputBarcode);
      console.log("getScannedDataInDevice called and input.ublid", input.ublId);
      if (
        inputBarcode.length === 7 &&
        !isNaN(inputBarcode) &&
        inputBarcode > 0
      ) {
        setInvalidUblNum(false);
        if (scannedUBL.length > 0 || scannedUBLRef.current.length > 0) {
          console.log("getScannedDataInDevice addubltocarton called")
          addUblToCarton(inputBarcode, despatchNumberRef.current,"")
        } else {
          getUBLDetails(inputBarcode)
        }
      } else {
        setInvalidUblNum(true);
      }
    }


  };

  return (
    <div className="OrderLookup bg-grey" id="dcdespatch_component">
      <Header />
      <div className="container-fluid">
        <div className="row height-100">
          <Sidebar active="CreateDcDespatch" state={user} />
          <main
            role="main"
            className="col-md-9 ml-sm-auto col-lg-10 rtn-main-wrapper p-0 bg-white"
            id="DCReceipting"
          >
            {/* page title section 1 */}
            <section className="dcreceipting-header">
              <p> DC Despatch - Create </p>
              <div
                class="d-none d-lg-block d-md-block"
                style={{
                  float: "right",
                  marginTop: "-40px"
                }}>
                {/* {<Breadcrumb crumbs={crumbs} selected={selected} />} */}
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb" style={{ fontSize: "12px" }}>
                    <Link
                      to={{ pathname: "/LandingPage", state: user }}
                      className="breadcrumb-item breadcrumb-color pr-10"
                    >
                      Home
                    </Link>
                    <span className="pr-10"> {" >"}</span>
                    <li
                      className="breadcrumb-item active"
                      aria-current="page"
                    >
                      Create Despatch
                    </li>
                  </ol>
                </nav>
              </div>
            </section>
            {/* Search panel for searching UBl Number section 2 */}
            {!displayItems && (
              <section>
                <div className="media">
                  <img
                    src="./images/despatch-icon.svg"
                    alt="despatch"
                    className="m-2"
                  />

                  <div className="media-body">
                    <h5 className="mt-3 bd-highlight dc-despatch-item-label">
                      DC Despatch Number
                    </h5>
                    <p>{despatchNumber}</p>
                  </div>
                </div>
              </section>
            )}

            {carrierForm && (
              <section className="search-panel col-md-6 mx-auto mt-3 mb-3">
                <div className="putaway-input-section mb-20">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      alert("dfjdk");
                    }}
                  >
                    <div className="form-group row">
                      <label
                        for="despatchNumber"
                        className="col-sm-3 col-form-label"
                      >
                        Despatch Number
                      </label>
                      <div className="col-sm-9">
                        <div className="dcr-input-icon-container">
                          <input className="input-disable" type="text"
                            //placeholder="AP Con No"
                            value={despatchNumber}
                            //onChange={validateAPConNo}
                            disabled={true} />
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label for="supplier" className="col-sm-3 col-form-label">
                        Carrier Name
                      </label>
                      {/* <div className="col-sm-9">
                        <select class="form-control" id="carrierName">
                          <option>Australia Post</option>
                        </select>
                      </div> */}
                      <div class="col-sm-9">
                        <div class="dropdown Select-form ">
                          <button class=" carrier-dc-despatch"

                            type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {carrierName}
                          </button>
                          <div class="dropdown-menu despatch-search-dropdown-align "
                            style={{ width: "100%", overflowY: "auto", maxHeight: "300px", height: "max-content" }}
                            aria-labelledby="dropdownMenuButton">
                            {carrierlist.map((item, index) => {
                              return (
                                <a class="dropdown-item p10 fs12 fw6 cd"
                                  onClick={() => {
                                    setCarrierId(item.CARRIER_ID)
                                    setcarrierName(item.CARRIER_NAME)
                                  }}>{item.CARRIER_NAME}</a>
                              );
                            })}

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        for="despatchNumber"
                        className="col-sm-3 col-form-label"
                      >
                        Carrier Reference Number (AP Article ID)
                      </label>
                      <div className="col-sm-9">
                        <div className="dcr-input-icon-container">
                          <input
                            type="text"
                            placeholder="Carrier Reference No"
                            required
                            value={carrierRefNo}
                            onChange={validateCarrierRef}
                          />
                          <img
                            class="dcr-dispatch-scanner-img"
                            src="./images/barcodescanner.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        for="despatchNumber"
                        className="col-sm-3 col-form-label"
                      >
                        AP Con No
                      </label>
                      <div className="col-sm-9">
                        <div className="dcr-input-icon-container">
                          <input className="input-disable" type="text" placeholder="AP Con No"
                            value={apConNo}
                            onChange={validateAPConNo}
                            disabled={true} />
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        for="despatchNumber"
                        className="col-sm-3 col-form-label"
                      >
                        Enter Weight
                      </label>
                      <div className="col-sm-9">
                        <div className="dcr-input-icon-container weight">
                          <input
                            type="text"
                            placeholder="Enter Weight"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            value={weight}
                            onChange={validateWeight}
                          />
                          <span class="input-group-text" id="basic-addon2">
                            Kg
                          </span>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </section>
            )}

            {/* Search panel for searching UBl Number section 3 */}
            {!displayItems && (
              <section className="search-panel mb-2 putaway-input-section">
                <form
                  className="form-inline"
                  onSubmit={(event) => {
                    event.preventDefault();
                    let inputBarcode = input.ublId;
                    if (inputBarcode.length === 7 && !isNaN(inputBarcode) && inputBarcode > 0) {
                      setInvalidUblNum(false);
                    }
                    else {
                      setInvalidUblNum(true);
                      return;
                    }
                    getUBLDetails()
                  }}
                >
                  <label className="my-1 mr-3" for="ublId">
                    <strong>Scan/Enter UBL</strong>
                  </label>
                  <div className="input-group advanced-search">
                    <input
                      id="ublId"
                      autocomplete="off"
                      type="text"
                      required
                      className="form-control input-search-dcitem dc-location"
                      name="ublId"
                      placeholder="Scan or Enter UBL"
                      aria-label="Text input with dropdown button"
                      value={input.ublId}
                      ref={ScannedInputRef}
                     
                      onBlur={handleOnchange}
                      onChange={(e) => {
                        const inputBarcode = e.target.value.replace(/[^0-9\+]/g, "");
                        setInput({
                          ...input,
                          [e.target.name]: inputBarcode,
                        });
                        setInvalidUblNum(false);
                      }}
                      maxLength={20}
                    />
                  </div>

                  {invalidUblNum && input.ublId !== "" ? (
                    <div className="invalid-ubl-num">
                      <img src="./images/error-icon.svg" alt="" />
                      Invalid Barcode!
                    </div>
                  ) : null}
                </form>
              </section>
            )}

            {/* Scanned results for CBl Copy it from DcDespatch copy.js*/}

            {!displayItems && search && (
              <section className="scanned-ubl-results">
                <div className="sur-header">
                  <p>Scanned UBLs</p>
                </div>
                <div className="rtn-card-tiles">
                  <div className="container">
                    <div className="row">
                      <>
                        {scannedUBL.map((detail_1, index) => {
                          return (
                            <div className="col-md-4">
                              <div className="sur-list" key={index}>
                                <div>
                                  <span className="tile-item">Item #:</span>
                                  <span>{detail_1.itemId}</span>

                                  <p className="bd-highlight item-name mb-1">
                                    {detail_1.itemDescription}
                                  </p>
                                  <p className="ro-supname">
                                    {detail_1.supplierNo} |
                                    {detail_1.supplierName}
                                  </p>
                                </div>
                                <div className="container">
                                  <div className="row sur-bc-view d-flex bd-highlight mt-3 p-0 m-0">
                                    <div className="p-2 col-4 bd-highlight">
                                      <p className="barcode-font barcode-font-size">
                                        {detail_1.ublId}
                                      </p>
                                    </div>
                                    <div class="p-2 col-8 bd-highlight mt-0">
                                      <label className="bd-highlight brand mb-0">
                                        {/* {detail_1.BRAND} */}
                                        BRAND
                                      </label>
                                      <p className="brand-desc">
                                        {detail_1.brand}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    </div>
                  </div>
                </div>
              </section>
            )}

            {/* SECTION TO DISPLAY ALL UBLS AFTER CLICKING ON PROCEED BUTTON*/}
            {!carrierForm && displayItems && (
              <div className="container">
                <div className="row">
                  <div className="col-md-4 col-sm-12 col-xs-12">
                    <div className="m-1 ubl-cards-col">
                      <div className="d-flex bd-highlight">
                        <div className="flex-shrink-1 p-2 bd-highlight bg-secondary modal-dialog-centered carton-box-number-border">
                          {/* Figure tag starts */}
                          <figure classNameName="ubl-card-image">
                            <img
                              classNameName="img-responsive ml-10"
                              src="./images/bi_box-icon.svg"
                              alt=""
                            />
                            <figcaption className="m-1 ubl-card-index">
                              {" "}
                              {despatchNumber.toString().trim().slice(-2)}
                            </figcaption>
                          </figure>
                        </div>
                        <div className="w-100 bd-highlight p-3">
                          <div className="d-flex mb-2 bd-highlight mb-1 ">
                            <div className="bd-highlight">
                              <div className="brand"> DC Despatch Number </div>

                              <p className="mt-2 mb-2 supplier-details">
                                {despatchNumber}
                              </p>
                            </div>

                            <div className="ml-auto view-det-link bd-highlight">
                              <a
                                data-toggle="collapse"
                                role="button"
                                aria-expanded="false"
                                data-placement="right"
                                aria-controls="EditCreateDespatch"
                                onClick={() => {
                                  $("#EditCreateDespatch").modal();
                                }}
                              >
                                <img
                                  src="./images/edit-icon.svg"
                                  alt="edit"
                                  className="m-2"
                                />
                                &nbsp;Edit
                              </a>
                            </div>
                          </div>

                          {/* Item name */}
                          <div className="d-flex bd-highlight mb-2">
                            <div className="bd-highlight number-of-ubl">
                              No. of UBL
                            </div>
                            <div className="ubl-count">
                              {scannedUBL.length}
                            </div>
                          </div>
                          <hr />
                          {/* Supplier*/}
                          <div className="d-flex bd-highlight">
                            <div className="bd-highlight brand">
                              Supplier:
                              <p className="mt-2 mb-0 supplier-details">
                                {currentSupplierNo} |
                                {currentSupplierName}
                              </p>
                            </div>
                          </div>

                          <div className="d-flex bd-highlight">
                            <div className="bd-highlight mb-0 supplier-details">

                              {scannedUBL.length > 0 ? (
                                (scannedUBL[0].addLine1 ? scannedUBL[0].addLine1+",": "")+(scannedUBL[0].addLine2 ? scannedUBL[0].addLine2+"," : "") + (scannedUBL[0].city ? scannedUBL[0].city+"," : "")+scannedUBL[0].state+"-"+scannedUBL[0].postCode) : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <EditCreateDespatch itemDetails={detail_1} index={index} addUblToCarton={addUblToCarton} showloader={showloader} removeUblFromShipmentDC={removeUblFromShipmentDC} /> */
                    }
                    {scannedUBL.length > 0 ? <EditCreateDespatch itemDetails={scannedUBL} addUblToCarton={addUblToCarton} showloader={showloader} removeUblFromShipmentDC={confirmDelete} resetInput ={resetInput} resetInputRef = {resetInputRef}/> : null}
                  </div>

                </div>
              </div>
            )}

            {/* No items found message */}

            {/* Confirmation UBL Modal */}
            <div
              className="modal fade modal-transparent"
              id="2"
              tabindex="-1"
              role="dialog"
              id="errorModal1"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content dcr-modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Alert</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body dcr-modal-confirmation scanned-ubl-modal-content">
                    <div className="scanned-ubl">
                      <div className="mr-5">Scanned</div>
                      <div className="fw-600">UBL - {input.ublId} </div>
                    </div> <br />
                    <div className="c-red">
                      does not belong to this Supplier
                    </div>
                    <br />
                    <div className="fw-600 mt-7">
                      {currentSupplierNo + "|" + currentSupplierName}
                    </div>
                  </div>
                  <div className="modal-footer border-none">
                    <button
                      type="button"
                      className="btn bgred whitetext ctbtn "
                      data-dismiss="modal"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade loginModal modal-transparent"
              id="exampleModalCenter"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="errorModalCenterTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-z"
                role="document"
              >
                <div className="modal-content modal-radius">
                  <div className="modal-header confirmation-header">
                    <h5
                      className="modal-title confirmation-style"
                      id="errorModalCenterTitle"
                    >
                      Confirmation
                    </h5>
                  </div>
                  <div className="modal-body text-align-centre">
                    <div>
                      {" "}
                      Would you like to remove this <b>UBL# {ublToDelete}</b>?{" "}
                    </div>
                  </div>
                  <div className="modal-footer bo-modalbtnftr bt-none">
                    <button
                      data-dismiss="modal"
                      type="button"
                      className=" btn bo-btnprimary confirm-no"
                    >
                      No
                    </button>
                    <button
                      data-dismiss="modal"
                      type="button"
                      className=" btn bo-btnprimary confirm-yes"
                      onClick={(e) =>
                        removeUblFromShipmentDC()
                        
                      }
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="ViewManifestCreateNewModal"
              tabindex="-1"
              role="dialog"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content dcr-modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Success</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="tickmark-dc-despatch" >
                    <img src="./images/tick-circle-icon.svg" alt="" />
                  </div>
                  <div className="draft-modal-confirmation">
                    {errorMessageViewManifest}

                  </div>
                  <div className="modal-footer border-none">
                    
                    {!errorMessageViewManifest.includes("Draft Saved Successfully")?
                      <button
                      type="button"
                      className="btn bgred whitetext ctbtn-white-bg "
                      data-dismiss="modal"
                      onClick={navigateToViewManifest}
                    >
                      View Manifest
                    </button>:null
                    }
                    <button
                      type="button"
                      className="btn bgred whitetext ctbtn "
                      data-dismiss="modal"
                    >
                      Create New
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade modal-transparent"
              id="errorModalApiError"
              tabindex="-1"
              role="dialog"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content dcr-modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Alert</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body dcr-modal-confirmation">
                    {errorMessage}
                  </div>
                  <div className="modal-footer border-none">
                    <button
                      type="button"
                      className="btn bgred whitetext ctbtn "
                      data-dismiss="modal"
                      onClick={()=>{
                        if(carrierAPIFailed){
                          setfresh(!fresh)
                        }
                      }}
                    >
                      {carrierAPIFailed ? "Try Again" : "OK"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* BOTTOM Section to Dispaly Buttons*/}

            {/* Back and Complete Receiving buttons */}
            <div className="container-fluid section-four footer-position">
              <div className="float-right">

                {/* <Link
                  to={
                    !carrierForm
                      ? { pathname: "/LandingPage", state: user }
                      : { pathname: "/ModifyDcDespatch", state: user }
                  }
                  className="cancel-btn fs12"
                > */}
                {/* {!carrierForm ? "Back" : "Save as Draft"} */}
                {!carrierForm ? (
                  <button className="cancel-btn fs12" onClick={() => {
                    setDisplayItems(false)
                    setSearch(false)
                  }} disabled={true}>
                  </button>
                ) : (
                  <button className="cancel-btn fs12" onClick={() => {
                    onDraftClicked()
                  }}>
                    Save as Draft</button>
                )}
                {/* </Link> */}
                {!displayItems ? (
                  <button
                    type="button"
                    className="add-carrier-btn next-button fs12"
                    onClick={() => {
                      // $("#collapseExample" + 1).modal();
                      setDisplayItems(!displayItems);
                      setupCartonBoxDetails()
                    }}
                    disabled={!search}
                    style={{
                      opacity: !search ? "0.5" : "1",
                    }}
                  >
                    Proceed
                  </button>
                ) : carrierForm && search ? (
                  <button
                    type="button"
                    className="add-carrier-btn next-button fs12"
                    onClick={() => {
                      onCreateDespatchClicked()
                    }}
                    disabled={(carrierRefNo.length < 10 || (weight.length == 0 || parseFloat(weight) <= 0) || apConNo.length < 10)}
                    style={{
                      opacity: (carrierRefNo.length < 10 || (weight.length == 0 || parseFloat(weight) <= 0) || apConNo.length < 10) ? "0.5" : "1",
                    }}
                  >
                    Despatch
                  </button>
                ) : !carrierForm && search ? (
                  <button
                    type="button"
                    className="add-carrier-btn next-button fs12"
                    onClick={() => {
                      // $("#collapseExample" + 1).modal();
                      showCarrierForm(!carrierForm);
                    }}
                  >
                    Add Carrier
                  </button>
                ) : null}
              </div>
            </div>
          </main>
        </div>
      </div>
      {showloader ? <LoadingIndicator /> : null}
    </div>
  );
};

export default CreateDcDespatch;
