import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { viewUblSummary } from "redux/thunks";
const ViewCollapseDetails = ({ viewUbl, viewUblSummary, ...props }) => {
  const user = JSON.parse(localStorage.getItem("User"));
  const [loader, setLoader] = useState(false);
  console.log("VIEWUBLCOLLAPSE", props.index);
  useEffect(() => {
    console.log("USEEFFECT CALLED");
    console.log(props.index, user.storNo, user.tokn);
    viewSummary();
  }, [props.index]);
  const viewSummary = async () => {
    setLoader(true);
    await viewUblSummary(user.storeNo, props.index, user.token);
    setLoader(false);
  };
  const renderData = (viewUbl) => {
    return (
      <>
        {viewUbl &&
          viewUbl.map((detail_1) => {
            return (
              <div
                className="card-ublslide collapse"
                style={{ borderRadius: "15px" }}
                id={"collapseExample" + props.index}
              >
                <div className="container">
                  <div
                    className="row"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "80px",
                      backgroundColor: "#fe4001",
                    }}
                  >
                    <div className="col-6" style={{ color: "white" }}>
                      View Summary
                    </div>
                    <div
                      className="col-6 close-modal"
                      data-toggle="collapse"
                      href={"#collapseExample" + props.index}
                      role="button"
                      aria-expanded="false"
                      data-placement="right"
                      aria-controls="collapseExample"
                      id="ViewDetails"
                    ></div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="card">
                        <div className="card-details">
                          <h5>Supplier</h5>
                          <h6>{detail_1.storeId}</h6>
                        </div>
                        <div className="card-details">
                          <h5>Supplier Invoice #</h5>
                          <h6>{detail_1.returnOrderNo}</h6>
                        </div>
                        <div className="card-details">
                          <h5>Supplier PO </h5>
                          <h6>{detail_1.itemId}</h6>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-details">
                          <h5>Return Order #</h5>
                          <h6>{detail_1.qty}</h6>
                        </div>
                        <div className="card-details">
                          <h5>Return Created by</h5>
                          <h6>{detail_1.brand}</h6>
                        </div>
                        <div className="card-details">
                          <h5>Return Notes field</h5>
                          <h6>Return notes goes here</h6>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-details">
                          <h5>MP Ship Node</h5>
                          <h6>99000463 BMP Australia PTY Ltd</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="card">
                        <div className="card-details">
                          <h5>Item Class Group</h5>
                          <h6>{detail_1.supplierName}</h6>
                        </div>
                        <div className="card-details">
                          <h5>Item TPC</h5>
                          <h6>{detail_1.ublId}</h6>
                        </div>
                        <div className="card-details">
                          <h5>Brand</h5>
                          <h6></h6>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-details">
                          <h5>Return Location</h5>
                          <h6>{detail_1.supplierPo}</h6>
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-details">
                          <h5>Item's Destination</h5>
                          <h6>{detail_1.returnLocation}</h6>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-details">
                          <h5>Sales/Customer Order #</h5>
                          <h6>{detail_1.storeId}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </>
    );
  };
  return <>{renderData(viewUbl)}</>;
};
const mapStateToProps = (state, ownProps) => {
  return {
    viewUbl: state.ubl.viewUblSummary,
  };
};
const mapDispatchToProps = {
  viewUblSummary,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewCollapseDetails);
