import React from "react";

const Checkbox = ({ id, name, type, handleChange, select }) => {
  return (
    <input
      type={type}
      name={name}
      id={id}
      onChange={handleChange}
      checked={select}
    />
  );
};

export default Checkbox;
