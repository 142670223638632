import * as types from "redux/actions/ActionTypes";
import initialState from "redux/reducers/initialState";

export default function CblReducer(state = initialState.cblData, action) {
  switch (action.type) {
    case types.LOAD_CBL_DESTINATION_DATA_IN_PROGRESS:
      return {
        ...state,
        pending: true,
      };
    case types.LOAD_CBL_DESTINATION_DATA_SUCCESS:
      return {
        ...state,
        pending: false,
        cbl: action.cbl,
      };
    case types.LOAD_CBL_DESTINATION_DATA_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error,
      };

    case types.ADD_CBL_IN_PROGRESS:
      return true;
    case types.ADD_CBL_SUCCESS:
      return {
        ...state,
        addCblMessage: action.addCblMessage,
      };
    case types.ADD_CBL_FAILURE:
      return false;

    case types.GET_CUBL_IN_PROGRESS:
      return true;
    case types.GET_CUBL_SUCCESS:
      return {
        ...state,
        cblGetMessge: action.cblGetMessge,
      };
    case types.GET_CUBL_FAILURE:
      return false;

    case types.DELETE_CUBL_IN_PROGRESS:
      return true;
    case types.DELETE_CUBL_SUCCESS:
      return {
        ...state,
        deleteCblMessage: action.deleteCblMessage,
      };
    case types.DELETE_CUBL_FAILURE:
      return false;
    default:
      return state;
  }
}
