import * as types from "redux/actions/ActionTypes";
import initialState from "redux/reducers/initialState";

export default function UblDetailsReducer(
  state = initialState.ublDetails,
  action
) {
  switch (action.type) {
    case types.GET_UBL_DETAILS_IN_PROGRESS:
      return {
        ...state,
        pending: true,
      };
    case types.GET_UBL_DETAILS_SUCCESS:
      return {
        ...state,
        pending: false,
        LocUblIdValidationDetails: action.LocUblIdValidationDetails,
      };
    case types.GET_UBL_DETAILS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case types.PUT_UBL_DETAILS_TO_DC_LOCATION_IN_PROGRESS:
      return {
        ...state,
        pending: true,
      };
    case types.PUT_UBL_DETAILS_TO_DC_LOCATION_SUCCESS:
      return {
        ...state,
        putMessage: action.putMessage,
      };
    case types.PUT_UBL_DETAILS_TO_DC_LOCATION_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error,
      };

    default:
      return state;
  }
}
