import React from "react";
import moment from "moment";
const ViewSummary = ({ viewUblSummary, id, user, ...props }) => {
  return (
    <div
      class="modal summary-modal right fade"
      id={id}
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="myModalLabel">
              View Summary
            </h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {viewUblSummary !== null && viewUblSummary!= ""
            ? viewUblSummary.map((viewubl, index) => {
                return (
                  <div class="modal-body">
                          <div class="d-flex bd-highlight">
                            <div class="bd-highlight item-num">
                              Item #: <span>{viewubl.itemId}</span>
                            </div>

                            <div class="ml-auto view-det-link bd-highlight">
                              <div class="barcode-font">{viewubl.ublId}</div>
                            </div>
                          </div>

                          <div class=" item-name mb-3">
                            {" "}
                            {viewubl.itemDescription}{" "}
                          </div>

                          <div class="card">
                            <div class="card-header">
                              <div className="row">
                                <div className="col-md-6 col-xs-12">
                                  <h6>UBL:{viewubl.ublId}</h6>
                                </div>
                                <div className="col-md-6 col-xs-12">
                                  <h6></h6>
                                </div>
                              </div>
                            </div>
                            <div class="card-body">
                              <div className="row">
                              <div className="col-md-6 col-xs-12 mb-3">
                                  <h6>Customer Order#</h6>
                                  <span>{viewubl.saleOrderNo}</span>
                                </div>
                                <div className="col-md-6 col-xs-12 mb-3">
                                  <h6> Quantity</h6>
                                  <span>{viewubl.qty}</span>
                                </div>
                                <div className="col-md-6 col-xs-12 mb-3">
                                  <h6>Supplier PO#</h6>
                                  <span>{viewubl.supplierPo}</span>
                                </div>
                                <div className="col-md-6 col-xs-12 mb-3">
                                  <h6>Return Location</h6>
                                    <span>{('000'+viewubl.storeId).substr(-3)}-{viewubl.returnLocation}</span>
                                </div>
                                <div className="col-md-6 col-xs-12 mb-3">
                                  <h6>Supplier </h6>
                                  <span>{viewubl.supplierNo} | {viewubl.supplierName}</span><br/>
                                  {viewubl.addLine1?<span
                                  style={{
                                    fontSize:"11px",
                                    display:"inherit",
                                    marginTop:"5px"
                                  }}> <img
                          src="./images/location-icon.svg"
                          alt=""    
                          style={{height:"15px",
                          marginRight:"4px",
                          float:"left",}}
                        />
                        <p style={{
                          
                          display:"inline"

                        }}>{viewubl.addLine1+","+(viewubl.addLine2?(viewubl.addLine2+","):"")+(viewubl.addLine3?(viewubl.addLine3+","):"")+viewubl.city+","+viewubl.state+"-"+viewubl.postCode}</p>
                        </span>:null}
                                </div>
                                <div className="col-md-6 col-xs-12 mb-3">
                                  <h6>Return Reason</h6>
                                  <span>{viewubl.returnReason}</span>
                                </div>
                                <div className="col-md-6 col-xs-12 mb-3">
                                  <h6>Brand</h6>
                                  <span>{viewubl.brand}</span>
                                </div>
                               <div className="col-md-6 col-xs-12 mb-3">
                                  <h6>Return Order#</h6>
                                  <span>{viewubl.returnOrderNo}</span>
                                </div>
                                <div className="col-md-6 col-xs-12 ">
                                  <h6>Status</h6>
                                  <span>{"Putaway at DC"}|{user.storeName} ,{ viewubl.updatedTimestampPutaway?
                                     (moment.utc(viewubl.updatedTimestampPutaway).format("DD-MM-YYYY | hh:mm A")):
                                     viewubl.dateTimePutaway?
                                     (moment.utc(viewubl.dateTimePutaway).format("DD-MM-YYYY | hh:mm A"))
                                     :null}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
};

export default ViewSummary;
