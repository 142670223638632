import React,{useState,useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import $ from "jquery";
const CarrierDetailInput=(props)=>{
    console.log(props);
const[carrierName,setcarrierName]=useState(props.carrierName);
const[carrierRefno,setcarrierRefno]=useState(props.carrierRefno);
const[carrierlist,setcarrierlist]=useState(props.carrierlist);
const [msg,setmsg]=useState("");
const [msgheader,setmsgheader]=useState("");
const[barcode,setbarcode]=useState("");
const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
const [manifestno] = useState(props.manifestno)
useEffect(() => {
    window.addEventListener("resize", () => {
        const ismobile = window.innerWidth < 600;
        if (ismobile !== isMobile) setIsMobile(ismobile);
    }, false);
}, [isMobile]);

const handlesecondpage=()=>{
    let carrierid=getcarrierId();


    props.handlesecondpage(carrierName,carrierRefno,carrierid);
}
const handleback=()=>{
    let carrierid=getcarrierId();
    props.handleback(carrierName,carrierRefno,carrierid);
}
const getcarrierId=()=>{
    for(let i=0;i<carrierlist.length;i++)
    if(carrierlist[i].CARRIER_NAME==carrierName)
    return carrierlist[i].CARRIER_ID;
}
const validatebarcode=(event)=>{
    setbarcode(event.target.value);
    
};
const onclearbar=()=>{
setbarcode("");
}
 const validate_carrierRef=(event)=>{
    var regex = /^[A-Za-z0-9]*$/;
    if(regex.test(event.target.value))
    setcarrierRefno(event.target.value)
}



return(
    <>
    <div class="col-md-10">
    <div class="card2 secondScreen show">
    {props.manifestno? <div class="bggray card-sect2_cr manifest-header-carrier">Manifest No: <p class="manifest-number-header">{manifestno}</p> </div> :null}
        <h6 class="cbl-text f14 fw7 ff-openregular">Add Carrier Details</h6>
        <div class="cbl-container">
            <div class="pl24">
                <a class="blue1 text-decoration-none" onClick={handleback}><FontAwesomeIcon icon={faArrowLeft} />  <span class="fw5 pl10"> Back</span></a>
                <div class="form-group mt-3 mb-2 pt10">
                    <p class="mb-10 fw7 f14 ff-openregular">Carrier Name</p>
                    <div class="dropdown Select-form">
                        <button class="btn btn-secondary  dropdown-search_1 width100 "
                        
                        type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          {carrierName}
                        </button>
                        <div class="dropdown-menu despatch-search-dropdown-align " 
                        style={{width:"100%",overflowY:"auto",maxHeight:"300px",height:"max-content"}}
                        aria-labelledby="dropdownMenuButton"> 
                        {carrierlist.map((item,index)=>{
                                    return (                         
                          <a class="dropdown-item p10 fs12 fw6 cd" 
                          onClick={() =>{
                              setcarrierName(item.CARRIER_NAME);
                              if(item.CARRIER_NAME=="Linfox")
                              setcarrierRefno("");
                            }}>{item.CARRIER_NAME}</a>
                          );
                        })}
                       
                          </div>
                      </div>
                     
                </div>
                <div class="form-group mt-2 mb-2 pt10" hidden={carrierName=="Linfox"}>
                    <p class="mb-10 fw7 f14 ff-openregular">Carrier Reference</p>
                    <input type="text" id="carrierRef" value={carrierRefno}
                     onChange={validate_carrierRef} 
                     class="form-control text-form ref_in pr-5" 
                     placeholder=" Carrier Reference goes here" 
                     autoComplete="off" 
                     maxLength={30}
                     disabled={carrierName=="Linfox"}
                     required />
                    
                </div>                                           
            </div>
        </div>
    </div>
    </div>
    <div class="container-fluid section-four">
        <div class="float-right">
        <input type="button" value="Cancel" class="cancel-btn fs12"  onClick={props.oncancel}/>
            <input type="button" value="Proceed" 
            disabled={(carrierRefno.length==0 && carrierName!="Linfox")}
            class="add-carrier-btn next-button fs12" id="proceedBtn" onClick={handlesecondpage}/>
        </div>
    </div>
    </>
)

}
export default CarrierDetailInput;