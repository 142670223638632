import React from "react";

const LoadingIndicator = (props) => {
  return (
    <div className="LandingPage layout-desktop position-loader">
      <div className="loader"></div>
    </div>
  );
};

export default LoadingIndicator;
