import React from "react";
import * as APP_CONSTANTS from "./constants";
import axios from "axios";
import { AuthContext } from "./constants";

export const codeGrantFlow = () => {
  window.location.assign(
    //process.env.REACT_APP_COGNITO_BASE_ENDPOINT+"/login?client_id="+process.env.REACT_APP_COGNITO_CLIENT_ID+"&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri="+process.env.REACT_APP_COGNITO_REDIRECT_URI
    process.env.REACT_APP_COGNITO_BASE_ENDPOINT + "/oauth2/authorize?identity_provider=" + process.env.REACT_APP_COGNITO_IDENTITY_PROVIDER + "&client_id=" + process.env.REACT_APP_COGNITO_CLIENT_ID + "&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=" + process.env.REACT_APP_COGNITO_REDIRECT_URI
  );
};

export const callLogoutURL = () => {

  window.location.assign(
    process.env.REACT_APP_COGNITO_BASE_ENDPOINT + "/logout?client_id=" + process.env.REACT_APP_COGNITO_CLIENT_ID + "&response_type=code&state=STATE&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=" + process.env.REACT_APP_COGNITO_REDIRECT_URI
    //process.env.REACT_APP_COGNITO_BASE_ENDPOINT+"/logout?client_id="+process.env.REACT_APP_COGNITO_CLIENT_ID+"&response_type=code&state=STATE&scope=aws.cognito.signin.user.admin+email+openid+phone+profile"

  )
  localStorage.setItem('access_token', "null")

}

export const codeAndTokenExhangeForAuthentication = async (code) => {
  const details = {
    grant_type: process.env.REACT_APP_COGNITO_GRANT_TYPE,
    client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_COGNITO_REDIRECT_URI, // Update redirect URI here
    code,
    client_secret: process.env.REACT_APP_COGNITO_CLIENT_SECRET,
  };

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };

  const formBody = Object.keys(details)
    .map(
      (key) => encodeURIComponent(key) + "=" + encodeURIComponent(details[key])
    )
    .join("&");

  return await axios.post(
    process.env.REACT_APP_COGNITO_BASE_ENDPOINT + "/oauth2/token",
    formBody,
    headers
  );
};

export const authenticateBeforeAccessingPage = (history) => {
  return (
    <AuthContext.Consumer>
      {({ isAuthenticated }) => !isAuthenticated && history.push("/")}
    </AuthContext.Consumer>
  );
};
