import { useIdleTimer } from "react-idle-timer";

const DcLocationService = {
  /* This is the API Endpoint to get Supplier details when user inputs DC Shelf Location ID 
  DC LOC VALIDATION
  */
  getDcLocations(locId, token) {
    return fetch(
      process.env.REACT_APP_API_BASE_ENDPOINT +
      process.env.REACT_APP_DC +
      locId +
      "/dcLocValid",
      {
        method: "GET",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          Authorization: token,
        },
      }
    )
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        } else {
          response = {};
          response.message =
            "Supplier Description fetched successfully for the given Location Id.";
        }

        return response;
      })
      .catch((err) => {
        return err;
      });
  },

  getUblDetails(storeNo, ublId, token) {
    return fetch(
      process.env.REACT_APP_API_BASE_ENDPOINT +
      process.env.REACT_APP_DCID +
      storeNo +
      "/locUblIdValid?ublId=" +
      ublId,
      {
        method: "GET",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          Authorization: token,
        },
      }
    )
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        } else {
          response = {};
          response.message = "Loc Id not found for the ublId.";
        }

        return response;
      })
      .catch((err) => {
        return err;
      });
  },
  getUblSummary(StoreNo, ublId, token) {
    return fetch(
      process.env.REACT_APP_API_BASE_ENDPOINT +
      process.env.REACT_APP_STORE  +
      parseInt(StoreNo) +
      "/viewUblSummaryDC?ublId=" +
      ublId,
      {
        method: "GET",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          Authorization: token,
        },
      }
    )
      .then(async(response) => {
        if (response.status == 200) {
         var temp=await response.json();
         if(temp&&temp.UblSummaryDetails&&temp.UblSummaryDetails.details.length>0&&
           parseInt(temp.UblSummaryDetails.details[0].destId)== parseInt(StoreNo))
         {
           return temp;

         }
         else
         {
           return  {"UblSummaryDetails":{"message":"Ubl Summary Details not found for the storeId","details":""}};
         }
        } else {

          response = {};
          response.message = "Return order details not found.";
        }

        return response;
      })
      .catch((err) => {
        return err;
      });
  },
  putUblDetailsToLocation(
    storeNo,
    locId,
    itemId,
    qty,
    
    Barcode,
    token,
    supplier,
    
  ) {
    return fetch(
      process.env.REACT_APP_API_BASE_ENDPOINT +
      process.env.REACT_APP_DCID +
      parseInt(storeNo) +
      "/dcPutaway",
      {
        method: "POST",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          Authorization: token,
        },
        body: JSON.stringify({
          locId: locId,
          itemId: itemId,
          qty: qty,
          supplierDesc:supplier,
          ublId: Barcode,
        }),
      }
    )
      .then((response) => {
        if (response.status == 200) {
          console.log(200);
          console.log(response);
          return response.json();
        } else {
          response = {};
          console.log(400);
          response.message = "DC Putaway details already present.";
        }
        console.log(300);
        return response;
      })
      .catch((err) => {
        return err;
      });
  },
  addUBLShipmentDC(storeNo, dcShipId, barcode, token, displayName) {

    return fetch(process.env.REACT_APP_API_BASE_ENDPOINT + process.env.REACT_APP_STORE + parseInt(storeNo) + "/addUblToShipmentDC ",
      {
        method: 'PUT',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          'Authorization': token
        },
        body: JSON.stringify({
          dcShipId: dcShipId.toString(),
          ublId: barcode,
          employeeId: displayName,
          ublStatus: "SD"
        })

      }).then(response => {

        if (response.status == 200) {

          return response.json();
        }
        else if (response.status == 400) {
          response = {}
          response.message = "Invalid input fields in request";
          console.log('api error' + response)
          console.log('api-error' + response.errorMessage)
        }
        else if (response.status == 502) {
          response = {}
          response.message = "Internal Server Error";
        }
        else {
          response = {}
          response.message = "Internal Server Error";
        }

        return response;

      }).catch(err => { return err });

  },

  removeUblFromShipmentDC(storeNo, barcode, token,despatchId,flag,checkstatus) {
    console.log(despatchId,flag);

    return fetch(process.env.REACT_APP_API_BASE_ENDPOINT + process.env.REACT_APP_STORE + parseInt(storeNo) + "/removeUblFromShipmentDC ",
      {
        method: 'PUT',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          'Authorization': token
        },
        body: JSON.stringify({
          ublId: barcode,
          shipId:despatchId,
          checkFlag:flag,
          ublStatus:checkstatus,
        })

      }).then(response => {

        if (response.status == 200) {

          return response.json();
        }
        else if (response.status == 400) {
          response = {}
          response.message = "Invalid input fields in request";
          console.log('api error' + response)
          console.log('api-error' + response.errorMessage)
        }
        else if (response.status == 502) {
          response = {}
          response.message = "Internal Server Error";
        }
        else {
          response = {}
          response.message = "Internal Server Error";
        }

        return response;

      }).catch(err => { return err });

  },

  dcShipmentHeader(storeNo, itemDetails, token, displayName) {

    return fetch(process.env.REACT_APP_API_BASE_ENDPOINT + "/dc/" + parseInt(storeNo) + "/dcShipment ",
      {
        method: 'POST',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          'Authorization': token
        },
        body: JSON.stringify({
        despatchType:'2',
        shipOriginName:'AEXPRESS',
        shipStatus:'C',
        employeeId:displayName,
        supplierNo:itemDetails.supplierNo,
        supplierName:itemDetails.supplierName
        })

      }).then(response => {

        if (response.status == 200) {

          return response.json();
        }
        else if (response.status == 400) {
          response = {}
          response.message = "Invalid input fields in request";
          console.log('api error' + response)
          console.log('api-error' + response.errorMessage)
          return response.json();
        }
        else if (response.status == 502) {
          response = {}
          response.message = "Internal Server Error";
        }
        else {
          response = {}
          response.message = "Internal Server Error";
        }

        return response;

      }).catch(err => { return err });

  },
  manifestno(StoreNo, token) {
    return fetch(process.env.REACT_APP_API_BASE_ENDPOINT + process.env.REACT_APP_STORE + parseInt(StoreNo) + '/fetchManifest?despatchType=2',
      {
        method: 'GET',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          'Authorization': token
        }

      }).then(response => {
        if (response.status == 200) {

          return response.json();
        }
        else if (response.status == 400) {
          let res = {}
          response.message = "Invalid Search";
        }
        else {
          let res = {}
          response.message = "Internal Server Error";
        }

        return response;

      }
      ).catch(err => {
        return err;

      });
  },

  despatchSupplier(storeNo, itemDetails, token, despatchRequestType, ublDespatchIdArray, displayName, storeName) {

    return fetch(process.env.REACT_APP_API_BASE_ENDPOINT + "/dc/" + parseInt(storeNo) + "/despatchSupplier ",
      {
        method: 'POST',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          'Authorization': token
        },
        body: JSON.stringify({supplierDet :[{
        supplierNo:itemDetails.supplierNo,
        despatchRequest: despatchRequestType,
        supplierName:itemDetails.supplierName,
         employeeId:displayName,
        // destAddress1:itemDetails.addLine1 && itemDetails.addLine1.length > 0 ? itemDetails.addLine1 : null ,
        // destAddress2:itemDetails.addLine2 && itemDetails.addLine2.length > 0 ? itemDetails.addLine2 : null ,
        // destSuburb:itemDetails.city,
        // destPostCode:itemDetails.postCode,
        ublDespatchId:ublDespatchIdArray.length>0?ublDespatchIdArray:[],
        shipOriginName: storeName,
        shipStatus : "O",
        despatchTyp : '2'
        }]})

      }).then(response => {

        if (response.status == 200) {

          return response.json();
        }
        else if (response.status == 400) {
          response = {}
          response.message = "Invalid input fields in request";
          console.log('api error' + response)
          console.log('api-error' + response.errorMessage)
          return response.json();
        }
        else if (response.status == 502) {
          response = {}
          response.message = "Internal Server Error";
        }
        else {
          response = {}
          response.message = "Internal Server Error";
        }

        return response;

      }).catch(err => { return err });

  },

  modifyDespatchSupplier(storeNo, token, requestBody) {

    return fetch(process.env.REACT_APP_API_BASE_ENDPOINT + "/dc/" + parseInt(storeNo) + "/despatchSupplier ",
      {
        method: 'POST',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          'Authorization': token
        },
        body: JSON.stringify({supplierDet : requestBody})

      }).then(response => {

        if (response.status == 200) {

          return response.json();
        }
        else if (response.status == 400) {
          response = {}
          response.message = "Invalid input fields in request";
          console.log('api error' + response)
          console.log('api-error' + response.errorMessage)
          return response.json();
        }
        else if (response.status == 502) {
          response = {}
          response.message = "Internal Server Error";
        }
        else {
          response = {}
          response.message = "Internal Server Error";
        }

        return response;

      }).catch(err => { return err });

  },

  updateCarrierDetails(storeNo, token, despatchNumber, carrierRefNo, carrierId, carrierName, weight, apConNo) {

    return fetch(process.env.REACT_APP_API_BASE_ENDPOINT + "/dc/" + parseInt(storeNo) + "/updateCarrierDC ",
      {
        method: 'PUT',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          'Authorization': token
        },
        body: JSON.stringify({ carrierDet : [{
          shipId:despatchNumber,
          carrierId: carrierId.toString(),
          carrierName:carrierName,
          carrierConsFlag:"Y",
          carrierRefNo: carrierRefNo,
          weight:weight,
          appConNo:apConNo
        }]})

      }).then(response => {

        if (response.status == 200) {

          return response.json();
        }
        else if (response.status == 400) {
          response = {}
          response.message = "Invalid input fields in request";
          console.log('api error' + response)
          console.log('api-error' + response.errorMessage)
          return response.json();
        }
        else if (response.status == 502) {
          response = {}
          response.message = "Internal Server Error";
        }
        else {
          response = {}
          response.message = "Internal Server Error";
        }

        return response;

      }).catch(err => { return err });

  },
  modifyUpdateCarrierDetails(storeNo, token, carrierdetails) {

    return fetch(process.env.REACT_APP_API_BASE_ENDPOINT + "/dc/" + parseInt(storeNo) + "/updateCarrierDC ",
      {
        method: 'PUT',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          'Authorization': token
        },
        body: JSON.stringify({carrierDet : carrierdetails})

      }).then(response => {

        if (response.status == 200) {

          return response.json();
        }
        else if (response.status == 400) {
          response = {}
          response.message = "Invalid input fields in request";
          console.log('api error' + response)
          console.log('api-error' + response.errorMessage)
          return response.json();
        }
        else if (response.status == 502) {
          response = {}
          response.message = "Internal Server Error";
        }
        else {
          response = {}
          response.message = "Internal Server Error";
        }

        return response;

      }).catch(err => { return err });

  },
  getDespatchDetails(StoreNo, token) {
    return fetch(process.env.REACT_APP_API_BASE_ENDPOINT + process.env.REACT_APP_DCID + parseInt(StoreNo) + '/getDespatchDetails',
      {
        method: 'GET',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          'Authorization': token
        }

      }).then(response => {
        if (response.status == 200) {

          return response.json();
        }
        else if (response.status == 400) {
          let res = {}
          response.message = "Invalid Search";
        }
        else {
          let res = {}
          response.message = "Internal Server Error";
        }

        return response;

      }
      ).catch(err => {
        return err;

      });
  },

  getDespatchUBLDetails(StoreNo, token, despatchNumber) {
    return fetch(process.env.REACT_APP_API_BASE_ENDPOINT + process.env.REACT_APP_DCID + parseInt(StoreNo) + '/getDespatchUblDetails?shipId=' + despatchNumber,
      {
        method: 'GET',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          'Authorization': token
        }

      }).then(response => {
        if (response.status == 200) {

          return response.json();
        }
        else if (response.status == 400) {
          let res = {}
          response.message = "Invalid Search";
        }
        else {
          let res = {}
          response.message = "Internal Server Error";
        }

        return response;

      }
      ).catch(err => {
        return err;

      });
  },
  
};
export default DcLocationService;
