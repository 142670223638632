import { authenticationReducer } from "components/Authentication/reducer";
import { combineReducers } from "redux";
import CblReducer from "./CblReducer";
import DCLocationsReducer from "./DcLocationsReducer";
import UblDetailsReducer from "./UblDetailsReducer";
import UblReducer from "./UblReducer";

const rootReducer = combineReducers({
  ubl: UblReducer,
  cbl: CblReducer,
  dcLocations: DCLocationsReducer,
  ublDetails: UblDetailsReducer,
  authentication: authenticationReducer,
});
export default rootReducer;
