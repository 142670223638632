import * as constants from "./constants";

const initialState = {
  accessToken: "",
  refreshToken: "",
  authenticated: false,
};

export const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SAVE_TOKENS:
      const { accessToken, refreshToken } = action;
      return { ...state, accessToken, refreshToken, authenticated: true };
    default:
      return initialState;
  }
};
