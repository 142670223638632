import React, { useState, useEffect } from "react";
import Checkbox from "components/common/Checkbox";
import $ from "jquery";

import moment from "moment";

const ViewDetailOrderLookUp = ({ detail_1, index, ...props }) => {
    const user = JSON.parse(localStorage.getItem("User"));
    
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
    useEffect(() => {
        window.addEventListener("resize", () => {
            const ismobile = window.innerWidth < 600;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false);
    }, [isMobile]);
    const [expandAllViewSummary, setExpandAllViewSummary] = useState(false)
    const handleExpandAllSelectViewSummary = (event) => {
        setExpandAllViewSummary(!expandAllViewSummary)
        if (expandAllViewSummary) {
            $('.collapse2').collapse('hide');
        } else {
            $('.collapse2').collapse('show');
        }
    }
   console.log(detail_1.hubLocation.split(","));

    useEffect(() => {

        $(document).on('click', '.dropdown-menu-try', function (e) {
            e.stopPropagation();
        });
        //   $('#collapseOneSummary').on('shown.bs.collapse', function () {
        //     console.log("Opened")
        //  });

        $('#collapseOneSummary').on('hidden.bs.collapse', function () {
            setExpandAllViewSummary(false)
            console.log("Closed")
        });
        $('#collapseTwoSummary').on('hidden.bs.collapse', function () {
            setExpandAllViewSummary(false)
            console.log("Closed")
        });
        $('#collapseThreeSummary').on('hidden.bs.collapse', function () {
            setExpandAllViewSummary(false)
            console.log("Closed")
        });
        $('#collapseFourSummary').on('hidden.bs.collapse', function () {
            setExpandAllViewSummary(false)
            console.log("Closed")
        });

    }, []);


    return (
        <>
            <div class="modal summary-modal right fade"
                id={"collapseExample" + index}
                tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">

                        <div class="modal-header top-right-border view-summary-align">

                            <h4 class="modal-title" id="myModalLabel">View Summary</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div class="modal-body of-y dropdown-menu-try">

                            <Checkbox
                                key="expandAllViewSummary"
                                id="expandAllViewSummary"
                                name="expandAllViewSummary"
                                select={expandAllViewSummary}
                                handleChange={handleExpandAllSelectViewSummary}
                                type="checkbox"
                            /> <span className="expand-All">Expand All</span>
                            <div class="accordion" id="accordionExample">
                                <div className="mt-10">
                                    <div class="advance-search-header" id="headingOne">
                                        <h5 id="itemAndSupplier" class="mb-0" data-toggle="collapse" data-target="#collapseOneSummary" aria-expanded="true" aria-controls="collapseOneSummary">
                                            <button class="btn collapse-group-btn" type="button" >
                                                Returns
                                            </button>
                                        </h5>
                                    </div>

                                    <div id="collapseOneSummary" class={isMobile?"collapse2 collapse":"collapse2 collapse show"} aria-labelledby="headingOne" >
                                        <div class="card-body-summary">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div >
                                                        <div className="card-details">
                                                            <h5>Return Order #</h5>
                                                            <h6>{detail_1.returnOrderNumber}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div >
                                                        <div className="card-details">
                                                            <h5>Return Notes</h5>
                                                            <h6>{detail_1.returnNotes&&detail_1.returnNotes!="null"&&detail_1.returnNotes!=" "?
                                                            detail_1.returnNotes:"-"}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                             </div>   


                                             <div className="row">
                                                <div className="col-6">
                                                    <div >
                                                        <div className="card-details">
                                                            <h5>Return Reason</h5>
                                                            <h6>{detail_1.returnReason}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div >
                                                        <div className="card-details">
                                                            <h5>Return Created Date</h5>
                                                            <h6>{detail_1.returnCreatedOn?
                                                            moment.utc(detail_1.returnCreatedOn).format("DD-MM-YYYY | hh:mm A"):"-"}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                                <div className="row">
                                                <div className="col-6">
                                                    <div >
                                                        <div className="card-details">
                                                            <h5>Return Location </h5>
                                                         
                                                            <h6> {("00" +detail_1.returnLocation).slice(-3)} - {detail_1.storeName}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                <div >
                                                        <div className="card-details">
                                                            <h5>Return Received Date</h5>
                                                            <h6>{detail_1.returnReceivedFrom?
                                                            moment.utc(detail_1.returnReceivedFrom).format("DD-MM-YYYY | hh:mm A"):"-"}</h6>
                                                        </div>
                                                    </div>

                                                </div>
                                                </div>

                                                <div className="row">
                                                <div className="col-6">

                                                    <div >
                                                        <div className="card-details">
                                                            <h5>Customer Order#</h5>
                                                            <h6>{detail_1.salesOrder}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                <div >
                                                        <div className="card-details">
                                                            <h5>Return Channel</h5>
                                                            <h6>{detail_1.returnChannel?
                                                            detail_1.returnChannel.toLowerCase()=="store"?"MCO":"Webcom":"-"}</h6>
                                                        </div>
                                                    </div>

                                                </div>
                                                </div>
                                                <div className="row">
                                                <div className="col-6">
                                                    <div >
                                                        <div className="card-details">
                                                            <h5>Detag Reason</h5>
                                                            <h6>{detail_1.detagReason?detail_1.detagReason:"-"}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="col-6">
                                                <div >
                                                        <div className="card-details">
                                                            <h5>Return Created by</h5>
                                                            <h6>{detail_1.returnCreatedBy?detail_1.returnCreatedBy:"-"} </h6>
                                                        </div>
                                                    </div>

                                                </div>
                                                </div>
                                                
                                                <div className="row">
                                                <div className="col-6">
                                                    <div >
                                                        <div className="card-details">
                                                            <h5>Detag Comment</h5>
                                                            <h6>{detail_1.detagComment?detail_1.detagComment
                                                            :"-"}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                                    
                                                   


                                                    

                                                    



                                        </div>
                                    </div>
                                </div>
                                <div className="mt-10">
                                    <div class="advance-search-header" id="headingTwo">
                                        <h5 class="mb-0" data-toggle="collapse" data-target="#collapseTwoSummary" aria-expanded="false" aria-controls="collapseTwoSummary">
                                            <button class="btn collapse-group-btn collapsed" type="button" >
                                                Item
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseTwoSummary" class={isMobile?"collapse2 collapse":"collapse2 collapse show"} aria-labelledby="headingTwo" >
                                        <div class="card-body-summary">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div >
                                                        <div className="card-details">
                                                            <h5>Item #</h5>
                                                            <h6>{detail_1.item}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                <div >
                                                        <div className="card-details">
                                                            <h5>Brand</h5>
                                                            <h6>{detail_1.brand?detail_1.brand:"-"}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                                <div className="row">
                                                <div className="col-6">
                                            
                                                    <div >
                                                        <div className="card-details">
                                                            <h5>Item Description</h5>
                                                            <h6>{detail_1.itemDesc}</h6>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    <div className="col-6">
                                                    <div >
                                                        <div className="card-details">
                                                            <h5>TPC</h5>
                                                            <h6>{detail_1.itemTPC?detail_1.itemTPC:"-"}</h6>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    </div>
                                                    <div className="row">
                                                <div className="col-6">
                                                    <div >
                                                        <div className="card-details">
                                                            <h5>Class Group</h5>
                                                            <h6>{detail_1.itemClsGrp?(detail_1.itemClsGrp+"-"+detail_1.itemClsGrpDesc):"-"}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                
                                                <div >
                                                        <div className="card-details">
                                                            <h5>Status</h5>
                                                            <h6>{
                                                             detail_1.ublStatus=="DS"?"Despatch to Supplier":
                                                             detail_1.ublStatus=="RD"?"Ready Despatch to Supplier":
                                                            detail_1.dcPutawayLocation?"Putaway in DC":
                                                            detail_1.ublStatus=="C"?
                                                            (detail_1.storeManifestNumber?("Ready Despatch to DC"):
                                                            detail_1.cublId?"CBL tagged":"UBL tagged"):
                                                            detail_1.ublStatus=="D"?("Despatched to DC"):
                                                            detail_1.ublStatus=="R"?("Received at DC"):
                                                            detail_1.ublStatus=="M"?("Item Missing"):
                                                            detail_1.ublStatus=="X"?("Detagged"):
                                                            "New Return"}   <br/> 
                                                            
                                                            {(!detail_1.ublStatus || detail_1.ublStatus=="C" || detail_1.ublStatus=="D" 
                                                            ||  detail_1.ublStatus=="X")?(("00" +detail_1.returnLocation).slice(-3)+"-"+detail_1.storeName
                                                            ):(detail_1.hubLocation.split(",").length>1 && detail_1.hubLocation.split(",")[0] && detail_1.hubLocation.split(",")[0].trim().toLowerCase()!="null")?(   
                                                                (detail_1.hubLocation.split(",")[0]).toString().padStart(3,"0")+"-"+
                                                            detail_1.hubLocation.split(",")[1]):null},
                                                           
                                                            {
                                                                ((detail_1.ublStatus=="C" || detail_1.ublStatus=="R") && detail_1.createdDateTimeDcPutaway)?
                                                                (detail_1.updatedDateTimeDcPutaway?
                                                                (moment.utc(detail_1.updatedDateTimeDcPutaway).format("DD-MM-YYYY | hh:mm A")
                                                                ):detail_1.createdDateTimeDcPutaway?
                                                                (moment.utc(detail_1.createdDateTimeDcPutaway).format("DD-MM-YYYY | hh:mm A")
                                                                ):null)
                                                                :detail_1.updatedDateTimeUbl?
                                                                (moment.utc(detail_1.updatedDateTimeUbl).format("DD-MM-YYYY | hh:mm A")
                                                                ):
                                                                detail_1.createdDateTimeUbl?(moment.utc(detail_1.createdDateTimeUbl).format("DD-MM-YYYY | hh:mm A")):
                                                                detail_1.createdDateTimeReturnOrder?(moment.utc(detail_1.createdDateTimeReturnOrder).format("DD-MM-YYYY | hh:mm A")):null
                                                                
                                                            }</h6>
                                                        </div>
                                                    </div>
                                                    </div>

                                                </div>
                                                

                                        </div>
                                    </div>
                                </div>
                                <div className="mt-10">
                                    <div class="advance-search-header" id="headingThree">
                                        <h5 class="mb-0" data-toggle="collapse" data-target="#collapseThreeSummary" aria-expanded="false" aria-controls="collapseThreeSummary">
                                            <button class="btn collapse-group-btn collapsed" type="button" >
                                                Supplier
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThreeSummary" class="collapse2 collapse show" aria-labelledby="headingThree">
                                        <div class="card-body-summary">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div >
                                                        <div className="card-details">
                                                            <h5>Supplier</h5>
                                                            <h6>{detail_1.supplierNo} {detail_1.supplier}</h6>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    <div className="col-6">
                                                    <div >
                                                        <div className="card-details">
                                                            <h5>Item Destination</h5>
                                                            <h6>{detail_1.itemDestination.split(",")[0].trim().toLowerCase()!="null"?(detail_1.itemDestination.split(",")[0].trim()+","):"-"
                                                            
                                                        }
                                                        {detail_1.itemDestination.split(",")[1].trim().toLowerCase()!="null"?(detail_1.itemDestination.split(",")[1].trim()+","):null
                                                        
                                                    }
                                                    {detail_1.itemDestination.split(",")[2].trim().toLowerCase()!="null"?(detail_1.itemDestination.split(",")[2].trim()+","):null
                                                        
                                                    }
                                                    {detail_1.itemDestination.split(",")[3].trim().toLowerCase()!="null"?(detail_1.itemDestination.split(",")[3].trim()+"-"):null
                                                        
                                                    }
                                                    {detail_1.itemDestination.split(",")[4].trim().toLowerCase()!="null"?(detail_1.itemDestination.split(",")[4].trim()):null
                                                        
                                                    }
                                                    </h6>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    </div>
                                                    <div className="row">
                                                <div className="col-6">
                                                    <div >
                                                        <div className="card-details">
                                                            <h5>Supplier PO</h5>
                                                            <h6>{detail_1.supplierPo}</h6>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    <div className="col-6">
                                                    <div >
                                                        <div className="card-details">
                                                            <h5>Hub Location</h5>
                                                            <h6>{detail_1.hubLocation.substr(0,4).toLowerCase()!="null"&& detail_1.hubLocation.split(",")[0] && detail_1.hubLocation.split(",")[0].trim().toLowerCase()!="null"?(detail_1.hubLocation.split(",")[0].trim()+","):"-"
                                                            
                                                            }
                                                            {detail_1.hubLocation.substr(0,4).toLowerCase()!="null"&& detail_1.hubLocation.split(",")[1] && detail_1.hubLocation.split(",")[1].trim().toLowerCase()!="null"?(detail_1.hubLocation.split(",")[1].trim()+","):null
                                                            
                                                        }
                                                        {detail_1.hubLocation.substr(0,4).toLowerCase()!="null"&& detail_1.hubLocation.split(",")[2] && detail_1.hubLocation.split(",")[2].trim().toLowerCase()!="null"?(detail_1.hubLocation.split(",")[2].trim()+","):null
                                                            
                                                        }
                                                        {detail_1.hubLocation.substr(0,4).toLowerCase()!="null"&& detail_1.hubLocation.split(",")[3] && detail_1.hubLocation.split(",")[3].trim().toLowerCase()!="null"?(detail_1.hubLocation.split(",")[3].trim()+"-"):null
                                                            
                                                        }
                                                        {detail_1.hubLocation.substr(0,4).toLowerCase()!="null"&& detail_1.hubLocation.split(",")[4] && detail_1.hubLocation.split(",")[4].trim().toLowerCase()!="null"?(detail_1.hubLocation.split(",")[4].trim()):null
                                                            
                                                        }
                                                            </h6>
                                                        </div>
                                                    </div>

                                                    </div>
                                                    </div>
                                                    <div className="row">
                                                <div className="col-6">
                                                    <div >
                                                        <div className="card-details">
                                                            <h5>Myer Market ID</h5>
                                                            <h6>{detail_1.myerMarketId=="Y"?"9000397 Myer Market":"-"}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                                

                                        </div>
                                    </div>
                                </div>
                                <div className="mt-10">
                                    <div class="advance-search-header" id="headingFour">
                                        <h5 class="mb-0" data-toggle="collapse" data-target="#collapseFourSummary" aria-expanded="false" aria-controls="collapseFourSummary">
                                            <button class="btn collapse-group-btn collapsed" type="button" >
                                                Tracking
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseFourSummary" class={isMobile?"collapse2 collapse":"collapse2 collapse show"} aria-labelledby="headingFour">
                                        <div class="card-body-summary">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div >
                                                        <div className="card-details">
                                                            <h5>Ageing</h5>
                                                            <h6>{detail_1.aging?(detail_1.aging+" days"):"-"}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                <div >
                                                        <div className="card-details">
                                                            <h5>Store Manifest #</h5>
                                                            <h6>{detail_1.storeManifestNumber?detail_1.storeManifestNumber:"-"}</h6>
                                                        </div>
                                                    </div>

                                                </div>
                                                </div>

                                                <div className="row">
                                                <div className="col-6">
                                                    <div >
                                                        <div className="card-details">
                                                            <h5>Store Carrier Reference #</h5>
                                                            <h6>{detail_1.carrierRef?detail_1.carrierRef:"-"}</h6>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    <div className="col-6">
                                                    <div >
                                                        <div className="card-details">
                                                            <h5>DC Manifest #</h5>
                                                            <h6>{detail_1.dcManifestNumber?detail_1.dcManifestNumber:"-"}</h6>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    </div>
                                                    <div className="row">
                                                <div className="col-6">
                                                    <div >
                                                        <div className="card-details">
                                                            <h5>UBL</h5>
                                                            <h6>{detail_1.ublId?detail_1.ublId:"-"}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                <div >
                                                        <div className="card-details">
                                                            <h5>Putaway Location</h5>
                                                            <h6>{detail_1.dcPutawayLocation?detail_1.dcPutawayLocation:"-"}</h6>
                                                        </div>
                                                    </div>

                                                </div>
                                                </div>
                                                <div className="row">
                                                <div className="col-6">

                                                    <div >
                                                        <div className="card-details">
                                                            <h5>CBL</h5>
                                                            <h6>{detail_1.cublId?detail_1.cublId:"-"}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                <div >
                                                        <div className="card-details">
                                                            <h5>DC Receive to location</h5>
                                                            <h6>{detail_1.receiveLocation?detail_1.receiveLocation:"-"}</h6>
                                                        </div>
                                                    </div>

                                                </div>
                                                </div>
                                                <div className="row">
                                                <div className="col-6">
                                                    <div >
                                                        <div className="card-details">
                                                            <h5>UBL Tagged in MCO</h5>
                                                            <h6>{detail_1.mcoTag?detail_1.mcoTag=="Y"?"Yes":"No":"-"}</h6>
                                                        </div>
                                                    </div>
                                                    </div>

                                                </div>
                                               

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>


    );
};

export default ViewDetailOrderLookUp;
