import {
  faEye,
  faMapMarkerAlt,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingIndicator from "components/common/LoadingIndicator";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ViewCollapseDetails from "./ViewCollapseDetails";
import CblService from "services/CblService";
import UblService from "services/UblService";
import Pagination from "components/common/Pagination";
import { generatePdf } from "components/common/PDFGenerator";
import $ from "jquery";
import * as constants from "components/constants";
const ViewSummary = ({
  loadCblDestination,
  cbl,
  cart,
  handleViewUblSummary,
  handleGoPreviousScreen,
  handleGoHomeScreen,
  handleOnUblTextChange,
  handleGoNextScreen,
  ...props
}) => {
  const [loader, setLoader] = useState(false);
  const [show,setshow]=useState(true);
  const [Cart, setCart] = useState(cart);
  const [errmsg, seterrmsg] = useState("");
  const user = JSON.parse(localStorage.getItem("User"));
  const [cublId, setcublId] = useState(props.cblInputText);
  const [toggle, settoggle] = useState(true);
  const [ublInputText, setUblId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [detail_1, setdetail_1] = useState([{}]);
  const [barcodevalid, setbarcodevalid] = useState(true);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  useEffect(()=>{
    $('#AddUblForm').click( ()=>{
      validate({target:{value:""}});
      setUblId("");
      
    })
  },[]);
  //useEffect(()=>{},props);
  useEffect(()=>{
    $('#AddUblForm').click( ()=>{
      validate({target:{value:""}});
      setUblId("");
      
    })
  },[]);
  const [deleteindex, setDeleteindex] = useState("");
  useEffect(() => {}, [toggle]);
  const handleRemoveItem = async (storeNo, cublId, ublId, token) => {
    setLoader(true);
    CblService.removeCublFromUbl(storeNo, cublId, ublId, token)
      .then((response) => {
        setLoader(false);
        if (
          response.updateRemoveCublFromUbl.message ==
          "CUBL Details removed from UBL"
        ) {
          props.tostmsg();
          var temp_cart = Cart;
          for (let i = 0; i < cart.length; i++)
            if (cart[i].ublId == ublId) {
              temp_cart.splice(i, 1);
            }
          setCart(temp_cart, console.log(Cart));
          settoggle(!toggle);
        } else {
          seterrmsg("Invalid CBL ID");
          $("#errorModalCenter12").modal();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleAddUbl = async (e) => {
    $("#add_option").removeClass("show");
    e.preventDefault();
    setLoader(true);
    for (let i = 0; i < Cart.length; i++) {
      console.log(Cart[i].ublId, ublInputText, Cart[i].ublId == ublInputText);
      if (Cart[i].ublId == ublInputText) {
        seterrmsg("Already in Use!");
        $("#errorModalCenter12").modal();
        setLoader(false);
        setUblId("");
        return;
      }
    }
    UblService.addCublToUbl(user.storeNo, cublId, ublInputText, user.token,props.shipId)
      .then((response) => {
        if (
          response.addCublUbl.message == "UBL Headers updated with CUBL and Ship details"
        )
          CblService.viewUBLSummary(user.storeNo, ublInputText, user.token)
            .then((response1) => {
              var temp_cart = Cart ? Cart : [];
              console.log(Cart);
              temp_cart.push(response1.UblSummaryDetails.details[0]);
              setCart(temp_cart);
              settoggle(!toggle);
              setLoader(false);
              setUblId("");
            })
            .catch((err) => console.log(err));
        else {
          setUblId("");
          if(response.addCublUbl.message=="Detagged UBL")
          seterrmsg("UBL in detagged status");
          else if(response.addCublUbl.message=="CUBL alreay mapped to UBL")
          seterrmsg("UBL already tagged to other CBL");
          else if(response.addCublUbl.message=="UBL already present in Shipment")
          seterrmsg("UBL already tagged to shipment");
          else if(response.addCublUbl.message=="UBL already Dispatched")
          seterrmsg("UBL already Dispatched");
          else
          seterrmsg("UBL details not found");

          $("#errorModalCenter12").modal();
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };
  const viewdetail = (ublId) => {
    setLoader(true);
    CblService.viewUBLSummary(user.storeNo, ublId, user.token)
      .then((response1) => {
        setdetail_1(response1.UblSummaryDetails.details, console.log(detail_1));
        $("#myModal2").modal();
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const renderData = (data) => {
    console.log(data);
    return (
      <>
        {data.map((detail_1, index) => {
          return (
            <div style={{ paddingLeft: "38px" }}>
              <p style={{ fontSize: 15, marginBottom: "3px" }}>
                <b>View Summary</b>
              </p>

              <div
                style={{ fontWeight: 600, padding: "4px 0px 0px" }}
              >{`${detail_1.DC_NAME}`}</div>
              <address
                style={{ margin: "0px", padding: "0px", paddingTop: "4px" }}
              >
                {`${detail_1.ADDRESS_LN1} `}
                {`${detail_1.ADDRESS_LN2} `}
              </address>
              <p style={{ margin: "0px", padding: "0px", paddingTop: "4px" }}>
                {`${detail_1.STATE} `}
                {`${detail_1.POST_CD} `}
              </p>
            </div>
          );
        })}
      </>
    );
  };
  const renderSelectedCards = (data) => {
    return (
      <>
        {data
          .map((detail_1, index) => {
            return (
              <div className="col-md-4 col-lg-4 col-sm-4 card-padding">
                <div className="card-content-ubl p-2">
                  {/* item id */}
                  <div className="d-flex mb-1 bd-highlight">
                    <div className="bd-highlight item-id">
                      Item #: <span>{detail_1.itemId}</span>
                    </div>
                    <button
                      class="delete-icon"
                      id="removeBtn"
                      data-toggle="modal"
                      value={index}
                      data-target="#exampleModalCenter"
                      onClick={() => setDeleteindex(detail_1.ublId)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                    {/* <div
                    className="ml-auto check-link bd-highlight cart-delete"
                    onClick={(e) =>
                      handleRemoveItem(
                        user.storeNo,
                        props.cblInputText,
                        detail_1.UBL_ID,
                        user.token
                      )
                    }
                  ></div> */}
                  </div>

                  {/* Item name */}
                  <div
                    className="d-flex bd-highlight mb-1"
                    style={{
                      height: "30px",
                      overflow: "hidden",
                    }}
                  >
                    <div className="bd-highlight item-ublname">
                      {detail_1.itemDescription}
                    </div>
                  </div>

                  <hr />

                  {/* UBL ID */}
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div style={{}}>
                        <div>
                          {" "}
                          <p
                            class="barcode-font"
                            style={{
                              fontSize: 28,
                              marginTop: 15,
                              marginLeft: 5,
                            }}
                          >
                            {detail_1.ublId}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      className="ml-auto view-det-link bd-highlight"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <button
                        class="border-none bg-none bluec viewbtn view"
                        onClick={(e) => viewdetail(detail_1.ublId)}
                      >
                        <FontAwesomeIcon icon={faEye} className="view" />
                        View
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
          .slice(indexOfFirstItem, indexOfLastItem)}
      </>
    );
  };
  const handleClick = (event) => {
    setCurrentPage(Number(event.target.id));
  };

  const handlePrevBtn = () => {
    setCurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };
  const handleNextBtn = () => {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const validate = (event) => {
    var inputBarcode = event.target.value.toString().trim();

    if (
      inputBarcode.length === 7 &&

      inputBarcode > 0
    ) {
      setbarcodevalid(true);
    } else if (inputBarcode.length == 0) {
      setbarcodevalid(true);
    } else {
      setbarcodevalid(false);
    }
  };

  const pdfGeneration = () => {
    generatePdf(Cart, "Consolidated Barcode Label", "CUBLreport");
  };

  return (
    <>
      <div className="col-md-10">
        <div className="bggray card-sect2_cr" style={{ height: "min-content" }}>
          <form onSubmit={handleAddUbl}>
            <div className="row">
              <div className="col-md-8 flex">
                {props.pageno == 3 ? (
                  renderData(cbl)
                ) : (
                  <>
                    <p class="pl24 pt10 fw6 fs12 label">Destination :</p>
                    <div class="form-group">
                      <div class="input-group w100" style={{ width: "60%" }}>
                        <FontAwesomeIcon
                          icon={faMapMarkerAlt}
                          style={{
                            position: "absolute",
                            top: "15px",
                            left: "17px",
                            zIndex: "2",
                          }}
                        />
                        <input
                          type="text"
                          required=""
                          id="icon"
                          className="form-control cblInput dest"
                          autocomplete="off"
                          name="search"
                          disabled
                          placeholder={`${cbl[0].DC_NAME}`}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="col-md-4 add">
                <input
                  type="submit"
                 
                  value="+ Add UBL"
                  data-toggle="dropdown"
                  id="AddUblForm"
                  aria-haspopup="true"
                  autocomplete="off"
                  aria-expanded="false"
                  className="addbtnc dropdown-toggle"
                  style={{ marginRight: "10px", float: "right" }}
                />
                <section
                  className="col-12  mb-12 dropdown-menu notification add-btn-toggle"
                  style={
                    {
                      //marginRight: "40px"
                    }
                  }
                  id="add_option"
                >
                  <label
                    className=""
                    for="ublInput"
                    //style={{ padding: "40px" }}
                  >
                    Add Unique Barcode Label
                  </label>
                  <input
                    type="text"
                    required
                    className="form-control ref_in input-search mb-2"
                    id="ublInput"
                    name="ublInput"
                    placeholder="Add UBL"
                    value={ublInputText}
                    autoComplete="off"
                    aria-label="Text input with dropdown button"
                   
                    onChange={(e) => {
                      validate(e);
                      setUblId(e.target.value.replace(/[^0-9.]/g, ""));
                    }}
                    onFocus={(e)=>setshow(true)}
                    onBlur={(e)=>setshow(false)}
                  />
                  <img
                    src="./images/error-icon.svg"
                    alt=""
                    hidden={barcodevalid || show}
                  />
                  <span
                    className="barcode-validation-failed"
                    hidden={barcodevalid || show}
                  >
                    Invalid Barcode !
                  </span>
                  <div className="text-center">
                    <input
                      type="submit"
                      value="Add"
                      className="add-carrier-btn"
                      disabled={!barcodevalid || ublInputText.length == 0}
                    />
                  </div>
                </section>
              </div>
            </div>
          </form>
        </div>
        <div className="card2 firstScreen show">
          <h6 className="cbl-text f14 fw5">CBL#:{props.cblInputText}</h6>
          <div className="cbl-container" style={{ height: "auto" }}>
            {Cart.length > 0 ? (
              <>
                <div className="rtn-card-tiles">
                  <div className="container">
                    <div className="row">
                      {cart.length === 0 ? (
                        <p>No UBL ID found</p>
                      ) : (
                        renderSelectedCards(Cart)
                      )}
                    </div>
                  </div>
                </div>
                <section className="paginat">
                  <Pagination
                    className="pagination-bar"
                    data={cart}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    pageNumberLimit={pageNumberLimit}
                    maxPageNumberLimit={maxPageNumberLimit}
                    minPageNumberLimit={minPageNumberLimit}
                    handleClick={handleClick}
                    handlePrevBtn={handlePrevBtn}
                    handleNextBtn={handleNextBtn}
                  />
                </section>
              </>
            ) : (
              <div
                className="img-with-text"
                style={{ paddingTop: "20px", margin: "0px" }}
              >
                <img src="./images/Item-not-found.svg" alt="" />
                <p className="no-item-found">No Item found</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div class="container-fluid section-four">
        {props.pageno == 3 ? (
          <div class="float-right">
            <input
              type="button"
              value="Exit"
              class="cancel-btn fs12"
              onClick={(e)=>handleGoHomeScreen(e,false)}
            />

            <input
              type="button"
              value="Close"
              onClick={() => {
                $("#errorModalCenter51").modal();
              }}
              class="add-carrier-btn next-button fs12"
            />
          </div>
        ) : (
          <div class="float-right">
          <input
              type="button"
              value="Exit"
              class="cancel-btn fs12"
              onClick={(e)=>handleGoHomeScreen(e,false)}
            />
            <input
              type="button"
              value="View Summary"
              onClick={() => {
                setUblId(" ");
                handleGoNextScreen();
              }}
              class="add-carrier-btn  next-button fs12"
            />
          </div>
        )}
      </div>
      <div
        class="modal fade"
        id="errorModalCenter2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header bg-light-black whitetext">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Alert
              </h5>
            </div>
            <div class="modal-body">
              <p class="text-center"> {props.message.deleteCblMessage} </p>
            </div>
            <div class="modal-footer border-none">
              <button
                type="button"
                class="btn bgred whitetext ctbtn "
                data-dismiss="modal"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="errorModalCenter3"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header bg-light-black whitetext">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Alert
              </h5>
            </div>
            <div class="modal-body">
              <p class="text-center"> {props.message2.cublMapToUbl} </p>
            </div>
            <div class="modal-footer border-none">
              <button
                type="button"
                class="btn bgred whitetext ctbtn "
                data-dismiss="modal"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade loginModal"
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-z"
          role="document"
        >
          <div className="modal-content modal-radius">
            <div className="modal-header confirmation-header">
              <h5
                className="modal-title confirmation-style"
                id="errorModalCenterTitle"
              >
                Confirmation
              </h5>
            </div>
            <div className="modal-body text-align-centre">
              <div>
                {" "}
                Would you like to remove this <b>UBL# {deleteindex}</b>?{" "}
              </div>
            </div>
            <div className="modal-footer bo-modalbtnftr bt-none">
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-no"
              >
                No
              </button>
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-yes"
                onClick={(e) =>
                  handleRemoveItem(
                    user.storeNo,
                    props.cblInputText,
                    deleteindex,
                    user.token
                  )
                }
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      {loader ? <LoadingIndicator /> : null}
      <div
        className="modal fade loginModal"
        id="errorModalCenter12"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-z"
          role="document"
        >
          <div className="modal-content modal-radius">
            <div className="modal-header error-popup-header-bg">
              <h5
                className="modal-title confirmation-style"
                id="errorModalCenterTitle"
              >
                Error
              </h5>
            </div>
            <div className="modal-body text-align-centre">
              <div>{errmsg} </div>
            </div>
            <div className="modal-footer bo-modalbtnftr bt-none">
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-yes"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <div
        class="modal fade"
        id="errorModalCenter12"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header bg-light-black whitetext">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Error
              </h5>
            </div>
            <div class="modal-body">
              <p class="text-center"> {errmsg} </p>
            </div>
            <div class="modal-footer border-none">
              <button
                type="button"
                class="btn bgred whitetext ctbtn "
                data-dismiss="modal"
              >
                OK
              </button>
            </div>
          </div>
        </div>
     </div>*/}
      <div
        class="modal summary-modal right fade"
        id="myModal2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="myModalLabel">
                View Summary
              </h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="d-flex bd-highlight">
                <div class="bd-highlight item-num">
                  Item #: <span>{detail_1[0].itemId}</span>
                </div>

                <div class="ml-auto view-det-link bd-highlight">
                  <div class="barcode-font">{detail_1[0].ublId}</div>
                </div>
              </div>

              <div class=" item-name mb-3"> {detail_1[0].itemDescription} </div>

              <div class="card">
                <div class="card-header">
                  <div className="row">
                    <div className="col-md-6 col-xs-12">
                      <h6>Item Summary Details</h6>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <h6>CBL:{props.cblInputText}</h6>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div className="row">
                    <div className="col-md-6 col-xs-12 mb-3">
                    <h6>Customer Order #</h6>
                      <span>{detail_1[0].saleOrderNo}</span>
                    </div>
                    <div className="col-md-6 col-xs-12 mb-3">
                      <h6>Quantity</h6>
                      <span>{detail_1[0].qty}</span>
                    </div>

                    <div className="col-md-6 col-xs-12 mb-3">
                      <h6>Supplier PO #</h6>
                      <span>{detail_1[0].supplierPo}</span>
                    </div>
                    <div className="col-md-6 col-xs-12 mb-3">
                                     <h6>Return Location</h6>
                                     <span> {user.storeNo} - {user.storeName}</span> 
                                </div>

                                <div className="col-md-6 col-xs-12 mb-3">
                                     <h6>Supplier </h6>
                                     <span>{detail_1[0].supplierNo?(detail_1[0].supplierNo):""} {detail_1[0].supplierName}</span>
                                   
                                </div>
                    <div className="col-md-6 col-xs-12 mb-3">
                      <h6>Return Reason</h6>
                      <span>{detail_1[0].returnReason}</span>
                    </div>

                    <div className="col-md-6 col-xs-12 mb-3">
                      <h6>Brand</h6>
                      <span>{detail_1[0].brand}</span>
                    </div>
                    <div className="col-md-6 col-xs-12 ">
                    <h6>Return Order#</h6>
                      <span>{detail_1[0].returnOrderNo}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade loginModal"
        id="errorModalCenter51"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-z"
          role="document"
        >
          <div className="modal-content modal-radius">
            <div className="modal-header confirmation-header">
              <h5
                className="modal-title confirmation-style"
                id="errorModalCenterTitle"
              >
                Confirmation
              </h5>
            </div>
            <div className="modal-body text-align-centre">
              <div>
                Would you like to close this <b>CBL# {props.cblInputText} </b>?
              </div>
            </div>
            <div className="modal-footer bo-modalbtnftr bt-none">
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-no"
              >
                No
              </button>
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-yes"
                onClick={(e)=>handleGoHomeScreen(e,true)}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <div
        class="modal fade"
        id="errorModalCenter51"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header bg-light-black whitetext">
              <h5 class="modal-title" id="exampleModalLongTitle">
               Info
              </h5>
            </div>
            <div class="modal-body">
              <p class="text-center">Would you like to close this <b>CBL# {props.cblInputText} </b>?</p>
            </div>
            <div class="modal-footer border-none">
              <button
                type="button"
                class="btn box-shadow-none fw5 No_button"
                data-dismiss="modal"
               
              >
                No
              </button>
              <button
                type="button"
                class="btn bgred whitetext ctbtn "
                data-dismiss="modal"
                onClick={handleGoHomeScreen}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
     </div>*/}
    </>
  );
};
const mapStateToProps = (state, ownProps) => {
  return {};
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ViewSummary);
