import Header from "components/common/Header";
import Sidebar from "components/common/Sidebar";
import $ from "jquery";
import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import * as constants from "../constants";
import data from "./data";
import { MultiSelect } from "react-multi-select-component";
import DcLocationService from "services/DcLocationService";
import LoadingIndicator from "components/common/LoadingIndicator";
import { useIdleTimer } from "react-idle-timer";
import { callLogoutURL } from "../Authentication/utilities";
import CreateShipment from "services/CreateShipmentService";
import Pagination from "components/common/Pagination";


const ModifyDcDespatch = ({ ...props }) => {
  const user = JSON.parse(localStorage.getItem("User"));
  const [timeoutDuration] = useState(localStorage.getItem('timeout') ? localStorage.getItem('timeout') : 1800000);
  const [searchResult, setSearchResult] = useState([]);
  const searchResultRef = useRef([])
  const [displayItems, setDisplayItems] = useState(false);
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [showloader, setshowloader] = useState(false);
  const [itemsAfterModifying, setItemsAfterModifying] = useState([])
  const [itemsBeforeModifying, setItemsBeforeModifying] = useState([])
  const [editedDespatchNumbers, setEditedDespatchNumbers] = useState([])
  const [ublToDelete, setUblToDelete] = useState("")
  const [carrierId, setCarrierId] = useState("")
  const [ubltoDeleteIndex, setUblToDeleteIndex] = useState("")
  const [refresh, setRefresh] = useState(false)
  const createShipment = new CreateShipment();
  const [apiErrorMessage, setAPIErrorMessage] = useState()
  const [hasDraftItems, setHasDraftItems] = useState(true)
  const [carrierlist, setcarrierlist] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const addUBLInputRef = useRef()
  const selectedSupplierNameRef = useRef()
  const selectedSupplierNoRef = useRef()
  const [supplierSelectedValues, setSupplierSelectedValues] = useState([])
  const [despatchNumberDropdown, setDespatchNumberDropdown] = useState([]);
  const [defaultdespatchNumberDropdown,setdefaultdespatchNumberDropdown]=useState([]);
  const [despatchSelectedValues, setDespatchSelectedValues] = useState([])
  const [ublInCarton, setUBLInCarton] = useState([])
  const [selectedDespatchIndex, setSelectedDespatchIndex] = useState(0)
  const selectedDespatchIndexRef = useRef(0)
  const [checked, setchecked] = useState([])
  const [selectAll, setselectall] = useState(false)
  const [currentlySelectedSupplier, setCurrentlySelectedSupplier] = useState("")
  const [errorModal, setErrorModal] = useState("")
  const [carrierName, setcarrierName] = useState("");
  const [despatchDetailsForDc, setDespatchDetailsForDc] = useState([])
  const despatchDetailsForDcRef = useRef([])
  
  const [despatchNumbers, setDespatchNumbers] = useState([
    "868998",
    "444566",
    "688565",
  ])
  const ublInCartonRef=useRef([]);
  const [viewManifestDespatchID,setviewManifestDespatchID] = useState("");
  const [currentPage_not_tagged, setCurrentPagenottagged] = useState(1);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit_not_tagged, setmaxPageNumberLimitnottagged] = useState(5);
  const [minPageNumberLimit_not_tagged, setminPageNumberLimitnottagged] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [maxPageNumberLimit_tagged, setmaxPageNumberLimittagged] = useState(5);
  //const [maxPageNumberLimit_not_tagged, setmaxPageNumberLimitnottagged] = useState(5);
  const [minPageNumberLimit_tagged, setminPageNumberLimittagged] = useState(0);
  //const [minPageNumberLimit_not_tagged, setminPageNumberLimitnottagged] = useState(0);
  const indexOfLastItem_not_tagged = currentPage_not_tagged * itemsPerPage;
  const indexOfFirstItem_not_tagged = indexOfLastItem_not_tagged - itemsPerPage;
  const history = useHistory();
  const [carrierForm, showCarrierForm] = useState(false);
  const [toggleNoItemsFound, setToggleNoItemsFound] = useState(false);
  const [toggleUblResults, setToggleUblResults] = useState(false);
  const [toggleArrows, setToggleArrows] = useState(true);
  const [invalidUblNum, setInvalidUblNum] = useState(false);
  const [cart, setCart] = useState([]);
  const [ublIds, setUblIds] = useState([]);
  const [select, setSelect] = useState(false);
  const [toggle, settoggle] = useState(true);
  useEffect(() => { }, [toggle]);

  const [ublItemId, setUblItemId] = useState({
    ublItemId: "",
  });
  const getsupplierShelfLocation = async () => {
    /* API CALL TO GET SUPPLIER SHELF LOCATION */
  };
  const getScannedItemsByNumber = async () => {
    /* API CALL TO GET SCANNED ITEMS BY NUMBER */
  };
  const handleAllCheckboxes = (e) => {
    // Handle function for select all checkboxes
    setSelect(!select);

    if (!select) {
      setCart(data);
      setUblIds(data.map((ubl, index) => ubl.UBL_ID));
    } else {
      setUblIds([]);
      setCart([]);
      setSelect(!select);
    }
  };

  if (typeof window != 'undefined') {
    console.log("listener", localStorage.getItem("access_token"));
    window.addEventListener("storage", function (e) {
      if (localStorage.getItem('access_token') == "null") {
        callLogoutURL()
        return
      }
    })
  }

  useEffect(async () => {
    console.log('callLocationService')
    //setLoader(true);
    await callDespatchDetailsService();
    //setLoader(false);

  }, [refresh])

  const callDespatchDetailsService = async () => {

    setshowloader(true)
    await DcLocationService.getDespatchDetails(user.storeNo, user.token).then((response) => {
      if (response && response.despatchDetails && response.despatchDetails && response.despatchDetails.message == "Despatch Details fetched successfully") {
        //let despatchArray = response.despatchDetails.despatchDetails;
        let despatchArray = response.despatchDetails.despatchDetails.filter((item) => {
          return item.ublStatus == "SD"
        });
        console.log("despatchArrayBefore", despatchArray)
        setDespatchDetailsForDc(despatchArray)
        despatchDetailsForDc.current = despatchArray
        //setSearchResult(despatchArray)
        let tempSupplierListDropdown = []
        let tempDespatchNumberDropdown = []
        for (let i = 0; i < despatchArray.length; i++) {
          tempDespatchNumberDropdown.push({ label: despatchArray[i].shipId + "-" + (despatchArray[i].supplierNo + " " + despatchArray[i].supplierName), value: despatchArray[i].shipId,Supplier: despatchArray[i].supplierNo })
          tempSupplierListDropdown.push({ label: (despatchArray[i].supplierNo + " " + despatchArray[i].supplierName), value: despatchArray[i].supplierNo })
        }
        setSupplier(tempSupplierListDropdown.filter((item, index) =>
          tempSupplierListDropdown.findIndex(obj => obj.value === item.value) === index))
        setDespatchNumberDropdown(tempDespatchNumberDropdown)
        setdefaultdespatchNumberDropdown(tempDespatchNumberDropdown);
        createShipment.CarrierDetails(user.token).then((response) => {
          setshowloader(false)
          let res = response.return_carrier_response.details;
          let carriername = [];
          setcarrierName(res[1].CARRIER_NAME);
          setCarrierId(res[1].CARRIER_ID)
          for (let i = 0; i < res.length; i++)
            carriername.push(res[i]);

          setcarrierlist(carriername);
          console.log("carrier name ")

        }).catch(err => console.log(err));
        
      } else {
        setshowloader(false)
      }
    }).catch((e) => {
      console.log(e)
      setshowloader(false)
    })


  }

  const createSearchResult = () => {
    console.log("despatchSelectedValues", despatchSelectedValues)
    let finalSearchResult = []
    let selectedDropdownSearchResult = []
    if(supplierSelectedValues.length>0 && despatchSelectedValues.length>0)
    { 
      console.log(1);
      let temp_select_ship_id=despatchSelectedValues.map((a,index)=>a.value); 

      for (let i = 0; i < supplierSelectedValues.length; i++) {
        selectedDropdownSearchResult.push(despatchDetailsForDc.filter(item => item.supplierNo == supplierSelectedValues[i].value && temp_select_ship_id.includes(item.shipId)))
        console.log("for", despatchDetailsForDc.filter(item => item.supplierNo == supplierSelectedValues[i].value))

      }
    }
    else if (supplierSelectedValues.length>0) {
      console.log(2);
      console.log(supplierSelectedValues)
      for (let i = 0; i < supplierSelectedValues.length; i++) {
        selectedDropdownSearchResult.push(despatchDetailsForDc.filter(item => item.supplierNo == supplierSelectedValues[i].value))
        console.log("for", despatchDetailsForDc.filter(item => item.supplierNo == supplierSelectedValues[i].value))

      }
    }
    else if (despatchSelectedValues.length>0) {
      console.log(3);
      for (let i = 0; i < despatchSelectedValues.length; i++) {
        selectedDropdownSearchResult.push(despatchDetailsForDc.filter(item => item.shipId == despatchSelectedValues[i].value))
      }
    }
    finalSearchResult = [].concat.apply([], selectedDropdownSearchResult).filter((item, index) =>
      [].concat.apply([], selectedDropdownSearchResult).findIndex(obj => obj.shipId === item.shipId) === index)
    setSearchResult(finalSearchResult)
    searchResultRef.current = finalSearchResult
    setItemsBeforeModifying(finalSearchResult)
    setchecked(new Array(finalSearchResult.length).fill(false))
    setselectall(false);
    setDisplayItems(!displayItems);
    setShowCheckboxes(false)
    showCarrierForm(false)
    console.log("finalSearchResult", finalSearchResult)
  }

  const handleSingleCheckbox = (e) => {

    let newArray = [...cart, e];
    const arrayWithFilterObjects = cart.filter((c) => c.UBL_ID === e.UBL_ID);
    console.log("handleSingleCheckbox", newArray)
    if (arrayWithFilterObjects.length === 1) {
      newArray = newArray.filter((item) => item.UBL_ID !== e.UBL_ID);
    }

    setUblIds(newArray.map((ubl, index) => ubl.UBL_ID));
    setCart(newArray);
  };
  const handleAddDespatch = async () => {
    /* API CALL TO ADD DESPATCH ITEMS TO SHELF LOCATION */
    $("#3").modal();
  };
  const optionsArray = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const handleOnchangeUblItemId = (e) => {
    const inputBarcode = e.target.value.replace(/[^\w\s]/gi, "");
    setUblItemId({
      ...ublItemId,
      [e.target.name]: inputBarcode,
    });
    if (
      inputBarcode.length === 7 &&
      !isNaN(inputBarcode) &&
      inputBarcode > 0
    ) {
      setInvalidUblNum(false);
    } else {
      setInvalidUblNum(true);
    }
  };

  useEffect(() => {
    console.log("getScannedDataInDevice added");
    if (ublItemId.ublItemId === "") setInvalidUblNum(true);
    window.addEventListener("getScannedDataInDevice", getScannedDataInDevice);
    return () => {
      console.log("getScannedDataInDevice removed");
      window.removeEventListener(
        "getScannedDataInDevice",
        getScannedDataInDevice
      );
    };
  }, []);

  const getScannedDataInDevice = (data) => {
    if (document.activeElement === addUBLInputRef.current) {
      let inputBarcode = data.detail.toString().trim();
      console.log("getScannedDataInDevice called and inputbarcode", inputBarcode);
     
      console.log("getScannedDataInDevice called and input.ublid", ublItemId.ublItemId);
      if (
        inputBarcode.length === 7 &&
        !isNaN(inputBarcode) &&
        inputBarcode > 0
      ) {
        setInvalidUblNum(false);
        console.log("addUblToCarton in scan",selectedSupplierNameRef.current + selectedSupplierNoRef.current)
        addUblToCarton(selectedSupplierNoRef.current, selectedSupplierNameRef.current, inputBarcode,true)
        // if (scannedUBL.length > 0) {
        //   console.log("getScannedDataInDevice addubltocarton called")
        //   addUblToCarton(inputBarcode, despatchNumber)
        // } else {
        //   getUBLDetails(inputBarcode)
        // }
      } else {
        setInvalidUblNum(true);
      }
    } else {
      console.log("addUblToCarton", "not focused")
    }


  };


  const getUBLDetailsOfDespatch = (index, despatchNumber) => {
    console.log("index", index)
    //setSelectedDespatchIndex(index)
    setshowloader(true)
    DcLocationService.getDespatchUBLDetails(user.storeNo, user.token, despatchNumber).then((response) => {
      // console.log("called")
      if (response && response.despatchUBLDetails && response.despatchUBLDetails.message == "UBL Details fetched successfully") {
        // console.log("called")
        setSelectedDespatchIndex(index)
        selectedDespatchIndexRef.current = index
        selectedSupplierNoRef.current = searchResult[index].supplierNo
        selectedSupplierNameRef.current = searchResult[index].supplierName
        let tempArray = response.despatchUBLDetails.despatchDetails
         tempArray.despatchNumber = despatchNumber
        // tempArray.carrierName = searchResult[index].carrierName
        // tempArray.carrierRefNo = searchResult[index].carrierRefNo
        // tempArray.appConNo = searchResult[index].appConNo
        // tempArray.supplierNo = searchResult[index].supplierNo
        // tempArray.supplierName = searchResult[index].supplierName
        setUBLInCarton(tempArray)
        ublInCartonRef.current = tempArray
        setcarrierName(searchResult[index].carrierName ? searchResult[index].carrierName : "Australia Post")
        setshowloader(false)

        //selectedDespatchIndex(index)
        // $("#EditModifyDespatch").modal();
        //console.log("appConNo", tempArray.appConNo)
        $("#collapseExample" + 1).modal()

      } else if (response && response.despatchUBLDetails.message == "UBL Details not found for the dcId") {
        setAPIErrorMessage("UBL Details not found for the dcId")
        $("#errorModalApiError").modal();
        setshowloader(false);
      }
      else {
        setshowloader(false);
      }
    }).catch((e) => {
      setshowloader(false)
    })
  }

  const addUblToCarton = (supplierNo, supplierName, barcode, isScanned) => {
    //console.log("addUblToCarton", barcode, despatchNumber, index)
    console.log("addUblToCarton", supplierNo+supplierName)
    setCurrentlySelectedSupplier(supplierNo + "|" + supplierName)
    setshowloader(true);
    DcLocationService.getUblSummary(user.storeNo, barcode ? barcode : ublItemId.ublItemId, user.token).then((response) => {
      if (response.UblSummaryDetails.details) {
        console.log("hello inside case")
        if (response.UblSummaryDetails.details[0].ublStatus == "RD" || response.UblSummaryDetails.details[0].ublStatus == "SD") {
          if (response.UblSummaryDetails.details[0].dcShipId != null && response.UblSummaryDetails.details[0].dcShipId == ublInCarton.despatchNumber) {
            setAPIErrorMessage("Scanned UBL -" + ublItemId.ublItemId + " is already part of current Despatch")
          } else {
            setAPIErrorMessage("Scanned UBL -" + ublItemId.ublItemId + " already part of another DC despatch (" + response.UblSummaryDetails.details[0].dcShipId + ")")
          }

          setshowloader(false);
          //setErrorMessage("Scanned UBL -" + ublItemId.ublItemId + " already part of another DC despatch (" + response.UblSummaryDetails.details[0].dcShipId + ")")
          $("#errorModalApiError").modal();
          console.log("RD")
        }
        else if(response.UblSummaryDetails.details[0].dcShipId != null)
        {
          setAPIErrorMessage("UBL already Despatched")
          setshowloader(false);
          $("#errorModalApiError").modal();

        }
        else if (response.UblSummaryDetails.details[0].ublStatus == "M") {

          setAPIErrorMessage("Sorry,UBL Flagged as Missing")
          setshowloader(false);
          $("#errorModalApiError").modal();

        }
        else if (response.UblSummaryDetails.details[0].ublStatus == "X") {
          setAPIErrorMessage("Sorry,UBL is in Detagged status")
          setshowloader(false);
          $("#errorModalApiError").modal();

        }

        //else if (response.UblSummaryDetails.details[0].ublStatus == "C" || response.UblSummaryDetails.details[0].ublStatus == "R") {
        else if (response.UblSummaryDetails.details[0].locationId != null) {
          // if (response.UblSummaryDetails.details[0].ublStatus == "C" && parseInt(response.UblSummaryDetails.details[0].storeId) != user.storeNo) {
          //   setAPIErrorMessage("UBL Id Not Found")
          //   $("#errorModalApiError").modal();
          //   setshowloader(false);
          //   return
          // }
          let tempItemDetail = response.UblSummaryDetails.details[0]
          tempItemDetail.despatchNumber = isScanned ? searchResultRef.current[selectedDespatchIndexRef.current].shipId : searchResult[selectedDespatchIndex].shipId
          console.log("aaa", ublInCarton)
          // if (ublInCarton.supplierNo === tempItemDetail.supplierNo) {
          if (isScanned ? searchResultRef.current[selectedDespatchIndexRef.current].supplierNo : searchResult[selectedDespatchIndex].supplierNo === tempItemDetail.supplierNo) {
            DcLocationService.addUBLShipmentDC(user.storeNo, isScanned ? searchResultRef.current[selectedDespatchIndexRef.current].shipId : searchResult[selectedDespatchIndex].shipId, tempItemDetail.ublId, user.token, user.displayName)
              .then((response) => {
                if (response && response.shipment.message == "UBL Headers updated for DC") {
                  // const newArr = [...searchResult]
                  // newArr[selectedDespatchIndex].ublCount = newArr[selectedDespatchIndex].ublCount + 1;
                  // setSearchResult(newArr)
                  // setDespatchDetailsForDc(newArr)
                  let tempSearch = searchResultRef.current
                  tempSearch[selectedDespatchIndexRef.current].ublCount = tempSearch[selectedDespatchIndexRef.current].ublCount+1
                  setSearchResult(tempSearch)
                  searchResultRef.current = tempSearch
                  setDespatchDetailsForDc(tempSearch)
                  despatchDetailsForDcRef.current = tempSearch
                  let temp = ublInCartonRef.current
                  temp = [tempItemDetail].concat(temp);
                  //const tempArray = [tempItemDetail].concat(ublInCarton);
                 // console.log("addition result before", tempArray)
                  setUBLInCarton(temp)
                  ublInCartonRef.current = temp
                  //console.log("addition result", newArr)
                  setshowloader(false);
                  setUblItemId({
                    ublItemId: ""
                  });
                } else if (response && response.shipment.message == "UBL Id Not Found") {
                  setAPIErrorMessage("UBL status doesn't allow despatch")
                  $("#errorModalApiError").modal();
                  setshowloader(false);
                } else if (response && response.shipment.message == "Scanned UBL does not belong to this supplier") {
                  setshowloader(false);
                  setErrorModal(ublItemId.ublItemId)
                  $("#errorModal1").modal()
                } else {
                  setshowloader(false);
                }
              }).catch(err => { console.log(err); setshowloader(false); })
          } else {
            setshowloader(false);
            setErrorModal(ublItemId.ublItemId)
            $("#errorModal1").modal()
          }
        } else {
          setshowloader(false);
          setAPIErrorMessage("No such UBL found")
          $("#errorModalApiError").modal();
        }
      }
      else {
        setAPIErrorMessage("No such UBL found")
        $("#errorModalApiError").modal();
        console.log("hello else case")
        setshowloader(false);
      }
    }
    ).catch(err => { console.log(err); setshowloader(false); })
  }

  const handleCarrierRefEdit = (e, index) => {
    let tempArray = [...searchResult]
    let tempDespatchArray = [...editedDespatchNumbers]
    var regex = /^[A-Za-z0-9]*$/;
    if (regex.test(e.target.value)) {
      tempArray[index].carrierRefNo = e.target.value
      if (e.target.value.length >= 10) {
        tempArray[index].appConNo = e.target.value.toString().slice(0, 10)
        if (tempArray[index].carrierId == null) {
          tempArray[index].carrierId = "2"
        }
        if (tempArray[index].carrierName == null) {
          tempArray[index].carrierName = "Australia Post"
        }
        tempArray[index].carrierConsFlag = "Y"
        if (!tempDespatchArray.includes(searchResult[index].shipId) && searchResult[index].weight != null && parseFloat(searchResult[index].weight) > 0) {
          tempDespatchArray.push(searchResult[index].shipId)
          setEditedDespatchNumbers(tempDespatchArray)
        }
      } else {
        tempArray[index].appConNo = ""
        if (tempDespatchArray.includes(searchResult[index].shipId)) {
          let arr = [...tempDespatchArray]
          arr.splice(tempDespatchArray.findIndex(item => item.shipId === searchResult[index].shipId), 1)
          setEditedDespatchNumbers(arr)
        }
      }
    }
    setSearchResult(tempArray)
    searchResultRef.current = tempArray


  }

  const handleWeight = (event, index) => {
    let tempArray = [...searchResult]
    let tempDespatchArray = [...editedDespatchNumbers]
    var t = event.target.value;
    var input = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
    // if (!isNaN(input) && parseFloat(input) > 0) {
    if (!isNaN(input)) {
      //tempArray[index].weight = parseFloat(event.target.value).toFixed(2)
      tempArray[index].weight = input
      if (!tempDespatchArray.includes(searchResult[index].shipId) && searchResult[index].carrierRefNo != null && searchResult[index].carrierRefNo.length > 10) {
        tempDespatchArray.push(searchResult[index].shipId)
        setEditedDespatchNumbers(tempDespatchArray)
      }
    } else {
      //tempArray[index].weight = ""
      if (tempDespatchArray.includes(searchResult[index].shipId)) {
        let arr = [...tempDespatchArray]
        arr.splice(tempDespatchArray.findIndex(item => item.shipId === searchResult[index].shipId), 1)
        setEditedDespatchNumbers(arr)
      }
    }
    setSearchResult(tempArray)
    searchResultRef.current = tempArray
  }

  const navigateToViewManifest = () => {

    localStorage.setItem("DespatchId-viewManifestscreen",viewManifestDespatchID);
    props.history.push("/ViewDcDespatchManifest");
  }

  const removeUblFromShipmentDC = () => {
    setshowloader(true);
    DcLocationService.removeUblFromShipmentDC(user.storeNo, ublToDelete, user.token,
      searchResult[selectedDespatchIndex].shipId,ublInCarton.length == 1?"Y":"N",ublInCarton[ubltoDeleteIndex].storeId==parseInt(user.storeNo)?"C":"R")
      .then((response) => {
        console.log("response", response)
        if (response && response.updateRemoveUblFromShipmentDC.message == "UBL Details removed from Shipment") {

          let arr = [...ublInCarton]

          if (ublInCarton.length == 1) {
            console.log("arr length equal one")
            $("#collapseExample" + 1).modal('hide')
            arr.splice(ubltoDeleteIndex, 1)
            console.log(arr.length)
          } else {
            arr.splice(ubltoDeleteIndex, 1)
          }
          if (arr.length > 0) {
            console.log("arr length greater zero")

            setUBLInCarton(arr)
            ublInCartonRef.current = arr
            let tempSearchResults = [...searchResult]
            tempSearchResults[selectedDespatchIndex].ublCount = tempSearchResults[selectedDespatchIndex].ublCount - 1
            //let tempItemsBeforeModifying = [...itemsBeforeModifying]
            setSearchResult(tempSearchResults)
            console.log("tempSearchResults", tempSearchResults)
            setDespatchDetailsForDc(tempSearchResults)
            searchResultRef.current = tempSearchResults
            despatchDetailsForDcRef.current = tempSearchResults
          } else {
            console.log("arr length lesser zero")
            setUBLInCarton([])
            ublInCartonRef.current = []
            let tempSearchResults = [...searchResult]
            let tempItemsBeforeModifying = [...itemsBeforeModifying]
            tempSearchResults.splice(selectedDespatchIndex, 1)
            tempItemsBeforeModifying.splice(selectedDespatchIndex, 1)
            if (tempSearchResults.length > 0) {
              setSelectedDespatchIndex(0)
              selectedDespatchIndexRef.current = 0
              setSearchResult(tempSearchResults)
              searchResultRef.current = tempSearchResults
              despatchDetailsForDcRef.current = tempSearchResults
              setDespatchDetailsForDc(tempSearchResults)
              setchecked(new Array(tempSearchResults.length).fill(false))
              setselectall(false);
              setItemsBeforeModifying(tempItemsBeforeModifying)
              console.log("remainder",tempSearchResults.length % itemsPerPage)
              if(currentPage_not_tagged > 1){
                if(tempSearchResults.length % itemsPerPage == 0){
                  setCurrentPagenottagged(currentPage_not_tagged - 1)
                }
              }
            } else {
              setSearchResult([])
              searchResultRef.current = []
              setItemsBeforeModifying([])
              setchecked([])
              setselectall(false);
              setDisplayItems(!displayItems)
              setShowCheckboxes(false)
              //showCarrierForm(false)
              setDespatchSelectedValues([])
              setSupplierSelectedValues([])
              setRefresh(!refresh)
            }
          }
          setshowloader(false);
          setAPIErrorMessage("UBL -" + ublToDelete + " deleted successfully")
          $("#errorModalApiError").modal();
        } else if (response && response.updateRemoveUblFromShipmentDC.message == "UBL details not found") {
          setshowloader(false);
          setAPIErrorMessage("UBL details not found")
          $("#errorModalApiError").modal();

        } else if (response && response.updateRemoveUblFromShipmentDC.message == "No UBL Id exist") {
          setshowloader(false);
          setAPIErrorMessage("No UBL Id exist")
          $("#errorModalApiError").modal();
        }
      }).catch(err => { console.log(err); setshowloader(false); })
    //setshowloader(false);
  }

  const updateCarrierDetails = () => {
    let tempArray = []
    let sdItemsWithNoCompleteCarrierDetails = []
    let tempSearchResult = [searchResult]
    console.log("tempSearchResult", tempSearchResult[0].length)
    for (let i = 0; i < tempSearchResult[0].length; i++) {
      if (tempSearchResult[0][i].ublStatus == "SD") {
        if (isCarrierDetailsComplete(tempSearchResult[0][i])) {
          // tempSearchResult[0][i].carrierName = "Australia Post"
          // tempSearchResult[0][i].carrierId = "2"
          tempSearchResult[0][i].carrierConsFlag = "Y"
          tempSearchResult[0][i].weight = tempSearchResult[0][i].weight.toString()
          tempArray.push(tempSearchResult[0][i])
          console.log("forloopsd", tempSearchResult)
        } else {
          sdItemsWithNoCompleteCarrierDetails.push([tempSearchResult[0][i]])
        }
      }
    }
    //to add if there are no items to proceed
    if (tempArray.length == 0) {
      if (sdItemsWithNoCompleteCarrierDetails.length > 0) {
        setAPIErrorMessage("Please complete the carrier details")
      } else {
        setAPIErrorMessage("All items are despatched, cannot Modify")
      }

      $("#errorModalApiError").modal();
      return
    }
    console.log("tempArray", tempArray)
    let tempTempArray = Array.from(tempArray)
    let newArray = []
    for (let i = 0; i < tempTempArray.length; i++) {
      let requestBody = {}
      requestBody.shipId = tempTempArray[i].shipId
      requestBody.carrierId = tempTempArray[i].carrierId
      requestBody.carrierName = tempTempArray[i].carrierName
      requestBody.carrierConsFlag = tempTempArray[i].carrierConsFlag
      requestBody.carrierRefNo = tempTempArray[i].carrierRefNo
      requestBody.weight = tempTempArray[i].weight.toString()
      requestBody.appConNo = tempTempArray[i].appConNo
      newArray.push(requestBody)
    }
    setshowloader(true)
    DcLocationService.modifyUpdateCarrierDetails(user.storeNo, user.token, newArray).then((response) => {
      if (response && response.updateCarrierDetailsDC && response.updateCarrierDetailsDC.message == "Carrier Details Updated Successfully for the ShipId Found") {
        let temp = [searchResult]
        console.log("temptemp", temp[0])
        setItemsAfterModifying(temp[0])
        setSearchResult(tempArray)
        searchResultRef.current = tempArray
        setchecked(new Array(tempArray.length).fill(false))
        setselectall(false);
        setShowCheckboxes(!showCheckboxes);
        showCarrierForm(!carrierForm);
        setCurrentPagenottagged(1)
        setshowloader(false)
      } else if (response && response.updateCarrierDetailsDC && response.updateCarrierDetailsDC.ship_notFound && response.updateCarrierDetailsDC.ship_notFound.length > 0){
        
      
        if(response.updateCarrierDetailsDC.ship_notFound.length < 4){
          let temptext;
          response.updateCarrierDetailsDC.ship_notFound.forEach(element => {
            temptext = temptext ? temptext + "," + element : element
          });
          setAPIErrorMessage("Carrier Name mismatch found for "+ temptext)
        } else {
          setAPIErrorMessage("Carrier Name mismatch for multiple despatches")
        }       
        $("#errorModalApiError").modal();
        setshowloader(false)
        return
       } else if (response.message = "Invalid input fields in request") {

        setAPIErrorMessage("Invalid input fields in request")
        $("#errorModalApiError").modal();
        setshowloader(false)
      }
    }).catch((e) => {
      console.log(e);
      setshowloader(false)
    })
    // setCarrierDetailsToUpdate(newArray)
  }

  const isCarrierDetailsInComplete = (item) => {

    return ((item.carrierRefNo != null && item.carrierRefNo.length < 10) || ((item.weight != null && item.weight.length == 0) || (item.weight != null && parseFloat(item.weight) <= 0)) || (item.appConNo != null && item.appConNo.length < 10))
  }

  const isCarrierDetailsComplete = (item) => {
    return (item.carrierRefNo != null && item.carrierRefNo.length >= 10) && (item.weight != null && parseFloat(item.weight) > 0)
  }

  const isCarrierDetailsInCompleteNew = (item) => {
    return ((item.carrierId && item.carrierId.length < 10) || (item.carrierName && item.carrierName.length < 10) || (item.carrierRefNo && item.carrierRefNo.length < 10) || ((item.weight && item.weight.length == 0) || (item.weight && parseFloat(item.weight) <= 0)) || (item.appConNo && item.appConNo.length < 10))
  }

  const confirmDelete = (barcode,itemIndex) => {
    setUblToDelete(barcode)
    setUblToDeleteIndex(itemIndex)
    $("#exampleModalCenter").modal()
  }

  const despatchModifiedDetails = () => {
    let temp = []
    let supplierArray = []
    let requestBodyArray = []
    for (let i = 0; i < checked.length; i++) {
      if (checked[i]) {

        temp.push(searchResult[i])
        if (!supplierArray.includes(searchResult[i].supplierNo)) {
          supplierArray.push(searchResult[i].supplierNo)

        }

      }
    }

    console.log("supplierArray", supplierArray)


    for (let i = 0; i < supplierArray.length; i++) {
      let despatchNumberGroup = []
      let requestObject = {}
      let despatchGroupItems = temp.filter((item) => item.supplierNo == supplierArray[i])
      //console.log("despatchGroupItems", despatchGroupItems)
      for (let j = 0; j < despatchGroupItems.length; j++) {
        despatchNumberGroup.push(despatchGroupItems[j].shipId)
      }
      console.log("despatchNumberGroup", despatchNumberGroup)
      requestObject.supplierNo = despatchGroupItems[0].supplierNo
      requestObject.despatchRequest = "N"
      requestObject.supplierName = despatchGroupItems[0].supplierName
      requestObject.employeeId = user.displayName
      requestObject.shipOriginName = user.storeName
      requestObject.shipStatus = "O"
      requestObject.despatchTyp = "2"
      requestObject.ublDespatchId = despatchNumberGroup
      requestBodyArray.push(requestObject)
      if(i==0)setviewManifestDespatchID(requestObject.ublDespatchId[0]);
    }
    
    console.log("requestBodyArray", JSON.stringify(requestBodyArray))
    setshowloader(true)
    DcLocationService.modifyDespatchSupplier(user.storeNo, user.token, requestBodyArray).then((response) => {
      if (response && response.despatchSupplier.details.toString().length > 0) {
        $("#ViewManifestCreateNew").modal();
        setDisplayItems(!displayItems)
        setShowCheckboxes(false)
        //showCarrierForm(false)
        setRefresh(!refresh)
        setDespatchSelectedValues([])
        setSupplierSelectedValues([])
        setSearchResult([])
        searchResultRef.current = []
        setchecked([])
        setselectall(false);
        setshowloader(false)
      } else if (response && response.errorMessage) {
        setAPIErrorMessage(response.errorMessage)
        $("#errorModalApiError").modal();
        setshowloader(false)
      }
    }).catch((e) => {
      setshowloader(false)
    })

  }

  const handleOnIdle = (event) => {
    console.log("user is idle", event);
    console.log("last active", getLastActiveTime());
    callLogoutURL();
  };

  const handleOnActive = (event) => {
    console.log("user is active", event);
    console.log("time remaining", getRemainingTime());
    handleReset();
    if (localStorage.getItem('access_token') == "null") {
      callLogoutURL()
      return
    }

  };

  const handleOnAction = (event) => {
    console.log("user did something", event);
    if (localStorage.getItem('access_token') == "null") {
      callLogoutURL()
      return
    }
  };

  const { getRemainingTime, getLastActiveTime, reset } = useIdleTimer({
    timeout: timeoutDuration,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });

  const handleReset = () => reset();
  useEffect(() => {
    if (localStorage.getItem('access_token') == "null") {
      callLogoutURL()
      return
    }
    window.addEventListener("scroll", handleOnActive);
    return () => window.removeEventListener("scroll", handleOnAction);
  }, []);
  const handleClick_not_tagged = (event) => {
    setCurrentPagenottagged(Number(event.target.id));
  };
  const handlePrevBtn_not_tagged = () => {
    setCurrentPagenottagged(currentPage_not_tagged - 1);
    if ((currentPage_not_tagged - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimitnottagged(maxPageNumberLimit_not_tagged - pageNumberLimit);
      setminPageNumberLimitnottagged(minPageNumberLimit_not_tagged - pageNumberLimit);
    }
  };
  const handleNextBtn_not_tagged = () => {
    setCurrentPagenottagged(currentPage_not_tagged + 1);

    if (currentPage_not_tagged + 1 > maxPageNumberLimit_not_tagged) {
      setmaxPageNumberLimitnottagged(maxPageNumberLimit_not_tagged + pageNumberLimit);
      setminPageNumberLimitnottagged(minPageNumberLimit_not_tagged + pageNumberLimit);
    }
  };
  return (
    <div className="OrderLookup bg-grey" id="dcdespatch_component">
      <Header />
      <div className="container-fluid">
        <div className="row">
          <Sidebar active="ModifyDcDespatch" state={user} />
          <main
            role="main"
            className="col-md-9 ml-sm-auto col-lg-10 rtn-main-wrapper p-0 bg-white"
            id="DCReceipting"
          >
            {/* page title */}
            <section className="dcreceipting-header">
              <p> DC Despatch - Modify </p>
              <div
                class="d-none d-lg-block d-md-block"
                style={{
                  float: "right",
                  marginTop: "-40px"
                }}>
                {/* {<Breadcrumb crumbs={crumbs} selected={selected} />} */}
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb" style={{ fontSize: "12px" }}>
                    <Link
                      to={{ pathname: "/LandingPage", state: user }}
                      className="breadcrumb-item breadcrumb-color pr-10"
                    >
                      Home
                    </Link>
                    <span className="pr-10"> {" >"}</span>
                    <li
                      className="breadcrumb-item active"
                      aria-current="page"
                    >
                      Modify Despatch
                    </li>
                  </ol>
                </nav>
              </div>
            </section>
            {/* Search panel for searching DC Shelf location */}
            {!displayItems && (
              <section className="search-panel col-md-6 mx-auto mt-3 mb-3">
                <div className="mb-2 putaway-input-section">
                  <form>
                    <div className="form-group row">
                      <label for="supplier" className="col-sm-3 col-form-label">
                        Supplier
                      </label>
                      <div className="col-sm-9">
                        <MultiSelect
                          options={supplier}
                          value={supplierSelectedValues}
                          onChange=
                          {
                            (item)=>{

                            setSupplierSelectedValues(item)
                            let temp_supplier = item.map((a,index)=>a.value)
                            let temp_Despatch_Dropdown=[];
                            let temp_despatch_selected_dropdown=[];
                            if(item.length>0){
                            temp_Despatch_Dropdown=defaultdespatchNumberDropdown.filter((a)=> temp_supplier.includes(a.Supplier));
                            setDespatchNumberDropdown(temp_Despatch_Dropdown);

                            if(despatchSelectedValues.length>0)
                            {
                            
                            temp_despatch_selected_dropdown=despatchSelectedValues.filter((a)=> temp_supplier.includes(a.Supplier));
                           if(temp_despatch_selected_dropdown.length>0)
                           setDespatchSelectedValues(temp_despatch_selected_dropdown);
                           else
                           setDespatchSelectedValues([]);
                            }
                          }
                            else
                            setDespatchNumberDropdown(defaultdespatchNumberDropdown);

 
                            

                            }
                          }
                          // disableSearch={true}
                          hasSelectAll={false}
                          overrideStrings={{
                            "allItemsAreSelected": "All items are selected.",
                            "clearSearch": "Clear Search",
                            "clearSelected": "Clear Selected",
                            "noOptions": "No options",
                            "search": "Search",
                            "selectAll": "Select All",
                            "selectAllFiltered": "Select All (Filtered)",
                            "selectSomeItems": "Select",
                            "create": "Create",
                          }}
                          filterOptions={(options, filter) => {
                            if (!filter) {
                              return options;
                            }

                            return options.filter(({ label }) => label.toLocaleLowerCase().includes(filter.toLocaleLowerCase()));

                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        for="despatchNumber"
                        className="col-sm-3 col-form-label"
                      >
                        DC Despatch Number
                      </label>
                      <div className="col-sm-9">
                        <MultiSelect
                          options={despatchNumberDropdown}
                          value={despatchSelectedValues}
                          onChange=
                          {setDespatchSelectedValues}
                          // disableSearch={true}
                          hasSelectAll={true}
                          overrideStrings={{
                            "allItemsAreSelected": "All items are selected.",
                            "clearSearch": "Clear Search",
                            "clearSelected": "Clear Selected",
                            "noOptions": "No options",
                            "search": "Search",
                            "selectAll": "Select All",
                            "selectAllFiltered": "Select All (Filtered)",
                            "selectSomeItems": "Select",
                            "create": "Create",
                          }}
                          filterOptions={(options, filter) => {
                            if (!filter) {
                              return options;
                            }

                            return options.filter(({ label }) => label.toLocaleLowerCase().includes(filter.toLocaleLowerCase()));

                          }}
                        />
                      </div>
                    </div>
                    <div className="text-center mt-3">
                      <button
                        type="button"
                        className="search-button mx-auto"
                        onClick={

                          createSearchResult
                        }
                        disabled={supplierSelectedValues.length == 0 && despatchSelectedValues.length == 0}
                        style={{
                          opacity: (supplierSelectedValues.length == 0 && despatchSelectedValues.length == 0) ? "0.5" : "1",
                        }}
                      >
                        Search
                      </button>
                    </div>
                  </form>
                </div>
              </section>
            )}

            {/* Scanned results for CBl Copy it from DcDespatch copy.js*/}

            <section className="scanned-ubl-results">
              {/* UBL ITEM 1  NEED TO MAP OVER THE ITEMS*/}
              {displayItems && (
                <>
                  <div className="sur-header d-flex justify-content-end align-items-center">
                    {/* <p>Scanned UBL's</p> */}
                    {showCheckboxes && (
                      <p>
                        <label className="m-2">Select All</label>
                        <input
                          type="checkbox"
                          checked={selectAll}
                          // checked ={selectAll}
                          onClick={(e) => {
                            let temp = [...checked]
                            if (selectAll) {
                              setselectall(false);
                              setchecked(new Array(checked.length).fill(false))
                            }
                            else {
                              setselectall(true);
                              setchecked(new Array(checked.length).fill(true))

                            }


                          }
                          }

                        />
                      </p>
                    )}
                  </div>

                  <div className="container" style={{minHeight:"100vh"}}>
                    <div className="row">
                      {searchResult.map((detail_1, index) => {
                        return (
                          <div className="col-md-4 col-sm-12 col-xs-12 p-0">
                            <div className="m-1 ubl-cards-col">
                              <div className="d-flex bd-highlight">
                                <div className="flex-shrink-1 p-2 bd-highlight bg-secondary modal-dialog-centered carton-box-number-border">
                                  {/* Figure tag starts */}
                                  <figure classNameName="ubl-card-image">
                                    <img
                                      classNameName="img-responsive"
                                      src="./images/bi_box-icon.svg"
                                      alt=""
                                    />
                                    <figcaption className="m-1 ubl-card-index">
                                      {detail_1.shipId.toString().trim().slice(-2)}
                                    </figcaption>
                                  </figure>
                                </div>
                                <div className="w-100 bd-highlight p-3">
                                  <div className="d-flex mb-2 bd-highlight mb-1 ">
                                    <div className="bd-highlight item-num">
                                      DC Despatch Number:
                                      <p className="mt-2 mb-2">
                                        {detail_1.shipId}
                                      </p>
                                      <div className="d-flex bd-highlight mb-2">
                                        <div className="bd-highlight item-num">
                                          No. of UBL {detail_1.ublCount}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="ml-auto view-det-link bd-highlight">
                                      {!showCheckboxes ? (

                                        <p style={{ textAlign: "right" }}>
                                          {detail_1.ublStatus == "SD" ? (
                                            <a
                                              data-toggle="collapse"
                                              role="button"
                                              aria-expanded="false"
                                              data-placement="right"
                                              aria-controls="collapseExample"
                                              onClick={() => {
                                                // $("#collapseExample" + 1).modal();
                                                setUblItemId({
                                                  ublItemId: ""
                                                });
                                                getUBLDetailsOfDespatch(index, detail_1.shipId)

                                              }}
                                            >
                                              <img
                                                src="./images/edit-icon.svg"
                                                alt="edit"
                                                className="m-2"
                                              />
                                              &nbsp;Edit
                                            </a>
                                          ) : null}

                                        </p>

                                      ) : (
                                        <p style={{ textAlign: "right" }}>
                                          <input type="checkbox" className="mr-3"
                                            checked={checked[index]}
                                            onClick={() => {
                                              var temp = checked;
                                              temp[index] = !temp[index];
                                              console.log(temp);
                                              if (temp[index] == false)
                                                setselectall(false);
                                              else
                                                if (temp.length == temp.filter(a => a == true).length)
                                                  setselectall(true);
                                              setchecked(temp);
                                              settoggle(toggle => !toggle);

                                              console.log(temp);
                                            }} />

                                        </p>
                                      )}
                                      {
                                        // detail_1.carrierName != null && detail_1.carrierName.length > 0 ? (
                                        <div>
                                          <img
                                            src="./images/truck-icon.svg"
                                            alt="box"
                                            className="img-responsive ubl-card-image"
                                          />
                                          <span
                                            style={{
                                              color: "#ff4000",
                                              fontSize: "12px",
                                              fontWeight: "normal",
                                            }}
                                          >
                                            {detail_1.carrierName ? detail_1.carrierName : "Australia Post"}
                                            {/* Australia Post */}
                                          </span>
                                        </div>
                                        // ) : null
                                      }

                                    </div>
                                  </div>

                                  {/* Item name */}

                                  <hr />
                                  {/* Supplier*/}
                                  <div className="d-flex bd-highlight">
                                    <div className="bd-highlight item-num">
                                      Supplier:
                                      <p className="mt-2 mb-2 item-num">
                                        {detail_1.supplierNo} |
                                        {detail_1.supplierName}
                                      </p>
                                    </div>
                                  </div>

                                  {/* <div className="d-flex bd-highlight mb-2">
                                    <div className="bd-highlight reason-code">
                                      <span>{detail_1.supplierName}</span>
                                    </div>
                                  </div> */}

                                  <hr />
                                  <div className="d-flex mb-2 bd-highlight mb-1  ">
                                    <div className="bd-highlight item-num w-60">
                                      <label for="carrierReference">
                                        Carrier Ref #
                                      </label>
                                      <div className="dcr-input-icon-container">
                                        <input
                                          type="text"
                                          // placeholder={detail_1.carrierRefNo}
                                          value={detail_1.carrierRefNo ? detail_1.carrierRefNo : ""}

                                          required
                                          aria-label="carrierReference"
                                          disabled={showCheckboxes ? true : detail_1.ublStatus == "RD" ? true : false}
                                          onChange={(e) => {
                                            handleCarrierRefEdit(e, index)
                                          }}
                                        />
                                        <img
                                          class="dcr-dispatch-scanner-img"
                                          src="./images/barcodescanner.png"
                                          alt=""
                                        />
                                      </div>
                                    </div>

                                    <div className="ml-auto view-det-link bd-highlight w-35">
                                      <label for="weight">Enter Weight</label>
                                      <div className="dcr-input-icon-container">
                                        <input
                                          type="text"
                                          //placeholder={detail_1.weight}
                                          aria-label="Recipient's username"
                                          aria-describedby="basic-addon2"
                                          disabled={showCheckboxes ? true : detail_1.ublStatus == "RD" ? true : false}
                                          value={detail_1.weight ?detail_1.weight : "" }
                                          style={{
                                            outline: "none !important",
                                          }}
                                          onChange={(e) => { handleWeight(e, index) }}
                                        />
                                        <span
                                          className="input-group-text"
                                          id="basic-addon2"
                                          style={{
                                            fontSize: "10px",
                                          }}
                                        >
                                          Kg
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }) .slice(indexOfFirstItem_not_tagged, indexOfLastItem_not_tagged)}
                    </div>
                    {searchResult.length > 0 ? (
              <section>
                <Pagination
                  className="pagination-bar"
                  data={searchResult}
                  currentPage={currentPage_not_tagged}
                  itemsPerPage={itemsPerPage}
                  pageNumberLimit={pageNumberLimit}
                  maxPageNumberLimit={maxPageNumberLimit_not_tagged}
                  minPageNumberLimit={minPageNumberLimit_not_tagged}
                  handleClick={handleClick_not_tagged}
                  handlePrevBtn={handlePrevBtn_not_tagged}
                  handleNextBtn={handleNextBtn_not_tagged}
                />
              </section>
            ) : null}
                  </div>
                </>
              )}
            </section>
            {/* No items found message */}

            {/* View Details */}
            {!toggleUblResults && searchResult.length > 0 ? (
              <>
                <div
                  class="modal summary-modal right fade"
                  id={"collapseExample" + 1}
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="myModalLabel"
                >
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h4 class="modal-title" id="myModalLabel">
                          Edit Summary
                        </h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>

                      <div class="modal-body">
                        <div class="d-flex bd-highlight">
                          <div class="bd-highlight item-num">
                            {/* DC Despatch Number #: <span>{ublInCarton.despatchNumber}</span> */}
                            DC Despatch Number #: <span>{searchResult[selectedDespatchIndex] ? searchResult[selectedDespatchIndex].shipId : null}</span>
                          </div>
                        </div>
                        <hr />
                        <section className="dcr-fields">
                          <div>
                            <label className="my-1 mr-3" for="carrierName">
                              <strong>Carrier Name</strong>
                            </label>

                            {/* <div className={"dcr-input-icon-container"}>
                              <select class="form-control" id="carrierName">
                                <option selected>Australia Post</option>
                              </select>
                            </div> */}

                            {/* <label className="my-1 mr-3" for="carrierName">
                              <strong>AP Con No</strong>
                            </label> */}
                            {/* <div className={"dcr-input-icon-container"}>
                              <input
                                type="text"
                                // placeholder={ublInCarton.appConNo}
                                value="Australia Post"
                                //placeholder={searchResult[selectedDespatchIndex].appConNo}
                                disabled
                                id="carrienName"
                              />
                            </div> */}

                            <div>
                              <div class="dropdown Select-form ">
                                <button class=" carrier-dc-despatch"

                                  type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  {carrierName}
                                </button>
                                <div class="dropdown-menu despatch-search-dropdown-align "
                                  style={{ width: "100%", overflowY: "auto", maxHeight: "300px", height: "max-content" }}
                                  aria-labelledby="dropdownMenuButton">
                                  {carrierlist.map((item, index) => {
                                    return (
                                      <a class="dropdown-item p10 fs12 fw6 cd"
                                        onClick={() => {
                                          let tempSearchResult = [...searchResult]
                                          tempSearchResult[selectedDespatchIndex].carrierName = item.CARRIER_NAME
                                          tempSearchResult[selectedDespatchIndex].carrierId = item.CARRIER_ID.toString()
                                          setSearchResult(tempSearchResult)
                                          searchResultRef.current = tempSearchResult
                                          setCarrierId(item.CARRIER_ID)
                                         setcarrierName(item.CARRIER_NAME)
                                        }}>{item.CARRIER_NAME}</a>
                                    );
                                  })}

                                </div>
                              </div>
                            </div>

                            <label className="my-1 mr-3" for="apConNo">
                              <strong>AP Con No</strong>
                            </label>
                            <div className={"dcr-input-icon-container"}>
                              <input
                                type="text"
                                // placeholder={ublInCarton.appConNo}
                                placeholder={searchResult[selectedDespatchIndex].appConNo ? searchResult[selectedDespatchIndex].appConNo : ""}
                                disabled
                                id="apConNo"
                              />
                              {/* <img
                                class="dcr-dispatch-scanner-img"
                                src="./images/barcodescanner.png"
                                alt=""
                              /> */}
                            </div>
                            <form
                              onSubmit={(e) => {
                                e.preventDefault();
                                // alert("dfjdk");
                                let inputBarcode = ublItemId.ublItemId;
                                if (inputBarcode.length === 7 && !isNaN(inputBarcode) && inputBarcode > 0) {
                                  setInvalidUblNum(false);
                                }
                                else {
                                  setInvalidUblNum(true);
                                  return;
                                }
                                addUblToCarton(searchResult[selectedDespatchIndex].supplierNo, searchResult[selectedDespatchIndex].supplierName, "",false)
                              }}
                            >
                              <label className="my-1 mr-3" for="ublItemId">
                                <strong>Add UBL</strong>
                              </label>
                              <div className={"dcr-input-icon-container"}>
                                <input
                                  id="ublItemId"
                                  autocomplete="off"
                                  type="text"
                                  required
                                  name="ublItemId"
                                  placeholder="Scan or Enter Items"
                                  aria-label="Text input with dropdown button"
                                  value={ublItemId.ublItemId}
                                  //onChange={handleOnchangeUblItemId}
                                  onChange={(e) => {
                                    const inputBarcode = e.target.value.replace(/[^\w\s]/gi, "");
                                    setUblItemId({
                                      ...ublItemId,
                                      [e.target.name]: inputBarcode,
                                    });
                                    setInvalidUblNum(false);
                                  }}
                                  onBlur={handleOnchangeUblItemId}
                                  maxLength={7}
                                  ref={addUBLInputRef}
                                />
                                <img
                                  class="dcr-dispatch-scanner-img"
                                  src="./images/barcodescanner.png"
                                  alt=""
                                />
                              </div>
                            </form>
                            {invalidUblNum && ublItemId.ublItemId !== "" ? (
                              <div className="invalid-ubl-num">
                                <img src="./images/error-icon.svg" alt="" />
                                Invalid Barcode!
                              </div>
                            ) : null}
                          </div>
                        </section>
                        <section
                          className="d-flex justify-content-between align-items-center edit-summary-details"
                          onClick={() => setToggleArrows(!toggleArrows)}
                        >
                          {/* UBL ITEM 1  NEED TP MAP OVER THE ITEMS*/}

                          <strong>View Added UBL ({ublInCarton.length})</strong>
                          {toggleArrows ? (
                            <a
                              href="#"
                              onClick={() => setToggleArrows(!toggleArrows)}
                            >
                              <img
                                src="./images/down-arrow-icon-black.svg"
                                alt="downArrow"
                                className="m-2"
                              />
                            </a>
                          ) : (
                            <a
                              href="#"
                              onClick={() => setToggleArrows(!toggleArrows)}
                            >
                              <img
                                src="./images/up-arrow-icon.svg"
                                alt="upArrow"
                                className="m-2"
                              />
                            </a>
                          )}
                        </section>
                        {toggleArrows && (
                          <section>
                            {ublInCarton.map((detail_1, index) => {
                              return (
                                <div className="sur-list" key={index}>
                                  <div>
                                    <span className="tile-item">Item #: </span>
                                    <span>{detail_1.itemId}</span>
                                    <span>
                                      <a
                                        data-toggle="collapse"
                                        role="button"
                                        aria-expanded="false"
                                        data-placement="right"
                                        aria-controls="collapseExample"
                                        onClick={() => {
                                          //$("#3").modal();
                                          confirmDelete(detail_1.ublId, index)
                                         // removeUblFromShipmentDC(detail_1.ublId, index)
                                        }}
                                      >
                                        <img
                                          src="./images/delete-icon.svg"
                                          alt="delete"
                                        />
                                      </a>
                                    </span>

                                    <p className="bd-highlight item-name">
                                      {detail_1.itemDescription}
                                    </p>
                                    {/* <p>
                                      {ublInCarton.supplierNo} |
                                      {ublInCarton.supplierName}
                                    </p> */}
                                    <p>
                                      {searchResult[selectedDespatchIndex].supplierNo} |
                                      {searchResult[selectedDespatchIndex].supplierName}
                                    </p>
                                  </div>
                                  <div className="container">
                                    <div className="row sur-bc-view d-flex bd-highlight mt-3 p-0 m-0">
                                      <div className="p-2 col-4 bd-highlight">
                                        <p className="barcode-font barcode-font-size">
                                          {detail_1.ublId}
                                        </p>
                                      </div>
                                      <div class="p-2 col-8 bd-highlight mt-0">
                                        <label className="bd-highlight brand mb-0">
                                          {/* {detail_1.BRAND} */}
                                          BRAND
                                        </label>
                                        <p className="brand-desc">
                                          {detail_1.brand}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="sur-bc-view">
                                    <p className="barcode-font barcode-font-size">
                                      {detail_1.SUPPLIER_ORDER_NUMBER}
                                    </p>
                                    <a>{detail_1.BRAND}</a>
                                  </div> */}
                                </div>
                              );
                            })}
                          </section>
                        )}
                      </div>
                    </div>
                  </div>
                  {showloader ? <LoadingIndicator /> : null}
                </div>
              </>
            ) : null}
            {/* Confirmation UBL Modal */}
            <div
                        className="modal fade loginModal modal-transparent"
                        id="exampleModalCenter"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="errorModalCenterTitle"
                        aria-hidden="true"
                    >
                        <div
                            className="modal-dialog modal-dialog-centered modal-z"
                            role="document"
                        >
                            <div className="modal-content modal-radius">
                                <div className="modal-header confirmation-header">
                                    <h5
                                        className="modal-title confirmation-style"
                                        id="errorModalCenterTitle"
                                    >
                                        Confirmation
                                    </h5>
                                </div>
                                <div className="modal-body text-align-centre">
                                    <div>
                                        {" "}
                                        Would you like to remove this <b>UBL# {ublToDelete}</b>?{" "}
                                    </div>
                                </div>
                                <div className="modal-footer bo-modalbtnftr bt-none">
                                    <button
                                        data-dismiss="modal"
                                        type="button"
                                        className=" btn bo-btnprimary confirm-no"
                                    >
                                        No
                                    </button>
                                    <button
                                        data-dismiss="modal"
                                        type="button"
                                        className=" btn bo-btnprimary confirm-yes"
                                        onClick={(e) =>
                                            removeUblFromShipmentDC()
                                        }
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
            <div
              className="modal fade"
              id="3"
              tabindex="-1"
              role="dialog"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content dcr-modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Alert</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body dcr-modal-confirmation">
                    UBL ITEM ID - 000778669000387 <br />
                    <br />
                    Deleted Successfully
                  </div>
                  <div className="modal-footer border-none">
                    <button
                      type="button"
                      className="btn bgred whitetext ctbtn "
                      data-dismiss="modal"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* <div
              className="modal fade"
              id="ViewManifestCreateNew"
              tabindex="-3"
              role="dialog"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content dcr-modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Success</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body dcr-modal-confirmation">
                    Despatched Successfully
                  </div>
                  <div className="modal-footer mx-auto border-none">
                    <button
                      type="button"
                      className="btn bgred whitetext ctbtn"
                      data-dismiss="modal"
                      onClick={() => history.push("/ViewDcDespatchManifest")}
                    >
                      View Manifest
                    </button>

                    <button
                      type="button"
                      className="btn bgred whitetext ctbtn "
                      data-dismiss="modal"
                      onClick={() => history.push("/CreateDcDespatch")}
                    >
                      Create New
                    </button>
                  </div>
                </div>
              </div>
            </div> */}
            <div
              className="modal fade"
              id="ViewManifestCreateNew"
              tabindex="-1"
              role="dialog"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content dcr-modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Success</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="tickmark-dc-despatch" >
                    <img src="./images/tick-circle-icon.svg" alt="" />
                  </div>
                  <div className="draft-modal-confirmation">
                    Despatched Successfully

                  </div>
                  <div className="modal-footer border-none">
                    <button
                      type="button"
                      className="btn bgred whitetext ctbtn-white-bg "
                      data-dismiss="modal"
                      onClick={navigateToViewManifest}
                    >
                      View Manifest
                    </button>
                    <button
                      type="button"
                      className="btn bgred whitetext ctbtn "
                      data-dismiss="modal"
                      onClick={() => history.push("/CreateDcDespatch")}
                    >
                      Create New
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade modal-transparent"
              
              tabindex="-1"
              role="dialog"
              id="errorModal1"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content dcr-modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Alert</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body dcr-modal-confirmation scanned-ubl-modal-content">
                    <div className="scanned-ubl">
                      <div className="mr-5">Scanned</div>
                      <div className="fw-600">UBL - {errorModal} </div>
                    </div> <br />
                    <div className="c-red">
                      does not belong to this Supplier
                    </div>
                    <br />
                    <div className="fw-600 mt-7">
                      {currentlySelectedSupplier}
                    </div>
                  </div>
                  <div className="modal-footer border-none">
                    <button
                      type="button"
                      className="btn bgred whitetext ctbtn "
                      data-dismiss="modal"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade modal-transparent"
              id="errorModalApiError"
              tabindex="-1"
              role="dialog"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content dcr-modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Alert</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body dcr-modal-confirmation">
                    {apiErrorMessage}
                  </div>
                  <div className="modal-footer border-none">
                    <button
                      type="button"
                      className="btn bgred whitetext ctbtn "
                      data-dismiss="modal"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* BOTTOM Section to Dispaly Buttons*/}

            {/* Back and Complete Receiving buttons */}
            {displayItems ? (
              <div className="container-fluid section-four footer-position">
                <div className="float-right">
                  <button
                    onClick={() => {
                      setSelectedDespatchIndex(0)
                      selectedDespatchIndexRef.current = 0
                      setCurrentPagenottagged(1)
                      if (!carrierForm) {
                        setDespatchSelectedValues([])
                        setSupplierSelectedValues([])
                        setRefresh(!refresh)
                        setDisplayItems(!displayItems)
                      } else {
                        setSearchResult(itemsAfterModifying)
                        searchResultRef.current = itemsAfterModifying
                        setShowCheckboxes(!showCheckboxes)
                        showCarrierForm(!carrierForm)
                      }
                    }}
                    className="back-button"

                  >
                    Back
                  </button>
                  {!carrierForm ? (
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedDespatchIndex(0)
                        selectedDespatchIndexRef.current = 0
                        updateCarrierDetails()
                        // setShowCheckboxes(!showCheckboxes);
                        // showCarrierForm(!carrierForm);
                      }}
                      className="add-carrier-btn next-button fs12"
                      disabled={editedDespatchNumbers.length == 0 && !hasDraftItems}
                      style={{
                        opacity: (editedDespatchNumbers.length == 0 && !hasDraftItems) ? "0.5" : "1",
                      }}
                    >
                      Proceed
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => {
                        // $("#collapseExample" + 1).modal();
                        // $("#66").modal();
                        despatchModifiedDetails()
                      }}
                      disabled={checked.filter((a, index) => a == true).length == 0}
                      style={{
                        opacity: (checked.filter((a, index) => a == true).length == 0) ? "0.5" : "1",
                      }}
                      className="add-carrier-btn next-button fs12"
                    >
                      Despatch
                    </button>
                  )}
                </div>
              </div>
            ) : null}
          </main>
        </div>
      </div>
      {showloader ? <LoadingIndicator /> : null}
    </div>
  );
};

export default ModifyDcDespatch;
