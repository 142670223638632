import React, { useEffect, useState } from "react";
import * as constants from "../../constants";
import $ from "jquery";
import ReactTooltip from "react-tooltip";
import ViewDetailsItem from "components/common/ViewDetailsItemDC"
import DCRetagDetag from "./DCRetagDetag";
import { TrainRounded } from "@mui/icons-material";

const DCCreateDespatchCartModify = ({ ...props }) => {
  const [Barcode, setBarCode] = useState("");
  const [edit, setEdit] = useState(false);
  const [barcodeValid, setBarcodeValidation] = useState(true);
  const [flag,setflag] = useState(props.tagged)
  React.useEffect(() => {
    $(document).click(function (e) {
      if (!$(e.target).is('.panel-body')) {
        $('.collapse').collapse('hide');
      }
    })
  });
  useEffect(() => {
    console.log('useeffect in cartModify')
    setBarCode("");
    setEdit(false);
    setBarcodeValidation(true)
  }, [props.detail.itemNum, props.tagged]);

  const onItemTagClick_not_tagged_item = (event) => {
    event.preventDefault();
    (Barcode && barcodeValid) ? props.handle_taging(props.detail.itemNum, props.index, Barcode, props.tagged) : setBarcodeValidation(false)
  };

  const onItemTagClick_tagged_item = (event) => {
    event.preventDefault();
    (Barcode && barcodeValid) ? props.handle_taging(props.detail.itemNum, props.index, Barcode, props.tagged) : setBarcodeValidation(false)
    setEdit(false);
  };

  const detagItem = () => {
    props.detagItem()
  }

  const onRetag = (itemNum, index, barcode, tagged) =>{
    props.handle_taging(itemNum, index, barcode, tagged)
  }

  const onBarcodeChange = (event) => {
    setBarCode(event.target.value);
    var inputBarcode = event.target.value.toString().trim()
    setBarCode(inputBarcode.replace(/\s/g, ''))
    if (inputBarcode.length === 7 && !isNaN(inputBarcode) 
            && inputBarcode > 0) {
      console.log("valid")
      setBarcodeValidation(true)
    } else if (inputBarcode.length == 0) {
      setBarcodeValidation(true)
    }
    else {
      setBarcodeValidation(false)
    }
  };

  const isCheckDigitValid = (barcode) => {
    var oddSum = 0;
    var evenSum = 0;
    for (var i = 0; i < barcode.length; i++) {
      if ((i % 2) == 1) {
        oddSum = parseInt(barcode.charAt(i)) + oddSum;
      } else {
        evenSum = parseInt(barcode.charAt(i)) + evenSum
      }
    }
    var result = (oddSum + evenSum) % 10;
    console.log(oddSum, evenSum, result);
    if ((10 - result) == barcode.charAt(19)) {
      return true
    } else {
      return false
    }
  }

  const showDetails = () => {
      props.showDetails(props.index)
  }

  return (
    <div className="col-md-4 col-lg-4 col-sm-4 card-padding ">
      <section className="card-parent-group position-relative">
        <div className="card-content p-2">
          {/* item id */}
          <div className="d-flex mb-1 bd-highlight pb-item-num">
            <div className="bd-highlight item-num">
              Item #: <span>{props.detail.itemNum}</span>
            </div>
            <div className="ml-auto view-det-link bd-highlight">
              <a
                href=""
                data-toggle="modal"
                href={"#collapseExample" + props.index}
                role="button"
                aria-expanded="false"
                data-placement="right"
                aria-controls="collapseExample"
                id="ViewDetails"
                //onClick = {showDetails}
                class="text-decoration-none"
                >
                  {" "}
                  <i class="fas fa-eye" style={{paddingRight:"2px"}}></i>View
              </a>
            </div>
          </div>

          <ViewDetailsItem detail_1={props.detail} index={props.index} />

          {/* Item name */}
          <div className="d-flex bd-highlight mb-1" >
            <div className="bd-highlight item-name" data-tip={props.detail.itemDesc} data-for="registerTip">
              {" "}
              {props.detail.itemDesc}
              <ReactTooltip id="registerTip" place="top" effect="solid" data-type="info" />
            </div>
          </div>


          {/* Reason and qty*/}
          <div className="d-flex bd-highlight mb-1" >
            <div className="bd-highlight reason-code" >
              Reason: <span className = "pl-2" data-tip={props.detail.reasonCode} data-for="reason">{props.detail.reasonCode}
              </span>
              <ReactTooltip id="reason" place="top" effect="solid" data-type="info" />
            </div>
            <div className="ml-auto item-qty bd-highlight">
              Qty to return: <span>{props.detail.qty}</span>
            </div>
          </div>


          {/* Item name */}
          <div className="d-flex bd-highlight mb-1">
            <div className="bd-highlight item-price">
              {" "}
              {props.detail.retailPrice}{" "}
            </div>
          </div>

          <hr />

          {/* Item name */}

          <form className="item-level-tag">
            <div className="form-row item-level-tag">
              <div className="col-md-6 col-lg-6 col-sm-6 ">
                <input
                  type="text"
                  className="form-control lighten "
                  style={{fontSize:"12px"}}
                  placeholder={props.detail.ublId}
                 
                  value={Barcode}
                  onChange={onBarcodeChange}
                  disabled={true}
                />
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6 ">
                <button
                  id="itemtag"
                  type="submit"
                  className={true? edit ? "btn-item-tagged-edit" : "btn-item-tagged" : "btn btn-item-tag"}
                  onClick={true ? onItemTagClick_tagged_item : onItemTagClick_not_tagged_item}
                  //disabled={(props.tagged & (!edit))}
                  disabled ={true}
                >
                  Item Tag
                </button>
                {true ?
                  <button //onClick={() => setEdit(true)}
                    className={"btn-item-edit"}
                    type="button"
                    id="editButton"
                    data-toggle="modal" data-target={"#myModal2"+props.index}
                    onClick={()=>setflag(!flag)}
                  >
                    Edit
                  </button> : null
                }
                

              </div>
              <img src="./images/error-icon.svg" alt="" hidden={barcodeValid} />
              <div className="barcode-validation-failed" hidden={barcodeValid}>Barcode number validation failed</div>
            </div>

          </form>
        </div>
      </section>
      <DCRetagDetag itemDetails={props.detail} index={props.index} detagItem = {detagItem} onRetag = {onRetag} reset ={props.tagged} flag= {flag}/>
    </div>
  );
};

export default DCCreateDespatchCartModify;
