import React, { useCallback, useState } from "react";

const Tabs = ({ children }) => {
  const [activeTab, setActiveTab] = useState(children[0].props.label);
  const handleActiveTab = useCallback((label) => {
    setActiveTab(label, []);
  });
  const tabs = children.map((child) => (
    <button
      onClick={(e) => {
        e.preventDefault();
        handleActiveTab(child.props.label);
      }}
      className={
        child.props.label === activeTab
          ? ["tabs__tab", "tabs__tab-active"].join(" ")
          : "tabs__tab"
      }
      key={child.props.label}
    >
      {child.props.tabName}
    </button>
  ));
  const tabContent = children.filter(
    (child) => child.props.label === activeTab
  );
  return (
    <div> {/*className="tabs-container">*/}
      <div className="tabs_box">{tabs}
      <hr class ="mt-1"/>
      </div>
      <div>{tabContent}</div>
    </div>
  );
};

const Tab = (props) => {
  return <>{props.children}</>;
};

export { Tabs, Tab };
