import {
  faEye,
  faMapMarkerAlt,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingIndicator from "components/common/LoadingIndicator";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Pagination from "components/common/Pagination";
import * as constants from "components/constants";
import $ from "jquery";
const Destination = ({
  loadCblDestination,
  handleRemoveItem,
  handleGoPreviousScreen,
  handleGoNextScreen,
  handleViewUblSummary,
  handleAddUbl,
  viewUbl,
  handleOnUblTextChange,
  cbl,
  cart,
  ...props
}) => {
  const user = JSON.parse(localStorage.getItem("User"));
  const cublId = localStorage.getItem("CUBLID");
  const [deleteindex, setDeleteindex] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [barcodevalid,setbarcodevalid]=useState(true);
  const [show,setshow]=useState(false);
  

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  useEffect(()=>{
    $('#AddUblForm').click( ()=>{
      validate({target:{value:""}});
      handleOnUblTextChange({target:{value:""}});
     
      
    })
  },[]);
  

  useEffect(() => {}, [currentPage]);
  console.log("DESTINATION", viewUbl);

  const renderSelectedCards = (data) => {
    return (
      <>
        {data.map((detail_1, index) => {
          return (
            <div className="col-md-4 col-lg-4 col-sm-4 card-padding">
              <div className="card-content-ubl p-2">
                {/* item id */}
                <div className="d-flex mb-1 bd-highlight">
                  <div className="bd-highlight item-id">
                    Item #: <span>{detail_1.ITEM_ID}</span>
                  </div>
                  <button
                    class="delete-icon"
                    id="removeBtn"
                    data-toggle="modal"
                    value={index}
                    data-target="#exampleModalCenter"
                    onClick={() => setDeleteindex(detail_1.UBL_ID)}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </div>

                {/* Item name */}
                <div className="d-flex bd-highlight mb-1"
                style={{
                  height:"30px",
                  overflow:"hidden"
                }}
                >
                  <div className="bd-highlight item-ublname">
                    {detail_1.ITEM_DESCR}
                  </div>
                </div>

                <hr />

                {/* UBL ID */}
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div style={{}}>
                    <div> <p class="barcode-font" 
                        style={{fontSize:28,
                        marginTop:15,
                        marginLeft:5}}>{detail_1.UBL_ID}</p></div>
                    </div>
                  </div>

                  <div
                    className="ml-auto view-det-link bd-highlight"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button
                      class="border-none bg-none bluec viewbtn view"
                      onClick={() => handleViewUblSummary(detail_1.UBL_ID)}
                    >
                      <FontAwesomeIcon icon={faEye} className="view" /> View
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        }).slice(indexOfFirstItem, indexOfLastItem)}
      </>
    );
  };


  const handleClick = (event) => {
    setCurrentPage(Number(event.target.id));
  };

  const handlePrevBtn = () => {
    setCurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };
  const handleNextBtn = () => {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };
  const validate=(event) => {
    
    var inputBarcode = event.target.value.toString().trim()
   
    if (inputBarcode.length === 7 && inputBarcode > 0) {
      setbarcodevalid(true);
    } else if (inputBarcode.length == 0) {
      setbarcodevalid(true);
    }
    else {
      setbarcodevalid(false);
    }
  };



  return (
    <>
      <div className="col-md-10 mbtm"> 
        <div className="bggray card-sect2_cr">
          <form onSubmit={handleAddUbl}>
            <div className="row">
              <div className="col-md-8 flex">
                <p class="pl24 pt10 fw6 fs12 label">Destination :</p>
                <div class="form-group" >
              <div class="input-group w100 " style={{ width: "60%" }}>
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  style={{
                    position: "absolute",
                    top: "15px",
                    left: "17px",
                    zIndex: "2",
                  }}
                />
                <input
                  type="text"
                  required=""
                  id="icon"
                  className="form-control cblInput dest"
                  autocomplete="off"
                  name="search"
                  style={{borderRight:"0.25px solid rgb(189, 189, 189)"}}
                  disabled
                  placeholder={`${props.dcname}`}
                />
              </div>
            </div>
              </div>
              <div className="col-md-4 add">
                <input
                  type="submit"
                 
                  value="+ Add UBL"
                  
                  data-toggle="dropdown"
                  id="AddUblForm"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className="addbtnc"
                  style={{marginRight: "10px",float: "right" }}
                  
                />
                <section className="col-12  mb-12 dropdown-menu notification add-btn-toggle"
                // style={{
                //   marginRight: "50px"
                // }}
                id="add_option"
                >
                  <label
                    className="my-1 mr-3"
                    for="ublInput"
                   // style={{ padding: "40px" }}
                  >
                    Add Unique Barcode Label
                  </label>

                  <input
                    type="text"
                    required
                    className="form-control input-search ref_in mb-2"
                    id="ublInput"
                    name="ublInput"
                   
                    placeholder="Add UBL"
                    autocomplete="off"
                    value={props.ublInputText}
                    aria-label="Text input with dropdown button"
                    onChange={(e)=>{validate(e);handleOnUblTextChange(e);}}
                    onFocus={(e)=>{setshow(true)}}
                    onBlur={(e)=>setshow(false)}
                  />
                  <img
                    src="./images/error-icon.svg"
                    alt=""
                    hidden={barcodevalid || show }
                  />
                  <span
                    className="barcode-validation-failed"
                    hidden={barcodevalid || show}
                  >
                    Invalid Barcode !
                  </span>
                  <div className = "text-center">
                    <input
                      type="submit"
                      value="Add"
                      disabled={!barcodevalid || props.ublInputText.length==0}
                      className="add-carrier-btn"
                    />
                  </div>
                </section>
              </div>
            </div>
          </form>
        </div>
        <div className="card2 firstScreen show">
          <h6 className="cbl-text f14 fw5">CBL#:{props.cblInputText}</h6>
          <div className="cbl-container" style={{ height: "auto" }}>
          {cart.length > 0 ?(<>
            <div className="rtn-card-tiles">
              <div className="container">
                <div className="row">
                  {cart.length === 0 ? (
                    <p>No UBL ID found</p>
                  ) : (
                    renderSelectedCards(cart)
                  )}
                </div>
              </div>
            </div>
            <section className="paginat">
              <Pagination
                className="pagination-bar"
                data={cart}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                pageNumberLimit={pageNumberLimit}
                maxPageNumberLimit={maxPageNumberLimit}
                minPageNumberLimit={minPageNumberLimit}
                handleClick={handleClick}
                handlePrevBtn={handlePrevBtn}
                handleNextBtn={handleNextBtn}
              />
            </section>
            </>
            ):(<div className="img-with-text"
                            style={{paddingTop:"20px",margin:"0px"}}
                            >
                <img src="./images/Item-not-found.svg" alt="" />
                <p className="no-item-found">No Item found</p>
              </div>)}
          </div>
        </div>
      </div>
      <div class="container-fluid section-four"
      style={{marginTop:"13vh"}}>
        <div class="float-right">
        <input
              type="button"
              value="Exit"
            class="cancel-btn fs12"s
            onClick={(e)=>props.cancel(false)}
            />
          <input
            type="button"
            value="View Summary"
            onClick={handleGoNextScreen}
            class="add-carrier-btn next-button fs12"
          />
        </div>
      </div>
      {/*<div
        className="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header bg-light-black whitetext">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Alert
              </h5>
            </div>
            <div className="modal-body">
              <p className="text-center">
                {" "}
                Would you like to remove this <b>UBL# {deleteindex}</b>?{" "}
              </p>
            </div>
            <div className="modal-footer border-none">
              <button
                type="button"
                className="btn box-shadow-none fw5c No_button"
                data-dismiss="modal"
              >
                No
              </button>
              <button
                type="button"
                className="btn bgred whitetext ctbtn "
                data-dismiss="modal"
                onClick={(e) =>
                  handleRemoveItem(
                    user.storeNo,
                    props.cblInputText,
                    deleteindex,
                    user.token
                  )
                }
              >
                Yes
              </button>
            </div>
          </div>
        </div>
              </div>*/}
      
      <div
                className="modal fade loginModal"
                id="exampleModalCenter"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="errorModalCenterTitle"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-z" role="document">
                    <div className="modal-content modal-radius">
                        <div className="modal-header confirmation-header">
                            <h5 className="modal-title confirmation-style" id="errorModalCenterTitle">
                           Confirmation
                            </h5>

                        </div>
                        <div className="modal-body text-align-centre" >
                            <div> {" "}
                Would you like to remove this <b>UBL# {deleteindex}</b>?{" "}</div>
                        </div>
                        <div className="modal-footer bo-modalbtnftr bt-none">

                            <button
                                data-dismiss="modal"
                                type="button"
                                className=" btn bo-btnprimary confirm-no"
                            >
                                No
                            </button>
                            <button
                                data-dismiss="modal"
                                type="button"
                                className=" btn bo-btnprimary confirm-yes"
                                onClick={(e) =>
                                  handleRemoveItem(
                                    user.storeNo,
                                    props.cblInputText,
                                    deleteindex,
                                    user.token
                                  )
                                }
                            >
                                Yes
                            </button>
                        </div>
                    </div>
                </div>
            </div>




      <div
        className="modal fade"
        id="errorModalCenter2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header bg-light-black whitetext">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Alert
              </h5>
            </div>
            <div className="modal-body">
              <p className="text-center"> {props.message.deleteCblMessage} </p>
            </div>
            <div className="modal-footer border-none">
              <button
                type="button"
                className="btn bgred whitetext ctbtn "
                data-dismiss="modal"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="errorModalCenter3"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header bg-light-black whitetext">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Alert
              </h5>
            </div>
            <div className="modal-body">
              <p className="text-center"> {props.message2.cublMapToUbl} </p>
            </div>
            <div className="modal-footer border-none">
              <button
                type="button"
                className="btn bgred whitetext ctbtn "
                data-dismiss="modal"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade loginModal"
        id="errorModalCenter51"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-z"
          role="document"
        >
          <div className="modal-content modal-radius">
            <div className="modal-header confirmation-header">
              <h5
                className="modal-title confirmation-style"
                id="errorModalCenterTitle"
              >
                Confirmation
              </h5>
            </div>
            <div className="modal-body text-align-centre">
              <div>
                Would you like to close this <b>CBL# {props.cblInputText} </b>?
              </div>
            </div>
            <div className="modal-footer bo-modalbtnftr bt-none">
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-no"
              >
                No
              </button>
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-yes"
             
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>


      <div class="modal summary-modal right fade"
             id="myModal2"
             tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
            <div class="modal-dialog" role="document">
                <div class="modal-content">

                    <div class="modal-header">
                        
                        <h4 class="modal-title" id="myModalLabel">View Summary</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                          </button>
                    </div>
                    
                    {viewUbl !== null && viewUbl.details != ""
              ? viewUbl.details.map((viewubl, index) => {
                  return (
                    <div class="modal-body">
<div class="d-flex bd-highlight">
                        <div class="bd-highlight item-num">
                            Item #: <span>{viewubl.itemId}</span>
                        </div>
                       
                        <div class="ml-auto view-det-link bd-highlight">
                          <div class="barcode-font">{viewubl.ublId}</div>
                            </div>
                        </div>

                      <div class=" item-name mb-3">
                        {" "}
                        {viewubl.itemDescription}{" "}
                    </div>

                    <div class="card">
                        <div class="card-header">
                            <div className="row">
                                <div className="col-md-6 col-xs-12">
                                     <h6>Item Summary Details</h6>
                                </div>
                                <div className="col-md-6 col-xs-12">
                                     <h6>CBL:{props.cblInputText}</h6>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div className="row">
                                <div className="col-md-6 col-xs-12 mb-3">
                                <h6>Customer Order #</h6>
                                     <span>{viewubl.saleOrderNo}</span>
                                </div>
                                <div className="col-md-6 col-xs-12 mb-3">
                                     <h6>Quantity</h6>
                                     <span>{viewubl.qty}</span>
                                </div>

                                <div className="col-md-6 col-xs-12 mb-3">
                                     <h6>Supplier PO #</h6>
                                     <span>{viewubl.supplierPo}</span>
                                </div>
                                <div className="col-md-6 col-xs-12 mb-3">
                                     <h6>Return Location</h6>
                                     <span> {user.storeNo} - {user.storeName}</span> 
                                </div>

                                <div className="col-md-6 col-xs-12 mb-3">
                                     <h6>Supplier </h6>
                                     <span>{viewubl.supplierNo?(viewubl.supplierNo):""} {viewubl.supplierName}</span>
                                   
                                </div>
                                <div className="col-md-6 col-xs-12 mb-3">
                                     <h6>Return Reason</h6>
                                     <span>{viewubl.returnReason}</span>
                                </div>

                                <div className="col-md-6 col-xs-12 mb-3">
                                     <h6>Brand</h6>
                                     <span>{viewubl.brand}</span>
                                </div>
                                <div className="col-md-6 col-xs-12 ">
                                <h6>Return Order #</h6>
                                     <span>{viewubl.returnOrderNo}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                  );
                })
              : null}
            

                </div>
            </div>
        </div>
          </>
  );
};
const mapStateToProps = (state, ownProps) => {
  return {};
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Destination);
