import React, { useState,useRef } from "react";
import $ from "jquery";
import ReactTooltip from "react-tooltip";
import ViewDetailOrderLookUp from "./ViewDetailOrderLookUp";
const Cart = ({ ...props }) => {
  const user = JSON.parse(localStorage.getItem("User"));
  const [Barcode, setBarCode] = useState("");
  const [barcodeValid, setBarcodeValidation] = useState(true);
  const [barcodeInFocus, setBarcodeInFocus] = useState(false);
  const focusref=useRef();
  const fun_ref=useRef();
  React.useEffect(() => {setBarCode("");setBarcodeValidation(true)},[props.detail.id]);

  React.useEffect(() => {
    $(document).click(function (e) {
      if (!$(e.target).is('.panel-body')) {
        $('.collapse5').collapse('hide');
      }
    })
  });

  const onBarcodeChange = (event) => {
    setBarCode(event.target.value);
    var inputBarcode = event.target.value.toString().trim()
    setBarCode(inputBarcode.replace(/\s/g, ''))
    if(inputBarcode.length === 7 && inputBarcode > 0) {
      console.log("valid")
     
      setBarcodeValidation(true)
    } else if (inputBarcode.length == 0) {
      setBarcodeValidation(true)
    }
    else {
      setBarcodeValidation(false)
    }
  };

  const onItemTagClick = async(event) => {
    event.preventDefault();
    console.log(props.index);
    //window.removeEventListener('simulateScan', barcodeScanResult);
    //(Barcode && barcodeValid) ? props.handle_taging(props.detail.itemNum, props.index, Barcode, props.tagged) : setBarcodeValidation(false)
    if (Barcode.length === 7 && Barcode>0) {
      window.removeEventListener('simulateScan', barcodeScanResult);
     await props.handle_taging(props.index, Barcode)
    } else {
      setBarcodeValidation(false)
    }
  };

  const enableListener = (e) => {
    console.log('listener' + props.index)
    //e.preventDefault()
    localStorage.setItem('infocusIndex', props.index)
    setBarcodeInFocus(true)
    initialiseTriggerToSetFocus(true)
    fun_ref.current=barcodeScanResult;
    window.addEventListener('simulateScan',fun_ref.current);
  }

  const disableListener = (e) => {
    console.log('listenerremove' + props.index)
    //e.preventDefault()
    localStorage.setItem('infocusIndex', "null")
    setBarcodeInFocus(false)
    initialiseTriggerToSetFocus(false)
    window.removeEventListener('simulateScan',fun_ref.current);
  }

  const initialiseTriggerToSetFocus = (state) => {
    if (
      typeof window.AndroidInterface !== "undefined" &&
      window.AndroidInterface !== null
    ) {
      console.log("barcodescan initialiseTriggerToSetFocus web")
      window.AndroidInterface.initialiseTriggerToSetFocus(state);
    }
  };

  const barcodeScanResult = (e) => {
    e.preventDefault();

    console.log("barcodescan in called", barcodeInFocus)
    console.log("barcodescan in called index", props.index + "   " + localStorage.getItem("infocusIndex"))
    if (localStorage.getItem("infocusIndex") == props.index) {
     
      setBarCode(e.detail.toString().trim());
      console.log("barcodeScanResult called" + e.detail)

      var inputBarcode = e.detail.toString().trim()
      setBarCode(inputBarcode);
      if(inputBarcode.length === 7 && inputBarcode > 0) {
        focusref.current.blur();
        console.log("barcodeScanResult called valid"+Barcode);
        setBarcodeValidation(true)
        window.removeEventListener('simulateScan', barcodeScanResult);
        props.handle_taging(props.index, inputBarcode);
       
      } else if (inputBarcode.length == 0) {
        console.log("barcodeScanResult called valid length zero")
        setBarcodeValidation(true)
      }
      else {
        console.log("barcodeScanResult called else"+Barcode);
        setBarcodeValidation(false)
      }
    } else {
      console.log('barcodescan in called index else' + props.index + "   " + localStorage.getItem("infocusIndex"))
      window.removeEventListener('simulateScan', barcodeScanResult);
    }

  }
  return (
    <div className="col-md-4 col-lg-4 col-sm-4 card-padding">
      <section className="card-parent-group position-relative">
        <div className="card-content p-2">
          {/* item id */}
          <div className="d-flex mb-1 bd-highlight pb-item-num">
            <div className="bd-highlight item-num">
              Item #: <span>{props.detail.item}</span>
            </div>
            <div className="ml-auto view-det-link bd-highlight">
              <a
                href=""
                data-toggle="modal"
                href={"#collapseExample" + props.index}
                role="button"
                aria-expanded="false"
                data-placement="right"
                aria-controls="collapseExample"
                id="ViewDetails"
                class="text-decoration-none"
              >
                {" "}
                <i class="fas fa-eye" style={{paddingRight:"2px"}}></i>View
              </a>
            </div>
          </div>

          <ViewDetailOrderLookUp detail_1={props.detail} index={props.index} />
          {/* Item name */}
          <div className="d-flex bd-highlight mb-1" >
            <div className="bd-highlight item-name" data-tip={props.detail.itemDesc} data-for="registerTip">
              {" "}
              {props.detail.itemDesc}
              <ReactTooltip id="registerTip" place="top" effect="solid" data-type="info" />
            </div>
          </div>


          {/* Reason and qty*/}
          <div className="d-flex bd-highlight mb-1" >
            <div className="bd-highlight reason-code" >
              Reason: <span className="pl-2" data-tip={props.detail.returnReason} data-for="reason" >
                {props.detail.returnReason}
              </span>
              <ReactTooltip id="reason" place="top" effect="solid" data-type="info" />
            </div>
            <div className="ml-auto item-qty bd-highlight">
              Qty : <span>{props.detail.qty ? props.detail.qty : "1"}</span>
            </div>
          </div>

          {/* Item name */}
          <div className="d-flex bd-highlight mb-1">
            <div className="bd-highlight item-price">
              {" "}
              {props.detail.retailPrice}{" "}
            </div>
          </div>

          <hr />

          {/* Item name */}

          <form className="item-level-tag">
            <div className={props.detail.ublStatus?"":"form-row item-level-tag"}>
              <div className={props.detail.ublStatus?"":"col-md-6 col-lg-6 col-sm-6 "}>
                <input
                  type="text"
                  className="form-control lighten"
                  value={Barcode}
                  ref={focusref}
                  onFocus={(e)=>enableListener(e)}
                  onBlur={(e)=>{onBarcodeChange(e);disableListener(e)}}
                  onChange={(e)=>{setBarCode(e.target.value.toString().trim().replace(/[^0-9.]/g, ""));
                  setBarcodeValidation(true);
                
                 
                }}
                  placeholder={props.detail.ublStatus ? props.detail.ublId : "Enter Barcode"}
                  disabled={props.detail.ublStatus ? true : (props.detail.returnLocation?(parseInt(user.storeNo)!=parseInt(props.detail.returnLocation)):false)}
                />
              </div>
              {props.detail.ublStatus?null:
              <div className="col-md-6 col-lg-6 col-sm-6 ">
                <button
                  type="submit"
                  className="btn btn-item-tag"
                  onClick={onItemTagClick}
                  disabled={props.detail.returnLocation?(parseInt(user.storeNo)!=parseInt(props.detail.returnLocation)):false}
                >
                  Item Tag
                </button>
              </div>}
              <img src="./images/error-icon.svg" alt="" hidden={barcodeValid} />
              <div className="barcode-validation-failed" hidden={barcodeValid}>Invalid Barcode !</div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Cart;
