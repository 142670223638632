import LoadingIndicator from "components/common/LoadingIndicator";
import Pagination from "components/common/Pagination";
import { generatePdf } from "components/common/PDFGenerator";
import { ExportXLSX } from "components/common/xlsReports/ExportXLSX";
import $ from "jquery";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import SearchService from "services/SearchService";
import Cart from "./Cart";

const Search = ({ ...props }) => {
  console.log("SEARCHCOMPONENT", props);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [sortToggle, setSortToggle] = useState(true);
  const [data, setData] = useState(props.details);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [searchTerm, setSearchTerm] = useState("");
  const [sortClicked, setSortClicked] = useState(false);
  const [sortMethod, setSortMethod] = useState("A");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const [tagged, setTagged] = useState(false);
  const [loader, setloader] = useState(false);
  const storeNo = props.storeNo;
  const token = props.token;
  const searchservice = new SearchService();
  const detail_ref = useRef(props.details);
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 600;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);
  const [state, setState] = useState({
    details: props.details,
    tempReturnCreatedByName:
      props.details[0].customerFirstName +
      " " +
      props.details[0].customerLastName,
    tempReturnOrderNum: props.details[0].returnOrderNum,
    defaultItems: props.details,
    advanceSearch: props.AdvanceSearch,
    displayName: props.displayName,
  });
  useEffect(() => {
    setData(props.details);
    console.log(props.details);
    setState({
      details: [],
      tempReturnCreatedByName:
        props.details[0].customerFirstName +
        " " +
        props.details[0].customerLastName,
      tempReturnOrderNum: props.details[0].returnOrderNumber,
      defaultItems: props.details,
      advanceSearch: props.AdvanceSearch,
      displayName: props.displayName,
    });
    detail_ref.current = props.details;
  }, [props.details]);

  let xlxData = [];
  let renderXLXData = <div></div>;
  const [state1, useState1] = useState({
    fileName: "ReturnOrderDetail",
  });
  useEffect(() => {}, [currentPage, sortToggle, searchTerm, tagged]);
  React.useEffect(() => {
    $(document).click(function (e) {
      if (!e.target.closest("#srt") && !e.target.closest("#Sort")) {
        console.log("outside");
        setSortClicked(false);
      }
    });
  });
  const renderData = (data) => {
    console.log("searchrenderdata", state.details.length);
    return (
      <>
        {
          /* {searchTerm.length > 2
          ? data
              .map((detail_1, index) => {
                return <Cart detail={detail_1} index={index} key={index} />;
              })
              .slice(indexOfFirstItem, indexOfLastItem)
          :   */
          data
            .map((detail_1, index) => {
              return (
                <Cart
                  detail={detail_1}
                  index={index}
                  key={index}
                  handle_taging={createUBLHeader}
                />
              );
            })
            .slice(indexOfFirstItem, indexOfLastItem)
        }
      </>
    );
  };

  const createUBLHeader = async (index, Barcode) => {
    let itemDetails = detail_ref.current[index];
    itemDetails.reasonCode = itemDetails.returnReason;
    itemDetails.itemNum = itemDetails.item;
    itemDetails.salesOrderNumber = itemDetails.salesOrder;
    itemDetails.returnOrderNum = itemDetails.returnOrderNumber;
    itemDetails.itemClsGrpDescription = itemDetails.itemClsGrpDesc;
    let barval = Barcode;
    setloader(true);
    await searchservice
      .CreateUblHeader(storeNo, itemDetails, Barcode, token, state.displayName)
      .then((response) => {
        setloader(false);
        console.log(response);
        if (
          response.ublHeader &&
          response.ublHeader.message === "UBL Headers created Successfully"
        ) {
          let tempData = detail_ref.current;
          tempData[index].ublStatus = "C";
          tempData[index].ublId = barval;
          setData(tempData);
          detail_ref.current = tempData;
          setTagged((prev) => !prev);
          setloader(false);
          let seqno = tempData[index].id;
          let index_def;
          for (let i = 0; i < state.defaultItems.length; i++) {
            if (state.defaultItems[i].id == seqno) {
              index_def = i;
            }
          }

          let temp_def = state.defaultItems;
          temp_def[index_def].ublStatus = "C";
          temp_def[index_def].ublId = barval;
          setState({ ...state, defaultItems: temp_def });

          props.showItemTaggingMessage("Item has been tagged", "success");
        } else if (
          response.ublHeader &&
          response.ublHeader.message === "Ubl details already present"
        ) {
          let tempData = detail_ref.current;
          tempData[index].ublStatus = null;
          tempData[index].ublId = "";

          setData(tempData);

          detail_ref.current = tempData;
          setTagged((prev) => !prev);
          setloader(false);

          props.showItemTaggingMessage("Already in use!", "error");
        } else if (
          response.ublHeader &&
          response.ublHeader.message ===
            "Return Order details already cancelled"
        ) {
          let tempData = data;
          tempData[index].ublStatus = null;
          tempData[index].ublId = "";
          setData(tempData);
          detail_ref.current = tempData;
          setTagged((prev) => !prev);
          setloader(false);
          props.showItemTaggingMessage("Item Cancelled!", "error");
        } else if (
          response.ublHeader &&
          response.ublHeader.message === "Seq no already in use"
        ) {
          let tempData = data;
          tempData[index].ublStatus = null;
          tempData[index].ublId = "";
          setData(tempData);
          detail_ref.current = tempData;
          setTagged((prev) => !prev);
          setloader(false);
          props.showItemTaggingMessage("Already Tagged!", "error");
        } else if (response.message === "Internal Server Error") {
          let tempData = data;
          tempData[index].ublStatus = null;
          tempData[index].ublId = "";
          setData(tempData);
          detail_ref.current = tempData;
          setTagged((prev) => !prev);
          setloader(false);
          props.showItemTaggingMessage(
            "Internal Server Error while creating UBL header",
            "error"
          );
        } else {
          console.log("else case");
          setloader(false);
          props.showItemTaggingMessage(response.message, "error");
        }
      })
      .catch((err) => {
        setloader(false);
        console.log(err);
      });
  };

  if (props.details) {
    // consider variable data if we want to export excel with the details that is present only in the
    renderXLXData = props.details.map((item, index) => {
      xlxData.push({
        "Item Number": `${
          item.item && item.item.length > 0 ? item.item : "null"
        }`,
        "Item desc": `${
          item.itemDesc && item.itemDesc.length > 0 ? item.itemDesc : "null"
        }`,
        "Return Reason": `${
          item.returnReason && item.returnReason.length > 0
            ? item.returnReason
            : "null"
        }`,
        "Return Notes": `${
          item.returnNotes && item.returnNotes.length > 0
            ? item.returnNotes
            : "null"
        }`,
        "Return Channel": `${
          item.returnChannel && item.returnChannel.length > 0
            ? item.returnChannel.toLowerCase() == "store"
              ? "MCO"
              : "Webcom"
            : "null"
        }`,
        "Retail price": `${
          item.retailPrice && item.retailPrice.length > 0
            ? item.retailPrice
            : "null"
        }`,
        Supplier: `${item.supplierNo ? item.supplierNo + "-" : ""}${
          item.supplier && item.supplier.length > 0 ? item.supplier : "null"
        }`,

        //"Supplier Invoice": `${item.supplierInvoice && item.supplierInvoice.length>0 ? item.supplierInvoice : "null"}`,
        "Supplier PO": `${
          item.supplierPo && item.supplierPo.length > 0
            ? item.supplierPo
            : "null"
        }`,
        //"Item Class Group": `${item.itemClsGrp}`-`${item.itemClsGrpDesc}`,
        "Item Class Group": `${
          item.itemClsGrp.toString() && item.itemClsGrp.toString().length > 0
            ? item.itemClsGrp
            : "null"
        } - ${
          item.itemClsGrpDesc.toString() &&
          item.itemClsGrpDesc.toString().length > 0
            ? item.itemClsGrpDesc
            : "null"
        }`,
        TPC: `${
          item.itemTPC && item.itemTPC.length > 0 ? item.itemTPC : "null"
        }`,
        Brand: `${item.brand && item.brand.length > 0 ? item.brand : "null"}`,
        "Return Order#": `${
          item.returnOrderNumber && item.returnOrderNumber.length > 0
            ? item.returnOrderNumber
            : "null"
        }`,
        "Return Created By": `${
          item.returnCreatedBy && item.returnCreatedBy.length > 0
            ? item.returnCreatedBy
            : "null"
        }`,
        "Return Created Date": `${
          item.returnCreatedOn
            ? moment.utc(item.returnCreatedOn).format("DD-MM-YYYY")
            : "null"
        }`,
        "Return Received Date": `${
          item.returnReceivedFrom
            ? moment.utc(item.returnReceivedFrom).format("DD-MM-YYYY")
            : "null"
        }`,

        "Return Store": `${item.returnLocation + "-" + item.storeName}`,
        "Customer Order#": `${
          item.salesOrder && item.salesOrder.length > 0
            ? item.salesOrder
            : "null"
        }`,

        Status: `${
          item.dcPutawayLocation
            ? "Putaway in DC"
            : item.ublStatus == "C"
            ? item.storeManifestNumber
              ? "Ready Despatch to DC"
              : item.cublId
              ? "CBL tagged"
              : "UBL tagged"
            : item.ublStatus == "D"
            ? "Despatched to DC"
            : item.ublStatus == "R"
            ? "Received at DC"
            : item.ublStatus == "M"
            ? "Item Missing"
            : item.ublStatus == "X"
            ? "Detagged"
            : "New Return"
        }`,
        "Myer Market ID": `${
          item.supplier == "MYER MARKET" ? "9000397 Myer Market" : "null"
        }`,
        "Item Destination": `${
          item.itemDestination.split(",")[0].trim() != "null"
            ? item.itemDestination.split(",")[0].trim() + ","
            : "null"
        }${
          item.itemDestination.split(",")[1].trim().toLowerCase() != "null"
            ? item.itemDestination.split(",")[1].trim() + ","
            : " "
        }${
          item.itemDestination.split(",")[2].trim().toLowerCase() != "null"
            ? item.itemDestination.split(",")[2].trim() + ","
            : " "
        }${
          item.itemDestination.split(",")[3].trim().toLowerCase() != "null"
            ? item.itemDestination.split(",")[3].trim() + "-"
            : " "
        }${
          item.itemDestination.split(",")[4].trim().toLowerCase() != "null"
            ? item.itemDestination.split(",")[4].trim()
            : " "
        }`,
        "Hub Location": `${
          item.hubLocation ? (item.hubLocation.substr(0, 4).toLowerCase() != "null" &&
          item.hubLocation.split(",")[0] && item.hubLocation.split(",")[0].trim().toLowerCase() != "null"
            ? item.hubLocation.split(",")[0].trim() + ","
            : null ) : null
        }${
          item.hubLocation ? (item.hubLocation.substr(0, 4).toLowerCase() != "null" &&
          item.hubLocation.split(",")[1] && item.hubLocation.split(",")[1].trim().toLowerCase() != "null"
            ? item.hubLocation.split(",")[1].trim() + ","
            : null) : null
        }${
          item.hubLocation ? (item.hubLocation.substr(0, 4).toLowerCase() != "null" &&
          item.hubLocation.split(",")[2] && item.hubLocation.split(",")[2].trim().toLowerCase() != "null"
            ? item.hubLocation.split(",")[2].trim() + ","
            : null) : null
        }${
          item.hubLocation ? ( item.hubLocation.substr(0, 4).toLowerCase() != "null" &&
          item.hubLocation.split(",")[3] && item.hubLocation.split(",")[3].trim().toLowerCase() != "null"
            ? item.hubLocation.split(",")[3].trim() + "-"
            : null):null
        }${
          item.hubLocation ? (item.hubLocation.substr(0, 4).toLowerCase() != "null" &&
          item.hubLocation.split(",")[4] && item.hubLocation.split(",")[4].trim().toLowerCase() != "null"
            ? item.hubLocation.split(",")[4].trim()
            : null ) : null
        }`,
        "Detag Reason": `${item.detagReason ? item.detagReason : null}`,
        "store Manifest#": `${
          item.storeManifestNumber ? item.storeManifestNumber : null
        }`,
        "DC Manifest#": `${
          item.dcManifestNumber ? item.dcManifestNumber : null
        }`,
        "UBL ": `${item.ublId ? item.ublId : null}`,
        "CBL ": `${item.cublId ? item.cublId : null}`,
        "Carrier Reference #": `${item.carrierRef ? item.carrierRef : null}`,
        "DC Putaway Location": `${
          item.dcPutawayLocation ? item.dcPutawayLocation : null
        }`,
        Ageing: `${item.aging}`,
        "DC Receive to location": `${
          item.dcReceiveToLocation ? item.dcReceiveToLocation : null
        }`,
        "UBL Tagged in MCO": `${
          item.mcoTag ? (item.mcoTag == "Y" ? "Yes" : "No") : null
        }`,
      });
      var price = 0;
      return (
        <tr>
          <td>{item.item}</td>
          <td>{item.itemDesc}</td>

          <td>{item.returnReason}</td>
          <td> {item.returnNotes}</td>
          <td>
            {item.returnChannel && item.returnChannel.length > 0
              ? item.returnChannel.toLowerCase() == "store"
                ? "MCO"
                : "Webcom"
              : "null"}
          </td>

          <td>
            {item.retailPrice || item.retailPrice == 0
              ? "$" +
                (Math.round(item.retailPrice * 100) / 100)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
              : "$" + item.retailPrice}
          </td>
          <td>
            {item.supplierNo ? item.supplierNo + "-" : ""}
            {item.supplier}
          </td>

          <td>{item.supplierPo}</td>
          <td>
            {item.itemClsGrp}-{item.itemClsGrpDesc}
          </td>
          <td>{item.itemClsGrpDesc}</td>
          <td>{item.itemTPC}</td>
          <td>{item.brand}</td>
          <td>{item.returnOrderNumber}</td>
          <td>{item.returnCreatedBy}</td>
          <td>
            {item.returnCreatedOn
              ? moment.utc(item.returnCreatedOn).format("DD-MM-YYYY")
              : null}
          </td>
          <td>
            {item.returnReceivedFrom
              ? moment.utc(item.returnReceivedFrom).format("DD-MM-YYYY")
              : "null"}
          </td>
          <td>{item.returnLocation + "-" + item.storeName}</td>
          <td>{item.salesOrder}</td>
          <td>
            {item.dcPutawayLocation
              ? "Putaway in DC"
              : item.ublStatus == "C"
              ? item.storeManifestNumber
                ? "Ready Despatch to DC"
                : item.cublId
                ? "CBL tagged"
                : "UBL tagged"
              : item.ublStatus == "D"
              ? "Despatched to DC"
              : item.ublStatus == "R"
              ? "Received at DC"
              : item.ublStatus == "M"
              ? "Item Missing"
              : item.ublStatus == "X"
              ? "Detagged"
              : "New Return"}
          </td>
          <td>
            {item.supplier == "MYER MARKET" ? "9000397 Myer Market" : "null"}
          </td>
          <td>
            {item.itemDestination.split(",")[0].trim() != "null"
              ? item.itemDestination.split(",")[0] + ","
              : " "}
            {item.itemDestination.split(",")[1].trim().toLowerCase() != "null"
              ? item.itemDestination.split(",")[1] + ","
              : " "}
            {item.itemDestination.split(",")[2].trim().toLowerCase() != "null"
              ? item.itemDestination.split(",")[2] + ","
              : " "}
            {item.itemDestination.split(",")[3].trim().toLowerCase() != "null"
              ? item.itemDestination.split(",")[3] + "-"
              : " "}
            {item.itemDestination.split(",")[4].trim().toLowerCase() != "null"
              ? item.itemDestination.split(",")[4]
              : " "}
          </td>
          <td>
            {item.hubLocation.substr(0, 4).toLowerCase() != "null" &&
            item.hubLocation.split(",")[0] && item.hubLocation.split(",")[0].trim().toLowerCase() != "null"
              ? item.hubLocation.split(",")[0] + ","
              : null}
            {item.hubLocation.substr(0, 4).toLowerCase() != "null" &&
            item.hubLocation.split(",")[1] && item.hubLocation.split(",")[1].trim().toLowerCase() != "null"
              ? item.hubLocation.split(",")[1] + ","
              : null}
            {item.hubLocation.substr(0, 4).toLowerCase() != "null" &&
            item.hubLocation.split(",")[2] && item.hubLocation.split(",")[2].trim().toLowerCase() != "null"
              ? item.hubLocation.split(",")[2] + ","
              : null}
            {item.hubLocation.substr(0, 4).toLowerCase() != "null" &&
            item.hubLocation.split(",")[3] && item.hubLocation.split(",")[3].trim().toLowerCase() != "null"
              ? item.hubLocation.split(",")[3] + "-"
              : null}
            {item.hubLocation.substr(0, 4).toLowerCase() != "null" &&
            item.hubLocation.split(",")[4] && item.hubLocation.split(",")[4].trim().toLowerCase() != "null"
              ? item.hubLocation.split(",")[4]
              : null}
          </td>

          <td>{item.detagReason}</td>
          <td>{item.storeManifestNumber}</td>
          <td>{item.dcManifestNumber}</td>
          <td>{item.ublId}</td>
          <td>{item.cublId}</td>
          <td>{item.carrierRef}</td>
          <td>{item.dcPutawayLocation}</td>
          <td>{item.aging}</td>
          <td></td>
          <td>{item.mcoTag ? (item.mcoTag == "Y" ? "Yes" : "No") : null}</td>
        </tr>
      );
    });
    if (xlxData.length === 0) {
      xlxData.push({
        "Item Number": ``,
        "Item desc": ``,
        "Return Reason": ``,
        "Return Notes": ``,
        "Return Channel": ``,
        "Retail price": ``,
        Supplier: ``,

        "Supplier PO": ``,
        "Item Class Group": ``,

        TPC: ``,
        Brand: ``,
        "Return Order#": ``,
        "Return Created By": ``,
        "Return Created Date": ``,
        "Return Received Date": ``,
        "Return Store": ``,
        "Sales Order#": ``,
        Status: ``,
        "Myer Market ID": ``,
        "Item Destination": ``,
        "Hub Location": ``,

        "Detag Reason": ``,
        "store Manifest#": ``,
        "Dc Manifest#": ``,
        "UBL ": ``,
        CBL: ``,
        "Carrier Reference #": ``,
        "DC Putaway Location": ``,
        Ageing: ``,
        "DC Receive to location": ``,
        "UBL Tagged in MCO": ``,
      });
    }
  }

  const subSearchPreventDefault = (e) => {
    e.preventDefault();
  };

  const handleClick = (event) => {
    setCurrentPage(Number(event.target.id));
  };

  const handlePrevBtn = () => {
    setCurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };
  const handleNextBtn = () => {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const sortAscending = () => {
    handleSort(true);
    setSortMethod("A");
  };

  const sortDescending = () => {
    handleSort(false);
    setSortMethod("D");
  };

  const handleSort = (ascending) => {
    let sortData;
    console.log("handleSort2", sortToggle);
    if (!ascending) {
      sortData = data.sort((a, b) => {
        return (
          b.supplier.trim().localeCompare(a.supplier.trim()) ||
          b.itemClsGrp - a.itemClsGrp ||
          b.brand.trim().localeCompare(a.brand.trim()) ||
          b.item - a.item
        );
      });
    } else {
      sortData = data.sort((a, b) => {
        return (
          a.supplier.trim().localeCompare(b.supplier.trim()) ||
          a.itemClsGrp - b.itemClsGrp ||
          a.brand.trim().localeCompare(b.brand.trim()) ||
          a.item - b.item
        );
      });
    }
    setData(sortData);
    detail_ref.current = sortData;
    setSortToggle((prev) => !prev);
  };

  const pdfGeneration = () => {
    setloader(true)
    let pdfarray = [];
    // for (var i = 0; i < props.details.length; i++) {
    //   // change state.details to data if we have to print only the data present on the ui
    //  // pdfarray[i] = { ...props.details[i] };
    //  let obj = { ...props.details[i] };
    //  obj.slNo = i + 1;
    //  let new_obj = {};
    //  let rev_obj = Object.keys(obj).reverse();
    //  rev_obj.forEach(function (j) {
    //    new_obj[j] = obj[j];
    //  });
    //  pdfarray[i] = new_obj;
    //   if (pdfarray[i].qty) {
    //     pdfarray[i].qty = (Math.round(pdfarray[i].qty * 100) / 100)
    //       .toString()
    //       .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    //   }
    //   if(pdfarray[i].returnCreatedOn){
    //     pdfarray[i].returnCreatedOn = moment.utc(pdfarray[i].returnCreatedOn).format("DD-MM-YYYY | hh:mm A")
    //   } else {
    //     pdfarray[i].returnCreatedOn = "-"
    //   }

    //   // if (pdfarray[i].retailPrice || pdfarray[i].retailPrice == 0) {
    //   //   pdfarray[i].retailPrice =
    //   //     "$" +pdfarray[i].retailPrice
    //   //     // (Math.round(pdfarray[i].retailPrice * 100) / 100)
    //   //     //   .toFixed(2)
    //   //     //   .toString()
    //   //     //   .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    //   // } else {
    //   //   pdfarray[i].price = "$" + pdfarray[i].price;
    //   // }
    // }
    let pdfArrayFinal = []
    let slNo = 1
    props.details.forEach((entry)=>{
        let object = {}
        object.slNo = slNo++
        object.item = entry.item
        object.itemDesc = entry.itemDesc
        object.returnReason = entry.returnReason
        object.retailPrice = entry.retailPrice
        object.supplier = entry.supplier
        object.supplierPo = entry.supplierPo
        object.brand = entry.brand
        object.returnOrderNumber = entry.returnOrderNumber
        object.salesOrder = entry.salesOrder
        object.returnCreatedOn = entry.returnCreatedOn ? moment.utc(entry.returnCreatedOn).format("DD-MM-YYYY | hh:mm A") : ""
      pdfArrayFinal.push(object)
    })
    console.log("pdfArrayFinal",pdfArrayFinal)
    generatePdf(
      pdfArrayFinal,
      props.filteredObject,
      "Return Order Report",
      "returnOrderIdReport"
    );
    setloader(false)
  };

  const handleOnChange = (e) => {
    setSearchTerm(e.target.value);
    let value = e.target.value.toLowerCase();
    console.log("search : ", state.defaultItems.toString());
    if (value.length > 2) {
      let datas = state.defaultItems;
      let result = datas.filter((d) => {
        let tempdata = JSON.stringify(d) + "$" + d.retailPrice;
        return tempdata.toLowerCase().includes(value);
      });
      console.log("search", result.length);
      setData(result);
      detail_ref.current = result;
      console.log("setData", data.length);
      if (result.length > 0) {
        setCurrentPage(1);
      }
    } else {
      setData(state.defaultItems);
      detail_ref.current = state.defaultItems;
    }
  };

  return (
    <div id="SearchResult">
      {state.advanceSearch == false ? (
        <div className="rtn-customer">
          <div className="media">
            <div className="media-body customer-name">
              <h5 className="cust-membercard " style={{ fontSize: "14px" }}>
                {" "}
                RO#:
                {state.tempReturnOrderNum}
              </h5>
            </div>
          </div>
        </div>
      ) : null}
      <hr />

      <section className="rtn-card-section">
        {/* table header functions */}
        <div className="rtn-card-hdr">
          <div className="container">
            <div className="row">
              <div className="col-6 card-padding">
                <form
                  className="rtn-table-search"
                  onSubmit={subSearchPreventDefault}
                >
                  <input
                    className="form-control input-search col-md-6"
                    type="text"
                    placeholder="Search"
                    id="SearchTerm"
                    value={searchTerm}
                    onChange={handleOnChange}
                  />
                </form>
              </div>
              <div className="col-6 card-padding">
                <ul className="rtn-card-icons mt-0">
                  {isMobile ? null : (
                    <>
                      <li>
                        <i className="table-icons" onClick={pdfGeneration}>
                          {" "}
                          <img
                            className=""
                            src="./images/table-icons/download-icon.svg"
                            alt=""
                          />{" "}
                        </i>
                      </li>
                      <li>
                        <ExportXLSX
                          csvData={xlxData}
                          fileName={state1.fileName}
                        />
                      </li>
                    </>
                  )}
                  <li id="Sort" onClick={() => setSortClicked(!sortClicked)}>
                    <i className="table-icons">
                      {" "}
                      <img
                        className=""
                        src="./images/table-icons/sorting-icon.svg"
                        alt=""
                      />{" "}
                    </i>
                  </li>
                  {sortClicked ? (
                    <div id="srt">
                      <section class="shadow p-3 mb-5 bg-white rounded sort p-3">
                        <div class="bd-highlight user-profile-store">
                          <div class="sort-by">
                            <h6 className="sort-title">SORT BY</h6>
                          </div>
                          <div>
                            <img
                              className="sort-tick"
                              src="./images/tick-icon-solid.svg"
                              alt=""
                              hidden={!(sortMethod === "A")}
                            />
                            <button
                              id="itemtag"
                              type="submit"
                              class="btn btn-item-tag sort-button"
                              onClick={() => sortAscending()}
                            >
                              Ascending
                            </button>
                          </div>
                          <div className="mr-8">
                            <img
                              src="./images/tick-icon-solid.svg"
                              alt=""
                              hidden={!(sortMethod === "D")}
                            />
                            <button
                              id="itemtag"
                              type="submit"
                              class="btn btn-item-tag sort-button"
                              onClick={() => sortDescending()}
                            >
                              Descending
                            </button>
                          </div>
                        </div>
                      </section>
                    </div>
                  ) : null}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Results card view */}

        <section className="rtn-card-tiles">
          <div className="container">
            <div className="row">
              {data.length > 0 ? (
                renderData(data)
              ) : (
                <div className="no-items-found-despatch">
                  <img src="./images/Item-not-found.svg" alt="" />
                  <p className="no-item-found">No Items found</p>
                </div>
              )}
            </div>
          </div>
        </section>
        {data.length > 0 ? (
          <section>
            <Pagination
              className="pagination-bar"
              data={data}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              pageNumberLimit={pageNumberLimit}
              maxPageNumberLimit={maxPageNumberLimit}
              minPageNumberLimit={minPageNumberLimit}
              handleClick={handleClick}
              handlePrevBtn={handlePrevBtn}
              handleNextBtn={handleNextBtn}
            />
          </section>
        ) : null}
      </section>
      {loader ? <LoadingIndicator /> : null}
    </div>
  );
};

export default Search;
