import React from "react";

const ErrorModal = ({ id, dc_loc_valid, putMessage, ...props }) => {
  return (
    <div
      className="modal fade"
      id={id}
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header bg-light-black whitetext">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Alert
            </h5>
          </div>
          <div className="modal-body">
            <p className="text-center">
              {/* UBL #: 00393131640003829 <br /> */}
            
              {putMessage}
              {/* <br />
                UBL already in putaway status at location <br /> */}
              {/* <br />
                9000387 - Cambridge Clothing Pty Ltd. */}
            </p>
          </div>
          <div className="modal-footer border-none">
            <button
              type="button"
              className="btn whitetext ctbtn "
              data-dismiss="modal"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;
