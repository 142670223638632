import React, { useEffect, useRef, useState } from "react";
import * as constants from "../../../components/constants";
import $ from "jquery";
import ReactTooltip from "react-tooltip";
import ViewDetailsItem from "components/common/ViewDetailsItem"
import { First } from "react-bootstrap/esm/PageItem";

const CreateDespatchCartNew = ({ ...props }) => {
  const [Barcode, setBarCode] = useState("");
  const [edit, setEdit] = useState(false);
  const [barcodeValid, setBarcodeValidation] = useState(true);
  const [barcodeInFocus,setBarcodeInFocus] = useState(false);
  const First=useRef();
  const fun_ref=useRef();
  React.useEffect(() => {
    $(document).click(function (e) {
      if (!$(e.target).is('.panel-body')) {
        $('.collapse').collapse('hide');
      }
    })
  });
  useEffect(() => {
    console.log('barcode scan useeffect in cart'+ props.index)
    setBarCode("");
    setEdit(false);
    setBarcodeValidation(true)
    if(props.index==0 && !props.tagged  && props.searchTerm=="")
    First.current.focus();
    
    
   
  }, [props.detail.itemNum, props.tagged, props.refresh]);

  // useEffect(()=>{
  //   return()=>{
  //     window.removeEventListener('simulateScan', barcodeScanResult); 
  //   }
  // })

  const onItemTagClick_not_tagged_item = (event) => {
    event.preventDefault();
    //window.removeEventListener('simulateScan', barcodeScanResult);
    //(Barcode && barcodeValid) ? props.handle_taging(props.detail.itemNum, props.index, Barcode, props.tagged) : setBarcodeValidation(false)
    var inputBarcode = Barcode;
    if(inputBarcode.length === 7 && inputBarcode > 0)
    {
      window.removeEventListener('simulateScan', barcodeScanResult);
      props.handle_taging(props.detail.itemNum, props.index, Barcode, props.tagged)
    } else {
      setBarcodeValidation(false)
    }
  };

  const onItemTagClick_tagged_item = (event) => {
    event.preventDefault();
    var inputBarcode = Barcode;
    (inputBarcode.length === 7 && inputBarcode > 0)? props.handle_taging(props.detail.itemNum, props.index, Barcode, props.tagged) : setBarcodeValidation(false)
    setEdit(false);
  };

  const onBarcodeChange = (event) => {
    setBarCode(event.target.value);
    var inputBarcode = event.target.value.toString().trim()
    setBarCode(inputBarcode.replace(/\s/g, ''))
    if (inputBarcode.length === 7 && inputBarcode > 0) {
      console.log("valid")
      setBarcodeValidation(true)
    } else if (inputBarcode.length == 0) {
      setBarcodeValidation(true)
    }
    else {
      setBarcodeValidation(false)
    }
  };

  const isCheckDigitValid = (barcode) => {
    var oddSum = 0;
    var evenSum = 0;
    for (var i = 0; i < barcode.length; i++) {
      if ((i % 2) == 1) {
        oddSum = parseInt(barcode.charAt(i)) + oddSum;
      } else {
        evenSum = parseInt(barcode.charAt(i)) + evenSum
      }
    }
    var result = (oddSum + evenSum) % 10;
    console.log(oddSum, evenSum, result);
    if ((10 - result) == barcode.charAt(19)) {
      return true
    } else {
      return false
    }
  }

  const enableListener = (e) =>{
    console.log('listener' +props.index)
    //e.preventDefault()
    localStorage.setItem('infocusIndex',props.index)
    setBarcodeInFocus(true)
    initialiseTriggerToSetFocus(true)
    fun_ref.current=barcodeScanResult;
    window.addEventListener('simulateScan',fun_ref.current);  
  }

  const disableListener =(e) => {
    console.log('listenerremove' +props.index)
    //e.preventDefault()
    localStorage.setItem('infocusIndex',"null")
    setBarcodeInFocus(false)
    initialiseTriggerToSetFocus(false)
    window.removeEventListener('simulateScan',fun_ref.current);     
  }

  const initialiseTriggerToSetFocus = (state) => {
    if (
      typeof window.AndroidInterface !== "undefined" &&
      window.AndroidInterface !== null
    ) {
      console.log("barcodescan initialiseTriggerToSetFocus web")
      window.AndroidInterface.initialiseTriggerToSetFocus(state);
    }
  };

  const barcodeScanResult=(e) =>{
    console.log("barcodescan in called", barcodeInFocus)
    //window.removeEventListener('simulateScan', barcodeScanResult);
    console.log("barcodescan in called index", props.index + "   " + localStorage.getItem("infocusIndex"))
    if(localStorage.getItem("infocusIndex") == props.index) {
      setBarCode(e.detail.toString().trim())
    
      // if(barcodeInFocus){
        console.log("barcodeScanResult called" + e.detail)
        
        var inputBarcode = e.detail.toString().trim()
        if (inputBarcode.length === 7 && inputBarcode > 0) {
          console.log("barcodeScanResult called valid")
          setBarcodeValidation(true)
          window.removeEventListener('simulateScan', barcodeScanResult);
          props.handle_taging(props.detail.itemNum, props.index, inputBarcode, props.tagged)
        } else if (inputBarcode.length == 0) {
          console.log("barcodeScanResult called valid length zero")
          setBarcodeValidation(true)
        }
        else {
          console.log("barcodeScanResult called else")
          setBarcodeValidation(false)
        }
    } else {
      console.log('barcodescan in called index else'+props.index +"   " +localStorage.getItem("infocusIndex"))
      window.removeEventListener('simulateScan', barcodeScanResult);
    }

  }

  return (
    <div className="col-md-4 col-lg-4 col-sm-4 card-padding ">
      <section className="card-parent-group position-relative">
        <div className="card-content p-2">
          {/* item id */}
          <div className="d-flex mb-1 bd-highlight pb-item-num">
            <div className="bd-highlight item-num">
              Item #: <span>{props.detail.itemNum}</span>
            </div>
            <div className="ml-auto view-det-link bd-highlight">
              <a
                href=""
                data-toggle="modal"
                href={"#collapseExample" + props.index}
                role="button"
                aria-expanded="false"
                data-placement="right"
                aria-controls="collapseExample"
                id="ViewDetails"
                class="text-decoration-none"
              >
                {" "}
                <i class="fas fa-eye" style={{paddingRight:"2px"}}></i>View
              </a>
            </div>
          </div>

          <ViewDetailsItem detail_1={props.detail} index={props.index} />

          {/* Item name */}
          <div className="d-flex bd-highlight mb-1" >
            <div className="bd-highlight item-name" data-tip={props.detail.itemDesc} data-for="registerTip">
              {" "}
              {props.detail.itemDesc}
              <ReactTooltip id="registerTip" place="top" effect="solid" data-type="info" />
            </div>
          </div>


          {/* Reason and qty*/}
          <div className="d-flex bd-highlight mb-1" >
            <div className="bd-highlight reason-code" >
              Reason: <span className = "pl-2" data-tip={props.detail.reasonCode} data-for="reason">{props.detail.reasonCode}
              </span>
              <ReactTooltip id="reason" place="top" effect="solid" data-type="info" />
            </div>
            <div className="ml-auto item-qty bd-highlight">
            Qty to return: <span>{props.detail.qty}</span>
            </div>
          </div>


          {/* Item name */}
          <div className="d-flex bd-highlight mb-1">
            <div className="bd-highlight item-price">
              {" "}
              {props.detail.retailPrice}{" "}
            </div>
          </div>

          <hr />

          {/* Item name */}
          {props.tagged ? (
            <div className="item-level-tag">          
            <input
              type="text"
              className="form-control lighten "
              placeholder="Enter Barcode"
              value={props.detail.ublId}
              disabled={true}
            />
          </div>
          ) :
          (
            <form className="item-level-tag">
            <div className="form-row item-level-tag">
              <div className="col-md-6 col-lg-6 col-sm-6 ">
                <input
                  type="text"
                  className="form-control lighten "
                  placeholder="Enter Barcode"
                  value={Barcode}
                  ref={First}
                  maxLength={7}
                  onFocus={(e)=>enableListener(e)}
                  onBlur={(e)=>{onBarcodeChange(e);disableListener(e)}}
                  onChange={(e)=>{setBarCode(e.target.value.toString().trim().replace(/[^0-9.]/g, ""));setBarcodeValidation(true);}}
                  disabled={(props.tagged & (!edit))}
                />
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6 ">
                <button
                  id="itemtag"
                  type="submit"
                  className={props.tagged ? edit ? "btn-item-tagged-edit" : "btn-item-tagged" : "btn btn-item-tag"}
                  onClick={props.tagged ? onItemTagClick_tagged_item : onItemTagClick_not_tagged_item}
                  disabled={(props.tagged & (!edit))}
                >
                  Item Tag
                </button>
                {props.tagged ?
                  <button onClick={() => setEdit(true)}
                    className={" btn-item-edit img-edit"}
                    type="button"
                    id="editButton"
                    disabled = "true"
                  >
                    Edit
                  </button> : null
                }

              </div>
              <img src="./images/error-icon.svg" alt="" hidden={barcodeValid} />
              <div className="barcode-validation-failed" hidden={barcodeValid}>Invalid Barcode !</div>
            </div>

          </form>
          )
        }
          
        </div>
      </section>
    </div>
  );
};

export default CreateDespatchCartNew;
