import React, { useEffect, useState,useRef } from "react";
import scanner from "../../../assets/images/barcodescanner.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import {
  faArrowLeft,
  faEdit,
  faSearch,
  faPrint,
  faEye,
  faTrashAlt,
  faWindowClose,
  faSync,
  faPencilAlt,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import CreateShipment from "services/CreateShipmentService";
import LoadingIndicator from "../../common/LoadingIndicator";
import Pagination from "components/common/Pagination";
import * as constants from "../../../components/constants";

const SearchManifest = (props) => {
  const createShipment = new CreateShipment();
  const [show, setshow] = useState(false);
  const [manifestno, setmanifestno] = useState("Select");
  const [manifestdetails, setmanifestdetails] = useState([]);
  const [items, setitems] = useState([]);
  const [carrierlist, setcarrierlist] = useState([]);
  const [carrierName, setcarrierName] = useState("");
  const [carrierId, setcarrierId] = useState("");
  const [carrierref, setcarrierref] = useState("");
  const [barcode, setbarcode] = useState("");
  const [flag, setflag] = useState(true);
  const [deleteindex, setDeleteindex] = useState("");
  const [Address, setAddress] = useState({});
  const [msg, setmsg] = useState("");
  const [msgheader, setmsgheader] = useState("");
  const [dele, setdelete] = useState(true);
  const user = JSON.parse(localStorage.getItem("User"));
  const [loader, setloader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [barcodeValid, setBarcodeValidation] = useState(true);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [manifestlist, setmanifestlist] = useState([]);
  const [ubldetails, setubldetails] = useState([]);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [input_alert, setinputalert] = useState("");
  const temp_item_detail = [{ cublId: "" }];
  const [itemdetail, setitemdetail] = useState(temp_item_detail);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);
  const [carrierrefdefault, setcarrierrefdefault] = useState("");
  const [carrierNamedefault, setcarriernamedefault] = useState("");
  const [focused,setfocused]=useState(false);
    const  inputFocus=useRef();

  const [itemtype, setitemtype] = useState(true);
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 700;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  useEffect(() => {}, [dele, flag, loader, currentPage]);
  useEffect(() => {
    setloader(true);
    createShipment
      .CarrierDetails(props.token)
      .then((response) => {
        let res = response.return_carrier_response.details;
        let carriername = [];
        setcarrierName(res[0].CARRIER_NAME);
        setcarriernamedefault(res[0].CARRIER_NAME);
        setcarrierId(res[0].CARRIER_ID);
        for (let i = 0; i < res.length; i++) carriername.push(res[i]);
        setcarrierlist(carriername);
      })
      .catch((err) => console.log(err));
    createShipment
      .destination(props.storeNo, props.token)
      .then((response) => {
        console.log(response);
        setAddress(response.DestinationConfigDetails.details[0]);
      })
      .catch((err) => {
        console.log(err);
      });

    createShipment
      .manifestlist(props.storeNo, props.token)
      .then((response) => {
        let temp_arr = [];
        console.log(response);
        for (let i = 0; i < response.shipment.ship_Id.length; i++) {
          temp_arr.push(response.shipment.ship_Id[i].SHIP_ID);
        }

        setmanifestlist(temp_arr);
        setloader(false);
      })
      .catch((err) => {
        console.log(err);
        setloader(false);
      });
  }, []);
  const deleteCUBL = () => {
    setloader(true);
    var ind = parseInt(deleteindex);
    console.log(ind);

    createShipment
      .delCUBL(manifestno, props.storeNo, items[ind], props.token)
      .then((response) => {
        if (
          response.updateCublHeader.message ==
            "CUBL and UBL Headers removed from shipment" ||
          response.updateCublHeader.message ==
            "UBL Headers removed from shipment"
        ) {
          var del = items;
          var del_details = manifestdetails;
          del.splice(ind, 1);
          del_details.splice(ind, 1);
          setitems(del);
          setmanifestdetails(del_details);
          setCurrentPage(1);
          console.log(items);
          setloader(false);
        } else {
          setmsgheader("Error");
          setmsg("CBL/UBL details not found");
          $("#exampleModalCenter1").modal();
          setloader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setloader(false);
      });
  };
  const blobToSaveAs = (fileName, blob) => {
    try {
      const arr = new Uint8Array(blob);
      const url = window.URL.createObjectURL(
        new Blob([arr], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (e) {
      console.error("BlobToSaveAs error", e);
    }
  };
  const download = () => {
    setloader(true);
    createShipment
      .ViewManifest(
        props.storeNo,
        manifestno,
        props.displayName,
        props.token,
        "Y",
        "N"
      )
      .then((response) => {
        blobToSaveAs(
          "ManifestReport",
          response.printManifest.binaryReportData.data
        );
        setloader(false);
      })
      .catch((err) => {
        console.log(err);
        setloader(false);
      });
  };

  const handleprint = () => {
    setloader(true);
    createShipment
      .ViewManifest(
        props.storeNo,
        manifestno,
        props.displayName,
        props.token,
        "N",
        "Y"
      )
      .then((response) => {
        setloader(false);
        if(response && response.printManifest && response.printManifest.message=="Success! Manifest data sent for printing")
        $("#exampleModalCenter2").modal();
       else
        $("#exampleModalCenter23").modal();
       
      })
      .catch((err) => {
        console.log(err);
        setloader(false);
      });
  };

  const handelselect = (a) => {
    setbarcode("");
    let event = { target: { value: a } };
    setBarcodeValidation(true);
    if (event.target.value != "Select") {
      setitems([]);
      setmanifestdetails([]);
      setloader(true);
      console.log();
      setmanifestno(event.target.value);
      createShipment
        .ViewManifest(
          props.storeNo,
          event.target.value,
          props.displayName,
          props.token
        )
        .then((response) => {
          console.log(response);
          inputFocus.current.focus();
          if (response.printManifest.results) {
           
            setmanifestdetails(response.printManifest.results);
            setubldetails(response.printManifest.results);
            var item = response.printManifest.results.map((item, index) => {
              return item.cublId && item.cublId != "NULL"
                ? item.cublId
                : item.ublId;
            });
            setitems([...new Set(item)]);
            setshow(true);
            props.setSecondPage();
            setcarrierName(response.printManifest.results[0].carrierName);
            setcarrierrefdefault(response.printManifest.results[0].carrierRef);
            setcarriernamedefault(
              response.printManifest.results[0].carrierName
            );
            setloader(false);
          } else {
            setmsgheader("Error");
            setmsg("No CBL/UBL found");
            $("#exampleModalCenter1").modal();
            setshow(true);
            setloader(false);
            props.setSecondPage();
          }
        })
        .catch((err) => {
          console.log(err);
          setloader(false);
        });
    }
  };
  const clear = () => {
    setitems([]);
    setmanifestdetails([]);
    setmanifestno("Select");
    setshow(false);
  };

  const addCUBL = () => {
    if (barcode == "") {
      setmsgheader("Alert");
      setmsg("CBL/UBL Id must not be Empty");
      $("#exampleModalCenter1").modal();
      return;
    }

    if (barcodeValid) {
      setloader(true);
      if (items.indexOf(barcode) === -1)
        createShipment
          .validateCUBL(props.storeNo, barcode, props.token)
          .then((response1) => {
            if (
              (response1.validate_cublubl.message == "Valid Ubl Id" &&
                (!response1.validate_cublubl.details[0].CUBL_ID ||
                  response1.validate_cublubl.details[0].CUBL_ID == "null") &&
                (!response1.validate_cublubl.details[0].SHIP_ID ||
                  response1.validate_cublubl.details[0].SHIP_ID == "null") &&
                response1.validate_cublubl.details[0].UBL_STATUS == "C") ||
              (response1.validate_cublubl.message == "Valid Cubl Id" &&
                (!response1.validate_cublubl.details[0].SHIP_ID ||
                  response1.validate_cublubl.details[0].SHIP_ID == "null") &&
                response1.validate_cublubl.details[0].UBL_STATUS == "C")
            ) {
              var arr = [barcode];
              var carrierid;
              for (let i = 0; i < carrierlist.length; i++)
                if (carrierlist[i].CARRIER_NAME == carrierNamedefault)
                  carrierid = carrierlist[i].CARRIER_ID;
              createShipment
                .addCUBL(
                  manifestno,
                  arr,
                  props.storeNo,
                  props.displayName,
                  props.token,
                  carrierid,
                  carrierNamedefault,
                  carrierref != ""
                    ? carrierref
                    : carrierrefdefault != ""
                    ? carrierrefdefault
                    : "0"
                )
                .then((response1) => {
                  if (
                    response1.shipment.message == "UBL Headers updated" ||
                    response1.shipment.message == "CUBL UBL Headers updated"
                  ) {
                    let inputBarcode = barcode;
                    if (
                      inputBarcode.length === 7 &&
                  
                      inputBarcode> 0
                    ) {
                      createShipment
                        .ViewUBLSummary(props.storeNo, barcode, props.token)
                        .then((response) => {
                          if (response.UblSummaryDetails.details) {
                            let temp_ubldetails = ubldetails;
                            temp_ubldetails.push(
                              response.UblSummaryDetails.details[0]
                            );
                            setubldetails(temp_ubldetails);
                            setloader(false);
                            var item = items;

                            item.push(barcode);
                            setitems(item);
                            setflag(!flag);
                            setbarcode("");
                          } else {
                            setmsgheader("Error");
                            setbarcode("");
                            setmsg(response.UblSummaryDetails.message);
                            $("#exampleModalCenter1").modal();
                            setloader(false);
                          }
                        })
                        .catch((err) => {
                          console.log(err);
                          setloader(false);
                        });
                    } else {
                      var item = items;

                      item.push(barcode);
                      setitems(item);
                      setflag(!flag);
                      setloader(false);
                      setbarcode("");
                    }
                  }
                })
                .catch((err) => {
                  console.log(err);
                  setloader(false);
                });
            } else {
              var inputBarcode = barcode;
              if (
                inputBarcode.length === 7 &&
                inputBarcode > 0
              ) {
                if (
                  response1.validate_cublubl.message ==
                  "Cubl/Ubl details not found"
                )
                  setinputalert("UBL details not found");
                else if (
                  response1.validate_cublubl.details[0].UBL_STATUS == "X"
                )
                  setinputalert("Detagged UBL");
                else if (
                  response1.validate_cublubl.details[0].UBL_STATUS == "D"
                )
                  setinputalert("UBL already Despatched");
                else if (response1.validate_cublubl.details[0].SHIP_ID)
                  setinputalert("UBL already tagged to another Shipment");
                else
                  setinputalert(
                    "UBL is Tagged to the CBL, Hence please scan the CBL"
                  );
              } else {
                if (
                  response1.validate_cublubl.message ==
                  "Cubl/Ubl details not found"
                )
                  setinputalert("CBL details not found");
                else if (
                  response1.validate_cublubl.details[0].UBL_STATUS == "D"
                )
                  setinputalert("CBL already Despatched");
                else setinputalert("CBL already tagged to another Shipment");
              }
              setBarcodeValidation(false);
              setloader(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setloader(false);
          });
      else {
        setloader(false);
        setmsgheader("Error");
        setmsg(!user.tempCreateShipmentToggle?"CBL/UBL Already Exists in this Shipment":"CBL Already Exists in this Shipment");
        $("#exampleModalCenter1").modal();
      }
    }
  };
  const onBarcodeChange = (event) => {
    setbarcode(event.target.value);
    setfocused(true);
    setinputalert("Invalid Barcode!");
    var inputBarcode = event.target.value.toString().trim();
    setbarcode(inputBarcode.replace(/[^0-9.]/g, ""));
    if (
      (inputBarcode.length === 10 &&
        !isNaN(inputBarcode) &&
        inputBarcode.substr(0, 3) ===
            ("00" + props.storeNo).slice(-3) &&
        inputBarcode.substr(3, 7) > 0) ||
      ((inputBarcode.length === 7 &&
        !isNaN(inputBarcode) &&
        inputBarcode > 0)&&!user.tempCreateShipmentToggle)
    ) {
      console.log("valid");
      setBarcodeValidation(true);
    } else if (inputBarcode.length == 0) {
      setBarcodeValidation(true);
    } else {
      setBarcodeValidation(false);
    }
  };
  const setdeafultcarrier = () => {
    setcarrierName(carrierNamedefault);
    setcarrierref("");
  };
  const renderData = (data) => {
    return (
      <>
        {data
          .map((inputBarcode, index) => {
            if (
              inputBarcode.length === 10 &&
              !isNaN(inputBarcode) &&
              inputBarcode.substr(0, 3) ===
                  ("00" + props.storeNo).slice(-3) &&
              inputBarcode.substr(3, 7) > 0
            )
              return (
                <div
                  class="card bg-white min"
                  index={index}
                  style={{ minWidth: "200px" }}
                >
                  <div class="card-header bgselago">
                    <p class="barcode-font">{inputBarcode}</p>
                    <button
                      class="delete-icon"
                      id="removeBtn"
                      data-toggle="modal"
                      value={index}
                      onClick={() => {
                        setitemtype(
                          inputBarcode.length==7
                        );

                        setDeleteindex(index);
                      }}
                      data-target="#exampleModalCenter"
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </div>
                  <div class="card-body  p10">
                    <h6 class="card-title fs12 fw6">{Address.DC_NAME}</h6>
                    <p class="card-text fs12">
                      {Address.DC}-{Address.ADDRESS_LN1},{Address.ADDRESS_LN2}
                      {Address.ADDRESS_LN3 && Address.ADDRESS_LN3 != "NULL"
                        ? "," + Address.ADDRESS_LN3
                        : null}{" "}
                      {Address.POST_CD}
                    </p>
                  </div>
                  <div class="card-footer text-center bg-white bluec">
                    <button
                      class="border-none bg-none bluec viewbtn view"
                      onClick={() => {
                        onView(inputBarcode);
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon
                        icon={faEye}
                        className="view"
                      /> View{" "}
                    </button>
                  </div>
                </div>
              );
            else if(inputBarcode.length==7) {
              var ind;
              for (let i = 0; i < ubldetails.length; i++) {
                console.log(ind, i, ubldetails[i].ublId, inputBarcode);
                if (ubldetails[i].ublId == inputBarcode) ind = i;
              }

              return (
                <div
                  class="card bg-white min"
                  index={index}
                  style={{ Width: "200px" }}
                >
                  <div
                    class="card-body"
                    style={{
                      padding: "10px 0px 10px 5px",
                      borderRadius: "5px",
                    }}
                  >
                    <button
                      class="delete-icon"
                      id="removeBtn"
                      data-toggle="modal"
                      value={index}
                      onClick={() => {
                        setitemtype(
                          inputBarcode.length==7
                        );

                        setDeleteindex(index);
                      }}
                      data-target="#exampleModalCenter"
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                    <h6
                      class="card-title"
                      style={{
                        fontSize: "13px",
                        fontWeight: 400,
                      }}
                    >
                      Item #:{ubldetails[ind].itemId}
                    </h6>
                    <p
                      class="card-text"
                      style={{
                        fontWeight: 600,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {ubldetails[ind].itemDescription
                        ? ubldetails[ind].itemDescription
                        : ubldetails[ind].itemDescr}
                    </p>
                  </div>
                  <div
                    class="card-footer"
                    style={{
                      margin: "0px",
                      padding: "15px 0px 5px",
                      backgroundColor: "#f9f9fe",
                    }}
                  >
                    <p
                      class="barcode-font"
                      style={{
                        fontSize: "20px",
                        paddingLeft: "5px",
                      }}
                    >
                      {inputBarcode}
                    </p>

                    <button
                      class="border-none bg-none bluec viewbtn view"
                      style={{
                        float: "right",
                        marginTop: "-35px",
                      }}
                      onClick={() => {
                        onView(inputBarcode);
                      }}
                    >
                      <FontAwesomeIcon icon={faEye} className="view" /> View{" "}
                    </button>
                  </div>
                </div>
              );
            }
          })
          .slice(indexOfFirstItem, indexOfLastItem)}
      </>
    );
  };
  const handleClick = (event) => {
    setCurrentPage(Number(event.target.id));
  };

  const handlePrevBtn = () => {
    setCurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };
  const handleNextBtn = () => {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };
  const onView = (barcode_1) => {
    setloader(true);
    setitemdetail(temp_item_detail);
    createShipment
      .ViewUBLSummary(props.storeNo, barcode_1, props.token)
      .then((response) => {
        if (response.UblSummaryDetails.details) {
          setitemdetail(response.UblSummaryDetails.details);
          setloader(false);
          $("#myModal2").modal();
        } else {
          setmsgheader("Error");
          setmsg(response.UblSummaryDetails.message);
          $("#exampleModalCenter1").modal();
          setloader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setloader(false);
      });
  };

  const UpdateCarrier = () => {
    $("#modifyModalRight").modal("hide");
    let carrierid;
    for (let i = 0; i < carrierlist.length; i++)
      if (carrierlist[i].CARRIER_NAME == carrierName)
        carrierid = carrierlist[i].CARRIER_ID;
    setloader(true);
    createShipment
      .UpdateCarrier(
        manifestno,
        props.storeNo,
        carrierName,
        carrierid,
        carrierref != ""
          ? carrierref
          : carrierrefdefault != ""
          ? carrierrefdefault
          : "0",
        props.token
      )
      .then((response) => {
        setloader(false);
        if (
          response.updateCarrierDetails.message ==
          "Failed to Update Carrier Details"
        ) {
          setmsgheader("Error");
          setmsg("Carrier Details Not Updated");
          $("#exampleModalCenter1").modal();
        } else {
          props.toast();

          setcarrierrefdefault(
            carrierref != ""
              ? carrierref
              : carrierrefdefault != ""
              ? carrierrefdefault
              : "0",
            setcarrierref("")
          );
          setcarriernamedefault(carrierName);
        }
      })
      .catch((err) => {
        console.log(err);
        setloader(false);
      });
  };
  const setcarrierdetails = (event) => {
    let temp_carrier_name = event.target.value;
    setcarrierName(temp_carrier_name);
    for (let i = 0; i < carrierlist.length; i++)
      if (carrierlist[i].CARRIER_NAME == temp_carrier_name)
        setcarrierId(carrierlist[i].CARRIER_ID);
  };
  const updatecarrierref = (event) => {
    var regex = /^[A-Za-z0-9]*$/;
    if (regex.test(event.target.value)) setcarrierref(event.target.value);
  };
  const handlefourthpage = () => {
    setloader(true);
    createShipment
      .DespatchShipment(manifestno, props.storeNo, props.token)
      .then((response) => {
        if (
          response.despatch_shipment.message ==
          "Shipment Despatched Successfully"
        ) {
          props.NavigatetoLanding();
        } else {
          setmsgheader("Error");
          setmsg(response.despatch_shipment.message);
          $("#exampleModalCenter1").modal();
        }
        setloader(false);
      })
      .catch((err) => {
        console.log(err);
        setloader(false);
      });
  };

  return (
    <>
      <div class="col-md-10">
        <div class="bggray card-sect2">
          <div class="row">
            <div class="col-md-12 ">
              <div class="row">
                <div class="label pt-1" style={{ width: "21%" }}>
                  <span
                    class="pl16 pt10 fw7 ff-openbold"
                    style={{
                      fontSize: "13px",
                    }}
                  >
                    Manifest Number:{" "}
                  </span>
                </div>
                <div class="col-md-9 flex Select_modify">
                  <div class="dropdown">
                    <button
                      class="btn btn-secondary dropdown-search_1 ssml10"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {manifestno}
                    </button>
                    <div
                      class="dropdown-menu despatch-search-dropdown-align ssml10"
                      style={{
                        width: "200px",
                        overflowY: "auto",
                        maxHeight: "300px",
                        height: "max-content",
                      }}
                      aria-labelledby="dropdownMenuButton"
                    >
                      {manifestlist.map((item, index) => {
                        return (
                          <a
                            class="dropdown-item p10 fs12 fw6 cd"
                            onClick={() => {
                              handelselect(item);
                            }}
                          >
                            {item}
                          </a>
                        );
                      })}
                      {manifestlist.length == 0 ? (
                        <a class="dropdown-item p10 fs12 fw6 cd">
                          No Manifest found
                        </a>
                      ) : null}
                    </div>
                  </div>

                  {show ? (
                    <button
                      class="edit-btn bluec ml-20"
                      style={{ width: 120 }}
                      id="editBtn"
                      type="button"
                      data-toggle="modal"
                      data-target="#modifyModalRight"
                    >
                      <FontAwesomeIcon icon={faPencilAlt} />{" "}
                      <span class="pl-4p">Edit Carrier</span>
                    </button>
                  ) : null}
                </div>
              </div>
              <div class="row mrt14">
                <div class="label" style={{ width: "21%" }}>
                  <p
                    class="pl16 pt-1 fw7 ff-openbold"
                    style={{
                      fontSize: "13px",
                    }}
                  >
                   {!user.tempCreateShipmentToggle?"Enter or Scan CBL/UBL:":"Enter or Scan CBL"}{" "}
                  </p>
                </div>
                <div class="col-md-9 flex">
                  <div class="form-group ctf">
                    <div class="input-group">
                      <input
                        type="text"
                        id="search"
                        class="form-control ctfinput"
                      
                        autocomplete="off"
                        name="search"
                        value={barcode}
                        ref={inputFocus}
                        maxLength={isMobile?"":10}
                        onChange={onBarcodeChange}
                        onFocus={(e)=>setfocused(true)}
                    onBlur={(e)=>setfocused(false)}
                    onKeyPress={(e)=>{if(e.key === 'Enter') {
                      setfocused(false);
                    }}}
                    
                        
                      />
                      <div class="input-group-append rtn-clear-input">
                        <button
                          type="submit"
                          id="clrbutton"
                          class=" empt_button"
                          onClick={() => {
                            setbarcode("");
                            setBarcodeValidation(true);
                          }}
                        >
                          <img class="" src="./images/clear-icon.svg" alt="" />
                        </button>
                        {/* <button  class="btn  btn-outline-secondary empt_button"></button> */}
                        <button class=" scan_button"></button>
                        <button disabled={true} class=" search_button"></button>
                      </div>
                    </div>
                  </div>

                  <input
                    type="button"
                    value="Add"
                    style={{ marginRight: "15px" }}
                    class="addbtnc_1 bg-light-red ml-20"
                    disabled={!barcodeValid || barcode.length == 0 || !show}
                    onClick={addCUBL}
                  />
                </div>

                <div
                  className="barcode-validation-failed  modi_val"
                  hidden={(barcodeValid || focused) && barcode.length<=10}
                >
                  <img src="./images/error-icon.svg" alt="" />
                  {input_alert}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card2 secondScreen show" id="shipment_card">
          <h6 class="cbl-text f14 fw5">
            {items.length > 0 ? <>{!user.tempCreateShipmentToggle?"Search Results for CBL/UBL":"Search Results for CBL"}</> : null}
          </h6>
          <div class="cbl-container">
            <div class={isMobile ? "card-columns row" : "card-columns"}>
              {items.length > 0 ? (
                renderData(items)
              ) : !loader ? (
                <div className="no-items-found-shipment">
                  <img src="./images/Item-not-found.svg" alt="" />
                  <p className="no-item-found">No Items found</p>
                </div>
              ) : null}
            </div>
            {items.length > 0 ? (
              <section className="paginat">
                <Pagination
                  className="pagination-bar"
                  data={items}
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  pageNumberLimit={pageNumberLimit}
                  maxPageNumberLimit={maxPageNumberLimit}
                  minPageNumberLimit={minPageNumberLimit}
                  handleClick={handleClick}
                  handlePrevBtn={handlePrevBtn}
                  handleNextBtn={handleNextBtn}
                />
              </section>
            ) : null}
          </div>
        </div>
      </div>
      {show ? (
        <div class="container-fluid section-four">
          <div class="float-right w100">
            {/* <input type="button" value="View Manifest" class="view-manifest-btn "  disabled={items.length==0} onClick={()=>{props.handlepage(manifestno,carrierName,Address)}} />
          <input type="button" value="Download Manifest" class="view-manifest-btn "  disabled={items.length==0}  onClick={download}/> */}
            {isMobile ? null : (
              <button
                class="view-manifest-btn"
                onClick={download}
                disabled={items.length == 0}
              >
                <img
                  className="pr-10"
                  src="./images/table-icons/download-icon.svg"
                  alt=""
                />

                {"Download Manifest"}
              </button>
            )}
            <button
              class="view-manifest-btn"
              onClick={() => {
                props.handlepage(manifestno, carrierName, Address);
              }}
              disabled={items.length == 0}
            >
              <img
                className="pr-10 gscale"
                src="./images/table-icons/view-icon.svg"
                alt=""
              />

              {"View Manifest"}
            </button>
            <button
              class="print-manifest-btn"
              onClick={handleprint}
              disabled={items.length == 0}
            >
              <img
                className="pr-10"
                src="./images/table-icons/printing-icon.svg"
                alt=""
              />

              {"Print Manifest"}
            </button>
            <input
              type="button"
              value="Despatch Shipment"
              class="complete-shipment-btn next-button"
              disabled={items.length == 0}
              data-toggle="modal"
              data-target="#exampleModalCenter123"
            />
          </div>
        </div>
      ) : null}

      <div
        className="modal fade loginModal"
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-z"
          role="document"
        >
          <div className="modal-content modal-radius">
            <div className="modal-header confirmation-header">
              <h5
                className="modal-title confirmation-style"
                id="errorModalCenterTitle"
              >
                Confirmation
              </h5>
            </div>
            <div className="modal-body text-align-centre">
              <div>
                Would you like to remove this{" "}
                <b>
                  {itemtype ? "UBL" : "CBL"}# {items[parseInt(deleteindex)]}
                </b>{" "}
                ?
              </div>
            </div>
            <div className="modal-footer bo-modalbtnftr bt-none">
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-no"
              >
                No
              </button>
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-yes"
                onClick={deleteCUBL}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal summary-modal right fade"
        id="myModal2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel2"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="myModalLabel2">
                View Summary
              </h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span
                  aria-hidden="true"
                  class="whitetext"
                  onClick={() => {
                    $("#myModal2").modal();
                  }}
                >
                  &times;
                </span>
              </button>
            </div>

            <div class="modal-body">
              <p class="barcode-font" style={{ fontSize: "30px" }}>
                {itemdetail[0].cublId != "NULL" && itemdetail[0].cublId
                  ? itemdetail[0].cublId
                  : itemdetail[0].ublId}
              </p>
              <h6 class="card-title fs12 " style={{ fontWeight: "bolder" }}>
                {Address.DC_NAME}{" "}
              </h6>
              <p class=" fs12  pb-1 mb-1">
                {Address.DC}-{Address.ADDRESS_LN1},
              </p>
              <p class=" fs12 width2 pb-2">
                {Address.ADDRESS_LN2}
                {Address.ADDRESS_LN3 && Address.ADDRESS_LN3 != "NULL"
                  ? "," + Address.ADDRESS_LN3
                  : null}{" "}
                {Address.POST_CD}
              </p>
              <table
                class="table cttable"
                style={{
                  textAlign: "left",
                }}
              >
                <thead
                  class="bg-grey"
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  <th class="fw5 fs12">Supplier</th>
                  <th class="fw5 fs12">Item#</th>
                  <th class="fw5 fs12">Customer Order#</th>
                  <th class="fw5 fs12">Return Order#</th>
                </thead>
                <tbody>
                  {itemdetail.map((item, index) => {
                    console.log(item);
                    return (
                      <tr>
                        <td>{item.supplierNo?(item.supplierNo):null} {item.supplierName}</td>
                        <td>{item.itemId}</td>
                        <td>{item.saleOrderNo}</td>
                        <td>{item.returnOrderNo}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal summary-modal right fade"
        id="modifyModalRight"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel2"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="myModalLabel2">
                Modify
              </h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" class="whitetext">
                  &times;
                </span>
              </button>
            </div>
            <div class="modal-body" style={{ margin: "0px" }}>
              <p class="card-title" style={{ marginLeft: 10 }}>
                <b>Destination</b>
              </p>
              <p class=" fs12  pb-1 mb-1" style={{ marginLeft: 10 }}>
                {Address.DC}-{Address.ADDRESS_LN1},
              </p>
              <p class=" fs12 width2 pb-2" style={{ marginLeft: 10 }}>
                {Address.ADDRESS_LN2}
                {Address.ADDRESS_LN3 && Address.ADDRESS_LN3 != "NULL"
                  ? "," + Address.ADDRESS_LN3
                  : null}{" "}
                {Address.POST_CD}
              </p>
              <div class="cbl-container" style={{ height: "auto" }}>
                <div class="pl24">
                  <div class="form-group mt-1 mb-1 pt10">
                    <p class="mb-1  f14">Manifest</p>
                    <input
                      type="text"
                      id="carrierName"
                      class="form-control modify-form-input"
                      value={manifestno}
                      disabled={true}
                      required
                    />
                  </div>

                  <div class="form-group mt-1 mb-1 pt10">
                    <p class="mb-1  f14">Carrier Name</p>
                   
                    <div class="dropdown modify-form-input Select-form">
                        <button class="btn btn-secondary  dropdown-search_1 width100 "
                        
                        type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          {carrierName}
                        </button>
                        <div class="dropdown-menu despatch-search-dropdown-align " 
                        style={{width:"100%",overflowY:"auto",maxHeight:"300px",height:"max-content"}}
                        aria-labelledby="dropdownMenuButton"> 
                        {carrierlist.map((item,index)=>{
                                    return (                         
                          <a class="dropdown-item p10 fs12 fw6 cd" 
                          onClick={() =>{
                            if(item.CARRIER_NAME=="Linfox")
                            setcarrierref("");
                            setcarrierdetails({target:{value:item.CARRIER_NAME}})}}>{item.CARRIER_NAME}</a>
                          );
                        })}
                       
                          </div>
                      </div>
                  </div>
                  <div class="form-group mt-1 mb-1 pt10">
                    <p class="mb-1  f14">Carrier Reference Number</p>
                    <input
                      type="text"
                      id="carrierrefno"
                      autocomplete="off"
                      class="form-control modify-form-input ref_in pr-5"
                      disabled={carrierName=="Linfox"}
                      placeholder={carrierName=="Linfox"?"":carrierrefdefault}
                      value={carrierref}
                      maxLength={30}
                      onChange={updatecarrierref}
                      required
                    />
                  </div>
                </div>
                <div class="clearfix"></div>
                <div class="text-center border-top-modify">
                  <button
                    class="modify-btn"
                    data-dismiss="modal"
                    onClick={() => {
                      setdeafultcarrier();
                      $("#modifyModalRight").modal("hide");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    class="modify-btn bluec"
                    data-toggle="modal"
                    data-dismiss="modal"
                    data-target="#modifySaveAlert"
                  >
                    <img src="./images/save-icon.svg" alt="" />
                    <span class="pl-4p bluec">Save </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade loginModal"
        id="exampleModalCenter1"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-z"
          role="document"
        >
          <div className="modal-content modal-radius">
            <div className={msgheader!="Error"?"modal-header confirmation-header"
            :"modal-header error-popup-header-bg"}>
              <h5
                className="modal-title confirmation-style"
                id="errorModalCenterTitle"
              >
                {msgheader}
              </h5>
            </div>
            <div className="modal-body text-align-centre">
              <div>{msg} </div>
            </div>
            <div className="modal-footer bo-modalbtnftr bt-none">
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-yes"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade loginModal"
        id="modifySaveAlert"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-z"
          role="document"
        >
          <div className="modal-content modal-radius">
            <div className="modal-header confirmation-header">
              <h5
                className="modal-title confirmation-style"
                id="errorModalCenterTitle"
              >
                Confirmation
              </h5>
            </div>
            <div className="modal-body text-align-centre">
              <div> Would you like to save the changes?</div>
            </div>
            <div className="modal-footer bo-modalbtnftr bt-none">
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-no"
                onClick={setdeafultcarrier}
              >
                No
              </button>
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-yes"
                onClick={UpdateCarrier}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade loginModal"
        id="exampleModalCenter2"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-z"
          role="document"
        >
          <div className="modal-content modal-radius">
            <div className="modal-header confirmation-header">
              <h5
                className="modal-title confirmation-style"
                id="errorModalCenterTitle"
              >
                Success
              </h5>
            </div>
            <div className="modal-body text-align-centre">
              <div>Data sent to printer successfully </div>
            </div>
            <div className="modal-footer bo-modalbtnftr bt-none">
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-yes"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade loginModal"
        id="exampleModalCenter23"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-z"
          role="document"
        >
          <div className="modal-content modal-radius">
            <div className="modal-header error-popup-header-bg">
              <h5
                className="modal-title confirmation-style"
                id="errorModalCenterTitle"
              >
                Error
              </h5>
            </div>
            <div className="modal-body text-align-centre">
              <div>Failed to print!</div>
            </div>
            <div className="modal-footer bo-modalbtnftr bt-none">
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-yes"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>


      <div
        className="modal fade loginModal"
        id="exampleModalCenter123"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-z"
          role="document"
        >
          <div className="modal-content modal-radius">
            <div className="modal-header confirmation-header">
              <h5
                className="modal-title confirmation-style"
                id="errorModalCenterTitle"
              >
                Confirmation
              </h5>
            </div>
            <div className="modal-body text-align-centre">
              <div>Would you like to Despatch this Shipment?</div>
            </div>
            <div className="modal-footer bo-modalbtnftr bt-none">
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-no"
              >
                No
              </button>
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-yes"
                onClick={handlefourthpage}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      {loader ? <LoadingIndicator /> : null}
    </>
  );
};
export default SearchManifest;
