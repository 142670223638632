import LoadingIndicator from "components/common/LoadingIndicator";
import Pagination from "components/common/Pagination";
import { generatePdf } from "components/common/PDFGenerator";
import { ExportXLSX } from "components/common/xlsReports/ExportXLSX";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import SearchService from "services/SearchService";
import CreateDespatchCartModify from "./CreateDespatchCartModify";
import moment from "moment";
const DespatchtabsModify = (props) => {
  const searchservice = new SearchService();
  const [loader, setloader] = useState(false);
  const [currentPage_tagged, setCurrentPagetagged] = useState(1);
  const [currentPage_not_tagged, setCurrentPagenottagged] = useState(1);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit_tagged, setmaxPageNumberLimittagged] = useState(5);
  const [maxPageNumberLimit_not_tagged, setmaxPageNumberLimitnottagged] =
    useState(5);
  const [minPageNumberLimit_tagged, setminPageNumberLimittagged] = useState(0);
  const [minPageNumberLimit_not_tagged, setminPageNumberLimitnottagged] =
    useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [data, setData] = useState(props.details);
  const [sortToggle, setSortToggle] = useState(true);
  const [tagging, settagging] = useState(true);
  const [tagged_item, settaggeditem] = useState(props.tagged_item);
  const [not_tagged_item, setnottaggeditem] = useState(props.not_tagged_item);
  const indexOfLastItem_tagged = currentPage_tagged * itemsPerPage;
  const indexOfFirstItem_tagged = indexOfLastItem_tagged - itemsPerPage;
  const indexOfLastItem_not_tagged = currentPage_not_tagged * itemsPerPage;
  const indexOfFirstItem_not_tagged = indexOfLastItem_not_tagged - itemsPerPage;
  const [searchTerm, setSearchTerm] = useState("");
  const [sortClicked, setSortClicked] = useState(false);
  const [sortMethod, setSortMethod] = useState("A");
  const [xlxTaggedModify, setxlxTaggedModify] = useState(props.tagged_item);
  const [isItemsToTagAvailable, setIsItemsToTagAvailable] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const user = JSON.parse(localStorage.getItem("User"));

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 600;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);
  const [state, setState] = useState({
    details: props.details,
    tempReturnCreatedByName: props.name,
    tempReturnOrderNum: props.tempRONum,
    defaultItems: props.details,
    defaultTaggedItem: props.tagged_item,
    defaultNotTaggedItem: props.not_tagged_item,
    advanceSearch: props.AdvanceSearch,
    displayName: props.displayName,
    tempSalesOrderNumber: props.tempSalesOrderNumber,
    searchBy: props.searchBy,
  });
  let xlxData = [];
  let data_type = { tagged_item: 1, not_tagged_item: 0 };
  let renderXLXData = <div></div>;
  const [state1, useState1] = useState({
    fileName: "Modify UBL Tagged item",
  });
  const storeNo = props.storeNo;
  const token = props.token;
  useEffect(() => {}, [
    currentPage_tagged,
    currentPage_not_tagged,
    sortToggle,
    searchTerm,
    tagging,
  ]);

  React.useEffect(() => {
    $(document).click(function (e) {
      if (!e.target.closest("#srt") && !e.target.closest("#Sort")) {
        console.log("outside");
        setSortClicked(false);
      }
    });
  });
  const renderData = (data, type) => {
    console.log("Modify depatch render", data);
    return (
      <>
        {data
          .map((detail_1, index) => {
            return (
              <CreateDespatchCartModify
                detail={detail_1}
                index={index}
                key={index}
                storeNo={storeNo}
                handle_taging={ItemValidation}
                tagged={tagging}
                showDetails={showDetails}
                detagItem={detagItem}
              />
            );
          })
          .slice(indexOfFirstItem_tagged, indexOfLastItem_tagged)}
      </>
    );
  };

  const showDetails = (index) => {
    props.showDetails(index);
  };
  const createUBLHeader = (index, Barcode) => {
    setloader(true);
    searchservice
      .CreateUblHeader(
        storeNo,
        not_tagged_item[index],
        Barcode,
        token,
        state.displayName
      )
      .then((response) => {
        console.log(response);
        if (
          response.ublHeader &&
          response.ublHeader.message === "UBL Headers created Successfully"
        ) {
          let temp_items_not_tagged = not_tagged_item;
          let temp_items_tagged = tagged_item;
          temp_items_not_tagged[index].ublId = Barcode;
          temp_items_tagged.push(temp_items_not_tagged.splice(index, 1)[0]);
          setnottaggeditem(temp_items_not_tagged);
          settaggeditem(temp_items_tagged);
          settagging(!tagging);
          setloader(false);
          props.showItemTaggingMessage(response.ublHeader.message, "success");
        } else if (
          response.ublHeader &&
          response.ublHeader.message === "Ubl details already present"
        ) {
          setloader(false);
          props.showItemTaggingMessage(response.ublHeader.message, "error");
        } else if (response.message === "Internal Server Error") {
          setloader(false);
          props.showItemTaggingMessage(
            "Internal Server Error while creating UBL header",
            "error"
          );
        } else {
          console.log("else case");
          setloader(false);
          props.showItemTaggingMessage(response.message, "error");
        }
      })
      .catch((err) => {
        setloader(false);
        console.log(err);
      });
  };
  const editUBLHeader = (oldublid, newublid, index) => {
    setloader(true);
    searchservice
      .EditUblHeader(storeNo, oldublid, newublid, token)
      .then((response) => {
        if (
          response.ublHead &&
          response.ublHead.message == "UBL Headers updated with new UBL ID"
        ) {
          let temp_items_tagged = tagged_item;
          temp_items_tagged[index].ublId = newublid;
          settaggeditem(temp_items_tagged);
          setxlxTaggedModify(temp_items_tagged);
          settagging(!tagging); //change
          setloader(false);
          props.showItemTaggingMessage("UBL Updated successfully", "success");
        } else if (
          response.ublHead &&
          response.ublHead.message == "Ubl details already present"
        ) {
          let temp_items_tagged = tagged_item;
          temp_items_tagged[index].ublId = tagged_item[index].ublId;
          console.log(
            "temp_items_tagged[index].ublId",
            temp_items_tagged[index].ublId
          );
          settaggeditem(temp_items_tagged);
          setxlxTaggedModify(temp_items_tagged);
          settagging(!tagging); //change
          setloader(false);
          props.showItemTaggingMessage("Already in use!", "error");
        } else if (response.message === "Internal Server Error") {
          setloader(false);
          props.showItemTaggingMessage(
            "Internal Server Error while editing UBL header",
            "error"
          );
        } else {
          setloader(false);
          props.showItemTaggingMessage(response.message, "error");
        }
      })
      .catch((err) => {
        setloader(false);
        console.log(err);
      });
  };

  const detagItem = () => {
    setloader(true);
    searchservice
      .DetagItem(
        storeNo,
        localStorage.getItem("detag_ubl"),
        localStorage.getItem("detag_ubl_reason"),
        localStorage.getItem("detag_comments"),
        token
      )
      .then((response) => {
        if (
          response.detagUbl &&
          response.detagUbl.message === "UBL Detagged successfully"
        ) {
          console.log("UBL Detagged successfully");

          let temp_items_not_tagged = not_tagged_item;
          let temp_items_tagged = tagged_item;
          //temp_items_not_tagged[index].ublId = Barcode;
          temp_items_not_tagged.push(
            temp_items_tagged.splice(
              localStorage.getItem("detag_ubl_index"),
              1
            )[0]
          );
          setnottaggeditem(temp_items_not_tagged);
          settaggeditem(temp_items_tagged);
          setxlxTaggedModify(temp_items_tagged);
          setIsItemsToTagAvailable(temp_items_tagged.length > 0 ? true : false);
          settagging(!tagging);
          setloader(false);
          props.showItemTaggingMessage(
            "UBL# " +
              localStorage.getItem("detag_ubl") +
              " detagged successfully",
            "success"
          );
        } else if (
          response.detagUbl &&
          response.detagUbl.message === "UBL details not found"
        ) {
          console.log("UBL details not found");
          setloader(false);
          props.showItemTaggingMessage("UBL details not found", "error");
        } else {
          setloader(false);
          props.showItemTaggingMessage(response.message, "error");
        }
      })
      .catch((err) => {
        console.log(err);
        setloader(false);
      });
  };
  const ItemValidation = (ProductId, index, barcode, tagged) => {
    editUBLHeader(tagged_item[index].ublId, barcode, index);
  };

  if (xlxTaggedModify) {
    // consider variable data if we want to export excel with the details that is present only in the
    renderXLXData = xlxTaggedModify.map((item, index) => {
      xlxData.push({
        "Item #": `${
          item.itemNum && item.itemNum.length > 0 ? item.itemNum : "null"
        }`,
        "Item desc": `${
          item.itemDesc && item.itemDesc.length > 0 ? item.itemDesc : "null"
        }`,
        Quantity: `${
          item.qty
            ? (Math.round(item.qty * 100) / 100)
                .toString()
                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
            : item.qty
        }`,
        "Return Reason": `${
          item.reasonCode && item.reasonCode.length > 0
            ? item.reasonCode
            : "null"
        }`,
        //  "Reason Notes": `${item.reasonNotes && item.reasonNotes.length>0 ? item.reasonNotes : "null"}`,
        "Retail price": `${
          item.retailPrice && item.retailPrice.length > 0
            ? item.retailPrice
            : "null"
        }`,
        Supplier: `${
          item.supplier && item.supplier.length > 0 ? item.supplier : "null"
        }`,
        // "Supplier Invoice": `${item.supplierInvoice && item.supplierInvoice.length>0 ? item.supplierInvoice : "null"}`,
        "Supplier PO #": `${
          item.supplierPO && item.supplierPO.length > 0
            ? item.supplierPO
            : "null"
        }`,
        // "Item Class Group": `${item.itemClsGrp+" "+item.itemClsGrpDescription}`,

        // "Item TPC": `${item.itemTPC && item.itemTPC.length>0 ? item.itemTPC : "null"}`,
        Brand: `${item.brand && item.brand.length > 0 ? item.brand : "null"}`,
        "Return Order #": `${
          item.returnOrderNum && item.returnOrderNum.length > 0
            ? item.returnOrderNum
            : "null"
        }`,
        // "Return Created By": `${item.returnCreatedBy && item.returnCreatedBy.length>0 ? item.returnCreatedBy : "null"}`,
        "Return Location": `${user.storeNo + "-" + user.storeName}`,
        // "Item Destination": `${item.itemDestination && item.itemDestination.length>0 ? item.itemDestination : "null"}`,
        "Customer Order #": `${
          item.salesOrderNumber && item.salesOrderNumber.length > 0
            ? item.salesOrderNumber
            : "null"
        }`,
        "UBL #": `${item.ublId}`,
        // "MP Ship Node": `${item.mpShipNode && item.mpShipNode.length>0 ? item.mpShipNode : "null"}`,
      });
      var price = 0;
      return (
        <tr>
          <td>{item.itemNum}</td>
          <td>{item.itemDesc}</td>
          <td>
            {item.qty
              ? (Math.round(item.qty * 100) / 100)
                  .toString()
                  .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
              : item.qty}
          </td>
          <td>{item.reasonCode}</td>

          <td>
            {item.retailPrice || item.retailPrice == 0
              ? "$" +
                (Math.round(item.retailPrice * 100) / 100)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
              : "$" + item.retailPrice}
          </td>
          <td>{item.supplier}</td>
          <td>{item.supplierPO}</td>
          <td>{item.brand}</td>
          <td>{item.returnOrderNum}</td>
          <td>{user.storeNo + "-" + user.storeName}</td>
          <td>{item.salesOrderNumber}</td>
          <td>{item.ublId}</td>
        </tr>
      );
    });
    if (xlxData.length === 0) {
      xlxData.push({
        "Item #": ``,
        "Item desc": ``,
        Quantity: ``,
        "Return Reason": ``,
        //   "Reason Notes": ``,
        "Retail price": ``,
        Supplier: ``,
        //"Supplier Invoice": ``,
        "Supplier PO #": ``,
        //   "Item Class Group": ``,
        // "Item TPC": ``,
        Brand: ``,
        "Return Order #": ``,
        "Return Created By": ``,
        "Return Location": ``,
        // "Item Destination": ``,
        "Customer Order #": ``,
        //"MP Ship Node": ``,
        "UBL #": ``,
      });
    }
  }

  const handleClick_tagged = (event) => {
    setCurrentPagetagged(Number(event.target.id));
  };
  const handleClick_not_tagged = (event) => {
    setCurrentPagenottagged(Number(event.target.id));
  };
  const handlePrevBtn_tagged = () => {
    setCurrentPagetagged(currentPage_tagged - 1);
    if ((currentPage_tagged - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimittagged(maxPageNumberLimit_tagged - pageNumberLimit);
      setminPageNumberLimittagged(minPageNumberLimit_tagged - pageNumberLimit);
    }
  };
  const handlePrevBtn_not_tagged = () => {
    setCurrentPagenottagged(currentPage_not_tagged - 1);
    if ((currentPage_not_tagged - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimitnottagged(
        maxPageNumberLimit_not_tagged - pageNumberLimit
      );
      setminPageNumberLimitnottagged(
        minPageNumberLimit_not_tagged - pageNumberLimit
      );
    }
  };
  const handleNextBtn_tagged = () => {
    setCurrentPagetagged(currentPage_tagged + 1);

    if (currentPage_tagged + 1 > maxPageNumberLimit_tagged) {
      setmaxPageNumberLimittagged(maxPageNumberLimit_tagged + pageNumberLimit);
      setminPageNumberLimittagged(minPageNumberLimit_tagged + pageNumberLimit);
    }
  };
  const handleNextBtn_not_tagged = () => {
    setCurrentPagenottagged(currentPage_not_tagged + 1);

    if (currentPage_not_tagged + 1 > maxPageNumberLimit_not_tagged) {
      setmaxPageNumberLimitnottagged(
        maxPageNumberLimit_not_tagged + pageNumberLimit
      );
      setminPageNumberLimitnottagged(
        minPageNumberLimit_not_tagged + pageNumberLimit
      );
    }
  };

  const sortAscending = () => {
    handleSort(true);
    setSortMethod("A");
  };

  const sortDescending = () => {
    handleSort(false);
    setSortMethod("D");
  };

  const handleSort = (ascending) => {
    let sortData_tagged;
    let sortData_not_tagged;
    console.log("handleSort2", sortToggle);
    if (!ascending) {
      sortData_tagged = tagged_item.sort((a, b) => {
        return (
          b.supplier.trim().localeCompare(a.supplier.trim()) ||
          b.itemClsGrp - a.itemClsGrp ||
          b.itemClsGrpDescription
            .trim()
            .localeCompare(a.itemClsGrpDescription.trim()) ||
          b.brand.trim().localeCompare(a.brand.trim()) ||
          b.itemNum - a.itemNum
        );
      });
      sortData_not_tagged = not_tagged_item.sort((a, b) => {
        return (
          b.supplier.trim().localeCompare(a.supplier.trim()) ||
          b.itemClsGrp - a.itemClsGrp ||
          b.itemClsGrpDescription
            .trim()
            .localeCompare(a.itemClsGrpDescription.trim()) ||
          b.brand.trim().localeCompare(a.brand.trim()) ||
          b.itemNum - a.itemNum
        );
      });
    } else {
      sortData_tagged = tagged_item.sort((a, b) => {
        return (
          a.supplier.trim().localeCompare(b.supplier.trim()) ||
          a.itemClsGrp - b.itemClsGrp ||
          a.itemClsGrpDescription
            .trim()
            .localeCompare(b.itemClsGrpDescription.trim()) ||
          a.brand.trim().localeCompare(b.brand.trim()) ||
          a.itemNum - b.itemNum
        );
      });
      sortData_not_tagged = not_tagged_item.sort((a, b) => {
        return (
          a.supplier.trim().localeCompare(b.supplier.trim()) ||
          a.itemClsGrp - b.itemClsGrp ||
          a.itemClsGrpDescription
            .trim()
            .localeCompare(b.itemClsGrpDescription.trim()) ||
          a.brand.trim().localeCompare(b.brand.trim()) ||
          a.itemNum - b.itemNum
        );
      });
    }
    settaggeditem(sortData_tagged);
    setnottaggeditem(sortData_not_tagged);
    setSortToggle((prev) => !prev);
  };

  const pdfGeneration = () => {
    let pdfarray = [];
    for (var i = 0; i < state.defaultTaggedItem.length; i++) {
      // change state.details to data if we have to print only the data present on the ui
      let obj = { ...state.defaultTaggedItem[i] };
      obj.slNo = i + 1;
      let new_obj = {};
      let rev_obj = Object.keys(obj).reverse();
      rev_obj.forEach(function (j) {
        new_obj[j] = obj[j];
      });
      pdfarray[i] = new_obj;
      if (pdfarray[i].qty) {
        pdfarray[i].qty = (Math.round(pdfarray[i].qty * 100) / 100)
          .toString()
          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      }
      if(pdfarray[i].returnLocation){
        pdfarray[i].returnLocation = pdfarray[i].returnLocation + "-" + user.storeName
      }
      if(pdfarray[i].date){
        pdfarray[i].date = moment.utc(pdfarray[i].date).format("DD-MM-YYYY | hh:mm A")
      } else {
        pdfarray[i].date = "-"
      }
      // let pdfarray = [];
      // for (var i = 0; i < state.defaultTaggedItem.length; i++) {
      //   // change state.details to data if we have to print only the data present on the ui
      //   pdfarray[i] = { ...state.defaultTaggedItem[i] };
      //   if (pdfarray[i].qty) {
      //     pdfarray[i].qty = (Math.round(pdfarray[i].qty * 100) / 100)
      //       .toString()
      //       .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      //   }

      // if (pdfarray[i].retailPrice || pdfarray[i].retailPrice == 0) {
      //   pdfarray[i].retailPrice =
      //     "$" +
      //     (Math.round(pdfarray[i].retailPrice * 100) / 100)
      //       .toFixed(2)
      //       .toString()
      //       .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      // } else {
      //   pdfarray[i].price = "$" + pdfarray[i].price;
      // }
    }
    //generatePdf(pdfarray, "Return Order Report", "returnOrderIdReport");
    generatePdf(pdfarray,props.filteredObject, "Modify UBL Report", "Modify UBL Report");
  };

  const subSearchPreventDefault = (e) => {
    e.preventDefault();
  };

  const handleOnChange = (e) => {
    setSearchTerm(e.target.value);
    let value = e.target.value.toLowerCase();
    if (value.length > 2) {
      let datas_tagged = state.defaultTaggedItem;
      let result_tagged = datas_tagged.filter((d) => {
        let tempdata = JSON.stringify(d) + "$" + d.retailPrice;
        return tempdata.toLowerCase().includes(value);
      });
      let datas_not_tagged = state.defaultNotTaggedItem;
      let result_not_tagged = datas_not_tagged.filter((d) => {
        let tempdata = JSON.stringify(d) + "$" + d.retailPrice;
        return tempdata.toLowerCase().includes(value);
      });
      settaggeditem(result_tagged);
      setnottaggeditem(result_not_tagged);
      if (result_tagged.length > 0) {
        setCurrentPagetagged(1);
      }
    } else {
      settaggeditem(state.defaultTaggedItem);
      setnottaggeditem(state.defaultNotTaggedItem);
    }
  };

  return (
    <div id="DispatchTabs">
      {state.advanceSearch === false && isItemsToTagAvailable ? (
        <div className="rtn-customer">
          <div className="media">
            <div className="media-body customer-name">
              {state.searchBy == "Customer Order #" ? (
                <h5 className=" cust-membercard " style={{ fontSize: "14px" }}>
                  {" "}
                  CO#:
                  {state.tempSalesOrderNumber}
                </h5>
              ) : (
                <h5 className="cust-membercard" style={{ fontSize: "14px" }}>
                  {" "}
                  RO#:
                  {state.tempReturnOrderNum}
                </h5>
              )}
            </div>
          </div>
        </div>
      ) : null}
      {isItemsToTagAvailable ? <hr /> : null}

      <section className="rtn-card-section">
        {/* table header functions */}
        {isItemsToTagAvailable ? (
          <div className="rtn-card-hdr">
            <div className="container">
              <div className="row">
                <div className="col-6 card-padding">
                  <form
                    className="rtn-table-search "
                    onSubmit={subSearchPreventDefault}
                  >
                    <input
                      name="field"
                      autocomplete="off"
                      className="form-control input-search col-md-6"
                      type="text"
                      placeholder="Search"
                      id="SearchTerm"
                      value={searchTerm}
                      onChange={handleOnChange}
                    />
                  </form>
                </div>
                <div className="col-6 card-padding">
                  <ul className="rtn-card-icons mt-0">
                    {isMobile ? null : (
                      <>
                        <li>
                          <i className="table-icons" onClick={pdfGeneration}>
                            {" "}
                            <img
                              className=""
                              src="./images/table-icons/download-icon.svg"
                              alt=""
                            />{" "}
                          </i>
                        </li>
                        <li>
                          <ExportXLSX
                            csvData={xlxData}
                            fileName={state1.fileName}
                          />
                        </li>
                      </>
                    )}
                    <li id="Sort" onClick={() => setSortClicked(!sortClicked)}>
                      <i className="table-icons">
                        {" "}
                        <img
                          className=""
                          src="./images/table-icons/sorting-icon.svg"
                          alt=""
                        />{" "}
                      </i>
                    </li>
                    {sortClicked ? (
                      <div id="srt">
                        <section class="shadow p-3 mb-5 bg-white rounded sort p-3">
                          <div class="bd-highlight user-profile-store">
                            <div class="sort-by">
                              <h6 className="sort-title">SORT BY</h6>
                            </div>
                            <div>
                              <img
                                className="sort-tick"
                                src="./images/tick-icon-solid.svg"
                                alt=""
                                hidden={!(sortMethod === "A")}
                              />
                              <button
                                id="itemtag"
                                type="submit"
                                class="btn btn-item-tag sort-button"
                                onClick={() => sortAscending()}
                              >
                                Ascending
                              </button>
                            </div>
                            <div className="mr-8">
                              <img
                                src="./images/tick-icon-solid.svg"
                                alt=""
                                hidden={!(sortMethod === "D")}
                              />
                              <button
                                id="itemtag"
                                type="submit"
                                class="btn btn-item-tag sort-button"
                                onClick={() => sortDescending()}
                              >
                                Descending
                              </button>
                            </div>
                          </div>
                        </section>
                      </div>
                    ) : null}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* Results card view */}

        <div label="Items Tagged" id="Item_tagged" tabName="Items Tagged">
          <section className="rtn-card-tiles">
            <div className="container">
              <div className="row">
                {tagged_item.length > 0 ? (
                  renderData(tagged_item, "tagged_item")
                ) : (
                  <div className="no-items-found-despatch-modify">
                    <img src="./images/Item-not-found.svg" alt="" />
                    <p className="no-item-found">No items found</p>
                  </div>
                )}
              </div>
            </div>
          </section>
          {tagged_item.length > 0 ? (
            <section>
              <Pagination
                className="pagination-bar"
                data={tagged_item}
                currentPage={currentPage_tagged}
                itemsPerPage={itemsPerPage}
                pageNumberLimit={pageNumberLimit}
                maxPageNumberLimit={maxPageNumberLimit_tagged}
                minPageNumberLimit={minPageNumberLimit_tagged}
                handleClick={handleClick_tagged}
                handlePrevBtn={handlePrevBtn_tagged}
                handleNextBtn={handleNextBtn_tagged}
              />
            </section>
          ) : null}
        </div>
      </section>
      {loader ? <LoadingIndicator /> : null}
    </div>
  );
};
export default DespatchtabsModify;
