import {
  faMapMarkerAlt,
  faPlus,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Checkbox from "components/common/Checkbox";
import Header from "components/common/Header";
import Sidebar from "components/common/Sidebar";
import * as constants from "components/constants";
import $, { map } from "jquery";
import React, { useState, useEffect,useRef} from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  getDcLocations,
  getUblDetails,
  putUblDetailsToDcLocation,
} from "redux/thunks";
import ErrorModal from "./ErrorModal";
import ToastNotification from "./ToastNotification";
import ViewCart from "./ViewCart";
import ViewSummary from "./ViewSummary";
import LoadingIndicator from "components/common/LoadingIndicator";
import DcLocationService from "services/DcLocationService";

import Pagination from "components/common/Pagination";
import { parse } from "@fortawesome/fontawesome-svg-core";
import { callLogoutURL } from "components/Authentication/utilities";
import { useIdleTimer } from "react-idle-timer";


const DcItemPutAway = ({
  LocUblIdValidationDetails,
  getUblDetails,
  viewUblSummary,
  message,
  putMessage,
  putUblDetailsToDcLocation,
  pending,
  error,
  getDcLocations,
  ...props
}) => {
  const user = JSON.parse(localStorage.getItem("User"));
  const [inputLocation, setInputLocation] = useState("");
  const [inputLocation_1, setInputLocation_1] = useState("");
  const [view_summary, setView_summary] = useState([]);
  const view_summary_ref=useRef([]); 
  const [loading, setLoading] = useState(false);
  const [Barcode, setBarCode] = useState("");
  const [barcodeValid, setBarcodeValidation] = useState(true);
  const [search, setSearch] = useState(false);
  const [searchUblData, setSearchUblData] = useState(false);
  const [select, setSelect] = useState(false);
  const [show, setShow] = useState(false);
  const [details, setdetails] = useState([]);
  const [isLocationScreen, setLocationScreen] = useState(true);
  const [itemTaggingToast, setItemTaggingToast] = useState(false);
  const [toastmsg, settoastmsg] = useState("");
  const [dc_loc_valid, setdc_loc_valid] = useState({});
  const [state, setState] = useState({});
  const [ublid, setublid] = useState("");
  const [loc_id, setloc_id] = useState("");
  const [sup_des, setsup_des] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [LocationValid, setLocationValid] = useState(true);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const item_ref=useRef("");
 
  const  inputFocus=useRef(); 
   const  inputFocus2=useRef();
  useEffect(()=>{
    inputFocus.current.focus()
  },[]);


  if (typeof window != 'undefined') {
    console.log("listener", localStorage.getItem("access_token"));
    window.addEventListener("storage", function (e) {
      if (localStorage.getItem('access_token') == "null") {
        callLogoutURL()
        return
      }
    })
  }
  const navigateToLandingPage = () => {
    props.history.push("/LandingPage", {
      token: user.token,
      storeNo: user.storeNo,
      userID: user.userID,
      userName: user.userName,
      role: user.role,
      storeName: user.storeName,
      displayName: user.displayName,
      menuAllowed: user.menuAllowed,
      tempSearchMenu: user.tempSearchMenu,
      tempCreateDispatchInStoreMenu: user.tempCreateDispatchInStoreMenu,
      tempCreateConsolidatedDispatchMenu:
        user.tempCreateConsolidatedDispatchMenu,
      tempCreateShipment: user.tempCreateShipment,
      loggedInTime: user.loggedInTime,
      tempDC: user.tempDC,
    });
  };

  useEffect(() => {
    console.log("putawayListener", "added");
    localStorage.setItem("isLocationScreen", "Y");
    if (localStorage.getItem('access_token') == "null") {
      callLogoutURL()
      return
    }
    window.addEventListener("getScannedDataInDevice", getScannedDataInDevice);
    
    return () => {
      console.log("putawayListener", "removed");
      window.removeEventListener(
        "getScannedDataInDevice",
        getScannedDataInDevice
      );
    };
  }, []);

  useEffect(() => {
    console.log("putaway show useeffect");
    if (show) {
      localStorage.setItem("isLocationScreen", "N");
      console.log("putaway show useeffect inside show");
      inputFocus2.current.focus();
    } else {
      localStorage.setItem("isLocationScreen", "Y");
      console.log("putaway show useeffect outside show");
      inputFocus.current.focus();
    }
  }, [show]);
  const handleClick = (event) => {
    setCurrentPage(Number(event.target.id));
  };

  const handlePrevBtn = () => {
    setCurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };
  const handleNextBtn = () => {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const getScannedDataInDevice = (data) => {
    

    console.log("getScannedDataInDevice", data.detail.toString().trim());
    let inputBarcode = data.detail.toString().trim();
    //console.log('putawayListener','removed getscanned')
    //window.removeEventListener('getScannedDataInDevice', getScannedDataInDevice)
    //setInputLocation_1(inputBarcode
    if(document.activeElement ==inputFocus.current || document.activeElement ==inputFocus2.current)
    if (localStorage.getItem("isLocationScreen") == "Y") {
      console.log(data.detail.toString().trim(), show);
      
      //setInputLocation_1(inputBarcode);
      //onSearchLocation()
      var regpan = /^.{3}([0-9])+([0-9]){2}$/;

      let isNum = regpan.test(inputBarcode);

      if (isNum) {
      
        console.log("putaway in onSearchLocation inside if");
        setLoading(true);

        localStorage.setItem("DC_PutAway_UBL", "N");
        localStorage.setItem(
          "LocationDC",
          inputBarcode
        );
        setSearch(false);
        DcLocationService.getDcLocations(
          inputBarcode.substring(3, inputBarcode.length - 2),
          user.token
        )
          .then((res) => {
            setSearch(true);
            setInputLocation_1("");
            if (
              res.dc_loc_valid.message !=
              "Supplier Description not found for the Location Id"
            )
              {
               setdc_loc_valid(res.dc_loc_valid);
               console.log(res.dc_loc_valid &&
                  res.dc_loc_valid.supplierDesc &&
                  res.dc_loc_valid.supplierDesc.toString().trim() &&
                  res.dc_loc_valid.supplierDesc.toString().trim() != "null" &&
                  res.dc_loc_valid.supplierDesc.toString().trim() != ""
                    ?res.dc_loc_valid.supplierDesc
                    : "");
                item_ref.current=(res.dc_loc_valid &&
                  res.dc_loc_valid.supplierDesc &&
                  res.dc_loc_valid.supplierDesc.toString().trim() &&
                  res.dc_loc_valid.supplierDesc.toString().trim() != "null" &&
                  res.dc_loc_valid.supplierDesc.toString().trim() != ""
                    ?res.dc_loc_valid.supplierDesc
                    : "");
                setInputLocation(inputBarcode.substring(3, inputBarcode.length - 2));
              }
            else {
              setdc_loc_valid(res.dc_loc_valid);
              setInputLocation(inputBarcode);
              localStorage.setItem("LocationDC", inputBarcode);
            }
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      } else {
        setSearch(true);
        setInputLocation_1("");
        setInputLocation(inputBarcode);
        setdc_loc_valid({ supplierDesc: " " });
        localStorage.setItem("LocationDC", inputBarcode);
      }
    } else {
      console.log(data.detail.toString().trim(), show);
   
    // setBarCode(inputBarcode);

      if (
        inputBarcode.length === 7 &&

        inputBarcode> 0
      ) {
        console.log("valid");
        setBarcodeValidation(true);
        setLoading(true);
        setSearchUblData(true);
        // API CALL HERE
        DcLocationService.getUblSummary(user.storeNo, inputBarcode, user.token)
          .then((res) => {
            console.log(res.toString());
            console.log("valid2");
           
            if (
              res.UblSummaryDetails.message ==
              "Ubl Summary fetched successfully"
            ) {
              let viewUBL = res.UblSummaryDetails.details[0];
              if (viewUBL.ublStatus == "X") {
                $("#errorModalCenter213").modal();
                setLoading(false);
                setBarCode("");
                return;
              }
              else if(viewUBL.dcShipId !=null)  
              {
                $("#errorModalCenter1331").modal();
                setLoading(false);
                setBarCode("");
                return;
    
              }
             else if (viewUBL.ublStatus != "C" && viewUBL.ublStatus != "R") {
                $("#errorModalCenter214").modal();
                setLoading(false);
                setBarCode("");
                return;
              }
             else if(viewUBL.ublStatus =="C" && parseInt(viewUBL.storeId)!=parseInt(user.storeNo))
              {
                $("#errorModalCenter214").modal();
                setLoading(false);
                setBarCode("");
                return;
  
              }



            else
              DcLocationService.putUblDetailsToLocation(
                user.storeNo,
                localStorage.getItem("LocationDC"),
                viewUBL.itemId,
                viewUBL.qty,

                inputBarcode,
                user.token,
                item_ref.current?item_ref.current:null
              )
                .then((response) => {
                  console.log(response.dcPutaway.message.toString());
                  console.log("valid3");
                  console.log(localStorage.getItem("DC_PutAway_UBL"));
                  if (
                    response.dcPutaway.message ==
                    "DC Putaway created Successfully"
                  ) {
                    let temp =
                      localStorage.getItem("DC_PutAway_UBL") == "Y"
                        ? view_summary_ref.current
                        : [];
                    console.log(temp);
                    temp=[viewUBL].concat(temp);
                    setView_summary(temp);
                    view_summary_ref.current=temp;

                    localStorage.setItem("DC_PutAway_UBL", "Y");

                    settoastmsg(
                      response.dcPutaway.message,
                      setItemTaggingToast(true)
                    );
                    setLoading(false);
                    setBarCode("");
                  } else {
                    console.log(response.dcPutaway.message);
                    setublid(inputBarcode);
                    if(viewUBL.supplierDesc)
                    {
                    setloc_id(viewUBL.locationId.substring(3,
                      viewUBL.locationId.length-2));
                    setsup_des(viewUBL.supplierDesc);
                  }
                    else
                    {
                      setloc_id(viewUBL.locationId);
                      setsup_des(" ");
                    }
                    $("#errorModalCenter13").modal();
                    setLoading(false);
                    setBarCode("");
                  }
                })
                .catch((err) => {
                  console.log(err.toString());
                  setBarCode("");
                  setLoading(false);
                });
            } else {
              $("#errorModalCenter133").modal();
              setBarCode("");
              setLoading(false);
            }
          })
          .catch((err) => {
            console.log(err.toString());
            setLoading(false);
          });

        //setLoading(false);

        setSearchUblData(true);
      } else if (inputBarcode.length == 0) {
        setBarcodeValidation(true);
      } else {
        setBarcodeValidation(false);
      }
    }
  };
  useEffect(() => {}, [currentPage]);
  const onSearchLocation = async () => {
    //console.log('putawayListener','removed onsearchlocation')
    //window.removeEventListener('getScannedDataInDevice', getScannedDataInDevice);
    if (LocationValid == false) {
      return;
    }

    console.log("putaway in onSearchLocation", inputLocation_1);
    var regpan = /^.{3}([0-9])+([0-9]){2}$/;
    let isNum = regpan.test(inputLocation_1);

    if (isNum) {
      console.log("putaway in onSearchLocation inside if");
      // API CALL HERE -- Either direct api call or call thunks here..
      setLoading(true);

      localStorage.setItem("DC_PutAway_UBL", "N");
      setInputLocation(
        inputLocation_1.substring(3, inputLocation_1.length - 2)
      );
      localStorage.setItem(
        "LocationDC",
        inputLocation_1
      );
      setSearch(false);
      DcLocationService.getDcLocations(
        inputLocation_1.substring(3, inputLocation_1.length - 2),
        user.token
      )
        .then((res) => {
          setLoading(false);
          setSearch(true);

          setInputLocation_1("");
          if (
            res.dc_loc_valid.message !=
            "Supplier Description not found for the Location Id"
          )
          {
            setdc_loc_valid(res.dc_loc_valid);
            console.log(res.dc_loc_valid &&
              res.dc_loc_valid.supplierDesc &&
              res.dc_loc_valid.supplierDesc.toString().trim() &&
              res.dc_loc_valid.supplierDesc.toString().trim() != "null" &&
              res.dc_loc_valid.supplierDesc.toString().trim() != ""
                ?res.dc_loc_valid.supplierDesc
                : "");
            item_ref.current=(res.dc_loc_valid &&
              res.dc_loc_valid.supplierDesc &&
              res.dc_loc_valid.supplierDesc.toString().trim() &&
              res.dc_loc_valid.supplierDesc.toString().trim() != "null" &&
              res.dc_loc_valid.supplierDesc.toString().trim() != ""
                ?res.dc_loc_valid.supplierDesc
                : "");
          }
          else {
            setdc_loc_valid(res.dc_loc_valid);
            setInputLocation(inputLocation_1);
            localStorage.setItem("LocationDC", inputLocation_1);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });

      // $("#errorModalCenter12").modal();
    } else if(inputLocation_1) {
      console.log("putaway in onSearchLocation inside else");
      setInputLocation(inputLocation_1);
      setInputLocation_1("");
      setdc_loc_valid({ supplierDesc: " " });
      localStorage.setItem("LocationDC", inputLocation_1);
      setSearch(true);
    }
  };
  const handleShow = () => {
    //console.log('putawayListener','removed handleshow')
    //window.removeEventListener('getScannedDataInDevice', getScannedDataInDevice);
    setShow(!show);
  };
  const showItemTaggingMessage = (message, status) => {
    settoastmsg(toastmsg, setItemTaggingToast(true));
    //$("#errorModalCenter12").modal();
  };

  const onSearchUbl = async () => {
    // console.log('putawayListener','removed onsearchubl')
    // window.removeEventListener('getScannedDataInDevice', getScannedDataInDevice);
    let inputBarcode=Barcode;
    if (
      inputBarcode.length === 7 &&
      !isNaN(inputBarcode) &&
      inputBarcode > 0
    ) {
      console.log("valid");
      setBarcodeValidation(true);
    } else if (inputBarcode.length == 0) {
      setBarcodeValidation(true);
    } else {
      setBarcodeValidation(false);
    }

    if (
      inputBarcode.length === 7 &&
      !isNaN(inputBarcode) &&
      inputBarcode > 0
    ) {
      setLoading(true);
      setSearchUblData(true);
      // API CALL HERE
      await DcLocationService.getUblSummary(user.storeNo, Barcode, user.token)
        .then((res) => {
          if (
            res.UblSummaryDetails.message == "Ubl Summary fetched successfully"
          ) {
            let viewUBL = res.UblSummaryDetails.details[0];
            if (viewUBL.ublStatus == "X") {
              $("#errorModalCenter213").modal();
              setLoading(false);
              setBarCode("");
              return;
            }
            if(viewUBL.dcShipId !=null)  
            {
              $("#errorModalCenter1331").modal();
              setLoading(false);
              setBarCode("");
              return;
  
            }

            if (viewUBL.ublStatus != "C" && viewUBL.ublStatus != "R") {
              $("#errorModalCenter214").modal();
              setLoading(false);
              setBarCode("");
              return;
            }
            if(viewUBL.ublStatus =="C" && parseInt(viewUBL.storeId)!=parseInt(user.storeNo))
            {
              $("#errorModalCenter214").modal();
              setLoading(false);
              setBarCode("");
              return;

            }

            
            console.log( item_ref.current);
            DcLocationService.putUblDetailsToLocation(
              user.storeNo,
              localStorage.getItem("LocationDC"),
              viewUBL.itemId,
              viewUBL.qty,
              Barcode,
              user.token,
              item_ref.current?item_ref.current:null

            )
              .then((response) => {
                if (
                  response.dcPutaway.message ==
                  "DC Putaway created Successfully"
                ) {
                  let temp =
                    localStorage.getItem("DC_PutAway_UBL") == "Y"
                      ? view_summary_ref.current
                      : [];
                  console.log(temp);
                  temp=[viewUBL].concat(temp);
                  setView_summary(temp);
                  view_summary_ref.current=temp;
                  setBarCode("");
                  localStorage.setItem("DC_PutAway_UBL", "Y");

                  settoastmsg(
                    "UBL Putaway created Successfully",
                    setItemTaggingToast(true)
                  );
                  setLoading(false);
                } else {
                  console.log(response.dcPutaway.message);
                  setublid(Barcode);
                  if(viewUBL.supplierDesc)
                  {
                  setloc_id(viewUBL.locationId.substring(3,
                    viewUBL.locationId.length-2));
                  setsup_des(viewUBL.supplierDesc);
                }
                  else
                  {
                    setloc_id(viewUBL.locationId);
                    setsup_des(" ");
                  }
                  $("#errorModalCenter13").modal();
                  setLoading(false);
                  setBarCode("");
                }
              })
              .catch((err) => {
                console.log(err);
                setLoading(false);
              });
          } else {
            $("#errorModalCenter133").modal();
            setLoading(false);
            setBarCode("");
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });

      //setLoading(false);
      setSearchUblData(true);
    } else {
      return false;
    }
  };
  console.log("STATE", state);
  const handleViewUblSummary = () => {};
  const handleLocationTextChange = (event) => {
    //console.log('putawayListener','removed handlechangeloc')
    // window.removeEventListener('getScannedDataInDevice', getScannedDataInDevice);

    var letterNumber = /^[0-9a-zA-Z]*$/;
    console.log(letterNumber.test(event.target.value));

      setInputLocation_1(event.target.value);
  };
  const handleUblTextChange = (event) => {
    console.log(
      event.target.value.toString(),
      event.target.value.toString().replace(/[^0-9.]/g, "")
    );
    // console.log('putawayListener','removed handlsechange ubl')
    // window.removeEventListener('getScannedDataInDevice', getScannedDataInDevice);
    setBarCode(event.target.value.toString());
    var inputBarcode = event.target.value.toString().replace(/[^0-9.]/g, "");
    setBarCode(inputBarcode.replace(/[^0-9.]/g, ""));
    if (
      inputBarcode.length === 7 &&
      !isNaN(inputBarcode) &&
      inputBarcode > 0
    ) {
      console.log("valid");
      setBarcodeValidation(true);
    } else if (inputBarcode.length == 0) {
      setBarcodeValidation(true);
    } else {
      setBarcodeValidation(false);
    }
  };
  const onClearLocation = () => {
    setInputLocation_1("");
  };
  const onClearUbl = () => {
    //console.log('putawayListener','removed clear ubl')
    // window.removeEventListener('getScannedDataInDevice', getScannedDataInDevice);
    setBarCode("");
  };
  const [timeoutDuration] = useState(
    localStorage.getItem("timeout") ? localStorage.getItem("timeout") : 1800000
  );
  const handleOnIdle = (event) => {
    console.log("user is idle", event);
    console.log("last active", getLastActiveTime());
    callLogoutURL();
  };

  const handleOnActive = (event) => {
    console.log("user is active", event);
    console.log("time remaining", getRemainingTime());
    handleReset();
    if(localStorage.getItem('access_token') == "null"){
      callLogoutURL()
      return
    }
  };

  const handleOnAction = (event) => {
    // console.log("user did something", event);
  };
  const { getRemainingTime, getLastActiveTime, reset } = useIdleTimer({
    timeout: timeoutDuration,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });
  const handleReset = () => reset();

  const viewsummary=(barcode_view)=>{
    setLoading(true);
    
    DcLocationService.getUblSummary(user.storeNo, barcode_view, user.token).then((response)=>{
      setLoading(false);
      setdetails(response.UblSummaryDetails.details);
      $("#myModal2").modal();

    }).catch(err=>{console.log(err);setLoading(false);})
    
  }


  return (
    <div className="OrderLookup bg-grey" id="dcitemputaway_component">
      <Header />
      <div className="container-fluid">
        <div className="row">
          <Sidebar active="CreatePutaway" state={user} />
          <main
            role="main"
            className="col-md-9 ml-sm-auto col-lg-10 rtn-main-wrapper p-0 bg-white"
            id="DCReceipting"
          >
            {/* page title */}
            <section className="dcreceipting-header">
              <p> DC Item Putaway - Create</p>
              <div style={{
                float:"right",
                marginTop:"-40px"
              }}
              class="d-none d-lg-block d-md-block"
              >
                  {/* {<Breadcrumb crumbs={crumbs} selected={selected} />} */}
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb" style={{fontSize:"12px"}}>
                    <Link
                        to={{ pathname: "/LandingPage", state: user }}
                        className="breadcrumb-item breadcrumb-color pr-10"
                      >
                        Home
                      </Link>
                      <span className="pr-10"> {" >"}</span>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                     DC Item Putaway
                      </li>
                    </ol>
                  </nav>
                </div>
            </section>
            {/* Search panel for searching DC Shelf location */}

            <section className="search-panel mb-2 putaway-input-section">
              {!show ? (
                <>
                  <form
                    className="form-inline"
                    onSubmit={(event) => {
                      event.preventDefault();
                      onSearchLocation();
                    }}
                  >
                    <label className="my-1 mr-3" for="dcLocation">
                      Putaway Location
                    </label>
                    <div className="input-group advanced-search">
                      <FontAwesomeIcon
                        icon={faMapMarkerAlt}
                        style={{
                          position: "absolute",
                          top: "13px",
                          left: "11px",
                          color: "gray",
                        }}
                      />
                      <input
                        name="dcLocation"
                        autocomplete="off"
                        type="text"
                      
                        className={
                          "form-control input-search-dcitem dc-location"
                        }
                        id="dcLocation"
                        placeholder="Scan or Enter DC Shelf Location"
                        aria-label="Text input with dropdown button"
                        value={inputLocation_1}
                        onChange={handleLocationTextChange}
                        
                        ref={inputFocus}
                      />
                    </div>
                  </form>
                  <div
                    className="barcode-validation-error"
                    hidden={LocationValid}
                  >
                    <img src="./images/error-icon.svg" alt="" />
                    Invalid Location!
                  </div>
                </>
              ) : null}

         
                <div class="p-0 m-0" hidden={!show}>
                  <p className="dc_location">
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      style={{ margin: "5px 5px 0px 0px" }}
                    />
                    {inputLocation}
                    {dc_loc_valid &&
                    dc_loc_valid.supplierDesc &&
                    dc_loc_valid.supplierDesc.toString().trim() &&
                    dc_loc_valid.supplierDesc.toString().trim() != "null" &&
                    dc_loc_valid.supplierDesc.toString().trim() != ""
                      ? "-" + dc_loc_valid.supplierDesc
                      : ""}
                  </p>
                  <hr className="hr_line" />
                  <form
                    className="form-inline"
                    onSubmit={(event) => {
                      event.preventDefault();

                      onSearchUbl();
                    }}
                  >
                    <label className="my-1 mr-3" for="textUbl">
                      Scan / Enter UBL
                    </label>
                    <div className="input-group advanced-search">
                      <FontAwesomeIcon
                        icon={faSearch}
                        style={{
                          position: "absolute",
                          top: "15px",
                          left: "17px",
                          color: "gray",
                        }}
                      />
                      <input
                        name="textUbl"
                        autocomplete="off"
                        className={"form-control input-search-dcitem"}
                        id="textUbl"
                        
                        placeholder="Scan/Enter UBL"
                        aria-label="Text input with dropdown button"
                        value={Barcode}
                        onFocus={()=>setBarcodeValidation(true)}
                        onChange={(e)=>setBarCode(e.target.value.replace(/[^0-9.]/g, ""))}
                        onBlur={handleUblTextChange}
                      
                        ref={inputFocus2}
                      />
                     
                    </div>
                  </form>

                  <div
                    className="barcode-validation-error"
                    hidden={barcodeValid}
                  >
                    <img
                      src="./images/error-icon.svg"
                      alt=""
                      hidden={barcodeValid}
                    />
                    Invalid Barcode!
                  </div>
                </div>
             
            </section>

            {/* Search Results page */}

            <section className="rtn-search-results">
              {/* THIS IS THE PUTAWAY SEARCH SHELF LOCATION GET SUPPLIER NAME OR NAMES FROM API AND DISPLAY IN THE FOLLOWING FORMAT AND
                  HIDE NO LOCATIONS FOUND TEXT AND ITEMS NOT FOUND IMAGE */}
              {search === true && !show ? (
                <div className="d-flex justify-content-between align-items-center p-3 bg-color">
                  <p class="mb-0 cursor-pointer" onClick={() => {setShow(true);inputFocus2.current.focus()}}>
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      style={{ margin: "5px 5px 0px 0px" }}
                    />
                    {inputLocation}
                    {dc_loc_valid &&
                    dc_loc_valid.supplierDesc &&
                    dc_loc_valid.supplierDesc.toString().trim() &&
                    dc_loc_valid.supplierDesc.toString().trim() != "null" &&
                    dc_loc_valid.supplierDesc.toString().trim() != ""
                      ? "-" + dc_loc_valid.supplierDesc
                      : null}
                  </p>
                </div>
              ) : !loading && !show ? (
                <div className="img-with-text">
                                    <img src="./images/location-found-icon.svg" alt="" />
                  <p className="no-item-found">No Locations found</p>
                  
                </div>
              ) : null}

              {/* THIS IS THE SEARCH RESULT FROM UBL INPUT TEXT ENTERED BY USER - GET UBL ITEMS
                 FROM API AND DISPLAY IN THE CARDS FORMAT */}
              {searchUblData === true && view_summary.length > 0 ? (
                <div className="rtn-card-tiles">
                  <div className="container">
                    <div>Scanned results for UBL</div>
                  </div>
                  <div className="scanned-ubl-results border-0">
                    <ViewCart
                      setdetails={(value) => {
                        viewsummary(value[0].ublId)
                      }}
                      handleViewUblSummary={handleViewUblSummary}
                      viewUblSummary={view_summary.slice(
                        indexOfFirstItem,
                        indexOfLastItem
                      )}
                    />
                  </div>
                  <section className="paginat">
                    <Pagination
                      className="pagination-bar"
                      data={view_summary}
                      currentPage={currentPage}
                      itemsPerPage={itemsPerPage}
                      pageNumberLimit={pageNumberLimit}
                      maxPageNumberLimit={maxPageNumberLimit}
                      minPageNumberLimit={minPageNumberLimit}
                      handleClick={handleClick}
                      handlePrevBtn={handlePrevBtn}
                      handleNextBtn={handleNextBtn}
                    />
                  </section>
                </div>
              ) : !loading && show ? (
                <div className="img-with-text">
                  <img src="./images/Item-not-found.svg" alt="" />
                  <p className="no-item-found">No items found</p>
                </div>
              ) : null}
            </section>

            {/* BOTTOM Section to Dispaly Buttons*/}
            {show? (
              <section className="dcru-buttons">
                <input
                  type="submit"
                
                  class="complete-putaway-btn next-button fs12"
                  value="Exit"
                  onClick={(e) => {
                    /* THIS API SHOULD GET CALLED WHEN USER INPUTS UBL ID IN THE TEXTBOX  THIS SHOULD BE REMOVED 
                    FROM HERE ON COMPLETE PUTAWAY BUTTON CLICK*/
                    // await putUblDetailsToDcLocation(
                    //   user.storeNo,
                    //   inputLocation,
                    //   viewUblSummary[0].itemId,
                    //   viewUblSummary[0].qty,
                    //   viewUblSummary[0].itemDescription,
                    //   Barcode,
                    //   user.token
                    // );
                    //console.log('putawayListener','removed input')
                    // window.removeEventListener('getScannedDataInDevice', getScannedDataInDevice);
                    setShow(false);
                    setInputLocation("");
                    setView_summary([]);
                    view_summary_ref.current=[];
                    setSearchUblData(false);
                    setBarCode("");
                    setSearch(false);
                    setInputLocation_1("");
                    setdc_loc_valid({});
                    localStorage.removeItem("LocationDC");
                    item_ref.current="";
                    //$("#errorModalCenter").modal();
                    // await showItemTaggingMessage(putMessage);
                    // await settoastmsg(putMessage);

                    // CART OR UBL IDS NEEDS TO BE PASSED TO AN DC PUTAWAY API
                  }}
                />
              </section>
            ) : null}
                    <ToastNotification
          itemTaggingToast={itemTaggingToast}
          setItemTaggingToast={setItemTaggingToast}
          putMessage={putMessage}
          toastmsg={toastmsg}
        />
          </main>
        </div>
        {loading ? <LoadingIndicator /> : null}

        <ErrorModal
          id="errorModalCenter12"
          dc_loc_valid={dc_loc_valid}
          putMessage={putMessage}
        />

        <ViewSummary viewUblSummary={details} id="myModal2" user={user} />
      </div>

      <div
        className="modal fade loginModal"
        id="errorModalCenter133"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-z"
          role="document"
        >
          <div className="modal-content modal-radius">
            <div className="modal-header error-popup-header-bg">
              <h5
                className="modal-title confirmation-style"
                id="errorModalCenterTitle"
              >
                Error
              </h5>
              <button
                      type="button"
                      className="close text-white"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
            </div>
            <div className="modal-body text-align-centre font-weight-bold">
              <div>UBL details not found</div>
            </div>
            <div className="modal-footer bo-modalbtnftr bt-none justify-content-center">
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-yes"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade loginModal"
        id="errorModalCenter213"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-z"
          role="document"
        >
          <div className="modal-content modal-radius">
            <div className="modal-header error-popup-header-bg">
              <h5
                className="modal-title confirmation-style"
                id="errorModalCenterTitle"
              >
                Error
              </h5>
              <button
                      type="button"
                      className="close text-white"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
            </div>
            <div className="modal-body text-align-centre font-weight-bold">
              <div>UBL in detagged status </div>
            </div>
            <div className="modal-footer bo-modalbtnftr bt-none justify-content-center">
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-yes"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade loginModal"
        id="errorModalCenter214"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-z"
          role="document"
        >
          <div className="modal-content modal-radius">
            <div className="modal-header error-popup-header-bg">
              <h5
                className="modal-title confirmation-style"
                id="errorModalCenterTitle"
              >
                Error
              </h5>
              <button
                      type="button"
                      className="close text-white"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
            </div>
            <div className="modal-body text-align-centre font-weight-bold">
              <div>UBL not in received status </div>
            </div>
            <div className="modal-footer bo-modalbtnftr bt-none justify-content-center">
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-yes"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade loginModal"
        id="errorModalCenter1331"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-z"
          role="document"
        >
          <div className="modal-content modal-radius">
            <div className="modal-header error-popup-header-bg">
              <h5
                className="modal-title confirmation-style"
                id="errorModalCenterTitle"
              >
                Error
              </h5>
              <button
                      type="button"
                      className="close text-white"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
            </div>
            <div className="modal-body text-align-centre">
              <div style={{ fontWeight: "500", fontSize: "16px" }}>
                UBL Already Despatched
              </div>
            </div>
            <div className="modal-footer bo-modalbtnftr bt-none justify-content-center">
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-yes"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade loginModal"
        id="errorModalCenter13"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content modal-radius">
            <div className="modal-header confirmation-header">
              <h5
                className="modal-title confirmation-style"
                id="errorModalCenterTitle"
              >
                Alert
              </h5>
              <button
                      type="button"
                      className="close text-white"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
            </div>
            <div className="modal-body text-align-centre font-weight-bold">
              <div>
                UBL#:{ublid}
                <p class="mt-3 mb-3  font-weight-bold" style={{ color: "red" }}>
                  <i
                    className="fas fa-exclamation-triangle pr-1"
                    style={{ fontSize: "16px" }}
                  ></i>
                  UBL already in putaway status at Location
                </p>
                {loc_id}
                {sup_des == " " ? "" : "-" + sup_des}
              </div>
            </div>
            <div className="modal-footer bo-modalbtnftr bt-none justify-content-center">
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-yes"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    dc_loc_valid: state.dcLocations.dc_loc_valid,
    pending: state.dcLocations.pending,
    error: state.dcLocations.error,
    LocUblIdValidationDetails: state.ublDetails.LocUblIdValidationDetails,
    pending: state.ublDetails.pending,
    error: state.ublDetails.error,
    putMessage: state.ublDetails.putMessage,
    viewUblSummary: state.ubl.viewUblSummary,
    message: state.ubl.viewUblSummary,
  };
};
const mapDispatchToProps = {
  getDcLocations,
  getUblDetails,
  putUblDetailsToDcLocation,
};
export default connect(mapStateToProps, mapDispatchToProps)(DcItemPutAway);
