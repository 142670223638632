import Breadcrumb from "components/common/Breadcrumb";
import LoadingIndicator from "components/common/LoadingIndicator";
import Sidebar from "components/common/Sidebar";
import $ from "jquery";
import React, { useState, useEffect,useRef } from "react";
import { useIdleTimer } from 'react-idle-timer'
import { callLogoutURL } from "../../Authentication/utilities";
import SearchService from "services/SearchService";
import Toast from 'react-bootstrap/Toast';
import Header from "components/common/Header";
import DCDespatchtabsModify from "./DCDespatchtabsModify";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";
const DCCreateDespatchModify = (props) => {
  const user = JSON.parse(localStorage.getItem("User"));
  const searchservice = new SearchService();
  const [filteredObject, setFilteredObject] = useState({});
  const [loader, setLoader] = useState(false);
  const [Invalidreturn, setInvalidreturn] = useState(false);
  const [crumbs, setCrumbs] = useState(["Home", "Create Dispatch"]);
  const [itemTaggingToast, setItemTaggingToast] = useState(false);
  const [toastmsg, settoastmsg] = useState("UBL Headers created Successfully");
  const [searchBy, setSearchBy] = useState("UBL")
  const [inputDisable, setInputDisable] = useState(false)
  const inputFocus = useRef()
  const [showmsg,setshowmsg]=useState(true);
  const [Invalidmsg,setInvalidmsg]=useState(" ");

  const [state1, setState1] = useState({
    token: user.token,
    userName: user.userName,
    storeNo: user.storeNo,
    role: user.role,
    storeName: user.storeName,
    displayName: user.displayName,
    menuAllowed: user.menuAllowed,
    tempSearchMenu: user.tempSearchMenu,
    tempCreateDispatchInStoreMenu:
      user.tempCreateDispatchInStoreMenu,
    tempCreateConsolidatedDispatchMenu:
      user.tempCreateConsolidatedDispatchMenu,
    tempCreateShipment: user.tempCreateShipment,
    loggedInTime: user.loggedInTime,
  });

  const [timeoutDuration] = useState(localStorage.getItem('timeout') ? localStorage.getItem('timeout') : 1800000);
  console.log('timeout in modify',timeoutDuration)

  const [state, setState] = useState({
    errorMsgPopup: "",
    ShipmentId: "",
    CACMLabel: "",
    CarrierRef: "",
    MpSupplierShip: "",
    orderId: "",
    flaglist: false,
    SalesOrderNo: "",
    ReturnOrderNo: 0,
    FirstName: "",
    LastName: "",
    PhoneNo: "",
    CustomerId: "",
    ReturnStatus: "null",
    Location: "",
    SupplierInvoice: "",
    SupplierPo: "",
    ItemId: "",
    DateFrom: "",
    DateTo: "",
    ReasonCode: "null",
    Brand: "",
    SupplierName: "",
    isValid: true,
    sort: false,
    CreatedBy: "",
    search: false,
    AdvanceSearch: false,
    details: [],
    tagged_item: [],
    not_tagged_item: [],
    showLoader: false,
  });
  const navigateToLandingPage = () => {
    props.history.push("/LandingPage", {
      token: state1.token,
      storeNo: state1.storeNo,
      userID: state1.userID,
      userName: state1.userName,
      role: state1.role,
      storeName: state1.storeName,
      displayName: state1.displayName,
      menuAllowed: state1.menuAllowed,
      tempSearchMenu: state1.tempSearchMenu,
      tempCreateDispatchInStoreMenu: state1.tempCreateDispatchInStoreMenu,
      tempCreateConsolidatedDispatchMenu:
        state1.tempCreateConsolidatedDispatchMenu,
      tempCreateShipment: state1.tempCreateShipment,
      loggedInTime: state1.loggedInTime
    });
  };
  const selected = (crumb) => {
    console.log(crumb);
  };
  const logout = () => {
    callLogoutURL();
  };
  const onClearOrderId = () => {
    setInvalidreturn(false);
    setState({ ...state, orderId: "" });
  };
  const UBLdetails = (returnordernos, tempdetails, tempAdvanceSearch) => {
    console.log(tempdetails);
    let taggedItems = []
    let unTaggedItems = []
    let totalItems = [...tempdetails].filter(detail => {
      if (detail.status === "O" || detail.status === "C") {
        return true;
      }
      return false;
    });

    for (let i = 0; i < tempdetails.length; i++) {
      if (tempdetails[i].status === "O") {
        unTaggedItems.push(tempdetails[i])
      } else if (tempdetails[i].status === "C") {
        taggedItems.push(tempdetails[i])
      }
    }


    setState({
      ...state,
      search: taggedItems.length > 0 ? true : false,
      details: sortAscending(totalItems),
      tagged_item: taggedItems.length > 0 ? sortAscending(taggedItems) : [],
      not_tagged_item: unTaggedItems.length > 0 ? sortAscending(unTaggedItems) : [],
      AdvanceSearch: tempAdvanceSearch,
      showLoader: false,
    });
    setLoader(false);


  }

  const sortAscending = (items) => {
    return items.sort((a, b) => {
      return (
        a.supplier.trim().localeCompare(b.supplier.trim()) ||
        a.itemClsGrp - b.itemClsGrp ||
        a.itemClsGrpDescription.trim().localeCompare(b.itemClsGrpDescription.trim()) ||
        a.brand.trim().localeCompare(b.brand.trim()) ||
        a.itemNum - b.itemNum
      );
    });
  }

  const formatRetailPrice = (price, decPlaces, thouSeparator, decSeparator) => {
    var n = price,
      decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
      decSeparator = decSeparator == undefined ? "." : decSeparator,
      thouSeparator = thouSeparator == undefined ? "," : thouSeparator,
      sign = n < 0 ? "-" : "",
      i = parseInt(n = Math.abs(+n || 0).toFixed(decPlaces)) + "",
      j = (j = i.length) > 3 ? j % 3 : 0;
    return sign + (j ? i.substr(0, j) + thouSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thouSeparator) + (decPlaces ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : "");
  }

  const onSearch = (event) => {
    console.log("selected state:", searchBy)
    var searchByAll, searchByCBL, searchByUBL, searchByItemId, searchByreturnOrderId, searchBySalesOrderId;
    searchByreturnOrderId = searchByItemId = searchBySalesOrderId = searchByAll = searchByUBL = searchByCBL = null;
    if (searchBy)
      switch (searchBy) {
        case 'All': {searchByAll = state.orderId;
          setFilteredObject({ All: "All" });
          break;}
        case 'Item ID': {
          setFilteredObject({ ItemId: state.orderId });
          searchByItemId = state.orderId;
          break;}
        case 'Customer Order #': {
          setFilteredObject({ CustomerOrder: state.orderId });
          searchBySalesOrderId = state.orderId
          break;}
        case 'Return Order #': {
          setFilteredObject({ ReturnOrder: state.orderId });
          searchByreturnOrderId = state.orderId
          break;}
        case 'UBL':{
          setFilteredObject({ UBLId: state.orderId });
          searchByUBL = state.orderId
          break;}
        case 'CBL': {
          setFilteredObject({ CBLId: state.orderId });
          searchByCBL = state.orderId
          break;
        }
      }
    event.preventDefault();
    setState({ ...state, details: [], tagged_item: [], not_tagged_item: [], search: false });
    setLoader(true);
    if (state.orderId.trim().length == 0) {
      setState({
        ...state,
        orderId: "",
        showLoader: false,
      });
      setLoader(false);
      //setInvalidreturn(true);
      return;
    }
    searchBySelectedDropdown(searchByreturnOrderId, searchBySalesOrderId, searchByCBL, searchByUBL, searchByItemId, searchByAll)
  };

  const searchBySelectedDropdown = (searchByreturnOrderId, searchBySalesOrderId, searchByCBL, searchByUBL, searchByItemId, searchByAll) => {
    setLoader(true)
    searchservice
      .orderDetails(state1.storeNo, searchByreturnOrderId, state1.token, searchBySalesOrderId, searchByCBL, searchByUBL, searchByItemId, searchByAll)
      .then((response) => {
        console.log(response);
        if (response.hasOwnProperty('message')) {
          setState({
            ...state,
            search: false,
            details: [],
            AdvanceDetails: [],
            showLoader: false,
            AdvanceSearch: true,
            errorMsgPopup: response.message
          });
          setLoader(false);
          setInvalidreturn(true);
          setInvalidmsg("Data doesn't match logged in Location");
          return;
        }
        var tempdetails = response.OrderDetails.details;
        if (tempdetails.length > 0) {
          for (let i = 0; i < tempdetails.length; i++)
            tempdetails[i].retailPrice = ('$' + formatRetailPrice(tempdetails[i].retailPrice, 2, ',', '.')).toString()
          // tempdetails[i].retailPrice = (parseInt(tempdetails[i].retailPrice)).toLocaleString('en-US', {
          //   style: 'currency',
          //   currency: 'USD',
          // });
          let arr = [], tempAdvancedSearch = true;

          if (searchByreturnOrderId || searchBySalesOrderId) {
            tempAdvancedSearch = false
            arr.push(state.orderId);
          } else {
            for (let i = 0; i < tempdetails.length; i++)
              arr.push(tempdetails[i].returnOrderNum);
            arr = [...new Set(arr)];
          }
          UBLdetails(arr, tempdetails, tempAdvancedSearch);

        } else {
          setState({
            ...state,
            search: false,
            details: [],
            tagged_item: [],
            not_tagged_item: [],
            showLoader: false,
            errorMsgPopup: response.OrderDetails.message,
          });
          setLoader(false);
          setInvalidreturn(true);
          setInvalidmsg("Data doesn't match logged in Location");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  }

  const handleDropDownSetting = (searchByTerm) => {
    setDropdownSearch(searchByTerm)
    if (searchByTerm === "All") {
      setFilteredObject({ All: "All" });
      onClearOrderId()
      setInputDisable(true)
      setState({ ...state, details: [], tagged_item: [], not_tagged_item: [], search: false });

      searchBySelectedDropdown(null, null, null, null, null, "all")
    } else {
      setInputDisable(false)
      inputFocus.current.focus()
    }
  }
  useEffect(()=>{
    inputFocus.current.focus()
  },[inputDisable])


  const onAdvanceSearch = () => {
    setInvalidreturn(false);
    setState({ ...state, orderId: "", details: [], tagged_item: [], not_tagged_item: [], search: false });
    setLoader(true);
    if (!(
      state.SalesOrderNo || state.ReturnOrderNo ||
      state.FirstName || state.LastName ||
      state.PhoneNo || state.CustomerId ||
      state.ReturnStatus || state.Location ||
      state.SupplierInvoice || state.SupplierPo ||
      state.ItemId || state.DateFrom ||
      state.DateTo || state.ReasonCode ||
      state.Brand || state.SupplierName)) {
      setState({
        ...state,
        search: false,
        details: [],
        AdvanceDetails: [],
        showLoader: false,
        AdvanceSearch: false,
        errorMsgPopup: "No Data Entered"
      });
      setLoader(false);
      $("#errorModalCenter1").modal();
      return;
    }
    searchservice
      .AdvanceOrderSearch(
        state1.storeNo,
        state.SalesOrderNo ? state.SalesOrderNo : null,
        state.ReturnOrderNo ? state.ReturnOrderNo : 0,
        state.FirstName ? state.FirstName : null,
        state.LastName ? state.LastName : null,
        state.PhoneNo ? state.PhoneNo : null,
        state.CustomerId ? state.CustomerId : null,
        state.ReturnStatus ? state.ReturnStatus : null,
        state.Location ? state.Location : null,
        state.SupplierInvoice ? state.SupplierInvoice : null,
        state.SupplierPo ? state.SupplierPo : null,
        state.ItemId ? state.ItemId : null,
        state.DateFrom ? state.DateFrom : null,
        state.DateTo ? state.DateTo : null,
        state.ReasonCode ? state.ReasonCode : null,
        state.Brand ? state.Brand : null,
        state.SupplierName ? state.SupplierName : null,
        state1.token
      )
      .then((response) => {
        console.log(response);
        if (response.hasOwnProperty('message')) {
          setState({
            ...state,
            orderId: "",
            search: false,
            details: [],
            AdvanceDetails: [],
            showLoader: false,
            AdvanceSearch: true,
            errorMsgPopup: response.message
          });
          setLoader(false);
          $("#errorModalCenter1").modal();
          return;
        }
        var tempdetails = response.returnOrder.details.returnOrderSearch;
        if (tempdetails.length > 0) {
          for (let i = 0; i < tempdetails.length; i++)
            tempdetails[i].retailPrice = ('$' + formatRetailPrice(tempdetails[i].retailPrice, 2, ',', '.')).toString()
          // tempdetails[i].retailPrice = (parseInt(tempdetails[i].retailPrice)).toLocaleString('en-US', {
          //   style: 'currency',
          //   currency: 'USD',
          // });
          let returnordernos = [];
          for (let i = 0; i < tempdetails.length; i++)
            returnordernos.push(tempdetails[i].returnOrderNum);
          returnordernos = [...new Set(returnordernos)];
          UBLdetails(returnordernos, tempdetails, true);

        } else {
          setState({
            ...state,
            orderId: "",
            search: true,
            details: tempdetails,
            AdvanceDetails: [],
            showLoader: false,
            AdvanceSearch: true,
            errorMsgPopup: response.OrderDetails.message,
          });
          setLoader(false);
          $("#errorModalCenter1").modal();
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
    //onClear();
  };


  const onClear = () => {

    setState({
      ...state,
      FirstName: "",
      LastName: "",
      PhoneNo: "",
      CustomerId: "",
      ReturnStatus: "",
      Location: "",
      SupplierInvoice: "",
      SupplierPo: "",
      SupplierName: "",
      MpSupplierShip: "",
      Brand: "",
      SalesOrderNo: "",
      ItemId: "",
      DateFrom: "",
      DateTo: "",
      ReasonCode: "",
      ShipmentId: "",
      CACMLabel: "",
      CarrierRef: "",
    });
    setInvalidreturn(false);
  };

  const showItemTaggingMessage = (message, status) => {
    if (status === "success")
      settoastmsg(message, setItemTaggingToast(true));
    else {
      setState({ ...state, errorMsgPopup: message });
      $("#errorModalCenter1").modal();
    }
  }

  const setDropdownSearch = (searchByTerm) => {
    setSearchBy(searchByTerm)
    onClearOrderId()
  }

  const handleOrderId = (event) => {
    setInvalidreturn(false);
    setshowmsg(false);
    var isnum = /^[0-9]*$/;
    var bar=event.target.value;
   
    setState({ ...state, orderId: event.target.value.replace(/[^\d.-]/g, '') });
    
if(searchBy=="CBL")
{
  if(bar.length==10 && isnum.test(bar) && bar.substr(0,3)==user.storeNo)
  {
    setInvalidreturn(false);

  }
  else
  {
    setInvalidreturn(true);
    setInvalidmsg("Invalid CBL!");
  }

}
    
else if(searchBy=="UBL")
{
  if((bar.length==7 && isnum.test(bar)) || bar.length==0)
  {
  
    setInvalidreturn(false);
  }
  else
  {

    setInvalidreturn(true);
    setInvalidmsg("Invalid UBL!");

  }

}
else
setInvalidreturn(false);



  };

  const handleChangeFname = (event) => {
    setInvalidreturn(false);
    setState({ ...state, FirstName: event.target.value });
  };
  const handleChangeLname = (event) => {
    setInvalidreturn(false);
    setState({ ...state, LastName: event.target.value });
  };
  const handleChangephno = (event) => {
    setInvalidreturn(false);
    setState({ ...state, PhoneNo: event.target.value });
  };
  const handleChangeCId = (event) => {
    setInvalidreturn(false);
    setState({ ...state, CustomerId: event.target.value });
  };
  const handleChangeReturnStatus = (event) => {
    setInvalidreturn(false);
    setState({ ...state, ReturnStatus: event.target.value });
  };
  const handleChangelocation = (event) => {
    setInvalidreturn(false);
    setState({ ...state, Location: event.target.value });
  };
  const handleChangeSupplierInvoice = (event) => {
    setInvalidreturn(false);
    setState({ ...state, SupplierInvoice: event.target.value });
  };
  const handleChangeSupplierPo = (event) => {
    setInvalidreturn(false);
    setState({ ...state, SupplierPo: event.target.value });
  };
  const handleChangeSupplierName = (event) => {
    setInvalidreturn(false);
    setState({ ...state, SupplierName: event.target.value });
  };
  const handleChangeMpSupplierShip = (event) => {
    setInvalidreturn(false);
    setState({ ...state, MpSupplierShip: event.target.value });
  };
  const handleChangeBrand = (event) => {
    setInvalidreturn(false);
    setState({ ...state, Brand: event.target.value });
  };
  const handleChangeSalesOrderNo = (event) => {
    setInvalidreturn(false);
    setState({ ...state, SalesOrderNo: event.target.value });
  };
  const handleChangeItemId = (event) => {
    setInvalidreturn(false);
    setState({ ...state, ItemId: event.target.value });
  };
  const handleChangeDateFrom = (event) => {
    setInvalidreturn(false);
    setState({ ...state, DateFrom: event.target.value });
  };
  const handleChangeDateTo = (event) => {
    setInvalidreturn(false);
    setState({ ...state, DateTo: event.target.value });
  };
  const handleChangeReasonCode = (event) => {
    setInvalidreturn(false);
    setState({ ...state, ReasonCode: event.target.value });
  };
  const handleChangeShipmentId = (event) => {
    setInvalidreturn(false);
    setState({ ...state, ShipmentId: event.target.value });
  };
  const handleChangeCACMLabel = (event) => {
    setInvalidreturn(false);
    setState({ ...state, CACMLabel: event.target.value });
  };
  const handleChangeCarrierRef = (event) => {
    setInvalidreturn(false);
    setState({ ...state, CarrierRef: event.target.value });
  };

  const handleOnIdle = event => {
    console.log('user is idle', event)
    console.log('last active', getLastActiveTime())
    callLogoutURL()
  }

  const handleOnActive = event => {
    console.log('user is active', event)
    console.log('time remaining', getRemainingTime())
    handleReset()
    if(localStorage.getItem('access_token') == "null"){
      callLogoutURL()
      return
    }
  }

  const handleOnAction = event => {
    console.log('user did something', event)
    if(localStorage.getItem('access_token') == "null"){
      callLogoutURL()
      return
    }
  }

  const { getRemainingTime, getLastActiveTime, reset } = useIdleTimer({
    timeout: timeoutDuration,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
  })

  const handleReset = () => reset()

  if (typeof window != 'undefined') {
    console.log("listener", localStorage.getItem("access_token"));
    window.addEventListener("storage", function (e) {
      if (localStorage.getItem('access_token') == "null") {
        callLogoutURL()
        return
      }
    })
  }

  useEffect(() => {
    if (localStorage.getItem('access_token') == "null") {
      callLogoutURL()
      return
    }
    window.addEventListener("scroll", handleOnActive);
    return () => window.removeEventListener("scroll", handleOnAction);
  }, []);

  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      console.log('enter press here! ')
      event.target.blur();
    }
  }

  return (
    <div id="CreateDispatch" className="OrderLookup bg-grey">
      <Header />

      <div className="container-fluid">
        <div className="row">
          <Sidebar active="DCCreateDespatchModify" state={user} />
          <main
            role="main"
            className="col-md-9 ml-sm-auto col-lg-10 rtn-main-wrapper"
          >
            {/* page title */}
            <section className="rtn-page-title mb-2">
              <div className="d-flex row bd-highlight ">
                <div className="bd-highlight col-xs-12 col-md-6 modal-dialog-centered">
                  <h4>Tag UBL</h4>
                </div>

                <div className=" col-xs-12 col-md-6 bd-highlight home-bc-padding">
                  {/* {<Breadcrumb crumbs={crumbs} selected={selected} />} */}
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                    <Link
                        to={{ pathname: "/LandingPage", state: user }}
                        className="breadcrumb-item breadcrumb-color pr-10"
                      >
                        Home
                      </Link>
                      <span className="pr-10"> {" >"}</span>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Tag UBL
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </section>

            {/* Search panel with advanced filter */}

            <section className="search-panel mb-2">
              {/* <form className="form-inline" onSubmit={onSearch}>
                  <div class="container-fluid section-two">
                    <div class="row flex pb12">
                      <p class="pl24 pt10 fw6 fs12 mt15">Modify Despatch:</p>
                      <div class="dropdown ml-15">
                        <button class="btn btn-secondary dropdown-search" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          {searchBy}
                        </button>
                        <div class="dropdown-menu despatch-search-dropdown-align" aria-labelledby="dropdownMenuButton">
                          <a class="dropdown-item p10 fs12 fw6 cd" onClick={() => handleDropDownSetting("All")}>All</a>
                          <a class="dropdown-item p10 fs12 fw6 cd" onClick={() => handleDropDownSetting("UBL")}>UBL</a>
                          <a class="dropdown-item p10 fs12 fw6 cd" onClick={() => handleDropDownSetting("Item ID")}>Item ID</a>
                          <a class="dropdown-item p10 fs12 fw6 cd" onClick={() => handleDropDownSetting("CBL")}>CBL</a>
                          <a class="dropdown-item p10 fs12 fw6 cd" onClick={() => handleDropDownSetting("Return Order #")}>Return Order #</a>
                          <a class="dropdown-item p10 fs12 fw6 cd" onClick={() => handleDropDownSetting("Sales Order #")}>Sales Order #</a>
                        </div>
                      </div>
                      <input type="text" id="search"
                        className={Invalidreturn ? "form-control dispatch-search-input-error fs12" : "form-control dispatch-search-input fs12 input-shadow"} name="search" placeholder="Search..." required
                        name="field"
                        autocomplete="off"
                        value={inputDisable ? "" : state.orderId}
                        onChange={handleOrderId}
                        disabled ={inputDisable} />
                      <img class="dispatch-clear-img" src="./images/clear-icon.svg" alt="" onClick={onClearOrderId} disabled ={inputDisable}  />
                      <img class="dispatch-scanner-img" src="./images/barcodescanner.png" alt="" disabled ={inputDisable} />
                      {
                        Invalidreturn ?
                          <div className="despatch-error">
                            <img className="pb-2" src="./images/error-icon.svg" alt="" />Invalid input!
                          </div> : null
                      }
                    </div>
                  </div>


                </form> */}

              <div class="form-group row">
                <label for="inputPassword" class="col-sm-2 col-form-label input-margin pt-1 mt-3 ">Modify UBL:</label>
                <div class="col-sm-10">
                  <div class="input-group input-group-mt">
                    <div class="input-group-prepend width-input">
                      <div class="dropdown">
                        <button class="btn btn-secondary dropdown-search" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          {searchBy}
                        </button>
                        <div class="dropdown-menu despatch-search-dropdown-align" aria-labelledby="dropdownMenuButton">
                          {/* <a class="dropdown-item p10 fs12 fw6 cd" onClick={() => handleDropDownSetting("All")}>All</a> */}
                          <a class="dropdown-item p10 fs12 fw6 cd" onClick={() => handleDropDownSetting("UBL")}>UBL</a>
                          <a class="dropdown-item p10 fs12 fw6 cd" onClick={() => handleDropDownSetting("Item ID")}>Item ID</a>
                          <a class="dropdown-item p10 fs12 fw6 cd" onClick={() => handleDropDownSetting("Return Order #")}>Return Order #</a>
                          <a class="dropdown-item p10 fs12 fw6 cd" onClick={() => handleDropDownSetting("Customer Order #")}>Customer Order #</a>
                          
                        </div>
                      </div>
                      <form class="input-group" onSubmit={
                        (e)=>{
                        e.preventDefault();
                        setshowmsg(true);
                     
                        if(!Invalidreturn)
                          onSearch(e);
                        
                        }}  onKeyPress={handleKeyPress}>
                        <input name="field" autocomplete="off"
                          type="text" class="form-control input-search input-border input-shadow"
                          id="icon" placeholder="Search" aria-label="Text input with dropdown button"
                          ref ={inputFocus}
                          value={inputDisable ? "" : state.orderId}
                          onFocus={()=>{setshowmsg(false)}}
                          onBlur={()=>setshowmsg(true)}
                          onChange={handleOrderId}
                          disabled={inputDisable} />
                        <div class="input-group-append rtn-clear-input clear-height">
                          <button class="btn btn-outline-secondary clear-border"
                            type="button" id="Idclear"
                            onClick={onClearOrderId}
                            disabled={inputDisable}>
                            <img class="" src="./images/clear-icon.svg" alt="" />
                          </button>
                        </div>
                        <div class="input-group-append rtn-clear-input">
                          <button class="btn btn-outline-secondary barcode-scanner-border" type="button" id="Idclear" disabled={true}>
                            <img class="barcode-scanner-width" src="./images/barcodescanner.png" alt="" /></button>
                        </div>
                        {
                          (Invalidreturn && showmsg)?
                            <div className="despatch-error">
                              <img className="pb-2" src="./images/error-icon.svg" alt="" />
                              <span>{Invalidmsg}</span>
                            </div> : null
                        }
                      </form>

                    </div>
                  </div>
                </div>
              </div>

            </section>

            {/* Search Results page */}

            <section className="rtn-search-results ">
              {state.search === true ? (
                <DCDespatchtabsModify
                  tagged_item={state.tagged_item}
                  not_tagged_item={state.not_tagged_item}
                  details={state.details}
                  filteredObject={filteredObject}
                  storeNo={state1.storeNo}
                  token={state1.token}
                  AdvanceSearch={state.AdvanceSearch}
                  displayName={state1.displayName}
                  showItemTaggingMessage={showItemTaggingMessage}
                  name={(state.details && state.details.length > 0) ? (state.details[0].firstName + state.details[0].lastName) : ""}
                  tempRONum={(state.details && state.details.length > 0) ? state.details[0].returnOrderNum : ""}
                  searchBy={searchBy}
                  tempSalesOrderNumber={(state.details && state.details.length > 0) ? state.details[0].salesOrderNumber : ""}                
                />
              ) : (!loader ? (<div className="img-with-text">
                <img src="./images/Item-not-found.svg" alt="" />
                <p className="no-item-found">No items found</p>
              </div>) : null)}
            </section>
          </main>
        </div>
      </div>
      {loader ? <LoadingIndicator /> : null}
      <div className="topCenter" hidden={!itemTaggingToast}>
      <Toast  id="toast" show={itemTaggingToast} onClose={() => setItemTaggingToast(false)} delay={5000} autohide>
          <div className="toast__content">
            <div>
              <span class="vertical-toast-border"></span>
              <div className="tickmark" style={{ height: "100%" }}>
                <img className="tickmark" src="./images/tick-circle-icon.svg" alt="" />
              </div>
              <div className="pt-10" >
                <p className="toast__type">Success</p>
                <p className="toast__message">{toastmsg}</p>
              </div>
              <div>
                <button
                  type="button"
                  className="close toast-close"
                  data-dismiss="toast"
                  aria-label="Close"
                  onClick={() => setItemTaggingToast(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>
        </Toast>
      </div>
      <div
        className="modal fade loginModal"
        id="errorModalCenter1"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content modal-radius">
            <div className="modal-header error-popup-header-bg">
              <h5 className="modal-title confirmation-style" id="errorModalCenterTitle">
                Error
              </h5>

            </div>
            <div className="modal-body text-align-centre" >
              <div>{state.errorMsgPopup}</div>
            </div>
            <div className="modal-footer bo-modalbtnftr bt-none">
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-yes"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default DCCreateDespatchModify;
