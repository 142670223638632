import React from "react";
import moment from "moment";

const ViewDetailsItem = ({ detail_1, index, ...props }) => {
  const user = JSON.parse(localStorage.getItem("User"));

  return (
    <>
      {/* <div
      className="card-ublslide collapse view-details"
      // style={{ borderRadius: "15px" , width: "30%"}}
      id={"collapseExample" + index}
    >
      <div className="container">
        <div
          className="row view-details-header"
          
        >
          <div className="col-6" style={{ color: "white" }}>
            View Details
          </div>
          <div
            className="col-6 close-modal"
            data-toggle="collapse"
            href={"#collapseExample" + index}
            role="button"
            aria-expanded="false"
            data-placement="right"
            aria-controls="collapseExample"
            id="ViewDetails"
          ></div>
        </div>
        <div className="row">
              <div className="col-6">
                <div className="card">
                  <div className="card-details">
                    <h5>Supplier</h5>
                    <h6>{detail_1.supplier}</h6>
                  </div>
                  </div>
                  <div className="card">
                  <div className="card-details">
                    <h5>Supplier Invoice #</h5>
                    <h6>{detail_1.supplierInvoice}</h6>
                  </div>
                  </div>
                  <div className="card">
                  <div className="card-details">
                    <h5>Supplier PO </h5>
                    <h6>{detail_1.supplierPO}</h6>
                  </div>
                </div>


                <div className="card">
                  <div className="card-details">
                    <h5>Return Order #</h5>
                    <h6>{detail_1.returnOrderNum}</h6>
                  </div>
                  </div>
                  <div className="card">
                  <div className="card-details">
                    <h5>Return Created by</h5>
                    <h6>{detail_1.returnCreatedBy}</h6>
                  </div>
                  </div>
                  <div className="card">
                  <div className="card-details">
                    <h5>Return Notes field</h5>
                    <h6>{detail_1.reasonNotes}</h6>
                  </div>
                </div>


                <div className="card">
                  <div className="card-details">
                    <h5>MP Ship Node</h5>
                    <h6>{detail_1.mpShipNode}</h6>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="card">
                  <div className="card-details">
                    <h5>Item Class Group</h5>
                    <h6>{detail_1.itemClsGrp + " " + (detail_1.itemClsGrpDescription === 'null' ? '' : detail_1.itemClsGrpDescription)}</h6>
                  </div>
                  </div>
                  <div className="card">
                  <div className="card-details">
                    <h5>Item TPC</h5>
                    <h6>{detail_1.itemTPC}</h6>
                  </div>
                  </div>
                  <div className="card">
                  <div className="card-details">
                    <h5>Brand</h5>
                    <h6>{detail_1.brand}</h6>
                  </div>
                </div>


                <div className="card">
                  <div className="card-details">
                    <h5>Return Location</h5>
                    <h6>{detail_1.returnLocation}</h6>
                  </div>
                </div>

                <div className="card">
                  <div className="card-details">
                    <h5>Item's Destination</h5>
                    <h6>{detail_1.itemDestination} </h6>
                  </div>
                </div>
                <div className="card">
                  <div className="card-details">
                    <h5>Sales/Customer Order #</h5>
                    <h6>{detail_1.salesOrderNumber}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
   </div>*/}
      <div class="modal summary-modal right fade"
        id={"collapseExample" + index}
        tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog" role="document">
          <div class="modal-content">

            <div class="modal-header top-right-border">

              <h4 class="modal-title" id="myModalLabel">View Summary</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body of-y">
              <div class="d-flex bd-highlight">
                <div class="bd-highlight item-num">
                  Item #: <span>{detail_1.itemNum}</span>

                </div>

                <div class="ml-auto view-det-link bd-highlight">
                  <div class="barcode-font">
                    {detail_1.ublId}
                  </div>
                </div>
              </div>

              <div class=" item-name mb-3"> {detail_1.itemDesc} </div>


              <div class="card">
                <div class="card-header">
                  <div className="row">
                    <div className="col-md-6 col-xs-12">
                      <h6>Item Summary Details</h6>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <h6></h6>
                    </div>
                  </div>

                </div>
                <div class="card-body">
                  <div className="row">
                    <div className="col-md-6 col-xs-12 mb-3">
                      <h6>Customer Order#</h6>
                      <span>{detail_1.salesOrderNumber}</span>
                    </div>
                    <div className="col-md-6 col-xs-12 mb-3">
                      <h6>Quantity</h6>
                      <span>{detail_1.qty}</span>
                    </div>

                    <div className="col-md-6 col-xs-12 mb-3">
                      <h6>Supplier PO #</h6>
                      <span>{detail_1.supplierPO}</span>
                    </div>
                    <div className="col-md-6 col-xs-12 mb-3">
                      <h6>Return Location</h6>
                      <span> {user.storeNo} - {user.storeName}</span>
                    </div>

                    <div className="col-md-6 col-xs-12 mb-3">
                      <h6>Supplier </h6>
                      <span>{detail_1.supplierNo}{" "}{detail_1.supplier}</span>
                    </div>
                    <div className="col-md-6 col-xs-12 mb-3">
                      <h6>Return Reason</h6>
                      <span>{detail_1.reasonCode}</span>
                    </div>

                    <div className="col-md-6 col-xs-12 mb-3">
                      <h6>Brand</h6>
                      <span>{detail_1.brand}</span>
                    </div>
                    <div className="col-md-6 col-xs-12 mb-3">
                      <h6>Return Received Date</h6>
                      <span>{detail_1.returnReceivedFrom ?
                        moment.utc(detail_1.returnReceivedFrom).format("DD-MM-YYYY | hh:mm A") : "-"}</span>
                    </div>
                    <div className="col-md-6 col-xs-12 mb-3">
                      <h6>Return Created Date</h6>
                      <span>{detail_1.returnCreatedOn ?
                        moment.utc(detail_1.returnCreatedOn).format("DD-MM-YYYY | hh:mm A") : "-"}</span>
                    </div>
                    <div className="col-md-6 col-xs-12 ">
                      <h6>Return Created By</h6>
                      <span>{detail_1.returnCreatedBy}</span>
                    </div>
                   
                    <div className="col-md-6 col-xs-12 ">
                      <h6>Return Order#</h6>
                      <span>{detail_1.returnOrderNum}</span>
                    </div>

                    <div className="col-md-6 col-xs-12 mb-3">
                      <h6>Status</h6>
                      <span>{
                        detail_1.status == "DS" ? "Despatch to Supplier" :
                          detail_1.status == "RD" ? "Ready Despatch to Supplier" :
                            detail_1.dcPutawayLocation ? "Putaway in DC" :
                              detail_1.status == "C" ?
                                (detail_1.shipId ? ("Ready Despatch to DC") :
                                  detail_1.cublId ? "CBL tagged" : "UBL tagged") :
                                detail_1.status == "D" ? ("Despatched to DC") :
                                  detail_1.status == "R" ? ("Received at DC") :
                                    detail_1.status == "M" ? ("Item Missing") :
                                      detail_1.status == "X" ? ("Detagged") :
                                        "New Return"}   <br />

                        {(!detail_1.status || detail_1.status == "C" || detail_1.status == "D" || detail_1.status == "O"
                          || detail_1.status == "X") ? (("00" + detail_1.returnLocation).slice(-3) + "-" + detail_1.storeName
                        ) : detail_1.hubLocation ? ((detail_1.hubLocation.split(",").length > 1 && detail_1.hubLocation.split(",")[0].trim().toLowerCase() != "null") ? (
                          (detail_1.hubLocation.split(",")[0]).toString().padStart(3, "0") + "-" +
                          detail_1.hubLocation.split(",")[1]) : null) : null},

                        {
                          detail_1.updatedDateTimeUbl ?
                            (moment.utc(detail_1.updatedDateTimeUbl).format("DD-MM-YYYY | hh:mm A")
                            ) :
                            detail_1.createdDateTimeUbl ? (moment.utc(detail_1.createdDateTimeUbl).format("DD-MM-YYYY | hh:mm A")) :
                              detail_1.createdDateTimeReturnOrder ? (moment.utc(detail_1.createdDateTimeReturnOrder).format("DD-MM-YYYY | hh:mm A")) : null

                        }</span>
                    </div>



                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </>


  );
};

export default ViewDetailsItem;
