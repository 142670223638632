const ViewManifestDC = {
  updateManifest(dcid,manifestid,token,supplierno){
    return fetch(
      process.env.REACT_APP_API_BASE_ENDPOINT +
      process.env.REACT_APP_DCID +
      parseInt(dcid) +
      "/despatchSupplier",
      {
        method: "PUT",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          Authorization: token,
        },
        body: JSON.stringify({
          manifestId:manifestid,
          supplierNo:supplierno         
        }),
      }
    )
      .then((response) => {
        if (response.status == 200) {
          console.log(200);
          console.log(response);
          return response.json();
        } else {
          response = {};
          console.log(400);
          response.message = "Unable to update";
        }
        console.log(300);
        return response;
      })
      .catch((err) => {
        return err;
      });

  },

    getManifestDetails(dcid,manifestid,carrierref,token,print='N',download='N'){
        return fetch(
            process.env.REACT_APP_API_BASE_ENDPOINT +
              process.env.REACT_APP_DCID +
              parseInt(dcid)+
              "/viewDCManifest?manifestId=" +
              manifestid+"&carrierConNo="+carrierref
              +"&print="+print
              +"&download="+download
             
              +"&pageNumber=1&pageSize=1000"
              
              ,
            {
              method: "GET",
              headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                Authorization: token,
              },
            }
          )
            .then((response) => {
              if (response.status == 200) {
                return response.json();
              } else {
                response = {};
                response.message = "Details Not Found";
              }
      
              return response;
            })
            .catch((err) => {
              return err;
            });

    },
    getManifestlist(dcid,manifestIDstatus,token){
       return fetch(
           process.env.REACT_APP_API_BASE_ENDPOINT +
             process.env.REACT_APP_DCID +
             dcid +
             "/dcManifestDetails?manifestStatus=" +
             manifestIDstatus,
           {
             method: "GET",
             headers: {
               "x-api-key": process.env.REACT_APP_API_KEY,
               Authorization: token,
             },
           }
         )
           .then((response) => {
             if (response.status == 200) {
               return response.json();
             } else {
               response = {};
               response.message = "Details Not Found";
             }
     
             return response;
           })
           .catch((err) => {
             return err;
           });
   
   
    },
   
   
   
   
   }
   export default ViewManifestDC;