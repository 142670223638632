import * as types from "redux/actions/ActionTypes";
import initialState from "redux/reducers/initialState";

export default function UblReducer(state = initialState.ublData, action) {
  switch (action.type) {
    case types.LOAD_UBL_DATA_IN_PROGRESS:
      return {
        ...state,
        pending: true,
      };
    case types.LOAD_UBL_DATA_SUCCESS:
      return {
        ...state,
        pending: false,
        ubl: action.ubl,
      };
    case types.LOAD_UBL_DATA_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error,
      };

    case types.ADD_UBL_DATA_IN_PROGRESS:
      return true;
    case types.ADD_UBL_DATA_SUCCESS:
      return {
        ...state,
        addUblMessage: action.addUblMessage,
      };
    case types.ADD_UBL_DATA_FAILURE:
      return false;

    case types.VIEW_UBL_SUMMARY_IN_PROGRESS:
      return true;
    case types.VIEW_UBL_SUMMARY_SUCCESS:
      // return {
      //   ...state,
      //   vv: { ...state.vv, ...action.vv },
      // };
      return {
        ...state,
        viewUblSummary: { ...state.viewUblSummary, ...action.viewUblSummary },
      };
    case types.VIEW_UBL_SUMMARY_FAILURE:
      return false;

    case types.VIEW_MODIFY_CBL_DATA_IN_PROGRESS:
      return {
        ...state,
        pending: true,
      };
    case types.VIEW_MODIFY_CBL_DATA_SUCCESS:
      return {
        ...state,
        pending: false,
        modifyCbl: action.modifyCbl,
      };
    case types.VIEW_MODIFY_CBL_DATA_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case types.LOAD_CBL_MAPTOUBL_DATA_IN_PROGRESS:
      return true;
    case types.LOAD_CBL_MAPTOUBL_DATA_SUCCESS:
      return {
        ...state,
        cublMapToUbl: action.cublMapToUbl,
      };
    case types.LOAD_CBL_MAPTOUBL_DATA_FAILURE:
      return false;

    default:
      return state;
  }
}
