import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";

export default function Sidebar({ ...props }) {

  const user = JSON.parse(localStorage.getItem("User"));
  const [arrow, setarrow] = useState(
    props.active == "CreateShipment" || props.active == "ModifyShipment"
  );
  const [arrowCBL, setarrowCBL] = useState(
    props.active == "CreateCbl" || props.active == "ModifyCbl"
  );
  const [arrowReceipting, setArrowReceipting] = useState(
    props.active == "DCReceipting" ||
    props.active == "DCReceiptingMissing"
  )

  const [arrowDCItemTagging, setArrowDCItemTagging] = useState(
    props.active == "DCCreateDespatchNew" ||
    props.active == "DCCreateDespatchModify"
  );


  const onclickarrow = () => {
    setarrow((prev) => !prev);
    setarrowCBL(false);
    setArrowDespatch(false);
    setArrowDCItemTagging(false);
    setArrowReceipting(false);
    setArrowDCItemTagging(false)
    setArrowDcDespatch(false)
  };
  const [arrowDespatch, setArrowDespatch] = useState(
    props.active == "CreateDespatchNew" ||
    props.active == "CreateDespatchModify"
  );
  const [arrowDcDespatch, setArrowDcDespatch] = useState(
    props.active == "CreateDcDespatch" ||
    props.active == "ModifyDcDespatch" ||
    props.active == "ViewDcDespatchManifest"
  );
  const onclickarrowDespatch = () => {
    setArrowDespatch((prev) => !prev);
    setarrow(false);
    setarrowCBL(false);
    setArrowReceipting(false);
    setArrowDCItemPutaway(false);
    setArrowDCItemTagging(false)
    setArrowDcDespatch(false)
  };

  const onclickarrowCBL = () => {
    setarrowCBL((prev) => !prev);
    setArrowDespatch(false);
    setarrow(false);
    setArrowDCItemTagging(false);
    setArrowReceipting(false);
    setArrowDCItemPutaway(false)
    setArrowDcDespatch(false)
  };

  const [arrowDCItemPutaway, setArrowDCItemPutaway] = useState(
    props.active === "CreatePutaway" || props.active === "ModifyPutaway"
  );

  const onclickArrowDCItemPutaway = () => {
    setArrowDCItemPutaway((prev) => !prev);
    setarrow(false);
    setarrowCBL(false);
    setArrowDespatch(false);
    setArrowReceipting(false);
    setArrowDCItemTagging(false)
    setArrowDcDespatch(false)
  };
  const onclickarrowReceipting = () => {
    setArrowDCItemTagging(false);
    setarrowCBL(false);
    setArrowDespatch(false);
    setarrow(false);
    setArrowReceipting((prev) => !prev);
    setArrowDCItemPutaway(false)
    setArrowDCItemTagging(false)
    setArrowDcDespatch(false)
  };
  const onclickarrowDCItemTagging = () => {
    setArrowDCItemTagging((prev) => !prev);
    setarrowCBL(false);
    setArrowDespatch(false);
    setarrow(false);
    setArrowReceipting(false);
    setArrowDCItemPutaway(false)
    setArrowDcDespatch(false)
    //setArrowDCItemTagging((prev) => !prev)
  };
  const onclickarrowDcDespatch = () => {
    setArrowDcDespatch((prev) => !prev);
    setArrowDCItemTagging(false);
    setarrowCBL(false);
    setArrowDespatch(false);
    setarrow(false);
    setArrowReceipting(false);
    setArrowDCItemPutaway(false)
  };

  return (
    <nav
      id="sidebarMenu"
      className="col-md-3 col-lg-2 padding-reset d-md-block bg-white sidebar collapse"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="sidebar-sticky">
        <img
          className="logo-sidemenu"
          src="./images/Logo/logo-blk.png"
          alt=""
        />
        <ul className="nav flex-column returns-mainmenu">
          <li className="nav-item">
            <Link
              className="nav-link"
              to={
                user.tempSearchMenu
                  ? { pathname: "/LandingPage", state: user }
                  : { pathname: "/LandingPage", state: user }
              }
            >
              Dashboard
            </Link>
          </li>
          {!user.tempSearchMenu ? (
            <li className="nav-item">
              <Link
                className={
                  props.active === "OrderLookup"
                    ? "nav-link active"
                    : "nav-link"
                }
                to={
                  user.tempSearchMenu
                    ? { pathname: "/LandingPage", state: user }
                    : { pathname: "/OrderLookup", state: user }
                }
                onClick={() => { $("#sidebarMenu").removeClass("show") }}
              >
                Search Return Order
                {props.active === "OrderLookup" ? (
                  <span className="sr-only">(current)</span>
                ) : null}
              </Link>
            </li>
          ) : null}

          {!props.state.tempDC ? (
            <>
              <li className="nav-item">
                <div
                  className={
                    props.active == "DCReceipting" ||
                      props.active == "DCReceiptingMissing"
                      ? "nav-link active navbar-toggle"
                      : "nav-link navbar-toggle"
                  }
                  onClick={onclickarrowReceipting}
                >
                  DC Receipting{" "}
                  <span class="float-right mt-4p">
                    {arrowReceipting ? (
                      <FontAwesomeIcon icon={faAngleUp} />
                    ) : (
                      <FontAwesomeIcon icon={faAngleDown} />
                    )}
                  </span>
                </div>
                <div
                  class={
                    props.active == "DCReceipting" ||
                      props.active == "DCReceiptingMissing"
                      ? " show navbar-collapse"
                      : " navbar-collapse"
                  }
                  id="dccreateDespatchDataTarget"
                  aria-expanded={
                    props.active == "DCReceipting" ||
                    props.active == "DCReceiptingMissing"
                  }
                  hidden={!arrowReceipting}
                >
                  <ul class="nav navbar-nav navbar-left sub-ul ml-25">
                    <li
                      class={
                        props.active == "DCReceipting"
                          ? "nav-item mt-4p pl24 active-submenu"
                          : "nav-item mt-4p pl24 inactive-submenu"
                      }
                    >
                      <Link
                        to={{ pathname: "/DCReceiptingUbl", state: user }}
                        style={{ height: "20%", padding: "3%" }}
                        class="nav-link"
                        onClick={() => { $("#sidebarMenu").removeClass("show") }}
                      >
                        Create DC Receipt
                      </Link>

                    </li>


                    <li
                      class={
                        props.active == "DCReceiptingMissing"
                          ? "nav-item mt-4p pl24 active-submenu"
                          : "nav-item mt-4p pl24 inactive-submenu"
                      }
                    >
                      <Link
                        to={{ pathname: "/DCReceiptingMissing", state: user }}
                        style={{ height: "20%", padding: "3%" }}
                        class="nav-link"
                        onClick={() => { $("#sidebarMenu").removeClass("show") }}
                      >
                        Flag Missing UBLs
                      </Link>
                    </li>
                  </ul>
                </div>
              </li >
              <li className="nav-item">
                <div
                  className={
                    props.active === "CreatePutaway" ||
                      props.active === "ModifyPutaway"
                      ? "nav-link active navbar-toggle"
                      : "nav-link navbar-toggle"
                  }
                  onClick={onclickArrowDCItemPutaway}
                >
                  DC Item Putaway{" "}
                  <span class="float-right mt-4p">
                    {arrowDCItemPutaway ? (
                      <FontAwesomeIcon icon={faAngleUp} />
                    ) : (
                      <FontAwesomeIcon icon={faAngleDown} />
                    )}
                  </span>
                </div>
                <div
                  class={
                    props.active === "CreatePutaway"
                      ? " show navbar-collapse"
                      : " navbar-collapse"
                  }
                  id="createDCPutaway"
                  aria-expanded={props.active === "CreatePutaway"}
                  hidden={!arrowDCItemPutaway}
                >
                  <ul class="nav navbar-nav navbar-left sub-ul ml-25">
                    <li
                      class={
                        props.active === "CreatePutaway"
                          ? "nav-item mt-4p pl24 active-submenu"
                          : "nav-item mt-4p pl24 inactive-submenu"
                      }
                    >
                      <Link
                        to={{ pathname: "/DcItemPutAway", state: user }}
                        style={{ height: "20%", padding: "3%" }}
                        class="nav-link"
                        onClick={() => { $("#sidebarMenu").removeClass("show") }}
                      >
                        Create Putaway
                      </Link>
                    </li>
                    <li
                      class={
                        props.active === "ModifyPutaway"
                          ? "nav-item mt-4p pl24 active-submenu"
                          : "nav-item mt-4p pl24 inactive-submenu"
                      }
                    >
                      <Link
                        to={{
                          pathname: "/DCItemPutawayModify",
                          state: user,
                        }}
                        style={{ height: "20%", padding: "3%" }}
                        class="nav-link"
                        onClick={() => { $("#sidebarMenu").removeClass("show") }}
                      >
                        Modify Putaway
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="nav-item">
                <div
                  className={
                    props.active == "DCCreateDespatchNew" ||
                      props.active == "DCCreateDespatchModify"
                      ? "nav-link active navbar-toggle"
                      : "nav-link navbar-toggle"
                  }
                  onClick={onclickarrowDCItemTagging}
                >
                Tag UBL{" "}
                  <span class="float-right mt-4p">
                    {arrowDCItemTagging ? (
                      <FontAwesomeIcon icon={faAngleUp} />
                    ) : (
                      <FontAwesomeIcon icon={faAngleDown} />
                    )}
                  </span>
                </div>
                <div
                  class={
                    props.active == "DCCreateDespatchNew" ||
                      props.active == "DCCreateDespatchModify"
                      ? " show navbar-collapse"
                      : " navbar-collapse"
                  }
                  id="dccreateDespatchDataTarget"
                  aria-expanded={
                    props.active == "DCCreateDespatchNew" ||
                    props.active == "DCCreateDespatchModify"
                  }
                  hidden={!arrowDCItemTagging}
                >
                  <ul class="nav navbar-nav navbar-left sub-ul ml-25">
                    <li
                      class={
                        props.active == "DCCreateDespatchNew"
                          ? "nav-item mt-4p pl24 active-submenu"
                          : "nav-item mt-4p pl24 inactive-submenu"
                      }
                    >
                      <Link
                        to={{ pathname: "/DCCreateDespatchNew", state: user }}
                        style={{ height: "20%", padding: "3%" }}
                        class="nav-link"
                        onClick={() => { $("#sidebarMenu").removeClass("show") }}
                      >
                        New UBL
                      </Link>
                    </li>
                    <li
                      class={
                        props.active == "DCCreateDespatchModify"
                          ? "nav-item mt-4p pl24 active-submenu"
                          : "nav-item mt-4p pl24 inactive-submenu"
                      }
                    >
                      <Link
                        to={{ pathname: "/DCCreateDespatchModify", state: user }}
                        style={{ height: "20%", padding: "3%" }}
                        class="nav-link"
                        onClick={() => { $("#sidebarMenu").removeClass("show") }}
                      >
                        Modify UBL
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <div
                  className={
                    props.active == "CreateDcDespatch" ||
                      props.active == "ModifyDcDespatch" ||
                      props.active == "ViewDcDespatchManifest"
                      ? "nav-link active navbar-toggle"
                      : "nav-link navbar-toggle"
                  }
                  onClick={onclickarrowDcDespatch}
                >
                  DC Despatch
                  <span class="float-right mt-4p">
                    {arrowDcDespatch ? (
                      <FontAwesomeIcon icon={faAngleUp} />
                    ) : (
                      <FontAwesomeIcon icon={faAngleDown} />
                    )}
                  </span>
                </div>
                <div
                  class={
                    props.active == "CreateDcDespatch" ||
                      props.active == "ModifyDcDespatch" ||
                      props.active == "ViewDcDespatchManifest"
                      ? " show navbar-collapse"
                      : " navbar-collapse"
                  }
                  id="createDespatchDataTarget"
                  aria-expanded={
                    props.active == "CreateDcDespatch" ||
                    props.active == "ModifyDcDespatch" ||
                    props.active == "ViewDcDespatchManifest"
                  }
                  hidden={!arrowDcDespatch}
                >
                  <ul class="nav navbar-nav navbar-left sub-ul ml-25">
                    <li
                      class={
                        props.active == "CreateDcDespatch"
                          ? "nav-item mt-4p pl24 active-submenu"
                          : "nav-item mt-4p pl24 inactive-submenu"
                      }
                    >
                      <Link
                        to={{ pathname: "/CreateDcDespatch", state: user }}
                        style={{ height: "20%", padding: "3%" }}
                        class="nav-link"
                        onClick={() => { $("#sidebarMenu").removeClass("show") }}
                      >
                        Create Despatch
                      </Link>
                    </li>
                    <li
                      class={
                        props.active == "ModifyDcDespatch"
                          ? "nav-item mt-4p pl24 active-submenu"
                          : "nav-item mt-4p pl24 inactive-submenu"
                      }
                    >
                      <Link
                        to={{
                          pathname: "/ModifyDcDespatch",
                          state: user,
                        }}
                        style={{ height: "20%", padding: "3%" }}
                        class="nav-link"
                        onClick={() => { $("#sidebarMenu").removeClass("show") }}
                      >
                        Modify Despatch
                      </Link>
                    </li>
                    <li
                      class={
                        props.active == "ViewDcDespatchManifest"
                          ? "nav-item mt-4p pl24 active-submenu"
                          : "nav-item mt-4p pl24 inactive-submenu"
                      }
                    >
                      <Link
                        to={{
                          pathname: "/ViewDcDespatchManifest",
                          state: user,
                        }}
                        style={{ height: "20%", padding: "3%" }}
                        class="nav-link"
                        onClick={() => { $("#sidebarMenu").removeClass("show") }}
                      >
                        View Manifest
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            </>
          ) : null
          }
          {
            !props.state.tempCreateDispatchInStoreMenu ? (
              <li className="nav-item">
                <div
                  className={
                    props.active == "CreateDespatchNew" ||
                      props.active == "CreateDespatchModify"
                      ? "nav-link active navbar-toggle"
                      : "nav-link navbar-toggle"
                  }
                  onClick={onclickarrowDespatch}
                >
                  Tag UBL{" "}
                  <span class="float-right mt-4p">
                    {arrowDespatch ? (
                      <FontAwesomeIcon icon={faAngleUp} />
                    ) : (
                      <FontAwesomeIcon icon={faAngleDown} />
                    )}
                  </span>
                </div>
                <div
                  class={
                    props.active == "CreateDespatchNew" ||
                      props.active == "CreateDespatchModify"
                      ? " show navbar-collapse"
                      : " navbar-collapse"
                  }
                  id="createDespatchDataTarget"
                  aria-expanded={
                    props.active == "CreateDespatchNew" ||
                    props.active == "CreateDespatchModify"
                  }
                  hidden={!arrowDespatch}
                >
                  <ul class="nav navbar-nav navbar-left sub-ul ml-25">
                    <li
                      class={
                        props.active == "CreateDespatchNew"
                          ? "nav-item mt-4p pl24 active-submenu"
                          : "nav-item mt-4p pl24 inactive-submenu"
                      }
                    >
                      <Link
                        to={{ pathname: "/CreateDespatchNew", state: user }}
                        style={{ height: "20%", padding: "3%" }}
                        class="nav-link"
                        onClick={() => { $("#sidebarMenu").removeClass("show") }}
                      >
                        New UBL
                      </Link>
                    </li>
                    <li
                      class={
                        props.active == "CreateDespatchModify"
                          ? "nav-item mt-4p pl24 active-submenu"
                          : "nav-item mt-4p pl24 inactive-submenu"
                      }
                    >
                      <Link
                        to={{ pathname: "/CreateDespatchModify", state: user }}
                        style={{ height: "20%", padding: "3%" }}
                        class="nav-link"
                        onClick={() => { $("#sidebarMenu").removeClass("show") }}
                      >
                        Modify UBL
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              
            ) : null
          }
          {
            !user.tempCreateConsolidatedDispatchMenu ? (
              <li className="nav-item">
                <div
                  className={
                    props.active == "CreateCbl" || props.active == "ModifyCbl"
                      ? "nav-link active navbar-toggle"
                      : "nav-link navbar-toggle"
                  }
                  data-target="#mynavbar1"
                  onClick={onclickarrowCBL}
                >
                Create CBL
                  <span class="float-right mt-4p">
                    {arrowCBL ? (
                      <FontAwesomeIcon icon={faAngleUp} />
                    ) : (
                      <FontAwesomeIcon icon={faAngleDown} />
                    )}
                  </span>
                </div>

                <div
                  class={
                    props.active == "CreateCbl" || props.active == "ModifyCbl"
                      ? " show navbar-collapse"
                      : " navbar-collapse"
                  }
                  id="mynavbar1"
                  aria-expanded={
                    props.active == "CreateCbl" || props.active == "ModifyCbl"
                  }
                >
                  <ul
                    class="nav navbar-nav navbar-left sub-ul ml-25"
                    hidden={!arrowCBL}
                  >
                    <li
                      class={
                        props.active == "CreateCbl"
                          ? "nav-item mt-4p pl24 active-submenu"
                          : "nav-item mt-4p pl24 inactive-submenu"
                      }
                    >
                      <Link
                        to={{
                          pathname: "/CreateCbl",
                          state: user,
                          type: "createCBL",
                        }}
                        style={{ height: "20%", padding: "3%" }}
                        class="nav-link"
                        onClick={() => { $("#sidebarMenu").removeClass("show") }}
                      >
                        New CBL
                      </Link>
                    </li>
                    <li
                      class={
                        props.active == "ModifyCbl"
                          ? "nav-item mt-4p pl24 active-submenu"
                          : "nav-item mt-4p pl24 inactive-submenu"
                      }
                    >
                      <Link
                        to={{
                          pathname: "/ModifyCbl",
                          state: user,
                          type: "modifyCBL",
                        }}
                        style={{ height: "20%", padding: "3%" }}
                        class="nav-link"
                        onClick={() => { $("#sidebarMenu").removeClass("show") }}
                      >
                        Modify CBL
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            ) : null
          }
          {
            !props.state.tempCreateShipment ? (
              <li className="nav-item">
                <div
                  className={
                    props.active == "CreateShipment" ||
                      props.active == "ModifyShipment"
                      ? "nav-link active navbar-toggle"
                      : "nav-link navbar-toggle"
                  }
                  onClick={onclickarrow}
                >
                  Shipment{" "}
                  <span class="float-right mt-4p">
                    {arrow ? (
                      <FontAwesomeIcon icon={faAngleUp} />
                    ) : (
                      <FontAwesomeIcon icon={faAngleDown} />
                    )}
                  </span>
                </div>
                <div
                  class={
                    props.active == "CreateShipment" ||
                      props.active == "ModifyShipment"
                      ? "show navbar-collapse"
                      : "navbar-collapse"
                  }
                  id="mynavbar"
                  aria-expanded={
                    props.active == "CreateShipment" ||
                    props.active == "ModifyShipment"
                  }
                  hidden={!arrow}
                >
                  <ul class="nav navbar-nav navbar-left sub-ul">
                    <li
                      class={
                        props.active == "CreateShipment"
                          ? "nav-item mt-4p pl24 active-submenu"
                          : "nav-item mt-4p pl24 inactive-submenu"
                      }
                    >
                      <Link
                        to={{ pathname: "/CreateShipment", state: user }}
                        style={{ height: "20%", padding: "3%" }}
                        class="nav-link"
                        onClick={() => { $("#sidebarMenu").removeClass("show") }}
                      >
                        New Shipment
                      </Link>
                    </li>
                    <li
                      class={
                        props.active == "ModifyShipment"
                          ? "nav-item mt-4p pl24 active-submenu"
                          : "nav-item mt-4p pl24 inactive-submenu"
                      }
                    >
                      <Link
                        to={{ pathname: "/ModifyShipment", state: user }}
                        style={{ height: "20%", padding: "3%" }}
                        class="nav-link"
                        onClick={() => { $("#sidebarMenu").removeClass("show") }}
                      >
                        Modify Shipment
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            ) : null
          }
        </ul >
      </div >
    </nav >
  );
}
