import Header from "components/common/Header";
import LoadingIndicator from "components/common/LoadingIndicator";
import Sidebar from "components/common/Sidebar";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import Toast from "react-bootstrap/Toast";
import { useIdleTimer } from "react-idle-timer";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  addCubl,
  cblMapToUbl,
  deleteCubl,
  getCubl,
  loadCblData,
  loadCblDestination,
  viewUblSummary,
} from "redux/thunks";
import CblService from "services/CblService";
import UblService from "services/UblService";
import { callLogoutURL } from "../../Authentication/utilities";
import ModifyCblPage from "./ModifyCblPage";
import ViewSummary from "./ViewSummary";

const ConsolidateDispatchModify = ({
  loadCblData,
  loadCblDestination,
  viewUblSummary,
  getCubl,
  deleteCubl,
  cblMapToUbl,
  ubl,
  ublData,
  cbl,
  viewUbl,
  message,
  message2,
  ...props
}) => {
  const user = JSON.parse(localStorage.getItem("User"));
  const { pending, error } = ublData;
  const [crumbs, setCrumbs] = useState(["Home", "Create Dispatch In Store"]);
  const [edit, setEdit] = useState(false);
  const [cart, setCart] = useState([]);
  const [current, setCurrent] = useState(0);
  const [index, setIndex] = useState(0);
  const [pageno, setpageno] = useState(1);
  const [ublIds, setUblIds] = useState([]);
  const [select, setSelect] = useState(false);
  const [barcodeValid, setBarcodeValidation] = useState(true);
  const [loader, setLoader] = useState(false);
  const [cblInputText, setCblInputText] = useState("");
  const [ublInputText, setUblInputText] = useState("");
  const [errorMsgPopup, setErrorMsgPopup] = useState("");
  const [stepperLabel, setStepperLable] = useState("");
  const [errmsg, seterrmsg] = useState("");
  const [cancel, setcancel] = useState(true);
  const [headermsg, setheadermsg] = useState("");
  const [cublId, setcublId] = useState("");
  const [valid, setvalid] = useState(true);
  const [toggle, settoggle] = useState(true);
  const [timeoutDuration] = useState(
    localStorage.getItem("timeout") ? localStorage.getItem("timeout") : 1800000
  );
  console.log("UBLLLLL", pending, error);
  const [itemTaggingToast, setItemTaggingToast] = useState(false);
  const [toastmsg, settoastmsg] = useState("");
  const [storename, setstorename] = useState("");
  const [shipment, setshipment] = useState(null);
  const [state, setState] = useState({
    errorMsgPopup: "",
    search: false,
    details: [],
    showLoader: false,
  });
  console.log(viewUbl);
  useEffect(() => {}, [cancel, toggle]);
  if (typeof window != "undefined") {
    console.log("listener", localStorage.getItem("access_token"));
    window.addEventListener("storage", function (e) {
      if (localStorage.getItem("access_token") == "null") {
        callLogoutURL();
        return;
      }
    });
  }

  useEffect(() => {
    if (localStorage.getItem("access_token") == "null") {
      callLogoutURL();
      return;
    }
    window.addEventListener("scroll", handleOnActive);
    return () => window.removeEventListener("scroll", handleOnAction);
  }, []);
  useEffect(async () => {
    setLoader(true);
    await loadCblData(user.storeNo, user.token);
    await loadCblDestination(user.storeNo, user.token);
    setLoader(false);
    const cc = cbl.length > 0 ? localStorage.setItem("DCNO", cbl[0].DC) : null;
    const dd =
      cbl.length > 0 ? localStorage.setItem("DCNAME", cbl[0].DC_NAME) : null;
  }, []);
  const shouldComponentRender = () => {
    const { pending } = ublData;

    if (pending === false) return false;
    // more tests
    return true;
  };

  console.log("STEPPERLABLE", stepperLabel);

  const setCurrentStep = () => {};
  const handleViewUblSummary = (index1) => {
    setIndex(index1, console.log(index));
  };

  const handleMapCblToUbl = (e) => {
    e.preventDefault();
    cblMapToUbl(user.storeNo, cblInputText, ublIds, user.token);
    handleGoNextScreen();
  };
  const handleOnUblTextChange = (e) => {
    setUblInputText(e.target.value.replace(/[^0-9.]/g, ""));
    localStorage.setItem("UBLID", ublInputText);
  };
  const handleOnChange = (ubl_list, con) => {
    // Handle function for select all checkboxes
    let temp = ublIds;
    console.log(temp);
    console.log(ubl_list);
    let det_lis = [];
    let temp_ublids = [];

    if (con == "add") {
      setUblIds([...new Set(temp.concat(ubl_list))]);
      temp_ublids = [...new Set(temp.concat(ubl_list))];
    } else {
      if (temp.filter((a) => !ubl_list.includes(a)).length > 0) {
        setUblIds(temp.filter((a) => !ubl_list.includes(a)));
        temp_ublids = temp.filter((a) => !ubl_list.includes(a));
      } else setUblIds([]);
      console.log(temp.filter((a) => !ubl_list.includes(a)));
      temp_ublids = [];
    }

    setCart(ubl.filter((a) => temp_ublids.includes(a.UBL_ID)));
  };

  const handleChange = (e) => {
    let newArray = [...cart, e];
    const arrayWithFilterObjects = cart.filter((c) => c.UBL_ID === e.UBL_ID);

    if (arrayWithFilterObjects.length === 1) {
      newArray = newArray.filter((item) => item.UBL_ID !== e.UBL_ID);
    }
    console.log(newArray.map((ubl, index) => ubl.UBL_ID));
    setUblIds(newArray.map((ubl, index) => ubl.UBL_ID));
    setCart(newArray);
    settoggle((prev) => !prev);
  };

  const handleAddUbl = async (e) => {
    e.preventDefault();
    setLoader(true);
    console.log(user.storeNo, cblInputText, shipment);

    UblService.mapCublToUbl(
      user.storeNo,
      cblInputText,
      [ublInputText],
      user.token,
      shipment
    )
      .then((response) => {
        if (
          response.updateUblHeader.message ==
          "UBL Headers updated with CUBL and Ship details"
        )
          CblService.viewUBLSummary(user.storeNo, cblInputText, user.token)
            .then((response1) => {
              setCart(response1.UblSummaryDetails.details);
            })
            .catch((err) => console.log(err));
        else {
          setheadermsg("Error");
          seterrmsg("Invalid ubl Id");
          $("#errorModalCenter1").modal();
        }
      })
      .catch((err) => console.log(err));
    setLoader(false);
  };
  const handleAddCubl = async (e) => {
    e.preventDefault();
    setLoader(true);
    console.log(cblInputText);
    if (cblInputText == "") {
      setheadermsg("Error");
      seterrmsg("CBL ID must not be Empty");
      $("#errorModalCenter1").modal();
      return;
    }
    if (barcodeValid) {
      CblService.getCublHeader(user.storeNo, cblInputText, user.token)
        .then((response) => {
          if (
            response.CublDetails.message ==
              "CUBL Details fetched successfully" &&
            response.CublDetails.details[0].CUBL_STATUS == "C"
          ) {
            setshipment(
              !response.CublDetails.details[0].SHIP_ID ||
                response.CublDetails.details[0].SHIP_ID == "null"
                ? null
                : response.CublDetails.details[0].SHIP_ID
            );
            console.log(
              !response.CublDetails.details[0].SHIP_ID ||
                response.CublDetails.details[0].SHIP_ID == "null"
                ? null
                : response.CublDetails.details[0].SHIP_ID
            );
            setcublId(cblInputText);
            setvalid(false);
            settoastmsg("Valid CBL ID ", setItemTaggingToast(true));

            setLoader(false);
          } else {
            setheadermsg("Error");
            if (
              response.CublDetails.message !=
              "CUBL Details fetched successfully"
            )
              seterrmsg("CBL ID Not Exist");
            else seterrmsg("CBL ID already Despatch");

            $("#errorModalCenter1").modal();
            //setBarcodeValidation(false);
            setLoader(false);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  const handleCreateCubl = async (e) => {
    e.preventDefault();
    const dcNo = localStorage.getItem("DCNO");
    const dcName = localStorage.getItem("DCNAME");
    setLoader(true);
    await cblMapToUbl(user.storeNo, cublId, ublIds, user.token, shipment);
    handleProceed();
  };
  const handleProceed = () => {
    setLoader(true);
    CblService.viewUBLSummary(user.storeNo, cblInputText, user.token)
      .then((response) => {
        setCart(
          response.UblSummaryDetails.details
            ? response.UblSummaryDetails.details
            : []
        );
        setLoader(false);
        setpageno(2);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };
  const handleChangeText = (e) => {
    var inputBarcode = e.target.value
      .toString()
      .trim()
      .replace(/[^0-9.]/g, "");
    console.log(inputBarcode);
    setUblIds([]);
    setCart([]);
    setSelect(false);
    setvalid(true);
    setCblInputText(inputBarcode);
    if (
      inputBarcode.length === 10 &&
      !isNaN(inputBarcode) &&
      inputBarcode.substr(0, 3) === ("00" + user.storeNo).slice(-3) &&
      inputBarcode.substr(3, 7) > 0
    ) {
      console.log("valid");
      setBarcodeValidation(true);
    } else if (inputBarcode.length == 0) {
      setBarcodeValidation(true);
    } else {
      setBarcodeValidation(false);
    }

    localStorage.setItem("CUBLID", cblInputText);
  };
  const handleGoNextScreen = () => {
    if (pageno == 3) return;
    setpageno((pageno) => pageno + 1);
    setCurrent((current) => current + 1);
  };
  const handleGoHomeScreen = async (e, showtoast) => {
    setLoader(true);
    await loadCblData(user.storeNo, user.token);
    onCancel();
    if (showtoast) {
      settoastmsg("CBL closed Successfully", setItemTaggingToast(true));
    }
    setpageno(1);
    setLoader(false);
  };
  const handleGoPreviousScreen = () => {
    if (pageno == 1) return;
    setpageno((pageno) => pageno - 1);
    setCurrent((current) => current - 1);
  };
  const selected = (crumb) => {
    console.log(crumb);
  };

  const handleOnIdle = (event) => {
    console.log("user is idle", event);
    console.log("last active", getLastActiveTime());
    callLogoutURL();
  };

  const handleOnActive = (event) => {
    console.log("user is active", event);
    console.log("time remaining", getRemainingTime());
    handleReset();
    if (localStorage.getItem("access_token") == "null") {
      callLogoutURL();
      return;
    }
  };

  const handleOnAction = (event) => {
    console.log("user did something", event);
    if (localStorage.getItem("access_token") == "null") {
      callLogoutURL();
      return;
    }
  };
  const { getRemainingTime, getLastActiveTime, reset } = useIdleTimer({
    timeout: timeoutDuration,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });
  const handleReset = () => reset();
  console.log("CONNNN", state.details);
  const renderData = (ubl) => {
    return <div>Length:{ubl.length}</div>;
  };
  const onCancel = () => {
    setBarcodeValidation(true);
    setCblInputText("");
    setcublId("");
    setvalid(true);
    setCart([]);
    setUblIds([]);
    setSelect(false);
  };

  return (
    <div className="OrderLookup bg-grey" id="shipment_component">
      <Header />

      <div className="container-fluid">
        <div className="row">
          <Sidebar active="ModifyCbl" state={user} />
          <main
            role="main"
            className="col-md-9 ml-sm-auto col-lg-10 rtn-main-wrapper"
          >
            {/* page title */}
            <section className="rtn-page-title mb-2">
              <div className="d-flex row bd-highlight ">
                <div className="bd-highlight  col-xs-12 col-md-6 modal-dialog-centered">
                  <h4>Modify CBL: </h4>
                </div>

                <div className="col-xs-12 col-md-6 bd-highlight home-bc-padding">
                  {/* {<Breadcrumb crumbs={crumbs} selected={selected} />} */}
                  <nav
                    aria-label="breadcrumb"
                    style={{
                      marginRight: "15px",
                    }}
                  >
                    <ol className="breadcrumb">
                      <Link
                        to={{ pathname: "/LandingPage", state: user }}
                        className="breadcrumb-item breadcrumb-color pr-10"
                      >
                        Home
                      </Link>

                      <span className="pr-10"> {" >"}</span>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Create CBL
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </section>
            <section className="container-fluid section-three">
              <div className="row mrt14">
                <div class="col-md-2" id="sideprogress">
                  <div class="card1">
                    <ul id="progressbar" class="text-center ">
                      <li class="active step0 step1"></li>
                      <li
                        class={
                          pageno > 1 ? "step0 step2 active" : "step0 step2"
                        }
                      ></li>
                      <li
                        class={
                          pageno > 2 ? "step0 step3 active" : "step0 step3"
                        }
                      ></li>
                    </ul>
                    <div class="mb21">
                      <div class="row">
                        <div class="col-md-1 progressIcon">
                          <img
                            src="./images/create-icon.svg"
                            style={{ height: "65%", marginTop: "-2px" }}
                            alt=""
                          />
                        </div>
                        <div class="col icon_div">
                          <h6 class="fs12 zero">Modify CBL</h6>
                          <h6 class="fs10 gray">Enter/Scan CBL</h6>
                        </div>
                      </div>
                    </div>
                    <div class="mb21">
                      <div class="row">
                        <div class="col-md-1 progressIcon">
                          <img
                            src="./images/location-icon.svg"
                            style={{ height: "65%", marginTop: "-2px" }}
                            alt=""
                          />
                        </div>
                        <div class="col icon_div">
                          <h6 class="fs12 zero">Destination </h6>
                          <h6 class="fs10 gray">Select Destination</h6>
                        </div>
                      </div>
                    </div>
                    <div class="mb21">
                      <div class="row">
                        <div class="col-md-1 progressIcon">
                          <img
                            src="./images/view-summary-icon.svg"
                            style={{ height: "65%", marginTop: "-2px" }}
                            alt=""
                          />
                        </div>
                        <div class="col icon_div">
                          <h6 class="fs12 zero">View Summary </h6>
                          <h6 class="fs10 gray">Item Details</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {pageno === 1 ? (
                  <>
                    <ModifyCblPage
                      ublIds={ublIds}
                      handleAddCubl={handleAddCubl}
                      handleCreateCubl={handleCreateCubl}
                      handleProceed={handleProceed}
                      cbl={message}
                      barcodeValid={barcodeValid}
                      ubl={message2}
                      details={ubl}
                      cart={cart}
                      pending={pending}
                      ublIds={ublIds}
                      error={error}
                      viewUbl={viewUbl}
                      handleGoNextScreen={handleGoNextScreen}
                      handleChange={handleChange}
                      handleOnChange={handleOnChange}
                      handleViewUblSummary={handleViewUblSummary}
                      select={select}
                      handleChangeText={handleChangeText}
                      cblInputText={cblInputText}
                      valid={valid}
                      onCancel={onCancel}
                      clearcbl={onCancel}
                      storename={storename}
                    />
                  </>
                ) : (
                  <>
                    <ViewSummary
                      storename={storename}
                      cart={cart}
                      cbl={cbl}
                      message={message}
                      handleGoNextScreen={handleGoNextScreen}
                      handleOnUblTextChange={handleOnUblTextChange}
                      handleViewUblSummary={handleViewUblSummary}
                      handleGoHomeScreen={handleGoHomeScreen}
                      handleGoPreviousScreen={handleGoPreviousScreen}
                      handleAddUbl={handleAddUbl}
                      cblInputText={cblInputText}
                      message={message}
                      message2={message2}
                      pageno={pageno}
                      tostmsg={() => {
                        settoastmsg(
                          "UBL removed from this CBL",
                          setItemTaggingToast(true)
                        );
                      }}
                      shipId={shipment}
                    />
                  </>
                )}
              </div>
              {loader ? <LoadingIndicator /> : null}
            </section>
          </main>
        </div>
      </div>
      <div
        className="modal fade loginModal"
        id="errorModalCenter1"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-z"
          role="document"
        >
          <div className="modal-content modal-radius">
            <div className="modal-header error-popup-header-bg">
              <h5
                className="modal-title confirmation-style"
                id="errorModalCenterTitle"
              >
                {headermsg}
              </h5>
            </div>
            <div className="modal-body text-align-centre">
              <div>{errmsg} </div>
            </div>
            <div className="modal-footer bo-modalbtnftr bt-none">
              <button
                data-dismiss="modal"
                type="button"
                className=" btn bo-btnprimary confirm-yes"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*<div
        class="modal fade"
        id="errorModalCenter1"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header bg-light-black whitetext">
              <h5 class="modal-title" id="exampleModalLongTitle">
                {headermsg}
              </h5>
            </div>
            <div class="modal-body">
              <p class="text-center"> {errmsg} </p>
            </div>
            <div class="modal-footer border-none">
              <button
                type="button"
                class="btn bgred whitetext ctbtn "
                data-dismiss="modal"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>*/}

      <div className="topCenter" hidden={!itemTaggingToast}>
        <Toast
          id="toast"
          show={itemTaggingToast}
          onClose={() => setItemTaggingToast(false)}
          delay={5000}
          autohide
        >
          <div className="toast__content">
            <div>
              <span class="vertical-toast-border"></span>
              <div className="tickmark" style={{ height: "100%" }}>
                <img
                  className="tickmark"
                  src="./images/tick-circle-icon.svg"
                  alt=""
                />
              </div>
              <div className="pt-10">
                <p className="toast__type">Success</p>
                <p className="toast__message">{toastmsg}</p>
              </div>
              <div>
                <button
                  type="button"
                  className="close toast-close"
                  data-dismiss="toast"
                  aria-label="Close"
                  onClick={() => setItemTaggingToast(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>
        </Toast>
      </div>
    </div>
  );
};
const mapStateToProps = (state, ownProps) => {
  return {
    ubl: state.ubl.ubl,
    ublData: state.ubl,
    cbl: state.cbl.cbl,
    viewUbl: state.ubl.viewUblSummary,
    message: state.cbl,
    message2: state.ubl,
  };
};
const mapDispatchToProps = {
  loadCblData,
  loadCblDestination,
  addCubl,
  getCubl,
  cblMapToUbl,
  deleteCubl,
  viewUblSummary,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsolidateDispatchModify);
